<template>
  <div class="select-data-source-type-container">
    <div class="select-data-source-type-container-body">
      <div
        class="select-data-source-type-card vis-cursor-pointer"
        v-for="dataSourceTypeCard in dataSourceTypeCards"
        :key="dataSourceTypeCard.type"
        :class="{
          'select-data-source-type-card-selected': isTypeSelected(
            dataSourceTypeCard.type
          ),
        }"
        @click="selectType(dataSourceTypeCard.type)"
      >
        <div class="select-data-source-type-card-header">
          {{ $t(`${dataSourceTypeCard.header}`) }}
        </div>
        <div class="select-data-source-type-card-description">
          {{ $t(`${dataSourceTypeCard.description}`) }}
        </div>
        <div class="select-data-source-type-card-icon-container">
          <i
            v-for="icon in dataSourceTypeCard.icons"
            :key="icon"
            class="mdi select-data-source-type-card-icon"
            :class="icon"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
    <div class="select-data-source-type-container-footer">
      <button
        class="vis-btn medium vis-mt--2 next-button"
        @click="$emit('datasourceTypeSelected', selectedType)"
      >
        {{ $t("generalPages.next") }}
      </button>
    </div>
  </div>
</template>

<script>
import { importTypes } from "../../../commons/dataModelTypes";

export default {
  data() {
    return {
      importTypes: importTypes,
      dataSourceTypeCards: [
        {
          type: importTypes.CSV,
          header: "datamodel.headerCSV",
          description: "datamodel.descriptionCSV",
          icons: ["mdi-file-excel-outline", "mdi-file-delimited-outline"],
        },
        {
          type: importTypes.DB,
          header: "datamodel.headerDB",
          description: "datamodel.descriptionDB",
          icons: ["mdi-database-arrow-up-outline"],
        },
      ],
      selectedType: null,
    };
  },
  methods: {
    selectType(type) {
      this.selectedType = type;
    },
    isTypeSelected(type) {
      return this.selectedType === type;
    },
  },
};
</script>

<style scoped>
.select-data-source-type-container {
  height: inherit;
}
.select-data-source-type-container-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  column-gap: 60px;
  padding: 4rem;
}

.select-data-source-type-card {
  width: 350px;
  height: 170px;
  background: #fdfdfd 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  opacity: 1;
  padding: 18px 22px;
}

.select-data-source-type-card-selected {
  border: 1px solid #005aff !important;
}

.select-data-source-type-card-header {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #322e2e;
  margin-bottom: 10px;
}

.select-data-source-type-card-description {
  font-size: 13px;
  line-height: 15px;
  color: #322e2e;
  margin-bottom: 30px;
}

.select-data-source-type-card-icon-container {
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
}

.select-data-source-type-card-icon {
  font-size: 45px;
  opacity: 0.5;
}

.next-button {
  background: var(--primary-lighteen-1) 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: none;
  color: white;
  float: right;
  opacity: 1;
  margin-right: 50px;
  margin-bottom: 25px;
}

.next-button:disabled {
  opacity: 0.5;
}
</style>
