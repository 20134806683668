<template>
  <img
    class="vis-logo-image"
    :src="getImageUrl()"
    :alt="activeLogo"
    @click.stop="$emit('closePopoverMenu')"
  />
</template>
<script>
import { defaultLogo } from "../../../commons/dashboardAndPanel";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";

export default {
  props: {
    panelData: {
      type: Object,
    },
  },
  mixins: [panelDataPropertiesStyle],
  data() {
    return {};
  },
  computed: {
    activeLogo() {
      return this.panelDataDetailsLogo?.length
        ? this.panelDataDetailsLogo
        : defaultLogo;
    },
  },
  methods: {
    getImageUrl() {
      var images = require.context("../../../assets/images/", false, /\.png$/);
      return images("./" + this.activeLogo + ".png");
    },
  },
};
</script>
<style scoped>
#main {
  height: 400px;
  width: 600px;
}
.vis-logo-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>
