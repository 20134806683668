<template>
  <el-dialog
    :title="$t('Run Details')"
    :visible="dialogVisible"
    width="30%"
    @close="$emit('close')"
    :show-close="true"
  >
    <div
      class="vis-row schedule-row"
      v-for="column in columns"
      :key="column.field"
    >
      <div class="vis-col vis-col-6 schedule-row-item-title">
        <span>{{ column.label }}</span>
      </div>
      <div class="vis-col vis-col-6">
        <span
          v-if="column.field === columnKeys.STATUS"
          :class="{
            'failed-status': isStatusFailed(rowsProcessed[column.field]),
          }"
        >
          {{ rowsProcessed[column.field] || "-" }}
        </span>
        <span v-else-if="column.field === columnKeys.ERROR_DESCRIPTION">
          {{
            rowsProcessed[column.field]
              ? $t(`errorCode.${rowsProcessed[column.field]}`)
              : "-"
          }}
        </span>
        <span v-else-if="column.field === columnKeys.DYNAMIC">
          {{ rowsProcessed[column.field] ? $t("Yes") : $t("No") }}
        </span>
        <span v-else>{{ rowsProcessed[column.field] || "-" }}</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { formatISOToDateTime } from "../../util/moment";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columnKeys: {
        NAME: "dashboardName",
        OWNER: "owner",
        RUN_AS: "runAs",
        TYPE: "taskType",
        DYNAMIC: "isDynamic",
        STARTED_AT: "startingDate",
        FINISHED_AT: "endingDate",
        STATUS: "scheduleStatus",
        ERROR_DESCRIPTION: "errorMessage",
        DASHBOARD_ID: "transactionId",
        RUN_ID: "taskId",
      },
      statuses: {
        FAILED: "FAILED",
        // other statuses
      },
    };
  },
  computed: {
    columns() {
      return [
        {
          label: "Name",
          field: this.columnKeys.NAME,
        },
        {
          label: "Owner",
          field: this.columnKeys.OWNER,
        },
        {
          label: "Run as",
          field: this.columnKeys.RUN_AS,
        },
        {
          label: "File type",
          field: this.columnKeys.TYPE,
        },
        {
          label: "Dynamic",
          field: this.columnKeys.DYNAMIC,
        },
        {
          label: "Started at",
          field: this.columnKeys.STARTED_AT,
        },
        {
          label: "Finished at",
          field: this.columnKeys.FINISHED_AT,
        },
        {
          label: "Status",
          field: this.columnKeys.STATUS,
        },
        {
          label: "Error description",
          field: this.columnKeys.ERROR_DESCRIPTION,
        },
        {
          label: "Dashboard ID",
          field: this.columnKeys.DASHBOARD_ID,
        },
        {
          label: "Run ID",
          field: this.columnKeys.RUN_ID,
        },
      ];
    },
    rowsProcessed() {
      const rowFormatted = { ...this.row };

      rowFormatted[this.columnKeys.STARTED_AT] = formatISOToDateTime(
        rowFormatted[this.columnKeys.STARTED_AT]
      );
      rowFormatted[this.columnKeys.FINISHED_AT] = formatISOToDateTime(
        rowFormatted[this.columnKeys.FINISHED_AT]
      );

      return rowFormatted;
    },
  },
  methods: {
    isStatusFailed(value) {
      return value === this.statuses.FAILED;
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog__headerbtn {
  display: block !important;
}
::v-deep .el-dialog__title {
  padding-left: 30px !important;
}
.schedule-row {
  margin-bottom: 10px;
}
.schedule-row-item-title {
  font-weight: 600;
}
.failed-status {
  color: var(--danger);
  font-weight: 500;
}
</style>
