<template>
  <div class="vis-height-100">
    <div class="vis-flex--spacebeetwen data-model-sql-preview-header-container">
      <i
        @click.stop="$emit('previewTable')"
        class="vis-cursor-pointer data-model-sql-preview-action"
        :class="customIcon.Close"
        aria-hidden="true"
      />
    </div>
    <vue-good-table v-if="showPreviewTable" :columns="columns" :rows="rows" :fixed-header="false">
      <div class="vis-text--center" slot="emptystate">
        {{ $t("generalPages.noData") }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  components: { VueGoodTable },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    showPreviewTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
};
</script>
<style scoped>
.data-model-sql-preview-header {
  padding: 0 20px;
  display: inline-block;
}
::v-deep .vgt-table {
  border-spacing: 0;
  border-top: 0 !important;
}
::v-deep .vgt-table thead {
  position: sticky;
  top: 0;
}
::v-deep .vgt-table thead tr:first-child th {
  border-top: 1px solid #dcdfe6 !important;
}
::v-deep .vgt-table th,
.vgt-table td {
  border-width: 0.5px !important;
}
::v-deep .vgt-wrap {
  height: calc(100% - 40px) !important;
}
::v-deep .vgt-inner-wrap {
  height: 100% !important;
}
::v-deep .vgt-responsive {
  height: 100% !important;
}
</style>
