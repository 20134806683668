<template>
  <div class="scenario-aside-properties-event-tab">
    <span>{{ $t("Select the event you want to use in this transition") }}</span>
    <div class="vis-mt--1 vis-mb--1">
      <label>{{ $t("Event") }}</label>
      <el-select
        :value="properties.event"
        :placeholder="$t('Event')"
        @change="(v) => $emit('updateEventName', v)"
      >
        <el-option
          v-for="item in events"
          :key="item.name"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="selectedEventFields.length" class="vis-mb--1">
      <label>{{ $t("Field Info") }}</label>
      <el-row
        v-for="(e, index) in selectedEventFields"
        :key="e.field"
        class="vis-mb--1"
        :gutter="20"
      >
        <el-col :span="12">
          <label v-if="index === 0" class="label-disabled">{{
            $t("Name")
          }}</label>
          <div>
            <el-input :value="e.field" :readonly="true" /></div
        ></el-col>
        <el-col :span="12">
          <label v-if="index === 0" class="label-disabled">{{
            $t("Type")
          }}</label>
          <div><el-input :value="e.type" :readonly="true" /></div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    properties: {
      type: Object,
    },
    events: {
      type: Array,
    },
  },
  computed: {
    selectedEvent() {
      return this.events.find((e) => e.name === this.properties.event);
    },
    selectedEventFields() {
      return this.selectedEvent?.fields ?? [];
    },
  },
};
</script>

<style scoped>
.label-disabled {
  font: normal normal 400 13px/20px Poppins !important;
  letter-spacing: 0px;
  color: #3e3e3e;
}
label {
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
}
</style>
