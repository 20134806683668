<template>
  <draggable
    v-model="list"
    class="list-group"
    tag="ul"
    animation="200"
    :disabled="false"
    ghostClass="ghost"
    group="datamodelFields"
    @change="onDragged"
  >
    <li
      v-for="item in list"
      class="list-group-item"
      :class="{ 'list-group-item-active': isItemActive(item) }"
      :key="item.field"
      @click="clickItem(item)"
    >
      <i
        :class="[getUsageTypeIcon(item), 'usage-type-icon']"
        aria-hidden="true"
      />

      <span class="list-group-item-name"> {{ item.alias }}</span>

      <el-dropdown
        class="vis-ml--auto"
        size="mini"
        trigger="click"
        @command="(command) => handleCommand(command, item)"
      >
        <span class="el-dropdown-link vis-flex--Ycenter">
          <i :class="CustomIcon.VerticalThreeDot"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="delete">{{
            $t("generalPages.delete")
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </li>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import cloneDeep from "clone-deep";
import { DatamodelContextDefaults } from "../../../commons/dataModelTypes";
import { CustomIcon } from "../../../assets/js/custom-icons";
import { filterPopupTabValue } from "../../panel/filters/js/filters";

export default {
  components: {
    draggable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
      drag: false,
      CustomIcon: CustomIcon,
      activeItem: null,
    };
  },
  watch: {
    items: {
      handler(val) {
        this.list = cloneDeep(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.list = cloneDeep(this.items);
  },
  methods: {
    isItemActive(item) {
      return this.activeItem?.fieldId === item.fieldId;
    },
    clickItem(item) {
        this.activeItem = item;
        this.$emit('clickItem', item)
    },
    handleCommand(command, item) {
      if (command === "delete") {
        this.list = this.list.filter((i) => i.field !== item.field);

        this.$emit("updateItems", this.list);
      }
    },
    onDragged(e) {
      // find added item and update it according to the backend model and emit it to the parent
      const addedElement = e.added.element;

      if (addedElement) {
        this.list = this.list.map((i) => {
          if (i.fieldId === addedElement.fieldId) {
            return {
              fieldId: addedElement.fieldId,
              alias: addedElement.alias,
              datasetId: addedElement.datasetId,
              field: addedElement.name,
              fieldType: addedElement.type,
              fieldUsageType: addedElement.usageType,
              type: addedElement.defaultAggFunction,
              popupTabName:
                addedElement.usageType ===
                DatamodelContextDefaults.USAGE_TYPES.MEASURE
                  ? filterPopupTabValue.RULE
                  : filterPopupTabValue.SELECTION,
              operator: "",
              value: [],
            };
          }

          return i;
        });
      }

      this.$emit("updateItems", this.list);
    },
    getUsageTypeIcon(item) {
      const iconByTypes = {
        [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE]: CustomIcon.Abc,
        [DatamodelContextDefaults.USAGE_TYPES.DATE]: CustomIcon.Clock_Time,
        ["parameters"]: CustomIcon.UnfoldMoreVertical,
      };
      return iconByTypes[item.fieldUsageType] ?? CustomIcon.Measure;
    },
  },
  computed: {},
};
</script>

<style scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 100%;
}

.list-group-item {
  cursor: move;
  border: 1px solid #3455f1;
  padding: 4px 12px;
  margin-top: 8px;
  color: #3455f1;
  display: flex;
  align-items: center;
  gap: 10px;
}

.list-group-item-active {
  border: 2px solid #3455f1;
}

.list-group-item .usage-type-icon {
  cursor: pointer;
  font-size: 1rem;
  color: #3455f1;
}

.list-group-item .el-dropdown {
  cursor: pointer;
  font-size: 18px;
  color: #3455f1;
}

.list-group-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .icon-vertical_three_dot {
  font-size: 1rem;
  color: #3455f1;
}
</style>
