/* eslint-disable */
import { ACTION } from "./types";
import { ACTION as ACTION_DASHBOARD } from "../Dashboard/types"
import { ACTION as ACTION_DATAMODEL } from "../DataModel/types"
import { MUTATION_GENERAL } from "../General/types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const visBulkDelete = {
    actions: {
        [ACTION.BULK_DELETE_PROJECTS]: async ({ commit, dispatch }, bodyParam) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.BulkDelete,
                    serviceMethodSub: serviceMethodSub.bulkDeleteProjects,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );

            await dispatch(ACTION_DASHBOARD.FETCH_DASHBOARDS, { loadingComponent: null })
            await dispatch(ACTION_DATAMODEL.FETCH_DATAMODELS, { loadingComponent: null })
        },
    },
};
