<template>
  <div class="vis-tools-content vis-mb--1">
    <draggable
      class="list-group vis-mt--1"
      :list="parameters"
      :group="{ name: 'datamodelFields', pull: 'clone', put: true }"
    >
      <ul
        class="list-group-item vis-flex--spacebeetwen-Ycenter"
        v-for="field in parameters"
        :key="field.id"
      >
        <div class="vis-w--full">
          <li class="vis-flex--spacebeetwen-Ycenter">
            <div
              class="vis-flex--Ycenter vis-dataModel-list-icons vis-cursor-pointer dashboard-parameter"
            >
              <i :class="CustomIcon.UnfoldMoreVertical" aria-hidden="true" />
              <span class="">{{ field.name }}</span>
            </div>
          </li>
        </div>
      </ul>
    </draggable>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  props: {
    parameters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
    };
  },
};
</script>

<style scoped>
.dashboard-parameter {
  margin-left: 10px;
}
</style>
