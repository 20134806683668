<template>
  <div class="scenario-aside-properties-event-tab">
    <span>{{ $t("Define the enrichment for this transition") }}</span>
    <el-collapse class="vis-mt--1" v-model="selectedAccordion" accordion>
      <el-collapse-item
        v-for="(enrichment, index) in properties.enrichments"
        :key="index"
        :title="`Enrichment ${index + 1}`"
        :name="`enrichment-${index + 1}`"
      >
        <span>{{ $t("Select the enrichment and define parameters") }}</span>
        <div class="vis-mt--1 vis-mb--1">
          <label>{{ $t("Enrichment") }}</label>
          <el-select
            :value="enrichment.enrichmentId"
            :placeholder="$t('Enrichment')"
            @change="(id) => updateEnrichmentName(id, index)"
          >
            <el-option
              v-for="item in enrichmentOptions"
              :key="item.enrichmentId"
              :label="item.name"
              :value="item.enrichmentId"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="getEnrichmentParamsByIndex(index).length" class="vis-mb--1">
          <label>{{ $t("Parameters") }}</label>
          <div class="vis-pl--1">
            <el-row
              v-for="(e, enrichmentParamIndex) in getEnrichmentParamsByIndex(
                index
              )"
              :key="e.key"
              class="vis-mb--1"
              :gutter="20"
            >
              <el-col :span="12">
                <label
                  v-if="enrichmentParamIndex === 0"
                  class="label-disabled"
                  >{{ $t("Parameter") }}</label
                >
                <div>
                  <el-input :value="e.key" :readonly="true" /></div
              ></el-col>
              <el-col :span="12">
                <label
                  v-if="enrichmentParamIndex === 0"
                  class="label-disabled"
                  >{{ $t("Value") }}</label
                >
                <div>
                  <el-input
                    :value="e.value"
                    @input="(v) => updateEnrichmentParamValue(e.key, v, index)"
                  /></div
              ></el-col>
            </el-row>
          </div>
        </div>
        <div v-if="getEnrichmentOutputsByIndex(index).length" class="vis-mb--1">
          <label>{{ $t("Field Info") }}</label>
          <div class="vis-pl--1">
            <el-row
              v-for="(e, enrichmentOutputIndex) in getEnrichmentOutputsByIndex(
                index
              )"
              :key="e.field"
              class="vis-mb--1"
              :gutter="20"
            >
              <el-col :span="12">
                <label
                  v-if="enrichmentOutputIndex === 0"
                  class="label-disabled"
                  >{{ $t("Name") }}</label
                >
                <div>
                  <el-input :value="e.field" :readonly="true" /></div
              ></el-col>
              <el-col :span="12">
                <label
                  v-if="enrichmentOutputIndex === 0"
                  class="label-disabled"
                  >{{ $t("Type") }}</label
                >
                <div><el-input :value="e.type" :readonly="true" /></div
              ></el-col>
            </el-row>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div class="scenario-aside-properties-enrichment-tab-footer">
      <el-link
        class="scenario-aside-properties-enrichment-tab-check-button"
        :underline="false"
        @click="addEmptyEnrichment"
        >Add enrichment</el-link
      >
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";

export default {
  props: {
    properties: {
      type: Object,
    },
    enrichmentOptions: {
      type: Array,
    },
  },
  data() {
    return {
      selectedAccordion: "enrichment-1",
      enrichmentForm: {
        enrichmentValue: null,
      },
    };
  },
  computed: {},
  methods: {
    getEnrichmentParamsByIndex(index) {
      return this.properties.enrichments[index]?.params ?? [];
    },
    getEnrichmentOutputsByIndex(index) {
      return (
        this.enrichmentOptions?.find(
          (e) =>
            e.enrichmentId === this.properties.enrichments[index]?.enrichmentId
        )?.outputs ?? []
      );
    },
    addEmptyEnrichment() {
      const enrichments = cloneDeep(this.properties.enrichments);

      enrichments.push({
        enrichmentId: "",
        params: [],
      });

      this.$emit("updateEnrichment", enrichments);
    },
    updateEnrichmentParamValue(key, value, index) {
      const enrichments = cloneDeep(this.properties.enrichments);

      enrichments[index].params?.forEach((param) => {
        if (param.key === key) {
          param.value = value;
        }
      });

      this.$emit("updateEnrichment", enrichments);
    },
    updateEnrichmentName(newEnrichmentId, index) {
      const newEnrichment = cloneDeep(
        this.enrichmentOptions.find((e) => e.enrichmentId === newEnrichmentId)
      );
      const enrichments = cloneDeep(this.properties.enrichments);

      enrichments[index] = {
        enrichmentId: newEnrichmentId,
        params: newEnrichment.inputs.map((i) => ({
          key: i.field,
          value: "",
        })),
      };

      this.$emit("updateEnrichment", enrichments);
    },
  },
};
</script>

<style scoped>
::v-deep .el-collapse-item__header {
  padding: 0 !important;
}
::v-deep .el-collapse-item__wrap {
  width: 100%;
}
.label-disabled {
  font: normal normal 400 13px/20px Poppins !important;
  letter-spacing: 0px;
  color: #3e3e3e;
}
label {
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
}
.scenario-aside-properties-enrichment-tab-check-button {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0014ff !important;
}
.scenario-aside-properties-enrichment-tab-footer {
  display: flex;
  margin-top: 20px;
}
.scenario-aside-properties-enrichment-tab-check-button:hover {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0014ff !important;
}
</style>
