export const chatGptInsightsBodyParam = {
    "temperature": 0.3,
    "top_p": 1,
    "frequency_penalty": 0,
    "presence_penalty": 0,
    "max_tokens": 800,
    "best_of": 5,
    "stop": [
        "Human:",
        "AI:"
    ]
}

export const chatGptTabs = {
    INSIGHTS: "INSIGHTS",
    SUMMARY: "SUMMARY"
}