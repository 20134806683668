import { v4 as uuidv4 } from "uuid";
import i18n from "../../../i18n";
import { CustomIcon } from "../../../assets/js/custom-icons";
export const conditionalOperatorValues = {
    EQ: "=",
    NOT_EQ: "!=",
    BETWEEN: "between",
    NOT_BETWEEN: "notBetween",
    QT: ">",
    LT: "<",
    QTE: ">=",
    LTE: "<=",
    IN: "in",
    NOT_IN: "notIn"
}
export const conditionalOperatorLabels = {
    EQ: "=",
    NOT_EQ: "!=",
    BETWEEN: i18n.tc("filterComponents.between"),
    NOT_BETWEEN: i18n.tc("filterComponents.notBetween"),
    QT: ">",
    LT: "<",
    QTE: ">=",
    LTE: "<=",
    IN: "in",
    NOT_IN: "notIn"
}
export const measureOptions = () => {
    return [
        { value: conditionalOperatorValues.EQ, label: "=" },
        { value: conditionalOperatorValues.NOT_EQ, label: "!=" },
        { value: conditionalOperatorValues.BETWEEN, label: conditionalOperatorLabels.BETWEEN },
        { value: conditionalOperatorValues.NOT_BETWEEN, label: conditionalOperatorLabels.NOT_BETWEEN },
        { value: conditionalOperatorValues.QT, label: ">" },
        { value: conditionalOperatorValues.LT, label: "<" },
        { value: conditionalOperatorValues.QTE, label: ">=" },
        { value: conditionalOperatorValues.LTE, label: "<=" },
    ]
}
export const attributeOptions = () => {
    return [
        { value: conditionalOperatorValues.EQ, label: "=" },
        { value: conditionalOperatorValues.NOT_EQ, label: "!=" },
        { value: conditionalOperatorValues.IN, label: i18n.tc('operatorLocale.in') },
        { value: conditionalOperatorValues.NOT_IN, label: i18n.tc('operatorLocale.notIn') },
    ]
}

export const dateOptions = () => {
    return [
        { value: conditionalOperatorValues.EQ, label: "=" },
        { value: conditionalOperatorValues.NOT_EQ, label: "!=" },
        { value: conditionalOperatorValues.BETWEEN, label: conditionalOperatorLabels.BETWEEN },
        { value: conditionalOperatorValues.NOT_BETWEEN, label: conditionalOperatorLabels.NOT_BETWEEN },
    ]
}

export const fontSizes = [
    { label: "10", value: 10 },
    { label: "12", value: 12 },
    { label: "14", value: 14 },
    { label: "16", value: 16 },
    { label: "18", value: 18 },
    { label: "20", value: 20 },
    { label: "24", value: 24 },
    { label: "30", value: 30 },
]
export const defaultIconFormatData = () => {
    return {
        iconName: CustomIcon.CondCircle,
        iconSize: 14,
        iconColor: "",
        iconShowOption: iconFormatShowType.ICON_ONLY,
        iconPosition: "right",
        customText: ""
    }
}
export const defaultConditionData = () => {
    return {
        id: uuidv4(),
        format: {
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 500,
            fontColor: "",
            chipColor: "",
            backgroundColor: "",
        },
        iconFormat: defaultIconFormatData(),
        rules: [
            {
                id: uuidv4(),
                dataItem: "",
                operator: "",
                value1: null,
                value2: null,
            },
        ],
    }
}

export const defaultConditionalFormattingData = (fieldId, slotFieldId) => {
    return {
        fieldId: fieldId,
        slotFieldId: slotFieldId,
        formatType: conditionFormatType.FORMAT_VALUE,
        conditions: [defaultConditionData()],
    }
}

export const conditionFormatType = {
    FORMAT_VALUE: "FORMAT_VALUE",
    FORMAT_ICON: "FORMAT_ICON",
}
export const iconFormatShowType = {
    ICON_ONLY: "ICON_ONLY",
    ICON_VALUE_ONLY: "ICON_VALUE_ONLY"
}
export const iconFormatPositionValue = {
    LEFT: "left",
    RIGHT: "right"
}
export const conditionFormatTypes = [
    { value: conditionFormatType.FORMAT_VALUE, label: "conditionalFormatting.Format Value" },
    { value: conditionFormatType.FORMAT_ICON, label: "conditionalFormatting.Format Icon" },
]

export const iconList = [
    //Arrow Start
    { value: CustomIcon.CondArrowDecreasing, label: "Arrow Decreasing" },
    { value: CustomIcon.CondArrowDown, label: "Down Arrow" },
    { value: CustomIcon.CondArrowIncreasing, label: "Arrow Increasing" },
    { value: CustomIcon.CondArrowUp, label: "Up Arrow" },
    //{ value: CustomIcon.ArrowRight, label: "Right Arrow" },
    //Arrow END
    //BARS START
    { value: CustomIcon.CondBars1, label: "Bars 1" },
    { value: CustomIcon.CondBars2, label: "Bars 2" },
    { value: CustomIcon.CondBars3, label: "Bars 3" },
    { value: CustomIcon.CondBars4, label: "Bars 4" },
    //BARS END
    //CANCEL START
    { value: CustomIcon.CondCancel, label: "Cancel" },
    { value: CustomIcon.CondCancelCircled, label: "Cancel Circled" },
    //CANCEL END
    //CHECK START
    { value: CustomIcon.CondCheck, label: "Check" },
    { value: CustomIcon.CondCheckCircled, label: "Check Circled" },
    //CHECK END
    //CHEVRON START
    { value: CustomIcon.CondChevronDown, label: "Chevron Down" },
    { value: CustomIcon.CondChevronLeft, label: "Chevron Left" },
    { value: CustomIcon.CondChevronUp, label: "Chevron Up" },
    //CHECRON END

    { value: CustomIcon.CondCircle, label: "Circle" },
    { value: CustomIcon.CondDiamond, label: "Diamond" },

    //EXCLAMATION START
    { value: CustomIcon.CondExclamation, label: "Exclamation" },
    { value: CustomIcon.CondExclamationCicled, label: "Exclamation Circled" },
     //EXCLAMATION END
     { value: CustomIcon.CondFlag, label: "Flag" },
     //GRID START
    { value: CustomIcon.CondGrid1, label: "Grid 1" },
    { value: CustomIcon.CondGrid2, label: "Grid 2" },
    { value: CustomIcon.CondGrid3, label: "Grid 3" },
    { value: CustomIcon.CondGrid4, label: "Grid 4" },
     //GRID END

     //PIE START
     { value: CustomIcon.CondPie1, label: "Pie 1" },
     { value: CustomIcon.CondPie2, label: "Pie 2" },
     { value: CustomIcon.CondPie3, label: "Pie 3" },
     //PIE END
    { value: CustomIcon.CondSquare, label: "Square" },

     //TRIANGLE START
     { value: CustomIcon.CondTriangle, label: "Triangle" },
     { value: CustomIcon.CondTriangleDown, label: "Triangle Down" },
     { value: CustomIcon.CondTriangleUp, label: "Triangle Up" },
     //TRIANGLE END
    
]
export const iconShowOptions = [
    { value: iconFormatShowType.ICON_ONLY, label: "conditionalFormatting.Show Icon" },
    { value: iconFormatShowType.ICON_VALUE_ONLY, label: "conditionalFormatting.Show Icon And Value" },
]

export const iconPositionOptions = [
    { value: iconFormatPositionValue.LEFT, label: "generalPages.left" },
    { value: iconFormatPositionValue.RIGHT, label: "generalPages.right" },
]