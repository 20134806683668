/* eslint-disable */
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const visUser = {
    state: {
        [STATE.USER_PREFERENCES]: []
    },
    getters: {
        [GETTER.GET_USER_PREFERENCES]: (state) => {
            return state[STATE.USER_PREFERENCES];
        },
    },
    actions: {
        [ACTION.FETCH_USER_PREFERENCES]: async ({ commit }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.User,
                    serviceMethodSub: serviceMethodSub.readUserPreferences,
                    successMutation: MUTATION.SET_USER_PREFERENCES,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true
                },
            );
        },

        [ACTION.UPDATE_USER_PREFERENCES]: async ({ commit, dispatch }, { queryParam, bodyParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.User,
                    serviceMethodSub: serviceMethodSub.updateUserPreferences,
                    queryParam,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            await dispatch(ACTION.FETCH_USER_PREFERENCES)
        },
    },
    mutations: {
        [MUTATION.SET_USER_PREFERENCES](state, item) {
            Vue.set(state, [STATE.USER_PREFERENCES], item.preferences);
        }
    },

};
