<template>
  <div class="vis-flex--center" :class="{'vis-other-chart-button': !isChartEditView}">
    <el-button @click.stop="handleClick()" :style="valueLabelStyle">
      <div class="vis-ellipsis">{{ this.buttonCaptionLabel }}</div></el-button
    >
  </div>
</template>
<script>
import { BUTTON_PROPERTIES_ENUM, buttonSelectionOptions, buttonSelectionValues } from "../../../commons/dashboardProperties";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
export default {
  mixins: [panelDataPropertiesStyle],
  data() {
    return {};
  },
  props: {
    panelData: {
      type: Object,
    },
    isChartEditView: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttonActionSelectedValue() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_SELECT_VALUE
      ];
    },
    buttonCaptionLabel() {
      if (
        !this.CHART_COMPUTED_VALUES?.[
          BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL
        ]
      ) {
        return this.$t(
          buttonSelectionOptions.find(
            (x) => x.value === this.buttonActionSelectedValue
          )?.label
        );
      } else
        return this.CHART_COMPUTED_VALUES?.[
          BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL
        ];
    },
    valueLabelStyle() {
      return {
        color:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_COLOR
          ],
        "font-family":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_FAMILY
          ],
        "font-weight":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_WEIGHT
          ],
        "font-size":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_SIZE
          ] + "px  !important",
        "background-color":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BACKGROUND_COLOR
          ],
        "border-radius":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BORDER_RADIUS
          ] + "px !important",
      };
    },
    buttonActionApplyToTabs() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_APPLY_TO_TABS
      ];
    },
    buttonActionUrlValue() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_URL_VALUE
      ];
    },
  },
  methods: {
    handleClick() {
      if (this.buttonActionSelectedValue === buttonSelectionValues.RUN) {
        this.$emit("getViewChartWithButton");
      } else if (this.buttonActionSelectedValue === buttonSelectionValues.TAB) {
        this.$emit("setTabIndexWithButton", this.buttonActionApplyToTabs);
      } else if(this.buttonActionSelectedValue === buttonSelectionValues.URL) {
        this.$emit("redirectToUrlWithButton", this.buttonActionUrlValue);
      } else if(this.buttonActionSelectedValue === buttonSelectionValues.DIAOLOG) {
        this.$emit("openButtonDialog", this.panelData);
      }
    },
  },
};
</script>
<style scoped>
.vis-other-chart-button .el-button {
  width: 100%;
  overflow: hidden;
}
</style>
