<template>
  <el-popover
    placement="right"
    width="350"
    trigger="manual"
    popper-class="vis-dropdown-popper vis-custom-popper"
    :value="isCustomFilterPopupOpen"
  >
    <div slot="reference"></div>

    <ContextPopup :customClass="'vis-datamodelCustomFilterPopup'">
      <template v-slot:title>
        <span>{{ $t(`${popupTitleName}`) }}</span>
        <i
          @click="$emit('close')"
          :class="customIcon.Close"
          class="vis-cursor-pointer"
          aria-hidden="true"
        ></i>
      </template>

      <template v-slot:content>
        <div class="vis-pl--1 vis-pr--1">
          <div class="vis-row-box">
            <label>{{ $t("generalPages.labelName") }}</label>
            <input v-model="formData.name" class="vis-input" type="text" />
          </div>
          <div class="vis-row-box vis-mt--1">
            <label>{{ $t("generalPages.labelType") }}</label>
            <el-select
              v-model="formData.type"
              @change="handlerType()"
              :placeholder="$t('generalPages.customFilterPlaceholder')"
            >
              <el-option
                v-for="item in customFilterOptions"
                :key="item.key"
                :label="$t(item.label)"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
          <div class="vis-row vis-mt--1">
            <div
              class="vis-col vis-col-6 vis-pl--none"
              v-if="formData.type !== customFilterTypes.DATE"
            >
              <div class="vis-row-box">
                <label>{{ $t("generalPages.labelMultiSelect") }}</label>
                <el-select
                  v-model="formData.multi_select"
                  :placeholder="$t('generalPages.customFilterPlaceholder')"
                >
                  <el-option
                    v-for="item in optionsYesNo"
                    :key="item.key"
                    :label="$t(`${item.label}`)"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="vis-col vis-col-6 vis-pl--none" v-else>
              <div class="vis-row-box">
                <label>{{ $t("generalPages.labelRange") }}</label>
                <el-select
                  v-model="formData.rangeSelect"
                  @change="formData.default_value = null"
                  :placeholder="$t('generalPages.customFilterPlaceholder')"
                >
                  <el-option
                    v-for="item in optionsYesNo"
                    :key="item.key"
                    :label="$t(`${item.label}`)"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="vis-col vis-col-6 vis-pr--none">
              <div class="vis-row-box">
                <label>{{ $t("generalPages.mandatory") }}</label>
                <el-select
                  v-model="formData.mandatory"
                  :placeholder="$t('generalPages.customFilterPlaceholder')"
                >
                  <el-option
                    v-for="item in optionsYesNo"
                    :key="item.key"
                    :label="$t(`${item.label}`)"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="vis-row-box vis-mt--1">
            <label>{{ $t("generalPages.defaultValue") }}</label>
            <input
              v-if="formData.type === customFilterTypes.TEXT"
              v-model="formData.default_value"
              class="vis-input"
              type="text"
            />
            <el-date-picker
              v-if="formData.type === customFilterTypes.DATE"
              :value="dateRangeValue"
              :type="formData.rangeSelect ? 'daterange' : 'date'"
              format="dd/MM/yyyy"
              value-format="dd-MM-yyyy"
              :placeholder="$t('filterComponents.selectDate')"
              :start-placeholder="$t('generalPages.Start Date')"
              :end-placeholder="$t('generalPages.End Date')"
              :default-value="new Date()"
              @input="(val) => handleDateRangeChange(val)"
            >
            </el-date-picker>
            <el-input-number
              v-if="formData.type === customFilterTypes.NUMBER"
              :value="Number(formData.default_value)"
              controls-position="right"
              :min="0"
              @change="(val) => handleInputChange(val)"
            ></el-input-number>
          </div>
          <div class="vis-row-box vis-text--right">
            <el-button
              v-if="isCreateMode"
              v-loading="loading"
              @click="save()"
              class="vis-mt--2"
              type="primary"
              size="small"
              >{{ $t("generalPages.save") }}</el-button
            >
            <el-button
              v-if="isEditMode"
              v-loading="loading"
              @click="update()"
              class="vis-mt--2"
              type="primary"
              size="small"
              >{{ $t("generalPages.update") }}</el-button
            >
          </div>
        </div>
      </template>
    </ContextPopup>
  </el-popover>
</template>

<script>
import cloneDeep from "clone-deep";
import {
  customFilterPopupModes,
  customFilterPopupDefaultFormData,
} from "./types";
import { v4 as uuidv4 } from "uuid";
import {
  customFilterOptions,
  customFilterTypes,
} from "../../../commons/dataModelTypes";
import ContextPopup from "../../panel/ContextPopup.vue";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  components: { ContextPopup },
  watch: {
    isCustomFilterPopupOpen() {
      if (this.isCustomFilterPopupOpen) {
        this.formData = cloneDeep(this.filter);
      }
    },
    "formData.default_value": {
      handler(newVal) {
        if (newVal) this.dateRangeValue;
      },
      immediate: true,
    },
  },
  props: {
    filter: {
      type: Object,
    },
    mode: {
      type: String,
    },
    isCustomFilterPopupOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      customFilterPopupModes: customFilterPopupModes,
      formData: customFilterPopupDefaultFormData,
      optionsYesNo: [
        {
          key: "Yes",
          label: "generalPages.yes",
          value: true,
        },
        {
          key: "No",
          label: "generalPages.no",
          value: false,
        },
      ],
      customFilterOptions: customFilterOptions,
      customIcon: CustomIcon,
      customFilterTypes: customFilterTypes,
    };
  },
  mounted() {},
  computed: {
    isCreateMode() {
      return this.mode === this.customFilterPopupModes.Create;
    },
    isEditMode() {
      return this.mode === this.customFilterPopupModes.Edit;
    },
    popupTitleName() {
      return this.isCreateMode
        ? "generalPages.createCustomFilter"
        : "generalPages.editCustomFilter";
    },
    dateRangeValue() {
      if (
        this.formData.default_value &&
        this.formData.rangeSelect &&
        typeof this.formData.default_value === "string"
      ) {
        return JSON.parse(this.formData.default_value);
      }
      return this.formData.default_value;
    },
  },
  methods: {
    save() {
      this.loading = true;
      this.saveAndUpdateToDateRangeDataValue();
      setTimeout(() => {
        this.$emit("save", {
          ...this.formData,
          temporaryFilterParamId: uuidv4(),
        });
        this.loading = false;
      }, 1000);
    },
    update() {
      this.loading = true;
      this.saveAndUpdateToDateRangeDataValue();
      setTimeout(() => {
        this.$emit("update", this.formData);
        this.loading = false;
      }, 1000);
    },
    handlerType() {
      this.formData.default_value = null;
    },
    handleDateRangeChange(value) {
      this.formData.default_value = value;
    },
    handleInputChange(value) {
      this.formData.default_value = value;
    },
    saveAndUpdateToDateRangeDataValue() {
      if (this.formData.rangeSelect && typeof this.formData.default_value !== "string") {
        const convertDateJsonString = JSON.stringify(
          this.formData.default_value ?? ["", ""]
        );
        this.formData.default_value = convertDateJsonString;
      }
    },
  },
};
</script>
<style scoped>
.vis-panel-dropdown.vis-datamodelCustomFilterPopup {
  width: 350px;
}
</style>
