import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { xor } from 'lodash'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function haveLanguageFilesSameKeys() {
  const keyify = (obj, prefix = '') => 
    Object.keys(obj).reduce((res, el) => {
      if( Array.isArray(obj[el]) ) {
        return res;
      } else if( typeof obj[el] === 'object' && obj[el] !== null ) {
        return [...res, ...keyify(obj[el], prefix + el + '.')];
      } else {
        return [...res, prefix + el];
      }
    }, []);
  
  const messages = loadLocaleMessages();
  const languageKeys = Object.keys(messages).map(m => keyify(messages[m]));
  if (languageKeys.length > 1) {
    for (let i = 1, ii = languageKeys.length; i < ii; i++) {
      const diff = xor(languageKeys[0], languageKeys[i]);
      if (diff.length > 0) {
        console.warn("[i18n] Language files doesn't have same key values.", "Difference =>", diff);
      }
    }
  }
}

haveLanguageFilesSameKeys();

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
})

new VueI18n({
  // Key - language to use the rule for, `'ru'`, in this case
  // Value - function to choose right plural form
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'ru': function(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  }
})