import cloneDeep from "clone-deep";
import { createSmartTitle } from "../util/panelDataPropertiesStyle";
import { DatamodelContextDefaults } from "./dataModelTypes";
import { filterType } from "./filterComponents";

export const chartTypes = {
  TABLE: "DATA_TABLE",
  PIE: "PIE_CHART",
  BAR: "BAR_CHART",
  COLUMN_CHART: "COLUMN_CHART",
  LABEL: "LABEL_CHART",
  LINE_CHART: "LINE_CHART",
  LOGO: "LOGO_CHART",
  TREEMAP_CHART: "TREEMAP_CHART",
  PIVOT_TABLE: "PIVOT_TABLE",
  BUBBLE_CHART: "BUBBLE_CHART",
  HEATMAP_CHART: "HEATMAP_CHART",
  AREA_CHART: "AREA_CHART",
  SCATTER_CHART: "SCATTER_CHART",
  COMBINATION_CHART: "COMBINATION_CHART",
  STACKED_COLUMN_CHART: "STACKED_COLUMN_CHART",
  STACKED_BAR_CHART: "STACKED_BAR_CHART",
  SUMMARY: "SUMMARY_CHART",
  OTHER: "OTHER",
  GAUGE_CHART: "GAUGE_CHART",
  RADAR_CHART: "RADAR_CHART",
  GEO_MAP_CHART: "GEO_MAP_CHART",
  TAB_PANEL: "TAB_PANEL"
};

export const seriesTypeWithoutColorBy = {
  MAP: "map",
  TREEMAP: "treemap",
  HEATMAP: "heatmap"
};

export const seriesTypeRadar = {
  RADAR: "radar",
}

export const seriesTypeScatter = {
  SCATTER: "scatter",
}

export const panelTypes = {
  CHART: "chart",
  LOGO: "logo",
  SUMMARY: "summary",
  FILTER: "filter",
  TABLE: "table"
};
export const properties = {
  LOGO: "logo",
  METRICS: "metrics",
  AGGREGATION: "aggregation",
  COLUMNS: "columns",
  SECTIONS: "sections",
  FILTERS: "filters",
  ORDERS: "orders",
  PARAMS: "params",
  ISFILTER_PANE_CHART: "isFilterPaneChart",
  SERVERSIDE_FILTERS: "serversideFilters",
  CUSTOM_FILTERS: "customFilters",
};
export const collapseName = {
  CHARTS: "CHARTS",
  FILTERS: "FILTERS",
  OTHER: "OTHER",
};
export const onPropertiesEventOptions = {
  openConditionalFormatting: "openConditionalFormatting",
};
export const chartList = [
  { id: chartTypes.COLUMN_CHART, icon: "bar-column-chart", name: "generalPages.column" },
  {
    id: chartTypes.STACKED_COLUMN_CHART,
    icon: "stacked-column-chart",
    name: "generalPages.stackedColumn",
  },
  { id: chartTypes.BAR, icon: "bar-chart", name: "generalPages.bar" },
  {
    id: chartTypes.STACKED_BAR_CHART,
    icon: "stacked-bar-chart",
    name: "generalPages.stackedBar",
  },
  { id: chartTypes.LINE_CHART, icon: "line-chart", name: "generalPages.line" },
  { id: chartTypes.AREA_CHART, icon: "area-chart", name: "generalPages.area" },
  { id: chartTypes.PIE, icon: "pie-chart", name: "generalPages.pie" },
  { id: chartTypes.TREEMAP_CHART, icon: "treemap-chart", name: "generalPages.treeMap" },
  {
    id: chartTypes.COMBINATION_CHART,
    icon: "combination-chart",
    name: "generalPages.combination",
  },
  { id: chartTypes.HEATMAP_CHART, icon: "heatmap-chart", name: "generalPages.heatMap" },
  { id: chartTypes.SCATTER_CHART, icon: "scatter-chart", name: "generalPages.scatter" },
  { id: chartTypes.BUBBLE_CHART, icon: "bubble-chart", name: "generalPages.bubble" },
  { id: chartTypes.GAUGE_CHART, icon: "gauge-chart", name: "generalPages.gauge" },
  { id: chartTypes.TABLE, icon: "table-chart", name: "generalPages.table" },
  { id: chartTypes.PIVOT_TABLE, icon: "pivot-table", name: "generalPages.pivot" },
  { id: chartTypes.SUMMARY, icon: "summary-chart", name: "generalPages.summary" },
  {
    id: chartTypes.RADAR_CHART,
    icon: "radar-chart",
    name: "generalPages.radar",
  },
  {
    id: chartTypes.GEO_MAP_CHART,
    icon: "geo-map-chart",
    name: "generalPages.geoMap",
  },
  {
    id: chartTypes.TAB_PANEL,
    icon: "tab-panel",
    name: "Tab Panel",
  },
];

export const dashboardFilterTypes = {
  FILTER_TEXT: "FILTER_TEXT",
  FILTER_DROPDOWN: "FILTER_DROPDOWN",
  FILTER_NUMBER: "FILTER_NUMBER",
  FILTER_DATE: "FILTER_DATE",
  FILTER_SELECT_LIST: "FILTER_SELECT_LIST",
  ADVANCED_BUTTON: "ADVANCED_BUTTON",
};
export const otherChartType = {
  BUTTON: "BUTTON",
};
export const notSearchServiceFilterTypes = [
  { value: dashboardFilterTypes.FILTER_TEXT },
  { value: dashboardFilterTypes.FILTER_NUMBER },
  { value: dashboardFilterTypes.FILTER_DATE },
];

export const chartWithNotSeeFilterIcon = [
  chartTypes.LOGO,
  chartTypes.LABEL,
  dashboardFilterTypes.FILTER_TEXT,
  dashboardFilterTypes.FILTER_DATE,
  otherChartType.BUTTON
];

export const otherList = () => {
  let check = process.env.VUE_APP_CALL_API
  return [
    { id: chartTypes.LABEL, icon: "text-filter", name: "generalPages.labelText" },
    { id: chartTypes.LOGO, icon: "logo-chart", name: "generalPages.images" },
    {
      id: otherChartType.BUTTON,
      icon: "button-filter",
      name: "generalPages.button",
    },
    check === "true" ?
      {
        id: dashboardFilterTypes.ADVANCED_BUTTON,
        icon: "button-filter",
        name: "generalPages.advancedButton",
      } : null,
  ].filter((x) => x);
};

export const filterList = [
  {
    id: dashboardFilterTypes.FILTER_TEXT,
    icon: "text-filter",
    name: "generalPages.textInput",
  },
  {
    id: dashboardFilterTypes.FILTER_DROPDOWN,
    icon: "dropdown-filter",
    name: "generalPages.dropDown",
  },
  // {
  //     id: dashboardFilterTypes.FILTER_NUMBER,
  //     icon: "slider-filter",
  //     name: ""
  // },
  {
    id: dashboardFilterTypes.FILTER_SELECT_LIST,
    icon: "selectList-filter",
    name: "generalPages.selectList",
  },
  {
    id: dashboardFilterTypes.FILTER_DATE,
    icon: "date-filter",
    name: "generalPages.calendar",
  },
];

export const logos = {
  CUMHURBASKANLIGI: "cumhurbaskanligi-logo",
  TICARETBAKANLIGI: "ticaret-bakanligi-logo",
  IS: "is-bankasi-logo",
  SOFTTECH: "softtech-logo",
  ANADOLU: "anadolu-sigorta-logo",
  ECOMMERCE: "e-comm-logo",
  ISYATIRIM: "is-yatirim-logo",
  HAVELSAN: "havelsan-logo",
  ISFAKTORING: "is-faktoring-logo",
  MOKA: "moka-logo",
  EMTIA: "emtia-logo",
  BORUSAN: "borusan-logo",
  EFESANGRUP: "efesan-grup-logo",
  KAYSERISEKER: "kayseri-seker-logo",
  AZERSUN: "azersun-logo",
  SOFTTECHSEMBOL: "softtech-sembol-logo"
};

export const defaultLogo = "default-logo";


export const BOX_KEY_ENUM = {
  MEASURES: { KEY: "measures", TEXT: "Measures" },
  SLICES: { KEY: "slices", TEXT: "Slices" },
  CITY: { KEY: "city", TEXT: "City" },
  DISTRICT: { KEY: "district", TEXT: "District" },
  COLUMN: { KEY: "column", TEXT: "Columns" },
  ROW: { KEY: "row", TEXT: "Rows" },
  POINTS: { KEY: "points", TEXT: "Points" },
  XAXIS: { KEY: "x-axis", TEXT: "X-Axis" },
  YAXIS: { KEY: "y-axis", TEXT: "Y-Axis" },
  POINT_SIZE: { KEY: "point_size", TEXT: "Point Size" },
  DATA_ITEM: { KEY: "data_item", TEXT: "Data Items" },
  BARS: { KEY: "bars", TEXT: "Bars" },
  LINES: { KEY: "lines", TEXT: "Lines" },
  MIN: { KEY: "min", TEXT: "Min" },
  MAX: { KEY: "max", TEXT: "Max" },
  BRANCHES: { KEY: "branches", TEXT: "Branches" },
  COLORBY: { KEY: "colorBy", TEXT: "Color By" },
  CUSTOM_FILTERS: { KEY: "customFilter", TEXT: "Custom Filters" },
  SECTION: { KEY: "section", TEXT: "Section" },
  FILTERS: { KEY: "filters", TEXT: "Filters" },
  ORDERS: { KEY: "orders", TEXT: "Orders" },
  TARGET: { KEY: "target", TEXT: "Target" },
};
const commonFilterChartRules = {
  maxMeasureCount: 1,
  maxSliceCount: 1,
  boxKeys: [BOX_KEY_ENUM.DATA_ITEM.KEY],
  isMetricRequired: true
}
export const chartRules = {
  [chartTypes.TREEMAP_CHART]: {
    maxMeasureCount: 1,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BRANCHES.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    isMetricRequired: true
  },
  [chartTypes.LINE_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.POINTS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.POINTS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.AREA_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.POINTS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.POINTS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.PIE]: {
    maxMeasureCount: 1,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.SLICES.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.SLICES.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.BAR]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.COLUMN_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.HEATMAP_CHART]: {
    maxMeasureCount: 1,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.COLUMN.KEY, BOX_KEY_ENUM.ROW.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    isMetricRequired: true
  },
  [chartTypes.SCATTER_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.POINTS.KEY, BOX_KEY_ENUM.XAXIS.KEY, BOX_KEY_ENUM.YAXIS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.XAXIS.KEY, BOX_KEY_ENUM.YAXIS.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.XAXIS.TEXT, BOX_KEY_ENUM.YAXIS.TEXT, BOX_KEY_ENUM.POINTS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT],
    isMetricRequired: false
  },
  [chartTypes.BUBBLE_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.POINTS.KEY, BOX_KEY_ENUM.XAXIS.KEY, BOX_KEY_ENUM.YAXIS.KEY, BOX_KEY_ENUM.POINT_SIZE.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.XAXIS.KEY, BOX_KEY_ENUM.YAXIS.KEY, BOX_KEY_ENUM.POINT_SIZE.TEXT],
    tooltipKeyOrder: [BOX_KEY_ENUM.XAXIS.TEXT, BOX_KEY_ENUM.YAXIS.TEXT, BOX_KEY_ENUM.POINTS.TEXT, BOX_KEY_ENUM.POINT_SIZE.TEXT, BOX_KEY_ENUM.COLORBY.TEXT],
    isMetricRequired: false
  },
  [chartTypes.COMBINATION_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.LINES.KEY, BOX_KEY_ENUM.SLICES.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.LINES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.SLICES.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.LINES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.GAUGE_CHART]: {
    maxMeasureCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.MIN.KEY, BOX_KEY_ENUM.MAX.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.MIN.KEY, BOX_KEY_ENUM.MAX.KEY],
    isMetricRequired: true
  },
  [chartTypes.RADAR_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.SLICES.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.SLICES.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.STACKED_COLUMN_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.STACKED_BAR_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.PIVOT_TABLE]: {
    maxMeasureCount: 20,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.COLUMN.KEY, BOX_KEY_ENUM.ROW.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.MEASURES.TEXT, BOX_KEY_ENUM.COLUMN.TEXT, BOX_KEY_ENUM.ROW.TEXT],
    isMetricRequired: true
  },
  [chartTypes.GEO_MAP_CHART]: {
    maxMeasureCount: 1,
    maxSliceCount: 2,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.CITY.KEY, BOX_KEY_ENUM.DISTRICT.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.MEASURES.TEXT, BOX_KEY_ENUM.CITY.TEXT, BOX_KEY_ENUM.DISTRICT.TEXT],
    isMetricRequired: true
  },
  [chartTypes.SUMMARY]: {
    maxMeasureCount: 2,
    maxSliceCount: 0,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.TARGET.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.TARGET.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.MEASURES.TEXT, BOX_KEY_ENUM.TARGET.KEY],
    isMetricRequired: true
  },
  [dashboardFilterTypes.FILTER_SELECT_LIST]: {
    ...commonFilterChartRules,
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE, DatamodelContextDefaults.USAGE_TYPES.DATE, DatamodelContextDefaults.USAGE_TYPES.MEASURE],
  },
  [dashboardFilterTypes.FILTER_DROPDOWN]: {
    ...commonFilterChartRules,
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE, DatamodelContextDefaults.USAGE_TYPES.MEASURE, DatamodelContextDefaults.USAGE_TYPES.DATE],
  },
  [dashboardFilterTypes.FILTER_TEXT]: {
    ...commonFilterChartRules,
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE, DatamodelContextDefaults.USAGE_TYPES.MEASURE, DatamodelContextDefaults.USAGE_TYPES.DATE],
  },
  [dashboardFilterTypes.FILTER_DATE]: {
    ...commonFilterChartRules,
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.DATE],
  },
};



export const checkIfDatasetFieldSame = (selectedPanel, field) => {
  const boxList = [
    ...selectedPanel.details.metrics,
    ...selectedPanel.details.aggregation,
  ];

  const hasDataset = boxList.find((x) => x.datasetId == field.datasetId);

  if (hasDataset || field.filterType == filterType.CUSTOM || !boxList.length) {
    return true;
  } else return false;
}

export const getAggregationField = (field, boxKey) => {
  return {
    field: field?.name,
    alias: field?.alias,
    size: 5,
    orderType: null,
    fieldType: field?.type,
    fieldUsageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
    format: field?.format,
    boxKey: boxKey,
    datasetId: field?.datasetId,
    fieldId: field?.fieldId,
    defaultAggFunction: field?.defaultAggFunction,
    drillPaths: field?.drillPaths,
    isDrillDownAgg: true
  }
}

export const getSelectedField = (fieldList, datasetId, fieldId) => {
  return fieldList[datasetId].find(x => x.fieldId == fieldId);
}

export const getFilteredPanelDataByAuthorize = ({chartData, unAuthorizedDashboardFields}) => {
  if (!chartData) return;
 
  const clonedChartData = cloneDeep(chartData);

  clonedChartData.details.metrics = getFilteredArrayByParam(clonedChartData.details.metrics, unAuthorizedDashboardFields);
  clonedChartData.details.aggregation = getFilteredArrayByParam(clonedChartData.details.aggregation, unAuthorizedDashboardFields);
  clonedChartData.details.columns = getFilteredArrayByParam(clonedChartData.details.columns, unAuthorizedDashboardFields);
  clonedChartData.details.filters = getFilteredArrayByParam( clonedChartData.details.filters, unAuthorizedDashboardFields);
  clonedChartData.details.params = getFilteredArrayByParam(clonedChartData.details.params, unAuthorizedDashboardFields);
  clonedChartData.details.sections = getFilteredArrayByParam(clonedChartData.details.sections, unAuthorizedDashboardFields);

  chartData.name = createSmartTitle(clonedChartData);
  
  return clonedChartData;
}

export const getDetailsDataByAuthorize = ({chartDataDetails, unAuthorizedDashboardFields}) => {
  const clonedDetails = cloneDeep(chartDataDetails);

  clonedDetails.metric = getFilteredArrayByParam(clonedDetails.metric, unAuthorizedDashboardFields);
  clonedDetails.aggregation = getFilteredArrayByParam(clonedDetails.aggregation, unAuthorizedDashboardFields);
  clonedDetails.columns = getFilteredArrayByParam(clonedDetails.columns, unAuthorizedDashboardFields);
  clonedDetails.filter = getFilteredArrayByParam(clonedDetails.filter, unAuthorizedDashboardFields);
  clonedDetails.params = getFilteredArrayByParam(clonedDetails.params, unAuthorizedDashboardFields);
  clonedDetails.sections = getFilteredArrayByParam(clonedDetails.sections, unAuthorizedDashboardFields);

  return clonedDetails;
}

const getFilteredArrayByParam = (filteredArray, unAuthorizedDashboardFields) => {
  if (!filteredArray) return;

  return filteredArray?.filter((x) => !unAuthorizedDashboardFields.has(x.fieldId));
}