<template>
  <div class="vis-flex--spacebeetwen-Ycenter vis-mobile-flex-direction-column">
    <el-tabs
      v-if="!this.currentFolderId && !isInSearch"
      v-model="activeTab"
      @tab-click="handleSelectTab"
    >
      <el-tab-pane
        v-for="tab in getTabList()"
        :key="tab.id"
        :label="$t(`home.${tab.localizationKey}`)"
        :name="tab.key"
        :disabled="activeTab===tab.key"
      ></el-tab-pane>
    </el-tabs>
    <!-- BREADCRUMB START -->
    <div v-if="currentFolderId" class="vis-flex--spacebeetwen-Ycenter vis-mb-0375">
      <ul class="vis-user-tabs vis-flex--Ycenter">
        <li @click="getAllProjects" class="vis-main-breadcrumb vis-ml--none">
          {{ $t("home.all") }}
        </li>
        <div
          v-for="folder in folderNameList"
          :key="folder.id"
          class="vis-flex--Ycenter"
        >
          <li class="vis-main-breadcrumb">
            <i aria-hidden="true" :class="CustomIcon.ExpandRight"></i>
          </li>
          <li
            @click="$emit('viewFolder', folder.id, folder.name)"
            :class="{ 'vis-font-medium': currentFolderId == folder.id }"
            class="vis-main-breadcrumb"
          >
            {{ folder.name }}
          </li>
        </div>
      </ul>
    </div>
    <!--/ BREADCRUMB END -->
    <!-- LISTED ITEMS BAR START-->
    <div v-if="isInSearch" class="vis-flex--spacebeetwen-Ycenter">
      <span class="el-tabs__item vis-search-tab-label">
        {{ $t("generalPages.Search Results") + " " + 
        $t("generalPages.Listing") + " " +
        paginationResult.numberOfElements + " " + $t("generalPages.Items")
        }}
      </span>
    </div>
    <!-- / LISTED ITEMS END -->
    <div class="vis-user-tabs-iconbox vis-flex--Ycenter">
      <!-- MULTIPLE SELECTED START -->
      <div class="vis-flex--Ycenter" v-if="isActiveBulkDelete">
        <span class="vis-font-medium"
          >{{ selectedProjectsLength }} {{ $t("home.selectedObject") }}</span
        >
        <el-popover
          placement="bottom-start"
          trigger="click"
          popper-class="vis-dropdown-popper"
        >
          <div class="vis-dropdown">
            <ul>
              <li
                v-for="item in multipleSelectDropDownList()"
                :key="item.id"
                @click="item.serviceFn"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
          <i
            slot="reference"
            aria-hidden="true"
            :class="CustomIcon.VerticalThreeDot"
            class="vis-mr--1 vis-ml--none vis-vertical-align-middle"
          />
        </el-popover>
      </div>
      <!-- MULTIPLE SELECTED END -->
      <!-- SEARCH -->
      <div class="vis-position-relative">
        <el-input
          v-if="isSearchEnabled"
          v-model="searchDataValue"
          size="mini"
          :placeholder="$t('filterComponents.typeToSeach')"
          @keyup.enter.native="searchProjectList(HOMEPAGE_SEARH_PARAMS.SEARCH_KEY)"
        />
        <i :class="CustomIcon.Search" class="vis-input-icon" aria-hidden="true" @click="handleSearchVisibility" />
      </div>
      <!-- SEARCH END -->
      <!-- ADD FOLDER START -->
      <i
        v-if="isCreateFolderAvailable && isCreateFolderAuthorized && !isInSearch"
        @click="$emit('openNewFolderPopup')"
        aria-hidden="true"
        :class="CustomIcon.AddFolder"
      />
      <!-- / ADD FOLDER END -->
      <!-- FILTER -->
      <el-popover
        v-click-outside="() => handleClickOutside()"
        placement="bottom-start"
        trigger="manual"
        popper-class="vis-dropdown-popper vis-dropdown-body-close"
        :value="isFilterClicked"
      >
        <div class="vis-dropdown">
          <ul>
            <li
              v-for="model in filterByType"
              class="vis-flex--spacebeetwen"
              :key="model.id"
              @click="searchByType(model)"
            >
              <span>{{
                $t(`${model.label}`)
              }}</span>
              <i
                v-if="selectedType === model.id"
                aria-hidden="true"
                :class="CustomIcon.Checked"
                class="vis-text--xsmall"
              />
            </li>
          </ul>
        </div>
        <i
          v-show="isInSearch"
          slot="reference"
          aria-hidden="true"
          :id="filterByTypeEnum"
          :class="[
            {[CustomIcon.FilterAltOutlined] : !isFilterSelected},
            {[CustomIcon.FilterAlt]: isFilterSelected},
            {'vis-color-primary': isFilterSelected},
          ]"
          @click.stop="(e) => handlePopoverActivity(e)"
        />
      </el-popover>
      <!-- FILTER END -->
      <!-- SORT -->
      <el-popover
        v-if="isSortAvailable"
        v-click-outside="() => handleClickOutside()"
        placement="bottom"
        trigger="manual"
        popper-class="vis-dropdown-popper vis-dropdown-body-close vis-sort-dropdown-ml--15"
        :value="isSortClicked"
      >
        <div class="vis-dropdown vis-sort-dropdown-min-width">
          <ul>
            <li
              @click="sortByProjectList(sort)"
              v-for="sort in orderBySortList"
              :key="sort.id"
              class="vis-flex--spacebeetwen"
            >
              <span :class="activeSortItemClass(sort)">{{
                $t(`${sort.label}`)
              }}</span>
              <i
                v-if="sort.id == activeSortItemId"
                :class="[
                  CustomIcon.ArrowRight,
                  activeArrowIconClass(sort.type),
                ]"
                class="vis-arrow-icon-down vis-mr--none vis-color-primary"
                :aria-hidden="true"
              ></i>
            </li>
          </ul>
        </div>
        <i 
          slot="reference" 
          aria-hidden="true" 
          :class="CustomIcon.SortBy" 
          :id="sortByEnum"
          @click.stop="(e) => handlePopoverActivity(e)"
        />
      </el-popover>
      <!-- SORT END -->

      <i
        @click="$emit('changeViewCardOrTable')"
        aria-hidden="true"
        :class="iconName.changeViewIcon"
      />
      <i
        v-if="isMaximizeAvailable && !isInSearch"
        @click="$emit('changeResize')"
        aria-hidden="true"
        :class="iconName.resizeIcon"
      />
      <i
        v-if="isInSearch"
        @click="closeSearchHandler()"
        aria-hidden="true"
        :class="iconName.closeIcon"
      />
    </div>
  </div>
</template>
<script>
import uuid from "uuid";
import { CustomIcon } from "../../assets/js/custom-icons";
import ClickOutside from 'vue-click-outside';
import {
  HOME_TABS_KEY,
  HOME_TABS_SORT_LIST,
  HOME_TABS_FILTER_BY_TYPE,
  SORT_BY,
  getHomeTabListByAuthority,
  getHomeScenarioTabListByAuthority,
  HOMEPAGE_SEARH_PARAMS,
} from "../../commons/homeDataTypes";

export default {
  directives: {
    ClickOutside,
  },
  props: {
    isModuleVisualize: {
      type: Boolean,
      default: true,
    },
    isSearchAvailable: {
      type: Boolean,
      default: true,
    },
    isSortAvailable: {
      type: Boolean,
      default: true,
    },
    isCreateFolderAvailable: {
      type: Boolean,
      default: true,
    },
    isCreateFolderAuthorized: {
      type: Boolean,
      default: false,
    },
    isMaximizeAvailable: {
      type: Boolean,
      default: true,
    },
    iconName: {
      type: Object,
    },
    selectedProjectsLength: {
      type: Number,
    },
    currentFolderId: {
      type: String,
    },
    folderNameList: {
      type: Array,
    },
    isActiveBulkDelete: {
      type: Boolean,
      default: false,
    },
    forDataFlowDoesUserHaveEditRoles: {
      type: Boolean,
      default: false,
    },
    homePageSearchQueryData: {
      type: Array,
    },
    isInSearch: {
      type: Boolean,
      default: false,
    },
    showCardOrTable: {
      type: Boolean,
      default: false,
    },
    paginationResult: {
      type: Object,
    }
  },
  data() {
    return {
      activeTab: HOME_TABS_KEY.ALL,
      orderBySortList: HOME_TABS_SORT_LIST,
      filterByType: HOME_TABS_FILTER_BY_TYPE,
      projectName: "",
      SORT_BY: SORT_BY,
      activeSortItemId: null,
      CustomIcon: CustomIcon,
      HOMEPAGE_SEARH_PARAMS: HOMEPAGE_SEARH_PARAMS,
      searchDataValue: "",
      isSearchEnabled: false,
      selectedType: "",
      isFilterClicked: false,
      isFilterSelected: false,
      isSortClicked: false,
      filterByTypeEnum: "filterByType",
      sortByEnum: "sortBy",
    };
  },
  methods: {
    handleClickOutside() {
      this.isFilterClicked = false;
      this.isSortClicked = false;
    },
    handlePopoverActivity(e) {
      if(e.target.id === this.filterByTypeEnum) {
        this.isFilterClicked = !this.isFilterClicked;
        this.isSortClicked = false;
      } else  if(e.target.id === this.sortByEnum) {
        this.isSortClicked = !this.isSortClicked;
        this.isFilterClicked = false;
      }
    },
    handleSearchVisibility() {
      this.isSearchEnabled = !this.isSearchEnabled;
    },
    isSearchingWithDifferentKey(val, type){
      return this.homePageSearchQueryData?.find((x) => x?.key === type ? true : false);
    },
    changeViewCardOrTable() {
      if(!this.isInSearch && this.showCardOrTable) {
        this.$emit('changeViewCardOrTable');
        }
    },
    searchProjectList(type){
      if(this.searchDataValue.length > 2 && this.isSearchingWithDifferentKey(this.searchDataValue, type)) {
        this.$emit('resetHomepageSearchParameters')
        this.isFilterSelected = false;
        this.selectedType = "";
        this.$emit('handleSearchQueryData', {val: this.searchDataValue, type: type});
        this.$emit('handleSearchQueryData', {val: "", type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID});
        this.$emit('searchProjectList');
        this.changeViewCardOrTable();
        this.$emit('handleIsInSearch', true);
      }
    },
    getTabList() {
      if (this.isModuleVisualize) {
        return getHomeTabListByAuthority({
          editDataFlow: this.forDataFlowDoesUserHaveEditRoles,
        });
      } else {
        return getHomeScenarioTabListByAuthority();
      }
    },
    handleSelectTab() {
      this.$emit("tabChanged", this.activeTab);
    },
    sortByProjectList(sort) {
      this.activeSortItemId = sort.id;
      sort.type == SORT_BY.ASC
        ? (sort.type = SORT_BY.DESC)
        : (sort.type = SORT_BY.ASC);
      this.$emit('resetHomepageSearch');
      this.$emit('handleSearchQueryData', {val:`${sort?.value},${sort?.type}`, type: HOMEPAGE_SEARH_PARAMS.SORT_PARAMS})
      this.$emit('searchProjectList')
    },
    resetSearchDataValue(){
      this.searchDataValue = "";
    },
    resetTabValue(){
      this.activeTab = HOME_TABS_KEY.ALL;
    },
    searchByType(model) {
      this.changeViewCardOrTable();
      this.$emit('handleIsInSearch', true);
      this.$emit('resetHomepageSearch');
      if(this.selectedType === model.id) {
        this.selectedType = "";
        this.$emit('handleSearchQueryData', {val: null, type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE})
        this.isFilterSelected = false;
      } else {
        this.selectedType = model.id;
        this.$emit('handleSearchQueryData', {val: model.type.toUpperCase(), type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE})
        this.isFilterSelected = true;
      }

      this.$emit('searchProjectList')
    },
    closeSearchHandler() {
      this.$emit('closeHomePageSearch');
      this.resetTabValue()
      this.resetSearchDataValue();
    },
    getAllProjects() {
      this.resetTabValue()
      this.$emit("getAllProjects");
    },
    activeArrowIconClass(sortType) {
      return sortType == SORT_BY.DESC ? "vis-arrow-icon-up" : "";
    },
    activeSortItemClass(item) {
      return item.type && item.id == this.activeSortItemId
        ? "vis-font-bold"
        : "";
    },
    multipleSelectDropDownList() {
      return [
        {
          id: uuid(),
          label: this.$t("generalPages.delete"),
          serviceFn: () => this.$emit("deleteSelectedProjects"),
          isActive: true,
        },
        {
          id: uuid(),
          label: this.$t("generalPages.move"),
          serviceFn: () => this.$emit("moveMultipleProjects"),
          isActive: false,
        },
      ];
    },
  },
};
</script>
<style scoped>
::v-deep .el-tabs {
  max-width: 70%;
  z-index: 0;
}
::v-deep .el-tabs__nav-wrap::after {
  display: none !important;
}
::v-deep .el-tabs__item {
  padding: 0 25px;
  font-size: 0.75rem;
  color: var(--secondary-text);
  font-weight: normal;
  margin-bottom: 6px;
}
::v-deep .el-tabs__item:hover {
  color: var(--secondary-text);
}
::v-deep .el-tabs__item.is-active {
  color: var(--secondary-text);
  font-weight: 600;
}
::v-deep .el-tabs__header {
  margin: 0 !important;
}
::v-deep .el-tabs__active-bar {
  background-color: var(--primary);
}
.vis-arrow-icon-down {
  transform: rotate(90deg);
  transition: all 0.3s ease;
}
.vis-arrow-icon-up {
  transform: rotate(-90deg);
}
.vis-vertical-align-middle {
  vertical-align: middle;
}
.vis-ml--none {
  margin-left: 0 !important;
}
.vis-sort-dropdown-ml--15 {
  margin-left: -15px !important
}
.vis-sort-dropdown-min-width {
  min-width: 163px !important;
}
@media only screen and (max-width: 1199px) {
  ::v-deep .el-tabs {
    max-width: 62% !important;
  }
  .vis-user-tabs-iconbox .vis-icon {
    margin-left: 15px !important;
  }
}
</style>
