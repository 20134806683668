<template>
  <div id="color-selector">
    <div
      v-for="palette in palettes"
      :key="palette.name"
      class="palette-container"
      :class="{ selected: selectedPalette.name === palette.name }"
      @click="handleSelect(palette)"
    >
      <div class="vis-flex--spacebeetwen-Ycenter">
        <div class="vis-btn">
          {{
            (!palette.id
              ? $t("panelProperties.colorPalette.customPalette")
              : "") +
            " " +
            palette.name
          }}
        </div>
        <el-button
          v-if="palette.id"
          type="text"
          @click="customizePalette(palette)"
        >
          {{ $t("panelProperties.colorPalette.customize") }}
        </el-button>
        <el-button v-else type="text" @click="(e) => removePalette(e, palette)">
          {{ $t("panelProperties.colorPalette.remove") }}
        </el-button>
      </div>

      <draggable
        tag="div"
        :ref="palette.name + '_draggable'"
        :list="[...(palette.paletteColors || palette.colors)]"
        class="vis-row vis-mt--05"
        @change="(e) => handleDragDrop(e, palette)"
      >
        <div
          v-for="(color, index) in palette.paletteColors || palette.colors"
          :key="color + index"
          @click="changeClearButtonToDeleteButton(palette, color)"
        >
          <el-color-picker
            :value="color"
            :predefine="predefineColorsArr"
            @change="(value) => setColor(value, index)"
          ></el-color-picker>
        </div>
        <el-color-picker
          v-if="checkColorCount(selectedPalette)"
          class="add-new-color"
          value="#fff"
          :predefine="predefineColorsArr"
          @change="addNewColor"
        ></el-color-picker>
      </draggable>
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../../assets/js/custom-icons";
import { predefineColorsArr } from "../../../commons/dashboardProperties";
import draggable from "vuedraggable";
import { ColorPalette } from "../../../commons/chartOptions";
import cloneDeep from "clone-deep";

export default {
  components: { draggable },
  data() {
    return {
      predefineColorsArr,
      customIcons: CustomIcon,
      selectedPalette: new ColorPalette(),
      isColorDeleted: null,
      selectedColor: null,
    };
  },
  props: {
    value: {
      type: Object,
      default: () => new ColorPalette(),
    },
    palettes: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.selectedPalette = { ...this.value };
    //1-2 sürüm sonrası bu kısım silinebilir, amaç eski colors yapısını paletteColors için güncellemek
    this.palettes.forEach((palette) => {
      if ("colors" in palette) {
        palette.paletteColors = cloneDeep(palette.colors);
        delete palette.colors;
      }
    });
  },
  computed: {
    customPalettes() {
      return this.palettes.filter((i) => !i.id);
    },
  },
  methods: {
    handleSelect(palette) {
      this.selectedPalette = palette;
      this.setComponentStyle();
    },
    checkColorCount(data) {
      //Eski yapıdan color parametresi gidene kadar mounteda almıyorum!(mounted da tetiklenmiyor!)
      return (
        data?.paletteColors?.length < 16
      );
    },
    addBlankPalette(paletteColors = []) {
      const newPaletteName = this.getNewCustomTemplateName();
      const newPalette = {
        id: 0,
        name: newPaletteName,
        paletteColors: [...paletteColors],
      };

      this.saveNewPalette(newPalette);
      this.selectedPalette = newPalette;
      this.setComponentStyle();
    },
    setColor(value, index) {
      if (!this.isColorDeleted) {
        if (this.selectedPalette.id) {
          const paletteColors = cloneDeep(this.selectedPalette.paletteColors);
          paletteColors[index] = value;
          this.addBlankPalette(paletteColors);
        } else {
          this.selectedPalette.paletteColors[index] = value;
          this.setComponentStyle();
          this.setDashboardStyle(this.customPalettes);
        }
      }
    },
    handleDragDrop(event, palette) {
      const updatedList = this.$refs[palette?.name + "_draggable"]?.[0]?.list;

      if (!event?.moved || !updatedList) return;

      if (palette.id) {
        this.addBlankPalette(updatedList);
      } else {
        palette.paletteColors = updatedList;
        this.handleSelect(palette);
        this.setDashboardStyle(this.customPalettes);
      }
    },
    customizePalette(palette) {
      this.addBlankPalette(palette.paletteColors);
    },
    getNewCustomTemplateName() {
      const paletteNames = this.palettes.map((i) => i.name);
      let newPaletteName = "";

      paletteNames.forEach((name, index) => {
        const newTempName = index + 1;

        if (!newPaletteName && !paletteNames.includes(newTempName)) {
          newPaletteName = newTempName;
        }
      });

      return newPaletteName;
    },
    addNewColor(newColor) {
      if (this.selectedPalette.id) {
        const paletteColors = cloneDeep(this.selectedPalette.paletteColors);
        paletteColors.push(newColor);
        this.addBlankPalette(paletteColors);
      } else {
        this.selectedPalette.paletteColors.push(newColor);
        this.setDashboardStyle(this.customPalettes);
      }
    },
    saveNewPalette(newCustomPalette) {
      const customPalettes = this.palettes.filter((i) => i.id === 0);
      customPalettes.unshift(newCustomPalette);

      this.setDashboardStyle(customPalettes);
    },
    removePalette(e, palette) {
      e.stopPropagation();

      let removedIndex = 0;

      const customPalettes = this.palettes.filter((i, index) => {
        if (i.name === palette.name) removedIndex = index;

        return i.id === 0 && i.name !== palette.name;
      });

      this.selectedPalette = this.palettes[removedIndex + 1];

      this.setComponentStyle();
      this.setDashboardStyle(customPalettes);
    },
    setComponentStyle() {
      this.$emit("setComponentStyle", this.selectedPalette);
    },
    setDashboardStyle(customPalettes) {
      this.$emit("setDashboardStyle", customPalettes);
    },
    changeClearButtonToDeleteButton(palette, color) {
      this.selectedPalette = palette;
      this.selectedColor = color;
      const clearButtons = document.querySelectorAll(
        ".el-button.el-color-dropdown__link-btn.el-button--text.el-button--mini"
      );
      for (let h = 0; h < clearButtons?.length; h++) {
        clearButtons[h].querySelector("span").textContent = this.$t(
          "generalPages.delete"
        );
        clearButtons[h].addEventListener("mousedown", () => {
          this.deleteSelectedColor();
        });
      }
    },
    deleteSelectedColor() {
      this.isColorDeleted = true;
      if (this.selectedPalette?.id) {
        let paletteColors = this.selectedPalette.paletteColors;
        paletteColors = paletteColors.filter(
          (element) => element !== this.selectedColor
        );
        return this.addBlankPalette(paletteColors);
      } else {
        this.selectedPalette.paletteColors =
          this.selectedPalette.paletteColors.filter(
            (element) => element !== this.selectedColor
          );
        this.setDashboardStyle(this.customPalettes);
      }
    },
  },
};
</script>
<style>
#color-selector .header {
  padding: 0 20px;
}

#color-selector .el-color-picker {
  height: 28px !important;
  margin-right: 5px;
}

#color-selector .el-color-picker__trigger {
  width: 28px !important;
  height: 28px !important;
}

#color-selector .el-color-picker__icon::before {
  content: "";
}

#color-selector .el-color-picker__trigger {
  cursor: move;
}

#color-selector .add-palette-icon {
  margin-left: 5px;
}

#color-selector .add-new-color .el-color-picker__icon::before {
  color: var(--primary);
  font-size: 1rem;
  content: "\002B";
  display: block;
  height: 20px;
  margin: 0px 8px;
}

#color-selector .palette-container {
  border: 1.5px solid transparent;
  border-bottom-color: var(--dataset-card-border-color);
  padding: 12px;
  cursor: pointer;
}

#color-selector .palette-container:last-child {
  border-bottom: 0px;
}

#color-selector .palette-container.selected {
  border: 1.5px solid var(--primary);
}
</style>
