import { defaultColorPalette } from "../commons/chartOptions"
import { getItemPercent, legendPositionEnums } from "../commons/dashboardProperties"

export const LINE_CHART_DEFAULT_CONFIGURATIONS = {
    LINE_CHART_SHOW_VALUE_LABEL: true,
    LINE_CHART_SHOW_ONLY: getItemPercent(),
    LINE_CHART_SHOW_INSIGHT: true,
    LINE_CHART_SHOW_SQL: false,
    LINE_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins",
    LINE_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
    LINE_CHART_VALUE_LABEL_FONT_SIZE: 12,
    LINE_CHART_VALUE_LABEL_ROTATE: 0,
    LINE_CHART_VALUE_LABEL_FONT_COLOR: "#000",
    LINE_CHART_SHOW_LEGEND: false,
    LINE_CHART_LEGEND_MAX_CHARACTER: 15,
    LINE_CHART_LEGEND_POSITION: legendPositionEnums.TOP_CENTER,
    LINE_CHART_LEGEND_VERTICAL_ALIGN: "top",
    LINE_CHART_LEGEND_FONT_FAMILY: "Poppins",
    LINE_CHART_LEGEND_FONT_WEIGHT: 500,
    LINE_CHART_LEGEND_FONT_SIZE: 12,
    LINE_CHART_LEGEND_FONT_COLOR: "#000",
    LINE_CHART_SHOW_Y_AXIS_NAME: true,
    LINE_CHART_Y_AXIS_NAME_FONT_FAMILY: "Poppins",
    LINE_CHART_Y_AXIS_NAME_FONT_WEIGHT: 500,
    LINE_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN: "left",
    LINE_CHART_Y_AXIS_NAME_VERTICAL_ALIGN: "bottom",
    LINE_CHART_Y_AXIS_NAME_FONT_SIZE: 12,
    LINE_CHART_Y_AXIS_NAME_FONT_COLOR: "#000",
    LINE_CHART_SHOW_X_AXIS_LINE: true,
    LINE_CHART_SHOW_X_AXIS_NAME: true,
    LINE_CHART_X_AXIS_NAME_FONT_FAMILY: "Poppins",
    LINE_CHART_X_AXIS_NAME_FONT_WEIGHT: 500,
    LINE_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN: "center",
    LINE_CHART_X_AXIS_NAME_VERTICAL_ALIGN: "top",
    LINE_CHART_X_AXIS_NAME_FONT_SIZE: 12,
    LINE_CHART_X_AXIS_NAME_FONT_COLOR: "#000",
    LINE_CHART_X_AXIS_NAME_TEXT: "",
    LINE_CHART_SHOW_Y_AXIS_LABEL: true,
    LINE_CHART_Y_AXIS_LABEL_FONT_FAMILY: "Poppins",
    LINE_CHART_Y_AXIS_LABEL_FONT_WEIGHT: 500,
    LINE_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN: "left",
    LINE_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN: "middle",
    LINE_CHART_Y_AXIS_LABEL_FONT_SIZE: 12,
    LINE_CHART_Y_AXIS_LABEL_FONT_COLOR: "#000",
    LINE_CHART_Y_AXIS_NAME_TEXT: "",
    LINE_CHART_SHOW_X_AXIS_LABEL: true,
    LINE_CHART_X_AXIS_LABEL_FONT_FAMILY: "Poppins",
    LINE_CHART_X_AXIS_LABEL_FONT_WEIGHT: 500,
    LINE_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN: "center",
    LINE_CHART_X_AXIS_LABEL_VERTICAL_ALIGN: "top",
    LINE_CHART_X_AXIS_LABEL_FONT_SIZE: 12,
    LINE_CHART_X_AXIS_LABEL_FONT_COLOR: "#000",
    LINE_CHART_X_AXIS_GRID_LINE_COLOR: "#e0e6f1",
    LINE_CHART_X_AXIS_LABEL_ROTATE: 0,
    LINE_CHART_X_AXIS_LABEL_MAX_CHARACTER: 10,
    LINE_CHART_Y_AXIS_LOGARITHMIC_SCALE: false,
    LINE_CHART_Y_AXIS_MIN: null,
    LINE_CHART_Y_AXIS_POSITION: "left",
    LINE_CHART_Y_AXIS_SHOW_LINE: false,
    LINE_CHART_Y_AXIS_LINE_STYLE_COLOR: "#000",
    LINE_CHART_Y_AXIS_NAME_LOCATION: "middle",
    LINE_CHART_HIDE_OVERLAPPED_VALUE_LABELS: true,
    LINE_CHART_SELECTED_COLOR_PALETTE: defaultColorPalette,
    LINE_CHART_AUTO_REFRESH: 0,
    LINE_CHART_AUTO_REFRESH_CUSTOM: null,
    LINE_CHART_DYNAMIC_TITLE_VALUE: "",
    LINE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    LINE_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
    LINE_CHART_GROUP_MULTIPLE_VALUES: true,
    LINE_CHART_ALLOW_DRILL_THROUGH: true
}
