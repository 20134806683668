<template>
  <div
    v-if="isDialogActive && isButtonActionValueDialog"
    :class="{ 'vis-button-fixed-position': !isChartEditView }"
    @click.stop="$emit('closeButtonDialog')"
  >
    <div @click.stop class="vis-button-dialog" :style="buttonDialogCardStyle">
      <div class="vis-button-dialog-header">
        <div class="vis-button-dialog-title">
          <div v-if="isActiveTitle" :style="titleStyle">{{ title }}</div>
          <div v-if="isActiveSubTitle" :style="subTitleStyle">
            {{ subTitle }}
          </div>
        </div>
        <i
          v-if="!isChartEditView"
          @click="$emit('closeButtonDialog')"
          :class="customIcon.Close"
          class="vis-cursor-pointer vis-font-medium"
          aria-hidden="true"
        ></i>
      </div>
      <!-- CONTENT START -->
      <div
        class="vis-button-dialog-content vis-scroll-thin"
        :style="buttonDialogContentStyle"
      >
        <div v-if="isContentWrapTextActive" :style="contentStyle">
          {{ content }}
        </div>
        <pre v-else :style="contentStyle">{{ content }}</pre>
      </div>
      <!-- CONTENT END -->
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import {
  BUTTON_PROPERTIES_ENUM,
  buttonSelectionValues,
} from "../../commons/dashboardProperties";
import panelDataPropertiesStyle from "../../mixins/panelDataPropertiesStyle";
export default {
  mixins: [panelDataPropertiesStyle],
  props: {
    panelData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPositionFixed: {
      type: Boolean,
      default: true,
    },
    isDialogActive: {
      type: Boolean,
      default: false,
    },
    isChartEditView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  computed: {
    isButtonActionValueDialog() {
      return (
        this.CHART_COMPUTED_VALUES?.[
          BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_SELECT_VALUE
        ] === buttonSelectionValues.DIAOLOG
      );
    },
    title() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE
      ];
    },
    subTitle() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUBTITLE
      ];
    },
    content() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT
      ];
    },
    titleStyle() {
      return {
        color:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_COLOR
          ],
        "font-family":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_FAMILY
          ],
        "font-weight":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_WEIGHT
          ],
        "font-size":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_SIZE
          ] + "px  !important",
        "text-align":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_ALIGN
          ],
      };
    },
    subTitleStyle() {
      return {
        color:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_COLOR
          ],
        "font-family":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY
          ],
        "font-weight":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT
          ],
        "font-size":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_SIZE
          ] + "px  !important",
        "text-align":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_ALIGN
          ],
      };
    },
    contentStyle() {
      return {
        color:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_COLOR
          ],
        "font-family":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_FAMILY
          ],
        "font-weight":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_WEIGHT
          ],
        "font-size":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_SIZE
          ] + "px  !important",
        "text-align":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_ALIGN
          ],
      };
    },
    buttonDialogContentStyle() {
      return {
        width:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_WIDTH
          ] + "px",
        height:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_HEIGHT
          ] + "px",
      };
    },
    buttonDialogCardStyle() {
      return {
        border: this.CHART_COMPUTED_VALUES?.[
          BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_BORDER
        ]
          ? "1px solid"
          : "none",
        borderColor:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_COLOR
          ],
        "border-radius":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_RADIUS
          ] + "px",
        "background-color":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BACKGROUND_COLOR
          ],
        width:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_WIDTH
          ] + "px",
      };
    },
    isActiveTitle() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE
      ];
    },
    isActiveSubTitle() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_SUB_TITLE
      ];
    },
    isContentWrapTextActive() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_WRAP_TEXT
      ];
    },
  },
};
</script>

<style scoped>
.vis-button-dialog {
  position: relative;
  max-width: 800px;
  max-height: 800px;
  width: auto;
  height: auto;
  box-shadow: 0px 3px 6px #00002929;
  animation-name: fromAboveAnimation;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
}
.vis-button-dialog-header {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.vis-button-dialog-title {
  position: relative;
  width: 95%;
}
.vis-button-dialog-title div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vis-button-dialog-content {
  padding: 10px 20px;
  overflow-wrap: break-word;
}
.vis-button-fixed-position {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.5);
}
</style>
