<template>
  <div class="vis-vgt-card vis-field-popup">
    <div class="vis-vgt-card-title vis-flex--center">
      <span class="vis-font-medium">
        {{ $t("generalPages.updateField") }} -
        {{ this.field.alias }}
      </span>
      <i
        class="mdi mdi-close vis-cursor-pointer close-icon"
        aria-hidden="true"
        @click="$emit('closeBackgroundPopup', 0)"
      ></i>
    </div>
    <div class="vis-field-popup-content">
      <div class="vis-row-box vis-flex--spacebeetwen-Ycenter">
        <label>{{ $t("panelProperties.sectionBackgroundColor") }}</label>
        <el-color-picker
          :value="
            this.field.sectionBackgroundColor
              ? this.field.sectionBackgroundColor
              : color
          "
          :predefine="predefineColorsArr"
          @change="(value) => setBackground('sectionBackgroundColor', value)"
          show-alpha
        ></el-color-picker>
      </div>
      <div class="vis-row-box vis-flex--spacebeetwen-Ycenter">
        <label>{{ $t("panelProperties.subtotalBackgroundColor") }}</label>
        <el-color-picker
          :value="
            this.field.subtotalBackgroundColor
              ? this.field.subtotalBackgroundColor
              : color
          "
          :predefine="predefineColorsArr"
          @change="(value) => setBackground('subtotalBackgroundColor', value)"
          show-alpha
        ></el-color-picker>
      </div>

      <div class="vis-row-box vis-text--right">
        <el-button
          @click="saveField()"
          class="vis-mt--2"
          type="primary"
          size="small"
          >{{ $t("generalPages.save") }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import cloneDeep from "clone-deep";
import { predefineColorsArr } from "../../../commons/dashboardProperties";

export default {
  props: {
    selectedField: {
      type: Object,
    },
  },
  data() {
    return {
      field: {},
      color: "",
      predefineColorsArr,
    };
  },
  mounted() {
    if (!this.selectedField["sectionBackgroundColor"]) {
      this.selectedField["sectionBackgroundColor"] = "";
    }
    if (!this.selectedField["subtotalBackgroundColor"]) {
      this.selectedField["subtotalBackgroundColor"] = "";
    }

    this.field = cloneDeep(this.selectedField);
  },
  methods: {
    saveField() {
      this.selectedField["sectionBackgroundColor"] =
        this.field["sectionBackgroundColor"];

      this.selectedField["subtotalBackgroundColor"] =
        this.field["subtotalBackgroundColor"];

      this.$emit("closeBackgroundPopup", 0);
    },
    setBackground(element, value) {
      this.field[element] = value;
    },
  },
};
</script>
<style scoped>
.vis-field-popup {
  width: 280px;
}
.vis-field-popup-content {
  position: relative;
  width: 100%;
  padding: 10px 14px;
  margin: 0;
}
.close-icon {
  margin-left: auto;
  font-size: 1rem;
}
</style>
