<template>
  <div class="palettes">
    <div class="vis-flex--spacebeetwen-Ycenter">
      <span>{{
        $t(
          "admin.palettes.Manage common color palettes These palettes will be shown as list of palettes in charts"
        )
      }}</span>

      <el-button
        @click="addPalette"
        class="vis-cursor-pointer el-button--with-icon"
        type="text"
      >
        <i aria-hidden="true" :class="customIcon.AddBox"></i
        >{{ $t("admin.palettes.Add palette") }}</el-button
      >
    </div>
    <div
      class="vis-row palette-cards vgt-responsive"
      v-loading="isColorPaletteDataLoading"
    >
      <div
        class="palette-card vis-col vis-col-3"
        v-for="palette in palettes"
        :key="palette.id"
      >
        <div class="palette-card-header">
          <input
            :id="palette.name"
            class="vis-palette-input"
            :readonly="!isShowEditModePalette(showEditIcons, palette.id)"
            :value="palette.name"
            tabindex="-1"
            type="text"
            autocomplete="off"
            @input="updatePaletteTitle(palette, $event)"
          />
          <div>
            <div v-if="isShowEditModePalette(showEditIcons, palette.id)">
              <i
                :class="`${customIcon.SaveOutlined} vis-cursor-pointer vis-mr--05`"
                aria-hidden="true"
                @click="savePalette(palette)"
              ></i>
              <i
                :class="`${customIcon.Close} vis-cursor-pointer vis-mr--05`"
                aria-hidden="true"
                @click="closeTheEdit(palette)"
              ></i>
            </div>
            <div v-else>
              <i
                :class="`${customIcon.PencilOutline} vis-cursor-pointer vis-mr--05`"
                aria-hidden="true"
                @click="openTheEdit(palette)"
              ></i>
              <i
                :class="`${customIcon.Delete} vis-cursor-pointer vis-mr--05`"
                aria-hidden="true"
                @click="deleteActionClicked(palette)"
              ></i>
            </div>
          </div>
        </div>
        <div class="palette-colors">
          <div
            v-for="(color, colorIndex) in palette.paletteColors"
            :key="colorIndex"
            @click="changeClearButtonToDeleteButton(palette, colorIndex)"
          >
            <el-color-picker
              :predefine="predefineColorsArr"
              :disabled="!isShowEditModePalette(showEditIcons, palette.id)"
              v-model="palette.paletteColors[colorIndex]"
            ></el-color-picker>
          </div>
          <el-color-picker
            v-if="checkColorCount(palette)"
            value="#fff"
            class="add-new-color"
            :predefine="predefineColorsArr"
            @change="addNewColor"
          >
            <i :class="`${customIcon.Plus}`" aria-hidden="true"></i
          ></el-color-picker>
        </div>
      </div>
      <div class="add-palette-card vis-col vis-col-3" @click="addPalette">
        <div class="add-color-button">
          <i :class="`${customIcon.Plus}`" aria-hidden="true"></i>
        </div>
        <span class="vis-mt--1">{{ $t("admin.palettes.Add palette") }}</span>
      </div>
    </div>
    <ConfirmDialog
      :title="$t('dialog.deletePaletteTitle')"
      :message="$t('dialog.deletePaletteMessage')"
      :dialogVisible="dialogVisible"
      @confirm="deletePalette"
      @cancel="dialogVisible = $event"
    />
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import ConfirmDialog from "../helper/ConfirmDialog.vue";
import {
  ACTION as ACTION_COLOR_PALETTE,
  GETTER as GETTER_COLOR_PALETTE,
} from "../../store/modules/Visualize/ColorPalette/types";
import { mapActions, mapGetters } from "vuex";
import { predefineColorsArr } from "../../commons/dashboardProperties";
import deepEqual from "deep-equal";
import { Notify } from "../../commons/helper";
import { notificationType } from "../../commons/notificationTypes";
import cloneDeep from "clone-deep";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { defaultColorPalettes } from "./settings";

export default {
  components: { ConfirmDialog },
  data() {
    return {
      customIcon: CustomIcon,
      dialogVisible: false,
      selectedPalette: null,
      showEditIcons: false,
      predefineColorsArr,
      orginalPalettes: null,
      baseNewPaletteName: "New Palette",
    };
  },
  async mounted() {
    this.fetchAndControlColorPalettes();
  },
  computed: {
    ...mapGetters({
      allPalettes: GETTER_COLOR_PALETTE.GET_COLOR_PALETTES,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    palettes() {
      return this.allPalettes;
    },
    isColorPaletteDataLoading() {
      return this.loading[LoadingComponent.ColorPalettes];
    },
  },
  methods: {
    ...mapActions({
      fetchColorPalettes: ACTION_COLOR_PALETTE.FETCH_COLOR_PALETTES,
      createColorPalettes: ACTION_COLOR_PALETTE.CREATE_COLOR_PALETTES,
      updateColorPalettes: ACTION_COLOR_PALETTE.UPDATE_COLOR_PALETTES,
      deleteColorPalettes: ACTION_COLOR_PALETTE.DELETE_COLOR_PALETTES,
    }),
    async addPalette() {
      const matchingPalettes = this.checkAvailablePaletteNames();
      let createName = null;
      if (matchingPalettes.length === 0) {
        createName = `${this.baseNewPaletteName} 1`;
      } else {
        // Get the highest index of palettes containing "New Palette"
        const highestIndex = matchingPalettes.reduce((maxIndex, palette) => {
          const index = parseInt(palette.name.match(/\d+$/), 10);
          return isNaN(index) ? maxIndex : Math.max(maxIndex, index);
        }, 0);

        const newIndex = highestIndex + 1;
        createName = `${this.baseNewPaletteName} ${newIndex}`;
      }

      await this.createColorPalettes({
        name: createName,
      });
    },
    openTheEdit(palette) {
      this.orginalPalettes = cloneDeep(this.palettes);
      this.selectedPalette = palette;
      this.showEditIcons = true;
    },
    closeTheEdit(palette) {
      if (this.selectedPalette?.id === palette?.id) {
        const checkPalette = this.orginalPalettes.find(
          (data) => data.id === this.selectedPalette?.id
        );
        if (!deepEqual(checkPalette, palette)) {
          Notify(
            this.$t(
              "admin.palettes.There are unsaved changes to the Colour Palettes Please save the changes"
            ),
            notificationType.WARNING
          );

          return;
        } else {
          this.selectedPalette = null;
          this.showEditIcons = false;
        }
      }
    },
    deleteActionClicked(item) {
      this.dialogVisible = true;
      this.selectedPalette = item;
    },
    deletePalette() {
      this.deleteColorPalettes(this.selectedPalette.id);
      this.dialogVisible = false;
    },
    checkColorCount(data) {
      if (data?.id === this.selectedPalette?.id) {
        if (data?.paletteColors?.length != 16 && this.showEditIcons) {
          return true;
        } else return false;
      }
    },
    updatePaletteTitle(data, event) {
      data.name = event.target.value;
    },
    addNewColor(newColor) {
      this.selectedPalette?.paletteColors.push(newColor);
    },
    savePalette(data) {
      this.updateColorPalettes(data);
      this.showEditIcons = false;
    },
    isShowEditModePalette(isShow, paletteId) {
      return isShow && paletteId === this.selectedPalette?.id;
    },
    checkAvailablePaletteNames() {
      return this.palettes?.filter((palette) =>
        palette?.name.includes(this.baseNewPaletteName)
      );
    },
    async fetchAndControlColorPalettes() {
      const colorPalettes = await this.fetchColorPalettes({
        loadingComponent: LoadingComponent.ColorPalettes,
      });
      if (colorPalettes.length < 2) {
        defaultColorPalettes.forEach(async (palette) => {
          await this.createColorPalettes({
            name: palette.name,
            paletteColors: palette.paletteColors,
          });
        });
      }
    },
    changeClearButtonToDeleteButton(palette, colorIndex) {
      const clearButtons = document.querySelectorAll(
        ".el-button.el-color-dropdown__link-btn.el-button--text.el-button--mini"
      );

      clearButtons.forEach(button => {
        button.querySelector("span").textContent = this.$t("generalPages.delete");

        const newButton = button.cloneNode(true);

        button.parentNode.replaceChild(newButton, button);

        newButton.addEventListener("click", () => {
          return this.handleButtonClick(palette, colorIndex);
        });
    
      });
    },
    handleButtonClick(palette, colorIndex) {
      if (colorIndex >= 0 && colorIndex < palette.paletteColors.length) {
        palette.paletteColors.splice(colorIndex, 1);
        this.$set(palette, 'paletteColors', [...palette.paletteColors]);

        const dropdown = document.querySelectorAll('.el-color-dropdown.el-color-picker__panel');

        if (dropdown) {
          dropdown.forEach(button => {
            button.style.display = 'none';
          })
        }
      }
    }
  },
};
</script>

<style scoped>
.palettes {
  padding: 20px;
  font-size: 13px;
  font-weight: 500;
}
.palette-cards {
}
.palette-card {
  border: 1px solid #e4e3e8;
  padding: 12px;
  margin: 20px 15px;
  display: flex;
  flex-direction: column;
  max-width: 308px;
  min-height: 120px;
}

.palette-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  font-size: 13px !important;
}

.palette-card-header h3 {
  margin: 0;
  flex-grow: 1;
}

.add-color-button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 34px;
  width: 34px;
  padding: 4px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-size: 0;
  position: relative;
  cursor: pointer;
  color: #206ff9;
}

.add-palette-card {
  border: 1px solid #e4e3e8;
  padding: 16px;
  margin: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #3d62f8;
  font-weight: 600;
  max-width: 308px;
  min-height: 120px;
}
.palette-colors {
  display: flex;
  flex-flow: wrap;
}
.vis-icon {
  font-size: 16px !important;
}
::v-deep .el-color-picker__trigger {
  height: 34px !important;
  width: 34px !important;
  border: none !important;
  padding: 3px;
}

::v-deep .el-color-picker__icon::before {
  content: "";
}
.vgt-responsive {
  max-height: 650px;
}
::v-deep .add-new-color .el-color-picker__icon::before {
  color: #206ff9;
  font-size: 1rem;
  content: "\002B";
  display: block;
}
::v-deep .el-color-picker__mask {
  background-color: unset;
}
.vis-palette-input {
  position: relative;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
input:read-only {
  cursor: pointer;
}
</style>
