export const domain = "VISUALIZE_FILTER";

export const STATE = {
    ENCODE: `${domain}/ENCODE`,
    DECODE: `${domain}/DECODE`,
};

export const GETTER = {
    GET_ENCODE: `${domain}/GET_ENCODE`,
    GET_DECODE: `${domain}/GET_DECODE`,
};

export const ACTION = {
    FETCH_ENCODE: `${domain}/FETCH_ENCODE`,
    FETCH_DECODE: `${domain}/FETCH_DECODE`,
};

export const MUTATION = {
    SET_ENCODE: `${domain}/SET_ENCODE`,
    SET_DECODE: `${domain}/SET_DECODE`,
};
