<template>
  <!-- TODO make this popup as a generic component -->
  <el-dialog
    :title="$t('generalPages.Select filter criteria')"
    :visible="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="filter-row">
      <span class="filter-row-title">{{ $t("Name") }}</span>
      <el-select
        class="vis-pr--15"
        v-model="filterValueClone.filterNameDropdown"
        :placeholder="$t('generalPages.Select')"
        size="mini"
      >
        <el-option
          v-for="item in filterOptions.filterNameDropdown"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        size="mini"
        :placeholder="$t('Name')"
        v-model="filterValueClone.filterName"
      >
      </el-input>
    </div>

    <div class="filter-row">
      <span class="filter-row-title">{{ $t("Status") }}</span>
      <el-select
        v-model="filterValueClone.filterScheduleStatus"
        :placeholder="$t('generalPages.Select')"
        size="mini"
      >
        <el-option
          v-for="item in filterOptions.scheduleStatus"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="filter-row">
      <span class="filter-row-title">{{ $t("generalPages.Owner") }}</span>
      <el-select
        v-model="filterValueClone.filterOwner"
        :placeholder="$t('generalPages.Select')"
        size="mini"
        clearable
      >
        <el-option
          v-for="item in filterOptions.owner"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="filter-row">
      <span class="filter-row-title">{{ $t("Run as") }}</span>
      <el-select
        v-model="filterValueClone.filterRunAs"
        :placeholder="$t('generalPages.Select')"
        size="mini"
        clearable
      >
        <el-option
          v-for="item in filterOptions.runAs"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="filter-row">
      <span class="filter-row-title">{{ $t("File Type") }}</span>
      <el-select
        v-model="filterValueClone.filterTaskType"
        :placeholder="$t('generalPages.Select')"
        size="mini"
      >
        <el-option
          v-for="item in filterOptions.taskType"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="filter-row">
      <span class="filter-row-title">{{ $t("Started At") }}</span>
      <el-date-picker
        v-model="filterValueClone.filterStartingDate"
        type="daterange"
        align="right"
        :start-placeholder="$t('generalPages.Start Date')"
        :end-placeholder="$t('generalPages.End Date')"
        :default-value="new Date()"
      >
      </el-date-picker>
    </div>

    <div class="filter-row">
      <span class="filter-row-title">{{ $t("Finished At") }}</span>
      <el-date-picker
        v-model="filterValueClone.filterEndingDate"
        type="daterange"
        align="right"
        :start-placeholder="$t('generalPages.Start Date')"
        :end-placeholder="$t('generalPages.End Date')"
        :default-value="new Date()"
      >
      </el-date-picker>
    </div>

    <div class="filter-row">
      <span class="filter-row-title">{{ $t("Dashboard ID") }}</span>
      <el-input
        size="mini"
        :placeholder="$t('Dashboard ID')"
        v-model="filterValueClone.filterDashboardId"
      >
      </el-input>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeFilterPopup">{{
        $t("generalPages.Close")
      }}</el-button>
      <el-button type="primary" @click="applyFilters">{{
        $t("generalPages.Apply")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "clone-deep";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    filterValue: {
      type: Object,
      default: () => ({
        filterNameDropdown: "Contains",
        filterName: "",
        filterTaskType: "",
        filterOwner: "",
        filterRunAs: "",
        filterStartingDate: "",
        filterEndingDate: "",
        filterDashboardId: "",
        filterScheduleStatus: "",
      }),
    },
    owners: {
      type: Array,
      default: () => [],
    },
    runAs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterValueClone: null,
    };
  },
  watch: {
    filterValue: {
      handler(val) {
        this.filterValueClone = cloneDeep(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    filterOptions() {
      return {
        filterNameDropdown: [
          {
            value: "Contains",
            label: this.$t("generalPages.Contains"),
          },
        ],
        taskType: [
          {
            value: "EXCEL",
            label: "EXCEL",
          },
          {
            value: "CSV",
            label: "CSV",
          },
        ],
        scheduleStatus: [
          {
            value: "FINISHED",
            label: this.$t("FINISHED"),
          },
          {
            value: "FAILED",
            label: this.$t("FAILED"),
          },
        ],
        owner: this.owners.map((o) => ({
          value: o,
          label: o,
        })),
        runAs: this.runAs.map((o) => ({
          value: o,
          label: o,
        })),
      };
    },
  },
  methods: {
    applyFilters() {
      this.$emit("applyFilters", cloneDeep(this.filterValueClone));
    },
    closeFilterPopup() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.filter-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.filter-row-title {
  min-width: 150px;
}
::v-deep .el-dialog__title {
  padding-left: 15px !important;
}
</style>
