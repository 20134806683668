<template>
  <div class="properties-db-scheduling-container">
    <span class="properties-db-scheduling-plus-icon"
      >+</span
    >
    <span class="vis-font-bold">{{ $t(`${label}`) }}</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      label:"generalPages.addSchedule"
    }
  }
}
</script>
<style scoped>
.properties-db-scheduling-container {
  color: rgb(43, 106, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.properties-db-scheduling-plus-icon {
  font-size: 25px;
  margin-right: 10px;
}
</style>