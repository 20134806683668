<template>
  <div class="aside-properties">
    <div class="event-aside-title">
      <span class="event-aside-title-icon material-icons-outlined">
        view_week
      </span>
      {{ $t("Event Settings") }}
    </div>
    <div class="event-aside-properties-container">
      <el-tabs :stretch="true">
        <el-tab-pane>
          <span slot="label"> {{ $t("General") }}</span>
          <div class="event-aside-properties-general-tab">
            <span>{{
              $t("Enter name and optionally screen note for this transition")
            }}</span>
            <div class="vis-mt--1 vis-mb--1">
              <label>{{ $t("Name") }}</label>
              <el-input
                :value="properties.eventName"
                @input="(v) => $emit('updateName', v)"
              />
            </div>
            <div class="vis-mb--1">
              <label>{{ $t("Description") }}</label>
              <el-input
                :value="properties.eventDescription"
                @input="(v) => $emit('updateDescription', v)"
              />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    properties: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.aside-properties {
  padding: 20px !important;
}
.event-aside {
  display: flex;
  align-items: center;
  padding: 18px 25px;
  height: inherit;
  flex-direction: column;
}
.event-aside-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.event-aside-title-icon {
  color: #0014ff;
  font-size: 1.625rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.event-aside-properties-container {
  width: 100%;
}
</style>
