<template>
  <div class="vis-flex--center">
    <el-button @click.stop="$emit('getSearch')" :style="valueLabelStyle">
      <div>{{ this.buttonCaptionLabel }}</div></el-button
    >
  </div>
</template>
<script>
import { BUTTON_PROPERTIES_ENUM } from "../../../commons/dashboardProperties";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";

export default {
  mixins: [panelDataPropertiesStyle],

  data() {
    return {};
  },
  props: {
    panelData: {
      type: Object,
    },
  },
  computed: {
    buttonCaptionLabel() {
      return this.CHART_COMPUTED_VALUES?.[
        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL
      ];
    },
    valueLabelStyle() {
      return {
        color:
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_COLOR
          ],
        "font-family":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_FAMILY
          ],
        "font-weight":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_WEIGHT
          ],
        "font-size":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_SIZE
          ] + "px  !important",
        "background-color":
          this.CHART_COMPUTED_VALUES?.[
            BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BACKGROUND_COLOR
          ],
      };
    },
  },
};
</script>