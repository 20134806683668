import { getOperator } from "./dashboardChartFilter";
import { filterObjectProperty } from "../../components/panel/filters/js/filters";
import { properties } from "../../commons/dashboardAndPanel";
import Vue from "vue";
import { filterType } from "../../commons/filterComponents";

export const createFilterDrillThrough = (fieldKey, filterValue, filterId) => {
  const selectedFieldFilterObj = filterValue?.[fieldKey];
  const value = selectedFieldFilterObj.value;
  const {
    fieldId,
    datasetId,
    fieldType,
    fieldUsageType,
    filterType,
    operator,
    filterValues,
    popupTabName,
  } = selectedFieldFilterObj?.fieldItem;

  const payloadFilterObj = {
    field: fieldKey,
    alias: selectedFieldFilterObj?.alias,
    fieldId,
    datasetId,
    isActiveDashboardFilter: true,
    isActiveFilterIcon: true,
    operator: operator ? operator : getOperator(false),
    value: !Array.isArray(value) ? [value] : value,
    filterId,
    fieldType,
    fieldUsageType,
    filterType,
    filterValues,
    popupTabName,
  };

  return { payloadFilterObj, selectedFieldFilterObj };
};

export const addFiltersToFilterOrParamListOfCharts = (
  clonedDashboard,
  payloadFilterObj,
  //selectedFieldFilterObj TODO ++
) => {
  //chart'ın details objesi içindeki hangi listeye filtre ekleneceği filtre tipine göre belirleniyor.
  const filterTypeKey =
    payloadFilterObj?.filterType === filterType.CUSTOM
      ? properties.PARAMS
      : properties.FILTERS;

  clonedDashboard?.tabs.forEach((tab) => {
    tab?.charts.forEach((chart) => {
      //TODO++
      // if (chart.type.toLowerCase().includes(panelTypes.FILTER)) {
      //   setFilterValueOfFilterChart({
      //     chart,
      //     payloadFilterObj,
      //     selectedFieldFilterObj,
      //   });
      // }
        
      let selectedFilter = chart.details[filterTypeKey].find(
        (x) =>
          x.filterId == payloadFilterObj.filterId &&
          x.fieldId == payloadFilterObj.fieldId
      );

      if (!selectedFilter) {
        //FOR NEW ADD FILTER VALUE
        chart.details[filterTypeKey].push(payloadFilterObj);
      } else if (selectedFilter && payloadFilterObj.value) {
        //FOR UPDATE FILTER VALUE
        Vue.set(
          selectedFilter,
          filterObjectProperty.VALUE,
          payloadFilterObj.value
        );
      } else {
        //FOR DELETE FILTER VALUE
        const filterList = chart.details[filterTypeKey].filter((x) => {
          return x.fieldId != payloadFilterObj.fieldId;
        });
        Vue.set(chart.details, filterTypeKey, filterList);
      }
    });
  });
};
//TODO++
// const setFilterValueOfFilterChart = ({
//   chart,
//   payloadFilterObj,
//   selectedFieldFilterObj,
// }) => {
//   const selectedCustomFilter = chart.details.metrics.find(
//     (x) => x.filterType === filterType.CUSTOM
//   );

//   if (
//     selectedCustomFilter?.fieldId === payloadFilterObj?.fieldId &&
//     chart.details.FILTER_VALUE === ""
//   ) {
//     const filterObj = {};
//     filterObj[payloadFilterObj.field] = {
//       value: payloadFilterObj.value,
//       alias: payloadFilterObj.alias,
//       fieldItem: selectedFieldFilterObj?.fieldItem,
//     };
//     chart.details.FILTER_VALUE = filterObj;
//     //chart.i = payloadFilterObj.filterId;
//   }
// };
