<template>
  <div class="vis-time-picker">
    <el-select
      v-model="selectedHour"
      placeholder="Select Hour"
      @change="handleChangeHourOrMinute"
    >
      <el-option
        v-for="hour in hours"
        :key="hour"
        :label="hour"
        :value="hour"
      ></el-option>
    </el-select>

    <el-select
      v-if="isMinuteEnabled"
      v-model="selectedMinute"
      placeholder="Select Minute"
      @change="handleChangeHourOrMinute"
    >
      <el-option
        v-for="minute in minutes"
        :key="minute"
        :label="minute"
        :value="minute"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "00:00",
    },
    isMinuteEnabled: {
        type: Boolean,
    }
  },
  data() {
    return {
      selectedHour: "",
      selectedMinute: "",
      hours: Array.from({ length: 24 }, (_, i) =>
        i.toString().padStart(2, "0")
      ),
      minutes: Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, "0")
      ),
      timeZoneDifference: 3,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.selectedHour = String(
          (Number(val.split(":")[0]) + this.timeZoneDifference) % 24
        ).padStart(2, "0");
        this.selectedMinute = val.split(":")[1];
      },
    },
  },
  methods: {
    handleChangeHourOrMinute() {
      const hour = String(
        (Number(this.selectedHour) - this.timeZoneDifference + 24) % 24
      ).padStart(2, "0");
      this.isMinuteEnabled ?
      this.$emit("input", hour + ":" + this.selectedMinute) :
      this.$emit("input", hour);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
.vis-time-picker {
  display: flex;
  gap: 10px;
}
.el-select__caret::before,
.el-select__caret::after {
  width: 10px !important;
}
</style>
