<template>
  <div class="vis-panel-dropdown" :class="[customClass]">
    <div
      class="vis-popup-common-title vis-flex--spacebeetwen-Ycenter vis-mb--2"
    >
      <slot name="title"></slot>
    </div>
    <div class="vis-panel-dropdown-content vis-scroll-thin">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    customClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>
