export const domain = "VISUALIZE_DATAMODEL";

export const STATE = {
  DATAMODELS: `${domain}/DATAMODELS`,
  SELECTED_DATAMODEL: `${domain}/SELECTED_DATAMODEL`,
  DATAMODEL_FIELDS: `${domain}/DATAMODEL_FIELDS`,
  DATAMODEL_FILTER_PARAMS: `${domain}/DATAMODEL_FILTER_PARAMS`,
};

export const GETTER = {
  GET_DATAMODELS: `${domain}/GET_DATAMODELS`,
  GET_SELECTED_DATAMODEL: `${domain}/GET_SELECTED_DATAMODEL`,
  GET_DATAMODEL_BY_ID_FIELDS: `${domain}/GET_DATAMODEL_BY_ID_FIELDS`,
  GET_DATAMODEL_BY_ID_FILTER_PARAMS: `${domain}/GET_DATAMODEL_BY_ID_FILTER_PARAMS`,
};

export const ACTION = {
  FETCH_DATAMODELS: `${domain}/FETCH_DATAMODELS`,
  FETCH_DATAMODEL_BY_ID: `${domain}/FETCH_DATAMODEL_BY_ID`,
  CREATE_DATAMODEL: `${domain}/CREATE_DATAMODEL`,
  DELETE_DATAMODEL: `${domain}/DELETE_DATAMODEL`,
  UPDATE_DATAMODEL: `${domain}/UPDATE_DATAMODEL`,
  COPY_DATAMODEL: `${domain}/COPY_DATAMODEL`,
  UPDATE_DATAMODEL_NAME: `${domain}/UPDATE_DATAMODEL_NAME`,
  MOVE_DATAMODEL: `${domain}/MOVE_DATAMODEL`,
  UPDATE_DATAMODEL_PROPERTIES: `${domain}/UPDATE_DATAMODEL_PROPERTIES`,
  FETCH_DATAMODEL_BY_ID_FIELDS: `${domain}/FETCH_DATAMODEL_BY_ID_FIELDS`,
  FETCH_DATAMODEL_BY_ID_FILTER_PARAMS: `${domain}/FETCH_DATAMODEL_BY_ID_FILTER_PARAMS`,
  DUPLICATE_DATAMODEL_FIELD: `${domain}/DUPLICATE_DATAMODEL_FIELD`,
  FETCH_HIGHEST_PRIORITY_BY_DATAMODEL_ID: `${domain}/FETCH_HIGHEST_PRIORITY_BY_DATAMODEL_ID`,
  UPDATE_QUERY_TIMEOUT: `${domain}/UPDATE_QUERY_TIMEOUT`,
  UPDATE_QUERY_STATUS: `${domain}/UPDATE_QUERY_STATUS`,
};

export const MUTATION = {
  SET_DATAMODELS: `${domain}/SET_DATAMODELS`,
  SET_SELECTED_DATAMODEL: `${domain}/SET_SELECTED_DATAMODEL`,
  SET_SELECTED_DATAMODEL_QUERY_TIMEOUT: `${domain}/SET_SELECTED_DATAMODEL_QUERY_TIMEOUT`,
  SET_SELECTED_DATASET: `${domain}/SET_SELECTED_DATASET`,
  SET_DATAMODEL_FIELDS: `${domain}/SET_DATAMODEL_FIELDS`,
  RESET_DATAMODEL_FIELDS: `${domain}/RESET_DATAMODEL_FIELDS`,
  SET_DATAMODEL_FILTER_PARAMS: `${domain}/SET_DATAMODEL_FILTER_PARAMS`,
  RESET_DATAMODEL_FILTER_PARAMS: `${domain}/RESET_DATAMODEL_FILTER_PARAMS`,
};
