export const colorPalette = ['#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#9e99db', '#ffa600', '#003f5c']

export const defaultColorPalette = {
    id: 1,
    name: "Softtech Palette",
    paletteColors: colorPalette
}

export const heatmapDefaultColorPalette = {
    id: 2,
    name: "Heatmap Palette",
    paletteColors: ['#2f4b7c', '#eaedf2']
}

export class ColorPalette {
    constructor(palette) {
        this.id = palette?.id || 0;
        this.name = palette?.name || "";
        this.paletteColors = palette?.paletteColors || [];
    }
}