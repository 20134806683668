import { customFilterTypes } from "../../../commons/dataModelTypes"

export const customFilterPopupModes = {
    Edit: "Edit",
    Create: "Create",
}
export const customFilterPopupDefaultFormData = {
    default_value: "",
    mandatory: false,
    multi_select: false,
    name: "",
    filterType: "CUSTOM",
    type: customFilterTypes.TEXT,
    rangeSelect: false,
}