<template>
  <div class="properties-db-general-container">
    <div v-for="property in dbGeneralProperties" :key="property.key">
      <label class="vis-font-bold">{{ $t(`${property.label}`) }}</label>
      <input
        v-if="property.componentType === componentTypes.INPUT"
        class="vis-input"
        :value="property.value"
        :readonly="property.readonly"
        @keyup="(e) => inputKeyupHandler(property.key, e)"
        :required="checkRequired(property.key)"
      />
      <div
        v-else-if="property.componentType === componentTypes.TEXT"
        class="vis-input"
      >
        {{ $t(`generalPages.${property.value}`) }}
      </div>
      <el-select
        v-if="property.componentType === componentTypes.SELECT_BOX"
        :disabled="property.readonly"
        v-model="selectTableType.value"
        @change="(e) => selectTableTypeChanged(property.key, e)"
      >
        <el-option
          v-for="option in cloneTableTypeOptions"
          :key="option.key"
          :label="$t(`${option.label}`)"
          :value="option.key"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import {
  dbGeneralPropertiesKeys,
  dbGeneralPropertiesMapper,
} from "./../../../commons/dataModelDBProperties";
import { COMPONENT_TYPES } from "./../../../commons/Enum";
import { tableTypeOptions } from "../../../commons/dataModelTypes";
import cloneDeep from "clone-deep";

export default {
  props: {
    selectedDataset: {
      type: Object,
    },
    sqlViewVisible: {
      type: Boolean,
    },
    connectionLength: {
      type: Number,
    },
    selectedDatasetFieldsLength: {
      type: Number,
    },
    type: {
      type: String,
    },
    connectionName: {
      type: String,
    },
  },
  data() {
    return {
      componentTypes: COMPONENT_TYPES,
      tableTypeOptions: tableTypeOptions,
    };
  },
  watch: {
    selectedDataset: {
      handler() {
        this.selectTableType;
      },
      deep: true,
    },
  },
  computed: {
    dbGeneralProperties() {
      return dbGeneralPropertiesMapper(this.sqlViewVisible, {
        name: this.selectedDataset?.name,
        type: this.selectedDataset?.type
          ? this.selectedDataset?.type
          : this.type,
        connection: this.selectedDataset?.connection?.name
          ? this.selectedDataset?.connection?.name
          : this.connectionName,
        status: this.selectedDataset?.status,
        columnCount: this.selectedDatasetFieldsLength,
        tableType: this.selectedDataset?.tableType
          ? this.selectedDataset?.tableType
          : this.cloneTableTypeOptions[0],
      });
    },
    selectTableType() {
      return this.selectTableTypeValue(this.selectedDataset?.tableType);
    },
    cloneTableTypeOptions(){
      return cloneDeep(this.tableTypeOptions);
    }
  },
  methods: {
    checkRequired(key) {
      return key == dbGeneralPropertiesKeys.name && this.connectionLength > 0;
    },
    inputKeyupHandler(propertyKey, e) {
      this.$emit("propertyChanged", {
        key: propertyKey,
        value: e.target.value,
      });
    },
    selectTableTypeChanged(propertyKey, event) {
      this.$emit("propertyChanged", {
        key: propertyKey,
        value: event,
      });
    },
    selectTableTypeValue(value) {
      const cloneOptions = cloneDeep(this.tableTypeOptions);
      const matchedOption = cloneOptions.find(
        (option) => option.value === value
      );
      return matchedOption ? matchedOption : cloneOptions[0];
    },
  },
};
</script>

<style scoped>
.properties-db-general-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 25px;
}
</style>
