import { ACTION, GETTER, MUTATION, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visObjectLock = {
  state: {
    [STATE.OBJECT_LOCK]: [],
  },
  getters: {
    [GETTER.GET_FAVORITES]: (state) => {
      return state[STATE.OBJECT_LOCK];
    }
  },
  actions: {
    
    [ACTION.FETCH_OBJECT_LOCK]: async ({ commit }, { loadingComponent, bodyParam }) => {
     return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.ObjectLock,
        serviceMethodSub: serviceMethodSub.readObjectLock,
        bodyParam,
        successMutation: MUTATION.SET_OBJECT_LOCK,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },

    [ACTION.CREATE_OBJECT_LOCK]: async ({ commit }, { loadingComponent, bodyParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.ObjectLock,
        serviceMethodSub: serviceMethodSub.createObjectLock,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        loadingComponent,
      });
    },

    [ACTION.DELETE_OBJECT_LOCK]: async ({ commit }, {loadingComponent, bodyParam}) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.ObjectLock,
        serviceMethodSub: serviceMethodSub.deleteObjectLock,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        loadingComponent,
      });
    },
  },
  mutations: {
    [MUTATION.SET_OBJECT_LOCK](state, items) {
      Vue.set(state, [STATE.OBJECT_LOCK], items);
    },
  }
};
