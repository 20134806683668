export const domain = "VISUALIZE_EXPORT_METADATA";

export const STATE = {
  EXPORT_METADATA_RECORDS: `${domain}/EXPORT_METADATA_RECORDS`,
};

export const GETTER = {
  GET_EXPORT_METADATA_RECORDS: `${domain}/GET_EXPORT_METADATA_RECORDS`,
};

export const ACTION = {
  FETCH_EXPORT_METADATA_RECORDS: `${domain}/FETCH_EXPORT_METADATA_RECORDS`,
  CREATE_EXPORT_METADATA: `${domain}/CREATE_EXPORT_METADATA`,
  FETCH_EXPORT_METADATA_SHARED_URL: `${domain}/FETCH_EXPORT_METADATA_SHARED_URL`,
  DELETE_EXPORT_METADATA: `${domain}/DELETE_EXPORT_METADATA`,
  CREATE_EXPORT_NOTIFICATION: `${domain}/CREATE_EXPORT_NOTIFICATION`,
};

export const MUTATION = {
  SET_EXPORT_METADATA_RECORDS: `${domain}/SET_EXPORT_METADATA_RECORDS`,
};


