<template>
  <div id="gauge-seperator">
    <div class="draft-row vis-flex--Ycenter vis-mb--1 vis-pb--1">
      <div>
        <div>{{ $t("panelProperties.percentage") }} (%)</div>
        <el-input-number
          v-model="draftRow.percentage"
          @change="(val) => setDraft('percentage', val)"
          :min="0"
          :max="100"
        ></el-input-number>
      </div>

      <div class="vis-flex-1 vis-flex--Ycenter">
        <div class="color-picker-container vis-mx--05">
          <div>{{ $t("panelProperties.color") }}</div>
          <el-color-picker
            :value="draftRow.color"
            :predefine="predefineColorsArr"
            @change="(value) => setDraft('color', value)"
          ></el-color-picker>
        </div>

        <button
          class="el-button el-button--primary small vis-flex-self--end"
          @click="addDraft"
        >
        {{ $t("panelProperties.add") }}
        </button>
      </div>
    </div>

    <div
      v-for="(row, index) in rows"
      :key="index"
      class="vis-flex--Ycenter"
      :class="{ 'vis-mb--1': index !== rows.length - 1 }"
    >
      <el-input-number
        v-model="row.percentage"
        @change="(value) => setPercentage(value, index)"
        :min="0"
        :max="100"
      ></el-input-number>

      <el-color-picker
        class="vis-mx--05"
        :value="row.color"
        :predefine="predefineColorsArr"
        @change="(value) => setColor(value, index)"
      ></el-color-picker>

      <i
        :class="customIcons.TrashCircle + ' vis-cursor-pointer'"
        aria-hidden="true"
        @click="() => removeRow(index)"
      ></i>
    </div>
  </div>
</template>

<script>
import { predefineColorsArr } from "../../../commons/dashboardProperties";
import { CustomIcon } from "../../../assets/js/custom-icons";

const defaultRow = { percentage: 0, color: "#fff" };

export default {
  data() {
    return {
      predefineColorsArr: predefineColorsArr,
      rows: [],
      customIcons: CustomIcon,
      draftRow: { ...defaultRow },
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.rows = [...this.value];
  },
  methods: {
    setPercentage(value, index) {
      this.rows[index].percentage = value;
      this.sortByPercentage();
      this.setComponentStyle();
    },
    setColor(value, index) {
      this.rows[index].color = value;
      this.setComponentStyle();
    },
    removeRow(index) {
      this.rows.splice(index, 1);
      this.setComponentStyle();
    },
    setDraft(field, value) {
      this.draftRow[field] = value;
    },
    addDraft() {
      this.rows.push(this.draftRow);
      this.sortByPercentage();
      this.draftRow = { ...defaultRow };
      this.setComponentStyle();
    },
    sortByPercentage() {
      this.rows.sort((a, b) => a.percentage - b.percentage);
    },
    setComponentStyle() {
      this.$emit("setComponentStyle", this.rows);
    },
  },
};
</script>
<style>
#gauge-seperator .el-color-picker {
  height: 32px !important;
}

#gauge-seperator .el-color-picker__trigger {
  width: 32px !important;
  height: 32px !important;
}

#gauge-seperator .draft-row {
  border-bottom: 1px solid #d8d8d8;
}

#gauge-seperator .el-input-number__decrease,
#gauge-seperator .el-input-number__increase {
  top: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#gauge-seperator .el-input-number {
  max-width: 140px;
}

#gauge-seperator .color-picker-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
