export const domain = "VISUALIZE_TEST_QUERY";

export const STATE = {
  SEARCH_RESULTS: `${domain}/SEARCH_RESULTS`,
  TEST_QUERY_RESULTS: `${domain}/TEST_QUERY_RESULTS`,
  TEST_QUERY_TABLE_NAMES: `${domain}/TEST_QUERY_TABLE_NAMES`,
  TEST_QUERY_COLUMN_NAMES: `${domain}/TEST_QUERY_COLUMN_NAMES`,
};

export const GETTER = {
  GET_SEARCH_RESULTS: `${domain}/GET_SEARCH_RESULTS`,
  GET_TEST_QUERY_RESULTS: `${domain}/GET_TEST_QUERY_RESULTS`,
  GET_TEST_QUERY_TABLE_NAMES: `${domain}/GET_TEST_QUERY_TABLE_NAMES`,
  GET_TEST_QUERY_COLUMN_NAMES: `${domain}/GET_TEST_QUERY_COLUMN_NAMES`,
};

export const ACTION = {
  FETCH_SEARCH: `${domain}/FETCH_SEARCH`,
  FETCH_SEARCH_WITHOUT_MUTATE:`${domain}/FETCH_SEARCH_WITHOUT_MUTATE`,
  FETCH_TEST_QUERY: `${domain}/FETCH_TEST_QUERY`,
  FETCH_TEST_PREVIEW: `${domain}/FETCH_TEST_PREVIEW`,
  FETCH_TEST_QUERY_TABLE_NAMES: `${domain}/FETCH_TEST_QUERY_TABLE_NAMES`,
  FETCH_TEST_QUERY_COLUMN_NAMES: `${domain}/FETCH_TEST_QUERY_COLUMN_NAMES`,
};

export const MUTATION = {
  SET_TEST_QUERY_RESULTS: `${domain}/SET_TEST_QUERY_RESULTS`,
  SET_SEARCH_RESULTS: `${domain}/SET_SEARCH_RESULTS`,
  SET_TEST_QUERY_TABLE_NAMES: `${domain}/SET_TEST_QUERY_TABLE_NAMES`,
  SET_TEST_QUERY_COLUMN_NAMES: `${domain}/SET_TEST_QUERY_COLUMN_NAMES`,
  RESET_TEST_QUERY: `${domain}/RESET_TEST_QUERY`,
};


