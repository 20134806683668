export const domain = "VISUALIZE_USER";

export const STATE = {
    USER_PREFERENCES: `${domain}/USER_PREFERENCES`,
};

export const GETTER = {
    GET_USER_PREFERENCES: `${domain}/GET_USER_PREFERENCES`,
};

export const ACTION = {
    FETCH_USER_PREFERENCES: `${domain}/FETCH_USER_PREFERENCES`,
    UPDATE_USER_PREFERENCES: `${domain}/UPDATE_USER_PREFERENCES`,
};

export const MUTATION = {
    SET_USER_PREFERENCES: `${domain}/SET_USER_PREFERENCES`,
};


