<template>
  <div class="vis-notification-card">
    <!-- START CARD TITLE -->
    <div class="vis-flex--spacebeetwen-Ycenter">
      <div class="vis-flex--Ycenter">
        <i aria-hidden="true" class="vis-mr--1" :class="notificationIcon" />
        <div class="vis-display-flex vis-flex-direction--column">
          <span class="vis-notification-title">{{
            $t(notificationTitle)
          }}</span>
        </div>
      </div>
      <i
        v-if="isClosed"
        @click="$emit('handleClickClose')"
        aria-hidden="true"
        :class="customIcon.Close"
        class="vis-cursor-pointer"
      ></i>
    </div>
    <!-- END CARD TITLE -->
    <!-- START CONTENT-->
    <span v-if="notificationMessage" class="vis-notification-message">{{
      $t(notificationMessage)
    }}</span>
    <!-- END CONTENT-->
    <div v-if="isShowTextButton" class="vis-text--right">
      <el-button class="vis-font-size-0875" type="text" @click="handleClickWithTextButton">
        {{ $t(textButtonLabel) }}
      </el-button>
    </div>
    <div v-if="isShowProgressBar" class="vis-progress">
      <div class="vis-progress-line"></div>
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { notificationType } from "../../commons/notificationTypes";

export default {
  props: {
    notificationType: {
      type: String,
      default: "",
    },
    notificationTitle: {
      type: String,
      default: "",
    },
    notificationMessage: {
      type: String,
      default: null,
    },
    isClosed: {
      type: Boolean,
      default: false,
    },
    isShowProgressBar: {
      type: Boolean,
      default: false,
    },
    isShowTextButton: {
      type: Boolean,
      default: false
    },
    textButtonLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  computed: {
    notificationIcon() {
      return this.notificationType === notificationType.ERROR
        ? "el-icon-error vis-icon-error"
        : this.notificationType === notificationType.INFO
        ? "el-notification__icon el-icon-info"
        : "el-notification__icon el-icon-success";
    },
  },
  methods: {
    handleClickWithTextButton() {
      this.$emit("handleClickWithTextButton");
      this.$emit("handleClickClose");
    },
  },
};
</script>
<style scoped>
.vis-notification-card {
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  font-size: 0.75rem;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  transition: opacity .3s,transform .3s,left .3s,right .3s,top .4s,bottom .3s;
  z-index: 5001;
}
.vis-icon-error {
  color: #f56c6c !important;
  font-size: 24px !important;
}

.vis-notification-texture {
  margin-left: 13px;
  margin-right: 8px;
}
.vis-notification-title {
  font-weight: 700;
  font-size: 1rem;
  color: #303133;
  margin: 0;
}
.vis-notification-message {
  font-size: 0.875rem;
  line-height: 21px;
  color: #606266;
  margin-left: 2.5rem;
}
.vis-notification-card .el-button--text {
  padding: 0 !important;
}
@keyframes example {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(370px);
  }
}
.vis-progress {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 8px;
  background-color: rgba(235, 235, 235, 0.39);
  z-index: 1;
  border-radius: 2px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.vis-progress .vis-progress-line {
  position: relative;
  width: 80px;
  height: 4px;
  background-color: #206FFF;
  border-radius: 6px;
  animation-name: example;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
</style>
