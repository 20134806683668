<template>
  <div>
    <div class="vis-row">
      <div class="vis-col vis-col-12">
        <label>{{ $t("schedule.labelOnMonths") }}</label>
      </div>
    </div>
    <div class="vis-row">
      <div class="vis-col vis-col-12">
        <div class="vis-display-flex">
          <el-checkbox
            :indeterminate="isIndeterminateMonth"
            v-model="checkAllMonth"
            @change="handleCheckAllMonthChange"
            class="vis-centered-label vis-mb--2"
            >{{ $t("home.all") }}</el-checkbox
          >
          <el-checkbox-group
            v-model="scheduleCronDtoSelected.selectedMonths"
            @change="handleCheckedMonthChange"
          >
            <div class="vis-display-flex vis-flex-wrap">
              <el-checkbox
                v-for="month in filteredMonths"
                :label="month.key"
                :key="month.key"
                class="vis-centered-label"
              >{{ month.value }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <div class="vis-row">
      <div class="vis-col vis-col-12">
        <label>{{ $t("schedule.labelOnDays") }}</label>
      </div>
    </div>
    <div class="vis-row vis-flex-nowrap">
      <div class="vis-col vis-col-3">
        <el-select v-model="scheduleCronDtoUpdated.monthlyCronType">
          <el-option
            v-for="option in monthlyCronOptions"
            :key="option.key"
            :value="option.key"
            :label="option.value"
          ></el-option>
        </el-select>
      </div>

      <div
        class="day-selection"
        v-if="
          scheduleCronDtoUpdated.monthlyCronType ===
          scheduleMonthlyOnWeeksOptions.specificDays
        "
      >
        <div class="vis-row">
          <div class="vis-col vis-col-12">
            <el-checkbox-group
              v-model="scheduleCronDtoSelected.selectedSpecificDays"
            >
              <div class="vis-display-flex vis-flex-wrap">
                <el-checkbox
                v-for="day in numericDays"
                :label="day"
                :key="day"
                class="vis-centered-label vis-specific-days"
                >{{ day }}</el-checkbox
              >
              </div>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div 
        class="day-selection" 
        v-if="isMultipleDaySelect">
        <div>
          <div class="vis-col vis-col-12">
            <div class="vis-display-flex">
              <el-checkbox
                :indeterminate="isIndeterminateDay"
                v-model="checkAllDay"
                @change="handleCheckAllDayChange"
                class="vis-centered-label vis-all-day vis-mb--2"
                >{{ $t("home.all") }}</el-checkbox
              >
              <el-checkbox-group
                v-model="scheduleCronDtoSelected.selectedDays"
                @change="handleCheckedDayChange"
              >
                <div class="vis-display-flex vis-flex-wrap">
                  <el-checkbox
                    v-for="day in filteredDays"
                    :label="day.key"
                    :key="day.key"
                    class="vis-centered-label"
                    >{{ day.value }}</el-checkbox
                  >
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vis-row">
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.labelAt") }}</label>
        <TimePicker 
          :isMinuteEnabled="true"
          :value="dailyAtTimeUpdated.dailyTime" 
          @input="value => dailyAtTimeUpdated.dailyTime=value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";
import TimePicker from "../../helper/TimePicker.vue";
import {
  scheduleAllSelect,
  scheduleMonthlyOnWeeksOptions,
  scheduleCronDtoDefaultValue,
  dailyAtTimeDefaultValue,
  scheduleCronDtoSelectDefaultValue,
} from "./schedule.js";
export default {
  props: {
    monthsOptions: {
      type: Array,
    },
    daysOptions: {
      type: Array,
    },
    monthlyCronOptions: {
      type: Array,
    },
    scheduleCronDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dailyAtTime: {
      type: Object,
      default: () => {
        return {};
      },
    },
    existingSelectCronDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {TimePicker},
  data() {
    return {
      scheduleMonthlyOnWeeksOptions: scheduleMonthlyOnWeeksOptions,
      numericDays: Array.from({ length: 31 }, (_, index) => index + 1),
      isIndeterminateMonth: false,
      isIndeterminateDay: true,
      checkAllMonth: true,
      checkAllDay: false,
      dailyAtTimeUpdated: dailyAtTimeDefaultValue,
      scheduleCronDtoUpdated: scheduleCronDtoDefaultValue,
      scheduleCronDtoSelected: scheduleCronDtoSelectDefaultValue,
    };
  },
  mounted() {
    this.scheduleCronDtoUpdated = cloneDeep(this.scheduleCronDto);
    this.dailyAtTimeUpdated = cloneDeep(this.dailyAtTime);
    this.scheduleCronDtoSelected = cloneDeep(this.existingSelectCronDto);
  },
  computed: {
    filteredMonths() {
      return this.monthsOptions.filter((x) => x.key !== scheduleAllSelect.All);
    },
    filteredDays() {
      return this.daysOptions.filter((x) => x.key !== scheduleAllSelect.All);
    },
    isMultipleDaySelect() {
      return ![
        scheduleMonthlyOnWeeksOptions.firstDayOfMonth,
        scheduleMonthlyOnWeeksOptions.lastDayOfMonth, 
        scheduleMonthlyOnWeeksOptions.specificDays
      ].includes(this.scheduleCronDtoUpdated.monthlyCronType);
    }
  },
  watch: {
    dailyAtTimeUpdated: {
      deep: true,
      handler() {
        this.$emit("onDailyAtTimeUpdated", this.dailyAtTimeUpdated);
      },
    },
    scheduleCronDtoUpdated: {
      deep: true,
      handler() {
        this.$emit("onScheduleUpdated", this.scheduleCronDtoUpdated);
      },
    },
    scheduleCronDtoSelected: {
      deep: true,
      handler() {
        this.$emit("onCronDtoSelectUpdated", this.scheduleCronDtoSelected);
      },
    },
  },
  methods: {
    handleCheckAllMonthChange(val) {
      this.scheduleCronDtoSelected.selectedMonths = val
        ? this.monthsOptions.map((x) => x.key)
        : [];
      this.isIndeterminateMonth = false;
    },
    handleCheckedMonthChange(value) {
      let checkCount = value?.length;
      this.checkAllMonth = checkCount === this.monthsOptions.length;
      this.isIndeterminateMonth =
        checkCount > 0 && checkCount < this.monthsOptions.length;
    },
    handleCheckAllDayChange(val) {
      this.scheduleCronDtoSelected.selectedDays = val
        ? this.daysOptions.map((x) => x.key)
        : [];
      this.isIndeterminateDay = false;
    },
    handleCheckedDayChange(value) {
      let checkCount = value?.length;
      this.checkAllDay = checkCount === this.daysOptions.length;
      this.isIndeterminateDay =
        checkCount > 0 && checkCount < this.daysOptions.length;
    },
  },
};
</script>
<style scoped>
.vis-schedule-time-input{
  z-index: 999;
}
.vis-specific-days {
  width: 20px !important;
  margin-right: 1rem !important;
}
.vis-all-day {
  width: 35px !important;
}

</style>
