export const domain = "VISUALIZE";

export const STATE = {
  TOPBAR_QUERY_PARAM: "TOPBAR_QUERY_PARAM"
};

export const ACTION = {
  API_CALL: `${domain}/apiCall`,
};

export const MUTATION_GENERAL = {
  SET_LOADING: `${domain}/setLoading`,
  ADD_NOTIFY: `${domain}/addNotify`,
  RESET_NOTIFY: `${domain}/resetNotify`,
  SET_TOPBAR_QUERY_PARAM: `${domain}/SET_TOPBAR_QUERY_PARAM`
};

export const GETTER = {
  GET_LOADING: `${domain}/getLoading`,
  GET_NOTIFY: `${domain}/getNotify`,
  GET_TOPBAR_QUERY_PARAM: `${domain}/GET_TOPBAR_QUERY_PARAM`
};

