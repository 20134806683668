<template>
  <div class="new-item-button" draggable @dragstart="startDrag($event)">
    <div>
      <span
        v-if="addStateSelectorValue === options.state"
        class="scenario-add-state-node-icon material-icons-outlined"
      >
        archive
      </span>
      <span
        v-else
        class="scenario-state-finish-node-icon material-icons-outlined"
      >
        flag
      </span>
    </div>
    <div class="scenario-add-state-node-name">
      {{ addStateSelectorValue === options.state ? $t("State") : $t("Finish") }}
    </div>
    <div class="new-item-button-select">
      <span
        class="new-item-button-select-arrow-down material-icons-outlined"
        @click="showAddStateSelect = !showAddStateSelect"
      >
        {{ !showAddStateSelect ? "expand_more" : "expand_less" }}
      </span>
    </div>

    <!-- dropdown options -->
    <div v-if="showAddStateSelect" class="new-item-button-dropdown-options">
      <div
        class="new-item-button-dropdown-options-option"
        @click="selectOption(options.state)"
      >
        <div>
          <span class="scenario-add-state-node-icon material-icons-outlined">
            archive
          </span>
        </div>
        <div class="scenario-add-state-node-name">{{ $t("State") }}</div>
      </div>
      <el-divider></el-divider>

      <div
        class="new-item-button-dropdown-options-option"
        @click="selectOption(options.finish)"
      >
        <div>
          <span
            class="scenario-state-finish-node-icon material-icons-outlined"
          >
            flag
          </span>
        </div>
        <div class="scenario-add-state-node-name">{{ $t("Finish") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAddStateSelect: false,
      addStateSelectorValue: "state",
      options: {
        state: "state",
        finish: "finish",
      },
    };
  },
  created() {
    this.addStateSelectorValue = this.options.state;
  },
  methods: {
    selectOption(value) {
      this.addStateSelectorValue = value;
      this.showAddStateSelect = false;
    },
    startDrag(evt) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";

      // Set custom data using the 'setData' method
      evt.dataTransfer.setData(
        "text/plain",
        JSON.stringify({ dragItemName: this.addStateSelectorValue })
      );
    },
  },
};
</script>

<style scoped>
.new-item-button {
  width: 165px;
  height: 60px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px;
  border: 1px solid rgb(227, 227, 227);
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0 15px 15px;
  gap: 10px;
  cursor: pointer;
}
.new-item-button-dropdown-options {
  position: absolute;
  top: 70px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px;
  border: 1px solid rgb(227, 227, 227);
  border-radius: 10px;
  padding: 10px 15px;
  right: 0;
  display: flex;
  flex-direction: column;
}
::v-deep .el-divider--horizontal {
  margin: 10px 0;
}
.new-item-button-dropdown-options-option {
  display: flex;
  align-items: center;
  gap: 10px;
}
.new-item-button-select {
  width: 32px;
  height: 60px;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scenario-add-state-node-icon {
  font-size: 2rem;
  color: #0014ff;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.scenario-state-finish-node-icon {
  font-size: 2rem;
  color: #c92727;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.scenario-add-state-node-name {
  font-weight: 500;
  font-size: 1rem;
}
</style>
