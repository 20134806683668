<template>
  <div>
    <el-select
      :multiple="multiple"
      collapse-tags
      :allow-create="allowCreate"
      :filterable="filterable"
      :value="value"
      size="mini"
      :placeholder="placeholder"
      @focus="$emit('handleFocus')"
      @change="(value) => $emit('handleChange', value)"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array],
      default: "",
    },
    placeholder: {
      type: String,
      default: ""
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
