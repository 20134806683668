<template>
  <div id="number-range">
    <div class="draft-row vis-flex--Ycenter vis-mb--1 vis-pb--1">
      <div>
        <div>Min</div>
        <el-input
          v-model="inputValues.min"
          type="number"
          @input="setComponentStyleWithDebounce()"
        ></el-input>
      </div>

      <div class="vis-flex--Ycenter seperator">-</div>

      <div>
        <div>Max</div>
        <el-input
          v-model="inputValues.max"
          type="number"
          @input="setComponentStyleWithDebounce()"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import cloneDeep from "clone-deep";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ min: 0, max: 0 }),
    },
  },
  data() {
    return {
      inputValues: {},
    };
  },
  mounted() {
    this.inputValues = cloneDeep(this.value);
  },
  methods: {
    setComponentStyleWithDebounce: _.debounce(function() {
      this.$emit("setComponentStyle", { ...this.inputValues });
    }, 400),
  },
};
</script>
<style scoped>
.seperator {
  margin: 1.2rem 0.5rem 0 0.5rem;
}
</style>
