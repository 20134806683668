<template>
  <div class="vis-flex--center vis-flex--Ycenter vis-flex-direction--column">
    <div v-for="(item, index) in option.response" :key="item.field + index">
      <!-- START VALUES -->
      <div :style="propertyAlignStyleValue(item.boxKey)">
        <span :style="propertyStyleValue(item.boxKey)">
          {{ formatSummary(item.value, item.format) }}
        </span>
        <i
          v-if="isShowArrowIcon(index)"
          :class="arrowIcon"
          class="vis-ml--05"
          :style="getStyleIcon()"
        ></i>
        <!-- START PERCENTAGE VALUE -->
        <span
          v-if="isShowPercentage(item.boxKey)"
          :style="propertyStyleValue(item.boxKey)"
          class="vis-ml--05"
        >
          ({{ option.percentage }}%)</span
        >
        <!-- END PERCENTAGE VALUE -->
      </div>

      <!-- END VALUES -->
      <!-- START LABELS -->
      <p v-if="isShowLabel(item)" :style="propertyStyleValueLabel(item.boxKey)">
        {{ item.alias }}
      </p>
      <!-- END LABELS -->
    </div>
    <div class="vis-text--center" v-if="!panelDataDetailsMetrics.length">
        <img
          src="../../../assets/images/chartEmptyStates/SUMMARY.svg"
          alt="PIVOT_TABLE"
        />
      </div>
  </div>
</template>
<script>
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import { BOX_KEY_ENUM } from "../../../commons/dashboardAndPanel";
import { formatMeasure } from "../../panel/measures/js/formatMeasures";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  props: {
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    panelData: {
      type: Object,
    },
  },
  mixins: [panelDataPropertiesStyle],
  data() {
    return {
      customIcon: CustomIcon,
      propertyPrefixes: {
        TARGET_VALUE: "TARGET_VALUE",
        VALUE: "VALUE",
        TARGET_VALUE_LABEL: "TARGET_VALUE_LABEL",
        VALUE_LABEL: "VALUE_LABEL",
      },
    };
  },
  computed: {
    percentageCalculatedMinus() {
      return this.option?.percentage?.includes("-");
    },
    percentageCalculatedZero() {
      return this.option?.percentage === 0;
    },
    arrowIcon() {
      return this.percentageCalculatedZero
        ? `${this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON}`
        : this.percentageCalculatedMinus
        ? `${this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON}`
        : `${this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON}`;
    },
    //Duruma göre kaldıralacak
    percentageColor() {
      return this.percentageCalculatedZero
        ? `vis-color-gray`
        : this.percentageCalculatedMinus
        ? `vis-color-danger`
        : `vis-color-success`;
    },
    hasTargetValueInMetrics() {
      return this.panelDataDetailsMetrics?.filter(
        (x) => x.boxKey === BOX_KEY_ENUM.TARGET.KEY
      )?.length;
    },
  },
  methods: {
    isShowArrowIcon(index) {
      return (
        index === 0 &&
        this.hasTargetValueInMetrics &&
        this.option?.response?.length &&
        this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TREND_SHOW_TREND_ICON
      );
    },
    isShowPercentage(boxKey) {
      return (
        this.hasTargetField(boxKey) &&
        this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TREND_SHOW_DIFFERENCE
      );
    },
    hasTargetField(boxKey) {
      return boxKey === BOX_KEY_ENUM.TARGET.KEY;
    },
    formatSummary(value, format) {
      if (value >= 0) {
        return formatMeasure(value, format);
      }
      return value;
    },
    isShowLabel(chartData) {
      const isAuthorize = this.panelData?.details?.metrics?.find(
        (x) => x.field === chartData?.field
      )?.isAuthorize;

      if (!isAuthorize) return false;

      if (
        this.hasTargetField(chartData?.boxKey) &&
        this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL
      ) {
        return true;
      } else if (
        chartData?.boxKey === BOX_KEY_ENUM.MEASURES.KEY &&
        this.CHART_COMPUTED_VALUES.SUMMARY_CHART_SHOW_VALUE_LABEL
      ) {
        return true;
      } else return false;
    },
    propertyStyleValue(boxKey) {
      if (this.hasTargetField(boxKey)) {
        return this.getStyleValueByPrefix(this.propertyPrefixes.TARGET_VALUE);
      } else {
        return this.getStyleValueByPrefix(this.propertyPrefixes.VALUE);
      }
    },
    propertyAlignStyleValue(boxKey) {
      let prefix = "";

      if (this.hasTargetField(boxKey))
        prefix = this.propertyPrefixes.TARGET_VALUE;
      else prefix = this.propertyPrefixes.VALUE;

      return {
        display: "flex",
        "align-items": "center",
        "justify-content":
          this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_ALIGN`],
      };
    },
    propertyStyleValueLabel(boxKey) {
      if (this.hasTargetField(boxKey)) {
        return this.getStyleValueLabelByPrefix(
          this.propertyPrefixes.TARGET_VALUE_LABEL
        );
      } else {
        return this.getStyleValueLabelByPrefix(
          this.propertyPrefixes.VALUE_LABEL
        );
      }
    },
    getStyleValueByPrefix(prefix) {
      const fontSize =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_FONT_SIZE`];
      const fontFamily =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_FONT_FAMILY`];
      const fontWeight =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_FONT_WEIGHT`];
      let color =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_FONT_COLOR`];

      if (
        this.CHART_COMPUTED_VALUES[
          `SUMMARY_CHART_${prefix}_SHOW_TREND_COLOR`
        ] &&
        this.hasTargetValueInMetrics
      ) {
        color = this.getStyleIcon()?.color;
      }

      return {
        "font-size": fontSize + "px",
        "font-weight": fontWeight,
        "font-family": fontFamily,
        color: color,
      };
    },
    getStyleValueLabelByPrefix(prefix) {
      const fontSize =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_FONT_SIZE`];
      const fontFamily =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_FONT_FAMILY`];
      const fontWeight =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_FONT_WEIGHT`];
      const color =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_FONT_COLOR`];
      const justifyContent =
        this.CHART_COMPUTED_VALUES[`SUMMARY_CHART_${prefix}_ALIGN`];
      return {
        "font-size": fontSize + "px",
        "font-weight": fontWeight,
        "font-family": fontFamily,
        color: color,
        display: "flex",
        "justify-content": justifyContent,
      };
    },
    getStyleIcon() {
      const fontSize =
        this.CHART_COMPUTED_VALUES[
          `SUMMARY_CHART_${this.propertyPrefixes.VALUE}_FONT_SIZE`
        ];

      const color = this.percentageCalculatedZero
        ? `${this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR}`
        : this.percentageCalculatedMinus
        ? ` ${this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR}`
        : ` ${this.CHART_COMPUTED_VALUES.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR}`;

      return {
        "font-size": fontSize + "px",
        color: color,
      };
    },
  },
};
</script>
<style scoped></style>
