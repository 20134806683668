<template>
  <div class="aside-properties">
    <div class="scenario-aside-title">
      <span class="scenario-aside-title-icon material-icons-outlined">
        archive
      </span>
      {{ $t("State Properties") }}
    </div>
    <div class="scenario-aside-properties-container">
      <el-tabs :stretch="true">
        <el-tab-pane>
          <span slot="label"> {{ $t("General") }}</span>
          <ScenarioStateGeneralProperties
            :properties="properties"
            @updateName="(v) => $emit('updateName', v)"
            @updateScreenNote="(v) => $emit('updateScreenNote', v)"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ScenarioStateGeneralProperties from "./ScenarioStateGeneralProperties.vue";

export default {
  props: {
    properties: {
      type: Object,
    },
  },
  components: {
    ScenarioStateGeneralProperties,
  },
};
</script>

<style scoped>
.aside-properties {
    padding: 20px !important;
}
.scenario-aside {
  display: flex;
  align-items: center;
  padding: 18px 25px;
  height: inherit;
  flex-direction: column;
}
.scenario-aside-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.scenario-aside-title-icon {
  color: #0014ff;
  font-size: 1.625rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.scenario-aside-properties-container {
  width: 100%;
}
.scenario-properties-icon {
  font-size: 1rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  vertical-align: middle;
  margin-right: 2px;
}
</style>
