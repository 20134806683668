<template>
  <div style="padding: 30px">
    <div class="vis-row">
      <div class="vis-col vis-col-6">
        <h3>Draggable 1</h3>
        <draggable
          class="list-group"
          :list="list1"
          :group="{ name: 'people', pull: 'clone', put: false }"
          @change="onDraggableChanged"
        >
          <div
            class="list-group-item"
            v-for="element in list1"
            :key="element.id"
            @click.ctrl="addFirstItem(element)"
            @click.shift="addLastItem(list1, element)"
            :class="{ activeClass: isSelectedActive(element) }"
          >
            {{ element.name }}
          </div>
        </draggable>
      </div>
      <div class="vis-col vis-col-6">
        <h3>Draggable 2</h3>
        <draggable
          class="list-group"
          :list="list2"
          group="people"
          @change="onDraggableChanged"
        >
          <div
            class="list-group-item"
            v-for="element in list2"
            :key="element.id"
          >
            {{ element.name }}
          </div>
        </draggable>
      </div>
      {{ allList }}
      {{ selectedFirstItem }}
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      list1: [
        { id: 1, name: "Fatih" },
        { id: 2, name: "İsim2" },
        { id: 3, name: "İsim3" },
        { id: 4, name: "İsim4" },
        { id: 5, name: "İsim5" },
        { id: 6, name: "İsim6" },
        { id: 7, name: "İsim7" },
      ],
      measureOptions: [
        { key: "equals", value: "=", inputType: "single" },
        { key: "between", value: "between", inputType: "double" },
      ],
      attributeOptions: [
        { key: "equals", value: "=", inputType: "single" },
        { key: "in", value: "In", inputType: "multiSelect" },
      ],
      rulePopupProps: {
        kayitliConditions: {
          fieldId: "2222121212121",
          conditions: [
            {
              format: {
                fontFamily: "Poppins",
                size: 14,
                weight: "medium",
                fontColor: "red",
                chipColor: "blue",
                backgroundColor: "blue",
              },
              rules: [
                {
                  name: "Revenue",
                  operator: "between",
                  value1: 500,
                  value2: 700,
                },
                {
                  name: "Quantity",
                  operator: "between",
                  value1: 700,
                  value2: 1500,
                },
              ],
            },
            {
              format: {
                fontFamily: "Poppins",
                size: 14,
                weight: "medium",
                fontColor: "red",
                chipColor: "blue",
                backgroundColor: "blue",
              },
              rules: [
                {
                  name: "Revenue",
                  operator: "between",
                  value1: 500,
                  value2: 700,
                },
              ],
            },
          ],
        },

        test: {
          fieldId: "2222121212121",
          conditions: [
            {
              format: {
                fontFamily: "Poppins",
                size: 14,
                weight: "medium",
                fontColor: "red",
                chipColor: "blue",
                backgroundColor: "blue",
              },
              rules: [
                {
                  name: "Revenue",
                  operator: "between",
                  value1: 500,
                  value2: 700,
                },
                {
                  name: "Quantity",
                  operator: "between",
                  value1: 700,
                  value2: 1500,
                },
              ],
            },
            {
              format: {
                fontFamily: "Poppins",
                size: 14,
                weight: "medium",
                fontColor: "red",
                chipColor: "blue",
                backgroundColor: "blue",
              },
              rules: [
                {
                  name: "Revenue",
                  operator: "between",
                  value1: 500,
                  value2: 700,
                },
              ],
            },
          ],
        },

        dataItems: [
          {
            field: "COUNTRY",
            alias: "COUNTRY",
            fieldUsageType: "ATTRIBUTE",
            fieldId: "c0a8b00b-851f-159f-8185-29941039005f",
            options: ["Germany", "Turkey"],
          },
          {
            field: "Revenue",
            alias: "Revenue",
            fieldUsageType: "MEASURE",
            fieldId: "c0a8b00b-851f-159f-8185-29941039005f",
            options: null,
          },
        ],
      },
      list2: [],
      allList: [],
      selectedFirstItem: null,
    };
  },
  methods: {
    onDraggableChanged() {
      if (this.allList.length) {
        this.allList.forEach((element) => {
          let sameItem = this.list2.find((x) => x.id === element.id);
          if (!sameItem) this.list2.push(element);
        });
      }
    },
    addFirstItem(element) {
      let firstItem = this.allList.find(
        (f) => f.id === this.selectedFirstItem?.id
      );
      if (firstItem) {
        this.allList = [];
      } else if (this.selectedFirstItem?.id === element.id) {
        this.selectedFirstItem = null;
      } else this.selectedFirstItem = element;
    },
    addLastItem(defaultList, value) {
      console.log(defaultList, value);
      defaultList.forEach((element, index) => {
        let firstItemIndex = defaultList.findIndex(
          (x) => x.id === this.selectedFirstItem.id
        );
        let lastItemIndex = defaultList.findIndex((x) => x.id === value.id);
        if (index >= firstItemIndex && index <= lastItemIndex) {
          this.allList.push(element);
        }
      });
    },
    isSelectedActive(value) {
      return (
        this.allList.find((f) => f.id === value.id) ||
        this.selectedFirstItem?.id === value.id
      );
    },
  },
};
</script>
<style scoped>
.activeClass {
  background-color: green !important;
  border: 1px solid #fff !important;
}
</style>
