<template>
  <div>
    <div
      v-loading="isDatamodelListLoading"
      class="vis-vgt-card vis-dataModel-table-popup w700"
    >
      <div class="vis-vgt-card-title vis-flex--spacebeetwen vis-flex--Ycenter">
        <div v-if="!inFolder">
          <span class="vis-font-medium">
            {{ $t("generalPages.selectDataModel") }}
          </span>
        </div>
        <div v-else>
          <ul class="vis-user-tabs vis-flex--Ycenter">
            <li
              @click="getDatamodelsAndFoldersById(null, null)"
              class="vis-main-breadcrumb vis-ml--none"
            >
              {{ $t("home.all") }}
            </li>
            <div
              v-for="folder in folderNameList"
              :key="folder.id"
              class="vis-flex--Ycenter"
            >
              <li class="vis-main-breadcrumb">
                <i aria-hidden="true" :class="CustomIcon.ArrowRight"></i>
              </li>
              <li
                @click="getDatamodelsAndFoldersById(folder.id, folder.name)"
                :class="{ 'vis-font-medium': folderId == folder.id }"
                class="vis-main-breadcrumb"
              >
                {{ folder.name }}
              </li>
            </div>
          </ul>
        </div>

        <InputSearch @keyupSearchData="searchDataModel = $event" />
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :row-style-class="rowStyleClassFn"
        max-height="400px"
        :fixed-header="true"
        :search-options="{
          enabled: true,
          externalQuery: searchDataModel,
        }"
        @on-row-click="onRowClick"
      >
        <template slot="table-column" slot-scope="props">
          <span>
            {{ $t(`${props.column.label}`) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field == CardFieldsTypeEnum.NAME"
            class="vis-cursor-pointer"
          >
            {{ props.row.name }}
          </span>
          <span v-else-if="props.column.field == CardFieldsTypeEnum.TYPEICON">
            <i :class="getIconByType(props.row)" aria-hidden="true"></i>
          </span>
          <span v-else-if="props.column.field == CardFieldsTypeEnum.CREATED_BY">
            <span>{{ props.row.created_by || props.row.createdBy }}</span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <div class="vis-vgt-card-footer vis-text--right">
        <el-button
          :disabled="!isNextButtonActive"
          @click="setDataModel()"
          type="primary"
          size="small"
          >{{ $t("generalPages.done") }}</el-button
        >
      </div>
    </div>
    <div class="vis-popup-mask" />
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import InputSearch from "../helper/Form/InputSearch.vue";
import { CustomIcon } from "../../assets/js/custom-icons";
import { CardFieldsTypeEnum, CardType} from "../../util/homePageMappers";
export default {
  components: { VueGoodTable, InputSearch },
  props: {
    rows: {
      type: Array,
      required: false,
    },
    isDatamodelListLoading: {
      type: Boolean,
    },
    folderNameList: {
      type: Array,
    },
  },
  data() {
    return {
      columns: [
        {
          label: "",
          field: "typeIcon",
          width: "30px",
          sortable: false,
        },
        {
          label: "generalPages.labelName",
          field: "name",
        },
        {
          label: "generalPages.labelOwner",
          field: "created_by",
        },
      ],
      isNextButtonActive: false,
      searchDataModel: "",
      inFolder: false,
      folderId: null,
      CustomIcon: CustomIcon,
      CardFieldsTypeEnum: CardFieldsTypeEnum,
      selectedRow: "",
      datamodelObj: {},
    };
  },
  methods: {
    onRowClick(params) {
      this.selectedRow = params.row.originalIndex;
      if (params.row?.type === CardType.DATAMODEL) {
        this.datamodelObj = {
          id: params.row.id,
          name: params.row.name,
        };
        this.isNextButtonActive = true;
      } else {
        this.getDatamodelsAndFoldersById(params.row.id, params.row.name);
        this.inFolder = true;
        this.isNextButtonActive = false;
      }
    },
    rowStyleClassFn(row) {
      return row.originalIndex === this.selectedRow ? "vis-selectedRow" : "";
    },
    getIconByType(row) {
      return row.type === CardType.DATAMODEL
        ? "vis-icon icon-account_tree vis-color-success"
        : "vis-icon icon-folder vis-color-folder";
    },

    getDatamodelsAndFoldersById(id, name) {
      this.$emit("getDatamodelsAndFoldersById", id, name);
    },

    setDataModel() {
      this.$emit("setDataModel",  this.datamodelObj);
    },
  },
};
</script>
<style scoped>
.vis-dataModel-table-popup {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.vis-popup-mask {
  background: rgba(0, 0, 0, 0.007);
}
.vis-vgt-card-title {
  border-bottom: none;
  height: 48px;
}
</style>
