<template>
  <div id="jsplumb-vue2-data-flow-editor">
    <div class="jtk-demo-main" id="jtk-demo-flowchart">
      <div id="canvas" class="jtk-demo-canvas">
        <JsplumbVue2Controls
          ref="JsplumbVue2Controls"
          surface-id="surface"
        ></JsplumbVue2Controls>
        <JsplumbVue2Flowchart
          surface-id="surface"
          :editorData="editorData"
          :positionsAndLevels="positionsAndLevels"
          @showConnectionPopup="
            (params) => $emit('showConnectionPopup', params)
          "
          @updatePositionsAndLevels="
            (params) => $emit('updatePositionsAndLevels', params)
          "
          @onDatasetClicked="(params) => $emit('onDatasetClicked', params)"
          @onEditClicked="(id) => $emit('onEditClicked', id)"
          @onDeleteClicked="(id) => $emit('onDeleteClicked', id)"
          @onAppendClicked="(id) => $emit('onAppendClicked', id)"
          @onReplaceClicked="(id) => $emit('onReplaceClicked', id)"
          @onDuplicateClicked="(id) => $emit('onDuplicateClicked', id)"
          @onRemoveConnection="(id) => $emit('onRemoveConnection', id)"
          @onShowPreview="(id) => $emit('onShowPreview', id)"
          @onShowDataModelProperties="(isShow) =>$emit('onShowDataModelProperties', isShow)"
        ></JsplumbVue2Flowchart>
      </div>
    </div>
  </div>
</template>

<style>
@import "../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit.css";
@import "../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-dialogs.css";
@import "../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-demo-support.css";
@import "../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-connector-editors.css";
@import "../../../assets/css/flowchart.css";
</style>

<script>
import JsplumbVue2Flowchart from "./JsplumbVue2Flowchart.vue";
import JsplumbVue2Controls from "./JsplumbVue2Controls.vue";

export default {
  name: "JsplumbVue2DataFlowEditor",
  components: {
    JsplumbVue2Flowchart,
    JsplumbVue2Controls,
  },
  props: {
    connections: {
      type: Array,
    },
    nodes: {
      type: Array,
    },
    positionsAndLevels: {
      type: Object,
    },
  },

  computed: {
    editorData() {
      const nodes = this.nodes?.map((node) => {
        return {
          ...node,
          datasetType: node.type,
          type: "dataset",
          w: 200,
          h: 132,
        };
      });

      const edges = this.connections?.map((connection) => {
        return {
          source: connection.leftDatasetId,
          target: connection.rightDatasetId,
          data: {
            type: "datasetJoin",
            relationType: connection.relationType,
            operator: connection.operator,
            connectionId: connection.id,
            leftDatasetFieldId: connection.leftDatasetFieldId,
            rightDatasetFieldId: connection.rightDatasetFieldId,
          },
        };
      });

      return {
        nodes,
        edges,
      };
    },
  },

  methods: {
    cancelLinking() {
      this.$refs.JsplumbVue2Controls.undo();
    },
  },
};
</script>
<style scoped>
.jtk-demo-main {
  height: auto !important;
}
</style>
