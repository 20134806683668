import {
    tabsPropertiesEnum,
    dashboardGridPropertiesEnum
} from "../commons/dashboardProperties";
import { v4 as uuidv4 } from "uuid";
const propertiesStyle = {
    [tabsPropertiesEnum.HAS_TABS]: true,
    [dashboardGridPropertiesEnum.DASHBOARD_GRID_HAS_GRID_LINES]: true,
}
export const dashboardData = {
    name: "",
    description: "",
    create_by: "",
    created_date: "",
    update_by: "",
    updated_date: "",
    datamodel_id: null,
    folderId: null,
    properties: {},
    tabs: [
        {
            name: "Tab 1",
            description: "Tab",
            created_by: "",
            created_date: "",
            updated_by: "",
            updated_date: "",
            order: 1,
            pin: true,
            properties: {
                uiTabId: uuidv4(),
                style: propertiesStyle
            },
            charts: [],
        },
    ],
};
export const defaultTabData = {
    name: "Tab Name",
    description: "Tab",
    created_by: "",
    created_date: "",
    updated_by: "",
    updated_date: "",
    order: 1,
    pin: true,
    properties: {
        style: propertiesStyle

    },
    charts: [],
}