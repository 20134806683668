<template>
  <div class="confirmable-input">
    <label class="confirmable-input-label">{{ label }}</label>
    <div class="confirmable-input-body">
      <input
        class="vis-input"
        :class="{ 'vis-readonly-background': isInputReadonly }"
        :value="valueTemporary"
        @keyup="(e) => (valueTemporary = e.target.value)"
        :readonly="isInputReadonly"
      />
      <div v-if="isConfirmableInputActionsVisible" class="confirmable-input-actions">
        <template v-if="!isEditMode">
          <i
            :class="CustomIcon.Pencil"
            class="vis-cursor-pointer"
            aria-hidden="true"
            @click="isEditMode = true"
        /></template>
        <template v-else>
          <i
            :class="CustomIcon.Close"
            class="vis-cursor-pointer"
            aria-hidden="true"
            @click="cancel"
          />
          <el-popconfirm
            class="confirmable-input-popconfirm"
            :title="$t('dialog.Are you sure to update this field?')"
            :confirm-button-text="$t('dialog.confirm')"
            :cancel-button-text="$t('dialog.cancel')"
            @confirm="approve"
            @cancel="cancel"
          >
            <i
              slot="reference"
              :class="CustomIcon.Checked"
              class="vis-cursor-pointer"
              aria-hidden="true"
            />
          </el-popconfirm>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { tempDefaultJobPrefix } from "../../commons/ingestDefaultData";

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      isEditMode: false,
      valueTemporary: null,
    };
  },
  watch: {
    value: {
      handler() {
        this.valueTemporary = this.value;
      },
      immediate: true,
    },
    valueTemporary: {
      handler() {
        if (this.isJobNewlyCreated) {
          this.$emit("change", this.valueTemporary);
        }
      },
    },
  },
  computed: {
    isJobNewlyCreated() {
      return this.jobId?.includes(tempDefaultJobPrefix);
    },
    isInputReadonly() {
      if (this.readonly) return true;
      if (this.isJobNewlyCreated) return false;
      if (!this.isEditMode) return true;

      return false;
    },
    isConfirmableInputActionsVisible() {
      if (this.readonly) return false;
      if (this.isJobNewlyCreated) return false;
      if (this.isEditMode) return true;

      return true;
    },
  },
  methods: {
    cancel() {
      this.isEditMode = false;
      this.valueTemporary = this.value;
    },
    approve() {
      this.isEditMode = false;
      this.$emit("change", this.valueTemporary);
    },
  },
};
</script>

<style scoped>
.confirmable-input-body {
  display: flex;
  align-items: center;
  gap: 10px;
}
.confirmable-input-actions {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
