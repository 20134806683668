<template>
  <div class="vis-height-100">
    <div class="vis-flex--spacebeetwen data-model-sql-preview-header-container">
      <div>
        <span class="data-model-sql-preview-header">{{
          $t("generalPages.preview") + " - " + datasetName
        }}</span>
        <el-select
          v-if="isSelectDatasetVisible"
          :value="selectedDatasetId"
          :placeholder="$t('dashboard.SelectDataset')"
          size="mini"
          class="select-dataset-dropdown"
          @change="(val) => $emit('datasetChanged', val)"
        >
          <el-option
            v-for="item in datasets"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <i
        v-if="previewable"
        @click.stop="$emit('previewTable')"
        class="vis-cursor-pointer vis-mr--1_5"
        :class="handleViewIcon()"
        aria-hidden="true"
      />
    </div>
    <vue-good-table
      v-loading="isPreviewLoading"
      :columns="columns"
      :rows="rows"
      :fixed-header="false"
    >
      >
      <div class="vis-text--center" slot="emptystate">
        {{ $t("generalPages.noData") }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { mapGetters } from "vuex";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  components: { VueGoodTable },
  props: {
    hasMaxHeight: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      required: false,
    },
    rows: {
      type: Array,
      required: false,
    },
    previewable: {
      type: Boolean,
      required: false,
    },
    selectedDataset: {
      type: Object,
      required: false,
    },
    datasets: {
      type: Array,
      required: false,
    },
    isSelectDatasetVisible: {
      type: Boolean,
      default: false,
    },
    datasetName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  computed: {
    ...mapGetters({
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    isPreviewLoading() {
      return this.loading[LoadingComponent.RunSql];
    },
    selectedDatasetId(){
      return this.selectedDataset?.datasetId;
    }
  },
  methods: {
    handleViewIcon(){
      return this.isSelectDatasetVisible ? 
      CustomIcon.VisibilityEye : 
      CustomIcon.Close
    }
  },
};
</script>
<style scoped>
.select-dataset-dropdown {
  width: auto !important;
}
.data-model-sql-preview-header {
  padding: 0 20px;
  display: inline-block;
}
::v-deep .vgt-table {
  border-spacing: 0;
  border-top: 0 !important;
}
::v-deep .vgt-table thead {
  position: sticky;
  top: 0;
}
::v-deep .vgt-table thead tr:first-child th {
  border-top: 1px solid #dcdfe6 !important;
}
::v-deep .vgt-table th,
.vgt-table td {
  border-width: 0.5px !important;
}
::v-deep .vgt-wrap {
  height: calc(100vh - 140px);
}
::v-deep .vgt-inner-wrap {
  height: 100% !important;
}
::v-deep .vgt-responsive {
  height: 100% !important;
}
</style>
