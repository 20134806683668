<template>
  <div class="environments-list-container">
    <div class="environments-list-header">
      <span>{{ $t("admin.menuItem.labelEnvironments") }}</span>

      <el-button
        @click="addNewEnvironmentItem()"
        class="vis-mr--2 vis-cursor-pointer el-button--with-icon environments-list-header-add-button"
        type="text"
      >
        <i aria-hidden="true" :class="customIcon.AddBox"></i
        >{{ $t("admin.environment.Add environment") }}</el-button
      >
    </div>
    <div class="environments-list-content vgt-responsive">
      <EnvironmentItem
        v-for="environment in environments"
        :key="environment.id || environment.clientId"
        :item="environment"
        @deleteEnvironmentItem="deleteEnvironmentItem"
        @saveEnvironmentItem="saveEnvironmentItem"
      />
    </div>
  </div>
</template>

<script>
import EnvironmentItem from "./EnvironmentItem.vue";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from "clone-deep";

// Store
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ACTION as ACTION_ENVIRONMENT,
  GETTER as GETTER_ENVIRONMENT,
  MUTATION as MUTATION_ENVIRONMENT,
} from "../../store/modules/Transfer/Environments/types";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  components: {
    EnvironmentItem,
  },
  data() {
    return { customIcon: CustomIcon };
  },
  computed: {
    ...mapGetters({
      environments: GETTER_ENVIRONMENT.GET_ENVIRONMENTS,
    }),
  },
  async mounted() {
    await this.fetchEnvironments({
      loadingComponent: LoadingComponent.Environments,
    });

    if (!this.environments.length) {
      this.addNewEnvironmentItem(true);
    }
  },
  methods: {
    ...mapActions({
      fetchEnvironments: ACTION_ENVIRONMENT.FETCH_ENVIRONMENTS,
      createEnvironment: ACTION_ENVIRONMENT.CREATE_ENVIRONMENT,
      updateEnvironment: ACTION_ENVIRONMENT.UPDATE_ENVIRONMENT,
      deleteEnvironment: ACTION_ENVIRONMENT.DELETE_ENVIRONMENT,
    }),
    ...mapMutations({
      setEnvironments: MUTATION_ENVIRONMENT.SET_ENVIRONMENTS,
    }),
    addNewEnvironmentItem(isThisEnvironment) {
      const environmentsClone = cloneDeep(this.environments);

      environmentsClone.push({
        clientId: uuidv4(),
        name: "",
        address: "",
        keycloakDomain: "",
        keycloakRealm: "",
        keycloakUserName: "",
        keycloakPassword: "",
        isThisEnvironment,
      });

      this.setEnvironments(environmentsClone);
    },
    deleteEnvironmentItem(item) {
      // if there is clientId, it means environment was not saved to backend yet. Delete on client side
      if (item.clientId) {
        this.setEnvironments(
          this.environments.filter((e) => e.clientId !== item.clientId)
        );
      }
      // if there is no clientId, it means environment was saved to backend. Delete on server side (send request)
      else {
        this.deleteEnvironment({ queryParam: item.id });
      }
    },
    saveEnvironmentItem(item) {
      // if there is clientId, it means environment was not saved to backend yet. Use create
      if (item.clientId) {
        // eslint-disable-next-line no-unused-vars
        const { clientId, ...others } = item;

        this.createEnvironment(others);
      }
      // if there is no clientId, it means environment was saved to backend. Use update
      else {
        this.updateEnvironment({ queryParam: item.id, bodyParam: item });
      }
    },
  },
};
</script>

<style scoped>
.environments-list-container {
  background: #fff;
  width: 100%;
  height: 100%;
}
.environments-list-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding: 20px 30px 0;
  font-size: 26px;
  font-weight: 600;
  color: #3e3e3e;
}
.environments-list-header-add-button {
  margin-left: auto;
}
.environments-list-content {
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow-y: auto;
  height: calc(100% - 66px);
}
.vgt-responsive {
  max-height: 800px;
}
</style>
