<template>
  <div
    id="jsplumb-vue2-data-flow-editor"
    @dragover.prevent
    @dragenter.prevent
    @drop="onDrop($event)"
  >
    <div class="jtk-demo-main" id="jtk-demo-flowchart">
      <div id="canvas" class="jtk-demo-canvas">
        <JsplumbVue2Controls
          ref="JsplumbVue2Controls"
          surface-id="surface"
        ></JsplumbVue2Controls>
        <JsplumbVue2Flowchart
          ref="flowChartComponent"
          surface-id="surface"
          :nodesAndEdges="nodesAndEdges"
          @updatePositionsAndLevels="
            (params) => $emit('updatePositionsAndLevels', params)
          "
          @dataset-clicked="(param) => $emit('dataset-clicked', param)"
          @database-clicked="(param) => $emit('database-clicked', param)"
          @onRunJob="(param) => $emit('onRunJob', param)"
          @onShowPreview="(param) => $emit('onShowPreview', param)"
          @onEditClicked="(param) => $emit('onEditClicked', param)"
          @onDeleteClicked="(param) => $emit('onDeleteClicked', param)"
          @onCanvasClicked="() => $emit('onCanvasClicked')"
          @addNewTransition="(data) => $emit('addNewTransition', data)"
          @onTransitionDeleteClick="
            (param) => $emit('onTransitionDeleteClick', param)
          "
        ></JsplumbVue2Flowchart>
      </div>
    </div>
  </div>
</template>

<style>
@import "../../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit.css";
@import "../../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-dialogs.css";
@import "../../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-demo-support.css";
@import "../../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-connector-editors.css";
@import "../../../../assets/css/flowchart.css";
</style>

<script>
import JsplumbVue2Flowchart from "./JsplumbVue2Flowchart.vue";
import JsplumbVue2Controls from "./JsplumbVue2Controls.vue";
import { IngestNodeTypes } from "../../enums";

export default {
  name: "JsplumbVue2DataFlowEditor",
  components: {
    JsplumbVue2Flowchart,
    JsplumbVue2Controls,
  },
  props: {
    nodesAndEdges: {
      type: Object,
    },
  },
  data() {
    return {
      isDragDropAvailable: {
        dataset: true,
        database: true,
      },
    };
  },
  methods: {
    onDrop(evt) {
      evt.preventDefault();

      const canvasOffset =
        this.$refs.flowChartComponent.$refs.toolkitComponent.surface
          .getContainer()
          .getBoundingClientRect();
      const dropPosition = {
        left: evt.clientX - canvasOffset.left,
        top: evt.clientY - canvasOffset.top,
      };

      const dropData = JSON.parse(evt.dataTransfer.getData("text/plain"));

      if (
        dropData.dragItemName === IngestNodeTypes.Database &&
        this.isDragDropAvailable.database
      ) {
        this.$emit("addNewDatabaseNode", dropPosition);
      } else if (
        dropData.dragItemName === IngestNodeTypes.Dataset &&
        this.isDragDropAvailable.dataset
      ) {
        this.$emit("addNewDatasetNode", dropPosition);
      }
    },
  },
};
</script>
<style scoped>
.jtk-demo-main {
  height: auto !important;
}
</style>
