import { MUTATION, GETTER, STATE, TEMP_STORAGE_KEYS } from "./types";
import { dashboardData } from "../../../commons/dashboardDefaultData"
import cloneDeep from "clone-deep";
import Vue from "vue";
import { groupBy } from "lodash";
import { ingestData } from "../../../commons/ingestDefaultData";
import { scenarioData } from "../../../commons/scenarioDefaultData";

export const visTempstorage = {
  state: {
    [STATE.TEMP_STORAGE]: {
      [TEMP_STORAGE_KEYS.TEMP_DATASET_FIELDS]: {},
      [TEMP_STORAGE_KEYS.TEMP_SELECTED_DASHBOARD]: cloneDeep(dashboardData),
      [TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST]: cloneDeep(ingestData),
      [TEMP_STORAGE_KEYS.TEMP_NOT_MODIFIED_SELECTED_INGEST]: cloneDeep(ingestData),
      [TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO]: cloneDeep(scenarioData),
    },
  },
  getters: {
    [GETTER.GET_TEMP_STORAGE_BY_KEY]: (state) => (key) => {
      return state[STATE.TEMP_STORAGE]?.[key];
    },
  },
  mutations: {
    [MUTATION.SET_TEMP_STORAGE_DATASET_FIELDS]: (state, values) => {
      Vue.set(state[STATE.TEMP_STORAGE], [TEMP_STORAGE_KEYS.TEMP_DATASET_FIELDS], groupBy(values, x => x.datasetId));
    },
    [MUTATION.RESET_TEMP_STORAGE_DATASET_FIELDS]: (state) => {
      Vue.set(state[STATE.TEMP_STORAGE], [TEMP_STORAGE_KEYS.TEMP_DATASET_FIELDS], {});
    },
    [MUTATION.SET_TEMP_STORAGE_BY_KEY]: (state, { key, value }) => {
      Vue.set(state[STATE.TEMP_STORAGE], key, value);
    },
    [MUTATION.RESET_TEMP_STORAGE_BY_KEY](state, { key, value }) {
      Vue.set(state[STATE.TEMP_STORAGE], key, value);
    },
    [MUTATION.RESET_TEMP_STORAGE](state) {
      Vue.set(state, STATE.TEMP_STORAGE, {});
    },
    [MUTATION.UPDATE_TEMP_STORAGE_DATASET_FIELDS]: (state, values) => {
      Vue.set(state[STATE.TEMP_STORAGE], [TEMP_STORAGE_KEYS.TEMP_DATASET_FIELDS], values);
    },
  },
};
