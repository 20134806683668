<template>
  <layout
    articleId="article"
    class="ingest-layout"
    :showAside="showAside"
    :showNav="!showSqlEditor"
  >
    <div slot="modals">
      <ConfirmDialog
        :title="$t('dialog.unsaveTitle')"
        :message="$t('dialog.unsaveMessage')"
        :dialogVisible="unsavedDataDialogVisible"
        @cancel="unsavedDataDialogVisible = $event"
        @confirm="confirmUnsavedDialog"
      />
      <el-dialog
        :title="$t('ingest.Select a database connection')"
        :visible.sync="showConnectionPopup"
        width="1000px;"
        :before-close="handleConnectionPopupClose"
        class="select-database-connection-dialog"
      >
        <SelectDatabaseConnection
          v-if="showConnectionPopup"
          :hide-actions="true"
          :filterConnectionTypeBy="filterConnectionTypeBy"
          @selectConnection="selectConnection"
        />
        <span slot="footer" class="dialog-footer">
          <el-button
            class="vis-cancel-btn"
            type="text"
            @click="cancelSelectedConnection"
            >{{ $t("generalPages.cancel") }}</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="confirmSelectedConnection"
          >
            {{ $t("generalPages.save") }}
          </el-button>
        </span>
      </el-dialog>

      <ProjectSavePopup
        v-if="isActiveProjectNamePopup"
        :title="$t('generalPages.saveDataFlow')"
        :projectName="tempSelectedIngest.name"
        :tableRows="folders"
        :folderId="folderId"
        :folderNameList="folderNameList"
        :isActiveProjectNamePopup="isActiveProjectNamePopup"
        @setFolderId="setFolderId"
        @getFoldersById="getFoldersById"
        @setProjectName="setDataFlowName"
        @closePopup="isActiveProjectNamePopup = $event"
      />
    </div>
    <div slot="header">
      <IngestTopBar
        :showIngestActions="true"
        :isSaveAvailable="isDataFlowEditAvailable"
        @save="handleIngestSave"
        @toggleShowAside="showAside = !showAside"
      />
    </div>

    <div slot="aside">
      <div v-if="rightProperties.job">
        <IngestJobProperties
          ref="ingestProperties"
          :showOnlyGeneral="showSqlEditor"
          :job="selectedJobProperties"
          :connections="connections"
          :columnList="columnList"
          :showSqlEditor="showSqlEditor"
          @updateJob="updateJob"
        />
      </div>
      <div v-if="rightProperties.source">
        <IngestSourceProperties
          :sourceProperties="selectedSourceProperties"
          :connections="connections"
          @showConnectionPopup="showConnectionPopup = true"
        />
      </div>
      <div v-if="rightProperties.dataFlow">
        <IngestDataFlowProperties
          :properties="tempSelectedIngest"
          :isEditMode="!!routeId"
          @updateProperties="updateProperties"
        />
      </div>
    </div>

    <div slot="nav" class="vbox h-100">
      <IngestLeftPanel />
    </div>
    <div
      slot="article"
      class="vis-height-100"
      :class="showDataFlowBackground ? 'ingest-background' : ''"
    >
      <div
        class="vis-ingest-cards-and-preview-container"
        v-loading.fullscreen.lock="isDataFlowLoading"
      >
        <!-- CARD CONTAINER -->
        <div
          class="vis-row-box ingest-cards-items"
          :style="!showPreviewTable ? 'height: 100%' : ''"
        >
          <IngestSqlEditor
            v-if="showSqlEditor"
            :textToBeAddedToSql="textToBeAddedToSql"
            :connection="sourceConnectionId"
            @close="showSqlEditor = false"
            @resetTextToBeAddedToSql="textToBeAddedToSql = ''"
            @saveSql="saveSql"
            @runSql="
              (updatedSql) =>
                runPostRdbPreview(
                  sourceConnectionId,
                  rightProperties.jobId,
                  updatedSql
                )
            "
          />
          <JsplumbVue2DataFlowEditor
            v-if="!showSqlEditor"
            ref="JsplumbVue2DataFlowEditor"
            :nodesAndEdges="nodesAndEdges"
            @updatePositionsAndLevels="updatePositionsAndLevels"
            @dataset-clicked="clickDataset"
            @database-clicked="clickDatabase"
            @onShowPreview="runFetchIngestTestQuery"
            @onRunJob="handleRunJob"
            @onEditClicked="clickSqlEdit"
            @onDeleteClicked="handleDatasetDelete"
            @addNewDatabaseNode="addNewDatabaseNode"
            @addNewDatasetNode="addNewDatasetNode"
            @onCanvasClicked="handleCanvasClicked"
            @addNewTransition="addNewTransition"
            @onTransitionDeleteClick="handleTransitionDelete"
          />
        </div>

        <!-- PREVIEW CONTAINER -->
        <div
          class="vis-row-box preview-container"
          :style="!showPreviewTable ? 'height: 0' : ''"
        >
          <IngestPreviewTable
            v-if="showSqlEditor"
            :showPreviewTable="showPreviewTable"
            :columns="
              getPreviewTableColumnsByJobId(
                getRdbPreviewByJobId(rightProperties.jobId)
              )
            "
            :rows="
              getPreviewTableRowsByJobId(
                getRdbPreviewByJobId(rightProperties.jobId)
              )
            "
            @previewTable="onClosePreview"
          />
          <IngestPreviewTable
            v-else
            :showPreviewTable="showPreviewTable"
            :columns="getPreviewTableColumnsByJobId(ingestTestQueryResult)"
            :rows="getPreviewTableRowsByJobId(ingestTestQueryResult)"
            @previewTable="onClosePreview"
          />
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
//Layout
import Layout from "../layout/Layout.vue";
import IngestTopBar from "./../components/ingest/IngestTopBar";
import SelectDatabaseConnection from "../components/connection/SelectDatabaseConnection";

import JsplumbVue2DataFlowEditor from "../components/ingest/dataFlowEditor/components/JsplumbVue2DataFlowEditor.vue";
import IngestJobProperties from "../components/ingest/IngestJobProperties.vue";
import IngestSourceProperties from "../components/ingest/IngestSourceProperties.vue";
import IngestDataFlowProperties from "../components/ingest/IngestDataFlowProperties.vue";
import IngestPreviewTable from "../components/ingest/IngestPreviewTable.vue";
import IngestSqlEditor from "../components/ingest/IngestSqlEditor.vue";
import IngestLeftPanel from "../components/ingest/IngestLeftPanel.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GETTER as GETTER_DATAFLOW,
  ACTION as ACTION_DATAFLOW,
  MUTATION as MUTATION_DATAFLOW,
} from "../store/modules/Ingest/DataFlow/types";
import {
  GETTER as GETTER_CONNECTIONS,
  ACTION as ACTION_CONNECTIONS,
} from "../store/modules/Visualize/Connections/types";
import {
  TEMP_STORAGE_KEYS,
  GETTER as GETTER_TEMP_STORAGE,
  MUTATION as MUTATION_TEMP_STORAGE,
} from "../store/modules/temp-storage/types";
import {
  GETTER as GETTER_FOLDER,
  ACTION as ACTION_FOLDER,
} from "../store/modules/Visualize/Folder/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import { ACTION as ACTION_TEST_QUERY } from "../store/modules/Visualize/TestQuery/types";
import cloneDeep from "clone-deep";
import { DB_TYPE } from "../commons/connection";
import {
  createDefaultJob,
  createDefaultSourceConnection,
  ingestData,
  scheduleModes,
} from "../commons/ingestDefaultData";
import { routerEnums } from "../commons/Enum";
import deepEqual from "deep-equal";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import { Notify } from "../commons/helper.js";
import { notificationType } from "../commons/notificationTypes";
import ProjectSavePopup from "../components/helper/ProjectSavePopup.vue";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";
import { AuthorizationGeneralEnum } from "../util/homePageMappers";

export default {
  components: {
    Layout,
    IngestTopBar,
    JsplumbVue2DataFlowEditor,
    IngestJobProperties,
    IngestSourceProperties,
    IngestDataFlowProperties,
    IngestLeftPanel,
    SelectDatabaseConnection,
    IngestPreviewTable,
    IngestSqlEditor,
    ProjectSavePopup,
    ConfirmDialog,
  },
  data() {
    return {
      unsavedDataDialogVisible: false,
      unsavedRouteName: "",
      confirmLeave: false,
      ingestTestQueryResult: {
        columns: [],
        rows: [],
      },
      temporarySelectedConnection: null,
      showAside: true,
      showConnectionPopup: false,
      rightProperties: {
        job: false,
        jobId: null,
        source: false,
        sourceNodeId: null,
        dataFlow: true,
      },
      showPreviewTable: false,
      showSqlEditor: false,
      textToBeAddedToSql: "",
      sql: null,
      sourceConnectionId: null,
      nodesAndEdges: null,
      DB_TYPE: DB_TYPE,
      isActiveProjectNamePopup: false,
      folderNameList: [],
      folderId: null,
      fetchedDataFlowHighestPriority: null,
      filterConnectionTypeBy: [
        DB_TYPE.SYBASE_ASE,
        DB_TYPE.SYBASE_IQ,
        DB_TYPE.ORACLE,
        DB_TYPE.MSSQL,
      ],
    };
  },
  watch: {
    tempSelectedIngest: {
      handler(newValue, oldValue) {
        // iterate over jobs and check if the sql or sourceConnectionId is changed then send a request to get the preview
        if (newValue?.jobs && oldValue?.jobs) {
          newValue.jobs.forEach((job) => {
            const oldJob = oldValue.jobs.find((j) => j.id === job.id);
            if (
              oldJob &&
              (oldJob.sql !== job.sql ||
                oldJob.sourceConnectionId !== job.sourceConnectionId)
            ) {
              this.runPostRdbPreview(
                job.sourceConnectionId,
                job.id,
                job.sql,
                false
              );
            }
          });
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.init();
  },
  beforeRouteLeave(to, from, next) {
    if (this.confirmLeave) {
      next();
    }

    const updatePayload = this.prepareUpdatePayload(
      this.tempNotModifiedSelectedIngest,
      this.tempSelectedIngest
    );

    if (
      updatePayload?.createdJobs?.length === 0 &&
      updatePayload?.updatedJobs?.length === 0 &&
      updatePayload?.deletedJobs?.length === 0
    ) {
      next();
    } else {
      if (to.name === routerEnums.INGEST_EDIT) {
        next();
      } else {
        this.unsavedDataDialogVisible = true;
        this.unsavedRouteName = to.name;
        next(false);
      }
    }
  },
  beforeDestroy() {
    this.setTempStorageByKey({
      key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
      value: cloneDeep(ingestData),
    });
  },
  computed: {
    ...mapGetters({
      selectedDataFlowDetails: GETTER_DATAFLOW.GET_SELECTED_DATAFLOW_DETAILS,
      rdbPreview: GETTER_DATAFLOW.GET_RDB_PREVIEW,
      connections: GETTER_CONNECTIONS.GET_CONNECTIONS,
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
      loading: GETTER_GENERAL.GET_LOADING,
      folders: GETTER_FOLDER.GET_FOLDERS,
    }),
    isDataFlowLoading() {
      return this.loading[LoadingComponent.DataFlow];
    },
    getClickhouseDatabase() {
      return this.connections.find((c) => c.type === DB_TYPE.CLICKHOUSE);
    },
    tempNotModifiedSelectedIngest() {
      return this.tempStorageBykey(
        TEMP_STORAGE_KEYS.TEMP_NOT_MODIFIED_SELECTED_INGEST
      );
    },
    tempSelectedIngest() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST);
    },
    selectedJobProperties() {
      return (
        this.tempSelectedIngest?.jobs?.find(
          (job) =>
            job.id === this.rightProperties.jobId ||
            job.sourceConnectionNodeId === this.rightProperties.sourceNodeId
        ) ?? {}
      );
    },
    isDataFlowEditAvailable() {
      return (
       // TODO DataFlow edit sayfa kontrolü eklenmesi -> this.isComponentAvailable(COMPONENT_PRIVILEGES.DATAMODEL_PAGE_EDIT)
        this.doesUserHaveEditAuthority
      );
    },
    doesUserHaveEditAuthority() {
      return (
        this.fetchedDataFlowHighestPriority &&
        this.fetchedDataFlowHighestPriority?.authority !==
        AuthorizationGeneralEnum.READ
      );
    },
    selectedSourceProperties() {
      return (
        this.tempSelectedIngest?.properties?.sources?.find(
          (source) => source.id === this.rightProperties.sourceNodeId
        ) ?? {}
      );
    },
    columnList() {
      const rdbPreviewByJobId = this.getRdbPreviewByJobId(
        this.rightProperties.jobId
      );

      return (
        rdbPreviewByJobId?.columns?.map((c) => ({
          label: c.className,
          value: c.className,
          classType: c.classType,
        })) ?? []
      );
    },
    showDataFlowBackground() {
      return !this.showSqlEditor;
    },
    routeId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions({
      fetchDataFlowDetailsById: ACTION_DATAFLOW.FETCH_DATAFLOW_DETAILS_BY_ID,
      postRdbPreview: ACTION_DATAFLOW.POST_RDB_PREVIEW,
      postRdbSave: ACTION_DATAFLOW.POST_RDB_SAVE,
      putRdbSave: ACTION_DATAFLOW.PUT_RDB_SAVE,
      postRdbRun: ACTION_DATAFLOW.POST_RDB_RUN,
      fetchConnections: ACTION_CONNECTIONS.FETCH_CONNECTIONS,
      fetchTestQuery: ACTION_TEST_QUERY.FETCH_TEST_QUERY,
      fetchFolders: ACTION_FOLDER.FETCH_FOLDERS,
      getHighestPriorityByDataFlowId:
      ACTION_DATAFLOW.FETCH_HIGHEST_PRIORITY_BY_DATAFLOW_ID,
    }),
    ...mapMutations({
      setTempStorageByKey: MUTATION_TEMP_STORAGE.SET_TEMP_STORAGE_BY_KEY,
      setRdbPreviewByJobId: MUTATION_DATAFLOW.SET_RDB_PREVIEW,
    }),
    confirmUnsavedDialog() {
      this.unsavedDataDialogVisible = false;
      this.confirmLeave = true;
      this.$router.push({ name: this.unsavedRouteName });
    },
    async setDataFlowName(name) {
      this.handleIngestCreate(name);
    },
    checkBreadcrumb(id, name) {
      if (!id && !name) {
        this.folderNameList = [];
      }
      if (id && name) {
        const selectedBreadcrumb = this.folderNameList.find((x) => x.id == id);

        if (!selectedBreadcrumb) {
          this.folderNameList.push({ id, name });
        } else {
          const selectedFolderIndex = this.folderNameList.findIndex(
            (y) => y.id == selectedBreadcrumb.id
          );

          this.folderNameList = this.folderNameList.filter(
            (f, index) => index <= selectedFolderIndex
          );
        }
      }
    },
    getFoldersById(id, name) {
      this.folderId = id;
      this.checkBreadcrumb(id, name);
      this.getFolderList();
    },
    setFolderId(id) {
      this.folderId = id;
    },
    async getFolderList() {
      await this.fetchFolders({
        loadingComponent: LoadingComponent.FolderList,
        folderId: this.folderId,
      });
    },
    getRdbPreviewByJobId(jobId) {
      return this.rdbPreview?.[jobId];
    },
    handleTransitionDelete({ targetId }) {
      const clonedTempSelectedIngest = this.cloneTempSelectedIngest();

      clonedTempSelectedIngest.jobs.forEach((job) => {
        if (job.id === targetId) {
          job.sourceConnectionId = null;
          job.sourceConnectionNodeId = null;
        }
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: clonedTempSelectedIngest,
      });

      this.setNodesAndEdges();
    },
    addNewTransition({ source, target }) {
      const clonedTempSelectedIngest = this.cloneTempSelectedIngest();

      clonedTempSelectedIngest.jobs.forEach((job) => {
        if (job.id === target) {
          job.sourceConnectionId = job.sourceConnectionNodeId =
            clonedTempSelectedIngest.properties.sources.find(
              (s) => s.id === source
            )?.sourceConnectionId;
          job.sourceConnectionNodeId = source;
        }
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: clonedTempSelectedIngest,
      });

      this.setNodesAndEdges();
    },
    updatePositionsAndLevels(positions) {
      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: {
          ...this.tempSelectedIngest,
          properties: {
            ...this.tempSelectedIngest.properties,
            positions,
          },
        },
      });
    },
    getDatabaseName(id) {
      return this.connections.find((c) => c.connectionId === id)?.name;
    },
    handleConnectionPopupClose() {
      this.showConnectionPopup = false;
    },
    selectConnection(connectionPopupSelectedConnection) {
      this.temporarySelectedConnection = connectionPopupSelectedConnection;
    },
    cancelSelectedConnection() {
      this.handleConnectionPopupClose();
      this.temporarySelectedConnection = null;
    },
    confirmSelectedConnection() {
      if (!this.temporarySelectedConnection?.connectionId) {
        this.cancelSelectedConnection();

        return;
      }

      const clonedTempSelectedIngest = this.cloneTempSelectedIngest();

      clonedTempSelectedIngest.properties.sources.forEach((source) => {
        if (source.id === this.rightProperties.sourceNodeId) {
          source.sourceConnectionId =
            this.temporarySelectedConnection?.connectionId;
        }
      });

      clonedTempSelectedIngest.jobs.forEach((job) => {
        if (job.sourceConnectionNodeId === this.rightProperties.sourceNodeId) {
          job.sourceConnectionId =
            this.temporarySelectedConnection?.connectionId;
        }
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: clonedTempSelectedIngest,
      });

      this.setNodesAndEdges();
      this.cancelSelectedConnection();
    },
    isDataChangePreventsRunAndPreview(jobId) {
      const updatePayload = this.prepareUpdatePayload(
        this.tempNotModifiedSelectedIngest,
        this.tempSelectedIngest
      );

      if (updatePayload?.createdJobs.find((job) => job.id === jobId)) {
        Notify(
          this.$t("notifyMessages.You should save the job first to run it"),
          notificationType.ERROR
        );

        return true;
      }

      const findJobInUpdatedJobs = updatePayload?.updatedJobs.find(
        (job) => job.id === jobId
      );

      if (findJobInUpdatedJobs) {
        const fieldsToBeChecked = [
          "name",
          "sql",
          "targetTableName",
          "jobMode",
          "cursorField",
          "orderBy",
          "partitionBy",
        ];

        if (
          fieldsToBeChecked.some(
            (field) => findJobInUpdatedJobs[field] === undefined
          )
        ) {
          Notify(
            this.$t("notifyMessages.You should save the job first to run it"),
            notificationType.ERROR
          );

          return true;
        }
      }

      return false;
    },
    handleRunJob(id) {
      if (this.isDataChangePreventsRunAndPreview(id)) {
        return;
      }

      this.postRdbRun({
        bodyParam: {
          ingestJobId: id,
        },
      });
      this.$nextTick(async () => {
        this.$refs?.ingestProperties?.$refs?.jobStatus[0].jobLoading();
        await this.$refs?.ingestProperties?.$refs?.jobStatus[0].fetchRdbData();
      });
    },
    handleCanvasClicked() {
      this.rightProperties.job = false;
      this.rightProperties.source = false;
      this.rightProperties.dataFlow = true;
      this.setNodesAndEdges();
    },
    prepareUpdatePayload(initialData, updatedData) {
      const initialDataCloned = cloneDeep(initialData);
      const updatedDataCloned = cloneDeep(updatedData);
      const deletedJobs = [];
      const updatedJobs = [];
      const createdJobs = [];

      // Identify deleted jobs
      initialDataCloned.jobs.forEach((initialJob) => {
        const updatedJob = updatedDataCloned.jobs.find(
          (job) => job.id === initialJob.id
        );

        if (!updatedJob) {
          deletedJobs.push(initialJob.id);
        }
      });

      updatedDataCloned.jobs.forEach((updatedJob) => {
        const initialJob = initialDataCloned.jobs.find(
          (job) => job.id === updatedJob.id
        );

        if (initialJob) {
          const updatedFields = {};

          Object.keys(updatedJob).forEach((key) => {
            if (!deepEqual(updatedJob[key], initialJob[key])) {
              if (key !== "totalColumns" && key !== "totalRows") {
                updatedFields[key] = updatedJob[key];
              }
            }
          });

          if (Object.keys(updatedFields).length > 0) {
            updatedJobs.push({
              id: updatedJob.id,
              ...updatedFields,
            });
          }
        } else {
          // New job (created)
          createdJobs.push(updatedJob);
        }
      });

      return {
        deletedJobs,
        updatedJobs,
        createdJobs,
      };
    },
    prepareCreatePayload(data) {
      data.jobs.forEach((job) => {
        delete job.totalRows;
        delete job.totalColumns;

        if (job.scheduleMode === scheduleModes.MANUAL) {
          delete job.scheduleField;
        }
      });

      return data;
    },

    async handleIngestCreate(name) {
      const createPayload = this.prepareCreatePayload(
        this.cloneTempSelectedIngest()
      );
      const result = await this.postRdbSave({
        bodyParam: {
          ...createPayload,
          folderId: this.folderId,
          name,
        },
        loadingComponent: LoadingComponent.DataFlow,
      });

      if (result?.dataflowId) {
        // send one more request to update properties with savedJobId
        await this.putRdbSaveForProperties(createPayload, result);

        this.$router.push({
          name: routerEnums.INGEST_EDIT,
          params: { id: result?.dataflowId },
        });
      }
    },

    async handleIngestSave() {
      if (this.routeId) {
        const initialData = this.tempNotModifiedSelectedIngest;
        const updatedData = this.tempSelectedIngest;
        const payload = this.prepareUpdatePayload(initialData, updatedData);
        const result = await this.putRdbSave({
          dataflowId: this.routeId,
          bodyParam: {
            ...payload,
            updatedJobs: payload.updatedJobs.map((job) => {
              if (job.scheduleField) {
                return {
                  ...job,
                  scheduleMode: scheduleModes.SCHEDULED,
                };
              } else {
                return job;
              }
            }),
          },
          loadingComponent: LoadingComponent.DataFlow,
        });

        if (!result?.dataflowId) {
          //error occurred
          return;
        }

        // send one more request to update properties with savedJobId
        await this.putRdbSaveForProperties(updatedData, result);

        this.init();
      } else {
        //check if all the jobs have name filled
        const isJobNameFilled = this.tempSelectedIngest.jobs.every((job) => {
          return job.name;
        });

        if (!isJobNameFilled) {
          Notify(
            this.$t("notifyMessages.Please fill the job name field"),
            notificationType.ERROR
          );

          return;
        }

        const requiredFieldsText = {
          targetTableName: this.$t("ingest.Target Dataset"),
          sql: this.$t("ingest.Sql"),
          sourceConnectionId: this.$t("ingest.Source Connection"),
          cursorField: this.$t("ingest.Unique Identifier"),
        };
        const requiredFields = [
          "targetTableName",
          "sql",
          "sourceConnectionId",
          "cursorField",
        ];

        let jobsWithIncompleteFields = this.tempSelectedIngest.jobs.map(
          (job) => {
            const notFilledFields = requiredFields.filter(
              (field) => !job[field]
            );

            return {
              jobName: job.name,
              notFilledRequiredFields: notFilledFields.map(
                (field) => requiredFieldsText[field]
              ),
            };
          }
        );

        jobsWithIncompleteFields = jobsWithIncompleteFields.filter(
          (j) => j.notFilledRequiredFields?.length
        );

        //check if all the jobs have all the required fields filled
        if (!jobsWithIncompleteFields.length) {
          this.isActiveProjectNamePopup = true;
        } else {
          const notFilledFieldsText = jobsWithIncompleteFields.map(
            (job) =>
              `<br><br>${this.$t("ingest.Job name")}<br> <b>${
                job.jobName
              }</b><br> <br>${this.$t(
                "ingest.Fields"
              )} <br>  <b>${job.notFilledRequiredFields.join("<br>")}</b>`
          );

          Notify(
            this.$t("notifyMessages.Please fill all the required fields") +
              notFilledFieldsText[0],
            notificationType.ERROR,
            "",
            true
          );
        }
      }
    },
    async putRdbSaveForProperties(currentData, repsonse) {
      await this.putRdbSave({
        dataflowId: repsonse.dataflowId,
        bodyParam: {
          deletedJobs: [],
          updatedJobs: [],
          createdJobs: [],
          properties: {
            ...currentData.properties,
            positions: currentData.properties.positions.map((pos) => {
              return {
                id:
                  repsonse.jobsMapList.find((job) => job.tempJobId === pos.id)
                    ?.savedJobId ?? pos.id,
                left: pos.left,
                top: pos.top,
              };
            }),
          },
        },
        loadingComponent: LoadingComponent.DataFlow,
      });
    },
    setNodesAndEdges() {
      const nodes = [];
      const edges = [];

      this.tempSelectedIngest?.jobs?.forEach((job) => {
        const currentNodePosition =
          this.tempSelectedIngest?.properties?.positions?.find(
            (pos) => pos.id === job.id
          );

        // target dataset node
        nodes.push({
          type: "dataset",
          id: job.id,
          name: job.name,
          left: currentNodePosition?.left,
          top: currentNodePosition?.top,
          nodeSelected:
            this.rightProperties.job && this.rightProperties.jobId === job.id,
          columns: job.totalColumns,
          rows: job.totalRows,
          sourceConnectionNodeId: job.sourceConnectionNodeId,
        });
      });

      this.tempSelectedIngest?.properties?.sources?.forEach((source) => {
        const currentNodePosition =
          this.tempSelectedIngest.properties.positions.find(
            (pos) => pos.id === source.id
          );

        // database node
        nodes.push({
          type: "database",
          id: source.id,
          name: this.getDatabaseName(source.sourceConnectionId) ?? "",
          left: currentNodePosition?.left,
          top: currentNodePosition?.top,
          nodeSelected:
            this.rightProperties.source &&
            this.rightProperties.sourceNodeId === source.id,
        });
      });

      this.tempSelectedIngest?.jobs?.forEach((job) => {
        // if there is connection between database and target dataset then add edge
        if (job.sourceConnectionId) {
          edges.push({
            source: job.sourceConnectionNodeId,
            target: job.id,
            data: {
              type: "datasetJoin",
            },
          });
        }
      });

      this.nodesAndEdges = {
        nodes,
        edges,
      };
    },
    addNewDatabaseNode(dropPosition) {
      const clonedTempSelectedIngest = this.cloneTempSelectedIngest();
      const defaultSourceConnection = createDefaultSourceConnection();

      clonedTempSelectedIngest.properties.sources.push(defaultSourceConnection);
      clonedTempSelectedIngest.properties.positions.push({
        id: defaultSourceConnection.id,
        left: dropPosition.left,
        top: dropPosition.top,
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: clonedTempSelectedIngest,
      });

      this.setNodesAndEdges();
    },
    addNewDatasetNode(dropPosition) {
      const clonedTempSelectedIngest = this.cloneTempSelectedIngest();
      const defaultJob = createDefaultJob();

      defaultJob.targetConnectionId = this.getClickhouseDatabase.connectionId;
      clonedTempSelectedIngest.properties.positions.push({
        id: defaultJob.id,
        left: dropPosition.left,
        top: dropPosition.top,
      });

      clonedTempSelectedIngest.jobs.push(defaultJob);

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: clonedTempSelectedIngest,
      });

      this.setNodesAndEdges();
    },
    handleDatasetDelete(idToRemove) {
      this.$confirm(this.$t("dialog.Do you want to delete?"), "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.handleCanvasClicked();
          const clonedTempSelectedIngest = this.cloneTempSelectedIngest();

          const filteredObject = {
            ...clonedTempSelectedIngest,
            // if jobs contain idToRemove then remove this job
            jobs: clonedTempSelectedIngest.jobs.filter(
              (job) => job.id !== idToRemove
            ),
            properties: {
              // if positions contain idToRemove then remove this position
              positions: clonedTempSelectedIngest.properties.positions.filter(
                (pos) => pos.id !== idToRemove
              ),
              // if sources contain idToRemove then remove this source
              sources: clonedTempSelectedIngest.properties.sources.filter(
                (source) => source.id !== idToRemove
              ),
            },
          };

          // if there is connection between database and target dataset (idToRemove is sourceConnectionNodeId) then remove edge
          filteredObject.jobs.forEach((job) => {
            if (job.sourceConnectionNodeId === idToRemove) {
              job.sourceConnectionId = null;
              job.sourceConnectionNodeId = null;
            }
          });

          this.setTempStorageByKey({
            key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
            value: filteredObject,
          });

          this.setNodesAndEdges();
        })
        .catch(() => {});
    },
    clickSqlEdit(obj) {
      this.clickDataset(obj);
      this.showSqlEditor = true;

      setTimeout(() => {
        this.textToBeAddedToSql = this.sql;
      }, 100);
    },
    clickDataset(obj) {
      const selectedJob = this.tempSelectedIngest?.jobs?.find(
        (job) => job.id === obj.id
      );

      this.rightProperties = {
        job: true,
        jobId: obj.id,
        source: false,
        sourceNodeId: null,
      };
      this.sql = selectedJob?.sql ?? null;
      this.sourceConnectionId = selectedJob?.sourceConnectionId ?? null;

      this.setNodesAndEdges();
    },
    clickDatabase(obj) {
      this.rightProperties = {
        job: false,
        jobId: null,
        source: true,
        sourceNodeId: obj.id,
      };

      this.setNodesAndEdges();
    },
    onClosePreview() {
      this.showPreviewTable = false;
    },
    async runFetchIngestTestQuery(obj) {
      if (this.isDataChangePreventsRunAndPreview(obj.id)) {
        return;
      }

      this.ingestTestQueryResult = {
        columns: [],
        rows: [],
      };
      this.clickDataset(obj);

      const result = await this.fetchTestQuery({
        loadingComponent: LoadingComponent.DataFlow,
        bodyParam: {
          sqlQuery: `select * from ingest.${this.selectedJobProperties.targetTableName}`,
          connection: {
            connectionId: this.getClickhouseDatabase.connectionId,
            name: this.getClickhouseDatabase.name,
          },
          isIngestRequest: true,
        },
      });

      if (!result?.data?.code) {
        this.showPreviewTable = true;
      } else {
        return;
      }

      if (result?.length > 0) {
        const columns = Object.keys(result[0]);

        this.ingestTestQueryResult = {
          columns: columns.map((col) => {
            return {
              className: col,
              classType: "STRING",
            };
          }),
          rows: result.map((row) => Object.values(row)),
        };
      }
    },
    async init() {
      await this.fetchConnections();
      await this.getFolderList();

      if (this.routeId) {
        await this.fetchDataFlowDetailsById(this.routeId);
        this.setTempStorageByKey({
          key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
          value: this.selectedDataFlowDetails,
        });
        this.setTempStorageByKey({
          key: TEMP_STORAGE_KEYS.TEMP_NOT_MODIFIED_SELECTED_INGEST,
          value: this.selectedDataFlowDetails,
        });
        this.setNodesAndEdges();
        this.tempSelectedIngest.jobs.forEach((job) => {
          this.runPostRdbPreview(
            job.sourceConnectionId,
            job.id,
            job.sql,
            false
          );
        });
      }

      await this.getDataFlowHighestPriority();
    },
    getPreviewTableRowsByJobId(previewColumnsAndRows) {
      const keys = previewColumnsAndRows?.columns;

      return (
        previewColumnsAndRows?.rows?.map((data) => {
          const obj = {};

          data.forEach((value, index) => {
            const key = keys[index].className;
            obj[key] = value;
          });

          return obj;
        }) ?? []
      );
    },
    getPreviewTableColumnsByJobId(previewColumnsAndRows) {
      return (
        previewColumnsAndRows?.columns?.map((col) => {
          return { field: col.className, label: col.className };
        }) ?? []
      );
    },
    async saveSql(updatedSql) {
      const runSqlResult = await this.runPostRdbPreview(
        this.sourceConnectionId,
        this.rightProperties.jobId,
        updatedSql,
        false
      );

      // if there is an error on run sql then do no update the sql
      if (runSqlResult?.data?.code) {
        return;
      }

      const clonedTempSelectedIngest = this.cloneTempSelectedIngest();

      clonedTempSelectedIngest.jobs.forEach((job) => {
        if (job.id === this.rightProperties.jobId) {
          job.sql = updatedSql;
        }
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: clonedTempSelectedIngest,
      });
      this.showSqlEditor = false;
    },
    async runPostRdbPreview(
      sourceConnectionId,
      jobId,
      updatedSql,
      showPreviewTable = true
    ) {
      if (!sourceConnectionId || !updatedSql) {
        return;
      }

      this.showPreviewTable = showPreviewTable;
      const result = await this.postRdbPreview({
        bodyParam: {
          sourceConnectionId,
          sql: updatedSql,
        },
        jobId,
        withSuccessNotify: showPreviewTable,
      });

      // if there is an error on run then update the column counts
      if (!result?.data?.code) {
        const clonedTempSelectedIngest = this.cloneTempSelectedIngest();

        clonedTempSelectedIngest.jobs.forEach((job) => {
          if (job.id === jobId) {
            job.totalColumns = result?.columns?.length;
            job.totalRows = result?.rows?.length;
          }
        });

        this.setTempStorageByKey({
          key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
          value: clonedTempSelectedIngest,
        });

        this.setNodesAndEdges();
      }

      return result;
    },
    updateJob(updatedJob) {
      const clonedTempSelectedIngest = this.cloneTempSelectedIngest();

      clonedTempSelectedIngest.jobs = clonedTempSelectedIngest.jobs.map(
        (job) => {
          if (job.id === this.rightProperties.jobId) {
            return updatedJob;
          } else {
            return job; // Keep the original object
          }
        }
      );

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: clonedTempSelectedIngest,
      });

      this.setNodesAndEdges();
    },
    updateProperties(updatedProperties) {
      const clonedTempSelectedIngest = this.cloneTempSelectedIngest();

      clonedTempSelectedIngest.name = updatedProperties.name;
      clonedTempSelectedIngest.description = updatedProperties.description;

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST,
        value: clonedTempSelectedIngest,
      });
    },
    cloneTempSelectedIngest() {
      return cloneDeep(this.tempSelectedIngest);
    },
    async getDataFlowHighestPriority() {
      this.fetchedDataFlowHighestPriority =
        await this.getHighestPriorityByDataFlowId({
          dataFlowId: this.selectedDataFlowDetails?.id,
        });
    },
  },
};
</script>

<style scoped>
.ingest-cards-items {
  height: 50%;
}

.ingest-sql-editor-container {
  padding-left: 0;
  padding-right: 0;
  flex: 1;
}

layout {
  width: 100%;
  height: 100%;
  margin: 0;
}

::v-deep .data-flow-linker-popover .el-popover {
  padding: 20px;
}

.data-flow-linker-modal {
  position: absolute;
  top: 32%;
  left: calc(50% - 375px);
}
.preview-container {
  background-color: #f4f7f8 !important;
  height: 50%;
  border-top: 2px solid #d8d8d8;
}
.vis-ingest-cards-and-preview-container {
  height: inherit;
}
.ingest-background {
  background: url("../assets/images/dashboard/Dashboard-Grid.svg") !important;
}
::v-deep .select-database-connection-container-body {
  padding: 0;
}
::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
}
::v-deep .el-dialog__footer {
  padding-right: 20px;
}
.select-database-connection-dialog ::v-deep .vgt-responsive {
  max-height: 300px !important;
}

.ingest-layout ::v-deep section article {
  overflow: hidden !important;
}
</style>
