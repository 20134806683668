<template>
  <div class="vis-row">
    <div
      v-for="(card, index) in cards"
      :key="index"
      @click="createClicked(card, card.routerName)"
      class="vis-create-card"
      :class="{
        'vis-display-none': !card.isVisible,
      }"
    >
      <div class="vis-create-card-content">
        <i
          class="vis-icon"
          :class="card.icon"
          :style="{ color: card.iconColor }"
          aria-hidden="true"
        ></i>
        <div class="vis-create-card-footer">
          <h4>{{ $t("generalPages.new") }}</h4>
          <div class="vis-flex--spacebeetwen-Ycenter">
            <h3>{{ $t(`generalPages.${card.title}`) }}</h3>
            <i class="vis-icon icon-plus" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "../../commons/helper";
import { notificationType } from "../../commons/notificationTypes";
import componentAvailabilityCheck from "../../mixins/componentAvailabilityCheck";
import { COMPONENT_PRIVILEGES } from "../../util/authorization";
import { CardType } from "../../util/homePageMappers";

export default {
  props: {
    forDataFlowDoesUserHaveEditRoles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mixins: [componentAvailabilityCheck],
  computed: {
    cards() {
      return [
        {
          title: "dashboard",
          icon: "icon-dashboard",
          iconColor: "#206FFF",
          routerName: "Dashboard",
          isVisible: this.isDashboardCreateVisible,
          isClickable: this.isDashboardCreateClickable,
          isShow: true,
        },
        {
          title: "dataModel",
          icon: "icon-account_tree",
          iconColor: "#34BACB",
          routerName: "Datamodel",
          isVisible: this.isDatamodelCreateVisible,
          isClickable: this.isDatamodelCreateClickable,
          isShow: true,
        },
        {
          title: "Dataflow",
          icon: "icon-data_flow",
          iconColor: "",
          routerName: "IngestCreate",
          isVisible: this.isDataFlowCreateVisible,
          isClickable: this.isDataFlowCreateClickable,
          isShow: this.forDataFlowDoesUserHaveEditRoles,
        },
      ].filter((x) => x.isShow);
    },
    isDashboardCreateVisible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_CREATE_VISIBLE
      );
    },
    isDashboardCreateClickable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_CREATE_CLICKABLE
      );
    },
    isDatamodelCreateVisible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_VISIBLE
      );
    },
    isDatamodelCreateClickable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_CLICKABLE
      );
    },
    isDataFlowCreateVisible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_DATAFLOW_CREATE_VISIBLE
      );
    },
    isDataFlowCreateClickable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_DATAFLOW_CREATE_CLICKABLE
      );
    },
  },
  methods: {
    createClicked(card, name) {
      if (card.isClickable) this.$emit("createClicked", name);
      else {
        if (card.routerName === CardType.DASHBOARD) {
          Notify(
            this.$t("generalPages.dashboardNotAuthorized"),
            notificationType.WARNING
          );
        } else if (card.routerName === CardType.DATAMODEL) {
          Notify(
            this.$t("generalPages.datamodelNotAuthorized"),
            notificationType.WARNING
          );
        }
      }
    },
  },
};
</script>
