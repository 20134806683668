<template>
  <div>
    <!-- Code editing, highlight the content of the completion dialog box -->
    <div class="in-coder-panel">
      <textarea ref="textareaSql"></textarea>
    </div>
  </div>
</template>

<script>
// Introduce global instance
import CodeMirror from "codemirror";
// Core style
import "codemirror/lib/codemirror.css";
// After the theme is introduced, you need to specify the theme in options to take effect
import "codemirror/theme/idea.css";
import "codemirror/mode/sql/sql.js";

//Code completion prompt
import "codemirror/addon/hint/anyword-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/sql-hint.js";

export default {
  name: "CodeMirrorSql",
  props: {
    language: {
      type: String,
      default: null,
    },
    sql: {
      type: String,
      required: true,
    },
    operators: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
    },
    textToBeAddedToSql: {
      type: String,
      default: "",
    },
  },
  watch: {
    textToBeAddedToSql: {
      handler(val) {
        this.addTextToCursorPosition(val);
      },
      deep: true,
    },
  },
  data() {
    return {
      formulaOperationHintStr: [],
      formulaOperationHintList: {},
      code: "",
      mode: "shell",
      coder: null,
      options: {
        tabSize: 4,
        lineNumbers: true,
        line: true,
        gutter: true,
        lineWrapping: true,
        extraKeys: { Ctrl: "autocomplete" },
      },
      modes: [
        {
          value: "x-sql",
          label: "SQL",
        },
      ],
    };
  },
  mounted() {
    this._initialize();
    this.setTableHint();
  },
  computed: {
    columnNames() {
      return this.columns?.map((c) => `[${c}]`);
    },
  },
  methods: {
    /**
     * @return {void}
     */
    _initialize() {
      let self = this;
      CodeMirror.defineMode("sql", function () {
        return {
          token: function (stream) {
            if (stream.eatSpace()) return null;
            if (stream.match(/^\d+/)) return "number";
            if (stream.match(/^\[\w+]/) || stream.match(/^\[\w+ \w+]/)) {
              if (self.columnNames?.indexOf(stream.current()) >= 0) {
                return "columnName";
              } else return "";
            }
            if (stream.match(/^\w+\.\w+/) || stream.match(/^\w+/)) {
              if (self.operators?.indexOf(stream.current()) >= 0) {
                return "operator";
              } else return "";
            }
            stream.next();
            return null;
          },
        };
      });
      this.coder = CodeMirror.fromTextArea(
        this.$refs.textareaSql,
        this.options
      );
      this.coder.refresh();
      this.coder.setValue(this.sql || this.code);

      this.coder.on("change", (coder) => {
        this.code = coder.getValue();
        this.formulaOperationHintStr = [];
        this.updateSql(this.code);
      });

      this.changeMode("x-sql");
    },
    setTableHint() {
      const orig = CodeMirror.hint.sql;

      CodeMirror.hint.sql = (cm) => {
        let inner = orig(cm) || {
          from: cm.getCursor(),
          to: cm.getCursor(),
          list: [],
        };

        const operatorList = this.operators.map((keyword) => {
          return {
            text: keyword,
            className: "CodeMirror-hint-keyword cm-keyword",
          };
        });
        const columnList = this.columnNames.map((keyword) => {
          return { text: keyword, className: "CodeMirror-hint" };
        });

        const snippets = [...operatorList, ...columnList];
        const currentWord = cm.getTokenAt(cm.getCursor()).string;
        const list = snippets.filter(function (item) {
          return item.text.indexOf(currentWord) >= 0;
        });

        inner.list = list.length ? list : snippets;

        return inner;
      };
    },
    /**
     * @param {string} language
     * @return {string} foundMode
     */
    _getLanguage(language) {
      // Find the incoming syntax type in the list of supported syntax types
      return this.modes.find((mode) => {
        // All values ignore case for comparison
        let currentLanguage = language.toLowerCase();
        let currentLabel = mode.label.toLowerCase();
        let currentValue = mode.value.toLowerCase();

        // Because the real value may not be standardized, for example, the real value of Java is x-java, so it is said that value and label are compared with the incoming syntax at the same time
        return (
          currentLabel === currentLanguage || currentValue === currentLanguage
        );
      });
    },

    addTextToCursorPosition(val) {
      let doc = this.coder.getDoc();

      doc.replaceSelection(val);

      this.$emit("resetTextToBeAddedToSql");
    },
    /**
     * @param {string} val
     * @return {void}
     */
    changeMode(val) {
      // Modify the syntax configuration of the editor
      this.coder.setOption("mode", `text/${val}`);
    },
    /**
     * @param {string} val
     * @return {void}
     */
    updateSql(val) {
      this.$emit("update:sql", val);
    },
  },
};
</script>
