
import {
    LABEL_CHART_PROPERTIES_ENUM,
} from "../../commons/dashboardProperties";
import { LABEL_CHART_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/labelChartDefaultProperties";


export default {
    computed: {
        labelChartComponentsMapping() {
            return {

                [LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_VALUE
                    ], LABEL_CHART_DEFAULT_CONFIGURATIONS.LABEL_CHART_DYNAMIC_TITLE_VALUE),
                    property: LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], LABEL_CHART_DEFAULT_CONFIGURATIONS.LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE
                    ], LABEL_CHART_DEFAULT_CONFIGURATIONS.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_GROUP_MULTIPLE_VALUES
                    ], LABEL_CHART_DEFAULT_CONFIGURATIONS.LABEL_CHART_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
            }
        },
    },
};
