import { render, staticRenderFns } from "./ArrowScroll.vue?vue&type=template&id=c0aa98fa&scoped=true"
import script from "./ArrowScroll.vue?vue&type=script&lang=js"
export * from "./ArrowScroll.vue?vue&type=script&lang=js"
import style0 from "./ArrowScroll.vue?vue&type=style&index=0&id=c0aa98fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0aa98fa",
  null
  
)

export default component.exports