var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"vis-topbar vis-ma--none vis-setting-bar"},[_c('div',{staticClass:"vis-row",staticStyle:{"height":"100%"}},[(!_vm.isModeDashboardView)?_c('div',{staticClass:"vis-dashboard-custom-col vis-flex--Ycenter vis-mobile-hidden vis-right-border"},[(_vm.isPageLink)?_c('SettingsLeftTabLink',{attrs:{"activeSettingTab":_vm.activeSettingTab,"showDataModelLink":_vm.showDataModelLink,"showParametersLink":_vm.showParametersLink},on:{"setSettingLeftTab":_vm.setSettingLeftTab}}):_vm._e()],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTabSectionVisible),expression:"isTabSectionVisible"}],staticClass:"vis-col vis-col-7 vis-col-xxxl6 vis-col-xxl5 vis-col-xl6 vis-col-l5 vis-col-m4 vis-mb--none vis-pa--none vis-mobile-hidden",class:[
          { 'vis-col-9 vis-col-xl8 vis-col-l7 vis-col-m6': _vm.isModeDashboardView },
        ]},[_c('DashboardTab',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActiveTab),expression:"isActiveTab"}],attrs:{"tabList":_vm.tabList,"selectedTab":_vm.selectedTab,"isModeDashboardView":_vm.isModeDashboardView,"tabIndex":_vm.tabIndex},on:{"setTabIndex":_vm.setTabIndex,"deleteDashboardTab":_vm.deleteDashboardTab,"addDashboardTab":_vm.addDashboardTab,"duplicateDashboardTab":_vm.duplicateDashboardTab,"dashboardTabChanged":function($event){return _vm.$emit('dashboardTabChanged')}}})],1),_c('div',{staticClass:"vis-col vis-mb--none vis-text--right vis-flex--Xright vis-flex--Ycenter vis-mobile-hidden",class:{ 'vis-col-12': !_vm.isTabSectionVisible && _vm.isModeDashboardView }},[(_vm.isActiveIconBox)?_c('div',{staticClass:"vis-settingbar-iconBox"},_vm._l((_vm.iconList),function(icon){return _c('div',{key:icon.id,staticClass:"vis-display-inline-block"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"primary","content":icon.tooltipContent,"placement":"bottom","disabled":!icon.isShowTooltip}},[(!icon.useDropdown)?_c('button',{staticClass:"vis-icon-button",class:[icon.className],attrs:{"disabled":icon.disabled},on:{"click":function($event){$event.stopPropagation();return icon.serviceFn(icon.id)}}},[_c('i',{staticClass:"vis-position-relative",class:[
                    icon.iconClassName,
                    {
                      'vis-active-setting-icon': icon.isActiveClass,
                    },
                  ],attrs:{"aria-hidden":"true"}},[(icon.isShowElBadge)?_c('el-badge',{staticClass:"item",attrs:{"value":icon.elBadgeValue}}):_vm._e()],1)]):_vm._e()]),(icon.useDropdown)?_c('el-dropdown',{class:_vm.isModeDashboardView ? 'vis-mr--1_5' : 'vis-ma--none',attrs:{"trigger":"click","placement":"bottom"},on:{"command":_vm.handleCommand}},[_c('i',{class:icon.className,attrs:{"aria-hidden":"true"}}),_c('el-dropdown-menu',{staticClass:"vis-dropdown-menu"},_vm._l((_vm.popoverMenuItems),function(popoverItem){return _c('el-dropdown-item',{key:popoverItem.id,attrs:{"command":popoverItem}},[_c('label',{staticClass:"vis-cursor-pointer"},[_c('i',{class:popoverItem.icon,attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t(popoverItem.label))+" ")])])}),1)],1):_vm._e()],1)}),0):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }