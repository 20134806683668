import { createMetricKey, getConcatArrayWithPipe, mapAxisItems } from "../chartOptionsHelper";
import { groupBy } from "lodash";
import { DatamodelContextDefaults} from "../../commons/dataModelTypes";
import { AXIS_TYPES_ENUM } from "../../commons/dashboardProperties";
import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";

export const scatterSearch = (params, searchData, chartI) => {
  const groups = groupBy(
    [...params.aggregation, ...params.metric],
    (val) => val.boxKey
  );
  const aggregationFormats = params?.aggregation?.map((a) => a.format);
  const isYAxisMetric = groups[BOX_KEY_ENUM.YAXIS.KEY]?.every(
    (i) => i.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
  );
  const isXAxisMetric = groups[BOX_KEY_ENUM.XAXIS.KEY]?.every(
    (i) => i.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
  );
  const metricFormatsMapping = params?.metric?.reduce((accumulator, value) => {
    return { ...accumulator, [value.field]: value.format };
  }, {});

  const metricKey = params?.metric?.[0]?.alias || "",
    xAxisItems = groups[BOX_KEY_ENUM.XAXIS.KEY]?.map(mapAxisItems),
    yAxisItems = groups[BOX_KEY_ENUM.YAXIS.KEY]?.map(mapAxisItems),
    pointItems = groups[BOX_KEY_ENUM.POINTS.KEY]?.map(mapAxisItems),
    colorByItems = groups[BOX_KEY_ENUM.COLORBY.KEY]?.map(mapAxisItems),
    xAxisName = getConcatArrayWithPipe(xAxisItems?.map(i => i.alias)),
    yAxisName = getConcatArrayWithPipe(yAxisItems?.map(i => i.alias)),
    pointName = getConcatArrayWithPipe(pointItems?.map(i => i.alias)),
    // dimensions is used when highlighting clicked node on the chart. 
    // we need the exact index during highlighting so null values also play an important role and should not be removed.
    dimensionNames = [
      isXAxisMetric ? null : xAxisName,
      isYAxisMetric ? null : yAxisName,
      pointName
    ];

  const yAxisData = new Set(),
    xAxisData = new Set();

  let dataSeries = {};

  searchData.aggregations.forEach((i) => {
    const xAxisValue =
      getConcatArrayWithPipe(xAxisItems?.map((item) =>  i[item.field])) || "";
    const yAxisValue =
      getConcatArrayWithPipe(yAxisItems?.map((item) =>  i[item.field])) || "";
    const point =
      getConcatArrayWithPipe(pointItems?.map((item) =>  i[item.field])) || "";
    const colorBy =
      getConcatArrayWithPipe(colorByItems?.map((item) =>  i[item.field])) || "";
    yAxisData.add(yAxisValue);
    xAxisData.add(xAxisValue);

    const x = isXAxisMetric ? i[createMetricKey(groups[BOX_KEY_ENUM.XAXIS.KEY]?.[0])] : xAxisValue,
          y = isYAxisMetric ? i[createMetricKey(groups[BOX_KEY_ENUM.YAXIS.KEY]?.[0])] : yAxisValue,
          result = [x, y, point, colorBy];

      const objKey = JSON.stringify(x + y + point + colorBy);
      // filter same values by creating and checking unique key
      if (!dataSeries[objKey]) {
        dataSeries[objKey] = result;
      }
  });

  if (colorByItems?.length) {
    dataSeries = groupBy(dataSeries, (val) => val[val.length - 1]);
  } else {
    dataSeries = {
      [metricKey]: Object.values(dataSeries)
    }
  }

  const axisData = {
    x: {
      name: xAxisName,
      type: isXAxisMetric ? AXIS_TYPES_ENUM.VALUE : AXIS_TYPES_ENUM.CATEGORY,
      data: [...xAxisData]
    },
    y: {
      name: yAxisName,
      type: isYAxisMetric ? AXIS_TYPES_ENUM.VALUE : AXIS_TYPES_ENUM.CATEGORY,
      data: [...yAxisData]
    },
  };

  const formatMap = {
    x: [...params.metric, ...params.aggregation]
          .filter((i) => i.boxKey === BOX_KEY_ENUM.XAXIS.KEY)
          .map((i) => i.format),
    y: [...params.metric, ...params.aggregation]
          .filter((i) => i.boxKey === BOX_KEY_ENUM.YAXIS.KEY)
          .map((i) => i.format)
  };

  let dataSeriesTotal = [0, 0];
  if (Object.keys(dataSeries).length) {
    Object.values(dataSeries)?.forEach((item) =>
      item?.forEach((y) => {
        dataSeriesTotal[0] += y[0]; // x eksenindeki değerler toplamını döner
        dataSeriesTotal[1] += y[1]; // y eksenindeki değerler toplamını döner
      })
    );
  }
  
    return {
    dataSeries,
    dataSeriesTotal,
    dataSeriesNames: params.metric?.map((e) => e.field),
    axisData,
    id: chartI,
    dimensionNames,
    formatMap,
    metricFormatsMapping,
    aggregationFormats
  };
};
