import { ServiceContainer } from '../api/ServiceContainer';
import { notifyStatusMapping } from '../commons/notifierTypes';
import { MUTATION_GENERAL } from '../store/modules/Visualize/General/types';
import { Notification } from 'element-ui';
import i18n from "@/i18n";


const showNotifier = (notify) => {
    try {
        let message = null;
        const commonResp = {
            ...notifyStatusMapping[notify.statusCode],
            data: "",
            duration: 3000,
            customClass: "center vis-notification",
        }

        if (notify?.deleteErrors?.length) {
            notify?.deleteErrors.forEach(element => {
                const resp = {
                    message: `${element.name} ${i18n.t(`errorCode.${element.code}`)}` || notify.message,
                    ...commonResp,
                };
                resp.title = i18n.t(resp.title)
                setTimeout(() => {
                    Notification(resp);
                }, 200);
            });
            return;
        }


        if (notify?.code) {
            message = i18n.t(`errorCode.${notify.code}`);

            if (notify.params?.length) {
                message = parseMessageWithParameters(message, notify.params);
            }
        }

        const resp = {
            message: message || notify.message,
            ...commonResp
        };

        if (notify.notifyTitle) {
            resp.title = i18n.t(notify.notifyTitle);
        } else {
            resp.title = i18n.t(resp.title)
        }

        Notification(resp);
    } catch (err) {
        console.log(err)
    }
}

const parseMessageWithParameters = (message, parameters) => {
    let i = 0;
    return message.replace(/\{}/g, function () {
        return parameters[i++];
    });
};

export const vuexActionHandler = async (
    {
        commit, //required
        serviceMethodParent, //required
        serviceMethodSub, //required
        successMutation,
        errorMutation,
        resultSelector, //required
        queryParam,
        bodyParam,
        withLoading,
        withNotify,
        withSuccessNotify = true,
        withErrorNotify = true,
        loadingComponent,
        notifyTitle
    }
) => {
    if (withLoading && loadingComponent)
        commit(MUTATION_GENERAL.SET_LOADING, { loadingComponent, value: true });

    try {
        let result = null;


        if ((queryParam && bodyParam) || (queryParam === "" && bodyParam)) {
            result = await ServiceContainer.getInstance()[serviceMethodParent][serviceMethodSub](queryParam, bodyParam);
        }
        else if (queryParam && !bodyParam)
            result = await ServiceContainer.getInstance()[serviceMethodParent][serviceMethodSub](queryParam);
        else if (!queryParam && bodyParam)
            result = await ServiceContainer.getInstance()[serviceMethodParent][serviceMethodSub](bodyParam);
        else
            result = await ServiceContainer.getInstance()[serviceMethodParent][serviceMethodSub]();

        const resultParsed = resultSelector(result);

        successMutation && commit(successMutation, resultParsed);

        withSuccessNotify && withNotify && showNotifier({ statusCode: 200, notifyTitle });

        return resultParsed;
    } catch (error) {
        errorMutation && commit(errorMutation, error);
        withErrorNotify && withNotify && showNotifier(error?.data);

        return error;
    } finally {
        if (withLoading && loadingComponent) {
            setTimeout(() => {
                commit(MUTATION_GENERAL.SET_LOADING, { loadingComponent, value: false });
            }, 500);
        }
    }
}