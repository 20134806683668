<template>
  <div class="vis-empty-state">
    <img
      v-if="isEmptyImageActive"
      src="../../assets/images/dashboard/dashboard-emptystate.svg"
      alt=""
    />
    <p>
      {{ message }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    isEmptyImageActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
