export const domain = "VISUALIZE_TEST_QUERY";

export const STATE = {
  AUTHORIZATION: `${domain}/AUTHORIZATION`,
  USERS: `${domain}/USERS`,
  GROUPS: `${domain}/GROUPS`,

  DASHBOARD_AUTHORIZATION_BY_ID: `${domain}/DASHBOARD_AUTHORIZATION_BY_ID`,
  DATAMODEL_AUTHORIZATION_BY_ID: `${domain}/DATAMODEL_AUTHORIZATION_BY_ID`,
  FOLDER_AUTHORIZATION_BY_ID: `${domain}/FOLDER_AUTHORIZATION_BY_ID`,
  FOLDER_AUTHORIZATION_TYPES: `${domain}/FOLDER_AUTHORIZATION_TYPES`,
  DATAFLOW_AUTHORIZATION_BY_ID: `${domain}/DATAMODEL_AUTHORIZATION_BY_ID`,
};

export const GETTER = {
  GET_AUTHORIZATION: `${domain}/GET_AUTHORIZATION`,
  GET_USERS: `${domain}/GET_USERS`,
  GET_GROUPS: `${domain}/GET_GROUPS`,
  
  GET_DASHBOARD_AUTHORIZATION_BY_ID: `${domain}/GET_DASHBOARD_AUTHORIZATION_BY_ID`,
  GET_DATAMODEL_AUTHORIZATION_BY_ID: `${domain}/GET_DATAMODEL_AUTHORIZATION_BY_ID`,
  GET_FOLDER_AUTHORIZATION_BY_ID: `${domain}/GET_FOLDER_AUTHORIZATION_BY_ID`,
  GET_FOLDER_AUTHORIZATION_TYPES: `${domain}/GET_FOLDER_AUTHORIZATION_TYPES`,
  GET_DATAFLOW_AUTHORIZATION_BY_ID: `${domain}/GET_DATAFLOW_AUTHORIZATION_BY_ID`,
};

export const ACTION = {
  FETCH_AUTHORIZATION: `${domain}/FETCH_AUTHORIZATION`,
  FETCH_USERS: `${domain}/FETCH_USERS`,
  FETCH_GROUPS: `${domain}/FETCH_GROUPS`,
  FETCH_DASHBOARD_AUTHORIZATION_BY_ID: `${domain}/FETCH_DASHBOARD_AUTHORIZATION_BY_ID`,
  FETCH_DASHBOARD_AUTHORIZATION_BY_ID_WITHOUT_MUTATE: `${domain}/FETCH_DASHBOARD_AUTHORIZATION_BY_ID_WITHOUT_MUTATE`,
  CREATE_DASHBOARD_AUTHORIZATION: `${domain}/CREATE_DASHBOARD_AUTHORIZATION`,
  UPDATE_DASHBOARD_AUTHORIZATION_BY_ID: `${domain}/UPDATE_DASHBOARD_AUTHORIZATION_BY_ID`,
  UPDATE_DASHBOARD_PARENT_AUTHORIZATION_BY_ID: `${domain}/UPDATE_DASHBOARD_PARENT_AUTHORIZATION_BY_ID`,
  DELETE_DASHBOARD_AUTHORIZATION_BY_ID: `${domain}/DELETE_DASHBOARD_AUTHORIZATION_BY_ID`,
  FETCH_DATAMODEL_AUTHORIZATION_BY_ID: `${domain}/FETCH_DATAMODEL_AUTHORIZATION_BY_ID`,
  FETCH_DATAMODEL_AUTHORIZATION_BY_ID_WITHOUT_MUTATE: `${domain}/FETCH_DATAMODEL_AUTHORIZATION_BY_ID_WITHOUT_MUTATE`,
  CREATE_DATAMODEL_AUTHORIZATION: `${domain}/CREATE_DATAMODEL_AUTHORIZATION`,
  UPDATE_DATAMODEL_AUTHORIZATION_BY_ID: `${domain}/UPDATE_DATAMODEL_AUTHORIZATION_BY_ID`,
  UPDATE_DATAMODEL_PARENT_AUTHORIZATION_BY_ID: `${domain}/UPDATE_DATAMODEL_PARENT_AUTHORIZATION_BY_ID`,
  DELETE_DATAMODEL_AUTHORIZATION_BY_ID: `${domain}/DELETE_DATAMODEL_AUTHORIZATION_BY_ID`,
  FETCH_FOLDER_AUTHORIZATION_BY_ID: `${domain}/FETCH_FOLDER_AUTHORIZATION_BY_ID`,
  FETCH_FOLDER_AUTHORIZATION_BY_ID_WITHOUT_MUTATE: `${domain}/FETCH_FOLDER_AUTHORIZATION_BY_ID_WITHOUT_MUTATE`,
  FETCH_FOLDER_AUTHORIZATION_TYPES: `${domain}/FETCH_FOLDER_AUTORIZATION_TYPES`,
  CREATE_FOLDER_AUTHORIZATION: `${domain}/CREATE_FOLDER_AUTHORIZATION`,
  UPDATE_FOLDER_AUTHORIZATION_BY_ID: `${domain}/UPDATE_FOLDER_AUTHORIZATION_BY_ID`,
  DELETE_FOLDER_AUTHORIZATION_BY_ID: `${domain}/DELETE_FOLDER__AUTHORIZATION_BY_ID`,
  UPDATE_FOLDER_OWNER_AUTHORIZATION_BY_ID: `${domain}/UPDATE_FOLDER_OWNER_AUTHORIZATION_BY_ID`,
  CREATE_FOLDER_OWNER_AUTHORIZATION_BY_ID: `${domain}/CREATE_FOLDER_OWNER_AUTHORIZATION_BY_ID`,
  UPDATE_FOLDER_PARENT_AUTHORIZATION_BY_ID: `${domain}/UPDATE_FOLDER_PARENT_AUTHORIZATION_BY_ID`,
  FETCH_DATAFLOW_AUTHORIZATION_BY_ID: `${domain}/FETCH_DATAFLOW_AUTHORIZATION_BY_ID`,
  CREATE_DATAFLOW_AUTHORIZATION: `${domain}/CREATE_DATAFLOW_AUTHORIZATION`,
  UPDATE_DATAFLOW_AUTHORIZATION_BY_ID: `${domain}/UPDATE_DATAFLOW_AUTHORIZATION_BY_ID`,
  DELETE_DATAFLOW_AUTHORIZATION_BY_ID: `${domain}/DELETE_DATAFLOW_AUTHORIZATION_BY_ID`,
  UPDATE_DATAFLOW_PARENT_AUTHORIZATION_BY_ID: `${domain}/UPDATE_DATAFLOW_PARENT_AUTHORIZATION_BY_ID`,
};

export const MUTATION = {
  SET_AUTHORIZATION: `${domain}/SET_AUTHORIZATION`,
  SET_USERS: `${domain}/SET_USERS`,
  SET_GROUPS: `${domain}/SET_GROUPS`,
  
  SET_DASHBOARD_AUTHORIZATION_BY_ID: `${domain}/SET_DASHBOARD_AUTHORIZATION_BY_ID`,
  SET_DATAMODEL_AUTHORIZATION_BY_ID: `${domain}/SET_DATAMODEL_AUTHORIZATION_BY_ID`,
  SET_FOLDER_AUTHORIZATION_BY_ID: `${domain}/SET_FOLDER_AUTHORIZATION_BY_ID`,
  SET_DATAFLOW_AUTHORIZATION_BY_ID: `${domain}/SET_DATAFLOW_AUTHORIZATION_BY_ID`,
};


