
import {
    fontsArr,
    fontSizesArr,
    bigFontSizesArr,
    fontWeightsArr,
    SUMMARY_CHART_PROPERTIES_ENUM,
    predefineColorsArr,
    autoRefreshTimes,
    alignItems,
    propertiesIconList
} from "../../commons/dashboardProperties";
import { SUMMARY_CHART_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/summaryChartDefaultProperties";

export default {
    computed: {
        summaryChartComponentsMapping() {
            return {
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_FAMILY
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_FONT_FAMILY),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_WEIGHT
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_FONT_WEIGHT),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_SIZE
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_FONT_SIZE),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_ALIGN]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.align",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_ALIGN
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_ALIGN),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_SHOW_TREND_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_SHOW_TREND_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Use Trend Color",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_SHOW_TREND_COLOR
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_SHOW_TREND_COLOR),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_SHOW_TREND_COLOR,
                    flexClass: true,
                    isShowCustomLabel: true,
                    customLabelText: "panelProperties.value.fontColor",
                    paddingLeftClass: "vis-pl--05"
                },
                
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "generalPages.Font Color",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_COLOR
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_FONT_COLOR),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    paddingLeftClass: "vis-pl--05",
                    readOnly: this.componentStyle.SUMMARY_CHART_VALUE_SHOW_TREND_COLOR ? true : false
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_SIZE
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_FONT_SIZE),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_ALIGN]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.align",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_ALIGN
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_ALIGN),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Use Trend Color",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR,
                    flexClass: true,
                    isShowCustomLabel: true,
                    customLabelText: "panelProperties.value.fontColor",
                    paddingLeftClass: "vis-pl--05"
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_COLOR
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_FONT_COLOR),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    paddingLeftClass: "vis-pl--05",
                    readOnly: this.componentStyle.SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR ? true : false
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_VALUE_LABEL]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_VALUE_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_VALUE_LABEL
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_VALUE_LABEL,
                    flexClass: true,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_SIZE
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_LABEL_FONT_SIZE),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_ALIGN]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.align",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_ALIGN
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_LABEL_ALIGN),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_COLOR
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_VALUE_FONT_COLOR),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },



                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL,
                    flexClass: true,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.align",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_COLOR
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TARGET_VALUE_FONT_COLOR),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.BUBBLE_CHART_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_AUTO_REFRESH),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH_CUSTOM
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_AUTO_REFRESH_CUSTOM),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_VALUE
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_DYNAMIC_TITLE_VALUE),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_GROUP_MULTIPLE_VALUES
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_INSIGHT
                    ], SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_SQL]: {
                    id: this.getPropertyId(
                      SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_SQL
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_SQL,
                    flexClass: true,
                },

                //TREND COLLAPSE
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_TREND_ICON]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_TREND_ICON,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Show Trend Icon",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_TREND_ICON
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TREND_SHOW_TREND_ICON
                    ),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_TREND_ICON,
                    flexClass: true,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_DIFFERENCE]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_DIFFERENCE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Show Difference",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_DIFFERENCE
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TREND_SHOW_DIFFERENCE
                    ),
                    componentType: this.componentTypes.SWITCH,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_DIFFERENCE,
                    flexClass: true,
                },
                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON,
                        this.collapse.CHART
                    ),
                    label: "conditionalFormatting.icon",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON
                    ),
                    componentType: this.componentTypes.CUSTOM_ICON_SELECT_BOX,
                    selectValues: propertiesIconList,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON,
                    flexClass: true,
                    isShowCustomLabel: true,
                    customLabelText: "Greater than target",
                    paddingLeftClass: "vis-pl--05"
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR,
                        this.collapse.CHART
                    ),
                    label: "conditionalFormatting.Icon Color",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR
                    ),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    paddingLeftClass: "vis-pl--05"
                },


                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON,
                        this.collapse.CHART
                    ),
                    label: "conditionalFormatting.icon",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON
                    ),
                    componentType: this.componentTypes.CUSTOM_ICON_SELECT_BOX,
                    selectValues: propertiesIconList,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON,
                    flexClass: true,
                    isShowCustomLabel: true,
                    customLabelText: "Equal to target",
                    paddingLeftClass: "vis-pl--05"
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR,
                        this.collapse.CHART
                    ),
                    label: "conditionalFormatting.Icon Color",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR
                    ),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    paddingLeftClass: "vis-pl--05"
                },


                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON,
                        this.collapse.CHART
                    ),
                    label: "conditionalFormatting.icon",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON
                    ),
                    componentType: this.componentTypes.CUSTOM_ICON_SELECT_BOX,
                    selectValues: propertiesIconList,
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON,
                    flexClass: true,
                    isShowCustomLabel: true,
                    customLabelText: "Less than target",
                    paddingLeftClass: "vis-pl--05"
                },

                [SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR]: {
                    id: this.getPropertyId(
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR,
                        this.collapse.CHART
                    ),
                    label: "conditionalFormatting.Icon Color",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR
                      ],
                      SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR
                    ),
                    property: SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    paddingLeftClass: "vis-pl--05"
                },
            }
        },
    },
};
