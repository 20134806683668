
import { ACTION, MUTATION, GETTER, STATE } from "./types";

import { MUTATION_GENERAL } from "../General/types";

import { vuexActionHandler } from "../../../../util/storeHelper";

import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
export const visChartFields = {
    state: {
        [STATE.CHART_FIELDS]: {}
    },
    getters: {
        [GETTER.GET_CHART_BY_ID_FIELDS]: (state) => {
            return state[STATE.CHART_FIELDS];
        },
    },
    actions: {
        [ACTION.FETCH_CHART_BY_ID_FIELDS]: async ({ commit }, chartId) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ChartFields,
                    serviceMethodSub: serviceMethodSub.readChartFieldsById,
                    successMutation: MUTATION.SET_CHART_FIELDS,
                    queryParam: chartId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.CREATE_CHART_FIELDS]: async ({ commit, dispatch }, bodyParam) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ChartFields,
                    serviceMethodSub: serviceMethodSub.createChartFields,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            await dispatch(ACTION.FETCH_CHART_BY_ID_FIELDS)
        },
        [ACTION.UPDATE_CHART_FIELDS]: async ({ commit, dispatch }, payload) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ChartFields,
                    serviceMethodSub: serviceMethodSub.updateChartFields,
                    bodyParam: payload.data,
                    queryParam: payload.chartId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            await dispatch(ACTION.FETCH_CHART_BY_ID_FIELDS)
        },
        [ACTION.DELETE_DASHBOARD_FIELDS]: async ({ commit, dispatch }, dashboardId) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Dashboard,
                    serviceMethodSub: serviceMethodSub.deleteDashboard,
                    queryParam: dashboardId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            dispatch(ACTION.FETCH_CHART_BY_ID_FIELDS);
        },
        [ACTION.FETCH_MAP_DATA]: async ({ commit }, payload) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.MapData,
                    serviceMethodSub: serviceMethodSub.readMapData,
                    queryParam: payload,
                    resultSelector: (result) => result.data,
                },
            );
        }
    },
    mutations: {
        [MUTATION.SET_CHART_FIELDS](state, payload) {
            Vue.set(state[STATE.CHART_FIELDS], payload[0].datasetId, payload);
        },
    },

};
