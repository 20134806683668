<template>
  <div class="scenario-aside-properties-general-tab">
    <span>{{ $t("Create simulation data for selected events") }}</span>
    <div class="vis-mt--1 vis-mb--1">
      <div class="simulation-properties-title">
        <label>{{ $t("Simulation Data") }}</label>
        <a @click="addNewSimulationData" class="add-button">{{ $t("Add") }}</a>
      </div>

      <draggable
        tag="ul"
        :list="localSimulateDataCloned"
        class="list-group"
        handle=".handle"
        @change="$emit('simulateOrderChanged', localSimulateDataCloned)"
      >
        <li
          class="list-group-item"
          v-for="element in localSimulateDataCloned"
          :key="element.id"
        >
          <span class="material-icons-outlined handle"> drag_handle </span>
          <div class="list-group-item-body">
            <div class="list-group-item-body-description">
              {{ element.description }}
            </div>
            <div class="list-group-item-body-title">
              <span class="scenario-properties-icon material-icons-outlined">
                view_week </span
              >{{ element.name }}
            </div>
          </div>
          <div class="scenario-simulate-item-actions">
            <span
              class="material-icons-outlined"
              @click="$emit('deleteSimulateItem', element.id)"
            >
              delete
            </span>
            <span
              class="material-icons-outlined"
              @click="$emit('editSimulateItem', element.id)"
            >
              edit
            </span>
            <span
              class="material-icons-outlined"
              @click="$emit('simulateByElement', element)"
            >
              bolt
            </span>
          </div>
        </li>
      </draggable>

      <button
        class="vis-btn medium vis-mt--2 publish-all-button"
        @click="$emit('publishAll')"
      >
        {{ $t("Publish All") }}
      </button>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import cloneDeep from "clone-deep";

export default {
  components: {
    draggable,
  },
  props: {
    properties: {
      type: Object,
    },
    events: {
      type: Array,
    },
    localSimulateData: {
      type: Array,
    },
  },
  data() {
    return {
      generalForm: {
        name: "",
      },
      localSimulateDataCloned: null,
    };
  },
  watch: {
    deep: true,
    localSimulateData: {
      handler() {
        this.localSimulateDataCloned = cloneDeep(this.localSimulateData);
      },
    },
  },
  mounted() {
    this.localSimulateDataCloned = cloneDeep(this.localSimulateData);
  },
  computed: {
    simulateItemsLength() {
      return !!this.localSimulateDataCloned?.length;
    },
  },
  methods: {
    addNewSimulationData() {
      this.$emit("showSimulatePopup");
    },
  },
};
</script>

<style scoped>
.list-group-item-body-title {
  font-size: 0.75rem;
}
.list-group-item-body-description {
  font-size: 0.75rem;
}
label {
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
  margin-bottom: 10px;
}
.handle {
  color: #bcbcbc;
  margin-left: 10px;
  cursor: all-scroll;
}
.scenario-simulate-item-actions {
  margin-left: auto;
  display: flex;
  gap: 10px;
  margin-right: 10px;
}
.scenario-simulate-item-actions .material-icons-outlined {
  font-size: 1.25rem;
  cursor: pointer;
}
.list-group-item {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 55px;
  border: 1px solid #d2d2d2;
  margin-bottom: 10px;
}
.add-button {
  color: rgb(11, 21, 248);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: auto;
  margin-bottom: 20px;
}
.simulation-properties-title {
  display: flex;
  align-items: center;
}
.not-allowed {
  cursor: not-allowed !important;
}
.publish-all-button {
  background: var(--primary-lighteen-1) 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: none;
  color: white;
  float: right;
  opacity: 1;
  margin-bottom: 25px;
}
.scenario-properties-icon {
  font-size: 1.2rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  vertical-align: middle;
  margin-right: 2px;
  color: #0014ff;
}
</style>
