/* eslint-disable */
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import { groupBy } from "lodash";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import { NOTIFY_TITLE } from "../../../../commons/notifierTypes";
import { LoadingComponent } from "../General/loadingComponentDefinitions";
export const visDashboardFields = {
    state: {
        [STATE.DASHBOARD_FIELDS]: {},
        [STATE.DASHBOARD_UNAUTHORIZED_FIELDS]: new Map()
    },
    getters: {
        [GETTER.GET_DASHBOARD_BY_ID_FIELDS]: (state) => {
            return state[STATE.DASHBOARD_FIELDS];
        },
        [GETTER.GET_DASHBOARD_UNAUTHORIZED_FIELDS]: (state) => {
            return state[STATE.DASHBOARD_UNAUTHORIZED_FIELDS];
        },
    },
    actions: {
        [ACTION.FETCH_DASHBOARD_BY_ID_FIELDS]: async ({ commit }, dashboardId) => {
           return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.DashboardFields,
                    serviceMethodSub: serviceMethodSub.readDashboardFieldsById,
                    successMutation: MUTATION.SET_DASHBOARD_FIELDS,
                    queryParam: dashboardId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    loadingComponent: LoadingComponent.DatamodelFields
                },
            );
        },
        [ACTION.CREATE_DASHBOARD_FIELDS]: async ({ commit, dispatch }, bodyParam) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.DashboardFields,
                    serviceMethodSub: serviceMethodSub.createDashboardFields,
                    bodyParam,
                    queryParam: bodyParam.dashboardId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    withSuccessNotify: false,
                },
            );
            await dispatch(ACTION.FETCH_DASHBOARD_BY_ID_FIELDS, bodyParam.dashboardId)
        },
        [ACTION.UPDATE_DASHBOARD_FIELDS]: async ({ commit, dispatch }, bodyParam) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.DashboardFields,
                    serviceMethodSub: serviceMethodSub.updateDashboardFields,
                    bodyParam,
                    queryParam: bodyParam.dashboardId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            await dispatch(ACTION.FETCH_DASHBOARD_BY_ID_FIELDS, bodyParam.dashboardId)
        },
        [ACTION.DELETE_DASHBOARD_FIELDS]: async ({ commit, dispatch }, dashboardId) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Dashboard,
                    serviceMethodSub: serviceMethodSub.deleteDashboard,
                    queryParam: dashboardId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            dispatch(ACTION.FETCH_DASHBOARD_FIELDS_BY_ID);
        },

        [ACTION.DUPLICATE_DASHBOARD_DATAMODEL_FIELD]: async ({ commit }, { dashboardId, bodyParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.DashboardFields,
                    serviceMethodSub: serviceMethodSub.duplicateDashboardDataModelField,
                    queryParam: dashboardId,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
                },
            );
        },
    },
    mutations: {
        [MUTATION.SET_DASHBOARD_FIELDS](state, payload) {
            Vue.set(state, STATE.DASHBOARD_FIELDS, groupBy(payload, x => x.datasetId));
        },
        [MUTATION.SET_DASHBOARD_UNAUTHORIZED_FIELDS](state, payload) {
            Vue.set(state, STATE.DASHBOARD_UNAUTHORIZED_FIELDS, payload);
        },
    },

};
