import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";
import { createMetricKey } from "../chartOptionsHelper";

export const summaryChartSearch = (params, searchData, chartI) => {
  const metric = params?.metric?.find(
    (x) => x.boxKey === BOX_KEY_ENUM.MEASURES.KEY
  );

  //target slotu tek başına çalışmayacağı için.
  if (!metric || !metric?.isAuthorize) return;

  const mappedResponseList = params?.metric
    ?.map((m) => {
      const fieldKey = createMetricKey(m);
      const value = searchData?.aggregations?.[0]?.[fieldKey];
      return {
        field: m?.field,
        alias: m?.alias,
        value,
        format: m?.format,
        boxKey: m?.boxKey,
      };
    })
    .sort((a, b) => {
      return a.boxKey > b.boxKey ? 1 : -1;
    });

  const calculatePercentage = () => {
    const getValueByBoxKey = (boxKey) => {
      return mappedResponseList?.find((x) => x.boxKey === boxKey)?.value;
    };

    const metricValue = getValueByBoxKey(BOX_KEY_ENUM.MEASURES.KEY);
    const targetValue = getValueByBoxKey(BOX_KEY_ENUM.TARGET.KEY);

    return ((metricValue - targetValue) / targetValue) * 100;
  };

  const percentage =
    calculatePercentage() === 0
      ? calculatePercentage()
      : calculatePercentage()?.toFixed(2);

  return {
    id: chartI,
    response: mappedResponseList,
    percentage,
  };
};
