import { CustomIcon } from "../assets/js/custom-icons"


export const SUMMARY_CHART_DEFAULT_CONFIGURATIONS = {
    SUMMARY_CHART_VALUE_FONT_FAMILY: "Poppins",
    SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins",
    SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY: "Poppins",
    SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY: "Poppins",
    SUMMARY_CHART_VALUE_FONT_COLOR: "#000",
    SUMMARY_CHART_TARGET_VALUE_FONT_COLOR: "#000",
    SUMMARY_CHART_VALUE_FONT_WEIGHT: 600,
    SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
    SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT: 600,
    SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT: 500,
    SUMMARY_CHART_VALUE_FONT_SIZE: 28,
    SUMMARY_CHART_VALUE_LABEL_FONT_SIZE: 12,
    SUMMARY_CHART_TARGET_VALUE_FONT_SIZE: 18,
    SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE: 12,
    SUMMARY_CHART_VALUE_ALIGN: "center",
    SUMMARY_CHART_VALUE_LABEL_ALIGN: "center",
    SUMMARY_CHART_TARGET_VALUE_ALIGN: "center",
    SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN: "center",
    SUMMARY_CHART_SHOW_VALUE_LABEL: true,
    SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL: true,
    SUMMARY_CHART_AUTO_REFRESH: 0,
    SUMMARY_CHART_AUTO_REFRESH_CUSTOM: null,
    SUMMARY_CHART_PROPERTIES_ENUM: "",
    SUMMARY_CHART_DYNAMIC_TITLE_VALUE: "",
    SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
    SUMMARY_CHART_SHOW_INSIGHT: true,
    SUMMARY_CHART_SHOW_SQL: false,
    SUMMARY_CHART_GROUP_MULTIPLE_VALUES: true,
    SUMMARY_CHART_TREND_SHOW_TREND_ICON: true,
    SUMMARY_CHART_TREND_SHOW_DIFFERENCE: true,
    SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON: CustomIcon.ArrowUp,
    SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR: "#57BC31",
    SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON: CustomIcon.Square,
    SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR: "#626262",
    SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON: CustomIcon.ArrowDown,
    SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR: "#FF0B44",
    SUMMARY_CHART_VALUE_SHOW_TREND_COLOR: false,
    SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR: false
}

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}
