<template>
  <div class="vis-display-flex vis-chart-filter">
    <div class="vis-mr--1" v-for="item in getFilterValueParse()" :key="item.id">
      <label>{{ item.alias }}</label>
      <el-select
        :value="item.value"
        @change="(x) => addFiltersToPanels(x, item)"
        clearable
        filterable
        collapse-tags
        :size="selectBoxSize"
        :placeholder="dropdownPlaceholder[dropdownFilterMultipleSelection]"
      >
        <el-option
          v-for="filter in item.list"
          :key="filter.value"
          :label="filter.label"
          :value="filter.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { chartTypes } from "../../../commons/dashboardAndPanel";
import { DefaultValue } from "../../../commons/dashboardProperties";
import { filterOperator } from "../../../commons/filterComponents";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import {
  checkSelectBoxSize,
  getDropdownPlaceholder,
} from "../../../util/dashboard-filter/dashboardChartFilter";
import { v4 as uuidv4 } from "uuid";
import { getSelectedFieldDatasetId } from "../../../util/dataset-fields/datasetFields";

export default {
  mixins: [panelDataPropertiesStyle],
  props: {
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    panelData: {
      type: Object,
    },
    isFilterPaneActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartTypes: chartTypes,
    };
  },
  computed: {
    dropdownPlaceholder() {
      return getDropdownPlaceholder();
    },
    selectBoxSize() {
      return checkSelectBoxSize(this.isFilterPaneActive);
    },
  },

  methods: {
    addFiltersToPanels(value, item) {
      this.$emit("addFiltersToPanels", {
        value: {
          [item.fieldKey]: { value: value.toString(), alias: item.alias },
        },
        operator: filterOperator.EQ,
        panelI: this.panelData.i,
        isActiveDefaultValue:
          this.dropdownFilterDefaultValue == DefaultValue.SELECTION,
        isChart: true,
        datasetId: getSelectedFieldDatasetId(this.panelData),
      });
    },
    getFilterValueParse() {
      return Object.keys(this.filterValue).map((x) => {
        return {
          id: uuidv4(),
          fieldKey: x,
          value: this.filterValue?.[x]?.value,
          alias: this.filterValue?.[x]?.alias,
          list: this.option?.searchFilterList?.[x]?.map((val) => {
            return { value: val, name: val };
          }),
        };
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-select__input {
  font-size: 0.75rem;
}
::v-deep
  .el-select-dropdown.is-multiple
  .el-select-dropdown__item.selected.hover {
  background-color: var(--primary);
}
</style>
