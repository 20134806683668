import { DefaultValue } from "../../commons/dashboardProperties";

export const checkFilterDefaultValueSelection = (filterDefaultValue) => {
  return filterDefaultValue === DefaultValue.SELECTION;
};
export const checkFilterDefaultValueNone = (filterDefaultValue) => {
  return filterDefaultValue === DefaultValue.NONE;
};
export const checkFilterDefaultValueDynamic = (filterDefaultValue) => {
  return filterDefaultValue === DefaultValue.DYNAMIC;
};