<template>
  <div>
    <div class="in-coder-panel">
      <textarea id="ruleEditor"></textarea>
    </div>
  </div>
</template>

<script>
// Introduce global instance
import * as CodeMirror from "codemirror";

import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/display/autorefresh.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/javascript-hint.js";

export default {
  props: {
    eventFields: {
      type: Array,
      required: true,
    },
    enrichments: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      eventHints: [],
      enrichmentHints: [],
      coder: null,
    };
  },
  watch: {
    value(newVal) {
      if (this.coder) {
        const cursor = this.coder.getCursor(); // Get current cursor position
        this.coder.setValue(newVal);
        this.coder.setCursor(cursor); // Set cursor position back to where it was
      }
    },
  },
  mounted() {
    const registerCodeMirror = () => {
      this.coder = CodeMirror.fromTextArea(
        document.getElementById("ruleEditor"),
        {
          lineNumbers: true,
          tabSize: 4,
          line: true,
          mode: "javascript",
          autoRefresh: true,
          extraKeys: { Ctrl: "autocomplete" },
        }
      );
      this.coder.setValue(this.value);
      this.coder.on("change", (coder) => {
        this.$emit("input", coder.getValue());
      });
    };

    const createEventHints = () => {
      this.eventHints = this.eventFields?.map((field) => {
        return {
          text: `@${field}`,
          className: "CodeMirror-hint-keyword cm-keyword",
        };
      });
    };

    const createEnrichmentHints = () => {
      this.enrichmentHints = this.enrichments?.map((field) => {
        return {
          text: `#${field}`,
          className: "CodeMirror-hint-keyword cm-keyword",
        };
      });
    };

    const setCustomHints = () => {
      createEventHints();
      createEnrichmentHints();

      CodeMirror.hint.javascript = (cm) => {
        const inner = CodeMirror.hint.anyword(cm) || {
          from: cm.getCursor(),
          to: cm.getCursor(),
          list: [],
        };

        inner.list.push(...this.eventHints);
        inner.list.push(...this.enrichmentHints);

        return inner;
      };
    };

    registerCodeMirror();
    setCustomHints();
  },
};
</script>
