var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vis-mb--2 vis-col vis-col-6 vis-pa--none",class:{ 'vis-col-12': !_vm.component.flex }},[_c('div',{class:[
      {
        'vis-flex--spacebeetwen-Ycenter':
          _vm.component.componentType !== _vm.componentTypes.SELECT_BOX,
      },
      _vm.component.className,
    ]},[_c('label',[_vm._v(_vm._s(_vm.$t(_vm.component.label)))]),(_vm.component.componentType === _vm.componentTypes.COLOR_PICKER)?_c('el-color-picker',{attrs:{"value":_vm.component.value,"size":"mini","disabled":_vm.disabledComponent},on:{"change":function($event){return _vm.changePropertyStyle($event, _vm.component.property)}}}):_vm._e(),(_vm.component.componentType === _vm.componentTypes.SWITCH)?_c('el-switch',{attrs:{"value":_vm.component.value,"disabled":_vm.disabledComponent},on:{"change":function($event){return _vm.changePropertyStyle($event, _vm.component.property)}}}):_vm._e(),(_vm.component.componentType === _vm.componentTypes.SELECT_BOX)?_c('el-select',{attrs:{"value":_vm.component.value,"disabled":_vm.disabledComponent,"placeholder":_vm.$t('generalPages.selectValue')},on:{"change":function($event){return _vm.changePropertyStyle($event, _vm.component.property)}}},_vm._l((_vm.component.selectBoxOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(item.label),"value":item.value}})}),1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }