<template>
  <Layout articleId="article" :showNav="false" :showAside="showEventAside">
    <div slot="modals"></div>
    <div slot="header" class="event-header">
      <span class="event-header-title">{{ $t("Event") }}</span>
      <div class="vis-ml--auto event-header-actions">
        <i
          class="vis-cursor-pointer vis-position-relative"
          :class="CustomIcon.SaveOutlined"
          @click.stop="saveEvent"
          aria-hidden="true"
        />
        <i
          class="vis-cursor-pointer vis-position-relative"
          :class="[
            CustomIcon.Filter,
            {
              'vis-active-setting-icon': showEventAside,
            },
          ]"
          @click.stop="toggleAside"
          aria-hidden="true"
        />
      </div>
    </div>
    <div slot="aside">
      <EventAsideProperties
        :properties="properties"
        @updateName="(value) => (properties.eventName = value)"
        @updateDescription="(value) => (properties.eventDescription = value)"
      />
    </div>

    <div slot="article" class="vis-height-100 event-article-container">
      <div class="event-table-top-bar">
        <span class="fields-table-title">{{ $t("Fields") }}</span>
        <a @click="addNewFieldItem" class="field-add-button">{{
          $t("Add Field")
        }}</a>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :sort-options="{
          enabled: false,
        }"
      >
        <div slot="emptystate" class="vis-text--center">
          {{ $t("generalPages.noData") }}
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == columnKeys.field">
            <el-input
              :placeholder="$t('Field name')"
              :value="props.row.field"
              @input="
                (value) => updateRowById(props.row.id, columnKeys.field, value)
              "
            ></el-input>
          </span>
          <span v-if="props.column.field == columnKeys.type">
            <el-select
              :value="props.row.type"
              :placeholder="$t('Type')"
              @change="
                (value) => updateRowById(props.row.id, columnKeys.type, value)
              "
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <span v-if="props.column.field == columnKeys.actor">
            <el-radio v-model="actorIdField" :label="props.row.id"></el-radio>
          </span>
          <span v-if="props.column.field == columnKeys.description">
            <el-input
              :placeholder="$t('Description')"
              :value="props.row.description"
              @input="
                (value) =>
                  updateRowById(props.row.id, columnKeys.description, value)
              "
            ></el-input>
          </span>
          <span v-if="props.column.field == columnKeys.action">
            <i
              :class="CustomIcon.Delete"
              class="select-database-connection-action-icon vis-cursor-pointer"
              aria-hidden="true"
              @click="deleteActionClicked(props.row)"
            />
          </span>
        </template>
      </vue-good-table>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Layout from "../layout/Layout.vue";
import { CustomIcon } from "../assets/js/custom-icons";
import { VueGoodTable } from "vue-good-table";
import { v4 as uuidv4 } from "uuid";
import {
  GETTER as GETTER_SCENARIO_MAIN,
  ACTION as ACTION_SCENARIO_MAIN,
} from "../store/modules/Scenario/Main/types";
import EventAsideProperties from "../components/scenario/event/EventAsideProperties.vue";
import { routerEnums } from '../commons/Enum';

export default {
  components: {
    Layout,
    VueGoodTable,
    EventAsideProperties,
  },
  data() {
    return {
      showEventAside: true,
      selectedTransitionId: null,
      selectedTransitionProperties: null,
      selectedStateId: null,
      selectedStateProperties: null,
      CustomIcon: CustomIcon,
      rows: [],
      typeEnums: {
        string: "String",
        number: "Number",
        long: "Long",
      },
      properties: {
        eventName: "",
        eventDescription: "",
      },
      actorIdField: null,
      columnKeys: {
        field: "field",
        type: "type",
        actor: "actor",
        description: "description",
        action: "action",
      },
    };
  },
  async mounted() {
    if (!this.eventId) {
      this.addNewFieldItem();
    } else {
      await this.fetchEventById({ id: this.eventId });
      this.prepareFormForUpdate();
    }
  },
  computed: {
    ...mapGetters({
      selectedEvent: GETTER_SCENARIO_MAIN.GET_SELECTED_EVENT,
    }),
    eventId() {
      return this.$route.params.id;
    },
    typeOptions() {
      return [
        {
          value: this.typeEnums.string,
          label: this.$t("String"),
        },
        {
          value: this.typeEnums.number,
          label: this.$t("Number"),
        },
        {
          value: this.typeEnums.long,
          label: this.$t("Long"),
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("Field Name"),
          field: this.columnKeys.field,
        },
        {
          label: this.$t("Type"),
          field: this.columnKeys.type,
        },
        {
          label: this.$t("Actor"),
          field: this.columnKeys.actor,
        },
        {
          label: this.$t("Description"),
          field: this.columnKeys.description,
        },
        {
          label: this.$t("Action"),
          field: this.columnKeys.action,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      createEvent: ACTION_SCENARIO_MAIN.CREATE_EVENT,
      updateEvent: ACTION_SCENARIO_MAIN.UPDATE_EVENT,
      fetchEventById: ACTION_SCENARIO_MAIN.FETCH_EVENT_BY_ID,
    }),
    prepareFormForUpdate() {
      this.rows = this.selectedEvent.fields.map((e) => ({
        id: uuidv4(),
        ...e,
      }));
      this.properties = {
        eventName: this.selectedEvent.name,
        eventDescription: this.selectedEvent.description,
      };
      this.actorIdField = this.rows.find(
        (row) => row.field === this.selectedEvent.actorIdField
      )?.id;
    },
    updateRowById(id, key, value) {
      const rowToUpdate = this.rows.find((row) => row.id === id);

      rowToUpdate[key] = value;
    },
    async saveEvent() {
      const fields = this.rows.map(({ field, type, description }) => ({
        field,
        type,
        description,
      }));
      const actorIdField = this.rows.find(
        (row) => row.id === this.actorIdField
      )?.field;
      const payload = {
        name: this.properties.eventName,
        description: this.properties.eventDescription,
        actorIdField,
        fields,
      };

      if (!this.eventId) {
        const result = await this.createEvent({ data: payload });

        if (result.eventId)
          this.$router.push({
            name: routerEnums.EVENT_EDIT,
            params: { id: result.eventId },
          });
      } else {
        this.updateEvent({ id: this.eventId, data: payload });
      }
    },
    toggleAside() {
      this.showEventAside = !this.showEventAside;
    },
    addNewFieldItem() {
      const newFieldItemId = uuidv4();

      this.rows.push({
        id: newFieldItemId,
        field: "",
        type: this.typeEnums.string,
        description: "",
      });

      if (this.rows.length === 1) this.actorIdField = newFieldItemId;
    },
    deleteActionClicked(row) {
      this.rows = this.rows.filter((r) => r.id !== row.id);

      if (this.actorIdField === row.id) {
        this.actorIdField = null;
      }
    },
  },
};
</script>

<style scoped>
::v-deep header {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e3e8;
}
::v-deep aside {
  width: 470px;
}
::v-deep .el-tabs__item {
  padding: 0;
}
::v-deep .el-tabs__item.is-active {
  color: #0014ff;
}
::v-deep .el-tabs__active-bar {
  background: #0014ff;
}
::v-deep .el-tabs__item:hover {
  color: #0014ff;
}
::v-deep .el-radio__label {
  display: none;
}
::v-deep table.vgt-table thead tr {
  background: transparent !important;
  border: none !important;
}
::v-deep table.vgt-table thead tr {
  background: transparent !important;
  border: none !important;
}
::v-deep table.vgt-table tbody tr {
  background: transparent !important;
  border: none !important;
}
::v-deep table.vgt-table tbody {
  background: transparent !important;
}
::v-deep .vgt-table.bordered td {
  vertical-align: middle !important;
}
.event-header {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: inherit;
}
.event-header-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
}
.event-header-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.event-article-container {
  padding: 50px;
}
.event-table-top-bar {
  display: flex;
}
.fields-table-title {
  font: normal normal 600 15px/23px Poppins;
  color: #3e3e3e;
}
.field-add-button {
  color: rgb(11, 21, 248);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: auto;
  margin-bottom: 20px;
}
</style>
