<template>
  <div class="vis-settings-page">
    <!-- filter modal starts -->
    <SchedulesFilterPopup
      v-if="showFilterPopup"
      :dialogVisible="showFilterPopup"
      :owners="owners"
      :runAs="runAs"
      :filterValue="filterValueApplied"
      @close="showFilterPopup = false"
      @applyFilters="applyFilters"
    />
    <!-- filter modal ends -->

    <!-- view details modal starts -->
    <SchedulesViewDetailsPopup
      :dialogVisible="viewDetailsDialogVisible"
      :row="viewDetailsRow"
      @close="handleViewDetailsDialogClose"
    />
    <!-- view details modal ends -->

    <div class="settings-menu">
      <div class="settings-header">
        <span class="settings-header-span">{{
          $t("admin.menuItem.Schedules")
        }}</span>
      </div>
      <el-menu mode="vertical" :default-active="activeMenuItem">
        <el-menu-item
          v-for="(menuItem, index) in menuItems"
          :key="index"
          :index="menuItem"
          :disabled="menuItem !== settingsMenuEnum.DASHBOARDS"
          @click="selectMenuItem(menuItem)"
        >
          {{ $t(`${menuItem}`) }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="settings-content" v-loading="!activeMenuItem">
      <div class="schedules-header">
        <span class="schedules-header-title">{{
          $t("Run history of scheduled dashboards")
        }}</span>

        <div class="schedules-header-filters">
          <i
            class="vis-cursor-pointer"
            aria-hidden="true"
            :class="CustomIcon.FilterAltOutlined"
            @click="showFilterPopup = true"
          />
        </div>
      </div>

      <div class="schedules-table">
        <div class="vis-vgt-card">
          <vue-good-table
            ref="migrate-selection-table"
            :columns="columns"
            :rows="filteredRows"
            :pagination-options="paginationOptions"
            max-height="560px"
          >
            <template slot="table-column" slot-scope="props">
              <span>
                {{ $t(`${props.column.label}`) }}
              </span>
            </template>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == columnKeys.STARTED_AT">
                <span>{{ formatISOToDateTime(props.row.startingDate) }}</span>
              </span>
              <span v-else-if="props.column.field == columnKeys.FINISHED_AT">
                <span>{{ formatISOToDateTime(props.row.endingDate) }}</span>
              </span>
              <span
                v-else-if="props.column.field == columnKeys.STATUS"
                :class="{
                  'failed-status': isStatusFailed(
                    props.row[props.column.field]
                  ),
                }"
              >
                {{ props.formattedRow[props.column.field] }}
              </span>
              <span v-else-if="props.column.field == columnKeys.ACTIONS">
                <i :class="CustomIcon.Description" class="vis-cursor-pointer" aria-hidden="true" @click="viewDetails(props.row)" />
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
import { formatISOToDateTime } from "../../util/moment";
import { mapActions, mapGetters } from "vuex";
import {
  ACTION as ACTION_SCHEDULE,
  GETTER as GETTERS_SCHEDULE,
} from "../../store/modules/Visualize/Schedule/types";
import SchedulesViewDetailsPopup from "./SchedulesViewDetailsPopup.vue";
import SchedulesFilterPopup from "./SchedulesFilterPopup.vue";

const SCHEDULES_MENU_ENUM = {
  DASHBOARDS: "Dashboards",
  FLOW_JOBS: "Flow Jobs",
  ALARMS: "Alarms",
};

const schedulesMenuItems = [
  SCHEDULES_MENU_ENUM.DASHBOARDS,
  SCHEDULES_MENU_ENUM.FLOW_JOBS,
  SCHEDULES_MENU_ENUM.ALARMS,
];

function removeusingSet(arr) {
  let outputArray = Array.from(new Set(arr));
  return outputArray;
}

export default {
  components: {
    VueGoodTable,
    SchedulesViewDetailsPopup,
    SchedulesFilterPopup,
  },
  data() {
    return {
      viewDetailsDialogVisible: false,
      viewDetailsRow: null,
      showFilterPopup: false,
      menuItems: schedulesMenuItems,
      activeMenuItem: null,
      settingsMenuEnum: SCHEDULES_MENU_ENUM,
      CustomIcon: CustomIcon,
      columnKeys: {
        NAME: "dashboardName",
        OWNER: "owner",
        STARTED_AT: "startingDate",
        FINISHED_AT: "endingDate",
        RUN_AS: "runAs",
        FILE_TYPE: "taskType",
        DYNAMIC: "isDynamic",
        STATUS: "scheduleStatus",
        ACTIONS: "actions",
      },
      filterValueApplied: {
        filterNameDropdown: "Contains",
        filterName: "",
        filterTaskType: "",
        filterOwner: "",
        filterRunAs: "",
        filterStartingDate: "",
        filterEndingDate: "",
        filterDashboardId: "",
        filterScheduleStatus: "",
      },
      paginationOptions: {
        enabled: true,
        mode: "pages",
        perPageDropdownEnabled: false,
        perPage: 10,
        setCurrentPage: 1,
        position: "bottom",
        jumpFirstOrLast: false,
        nextLabel: "",
        prevLabel: "",
        ofLabel: "/",
        pageLabel: this.$t("generalPages.Page"),
      },
      formatISOToDateTime: formatISOToDateTime,
      statuses: {
        FAILED: "FAILED",
        // other statuses
      },
    };
  },
  async mounted() {
    this.activeMenuItem = SCHEDULES_MENU_ENUM.DASHBOARDS;
    this.fetchTransactionsHistory();
  },
  computed: {
    ...mapGetters({
      transactionsHistory: GETTERS_SCHEDULE.GET_TRANSACTIONS_HISTORY,
    }),
    columns() {
      return [
        {
          label: "Name",
          field: this.columnKeys.NAME,
        },
        {
          label: "Owner",
          field: this.columnKeys.OWNER,
        },
        {
          label: "Run as",
          field: this.columnKeys.RUN_AS,
        },
        {
          label: "File type",
          field: this.columnKeys.FILE_TYPE,
        },
        {
          label: "Dynamic",
          field: this.columnKeys.DYNAMIC,
        },
        {
          label: "Started at",
          field: this.columnKeys.STARTED_AT,
        },
        {
          label: "Finished at",
          field: this.columnKeys.FINISHED_AT,
        },
        {
          label: "Status",
          field: this.columnKeys.STATUS,
        },
        {
          label: "Actions",
          field: this.columnKeys.ACTIONS,
        },
      ];
    },
    filteredRows() {
      let filtered = this.transactionsHistory;

      if (this.filterValueApplied.filterName) {
        filtered = filtered.filter((f) =>
          f.dashboardName
            .toLowerCase()
            .includes(this.filterValueApplied.filterName.toLowerCase())
        );
      }

      if (this.filterValueApplied.filterTaskType)
        filtered = filtered.filter(
          (f) => f.taskType === this.filterValueApplied.filterTaskType
        );

      if (this.filterValueApplied.filterOwner)
        filtered = filtered.filter(
          (f) => f.owner === this.filterValueApplied.filterOwner
        );

      if (this.filterValueApplied.filterRunAs)
        filtered = filtered.filter(
          (f) => f.runAs === this.filterValueApplied.filterRunAs
        );

      if (this.filterValueApplied.filterScheduleStatus)
        filtered = filtered.filter(
          (f) =>
            f.scheduleStatus === this.filterValueApplied.filterScheduleStatus
        );

      if (this.filterValueApplied.filterDashboardId)
        filtered = filtered.filter(
          (f) => f.dashboardId === this.filterValueApplied.filterDashboardId
        );

      if (this.filterValueApplied.filterStartingDate?.length === 2) {
        const momentCreatedAtStart = moment(
          this.filterValueApplied.filterStartingDate[0]
        );
        const momentCreatedAtEnd = moment(
          this.filterValueApplied.filterStartingDate[1]
        );

        filtered = filtered.filter((f) => {
          return moment(f.startingDate).isBetween(
            momentCreatedAtStart,
            momentCreatedAtEnd
          );
        });
      }

      if (this.filterValueApplied.filterEndingDate?.length === 2) {
        const momentUpdatedAtStart = moment(
          this.filterValueApplied.filterEndingDate[0]
        );
        const momentUpdatedAtEnd = moment(
          this.filterValueApplied.filterEndingDate[1]
        );

        filtered = filtered.filter((f) => {
          return moment(f.endingDate).isBetween(
            momentUpdatedAtStart,
            momentUpdatedAtEnd
          );
        });
      }

      return filtered;
    },
    owners() {
      return removeusingSet(
        this.transactionsHistory?.map((d) => d.owner) ?? []
      );
    },
    runAs() {
      return removeusingSet(this.transactionsHistory.map((d) => d.runAs) ?? []);
    },
  },
  methods: {
    ...mapActions({
      fetchTransactionsHistory: ACTION_SCHEDULE.FETCH_TRANSACTIONS_HISTORY,
    }),
    handleViewDetailsDialogClose() {
      this.viewDetailsDialogVisible = false;
      this.viewDetailsRow = null;
    },
    viewDetails(row) {
      this.viewDetailsDialogVisible = true;
      this.viewDetailsRow = row;
    },
    isStatusFailed(status) {
      return status === this.statuses.FAILED;
    },
    selectMenuItem(item) {
      this.activeMenuItem = item;
    },
    resetCurrentPage() {
      this.$refs["migrate-selection-table"].changePage(1);
    },
    applyFilters(newFilterValue) {
      this.showFilterPopup = false;
      this.filterValueApplied = newFilterValue;
      this.resetCurrentPage();
    },
  },
};
</script>

<style scoped>
.vis-settings-page {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  background: var(--white);
}

.settings-menu {
  width: 200px;
}
.el-menu {
  height: 100%;
}

.settings-content {
  position: relative;
  flex-grow: 1;
  padding: 20px;
}

.settings-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding: 20px 30px 20px;
  font-size: 26px;
  font-weight: 600;
  color: #3e3e3e;
}
.settings-header-span {
  width: 108px;
  height: 36px;
}
.el-menu-item.is-active {
  background-color: #ebebeb !important;
  color: #494849;
  font-weight: 600;
}
.el-menu-item {
  height: 40px;
  line-height: 40px;
  padding-left: 40px !important;
}
.schedules-container {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.schedules-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding: 20px 30px 0;
  font-size: 26px;
  font-weight: 600;
  color: #3e3e3e;
}
.schedules-header-filters {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.schedules-table {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  overflow-y: hidden;
}

::v-deep table.vgt-table thead tr {
  background-color: #fff !important;
}
::v-deep .vgt-table th.vgt-checkbox-col {
  background: #fff !important;
}
::v-deep table.vgt-table tbody tr:hover {
  background-color: #fff !important;
}
::v-deep .vgt-wrap__footer {
  background: white !important;
}
::v-deep .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid var(--primary-lighteen-1) !important;
}
::v-deep .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid var(--primary-lighteen-1) !important;
}
::v-deep .el-dialog__title {
  font-weight: 500;
}
::v-deep .el-dialog__header {
  padding: 20px 20px;
}
.schedules-header-title {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.failed-status {
  color: var(--danger);
  font-weight: 500;
}
</style>
