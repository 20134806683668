export const EXPORT_STATUS = {
    IN_PROGRESS: "IN_PROGRESS",
    WAITING: "WAITING",
    FAILED: "FAILED",
    FINISHED: "FINISHED"
}
export const USER_PREFERENCES_KEY = {
    LANGUAGE: "language"
}
export const USER_PREFERENCES_VALUE = {
    EN: "en",
    TR: "tr"
}
export const USER_LANGUAGES = [
    { id: 1, label: "generalPages.english", key: USER_PREFERENCES_KEY.LANGUAGE, value: USER_PREFERENCES_VALUE.EN },
    { id: 2, label: "generalPages.turkish", key: USER_PREFERENCES_KEY.LANGUAGE, value: USER_PREFERENCES_VALUE.TR },
]