/* eslint-disable */
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
/* import _ from "lodash"; */
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visFieldAuthorization = {
  state: {
    [STATE.FIELD_AUTHORIZATION_BY_DATAMODEL]: [],
    [STATE.FIELD_AUTHORIZATION_BY_DATASET]: [],
  },
  getters: {
    [GETTER.GET_FIELD_AUTHORIZATION_BY_DATAMODEL]: (state) => {
      return state[STATE.FIELD_AUTHORIZATION_BY_DATAMODEL];
    },
    [GETTER.GET_FIELD_AUTHORIZATION_BY_DATASET]: (state) => {
      return state[STATE.FIELD_AUTHORIZATION_BY_DATASET];
    },
  },
  actions: {
    [ACTION.FETCH_FIELD_AUTHORIZATION_BY_DATAMODEL]: async (
      { commit },
      { datamodelId }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.FieldAuthorization,
        serviceMethodSub: serviceMethodSub.readFieldAuthorizationByDatamodelId,
        queryParam: datamodelId,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        successMutation: MUTATION.SET_FIELD_AUTHORIZATION_BY_DATAMODEL,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: false,
        withErrorNotify: true,
      });
    },
    [ACTION.FETCH_FIELD_AUTHORIZATION_BY_DATASET]: async (
      { commit },
      { datasetId }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.FieldAuthorization,
        serviceMethodSub: serviceMethodSub.readFieldAuthorizationByDatasetId,
        queryParam: datasetId,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        successMutation: MUTATION.SET_FIELD_AUTHORIZATION_BY_DATASET,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: false,
        withErrorNotify: true,
      });
    },
    [ACTION.CREATE_FIELD_AUTHORIZATION]: async ({ commit }, bodyParam) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.FieldAuthorization,
        serviceMethodSub: serviceMethodSub.createFieldAuthorization,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_FIELD_AUTHORIZATION]: async (
      { commit },
      { fieldAuthorizationId, bodyParam }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.FieldAuthorization,
        serviceMethodSub: serviceMethodSub.updateFieldAuthorization,
        queryParam: fieldAuthorizationId,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.DELETE_FIELD_AUTHORIZATION]: async (
      { commit },
      { fieldAuthorizationId }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.FieldAuthorization,
        serviceMethodSub: serviceMethodSub.deleteFieldAuthorization,
        queryParam: fieldAuthorizationId,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
  },
  mutations: {
    [MUTATION.SET_FIELD_AUTHORIZATION_BY_DATAMODEL](state, items) {
      Vue.set(state, [STATE.FIELD_AUTHORIZATION_BY_DATAMODEL], items);
    },
    [MUTATION.SET_FIELD_AUTHORIZATION_BY_DATASET](state, items) {
      Vue.set(state, [STATE.FIELD_AUTHORIZATION_BY_DATASET], items);
    },
  },
};
