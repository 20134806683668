export const domain = "VISUALIZE_HOME";

export const STATE = {
  SEARCH_HOMEPAGE_RESULTS: `${domain}/SEARCH_HOMEPAGE_RESULTS`,
  SEARCH_HOMEPAGE_PAGINATION: `${domain}/SEARCH_HOMEPAGE_PAGINATION`,
};

export const GETTER = {
  GET_SEARCH_HOMEPAGE_RESULTS: `${domain}/GET_SEARCH_RESULTS`,
  GET_HOMEPAGE_PAGINATION: `${domain}/GET_HOMEPAGE_PAGINATION`,
};

export const ACTION = {
  FETCH_HOMEPAGE_SEARCH: `${domain}/FETCH_HOMEPAGE_SEARCH`,
};

export const MUTATION = {
  SET_SEARCH_HOMEPAGE_RESULTS: `${domain}/SET_SEARCH_HOMEPAGE_RESULTS`,
  RESET_SEARCH_HOMEPAGE_RESULTS: `${domain}/RESET_SEARCH_HOMEPAGE_RESULTS`,
};
