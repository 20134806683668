<template>
  <div
    id="selectDatabaseConnectionContent"
    class="select-database-connection-container"
  >
    <div class="select-database-connection-container-body">
      <vue-good-table
        :columns="selectDatabaseConnectionColumns"
        :rows="rows"
        :selectOptions="selectOptions"
        @on-row-click="handleRowClick"
      >
        <div slot="emptystate" class="vis-text--center">
          {{
            $t(
              "generalPages.You don’t have access permission to any connection!"
            )
          }}
        </div>
        <template slot="table-column" slot-scope="props">
          <span>
            {{ $t(`${props.column.label}`) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <div
            v-if="
              props.column.field ===
              selectDatabaseConnectionColumnsFields.actions
            "
          >
            <div class="select-database-connection-actions-container">
              <button
                v-if="!findAuthorityById(props.row.connectionId, [
                    AuthorizationConnectionEnums.read,
                  ])"
                class="select-database-comnection-actions-button"
                @click="deleteActionClicked(props.row)"
              >
                <i
                  :class="CustomIcon.Delete"
                  class="select-database-connection-action-icon vis-cursor-pointer"
                  aria-hidden="true"
                />
              </button>

              <button
                v-if="!findAuthorityById(props.row.connectionId, [
                    AuthorizationConnectionEnums.read,
                  ])"
                class="select-database-comnection-actions-button"
                @click="editActionClicked(props.row)"
              >
                <i
                  :class="CustomIcon.PencilOutline"
                  class="select-database-connection-action-icon vis-cursor-pointer"
                  aria-hidden="true"
                />
              </button>

              <button
                v-if="!isModuleScenario && !findAuthorityById(props.row.connectionId, [
                    AuthorizationConnectionEnums.read,
                    AuthorizationConnectionEnums.write,
                  ])"
                class="select-database-comnection-actions-button"
                @click="checkActionClicked(props.row)"
              >
                <i
                  :class="CustomIcon.Share"
                  class="select-database-connection-action-icon vis-cursor-pointer"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span></template
        >
      </vue-good-table>
    </div>
    <ConfirmDialog
      :title="$t('dialog.deleteConnectionTitle')"
      :message="$t('dialog.deleteConnectionMessage')"
      :dialogVisible="dialogVisible"
      @confirm="deleteConnectionData"
      @cancel="dialogVisible = $event"
    />
  </div>
</template>

<script>
import { importTypes } from "../../commons/dataModelTypes";
import {
  getSelectDatabaseConnectionColumns,
  selectDatabaseConnectionColumnsFields,
} from "./SelectDatabaseConnection.config";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";
import {
  GETTER as GETTER_CONNECTIONS,
  ACTION as ACTION_CONNECTIONS,
} from "../../store/modules/Visualize/Connections/types";
import ConfirmDialog from "../../components/helper/ConfirmDialog.vue";
import { CustomIcon } from "../../assets/js/custom-icons";
import { Modules } from "../../commons/modules";
import eventBus from "../../eventBus";
import { ROLES } from "../../util/authorization";

export default {
  components: { VueGoodTable, ConfirmDialog },
  props: {
    hideCreateDate: {
      type: Boolean,
      default: false,
    },
    hideUpdateDate: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideSelectOptions: {
      type: Boolean,
      default: false,
    },
    filterConnectionTypeBy: {
      type: Array,
      default: () => [],
    },
    currentUserRoles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectDatabaseConnectionColumnsFields:
        selectDatabaseConnectionColumnsFields,
      importTypes: importTypes,
      dialogVisible: false,
      connectionId: null,
      CustomIcon: CustomIcon,
      AuthorizationConnectionEnums: {
        read: "READ",
        write: "WRITE",
      },
      selectedRow: null,
      isModuleScenario: false,
    };
  },
  mounted() {
    this.init();

    eventBus.$on("onSelectedModuleChanged", () => {
      this.init();
    });
  },
  computed: {
    ...mapGetters({
      connections: GETTER_CONNECTIONS.GET_CONNECTIONS,
      userAuthorities: GETTER_CONNECTIONS.GET_USER_AUTHORIZATIONS,
    }),
    selectOptions() {
      return {
        enabled: !this.hideSelectOptions,
        selectionInfoClass: "custom-selection-info", // optional
        disableSelectInfo: true, // disable the select info panel on top
      };
    },
    selectDatabaseConnectionColumns() {
      return getSelectDatabaseConnectionColumns(
        this.hideCreateDate,
        this.hideUpdateDate,
        this.hideActions
      );
    },
    rows() {
      if (this.filterConnectionTypeBy?.length) {
        return this.connections?.filter(
          (c) => this.filterConnectionTypeBy.includes(c.type)
        );
      }

      return this.connections;
    },
    username: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Username"];
      },
    },
  },
  methods: {
    ...mapActions({
      fetchConnections: ACTION_CONNECTIONS.FETCH_CONNECTIONS,
      deleteConnection: ACTION_CONNECTIONS.DELETE_CONNECTION,
      createConnection: ACTION_CONNECTIONS.CREATE_CONNECTION,
      findAuthoritiesByUsername:
        ACTION_CONNECTIONS.FIND_AUTHORITIES_BY_USERNAME,
    }),
    init() {
      this.fetchConnections();
      this.findAuthoritiesByUsername(this.username);
      this.isModuleScenario =
        localStorage.getItem("selectedModule") == Modules.SCENARIO;
    },
    handleRowClick(item) {
      this.rows.forEach((row, index) => {
        if (index !== item.pageIndex) {
          this.$set(row, "vgtSelected", false);
        } else {
          if (!row.vgtSelected) {
            this.$set(row, "vgtSelected", true);
            this.onRowClick(item.row);
          } else {
            this.$set(row, "vgtSelected", false);
            this.onRowClick(null);
          }
        }
      });
    },
    onRowClick(row) {
      this.$emit("selectConnection", row);
    },
    deleteActionClicked(item) {
      this.dialogVisible = true;
      this.connectionId = item.connectionId;
    },
    deleteConnectionData() {
      this.deleteConnection(this.connectionId);
      this.dialogVisible = false;
    },
    editActionClicked(item) {
      this.$emit("editClicked", item);
    },
    checkActionClicked(item) {
      this.$emit("checkClicked", item);
    },
    findAuthorityById(connectionId, authorities) {
      const isAdmin = this.currentUserRoles?.some((r) =>
        [ROLES.ADMIN.key].includes(r)
      );
      const userInfo = this.userAuthorities.find(
        (item) => item?.connectionId == connectionId
      );

      if (!isAdmin && authorities.includes(userInfo?.connectionAuthorityTypes)) {
        return true;
      } else return false;
    },
  },
};
</script>

<style scoped>
.select-database-connection-container {
  height: calc(100% - 64px);
  background: #fff;
}
.select-database-connection-container-body {
  padding: 20px 58px;
  background: var(--white);
  height: inherit;
}
.select-database-connection-actions-container {
  display: flex;
  column-gap: 10px;
}
::v-deep table.vgt-table thead tr {
  background-color: var(--white);
  border-top: none;
}
.select-database-connection-action-icon {
  color: #606266;
}
.select-database-comnection-actions-button {
  height: 20px;
  width: 20px;
  border: 0 !important;
  background-color: transparent;
}
.vis-icon {
  font-size: 16px !important;
}
::v-deep .vgt-table thead th {
  color: #3e3e3e !important;
}
::v-deep table.vgt-table tbody td {
  color: #3e3e3e !important;
}
::v-deep .vgt-table thead th.vgt-checkbox-col {
  visibility: hidden;
}
::v-deep .vgt-table th.vgt-checkbox-col {
  background: transparent !important;
}
</style>
