export const domain = "VISUALIZE_SCHEDULE";

export const STATE = {
    SCHEDULES: `${domain}/SCHEDULES`,
    TRANSACTIONS_HISTORY: `${domain}/TRANSACTIONS_HISTORY`,
};

export const GETTER = {
    GET_SCHEDULES: `${domain}/GET_SCHEDULES`,
    GET_TRANSACTIONS_HISTORY: `${domain}/GET_TRANSACTIONS_HISTORY`,
};

export const ACTION = {
    CREATE_SCHEDULE: `${domain}/CREATE_SCHEDULE`,
    UPDATE_SCHEDULE: `${domain}/UPDATE_SCHEDULE`,
    DELETE_SCHEDULE: `${domain}/DELETE_SCHEDULE`,
    FETCH_SCHEDULES: `${domain}/FETCH_SCHEDULES`,
    FETCH_TRANSACTIONS_HISTORY: `${domain}/FETCH_TRANSACTIONS_HISTORY`,
};

export const MUTATION = {
    SET_SCHEDULES: `${domain}/SET_SCHEDULES`,
    SET_TRANSACTIONS_HISTORY: `${domain}/SET_TRANSACTIONS_HISTORY`,
};