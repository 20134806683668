<template>
  <div>
    <Datamodel ref="dataModelComponent" />
    <ConfirmDialog
      :title="$t('dialog.unsaveTitle')"
      :message="$t('dialog.unsaveMessage')"
      :dialogVisible="dialogVisible"
      @cancel="dialogVisible = $event"
      @confirm="setConfirm()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Datamodel from "./Datamodel";
import {
  TEMP_STORAGE_KEYS,
  GETTER as GETTER_TEMP_STORAGE,
} from "../store/modules/temp-storage/types";
import { diff } from "deep-object-diff";
import { routerEnums } from "../commons/Enum";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";

export default {
  components: {
    Datamodel,
    ConfirmDialog,
  },
  beforeRouteLeave(to, from, next) {
    this.diffDatamodel = diff(
      this.$refs.dataModelComponent._data.createDatamodelPayload,
      this.tempDataModel
    );

    if (
      Object.keys(this.diffDatamodel).length &&
      to.name === routerEnums.HOME
    ) {
      this.dialogVisible = true;
      this.beforeRouteLink = to.name;
      if (this.confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      dialogVisible: false,
      confirmLeave: false,
      beforeRouteLink: "",
    };
  },
  computed: {
    ...mapGetters({
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
    }),
    tempDataModel() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_DATAMODEL);
    },
  },
  methods: {
    setConfirm() {
      this.confirmLeave = true;
      this.dialogVisible = false;
      this.$router.push({ name: this.beforeRouteLink });
    },
  },
};
</script>