<template>
  <div
    class="vis-dashboard-custom-col vis-right-border vis-height-100 vis-scroll-thin"
  >
    <div class="vis-tools-box vis-page vis-bg-color-white">
      <div class="vis-row vis-tools-tab">
        <Typography
          variant="h5"
          :text="selectedDatamodelName"
          :tooltipEnabled="true"
          class="vis-ellipsis-fieldsMenu"
        >
          <template v-slot:tooltipContent>{{
            selectedDatamodelName
          }}</template>
        </Typography>
      </div>
      <InputSearch
        v-if="Object.keys(selectedDataModel).length"
        customComponentClass="vis-dataset-search-input"
        @keyupSearchData="searchField = $event"
      />
      <!-- ROW AND VIS-TOOLS-TAB -->
      <el-collapse v-model="activeCollapseItem">
        <el-collapse-item
          v-for="dataSet in selectedDataModel.datasets"
          :key="dataSet.datasetId"
          :title="shortCollapseItemTitle(dataSet.name)"
          :name="dataSet.name"
          class="vis-collapse-item"
          :class="{
            'vis-white-space-nowrap': checkDatasetNameLength(dataSet.name),
          }"
          v-show="getDatasetFieldsByDatasetId(dataSet.datasetId).length"
        >
          <Typography
            variant="h5"
            text="..."
            :showText="checkDatasetNameLength(dataSet.name)"
            :tooltipEnabled="checkDatasetNameLength(dataSet.name)"
            class="vis-collapse-item-more-text"
          >
            <template v-slot:tooltipContent>
              <span>{{ dataSet.name }}</span>
            </template>
          </Typography>
          <div class="vis-tools-content vis-mb--1">
            <draggable
              class="list-group"
              :list="getDatasetFieldsByDatasetId(dataSet.datasetId)"
              :group="{ name: 'people', pull: 'clone', put: false }"
              :sort="false"
            >
              <ul
                class="list-group-item"
                v-for="(element, index) in getDatasetFieldsByDatasetId(
                  dataSet.datasetId
                )"
                :key="element.fieldId || element.datasetId + index"
              >
                <li
                  draggable
                  @dragend="$emit('passiveFieldBox', false)"
                  @dragstart="
                    dragAndDropField(dataSet, element),
                      $emit('activeFieldBox', true)
                  "
                  class="vis-flex--spacebeetwen-Ycenter vis-cursor-pointer"
                  :class="[
                    {
                      selectedFieldActiveClass:
                        checkSelectedFieldActive(element),
                    },
                  ]"
                  @click.ctrl.stop="
                    $emit('addSelectedFieldsWithKeyCtrl', element)
                  "
                  @click.shift.stop="
                    addSelectedFieldsWithKeyShift(
                      getDatasetFieldsByDatasetId(dataSet.datasetId),
                      element
                    )
                  "
                >
                  <el-tooltip
                    class="item"
                    placement="right-start"
                    popper-class="vis-bg-neon-blue"
                    :open-delay="2000"
                  >
                    <div
                      slot="content"
                      class="vis-mx--05 vis-display-flex vis-flex-direction--column"
                    >
                      <span class="vis-color-quartz vis-text--xsmall">{{
                        $t("generalPages.alias")
                      }}</span>
                      <span class="vis-color-white">{{ element.alias }}</span>
                      <span
                        class="vis-color-quartz vis-text--xsmall vis-mt--05"
                        >{{ $t("generalPages.name") }}</span
                      >
                      <span class="vis-color-white">{{
                        `${dataSet.name}.${element.name}`
                      }}</span>
                    </div>
                    <div class="vis-flex--Ycenter vis-dataModel-list-icons">
                      <i :class="datasetIcon(element)" aria-hidden="true"></i>
                      <span class="vis-field-item-span">{{
                        element.alias || element.name
                      }}</span>
                    </div>
                    <i
                      class="vis-icon icon-dots vis-dataset-dots"
                      aria-hidden="true"
                    ></i>
                  </el-tooltip>
                </li>
              </ul>
            </draggable>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-if="!disableArea" class="vis-mask"></div>
  </div>
</template>
<script>
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
import draggable from "vuedraggable";
import cloneDeep from "clone-deep";
import { CustomIcon } from "../../assets/js/custom-icons";
import { checkIfDatasetFieldSame } from "../../commons/dashboardAndPanel";
import { filterType } from "../../commons/filterComponents";
import InputSearch from "../helper/Form/InputSearch.vue";

export default {
  components: {
    draggable,
    InputSearch,
  },
  props: {
    selectedDataModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedPanel: {
      type: Object,
    },
    datasetFields: {
      type: Object,
    },
    datasetFilterParams: {
      type: Array,
    },
    isChartTypeFilter: {
      type: Boolean,
    },
    disableArea: {
      type: Boolean,
      default: true,
    },

    selectedFieldsWithKeyCtrlShift: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activeCollapseItem: [],
      searchField: null,
    };
  },
  watch: {
    searchField: {
      handler(search) {
        if (search) {
          let datasetHasTheSearchedFile = [];
          this.selectedDataModel?.datasets?.forEach((dataset) => {
            const searchedField = this.getDatasetFieldsByDatasetId(
              dataset.datasetId
            );
            if (searchedField.length)
              datasetHasTheSearchedFile.push(dataset?.name);
          });
          this.activeCollapseItem = datasetHasTheSearchedFile;
        } else {
          this.activeCollapseItem = [
            this.selectedDataModel?.datasets?.[0]?.name,
          ];
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.activeCollapseItem.push(this.selectedDataModel?.datasets?.[0]?.name);
  },
  computed: {
    datasetName() {
      return this.selectedDataModel?.properties?.dataSet?.name;
    },
    selectedDatamodelName() {
      return this.selectedDataModel?.name;
    }
  },
  methods: {
    addSelectedFieldsWithKeyShift(fieldList, value) {
      this.$emit("addSelectedFieldsWithKeyShift", { fieldList, value });
    },
    checkDatasetNameLength(datasetName) {
      return datasetName.length > 20;
    },
    isActiveFields(field) {
      return checkIfDatasetFieldSame(this.selectedPanel, field);
    },
    dragAndDropField(value, field) {
      const datasetId = value.datasetId.toString();

      if (field.filterType != filterType.CUSTOM) {
        let x = cloneDeep(this.selectedPanel);
        x.properties.dataSet.id = datasetId;
        x.properties.dataSet.name = value.name;
        x.properties.dataSet.filterProperty = this.getDatasetFilterProperty(
          datasetId,
          field
        );

        this.updateSelectedPanel(x);
      }
    },

    getDatasetFilterProperty(datasetId, field) {
      if (!this.datasetFilterParams?.length) {
        return { filterType: filterType.SECURITY };
      } else {
        let selectedFilterParam = this.datasetFilterParams.find(
          (x) => x.datasetId == datasetId
        );

        return {
          filterType: selectedFilterParam
            ? selectedFilterParam.filterType
            : field.filterType,
          mandatory: selectedFilterParam
            ? selectedFilterParam.mandatory
            : field.mandatory,
        };
      }
    },
    getDatasetFilterParamsByDatasetId(datasetId) {
      return this.datasetFilterParams
        ?.filter(
          (x) =>
            x?.datasetId == datasetId && x.filterType != filterType.SECURITY
        )
        .map((fp) => {
          return {
            ...fp,
            alias: fp.name,
          };
        });
    },
    getSearchFieldByFieldAlias(fieldAlias) {
      return fieldAlias.toLowerCase().includes(this.searchField.toLowerCase());
    },
    getDatasetFieldsByDatasetId(datasetId) {
      let visibleDatasetFields = this.datasetFields?.[datasetId]?.filter(
        (x) => !x.isHidden && x?.isAuthorize
      );
      const datasetFieldsAndDatasetFilterParams = [
        ...visibleDatasetFields,
        ...this.getDatasetFilterParamsByDatasetId(datasetId),
      ];
      //"isChartTypeFilter" chart tipi filtersa filterParamları getirmek için. Filter olmayan chartlarda datasetFilterParamları görmek istemiyoruz.
      if (this.searchField) {
        return this.isChartTypeFilter
          ? datasetFieldsAndDatasetFilterParams.filter((field) => {
              return this.getSearchFieldByFieldAlias(field.alias);
            })
          : visibleDatasetFields.filter((field) => {
              return this.getSearchFieldByFieldAlias(field.alias);
            });
      } else {
        return this.isChartTypeFilter
          ? datasetFieldsAndDatasetFilterParams
          : visibleDatasetFields.filter((x) => !x.isHidden);
      }
    },
    datasetIcon(element) {
      const iconByTypes = {
        [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE]: CustomIcon.Abc,
        [DatamodelContextDefaults.USAGE_TYPES.DATE]: CustomIcon.Clock_Time,
        [DatamodelContextDefaults.USAGE_TYPES.MEASURE]: CustomIcon.Measure,
        [filterType.CUSTOM]: CustomIcon.FilterAlt,
      };
      return iconByTypes[element.usageType || element.filterType];
    },
    shortCollapseItemTitle(name) {
      return name.substring(0, 23);
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    checkSelectedFieldActive(value) {
      return this.selectedFieldsWithKeyCtrlShift.find(
        (s) => s.fieldId === value.fieldId
      );
    },
  },
};
</script>
<style scoped>
.vis-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  opacity: 0.3;
  cursor: not-allowed;
}
.selectedFieldActiveClass {
  background-color: var(--primary) !important;
  border: none !important;
  color: #fff;
  padding: 2px 5px;
  border-radius: 2px;
}
.selectedFieldActiveClass i {
  color: #fff !important;
}
.vis-field-item-span {
  max-width: 11rem; /* Gerekirse */
}
</style>
