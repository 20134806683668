import { DefaultValue } from "../commons/dashboardProperties";

export const TEXT_FILTER_DEFAULT_CONFIGURATIONS = {
  TEXT_FILTER_MANDATORY_SELECTION: false,
  TEXT_FILTER_SELECTION_ACTION: false,
  TEXT_FILTER_DEFAULT_SELECTION: DefaultValue.NONE,
  TEXT_FILTER_UNIQUE_NAME: "",
  TEXT_FILTER_TAB_INDEX: 0,
  TEXT_FILTER_DEFAULT_VALUE_ACTIVE: false,
  TEXT_FILTER_APPLY_TO_TABS: []
};

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
  return typeof value != "undefined" ? value : computedValue;
};