import { getConcatArrayWithPipe } from "../chartOptionsHelper"
import { seriesTypeValue } from "../chartSeriesType"

const chartSeriesType =
{
    [seriesTypeValue.HEATMAP]: { serviceFn: (params) => getChartItemNameByCommonType(params) },
    [seriesTypeValue.SCATTER]: { serviceFn: (params) => getChartItemNameByCommonType(params) },
    [seriesTypeValue.TREEMAP]: { serviceFn: (params) => getChartItemNameByTreeMap(params) },
}

export const getSelectedChartItemNameBySeriesType = (params) => {
    if (chartSeriesType[params.seriesType]) {
        return chartSeriesType[params.seriesType].serviceFn(params)
    }
    else return params.name;

}

const getChartItemNameByCommonType = (params) => {
    //number değerler metric olanlar. metricler için filtreleme yapılmıyor. Filtrelenecek değerler her zaman string geliyor.
    const filteredList = params.value.filter(x => typeof x != "number")
    return getConcatArrayWithPipe(filteredList);
}

const getChartItemNameByTreeMap = (params) => {
    const newList = [];
    const filteredList = params.treeAncestors.filter(x => x.dataIndex);

    filteredList.forEach(element => {
        newList.push(element.name)
    });
    return getConcatArrayWithPipe(newList);

}

export const chartContextMenuItemKey = {
    EXCLUDE: "EXCLUDE",
    INCLUDE: "INCLUDE",
    DRILL_THROUGH: "DRILL_THROUGH",
    DRILL_DOWN: "DRILL_DOWN"
}