export const calculatedColumnFunctionTypes = {
  Oracle: {
    key: "Oracle",
    label: "Oracle",
  },
  Mssql: {
    key: "Mssql",
    label: "Mssql",
  },
  Clickhouse: {
    key: "Clickhouse",
    label: "Clickhouse",
  },
  Postgresql: {
    key: "Postgresql",
    label: "Postgresql",
  },
  SybaseAse: {
    key: "SYBASE_ASE",
    label: "Sybase ASE",
  },
  SybaseIq: {
    key: "SYBASE_IQ",
    label: "Sybase IQ",
  },
  Trino: {
    key: "TRINO",
    label: "Trino",
  },
  String: {
    key: "String",
    label: "generalPages.string",
  },
  Math: {
    key: "Math",
    label: "generalPages.math",
  },
  Date: {
    key: "Date",
    label: "generalPages.labelDate",
  },
  Conditions: {
    key: "Conditions",
    label: "generalPages.conditions",
  },
  Operators: {
    key: "Operators",
    label: "generalPages.operators",
  },
};

export const getCalculatedColumnFunctions = ({
  isOracleSelectable,
  isMssqlSelectable,
  isClickhouseSelectable,
  isPostgresqlSelectable,
  isSybaseAseSelectable,
  isSybaseIqSelectable,
  isTrinoSelectable,
}) => {
  return [
    isOracleSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.Oracle.key,
          collapseLabel: calculatedColumnFunctionTypes.Oracle.label,
          functions: [
            "ORACLE.AVG",
            "ORACLE.CASE",
            "ORACLE.COUNT",
            "ORACLE.MAX",
            "ORACLE.MIN",
            "ORACLE.SUM",
            "ORACLE.CAST",
            "ORACLE.TO_CHAR",
            "ORACLE.TO_CLOB",
            "ORACLE.TO_DATE",
            "ORACLE.TO_DSINTERVAL",
            "ORACLE.TO_LOB",
            "ORACLE.TO_MULTI_BYTE",
            "ORACLE.TO_NCHAR",
            "ORACLE.TO_NCLOB",
            "ORACLE.TO_NUMBER",
            "ORACLE.TO_SINGLE_BYTE",
            "ORACLE.TO_TIMESTAMP",
            "ORACLE.ADD_MONTHS",
            "ORACLE.CURRENT_DATE",
            "ORACLE.CURRENT_TIMESTAMP",
            "ORACLE.DBTIMEZONE",
            "ORACLE.LAST_DAY",
            "ORACLE.LOCALTIMESTAMP",
            "ORACLE.MONTHS_BETWEEN",
            "ORACLE.NEW_TIME",
            "ORACLE.NEXT_DAY",
            "ORACLE.SESSIONTIMEZONE",
            "ORACLE.SYS_EXTRACT_UTC",
            "ORACLE.SYSDATE",
            "ORACLE.SYSTIMESTAMP",
            "ORACLE.TZ_OFFSET",
            "ORACLE.ABS",
            "ORACLE.ACOS",
            "ORACLE.ASIN",
            "ORACLE.ATAN2",
            "ORACLE.BITAND",
            "ORACLE.CEIL",
            "ORACLE.CORR",
            "ORACLE.COS",
            "ORACLE.POWER",
            "ORACLE.RANK",
            "ORACLE.FLOOR",
            "ORACLE.GREATEST",
            "ORACLE.LEAST",
            "ORACLE.LN",
            "ORACLE.LOG",
            "ORACLE.MEDIAN",
            "ORACLE.NVL",
            "ORACLE.NVL2",
            "ORACLE.NULLIF",
            "ORACLE.REGEXP_COUNT",
            "ORACLE.REGEXP_INSTR",
            "ORACLE.REGEXP_REPLACE",
            "ORACLE.SUBSTR",
            "ORACLE.TRANSLATE",
            "ORACLE.CONCAT",
            "ORACLE.CONVERT",
          ],
        }
      : null,
    isMssqlSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.Mssql.key,
          collapseLabel: calculatedColumnFunctionTypes.Mssql.label,
          functions: [
            "MSSQL.IIF",
            "MSSQL.CASE",
            "MSSQL.CAST",
            "MSSQL.CONVERT",
            "MSSQL.AVG",
            "MSSQL.BINARY_CHECKSUM",
            "MSSQL.CHECKSUM",
            "MSSQL.CHECKSUM_AVG",
            "MSSQL.COUNT",
            "MSSQL.COUNT_BIG",
            "MSSQL.GROUPING",
            "MSSQL.MAX",
            "MSSQL.MIN",
            "MSSQL.SUM",
            "MSSQL.STDEV",
            "MSSQL.STDEVP",
            "MSSQL.ABS",
            "MSSQL.ACOS",
            "MSSQL.ASIN",
            "MSSQL.ATAN",
            "MSSQL.ATN2",
            "MSSQL.CEILING",
            "MSSQL.COS",
            "MSSQL.COT",
            "MSSQL.DEGREES",
            "MSSQL.EXP",
            "MSSQL.FLOOR",
            "MSSQL.LOG",
            "MSSQL.LOG10",
            "MSSQL.PI",
            "MSSQL.POWER",
            "MSSQL.RADIANS",
            "MSSQL.RAND",
            "MSSQL.ROUND",
            "MSSQL.SIGN",
            "MSSQL.SIN",
            "MSSQL.DATEADD",
            "MSSQL.DATEDIFF",
            "MSSQL.DATENAME",
            "MSSQL.DATEPART",
            "MSSQL.DAY",
            "MSSQL.GETDATE",
            "MSSQL.GETUTCDATE",
            "MSSQL.MONTH",
            "MSSQL.YEAR",
            "MSSQL.CHAR",
            "MSSQL.CHARINDEX",
            "MSSQL.DIFFERENCE",
            "MSSQL.LEFT",
            "MSSQL.LEN",
            "MSSQL.LOWER",
            "MSSQL.LTRIM",
            "MSSQL.NCHAR",
            "MSSQL.PATINDEX",
            "MSSQL.REPLACE",
            "MSSQL.QUOTENAME",
            "MSSQL.REPLICATE",
            "MSSQL.REVERSE",
            "MSSQL.RIGHT",
            "MSSQL.RTRIM",
            "MSSQL.SOUNDEX",
            "MSSQL.SPACE",
            "MSSQL.STR",
            "MSSQL.STUFF",
            "MSSQL.SUBSTRING",
            "MSSQL.UNICODE",
            "MSSQL.UPPER",
            "MSSQL.ISNULL",
            "MSSQL.ISNUMERIC",
            "MSSQL.NULLIF",
          ],
        }
      : null,
    isClickhouseSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.Clickhouse.key,
          collapseLabel: calculatedColumnFunctionTypes.Clickhouse.label,
          functions: [
            "CLICKHOUSE.count",
            "CLICKHOUSE.min",
            "CLICKHOUSE.max",
            "CLICKHOUSE.sum",
            "CLICKHOUSE.avg",
            "CLICKHOUSE.modulo",
            "CLICKHOUSE.abs",
            "CLICKHOUSE.max2",
            "CLICKHOUSE.min2",
            "CLICKHOUSE.toInt(8|16|32|64|128|256)",
            "CLICKHOUSE.toInt(8|16|32|64|128|256)OrZero",
            "CLICKHOUSE.toInt(8|16|32|64|128|256)OrNull",
            "CLICKHOUSE.toUInt(8|16|32|64|256)",
            "CLICKHOUSE.toUInt(8|16|32|64|256)OrZero",
            "CLICKHOUSE.toUInt(8|16|32|64|256)OrNull",
            "CLICKHOUSE.toFloat(32|64)",
            "CLICKHOUSE.toFloat(32|64)OrZero",
            "CLICKHOUSE.toFloat(32|64)OrNull",
            "CLICKHOUSE.toDate",
            "CLICKHOUSE.toDateOrZero",
            "CLICKHOUSE.toDateOrNull",
            "CLICKHOUSE.toDateTime",
            "CLICKHOUSE.toDateTimeOrZero",
            "CLICKHOUSE.toDateTimeOrNull",
            "CLICKHOUSE.toDate32",
            "CLICKHOUSE.toDate32OrZero",
            "CLICKHOUSE.toDate32OrNull",
            "CLICKHOUSE.toDateTime64",
            "CLICKHOUSE.toDecimal(32|64|128|256)",
            "CLICKHOUSE.toDecimal(32|64|128|256)OrNull",
            "CLICKHOUSE.toDecimal(32|64|128|256)OrZero",
            "CLICKHOUSE.toString",
            "CLICKHOUSE.CAST",
            "CLICKHOUSE.toInterval(Year|Quarter|Month|Week|Day|Hour|Minute|Second)",
            "CLICKHOUSE.timeZone",
            "CLICKHOUSE.toTimeZone",
            "CLICKHOUSE.timeZoneOf",
            "CLICKHOUSE.timeZoneOffset",
            "CLICKHOUSE.toYear",
            "CLICKHOUSE.toQuarter",
            "CLICKHOUSE.toMonth",
            "CLICKHOUSE.toDayOfYear",
            "CLICKHOUSE.toDayOfMonth",
            "CLICKHOUSE.toDayOfWeek",
            "CLICKHOUSE.toHour",
            "CLICKHOUSE.toMinute",
            "CLICKHOUSE.toSecond",
            "CLICKHOUSE.toUnixTimestamp",
            "CLICKHOUSE.toStartOfYear",
            "CLICKHOUSE.toStartOfISOYear",
            "CLICKHOUSE.toStartOfQuarter",
            "CLICKHOUSE.toStartOfMonth",
            "CLICKHOUSE.toLastDayOfMonth",
            "CLICKHOUSE.toMonday",
            "CLICKHOUSE.toStartOfWeek",
            "CLICKHOUSE.toStartOfDay",
            "CLICKHOUSE.toStartOfHour",
            "CLICKHOUSE.toStartOfMinute",
            "CLICKHOUSE.toStartOfSecond",
            "CLICKHOUSE.toStartOfFiveMinutes",
            "CLICKHOUSE.toStartOfTenMinutes",
            "CLICKHOUSE.toStartOfFifteenMinutes",
            "CLICKHOUSE.toStartOfInterval",
            "CLICKHOUSE.toTime",
            "CLICKHOUSE.toRelativeYearNum",
            "CLICKHOUSE.toRelativeQuarterNum",
            "CLICKHOUSE.toRelativeMonthNum",
            "CLICKHOUSE.toRelativeWeekNum",
            "CLICKHOUSE.toRelativeDayNum",
            "CLICKHOUSE.toRelativeHourNum",
            "CLICKHOUSE.toRelativeMinuteNum",
            "CLICKHOUSE.toRelativeSecondNum",
            "CLICKHOUSE.toISOYear",
            "CLICKHOUSE.toISOWeek",
            "CLICKHOUSE.toWeek",
            "CLICKHOUSE.date_diff",
            "CLICKHOUSE.date_trunc",
            "CLICKHOUSE.date_add",
            "CLICKHOUSE.date_sub",
            "CLICKHOUSE.timestamp_add",
            "CLICKHOUSE.timestamp_sub",
            "CLICKHOUSE.now",
            "CLICKHOUSE.now64",
            "CLICKHOUSE.today",
            "CLICKHOUSE.yesterday",
            "CLICKHOUSE.timeSlot",
            "CLICKHOUSE.toYYYYMM",
            "CLICKHOUSE.toYYYYMMDD",
            "CLICKHOUSE.toYYYYMMDDhhmmss",
            "CLICKHOUSE.formatDateTime",
            "CLICKHOUSE.dateName",
            "CLICKHOUSE.monthName",
            "CLICKHOUSE.length",
            "CLICKHOUSE.lengthUTF8",
            "CLICKHOUSE.char_length",
            "CLICKHOUSE.character_length",
            "CLICKHOUSE.leftPad",
            "CLICKHOUSE.leftPadUTF8",
            "CLICKHOUSE.rightPad",
            "CLICKHOUSE.rightPadUTF8",
            "CLICKHOUSE.lower",
            "CLICKHOUSE.upper",
            "CLICKHOUSE.lowerUTF8",
            "CLICKHOUSE.upperUTF8",
            "CLICKHOUSE.isValidUTF8",
            "CLICKHOUSE.toValidUTF8",
            "CLICKHOUSE.repeat",
            "CLICKHOUSE.reverse",
            "CLICKHOUSE.reverseUTF8",
            "CLICKHOUSE.format",
            "CLICKHOUSE.substring",
            "CLICKHOUSE.substringUTF8",
            "CLICKHOUSE.appendTrailingCharIfAbsent",
            "CLICKHOUSE.endsWith",
            "CLICKHOUSE.startsWith",
            "CLICKHOUSE.trim",
            "CLICKHOUSE.trimLeft",
            "CLICKHOUSE.trimRight",
            "CLICKHOUSE.trimBoth",
            "CLICKHOUSE.concatWithSeparator",
            "CLICKHOUSE.regexpExtract",
            "CLICKHOUSE.if",
            "CLICKHOUSE.multiIf",
            "CLICKHOUSE.e",
            "CLICKHOUSE.pi",
            "CLICKHOUSE.exp",
            "CLICKHOUSE.log",
            "CLICKHOUSE.exp2",
            "CLICKHOUSE.log2",
            "CLICKHOUSE.exp10",
            "CLICKHOUSE.log10",
            "CLICKHOUSE.sqrt",
            "CLICKHOUSE.sin",
            "CLICKHOUSE.cos",
            "CLICKHOUSE.tan",
            "CLICKHOUSE.asin",
            "CLICKHOUSE.acos",
            "CLICKHOUSE.atan",
            "CLICKHOUSE.pow",
            "CLICKHOUSE.degrees",
            "CLICKHOUSE.radians",
            "CLICKHOUSE.factorial",
            "CLICKHOUSE.floor",
            "CLICKHOUSE.ceil",
            "CLICKHOUSE.trunc",
            "CLICKHOUSE.round",
            "CLICKHOUSE.roundDown",
            "CLICKHOUSE.isNull",
            "CLICKHOUSE.isNotNull",
            "CLICKHOUSE.coalesce",
            "CLICKHOUSE.ifNull",
            "CLICKHOUSE.nullIf",
            "CLICKHOUSE.toNullable",
          ],
        }
      : null,
    isPostgresqlSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.Postgresql.key,
          collapseLabel: calculatedColumnFunctionTypes.Postgresql.label,
          functions: [
            "POSTGRESQL.AVG",
            "POSTGRESQL.COUNT",
            "POSTGRESQL.MAX",
            "POSTGRESQL.MIN",
            "POSTGRESQL.SUM",
            "POSTGRESQL.NULLIF",
            "POSTGRESQL.COALESCE",
            "POSTGRESQL.UPPER",
            "POSTGRESQL.SUBSTRING",
            "POSTGRESQL.RTRIM",
            "POSTGRESQL.RIGHT",
            "POSTGRESQL.REPLACE",
            "POSTGRESQL.LTRIM",
            "POSTGRESQL.LOWER",
            "POSTGRESQL.LENGTH",
            "POSTGRESQL.LEFT",
            "POSTGRESQL.CAST",
            "POSTGRESQL.CONCAT",
            "POSTGRESQL.TO_CHAR",
            "POSTGRESQL.CASE",
            "POSTGRESQL.TO_NUMBER",
            "POSTGRESQL.TO_DATE",
            "POSTGRESQL.TO_TIMESTAMP",
            "POSTGRESQL.EXTRACT",
            "POSTGRESQL.DATE_PART",
            "POSTGRESQL.NOW",
            "POSTGRESQL.DATE_SUBTRACT",
            "POSTGRESQL.CURRENT_DATE",
            "POSTGRESQL.CURRENT_TIME",
            "POSTGRESQL.CURRENT_TIMESTAMP",
            "POSTGRESQL.LOCALTIMESTAMP",
            "POSTGRESQL.ROUND",
            "POSTGRESQL.POWER",
            "POSTGRESQL.LOG",
            "POSTGRESQL.FLOOR",
            "POSTGRESQL.CEILING",
            "POSTGRESQL.ABS",
            "POSTGRESQL.MOD",
            "POSTGRESQL.SQRT",
            "POSTGRESQL.SIN",
            "POSTGRESQL.COS",
            "POSTGRESQL.TAN",
            "POSTGRESQL.COT",
          ],
        }
      : null,
    isSybaseAseSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.SybaseAse.key,
          collapseLabel: calculatedColumnFunctionTypes.SybaseAse.label,
          functions: [
            "SYBASE_ASE.AVG",
            "SYBASE_ASE.SUM",
            "SYBASE_ASE.COUNT",
            "SYBASE_ASE.MIN",
            "SYBASE_ASE.MAX",
            "SYBASE_ASE.ABS",
            "SYBASE_ASE.SQUARE",
            "SYBASE_ASE.PI",
            "SYBASE_ASE.STDEV",
            "SYBASE_ASE.ASCII",
            "SYBASE_ASE.CHAR",
            "SYBASE_ASE.CHARLENGTH",
            "SYBASE_ASE.CHARINDEX",
            "SYBASE_ASE.LEFT",
            "SYBASE_ASE.LEN",
            "SYBASE_ASE.LOWER",
            "SYBASE_ASE.UPPER",
            "SYBASE_ASE.LTRIM",
            "SYBASE_ASE.PATINDEX",
            "SYBASE_ASE.RIGHT",
            "SYBASE_ASE.RTRIM",
            "SYBASE_ASE.SUBSTRING",
            "SYBASE_ASE.REVERSE",
            "SYBASE_ASE.ISNULL",
            "SYBASE_ASE.CURRENT_DATE",
            "SYBASE_ASE.CURRENT_TIME",
            "SYBASE_ASE.YEAR",
            "SYBASE_ASE.DATENAME",
            "SYBASE_ASE.DATEPART",
            "SYBASE_ASE.DATEDIFF",
            "SYBASE_ASE.DATEADD",
            "SYBASE_ASE.GETDATE",
            "SYBASE_ASE.GETUTCDATE",
            "SYBASE_ASE.CASE",
            "SYBASE_ASE.CAST",
            "SYBASE_ASE.CONVERT",
            "SYBASE_ASE.ISDATE",
          ],
        }
      : null,
    isSybaseIqSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.SybaseIq.key,
          collapseLabel: calculatedColumnFunctionTypes.SybaseIq.label,
          functions: [
            "SYBASE_IQ.AVG",
            "SYBASE_IQ.SUM",
            "SYBASE_IQ.COUNT",
            "SYBASE_IQ.MIN",
            "SYBASE_IQ.MAX",
            "SYBASE_IQ.ABS",
            "SYBASE_IQ.SQUARE",
            "SYBASE_IQ.PI",
            "SYBASE_IQ.STDEV",
            "SYBASE_IQ.ASCII",
            "SYBASE_IQ.CHAR",
            "SYBASE_IQ.CHARLENGTH",
            "SYBASE_IQ.CHARINDEX",
            "SYBASE_IQ.LEFT",
            "SYBASE_IQ.LEN",
            "SYBASE_IQ.LOWER",
            "SYBASE_IQ.UPPER",
            "SYBASE_IQ.LTRIM",
            "SYBASE_IQ.PATINDEX",
            "SYBASE_IQ.RIGHT",
            "SYBASE_IQ.RTRIM",
            "SYBASE_IQ.SUBSTRING",
            "SYBASE_IQ.REVERSE",
            "SYBASE_IQ.ISNULL",
            "SYBASE_IQ.CURRENT_DATE",
            "SYBASE_IQ.CURRENT_TIME",
            "SYBASE_IQ.YEAR",
            "SYBASE_IQ.DATENAME",
            "SYBASE_IQ.DATEPART",
            "SYBASE_IQ.DATEDIFF",
            "SYBASE_IQ.DATEADD",
            "SYBASE_IQ.GETDATE",
            "SYBASE_IQ.GETUTCDATE",
            "SYBASE_IQ.CASE",
            "SYBASE_IQ.CAST",
            "SYBASE_IQ.CONVERT",
            "SYBASE_IQ.ISDATE",
          ],
        }
      : null,
    isTrinoSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.Trino.key,
          collapseLabel: calculatedColumnFunctionTypes.Trino.label,
          functions: [
            "TRINO.any_value",
            "TRINO.approx_distinct",
            "TRINO.approx_percentile",
            "TRINO.arbitrary",
            "TRINO.array_agg",
            "TRINO.avg",
            "TRINO.abs",
            "TRINO.approx_most_frequent",
            "TRINO.approx_set",
            "TRINO.bitwise_and_agg",
            "TRINO.bitwise_or_agg",
            "TRINO.bool_and",
            "TRINO.bool_or",
            "TRINO.case",
            "TRINO.cast",
            "TRINO.checksum",
            "TRINO.checksum_avg",
            "TRINO.char",
            "TRINO.count",
            "TRINO.count_if",
            "TRINO.ceiling",
            "TRINO.count_big",
            "TRINO.current_date",
            "TRINO.current_time",
            "TRINO.date_add",
            "TRINO.date_diff",
            "TRINO.date_parse",
            "TRINO.date_trunc",
            "TRINO.every",
            "TRINO.exp",
            "TRINO.format",
            "TRINO.floor",
            "TRINO.geometric_mean",
            "TRINO.listagg",
            "TRINO.lower",
            "TRINO.log",
            "TRINO.ltrim",
            "TRINO.length",
            "TRINO.max",
            "TRINO.max_by",
            "TRINO.min_by",
            "TRINO.merge",
            "TRINO.min",
            "TRINO.mod",
            "TRINO.now",
            "TRINO.numeric_histogram",
            "TRINO.nullif",
            "TRINO.qdigest_agg",
            "TRINO.reduce_agg",
            "TRINO.replace",
            "TRINO.round",
            "TRINO.rtrim",
            "TRINO.std",
            "TRINO.stddev",
            "TRINO.stddev_samp",
            "TRINO.stdev",
            "TRINO.sum",
            "TRINO.sqrt",
            "TRINO.stddev_pop",
            "TRINO.substr",
            "TRINO.tdigest_agg",
            "TRINO.upper",
            "TRINO.var_samp",
            "TRINO.variance",
            "TRINO.var",
            "TRINO.var_pop",
          ],
        }
      : null,
    !isOracleSelectable &&
    !isMssqlSelectable &&
    !isClickhouseSelectable &&
    !isPostgresqlSelectable &&
    !isSybaseAseSelectable &&
    !isSybaseIqSelectable &&
    !isTrinoSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.String.key,
          collapseLabel: calculatedColumnFunctionTypes.String.label,
          functions: [
            "contains",
            "endswith",
            "startswith",
            "in",
            "equals",
            "length",
            "ltrim",
            "rtrim",
            "trim",
            "substring",
            "nvl",
            "replace",
            "upper",
            "lower",
            "position",
            "right",
            "left",
            "concat",
          ],
        }
      : null,
    !isOracleSelectable &&
    !isMssqlSelectable &&
    !isClickhouseSelectable &&
    !isPostgresqlSelectable &&
    !isSybaseAseSelectable &&
    !isSybaseIqSelectable &&
    !isTrinoSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.Date.key,
          collapseLabel: calculatedColumnFunctionTypes.Date.label,
          functions: [
            "current_date",
            "current_datetime",
            "_add_days",
            "_add_months",
            "_add_years",
            "_age",
            "_day",
            "_month",
            "_year",
            "_hour",
            "_minute",
            "_day_of_week",
            "_day_of_year",
            "_day_name",
            "make_time_stamp",
          ],
        }
      : null,
    !isOracleSelectable &&
    !isMssqlSelectable &&
    !isClickhouseSelectable &&
    !isPostgresqlSelectable &&
    !isSybaseAseSelectable &&
    !isSybaseIqSelectable &&
    !isTrinoSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.Conditions.key,
          collapseLabel: calculatedColumnFunctionTypes.Conditions.label,
          functions: ["case", "when", "if", "else"],
        }
      : null,
    {
      collapseName: calculatedColumnFunctionTypes.Operators.key,
      collapseLabel: calculatedColumnFunctionTypes.Operators.label,
      functions: ["+", "-", "*", "/", "<", "<=", "<>", ">", ">="],
    },
    !isOracleSelectable &&
    !isMssqlSelectable &&
    !isClickhouseSelectable &&
    !isPostgresqlSelectable &&
    !isSybaseAseSelectable &&
    !isSybaseIqSelectable &&
    !isTrinoSelectable
      ? {
          collapseName: calculatedColumnFunctionTypes.Math.key,
          collapseLabel: calculatedColumnFunctionTypes.Math.label,
          functions: ["round", "ceil", "floor", "abs", "sqrt", "power", "log"],
        }
      : null,
  ].filter((x) => x);
};
