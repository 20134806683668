<template>
  <div class="vis-chatGpt-card">
    <div class="vis-chatGpt-card-title vis-flex--spacebeetwen-Ycenter">
      <el-tabs v-model="activeTabName">
        <el-tab-pane
          :label="$t('generalPages.chat')"
          :name="chatGptTabs.INSIGHTS"
        ></el-tab-pane>
        <el-tab-pane
          :label="$t('generalPages.queryExplanation')"
          :name="chatGptTabs.SUMMARY"
        ></el-tab-pane>
      </el-tabs>
      <i
        aria-hidden="true"
        :class="customIcon.Close"
        class="vis-cursor-pointer"
        @click="$emit('toggleChatGptByChartId', chatGptSelectedChartId)"
      ></i>
    </div>
    <div v-loading="isChatGptDataLoading" class="vis-chatGpt-card-content">
      <div v-if="isActiveTabNameInsights">
        <VueChatBot
          :is-open="true"
          :messages="messages"
          :options="botOptions"
          @msg-send="messageSendHandler"
        />
      </div>
      <div
        class="vis-summary-text-box vis-scroll-thin"
        v-if="!isActiveTabNameInsights"
      >
        <i
          aria-hidden="true"
          class="vis-color-primary-lighteen-1 vis-font-bold"
          :class="customIcon.TipsAndUpdated"
        ></i>
        <span class="vis-font-size-0875 vis-font-medium">
          {{ summaryText }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CustomIcon } from "../../assets/js/custom-icons";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { VueChatBot } from "vue-chat-bot";
import { chatGptTabs } from "../../util/chatGptHelper";

export default {
  components: {
    VueChatBot,
  },
  props: {
    chatGptInsightData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chatGptSummaryData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chatGptSelectedChartId: {
      type: String,
    },
    messages: {
      type: Array,
    },
  },
  data() {
    return {
      activeTabName: chatGptTabs.INSIGHTS,
      customIcon: CustomIcon,
      chatGptTabs: chatGptTabs,
      botOptions: {
        botTitle: this.$t("generalPages.assistant"),
        colorScheme: "#3d62f8",
        animation: true,
        inputPlaceholder: this.$t("generalPages.message"),
        botAvatarImg: this.getLogoImageUrl(),
      },
    };
  },
  async mounted() {
    //TODO daha sonra aktif edilecek
    // await this.$emit("getChatGptInsights");
    await this.$emit("getChatGptSummary");
  },
  computed: {
    ...mapGetters({
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    isChatGptDataLoading() {
      return this.loading[LoadingComponent.ChatGpt];
    },
    //insightTextList fonksiyonu daha sonradan kullanılacak.
    insightTextList() {
      return this.chatGptInsightData?.choices?.[0]?.text
        .split("\n")
        .filter((x) => x);
    },
    summaryText() {
      return this.chatGptSummaryData?.answer;
    },
    isActiveTabNameInsights() {
      return this.activeTabName === chatGptTabs.INSIGHTS;
    },
  },
  methods: {
    getLogoImageUrl() {
      let images = require.context("../../assets/images/", false, /\.svg$/);
      return images("./" + "topbar-logo" + ".svg");
    },
    messageSendHandler(value) {
      this.$emit("addMessageToChatBox", {
        agent: "user",
        type: "text",
        text: value.text,
      });

      this.$emit("getChatGptInsightsWithChatBot", value.text);
    },
  },
};
</script>
<style scoped>
::v-deep .qkb-board-content {
  overflow: hidden auto;
}
::v-deep .qkb-bot-ui {
  position: relative;
  bottom: 0;
  right: 0;
}
::v-deep .qkb-board {
  position: relative !important;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  border-radius: 0;
}
::v-deep .qkb-bot-bubble {
  display: none;
}
::v-deep .qkb-board-header {
  display: none;
}
.vis-chatGpt-card {
  position: fixed;
  top: 96px;
  right: 0;
  width: 30%;
  height: 100%;
  padding: 4px 0 20px 0;
  transition: all 0.3s ease;
  transform: none !important;
  z-index: 2001;
  background-color: #f8f9ff;
}
.vis-chatGpt-card-title {
  position: relative;
  width: 100%;
  padding: 0 16px;
}
::v-deep .el-tabs__nav-wrap::after {
  width: 0 !important;
}
::v-deep .el-tabs__active-bar {
  background-color: var(--primary);
}
::v-deep .el-tabs__item,
::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: var(--secondary-dark-1);
}

.vis-chatGpt-card-content {
  position: relative;
  width: 100%;
  padding: 0 18px;
}
.vis-chatGpt-card-content ul,
.vis-summary-text-box {
  height: 600px;
}
.vis-chatGpt-card-content ul li span {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--secondary-dark-1);
}
</style>
