<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold"> {{ $t('ingest.Data Flow Properties') }} </span>
    </div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in allCollapses"
        :key="collapse.key"
        :title="$t(`${collapse.label}`)"
        :name="collapse.key"
        :disabled="collapse.disabled"
      >
        <div v-if="collapseTypes.general === collapse.key" :class='isEditMode ? "vis-area-disabled": ""'>
          <div class="property-wrapper">
            <label>{{ $t('ingest.Name') }}</label>
            <input
              class="vis-input"
              :value="properties.name"
              @keyup="
                (e) =>
                  updateField('name', e.target.value)
              "
            />
          </div>
          <div class="property-wrapper">
            <label>{{ $t('ingest.Description') }}</label>
            <textarea
              class="vis-textarea"
              :value="properties.description"
              rows="3"
              @keyup="
                (e) =>
                  updateField('description', e.target.value)
              "
            >
            </textarea>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";

export default {
  components: {},
  props: {
    properties: {
      type: Object,
      required: true,
    },
    connections: {
      type: Array,
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      collapseTypes: {
        general: "general",
      },
      activeCollapses: [],
    };
  },
  mounted() {
    this.activeCollapses.push(this.collapseTypes.general);
  },
  computed: {
    allCollapses() {
      return [
        {
          key: this.collapseTypes.general,
          label: "generalPages.general",
          isShow: true,
        },
      ];
    },
  },
  methods: {
    updateField(field, value) {
      const clonedProperties = this.cloneTempProperties();

      clonedProperties[field] = value;

      this.$emit("updateProperties", clonedProperties);
    },
    cloneTempProperties() {
      return cloneDeep(this.properties);
    },
  },
};
</script>

<style scoped>
.property-wrapper {
  margin-bottom: 10px;
}
::v-deep .el-collapse-item__wrap {
  margin-bottom: 20px;
}
</style>
