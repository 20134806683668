export const domain = "TEMP_STORAGE";

export const MUTATION = {
  SET_TEMP_STORAGE_BY_KEY: `${domain}/SET_TEMP_STORAGE_BY_KEY`,
  SET_TEMP_STORAGE_DATASET_FIELDS: `${domain}/SET_TEMP_STORAGE_DATASET_FIELDS`,
  RESET_TEMP_STORAGE_DATASET_FIELDS: `${domain}/RESET_TEMP_STORAGE_DATASET_FIELDS`,
  UPDATE_TEMP_STORAGE_DATASET_FIELDS: `${domain}/UPDATE_TEMP_STORAGE_DATASET_FIELDS`,
  RESET_TEMP_STORAGE_BY_KEY: `${domain}/RESET_TEMP_STORAGE_BY_KEY`,
  RESET_TEMP_STORAGE: `${domain}/RESET_TEMP_STORAGE`,
};

export const GETTER = {
  GET_TEMP_STORAGE_BY_KEY: `${domain}/GET_TEMP_STORAGE_BY_KEY`,
};

export const STATE = {
  TEMP_STORAGE: "TEMP_STORAGE"
}

export const TEMP_STORAGE_KEYS = {
  TEMP_PANELS: "TEMP_PANELS",
  TEMP_DATASET_FIELDS: "TEMP_DATASET_FIELDS",
  TEMP_DATASET_FILTER_PARAMS: "TEMP_DATASET_FILTER_PARAMS",
  TEMP_RUN_SQL_FILTERS_POPUP: "TEMP_RUN_SQL_FILTERS_POPUP",
  TEMP_SELECTED_DASHBOARD: "TEMP_SELECTED_DASHBOARD",
  TEMP_SELECTED_INGEST: "TEMP_SELECTED_INGEST",
  TEMP_NOT_MODIFIED_SELECTED_INGEST: "TEMP_NOT_MODIFIED_SELECTED_INGEST",
  TEMP_DATAMODEL: "TEMP_DATAMODEL",
  TEMP_SELECTED_SCENARIO: "TEMP_SELECTED_SCENARIO",
}
