import { DefaultValue } from "../commons/dashboardProperties";

export const DATE_FILTER_DEFAULT_CONFIGURATIONS = {
  DATE_FILTER_MANDATORY_SELECTION: false,
  DATE_FILTER_ALLOW_MULTISELECT: false,
  DATE_FILTER_RANGE_SELECTOR_START_DATE: "",
  DATE_FILTER_RANGE_SELECTOR_END_DATE: "",
  DATE_FILTER_SELECTION_ACTION: false,
  DATE_FILTER_DEFAULT_SELECTION: DefaultValue.NONE,
  DATE_FILTER_SHOW_QUICK_OPTIONS: false,
  DATE_FILTER_SELECTED_QUICK_OPTIONS: null,
  DATE_FILTER_UNIQUE_NAME: "",
  DATE_FILTER_TAB_INDEX: 0,
  DATE_FILTER_DEFAULT_VALUE_ACTIVE: false,
  DATE_FILTER_MAX_RANGE: null,
  DATE_FILTER_APPLY_TO_TABS: []
};

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
  return typeof value != "undefined" ? value : computedValue;
};
