<template>
  <div class="vis-simple-tab">
    <form>
      <div
        class="vis-row"
        v-for="(filter, index) in ruleFilterList"
        :key="index"
      >
        <div v-if="index != 0" class="vis-col vis-col-12">
          <el-tag class="vis-cursor-pointer" size="small">{{
            $t("filterComponents.and")
          }}</el-tag>
        </div>
        <div class="vis-col">
          <label class="vis-form-label">{{
            $t("filterComponents.select_operator")
          }}</label>
          <el-select
            v-model="filter.operator"
            :placeholder="$t('filterComponents.select')"
          >
            <el-option
              v-for="item in operators"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div
          v-if="checkInputIsActiveByNullOperator(filter.operator)"
          class="vis-col"
        >
          <div v-if="!checkInputIsActiveByInOperator(filter.operator)">
            <label class="vis-form-label">{{
              $t("filterComponents.enterValue")
            }}</label>
            <el-input
              :placeholder="$t('generalPages.searchInput')"
              v-model="filter.value"
            ></el-input>
          </div>
          <div v-if="checkInputIsActiveByInOperator(filter.operator)">
            <label class="vis-form-label">{{
              $t("filterComponents.select")
            }}</label>
            <el-select
              v-model="filter.value"
              :placeholder="$t('filterComponents.select')"
              filterable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in mappedSelectionList"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import {
  operatorSelectList,
  objectsIncludesNull,
  filterPopupTabValue,
  objectsIncludesIn,
} from "./js/filters";
import { CustomIcon } from "../../../assets/js/custom-icons";
export default {
  props: {
    ruleFilterComponentList: {
      type: Array,
    },
    selectedComponent: {
      type: String,
    },
    selectionList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    ruleFilterList: {
      handler() {
        if (this.selectedComponent == filterPopupTabValue.RULE)
          this.setRuleFormData();
      },
      deep: true,
    },
  },
  data() {
    return {
      operators: operatorSelectList,
      ruleFilterList: [{ operator: "", value: "" }],
      customIcon: CustomIcon,
    };
  },
  mounted() {
    if (this.ruleFilterComponentList)
      this.ruleFilterList = this.ruleFilterComponentList;
  },
  computed: {
    mappedSelectionList() {
      return this.selectionList.map((m) => {
        return {
          value: m.value,
          label: m.value,
        };
      });
    },
  },
  methods: {
    setRuleFormData() {
      // ruleFilterList şimdilik 0. değeri alınıyor backend düzenlemesi bittikden sonra düzenlencek!!!
      this.$emit("setRuleFormData", this.ruleFilterList[0]);
    },
    addRuleFilterComponent() {
      this.$emit("addRuleFilterComponent", { value: "" });
    },
    checkInputIsActiveByNullOperator(selectedOperator) {
      return !Object?.values?.(objectsIncludesNull).includes(selectedOperator);
    },
    checkInputIsActiveByInOperator(selectedOperator) {
      return Object.values(objectsIncludesIn).includes(selectedOperator);
    },
  },
};
</script>
<style scoped>
.vis-simple-tab-title {
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
  margin-bottom: 15px;
}
.vis-text-container {
  position: relative;
}
.el-tag.vis-default-btn {
  padding: 4px 10px;
}
</style>
