export const homeProjectTableColumns = [
    {
        label: "",
        field: "typeIcon",
        width: '30px',
        sortable: false,
    },
    {
        label: 'generalPages.labelName',
        field: "name",
    },
    {
        label: 'generalPages.labelDescription',
        field: "description"
    },
    {
        label: 'generalPages.Directory',
        field: "folderNames"
    },
    {
        label: 'generalPages.labelOwner',
        field: "createBy",
    },
    {
        label: 'generalPages.labelCreatedAt',
        field: "createDate",
    },
    {
        label: 'generalPages.labelUpdatedAt',
        field: "updatedDate",
    },
    {
        label: 'generalPages.labelType',
        field: "type",
    },
    {
        label: 'generalPages.labelActions',
        field: "actions",
        sortable: false,
        thClass: 'vis-text--right',
    },
];