

export const LABEL_CHART_DEFAULT_CONFIGURATIONS = {
    LABEL_CHART_DYNAMIC_TITLE_VALUE: "",
    LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
    LABEL_CHART_GROUP_MULTIPLE_VALUES: false,
};

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue;
};
