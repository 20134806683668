export const domain = "OBJECT_LOCK";

export const STATE = {
    OBJECT_LOCK: `${domain}/OBJECT_LOCK`,
};

export const GETTER = {
    GET_OBJECT_LOCK: `${domain}/GET_OBJECT_LOCK`,
};

export const ACTION = {
    CREATE_OBJECT_LOCK: `${domain}/CREATE_OBJECT_LOCK`,
    DELETE_OBJECT_LOCK: `${domain}/DELETE_OBJECT_LOCK`,
    FETCH_OBJECT_LOCK: `${domain}/FETCH_OBJECT_LOCK`,
};

export const MUTATION = {
    SET_OBJECT_LOCK: `${domain}/SET_OBJECT_LOCK`
}