import { render, staticRenderFns } from "./IngestSourceProperties.vue?vue&type=template&id=2d86d572&scoped=true"
import script from "./IngestSourceProperties.vue?vue&type=script&lang=js"
export * from "./IngestSourceProperties.vue?vue&type=script&lang=js"
import style0 from "./IngestSourceProperties.vue?vue&type=style&index=0&id=2d86d572&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d86d572",
  null
  
)

export default component.exports