<template>
  <div class="container">
    <el-table :data="notifications">
      <el-table-column label-class-name="vis-notification-label" :label="$t('generalPages.dashboard')" width="200">
        <template slot-scope="scope">
          <div class="vis-flex--Ycenter">
            <i
              class="export-icon"
              :class="exportTypeIcons[scope.row.type]"
              aria-hidden="true"
            ></i>
            <span class="vis-ml--1 vis-line-clamp-2 vis-notification-rows">{{ scope.row.dashboardName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label-class-name="vis-notification-label" :label="$t('generalPages.chartName')" width="360">
        <template slot-scope="scope">
          <span class="vis-notification-rows" size="medium">{{
            scope.row.chartName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label-class-name="vis-notification-label" :label="$t('generalPages.labelStatus')" width="120">
        <template slot-scope="scope"
          ><span class="vis-notification-rows">{{ $t(`generalPages.${scope.row.status}`) }}</span>
        </template>
      </el-table-column>
      <el-table-column label-class-name="vis-notification-label" :label="$t('generalPages.labelDate')" width="210">
        <template slot-scope="scope">
            <span class="vis-notification-rows">{{ scope.row.createdDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label-class-name="vis-notification-label" :label="$t('generalPages.labelActions')">
        <template slot-scope="scope">
          <span>
            <i
              v-if="isStatusFinished(scope.row.status)"
              class="vis-cursor-pointer vis-mr--1 export-icon"
              :class="customIcons.Download"
              @click="handleDownload(scope.row)"
              aria-hidden="true"
            />
          </span>
          <span>
            <i
              v-if="isStatusFinished(scope.row.status) || isStatusFailed(scope.row.status)"
              class="vis-cursor-pointer export-icon"
              :class="customIcons.Trash"
              @click="handleDelete(scope.row)"
              aria-hidden="true"
            />
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { exportTypeIcons } from "../../commons/exports";
import { EXPORT_STATUS } from "../../util/layout/layoutHelper";
export default {
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      exportTypeIcons: exportTypeIcons,
      customIcons: CustomIcon,
      exportStatus: EXPORT_STATUS,
    };
  },
  methods: {
    handleDownload(row) {
      this.$emit("onDownload", row);
    },
    handleDelete(row) {
      this.$emit("onDelete", row);
    },
    isStatusFinished(status) {
      return status === this.exportStatus.FINISHED;
    },
    isStatusFailed(status) {
      return status === this.exportStatus.FAILED;
    },
  },
};
</script>
<style scoped>
.export-icon {
  max-width: 22px;
  font-size: 1rem !important;
}

</style>
