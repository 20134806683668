
import {
    DATE_FILTER_PROPERTIES_ENUM,
    filterDefaultValues,
    filterSelectionActions
} from "../../commons/dashboardProperties";
import { DATE_FILTER_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/dateFilterDefaultProperties";

export default {
    computed: {
        dateFilterComponentsMapping() {
            return {
                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MANDATORY_SELECTION]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MANDATORY_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.mandatorySelection",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MANDATORY_SELECTION
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_MANDATORY_SELECTION),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MANDATORY_SELECTION,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },

                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.range",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_ALLOW_MULTISELECT),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_APPLY_TO_TABS]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_APPLY_TO_TABS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Apply to Tabs",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_APPLY_TO_TABS
                    ], [this.dashboardTabsOptionList[this.chartTabIndex]?.value]),

                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_APPLY_TO_TABS,
                    componentType: this.componentTypes.CUSTOM_MULTIPLE_SELECT_BOX,
                    selectValues: this.dashboardTabsOptionList,
                    flexClass: false,
                },
                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_START_DATE]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_START_DATE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Limit Selection",
                    placeHolder: "el.datepicker.startDate",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_START_DATE
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_RANGE_SELECTOR_START_DATE),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_START_DATE,
                    componentType: this.componentTypes.DATE_RANGE,
                    className: 'vis-datepicker',
                    isPickerOptionsActive: true
                },
                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_END_DATE]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_END_DATE,
                        this.collapse.CHART
                    ),
                    placeHolder: "el.datepicker.endDate",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_END_DATE
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_RANGE_SELECTOR_END_DATE),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_END_DATE,
                    componentType: this.componentTypes.DATE_RANGE,
                    className: 'vis-datepicker',
                    isPickerOptionsActive: true
                },
                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MAX_RANGE]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MAX_RANGE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Maximum Range",
                    placeHolder: "panelProperties.Enter Value in Days",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MAX_RANGE
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_MAX_RANGE),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MAX_RANGE,
                    componentType: this.componentTypes.INPUT_NUMBER,
                    minValue: 1
                },
                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SELECTION_ACTION]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SELECTION_ACTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.selectionAction",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SELECTION_ACTION
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_SELECTION_ACTION),

                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SELECTION_ACTION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterSelectionActions,
                    flexClass: false,
                },

                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_SELECTION]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.defaultValue",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_SELECTION
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_DEFAULT_SELECTION),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_SELECTION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterDefaultValues,
                    flexClass: false,
                },

                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SHOW_QUICK_OPTIONS]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SHOW_QUICK_OPTIONS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showQuickOptions",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SHOW_QUICK_OPTIONS
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_SHOW_QUICK_OPTIONS),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SHOW_QUICK_OPTIONS,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                    readOnly: this.componentStyle[DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT]
                },

                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_UNIQUE_NAME]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_UNIQUE_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.uniqueName",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_UNIQUE_NAME
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_UNIQUE_NAME),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_UNIQUE_NAME,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                },

                [DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_TAB_INDEX]: {
                    id: this.getPropertyId(
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_TAB_INDEX,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.tabIndex",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_TAB_INDEX
                    ], DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_TAB_INDEX),
                    property: DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_TAB_INDEX,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                },
            }
        },
    },
};
