/**
 * Component privileges will be defined on Front-end
 */
export const COMPONENT_PRIVILEGES = {
    EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD: "EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD",
    HOME_PAGE_DATAMODEL_CREATE_VISIBLE: "HOME_PAGE_DATAMODEL_CREATE_VISIBLE",
    HOME_PAGE_DASHBOARD_CREATE_VISIBLE: "HOME_PAGE_DASHBOARD_CREATE_VISIBLE",
    HOME_PAGE_DATAFLOW_CREATE_VISIBLE: "HOME_PAGE_DATAFLOW_CREATE_VISIBLE",
    HOME_PAGE_DATAMODEL_CREATE_CLICKABLE: "HOME_PAGE_DATAMODEL_CREATE_CLICKABLE",
    HOME_PAGE_DASHBOARD_CREATE_CLICKABLE: "HOME_PAGE_DASHBOARD_CREATE_CLICKABLE",
    HOME_PAGE_DATAFLOW_CREATE_CLICKABLE: "HOME_PAGE_DATAFLOW_CREATE_CLICKABLE",
    HOME_PAGE_FOLDER_CREATE_VISIBLE: "HOME_PAGE_FOLDER_CREATE_VISIBLE",
    HOME_PAGE_MOVE_DASHBOARD_TO_FOLDER: "HOME_PAGE_MOVE_DASHBOARD_TO_FOLDER",
    HOME_PAGE_MOVE_DATAMODEL_TO_FOLDER: "HOME_PAGE_MOVE_DATAMODEL_TO_FOLDER",
    HOME_PAGE_CARD_EDIT: "HOME_PAGE_CARD_EDIT",
    DASHBOARD_PAGE_EDIT: "DASHBOARD_PAGE_EDIT",
    DASHBOARD_PAGE_SAVE: "DASHBOARD_PAGE_SAVE",
    DATAMODEL_PAGE_EDIT: "DATAMODEL_PAGE_EDIT",
    DATAMODEL_PAGE_SAVE: "DATAMODEL_PAGE_SAVE",
    TOP_BAR_SETTINGS_ADMINISTRATE: "TOP_BAR_SETTINGS_ADMINISTRATE",
    DATAMODEL_PAGE_SCHEDULE_SECTION: "DATAMODEL_PAGE_SCHEDULE_SECTION",
    DATAMODEL_PAGE_LOAD_OPTIONS_SECTION: "DATAMODEL_PAGE_LOAD_OPTIONS_SECTION",
    ADMINISTRATION_PAGE_SYSTEM_SECTION: "ADMINISTRATION_PAGE_SYSTEM_SECTION",
    ADMINISTRATION_PAGE_MANAGE_USERS_SECTION: "ADMINISTRATION_PAGE_MANAGE_USERS_SECTION",
    ADMINISTRATION_PAGE_VIEW_LOGS_SECTION: "ADMINISTRATION_PAGE_VIEW_LOGS_SECTION",
    ADMINISTRATION_PAGE_ALL_LEFT_PANEL: "ADMINISTRATION_PAGE_ALL_LEFT_PANEL",
    ADMINISTRATION_PAGE_CONNECTION_SECTION: "ADMINISTRATION_PAGE_CONNECTION_SECTION",
    HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION: "HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION",
    HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION: "HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION",
    HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION: "HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION",
}

/**
 * Page privileges will be defined on Front-end
 */
export const PAGE_PRIVILEGES = {
    HOME_PAGE: "HOME_PAGE",
    DATAMODEL_PAGE: "DATAMODEL_PAGE",
    DATAMODEL_VIEW_PAGE: "DATAMODEL_VIEW_PAGE",
    DASHBOARD_PAGE: "DASHBOARD_PAGE",
    DASHBOARD_EDIT_PAGE: "DASHBOARD_EDIT_PAGE",
    DASHBOARD_CREATE_PAGE: "DASHBOARD_CREATE_PAGE",
    DASHBOARD_VIEW_PAGE: "DASHBOARD_VIEW_PAGE",
    DATA_PAGE: "DATA_PAGE",
    PANEL_PAGE: "PANEL_PAGE",
    ADMINISTRATION_PAGE: "ADMINISTRATION_PAGE",
    SETTINGS_PAGE: "SETTINGS_PAGE",
}

/**
 * Roles will be defined in keycloak
 * Value of enum should be equal to one of the keycloak roles
 */
export const ROLES = {
    ADMIN: {
        key: "Admin",
        label: "Admin"
    },
    DATAMODEL_EDITOR: {
        key: "DatamodelEditor",
        label: "Datamodel Editor"
    },
    DASHBOARD_EDITOR: {
        key: "DashboardEditor",
        label: "Dashboard Editor"
    },
    DATAFLOW_EDITOR: {
        key: "DataflowEditor",
        label: "Dataflow Editor"
    },
    DASHBOARD_VIEWER: {
        key: "DashboardViewer",
        label: "Dashboard Viewer"
    },
    DATAMODEL_VIEWER: {
        key: "DatamodelViewer",
        label: "Datamodel Viewer"
    },
    CONNECTION_EDITOR: {
        key: "ConnectionEditor",
        label: "Connection Editor"
    },
    SCHEDULE_MANAGER: {
        key: "ScheduleManager",
        label: "Schedule Manager"
    },
};

const rolePrivilegeMapping = [
    {
        role: ROLES.ADMIN.key,
        privileges: [
            COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAFLOW_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_CLICKABLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAFLOW_CREATE_CLICKABLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_CREATE_CLICKABLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_CARD_EDIT,
            COMPONENT_PRIVILEGES.DASHBOARD_PAGE_EDIT,
            COMPONENT_PRIVILEGES.DASHBOARD_PAGE_SAVE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_EDIT,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SAVE,
            COMPONENT_PRIVILEGES.TOP_BAR_SETTINGS_ADMINISTRATE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_SYSTEM_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_MANAGE_USERS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_VIEW_LOGS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_ALL_LEFT_PANEL,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_FOLDER_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DASHBOARD_TO_FOLDER,
            COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DATAMODEL_TO_FOLDER,
        ],
        pagePrivileges: [
            PAGE_PRIVILEGES.HOME_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_VIEW_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_EDIT_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_CREATE_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_VIEW_PAGE,
            PAGE_PRIVILEGES.DATA_PAGE,
            PAGE_PRIVILEGES.PANEL_PAGE,
            PAGE_PRIVILEGES.ADMINISTRATION_PAGE,
            PAGE_PRIVILEGES.SETTINGS_PAGE,
        ]
    },
    {
        role: ROLES.DATAMODEL_EDITOR.key,
        privileges: [
            COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_CLICKABLE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_EDIT,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SAVE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_SYSTEM_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_MANAGE_USERS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_VIEW_LOGS_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_FOLDER_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DATAMODEL_TO_FOLDER,
        ],
        pagePrivileges: [
            PAGE_PRIVILEGES.HOME_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_VIEW_PAGE,
            PAGE_PRIVILEGES.DATA_PAGE,
            PAGE_PRIVILEGES.SETTINGS_PAGE,
        ]
    },
    {
        role: ROLES.DATAFLOW_EDITOR.key,
        privileges: [
            COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAFLOW_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAFLOW_CREATE_CLICKABLE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_EDIT,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SAVE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_SYSTEM_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_MANAGE_USERS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_VIEW_LOGS_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_FOLDER_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DATAMODEL_TO_FOLDER,
        ],
        pagePrivileges: [
            PAGE_PRIVILEGES.HOME_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_VIEW_PAGE,
            PAGE_PRIVILEGES.DATA_PAGE,
            PAGE_PRIVILEGES.SETTINGS_PAGE,
        ]
    },
    {
        role: ROLES.DASHBOARD_EDITOR.key,
        privileges: [
            COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_CREATE_CLICKABLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_CARD_EDIT,
            COMPONENT_PRIVILEGES.DASHBOARD_PAGE_EDIT,
            COMPONENT_PRIVILEGES.DASHBOARD_PAGE_SAVE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_SYSTEM_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_MANAGE_USERS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_VIEW_LOGS_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_FOLDER_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DASHBOARD_TO_FOLDER,
        ],
        pagePrivileges: [
            PAGE_PRIVILEGES.HOME_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_EDIT_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_CREATE_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_VIEW_PAGE,
            PAGE_PRIVILEGES.PANEL_PAGE,
        ]
    },
    {
        role: ROLES.DASHBOARD_VIEWER.key,
        privileges: [
            COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_SYSTEM_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_MANAGE_USERS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_VIEW_LOGS_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION,
        ],
        pagePrivileges: [
            PAGE_PRIVILEGES.HOME_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_VIEW_PAGE,
        ]
    },
    {
        role: ROLES.DATAMODEL_VIEWER.key,
        privileges: [
            COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_SYSTEM_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_MANAGE_USERS_SECTION,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_VIEW_LOGS_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION,
        ],
        pagePrivileges: [
            PAGE_PRIVILEGES.HOME_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_VIEW_PAGE,
            PAGE_PRIVILEGES.DATA_PAGE,
        ]
    },
    {
        role: ROLES.CONNECTION_EDITOR.key,
        privileges: [
            COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_CREATE_VISIBLE,
            COMPONENT_PRIVILEGES.HOME_PAGE_CARD_EDIT,
            COMPONENT_PRIVILEGES.DASHBOARD_PAGE_EDIT,
            COMPONENT_PRIVILEGES.DASHBOARD_PAGE_SAVE,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_EDIT,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SAVE,
            COMPONENT_PRIVILEGES.TOP_BAR_SETTINGS_ADMINISTRATE,
            COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_CONNECTION_SECTION,
        ],
        pagePrivileges: [
            PAGE_PRIVILEGES.HOME_PAGE,
            PAGE_PRIVILEGES.DATAMODEL_VIEW_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_VIEW_PAGE,
            PAGE_PRIVILEGES.DATA_PAGE,
            PAGE_PRIVILEGES.PANEL_PAGE,
            PAGE_PRIVILEGES.ADMINISTRATION_PAGE,
            PAGE_PRIVILEGES.SETTINGS_PAGE,
        ]
    },
    {
        role: ROLES.SCHEDULE_MANAGER.key,
        privileges: [
            COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
            COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION,
            COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION
        ],
        pagePrivileges: [
            PAGE_PRIVILEGES.HOME_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_EDIT_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_CREATE_PAGE,
            PAGE_PRIVILEGES.DASHBOARD_VIEW_PAGE,
            PAGE_PRIVILEGES.PANEL_PAGE,
        ]
    },
]


export const getPrivilegesByRole = (roleArray) => {
    let privileges = [];
    let uniqueArray = [];
    roleArray.forEach(element => {
        rolePrivilegeMapping.forEach(rolePrivilege => {
            if (rolePrivilege.role === element) {
                privileges = privileges.concat(rolePrivilege.privileges);
            }
        })
    });

    if (privileges.length > 0)
        uniqueArray = [...new Set(privileges)];

    return uniqueArray;
}

//gets page privileges by role
export const getPagePrivilegesByRole = (roleArray) => {
    let pagePrivileges = [];
    let uniqueArray = [];
    roleArray.forEach(element => {
        rolePrivilegeMapping.forEach(rolePrivilege => {
            if (rolePrivilege.role === element) {
                pagePrivileges = pagePrivileges.concat(rolePrivilege.pagePrivileges);
            }
        })
    });

    if (pagePrivileges.length > 0)
        uniqueArray = [...new Set(pagePrivileges)];

    return uniqueArray;
}