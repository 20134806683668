<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold">{{ $t('ingest.Job Properties') }}</span>
    </div>
    <el-collapse
      v-if="!isDifferentJobPropertiesLoading"
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in allCollapses"
        :key="collapse.key"
        :title="$t(`${collapse.label}`)"
        :name="collapse.key"
        :disabled="collapse.disabled"
      >
        <div v-if="collapseTypes.general === collapse.key">
          <div class="property-wrapper">
            <label>{{ $t('ingest.Name') }}</label>
            <input
              class="vis-input"
              :class="{ 'vis-readonly-background': showSqlEditor }"
              :value="job.name"
              @keyup="(e) => updateField(IngestJobFields.name, e.target.value)"
              :readonly="showSqlEditor"
            />
          </div>
          <div class="property-wrapper">
            <label>{{ $t('ingest.Description') }}</label>
            <textarea
              class="vis-textarea vis-textarea-vertical-resize"
              :class="{ 'vis-readonly-background': showSqlEditor }"
              :value="job.description"
              rows="3"
              @keyup="
                (e) => updateField(IngestJobFields.description, e.target.value)
              "
              :readonly="showSqlEditor"
            >
            </textarea>
          </div>
          <div class="property-wrapper">
            <label>{{ $t("ingest.Source Connection") }}</label>
            <input
              class="vis-input vis-readonly-background"
              :value="sourceConnectionName"
              readonly
            />
          </div>
          <div class="property-wrapper">
            <ConfirmableInput
              :label="$t('ingest.Target Dataset')"
              :value="job.targetTableName"
              :readonly="showSqlEditor"
              :jobId="job.id"
              @change="
                (val) => updateField(IngestJobFields.targetTableName, val)
              "
            />
          </div>
        </div>
        <div v-if="collapseTypes.dataRefresh === collapse.key">
          <div class="property-wrapper">
            <ConfirmableSelect
              :label="$t('ingest.Update Mode')"
              :placeholder="$t('ingest.Update Mode')"
              :value="job.jobMode"
              :items="jobModeOptions"
              :jobId="job.id"
              @change="(val) => updateField(IngestJobFields.jobMode, val)"
            />
          </div>
          <div class="property-wrapper">
            <ConfirmableSelect
              :label="$t('ingest.Unique Identifier')"
              :placeholder="$t('ingest.Unique Identifier')"
              :value="job.cursorField"
              :items="dateAndNumberColumnList"
              :jobId="job.id"
              @change="(val) => updateField(IngestJobFields.cursorField, val)"
            />
          </div>
        </div>
        <div v-if="collapseTypes.optimization === collapse.key">
          <div class="property-wrapper">
            <ConfirmableSelect
              :label="$t('ingest.Index Fields')"
              :placeholder="$t('ingest.Index Fields')"
              :value="orderBy"
              :items="columnList"
              :jobId="job.id"
              :multiple="true"
              :collapseTags="true"
              @change="(val) => updateArrayField(IngestJobFields.orderBy, val)"
            />
          </div>
          <div class="property-wrapper">
            <ConfirmableSelect
              :label="$t('ingest.Partition Fields')"
              :placeholder="$t('ingest.Partition Fields')"
              :value="partitionBy"
              :items="columnList"
              :jobId="job.id"
              :multiple="true"
              :collapseTags="true"
              @change="
                (val) => updateArrayField(IngestJobFields.partitionBy, val)
              "
            />
          </div>
        </div>
        <div v-if="collapseTypes.schedule === collapse.key">
          <SchedulePopup
            v-if="popupScheduleVisible"
            :showDynamicContent="false"
            :months="months"
            :days="days"
            :monthlyCron="monthlyCron"
            :dailyCron="dailyCron"
            :taskCron="taskCronFiltered"
            :timeRanges="timeRanges"
            :scheduleEditForm="scheduleEditForm"
            :isScheduleEditMode="isScheduleEditMode"
            :isDistributionAvailable="false"
            :isContentAvailable="false"
            :isFilterAvailable="false"
            @closePopupSchedule="closePopupSchedule"
            @handleSchedule="handleSchedule"
          />
          <ScheduleCard
            class="vis-schedule-card-comp"
            :schedules="scheduleCardData"
            @handleEditSchedule="handleEditSchedule"
            @deleteScheduleData="deleteScheduleData"
          />
          <div
            class="vis-flex--Xright addAction vis-mb--1"
            @click="showSchedulePopup"
          >
            <i :class="customIcon.Plus" aria-hidden="true"></i>
            <span class="vis-font-bold"
              >{{ $t("generalPages.addSchedule") }}
            </span>
          </div>
        </div>
        <div v-if="collapseTypes.runHistory === collapse.key">
          <IngestJobStatus ref="jobStatus" :jobId="job.id" />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";
import { IngestJobFields } from "./enums";
import SchedulePopup from "../data/scheduleProperties/SchedulePopup";
import ScheduleCard from "../data/scheduleProperties/ScheduleCard";
import {
  ACTION as ACTION_SCHEDULE_TASK_TYPES,
  GETTER as GETTER_SCHEDULE_TASK_TYPES,
} from "../../store/modules/Visualize/TaskTypes/types";
import { mapActions, mapGetters } from "vuex";
import { CustomIcon } from "../../assets/js/custom-icons";
import { scheduleModes } from "../../commons/ingestDefaultData";
import IngestJobStatus from "./ingestJobs/IngestJobStatus";
import ConfirmableSelect from "../helper/ConfirmableSelect";
import ConfirmableInput from "../helper/ConfirmableInput";
import { schedulePeriodOptions } from "../data/scheduleProperties/schedule";

export default {
  components: {
    SchedulePopup,
    ScheduleCard,
    IngestJobStatus,
    ConfirmableSelect,
    ConfirmableInput,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    connections: {
      type: Array,
      required: true,
    },
    columnList: {
      type: Array,
      required: true,
    },
    showOnlyGeneral: {
      type: Boolean,
      default: false,
    },
    showSqlEditor: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "job.id": {
      handler() {
        this.isDifferentJobPropertiesLoading = true;

        setTimeout(() => {
          this.isDifferentJobPropertiesLoading = false;
        }, 100);
      },
      immediate: true,
    },
  },
  data() {
    return {
      isDifferentJobPropertiesLoading: false,
      IngestJobFields: IngestJobFields,
      collapseTypes: {
        general: "general",
        dataRefresh: "dataRefresh",
        optimization: "optimization",
        schedule: "schedule",
        runHistory: "runHistory",
      },
      activeCollapses: [],
      isScheduleEditMode: false,
      popupScheduleVisible: false,
      scheduleEditForm: {},
      customIcon: CustomIcon,
      columnTypes: {
        DECIMAL: "DECIMAL",
        TEXT: "TEXT",
        INTEGER: "INTEGER",
        DATE: "DATE",
      },
    };
  },
  mounted() {
    this.activeCollapses.push(this.collapseTypes.general);

    if (!this.showOnlyGeneral) {
      this.activeCollapses.push(this.collapseTypes.dataRefresh);
      this.activeCollapses.push(this.collapseTypes.optimization);
      this.activeCollapses.push(this.collapseTypes.schedule);
      this.activeCollapses.push(this.collapseTypes.runHistory);
    }

    this.fetchMonths();
    this.fetchDays();
    this.fetchMonthlyCron();
    this.fetchDailyCron();
    this.fetchTaskCron();
    this.fetchTimeRanges();
  },
  computed: {
    ...mapGetters({
      months: GETTER_SCHEDULE_TASK_TYPES.GET_MONTHS,
      days: GETTER_SCHEDULE_TASK_TYPES.GET_DAYS,
      monthlyCron: GETTER_SCHEDULE_TASK_TYPES.GET_MONTHLY_CRON,
      dailyCron: GETTER_SCHEDULE_TASK_TYPES.GET_DAILY_CRON,
      taskCron: GETTER_SCHEDULE_TASK_TYPES.GET_CRON,
      timeRanges: GETTER_SCHEDULE_TASK_TYPES.GET_TIME_RANGES,
    }),
    taskCronFiltered() {
      return this.taskCron?.filter(
        (x) => x.key !== schedulePeriodOptions.Hourly
      );
    },
    orderBy() {
      return this.job.orderBy?.map((o) => o.columnName) ?? [];
    },
    partitionBy() {
      return this.job.partitionBy?.map((o) => o.columnName) ?? [];
    },
    scheduleCardData() {
      return this.job.scheduleField
        ? [{ taskDefinitionDto: this.job.scheduleField }]
        : [];
    },
    dateAndNumberColumnList() {
      return this.columnList.filter((c) =>
        [
          this.columnTypes.DATE,
          this.columnTypes.INTEGER,
          this.columnTypes.DECIMAL,
        ].includes(c.classType)
      );
    },

    sourceConnectionName() {
      return (
        this.connections.find(
          (c) => c.connectionId === this.job.sourceConnectionId
        )?.name ?? "N/A"
      );
    },
    jobModeOptions() {
      return [
        {
          value: "OVERWRITE",
          label: this.$t('ingest.Overwrite'),
        },
        {
          value: "INCREMENTAL",
          label: this.$t('ingest.Incremental'),
        },
      ];
    },
    allCollapses() {
      return [
        {
          key: this.collapseTypes.general,
          label: "generalPages.general",
          isShow: true,
        },
        !this.showOnlyGeneral
          ? {
              key: this.collapseTypes.dataRefresh,
              label: this.$t('ingest.Data Refresh'),
              isShow: true,
            }
          : null,
        !this.showOnlyGeneral
          ? {
              key: this.collapseTypes.optimization,
              label: this.$t('ingest.Optimization'),
              isShow: true,
            }
          : null,
        !this.showOnlyGeneral
          ? {
              key: this.collapseTypes.schedule,
              label: this.$t('ingest.Schedule'),
              isShow: true,
            }
          : null,
        !this.showOnlyGeneral
          ? {
              key: this.collapseTypes.runHistory,
              label: "dataflow.runHistory.Run History",
              isShow: true,
            }
          : null,
      ]
        .filter((c) => c)
        .filter((n) => n.isShow);
    },
  },
  methods: {
    ...mapActions({
      fetchMonths: ACTION_SCHEDULE_TASK_TYPES.FETCH_MONTHS,
      fetchDays: ACTION_SCHEDULE_TASK_TYPES.FETCH_DAYS,
      fetchMonthlyCron: ACTION_SCHEDULE_TASK_TYPES.FETCH_MONTHLY_CRON,
      fetchDailyCron: ACTION_SCHEDULE_TASK_TYPES.FETCH_DAILY_CRON,
      fetchTaskCron: ACTION_SCHEDULE_TASK_TYPES.FETCH_CRON,
      fetchTimeRanges: ACTION_SCHEDULE_TASK_TYPES.FETCH_TIME_RANGES,
    }),
    cloneTempJob() {
      return cloneDeep(this.job);
    },
    showSchedulePopup() {
      this.popupScheduleVisible = true;
    },
    closePopupSchedule() {
      this.isScheduleEditMode = false;
      this.popupScheduleVisible = !this.popupScheduleVisible;
    },
    handleEditSchedule(payload) {
      this.scheduleEditForm = payload;
      this.isScheduleEditMode = true;
      this.showSchedulePopup();
    },
    deleteScheduleData() {
      const clonedJob = this.cloneTempJob();

      clonedJob[IngestJobFields.scheduleField] = null;
      clonedJob[IngestJobFields.scheduleMode] = scheduleModes.MANUAL;

      this.$emit("updateJob", clonedJob);
    },
    updateArrayField(field, value) {
      const clonedJob = this.cloneTempJob();

      clonedJob[field] = value?.map((v) => ({ columnName: v })) ?? [];

      this.$emit("updateJob", clonedJob);
    },
    updateField(field, value) {
      const clonedJob = this.cloneTempJob();

      clonedJob[field] = value;

      this.$emit("updateJob", clonedJob);
    },
    handleSchedule(newScheduleField) {
      // eslint-disable-next-line no-unused-vars
      const { message, objectId, objectType, taskStatus, taskType, ...others } =
        cloneDeep(newScheduleField.taskDefinitionDto);

      const clonedJob = this.cloneTempJob();

      clonedJob.scheduleField = others;
      clonedJob.scheduleMode = scheduleModes.SCHEDULED;

      this.$emit("updateJob", clonedJob);
    },
  },
};
</script>

<style scoped>
.property-wrapper {
  margin-bottom: 10px;
}
::v-deep .el-collapse-item__wrap {
  margin-bottom: 20px;
}
</style>
