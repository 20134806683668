import { chartTypes } from "../commons/dashboardAndPanel";
import { DatamodelContextDefaults } from "../commons/dataModelTypes";

export const getSearchApiBodyParam = (fieldData) => {
    const { alias, fieldId, datasetId } = fieldData
    return {
        filter: [],
        metric: [
            {
                field: fieldData.name,
                type: DatamodelContextDefaults.DISTINCT,
                alias,
                fieldId,
                datasetId,
                fieldType: fieldData.type,
            },
        ],
        dataset: { id: datasetId },
        aggregation: [],
        params: [],
        orders: [],
        vismetadata: {
            vistype: chartTypes?.OTHER,
        },
    }
}