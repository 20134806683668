<template>
  <div class="scenario-aside-properties-action-tab">
    <span>{{ $t("Define the action for this transition") }}</span>
    <el-collapse class="vis-mt--1" v-model="selectedAccordion" accordion>
      <el-collapse-item title="Action 1" name="action-1">
        <span>{{ $t("Select the action and define parameters") }}</span>
        <div class="vis-mt--1 vis-mb--1">
          <label>{{ $t("Action") }}</label>
          <el-select
            :value="properties.action.actiondetails.name"
            :placeholder="$t('Action')"
            @change="updateSelectedAction"
          >
            <el-option
              v-for="item in actions"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="vis-mb--1">
          <label>{{ $t("Parameters") }}</label>
          <div
            v-for="(selectedActionParam, index) in selectedActionParams"
            :key="selectedActionParam.key"
            class="vis-pl--1 vis-mb--2"
          >
            <el-row class="vis-mb--1" :gutter="20">
              <el-col :span="6">
                <span class="parameter-span">{{ $t("Parameter") }}</span>
              </el-col>
              <el-col :span="18">
                <div>
                  <el-input
                    :value="selectedActionParam.key"
                    :disabled="true"
                  /></div
              ></el-col>
            </el-row>
            <el-row class="vis-mb--1" :gutter="20">
              <el-col :span="12">
                <label class="label-disabled">{{ $t("Source") }}</label>
                <div>
                  <!-- TODO backend source secilimini destekleyince guncelle -->
                  <el-select
                    :value="sourceOptionTypes.custom"
                    :placeholder="$t('Source')"
                  >
                    <el-option
                      v-for="item in sourceOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select></div
              ></el-col>
              <el-col :span="12">
                <label class="label-disabled">{{ $t("Field") }}</label>
                <div>
                  <el-input
                    :value="selectedActionParam.value"
                    @input="
                      (v) => updateSelectedActionParamValueByIndex(index, v)
                    "
                  />
                  <!--<el-select
                    v-model="selectedActionParam.field"
                    :placeholder="$t('Field')"
                    ><el-option
                      v-for="item in getFieldOptions(
                        selectedActionParam.source
                      )"
                      :key="item.field"
                      :label="item.field"
                      :value="item.field"
                    >
                    </el-option>
                  </el-select>
                  -->
                </div></el-col
              >
            </el-row>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <!--<div class="scenario-aside-properties-action-tab-footer">
      <el-link
        class="scenario-aside-properties-action-tab-check-button"
        :underline="false"
        >Add action</el-link
      >
    </div>-->
  </div>
</template>

<script>
import cloneDeep from "clone-deep";

export default {
  props: {
    properties: {
      type: Object,
    },
    actions: {
      type: Array,
    },
    events: {
      type: Array,
    },
    enrichments: {
      type: Array,
    },
  },
  data() {
    return {
      selectedAccordion: "action-1",
      sourceOptionTypes: {
        event: "event",
        enrichment: "enrichment",
        custom: "custom",
      },
    };
  },
  computed: {
    selectedActionParams() {
      return this.properties.action.actiondetails.params;
    },
    selectedEventFields() {
      return this.events.find((e) => e.name === this.properties.event).fields;
    },
    selectedEnrichmentOutputs() {
      return this.enrichments?.find(
        (e) => e.enrichmentId === this.properties.enrichments[0].enrichmentId
      )?.outputs;
    },
    sourceOptions() {
      return [
        {
          value: this.sourceOptionTypes.event,
          label: this.$t("Event"),
          disabled: true,
        },
        {
          value: this.sourceOptionTypes.enrichment,
          label: this.$t("Enrichment"),
          disabled: true,
        },
        {
          value: this.sourceOptionTypes.custom,
          label: this.$t("Custom"),
          disabled: false,
        },
      ];
    },
  },
  methods: {
    updateSelectedActionParamValueByIndex(updateIndex, newValue) {
      const newAction = cloneDeep(this.properties.action);

      newAction.actiondetails.params?.forEach((param, index) => {
        if (index === updateIndex) {
          param.value = newValue;
        }
      });

      this.$emit("updateAction", newAction);
    },
    updateSelectedAction(newActionName) {
      const newSelectedAction = this.actions.find(
        (action) => action.name === newActionName
      );

      const convertSelectedActionToActionDetails = () => {
        return {
          actiondetails: {
            name: newSelectedAction.name,
            params: newSelectedAction.params.map((action, index) => {
              return {
                eval: action.eval,
                key: action.key,
                order: index + 1,
                value: "",
              };
            }),
          },
        };
      };

      this.$emit("updateAction", convertSelectedActionToActionDetails());
    },
    getFieldOptions(sourceType) {
      if (sourceType === this.sourceOptionTypes.event) {
        return this.selectedEventFields;
      } else if (sourceType === this.sourceOptionTypes.enrichment) {
        return this.selectedEnrichmentOutputs;
      }

      return [];
    },
  },
};
</script>

<style scoped>
::v-deep .el-collapse-item__header {
  padding: 0 !important;
}
::v-deep .el-collapse-item__wrap {
  width: 100%;
}
.label-disabled {
  font: normal normal 400 13px/20px Poppins !important;
  letter-spacing: 0px;
  color: #3e3e3e;
}
label {
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
}
.parameter-span {
  line-height: 32px;
}
.scenario-aside-properties-action-tab-check-button {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0014ff !important;
}
.scenario-aside-properties-action-tab-footer {
  display: flex;
}
.scenario-aside-properties-action-tab-check-button:hover {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0014ff !important;
}
</style>
