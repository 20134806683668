export const domain = "VISUALIZE_FOLDER";

export const STATE = {
    FOLDERS: `${domain}/FOLDERS`,
};

export const GETTER = {
    GET_FOLDERS: `${domain}/GET_FOLDERS`,
};

export const ACTION = {
    CREATE_FOLDER: `${domain}/CREATE_FOLDER`,
    UPDATE_FOLDER_NAME: `${domain}/UPDATE_FOLDER_NAME`,
    DELETE_FOLDER: `${domain}/DELETE_FOLDER`,
    FETCH_FOLDERS: `${domain}/FETCH_FOLDERS`,
    FETCH_FOLDER_BY_ID: `${domain}/FETCH_FOLDER_BY_ID`,
    MOVE_FOLDER: `${domain}/MOVE_FOLDER`,
};

export const MUTATION = {
    SET_FOLDERS: `${domain}/SET_FOLDERS`,
};