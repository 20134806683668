<template>
  <div class="vis-flex-scrollbar">
    <ConfirmDialog
      :title="deleteProjectDialogTitle"
      :message="deleteProjectDialogMessage"
      :dialogVisible="deleteProjectDialogVisible"
      @confirm="deleteProject"
      @cancel="cancelDeleteProject"
    />
    <header>
      <div class="vis-container">
        <div class="vis-row">
          <div class="vis-col vis-pa--none vis-col-xl8 vis-col-l12 vis-col-m12">
            <HomeScenarioCreateCard @createClicked="createClicked" />
          </div>
          <div class="vis-col vis-col-3 vis-col-xl4 vis-col-l12 vis-col-m12">
            <WelcomeBox />
          </div>
          <div
            class="vis-col vis-col-3 vis-col-xxxl2 vis-col-xl2 vis-col-s12 vis-tablet-hidden"
          >
            <img
              class="vis-header-image"
              :src="getHeaderImage()"
              :alt="imageName"
            />
          </div>
        </div>
      </div>
    </header>
    <div
      v-loading="isSelectedScenarioLoading"
      :class="{ 'vis-big-size': bigSize }"
      class="vis-flex-scrollbar"
    >
      <section class="vis-bg-color-white">
        <div class="vis-container">
          <div class="vis-row">
            <div class="vis-col vis-col-12 vis-mb--none">
              <HomeTabsAndActions
                :isModuleVisualize="false"
                :isSearchAvailable="false"
                :isSortAvailable="false"
                :isCreateFolderAvailable="false"
                :isMaximizeAvailable="false"
                :iconName="iconName"
                :selectedProjectsLength="selectedProjects.length"
                @getAllProjects="getAllProjects"
                @changeViewCardOrTable="changeViewCardOrTable"
                @searchProjectList="projectName = $event"
                @tabChanged="lastSelectedTab = $event"
              />
            </div>
          </div>
        </div>
      </section>
      <div class="vis-container vis-scroll-thin">
        <div class="vis-row">
          <div
            class="vis-col vis-col-12"
            :class="{ 'vis-pa--none': showCardOrTable }"
          >
            <HomeProjectCards
              v-if="showCardOrTable"
              :mappedProjects="mappedProjects"
              :selectedProjects="selectedProjects"
              @view="viewProject"
              @deploy="deployProject"
              @undeploy="undeployProject"
              @delete="showDeleteProjectDialog"
            />
            <HomeProjectTable
              v-else
              :mappedProjects="mappedProjects"
              @view="viewProject"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeScenarioCreateCard from "../components/home/scenario/HomeScenarioCreateCard.vue";
import HomeProjectCards from "../components/home/HomeProjectCards.vue";
import HomeProjectTable from "../components/home/HomeProjectTable.vue";
import WelcomeBox from "../components/home/WelcomeBox.vue";
import HomeTabsAndActions from "../components/home/HomeTabsAndActions.vue";
import { mapActions, mapGetters } from "vuex";
import { HOME_SCENARIO_TABS_KEY } from "../commons/homeDataTypes";
import { routerEnums } from "../commons/Enum";
import { CustomIcon } from "../assets/js/custom-icons";
import { parseQueryStringTopbar } from "../util/embeded/queryString";
import { mapScenarioItemToHomePageProjectItem } from "../util/homePageMappers";
import {
  GETTER as GETTER_SCENARIO_MAIN,
  ACTION as ACTION_SCENARIO_MAIN,
} from "../store/modules/Scenario/Main/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";

export default {
  components: {
    HomeScenarioCreateCard,
    HomeProjectCards,
    HomeProjectTable,
    WelcomeBox,
    HomeTabsAndActions,
    ConfirmDialog,
  },
  data() {
    return {
      deleteProjectDialogVisible: false,
      deleteProjectDialogMessage: "",
      deleteProjectDialogTitle: "",
      deleteProjectParams: {},
      showCardOrTable: true,
      bigSize: false,
      iconName: {
        changeViewIcon: CustomIcon.Hamburger,
        resizeIcon: CustomIcon.OpenInFull,
      },
      imageName: "home-header-bg-image",
      lastSelectedTab: HOME_SCENARIO_TABS_KEY.ALL,
      projectName: "",
      selectedProjects: [],
      currentFolderId: null,
      isDashboardProperties: false,
      projectTypes: {
        scenario: "scenario",
        event: "event",
        enrichment: "enrichment",
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      scenarioItem: GETTER_SCENARIO_MAIN.GET_SCENARIO_ITEM,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    mappedProjects() {
      const mappedProjects = mapScenarioItemToHomePageProjectItem(
        this.scenarioItem
      );

      if (this.lastSelectedTab === HOME_SCENARIO_TABS_KEY.EVENT) {
        return mappedProjects.filter(
          (project) =>
            project.type.toLowerCase() ===
            HOME_SCENARIO_TABS_KEY.EVENT.toLowerCase()
        );
      } else if (this.lastSelectedTab === HOME_SCENARIO_TABS_KEY.ENRICHMENT) {
        return mappedProjects.filter(
          (project) =>
            project.type.toLowerCase() ===
            HOME_SCENARIO_TABS_KEY.ENRICHMENT.toLowerCase()
        );
      } else if (this.lastSelectedTab === HOME_SCENARIO_TABS_KEY.SCENARIO) {
        return mappedProjects.filter(
          (project) =>
            project.type.toLowerCase() ===
            HOME_SCENARIO_TABS_KEY.SCENARIO.toLowerCase()
        );
      }

      return mappedProjects;
    },
    isSelectedScenarioLoading() {
      return this.loading[LoadingComponent.SelectedScenario];
    },
  },
  methods: {
    ...mapActions({
      fetchScenarioItem: ACTION_SCENARIO_MAIN.FETCH_SCENARIO_ITEM,
      deployScenario: ACTION_SCENARIO_MAIN.DEPLOY_SCENARIO,
      undeployScenario: ACTION_SCENARIO_MAIN.UNDEPLOY_SCENARIO,
      deleteScenario: ACTION_SCENARIO_MAIN.DELETE_SCENARIO,
      deleteEvent: ACTION_SCENARIO_MAIN.DELETE_EVENT,
      deleteEnrichment: ACTION_SCENARIO_MAIN.DELETE_ENRICHMENT,
    }),
    async init() {
      await this.fetchScenarioItem();
      this.parseQueryParamOnHomePage();
    },
    deployProject(params) {
      this.deployScenario({ id: params.id });
    },
    undeployProject(params) {
      this.undeployScenario({ id: params.id });
    },
    cancelDeleteProject() {
      this.deleteProjectDialogVisible = false;
      this.deleteProjectDialogTitle = "";
      this.deleteProjectDialogMessage = "";
      this.deleteProjectParams = {};
    },
    showDeleteProjectDialog(params) {
      this.deleteProjectDialogVisible = true;
      this.deleteProjectParams = params;

      if (params.type === this.projectTypes.scenario) {
        this.deleteProjectDialogTitle = this.$t("Delete Scenario");
        this.deleteProjectDialogMessage = this.$t(
          "Do you want to delete this scenario?"
        );
      } else if (params.type === this.projectTypes.event) {
        this.deleteProjectDialogTitle = this.$t("Delete Event");
        this.deleteProjectDialogMessage = this.$t(
          "Do you want to delete this event?"
        );
      } else if (params.type === this.projectTypes.enrichment) {
        this.deleteProjectDialogTitle = this.$t("Delete Enrichment");
        this.deleteProjectDialogMessage = this.$t(
          "Do you want to delete this enrichment?"
        );
      }
    },
    async deleteProject() {
      if (this.deleteProjectParams.type === this.projectTypes.scenario) {
        await this.deleteScenario({ id: this.deleteProjectParams.id });
      } else if (this.deleteProjectParams.type === this.projectTypes.event) {
        await this.deleteEvent({ id: this.deleteProjectParams.id });
      } else if (
        this.deleteProjectParams.type === this.projectTypes.enrichment
      ) {
        await this.deleteEnrichment({ id: this.deleteProjectParams.id });
      }

      this.cancelDeleteProject();
      this.init();
    },
    removeTableClass() {
      var row = document.querySelectorAll(".clickable td");
      for (let index = 0; index < row.length; index++) {
        const element = row[index];
        element.classList.remove("vis-selectedRow");
      }
    },
    getAllProjects() {
      this.fetchScenarioItem();
    },
    parseQueryParamOnHomePage() {
      if (this.$route.query.embeded == "true") {
        const parsedQueryString = parseQueryStringTopbar(this.$route.query);

        this.$root.$emit("setTopbarQuery", parsedQueryString);
      }
    },
    createClicked(name) {
      if (routerEnums.DASHBOARD == name) {
        this.resetDashboardState();
      }
      this.$router.push({ name });
    },
    viewProject({ type, id }) {
      if (type === this.projectTypes.scenario)
        this.$router.push({
          name: routerEnums.SCENARIO_EDIT,
          params: { id },
        });
      else if (type === this.projectTypes.event)
        this.$router.push({
          name: routerEnums.EVENT_EDIT,
          params: { id },
        });
      else if (type === this.projectTypes.enrichment)
        this.$router.push({
          name: routerEnums.ENRICHMENT_EDIT,
          params: { id },
        });
    },
    changeViewCardOrTable() {
      this.showCardOrTable = !this.showCardOrTable;
      if (this.showCardOrTable) {
        this.iconName.changeViewIcon = CustomIcon.Hamburger;
      } else {
        this.iconName.changeViewIcon = CustomIcon.Grid;
      }
    },
    getHeaderImage() {
      var images = require.context("../assets/images/home/", false, /\.png$/);
      return images("./" + this.imageName + ".png");
    },
  },
};
</script>
<style scoped>
.authorizationPropertiesContainer {
  top: 48px;
  right: 0;
}
</style>
