
import {
    bigFontSizesArr,
    fontsArr,
    predefineColorsArr,
    fontSizesArr,
    fontWeightsArr,
    BUTTON_PROPERTIES_ENUM,
    buttonSelectionOptions,
    alignItems
} from "../../commons/dashboardProperties";

import { BUTTON_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/buttonDefaultProperties";

export default {
    computed: {
        buttonComponentsMapping() {
            return {
                [BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.button.caption",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_CAPTION_LABEL),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.button.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_FAMILY
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_CAPTION_FONT_FAMILY),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.button.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_WEIGHT
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_CAPTION_FONT_WEIGHT),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.button.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_SIZE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_CAPTION_FONT_SIZE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.button.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_COLOR
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_CAPTION_FONT_COLOR),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BORDER_RADIUS]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BORDER_RADIUS,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.borderRadius",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BORDER_RADIUS
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_CAPTION_BORDER_RADIUS),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BORDER_RADIUS,
                    componentType: this.componentTypes.INPUT,
                    inputType: "text",
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                      BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BACKGROUND_COLOR,
                      this.collapse.CARD
                    ),
                    label: this.$t("panelProperties.button.backgroundColor"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BACKGROUND_COLOR
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_CAPTION_BACKGROUND_COLOR),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    hasBorder: true,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                  },

                  [BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_SELECT_VALUE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_SELECT_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Select Action",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_SELECT_VALUE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_ACTION_SELECT_VALUE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_SELECT_VALUE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: buttonSelectionOptions,
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_APPLY_TO_TABS]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_APPLY_TO_TABS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Apply to Tabs",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_APPLY_TO_TABS
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_ACTION_APPLY_TO_TABS),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_APPLY_TO_TABS,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: this.dashboardTabsOptionList,
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_URL_VALUE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_URL_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Url",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_URL_VALUE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_ACTION_URL_VALUE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_URL_VALUE,
                    componentType: this.componentTypes.INPUT,
                    inputType: "text",
                    flexClass: false,
                },

                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE,
                        this.collapse.CHART
                    ),
                    label: "Title",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_TITLE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE,
                    componentType: this.componentTypes.INPUT,
                    inputType: "text",
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUBTITLE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUBTITLE,
                        this.collapse.CHART
                    ),
                    label: "Sub Title",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUBTITLE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SUBTITLE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUBTITLE,
                    componentType: this.componentTypes.INPUT,
                    inputType: "text",
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "Content",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CONTENT),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    inputType: "text",
                    flexClass: false,
                    textAreaRow: "6"
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.showTitle",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SHOW_TITLE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.showTitle",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SHOW_TITLE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_BORDER]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_BORDER,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.showBorder",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_BORDER
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SHOW_BORDER),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_BORDER,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_SUB_TITLE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_SUB_TITLE,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.showSubTitle",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_SUB_TITLE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SHOW_SUB_TITLE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_SUB_TITLE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_COLOR]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_COLOR,
                        this.collapse.CHART
                    ),
                    label: this.$t("dashboardPropertiesLocale.commonCard.borderColor"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_COLOR
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_BORDER_COLOR),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                  },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_RADIUS]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_RADIUS,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.borderRadius",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_RADIUS
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_BORDER_RADIUS),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_RADIUS,
                    componentType: this.componentTypes.INPUT,
                    inputType: "text",
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BACKGROUND_COLOR,
                        this.collapse.CHART
                    ),
                    label: this.$t("dashboardPropertiesLocale.general.backgroundColor"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BACKGROUND_COLOR
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_BACKGROUND_COLOR),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                  },
                
                //DIALOG TITLE PROPERTIES START
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: this.$t("dashboardPropertiesLocale.commonCard.titleFontColor"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_COLOR
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_TITLE_FONT_COLOR),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                  },
                  [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.titleFontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_FAMILY
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_TITLE_FONT_FAMILY),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.titleFontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_SIZE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_TITLE_FONT_SIZE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.titleFontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_WEIGHT
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_TITLE_FONT_WEIGHT),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },

                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_ALIGN]: {
                    id: this.getPropertyId(
                      BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_ALIGN,
                      this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.Title Align Item",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_ALIGN
                      ],
                      BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_TITLE_ALIGN
                    ),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_ALIGN,
                    size: "mini",
                    selectValues: alignItems,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                  },
                  //DIALOG TITLE PROPERTIES END

                  //DIALOG SUB TITLE PROPERTIES START
                  [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: this.$t("dashboardPropertiesLocale.commonCard.subTitleFontColor"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_COLOR
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SUB_TITLE_FONT_COLOR),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                  },
                  [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.subTitleFontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.subTitleFontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_SIZE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SUB_TITLE_FONT_SIZE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.subTitleFontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },

                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_ALIGN]: {
                    id: this.getPropertyId(
                      BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_ALIGN,
                      this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.Subtitle Align Item",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_ALIGN
                      ],
                      BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_SUB_TITLE_ALIGN
                    ),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_ALIGN,
                    size: "mini",
                    selectValues: alignItems,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                  },
                  //DIALOG SUB TITLE PROPERTIES END

                   //DIALOG CONTENT PROPERTIES START
                   [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_WRAP_TEXT]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_WRAP_TEXT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.wrapText",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_WRAP_TEXT
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CONTENT_WRAP_TEXT),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_WRAP_TEXT,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                    },
                   [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: this.$t("dashboardPropertiesLocale.commonCard.Content Font Color"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_COLOR
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CONTENT_FONT_COLOR),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                  },
                  [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.Content Font Family",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_FAMILY
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CONTENT_FONT_FAMILY),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.Content Font Size",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_SIZE
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CONTENT_FONT_SIZE),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.Content Font Weight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_WEIGHT
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CONTENT_FONT_WEIGHT),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },

                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_ALIGN]: {
                    id: this.getPropertyId(
                      BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_ALIGN,
                      this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.commonCard.Content Font Align",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_ALIGN
                      ],
                      BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CONTENT_ALIGN
                    ),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_ALIGN,
                    size: "mini",
                    selectValues: alignItems,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                  },
                  //DIALOG CONTENT PROPERTIES END

                  [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_WIDTH]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_WIDTH,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.common.width",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_WIDTH
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CARD_WIDTH),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_WIDTH,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_HEIGHT]: {
                    id: this.getPropertyId(
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_HEIGHT,
                        this.collapse.CHART
                    ),
                    label: "dashboardPropertiesLocale.common.height",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_HEIGHT
                    ], BUTTON_DEFAULT_CONFIGURATIONS.BUTTON_DIALOG_CARD_HEIGHT),
                    property: BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_HEIGHT,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
            }
        },
    },
};
