<template>
  <div
    id="jsplumb-vue2-data-flow-editor"
    @dragover.prevent
    @dragenter.prevent
    @drop="onDrop($event)"
  >
    <div class="jtk-demo-main" id="jtk-demo-flowchart">
      <div id="canvas" class="jtk-demo-canvas">
        <!-- <JsplumbVue2Controls
          ref="JsplumbVue2Controls"
          surface-id="surface"
        ></JsplumbVue2Controls> -->
        <JsplumbVue2Flowchart
          ref="flowChartComponent"
          surface-id="surfaceId"
          :editorData="editorData"
          @transition-clicked="
            (connectionId) => $emit('transition-clicked', connectionId)
          "
          @state-clicked="(index) => $emit('state-clicked', index)"
          @addNewTransition="(data) => $emit('addNewTransition', data)"
          @updatePositionsAndLevels="
            (data) => $emit('updatePositionsAndLevels', data)
          "
          @canvas-clicked="$emit('canvas-clicked')"
          @onDeleteClicked="(id) => $emit('onDeleteClicked', id)"
          @onConvertToFinishClicked="
            (id) => $emit('onConvertToFinishClicked', id)
          "
          @onConvertToStateClicked="
            (id) => $emit('onConvertToStateClicked', id)
          "
          @onEventIconClick="(id) => $emit('onEventIconClick', id)"
          @onEnrichmentIconClick="(id) => $emit('onEnrichmentIconClick', id)"
          @onRuleIconClick="(id) => $emit('onRuleIconClick', id)"
          @onActionIconClick="(id) => $emit('onActionIconClick', id)"
          @onTransitionDeleteClick="
            (id) => $emit('onTransitionDeleteClick', id)
          "
        ></JsplumbVue2Flowchart>
      </div>
    </div>
  </div>
</template>

<style>
@import "../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit.css";
@import "../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-dialogs.css";
@import "../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-demo-support.css";
@import "../../../../node_modules/@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit-connector-editors.css";
@import "../../../assets/css/flowchart.css";
</style>

<script>
import JsplumbVue2Flowchart from "./JsplumbVue2Flowchart.vue";
//import JsplumbVue2Controls from "./JsplumbVue2Controls.vue";

export default {
  name: "JsplumbVue2DataFlowEditor",
  components: {
    JsplumbVue2Flowchart,
    //JsplumbVue2Controls,
  },
  props: {
    editorData: {
      type: Object,
    },
  },
  data() {
    return {
      stateTypes: {
        state: "state",
        finish: "finish",
      },
    };
  },
  methods: {
    onDrop(evt) {
      evt.preventDefault();

      const canvasOffset =
        this.$refs.flowChartComponent.$refs.toolkitComponent.surface
          .getContainer()
          .getBoundingClientRect();
      const dropPosition = {
        left: evt.clientX - canvasOffset.left,
        top: evt.clientY - canvasOffset.top,
      };

      const dropData = JSON.parse(evt.dataTransfer.getData("text/plain"));

      if (this.stateTypes.state === dropData.dragItemName) {
        this.$emit("addNewState", dropPosition);
      } else if (this.stateTypes.finish === dropData.dragItemName) {
        this.$emit("addNewFinishState", dropPosition);
      }
    },
  },
};
</script>
<style scoped>
.jtk-demo-main {
  height: auto !important;
}
</style>
