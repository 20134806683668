import {
    chartTypes,
  } from "../commons/dashboardAndPanel";

export const getPanelDataPropertiesStyle = (panelData, key) => {
    return panelData?.properties?.style?.[key]
}

export const createSmartTitle = (chartData) => {
  
    const { aggregation, metrics } = chartData.details;
    let title = "";

    //for table chart If metrics array empty will use aggregation list
    if ((!metrics || !metrics.length) && chartData.type !== chartTypes.TABLE) {
        return null;
    }
      
    title = metrics.filter(x=> x.isAuthorize).map(i => i.alias).join(', ');

    if (aggregation?.length > 0) {
        const aggAlias = aggregation.filter(x=> x.isAuthorize).map(i => i.alias);
        if (title) title += ' by ' + aggAlias.join(', ');
        else title = aggAlias.join(', ')
    }

    return title;
}