import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import {
  formatValueController,
  getSelectedColorPaletteByProperty,
  setShowOnlyValue,
} from "../chartOptionsHelper";
import {
  GEO_MAP_CHART_PROPERTIES_ENUM,
  countryValues,
  dashboardSPEnums,
  showOnlyTypesExceptOnlyItem,
} from "../../commons/dashboardProperties";

export const getGeoMapOptions = (
  geoMapChartComputedValues,
  paramsFromSearch,
  panelData
) => {
  const canShowVisualMap = () => {
    return (paramsFromSearch?.visualMapValues?.max ||
      +geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_VALUES?.max) &&
      geoMapChartComputedValues.GEO_MAP_CHART_SHOW_VISUALMAP
      ? true
      : false;
  };
  const selectedCountry =
    geoMapChartComputedValues.GEO_MAP_CHART_SELECTED_COUNTRY;
  if (!selectedCountry) return { options: {} };

  const optionObject = {
    title: {
      text: "",
    },
    tooltip: {
      trigger: "item",
      formatter: showOnlyTypesExceptOnlyItem.includes(
        geoMapChartComputedValues.GEO_MAP_CHART_SHOW_ONLY
      )
        ? function (value) {
            value.name = handleSplittedValues(
              value?.data?.name,
              paramsFromSearch,
              panelData,
              selectedCountry
            );
            return setShowOnlyValue(
              value,
              paramsFromSearch,
              geoMapChartComputedValues,
              GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_ONLY,
              dashboardSPEnums.TOOLTIP,
              panelData
            );
          }
        : function (value) {
            return handleSplittedValues(
              value?.data?.name,
              paramsFromSearch,
              panelData,
              selectedCountry
            );
          },
    },
    visualMap: {
      show: canShowVisualMap(),
      type: geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_TYPE,
      inRange: {
        color: getSelectedColorPaletteByProperty(
          geoMapChartComputedValues.GEO_MAP_CHART_SELECTED_COLOR_PALETTE
        ),
      },
      min:
        +geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_VALUES?.min ||
        paramsFromSearch?.visualMapValues?.min ||
        0,
      max:
        +geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_VALUES?.max ||
        paramsFromSearch?.visualMapValues?.max ||
        0,
      calculable: true,
      orient: "horizontal",
      left: geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN,
      top: geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN,
      itemWidth: geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_WIDTH,
      textStyle: {
        fontWeight:
          geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT,
        fontSize: geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_FONT_SIZE,
        fontFamily:
          geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_FONT_FAMILY,
        color: geoMapChartComputedValues.GEO_MAP_CHART_VISUALMAP_FONT_COLOR,
      },
      formatter: function (value, value2) {
        // if the type of visualmap set to 'piecewise', value2 will be defined

        const serieName =
          paramsFromSearch?.metricFormatsMapping?.[
            paramsFromSearch?.dataSeriesNames?.[0]
          ];
        if (value2) {
          return (
            formatValueController(serieName, value) +
            " - " +
            formatValueController(serieName, value2)
          );
        }

        return formatValueController(serieName, value);
      },
    },
    series: paramsFromSearch?.dataSeries
      ? paramsFromSearch?.dataSeries.map((series, index) => ({
          name: paramsFromSearch?.dataSeriesNames?.[index],
          data: series?.map((i, index) => ({
            ...i,
            itemStyle: getCommonChartItemStyle(
              paramsFromSearch.mapPureLocName.get(
                paramsFromSearch?.dataAxis[index]
              ),
              panelData
            ),
          })),
          type: "map",
          roam: true,
          map: selectedCountry,
          itemStyle: {
            areaColor: "#e6e6e6",
            borderColor: geoMapChartComputedValues.GEO_MAP_CHART_BORDER_COLOR,
          },
          emphasis: {
            label: {
              show: true,
            },
          },
          label: {
            show: geoMapChartComputedValues.GEO_MAP_CHART_SHOW_VALUE_LABEL,
            fontSize:
              geoMapChartComputedValues.GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE,
            fontFamily:
              geoMapChartComputedValues.GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY,
            fontWeight:
              geoMapChartComputedValues.GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT,
            color:
              geoMapChartComputedValues.GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR,
            formatter: showOnlyTypesExceptOnlyItem.includes(
              geoMapChartComputedValues.GEO_MAP_CHART_SHOW_ONLY
            )
              ? function (value) {
                  value.name = handleSplittedValues(
                    value?.data?.name,
                    paramsFromSearch,
                    panelData,
                    selectedCountry
                  );
                  return setShowOnlyValue(
                    value,
                    paramsFromSearch,
                    geoMapChartComputedValues,
                    GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_ONLY,
                    dashboardSPEnums.LABEL,
                    panelData
                  );
                }
              : function (value) {
                  return handleSplittedValues(
                    value?.data?.name,
                    paramsFromSearch,
                    panelData,
                    selectedCountry
                  );
                },
          },
          labelLayout: {
            hideOverlap:
              geoMapChartComputedValues.GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS,
          },
        }))
      : [],
  };
  return {
    options: optionObject,
  };
};

const handleSplittedValues = (
  val,
  paramsFromSearch,
  panelData,
  selectedCountry
) => {
  if (
    !panelData?.details?.aggregation?.length ||
    (selectedCountry === countryValues.TR_DISTRICT &&
      !paramsFromSearch?.hasDistrictInAggregation ||
    !paramsFromSearch?.hasCityInAggregation)
  )
    return "";
  if (!paramsFromSearch.mapPureLocName.get(val)) return val;
  return paramsFromSearch.mapPureLocName.get(val);
};
