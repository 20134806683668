import { chartRules, panelTypes } from "../../../../commons/dashboardAndPanel";
import i18n from "@/i18n";

export const defaultType = "decimal";
export const percentType = "percent";
export const currencyType = "currency";
export const rightLocation = "right";
export const leftLocation = "left";
export const defaultSeperator = "dot";
export const defaultCurrency = "TRY";
export const defaultLocale = "tr-TR";
export const dateType = "date";
export const clockType = "time";
export const quarterType = "quarter";
export const weekType = "week";

export const defaultClockFormat = ":";
export const defaultDateFormat = ".";
export const defaultClockView = "24";
export const hour24 = "HH";
export const hour12 = "hh";
export const minute = "mm";
export const second = "ss";
export const defaultDateList = [];

export const THOUSAND_SEPERATOR_ENUM = {
  DOT: "dot",
  NONE: "none",
};

export const measureTypes = {
  NUMBER: "decimal",
  RATIO: "percent",
  CURRENCY_UNIT: "currency",
};
export const numericTypes = Object.values(measureTypes);

export const defaultNumberFormat = {
  type: defaultType,
  thousandSeperator: defaultSeperator,
  ratioSignLocation: leftLocation,
  decimal: 2,
  scale: 0,
  currency: defaultCurrency,
  currencyLocation: rightLocation,
  showScaleUnit: true,
  prefix: "",
  suffix: "",
};

export const measuresTabList = [
  { id: 1, label: measureTypes.NUMBER, text: "generalPages.number", code: "N" },
  { id: 2, label: measureTypes.RATIO, text: "generalPages.percentage", code: "P" },
  { id: 3, label: measureTypes.CURRENCY_UNIT, text: "generalPages.currency", code: "C" },
];

export const scaleList = [
  { value: 0, label: "generalPages.labelNone" },
  { value: 3, label: "generalPages.labelThousand" },
  { value: 6, label: "generalPages.labelMillion" },
  { value: 9, label: "generalPages.labelBillion" },
];

export const currencyList = [
  { value: "TRY", label: "TRY", sign: "₺", locale: "tr-TR" },
  { value: "USD", label: "USD", sign: "$", locale: "us-US" },
  { value: "EUR", label: "EUR", sign: "€", locale: "de-DE" },
];

export const thousandSeperatorList = [
  { value: THOUSAND_SEPERATOR_ENUM.DOT, label: "10.000", locale: "tr-TR" },
  { value: THOUSAND_SEPERATOR_ENUM.NONE, label: "10000", locale: "us-US" },
];

export const ratioSignLocationList = [
  { value: "left", label: "% 100" },
  { value: "right", label: "100 %" },
];

export const currencyLocationList = [
  { value: "left", label: "₺ 100" },
  { value: "right", label: "100 ₺" },
];

export const defaultDateOptions = {
  year: "YYYY",
  month: "MM",
  day: "DD",
  dayName: "ddd",
};

export const dateVariables = ["year", "month", "day"];
export const dayNameVariable = ["dayName"];

export const quarterWeekVariables = {
  week: {
    label: "Week",
    key: "W",
  },
  quarter: {
    label: "Quarter",
    key: "Q",
  },
};

export const dateList = [
  { id: 1, value: "day", label: "generalPages.day", isActive: false },
  { id: 2, value: "month", label: "generalPages.month", isActive: false },
  { id: 3, value: "year", label: "generalPages.year", isActive: false },
  { id: 4, value: "dayName", label: "generalPages.dayName", isActive: false },
  { id: 5, value: "quarter", label: "generalPages.quarter", isActive: false },
  { id: 6, value: "week", label: "generalPages.week", isActive: false },
  { id: 7, value: "time", label: "generalPages.time", isActive: false },
];
export const clockFormats = [
  {
    value: ":",
    label: ":",
  },
  {
    value: ".",
    label: ".",
  },
];

export const dateFormats = [
  {
    value: ".",
    label: ".",
  },
  {
    value: "/",
    label: "/",
  },
  {
    value: "-",
    label: "-",
  },
];

export const clockviews = [
  {
    value: "24",
    label: "generalPages.24Hours",
  },
  {
    value: "12",
    label: "Am/Pm",
  },
];

export const dateOptions = () => {
  return {
    yearOptions: [
      {
        summary: "(2022)",
        text:  i18n.tc("dateOptions.yearOptions.fullYear"),
        value: "YYYY",
      },
      {
        summary: "(22)",
        text: i18n.tc("dateOptions.yearOptions.digitYear"),
        value: "YY",
      },
    ],
    monthOptions: [
      {
        summary: i18n.t("dateOptions.monthOptions.fullMonthSummary"),
        text: i18n.t("dateOptions.monthOptions.fullMonth"),
        value: "MMMM",
      },
      {
        summary: i18n.t("dateOptions.monthOptions.3charMonthSummary"),
        text: i18n.t("dateOptions.monthOptions.3charMonth"),
        value: "MMM",
      },
      {
        summary: "(01)",
        text: i18n.t("dateOptions.monthOptions.zeroPaddedMonth"),
        value: "MM",
      },
      {
        summary: "(1)",
        text: i18n.t("dateOptions.monthOptions.month"),
        value: "M",
      },
    ],
    dayOptions: [
      {
        summary: "(01)",
        text: i18n.t("dateOptions.dayOptions.zeroPaddedDay"),
        value: "DD",
      },
      {
        summary: "(1)",
        text: i18n.t("dateOptions.dayOptions.day"),
        value: "D",
      },
    ],
    dayNameOptions: [
      {
        summary: i18n.t("dateOptions.dayNameOptions.fullNameOfDaySummary"),
        text: i18n.t("dateOptions.dayNameOptions.fullNameOfDay"),
        value: "dddd",
      },
      {
        summary: i18n.t("dateOptions.dayNameOptions.3charDaySummary"),
        text: i18n.t("dateOptions.dayNameOptions.3charDay"),
        value: "ddd",
      },
    ],
    quarterOptions: [
      {
        summary: "(1)",
        text: i18n.t("dateOptions.quarterOptions.number"),
        value: "q",
      },
      {
        summary: "(01)",
        text: i18n.t("dateOptions.quarterOptions.zeroPaddedNumber"),
        value: "qq",
      },
      {
        summary: i18n.t("dateOptions.quarterOptions.shortNameSummary"),
        text: i18n.t("dateOptions.quarterOptions.shortName"),
        value: "qqq",
      },
      {
        summary: i18n.t("dateOptions.quarterOptions.fullNameSummary"),
        text: i18n.t("dateOptions.quarterOptions.fullName"),
        value: "qqqq",
      },
      {
        summary: i18n.t("dateOptions.quarterOptions.fullNameReversedSummary"),
        text: i18n.t("dateOptions.quarterOptions.fullNameReversed"),
        value: "qqqqq",
      },
    ],
    weekOptions: [
      {
        summary: "(1)",
        text: i18n.t("dateOptions.weekOptions.number"),
        value: "w",
      },
      {
        summary: "(01)",
        text: i18n.t("dateOptions.weekOptions.zeroPaddedNumber"),
        value: "ww",
      },
      {
        summary: i18n.t("dateOptions.weekOptions.shortNameSummary"),
        text: i18n.t("dateOptions.weekOptions.shortName"),
        value: "www",
      },
      {
        summary: i18n.t("dateOptions.weekOptions.fullNameSummary"),
        text: i18n.t("dateOptions.weekOptions.fullName"),
        value: "wwww",
      },
      {
        summary: i18n.t("dateOptions.weekOptions.fullNameReversedSummary"),
        text: i18n.t("dateOptions.weekOptions.fullNameReversed"),
        value: "wwwww",
      },
    ],
    timeOptions: [
      {
        summary: "(09:15:45)",
        text: i18n.t("dateOptions.timeOptions.fullTime"),
        value: "hh:mm:ss",
      },
      {
        summary: "(09:15)",
        text: i18n.t("dateOptions.timeOptions.hourAndMinute"),
        value: "hh:mm",
      },
      {
        summary: "(09)",
        text: i18n.t("dateOptions.timeOptions.justHour"),
        value: "hh",
      },
      {
        summary: "(15)",
        text: i18n.t("dateOptions.timeOptions.justMinute"),
        value: "mm",
      },
      {
        summary: "(45)",
        text: i18n.t("dateOptions.timeOptions.justSecond"),
        value: "ss",
      },
    ],
  }
};

export const getCurrencySign = (key) =>
  currencyList.find((item) => item.label == key)?.sign;

export const checkLimitAndSetPanelFields = (panel) => {
  if (!panel) {
    return null;
  }

  const isChartTypeFilter = panel.type?.toLowerCase()?.includes(panelTypes.FILTER);
  const rules = chartRules[panel.type];
  //for not filter chart
  if (rules && !isChartTypeFilter) {
    panel.details.aggregation = panel.details.aggregation
      .filter((i) => rules.boxKeys.includes(i.boxKey))
      .slice(rules.maxSliceCount * -1);
    panel.details.metrics = panel.details.metrics
      .filter((i) => rules.boxKeys.includes(i.boxKey))
      .slice(rules.maxMeasureCount * -1);
  }
  //for filter chart
  else if (rules && isChartTypeFilter) {
    // "i.fieldUsageType === undefined" filter paramlar için yazıldı. Filter paramlarda usageType yok.
    panel.details.metrics = panel.details.metrics
      .filter(
        (i) =>
          rules.boxKeys.includes(i.boxKey) &&
          (rules.fieldTypesToUse.includes(i.fieldUsageType) || i.fieldUsageType === undefined)
      )
      .slice(rules.maxMeasureCount * -1);
  }
};
