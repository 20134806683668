var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"vis-settings-tab-link"},[(_vm.showDataModelLink)?_c('li',{staticClass:"link-item",class:{ active: _vm.activeSettingTab == _vm.SettingsLeftTabLinkEnum.DataModel }},[_c('span',{on:{"click":function($event){return _vm.$emit('setSettingLeftTab', _vm.SettingsLeftTabLinkEnum.DataModel)}}},[_vm._v(_vm._s(_vm.$t("generalPages.dataModel")))])]):_vm._e(),(_vm.showParametersLink)?_c('li',{staticClass:"link-item",class:{
      active: _vm.activeSettingTab == _vm.SettingsLeftTabLinkEnum.Parameters,
    }},[_c('span',{on:{"click":function($event){return _vm.$emit('setSettingLeftTab', _vm.SettingsLeftTabLinkEnum.Parameters)}}},[_vm._v(_vm._s(_vm.$t("generalPages.Parameters")))])]):_c('li',{staticClass:"link-item",class:[
      {
        active: _vm.activeSettingTab == _vm.SettingsLeftTabLinkEnum.Visualize,
      },
      {
        disabled: _vm.activeSettingTab === '',
      },
    ]},[_c('span',{on:{"click":function($event){return _vm.$emit('setSettingLeftTab', _vm.SettingsLeftTabLinkEnum.Visualize)}}},[_vm._v(_vm._s(_vm.$t("generalPages.visualize")))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }