<template>
  <div class="vis-chart-filter">
    <el-input-number
      :value="filterValue"
      controls-position="right"
      @change="addFiltersToPanels"
      :min="1"
      :disabled="disabledNumberField"
    ></el-input-number>
  </div>
</template>
<script>
import {
  filterOperator,
  filterPropKey,
} from "../../../commons/filterComponents";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
export default {
  mixins: [panelDataPropertiesStyle],
  props: {
    panelData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    disabledNumberField() {
      return !this.panelDataDetailsMetrics.length;
    },
  },
  methods: {
    addFiltersToPanels(e) {
      const params = {
        value: e,
        property: filterPropKey.FILTER_VALUE,
        operator: filterOperator.EQ,
        panelI: this.panelData.i,
      };
      this.$emit("addFiltersToPanels", params);
    },
  },
};
</script>
