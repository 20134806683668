export const parseQueryStringTopbar = (queryParam) => {

    const routeQueryParam = { ...queryParam }

    const parsedQueryString = {};

    Object.keys(routeQueryParam).forEach(element => {
        if (routeQueryParam[element].toLowerCase() === 'true') {
            parsedQueryString[element] = true
        } else if (routeQueryParam[element].toLowerCase() === 'false') {
            parsedQueryString[element] = false
        }
    });

    if (!parsedQueryString.embeded) return;

    return parsedQueryString
}

export const getQueryStringParam = (topbarQueryParams, getterTopbarQueryParams) => {

    if (getterTopbarQueryParams?.embeded) {
        topbarQueryParams = {
            ...topbarQueryParams,
            ...getterTopbarQueryParams,
        };
    }

    return topbarQueryParams;
}

export const queryParamKeys = {
    embeded: "embeded",
    appLogo: "appLogo",
    dashboardName: "dashboardName",
    notificationMenu: "notificationMenu",
    profileMenu: "profileMenu",
    topBar: "topBar"
}

export const settingsBarParamKeys = {
    embeded: "embeded",
    resize: "resize",
    close: "close",
    filter: "filter",
    settingsBar: "settingsBar",
    verticalThreeDot: "verticalThreeDot"
}