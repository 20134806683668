<template>
  <div style="width: 100%; height: 100%; position: relative">
    <jsplumb-toolkit
      ref="toolkitComponent"
      v-bind:render-params="renderParams"
      v-bind:view="view"
      id="toolkit"
      :surface-id="surfaceId"
    ></jsplumb-toolkit>
    <jsplumb-miniview :surface-id="surfaceId"></jsplumb-miniview>
  </div>
</template>

<script>
// jsplumb imports

import {
  ForceDirectedLayout,
  LassoPlugin,
  DrawingToolsPlugin,
  DotEndpoint,
  EVENT_CLICK,
  EVENT_DBL_CLICK,
  EVENT_EDGE_ADDED,
  EVENT_CANVAS_CLICK,
  EVENT_ZOOM,
  StraightConnector,
  EVENT_GRAPH_CLEARED,
  //   EVENT_PAN,
} from "@jsplumbtoolkit/browser-ui";
import { getSurface } from "@jsplumbtoolkit/browser-ui-vue2";

// local imports
import JsplumbVue2DatasetNode from "./JsplumbVue2DatasetNode.vue";
import { CustomIcon } from "../../../assets/js/custom-icons";
import { Notification } from "element-ui";
import _ from "lodash";

let toolkitComponent;
let toolkit;

export default {
  name: "JsplumbVue2Flowchart",
  props: {
    surfaceId: {
      type: String,
      required: false,
    },
    editorData: {
      type: Object,
    },
    positionsAndLevels: {
      type: Object,
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      joinTypes: {
        Inner: "inner",
        Left: "left",
        Right: "right",
        Full: "full",
      },
      toolkitLoaded: false,
      renderParams: {
        layout: {
          type: ForceDirectedLayout.type,
        },
        events: {
          [EVENT_CANVAS_CLICK]: () => {
            //deselect all kısmı
            this._getSurface((s) => {
              s.getConnectionsForEdges(toolkit.getAllEdges()).forEach((con) => {
                con.connector.connection.setPaintStyle(
                  this.view.edges.default.paintStyle
                );
                con.connector.connection.setVisible(false);
                con.connector.connection.setVisible(true);
              });
            });
            const datasetNodes = document.getElementsByClassName(
              "dataset-node-container"
            );
            for (let i = 0; i < datasetNodes.length; i++) {
              const selectedField =
                datasetNodes[i].getElementsByClassName("dataset-node");
              selectedField[0].style.border = "";
            }
            this.showTheGeneralDataModelPropertyOnTheRight(true);
            toolkit.clearSelection();
          },
          [EVENT_ZOOM]: (z) => {
            if (z.zoom !== z.oldZoom && this.toolkitLoaded) {
              setTimeout(() => {
                // console.log("EVENT_ZOOM", z, this.toolkitLoaded);
                this.onPositionsAndLevelsUpdated();
              }, 300);
            }
          },
          //   [EVENT_PAN]: (p) => {
          //     console.log("EVENT_PAN", p);
          //   },
        },
        consumeRightClick: false,
        dragOptions: {
          filter: ".jtk-draw-handle, .node-action, .node-action i",
        },
        plugins: [
          DrawingToolsPlugin.type,
          {
            type: LassoPlugin.type,
            options: {
              invert: true,
            },
          },
        ],
        grid: {
          size: {
            w: 20,
            h: 20,
          },
        },
        magnetize: {
          afterDrag: true,
        },
      },
      view: {
        nodes: {
          dataset: {
            component: JsplumbVue2DatasetNode,
            events: {
              [EVENT_CLICK]: (e) => {
                this.onDatasetClicked(e);
                this.onPositionsAndLevelsUpdated();
                this.showTheGeneralDataModelPropertyOnTheRight(false);
              },
            },
          },
        },
        edges: {
          default: {
            anchor: "AutoDefault",
            endpoint: {
              type: DotEndpoint.type,
              options: {
                radius: 5,
              },
            },
            connector: { type: StraightConnector.type },
            // paint style for this edge type.
            paintStyle: {
              strokeWidth: 2,
              stroke: "rgb(132, 172, 179)",
              outlineWidth: 3,
              outlineStroke: "transparent",
            },
            // hover paint style for this edge type.
            hoverPaintStyle: {
              strokeWidth: 5,
              stroke: "rgb(132, 172, 179)",
              outlineWidth: 8,
              outlineStroke: "transparent",
            },
            events: {
              /*click: (p) => {
              },*/
            },
          },
          datasetJoin: {
            parent: "default",
            overlays: [
              {
                type: "Custom",
                options: {
                  create: (p) => {
                    const joinTypeDiv = document.createElement("div");

                    this.setJoin(
                      joinTypeDiv,
                      p.data.relationType?.toLowerCase(),
                      p.sourceId,
                      p.targetId,
                      p.data.connectionId
                    );

                    return joinTypeDiv;
                  },
                  events: {
                    [EVENT_CLICK]: (overlayClickParams) => {
                      const selectedEdge = toolkit.getSelection().getEdges()[0];

                      //deselect all kısmı
                      this._getSurface((s) => {
                        s.getConnectionsForEdges(toolkit.getAllEdges()).forEach(
                          (con) => {
                            con.connector.connection.setPaintStyle(
                              this.view.edges.default.paintStyle
                            );
                            con.connector.connection.setVisible(false);
                            con.connector.connection.setVisible(true);
                          }
                        );
                      });
                      toolkit.clearSelection();

                      if (
                        selectedEdge?.data?.connectionId !==
                        overlayClickParams.connection.data.connectionId
                      ) {
                        //select kısmı
                        toolkit.setSelection(
                          overlayClickParams.connection.edge.id
                        );
                        this._getSurface((s) => {
                          s.getConnectionsForEdges(
                            toolkit.getAllEdges()
                          ).forEach((con) => {
                            if (
                              con.connector.connection.data.connectionId ===
                              overlayClickParams.connection.data.connectionId
                            ) {
                              con.connector.connection.setPaintStyle(
                                this.view.edges.default.hoverPaintStyle
                              );
                            }
                          });
                        });
                      }
                    },
                    [EVENT_DBL_CLICK]: (overlayClickParams) => {
                      const connection = overlayClickParams.connection;
                      const allLinks = this.editorData.edges.filter(
                        (e) =>
                          e.source === connection.sourceId &&
                          e.target === connection.targetId
                      );
                      const showConnectionPopupData = allLinks.map((link) => {
                        return {
                          id: link.data.connectionId,
                          leftDatasetId: connection.sourceId,
                          rightDatasetId: connection.targetId,
                          leftDatasetFieldId: link.data.leftDatasetFieldId,
                          rightDatasetFieldId: link.data.rightDatasetFieldId,
                          relationType: link.data.relationType,
                        };
                      });

                      this.$emit(
                        "showConnectionPopup",
                        showConnectionPopupData
                      );
                    },
                  },
                },
              },
            ],
          },
        },

        ports: {
          start: {
            edgeType: "default",
          },
          source: {
            maxConnections: -1,
            edgeType: "datasetJoin",
          },
          target: {
            maxConnections: -1,
            isTarget: true,
          },
        },
      },
    };
  },

  beforeDestroy() {
    this.toolkitLoaded = false;
  },

  watch: {
    editorData: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (_.isEqual(newVal, oldVal)) {
          return;
        }

        if (toolkit) {
          toolkit.clear();
        }
      },
    },
  },

  mounted() {
    this.initializeToolkit();

    toolkit.bind(EVENT_EDGE_ADDED, (e) => {
      if (e.addedByMouse) {
        const alreadyAdded = this.isJoinAlreadyAdded(
          e.edge.source.id,
          e.edge.target.id
        );

        if (!alreadyAdded) {
          if (
            e.edge.source.data.connectionType ===
            e.edge.target.data.connectionType
          ) {
            if (e.edge.source.id !== e.edge.target.id) {
              this.$emit("showConnectionPopup", [
                {
                  id: undefined,
                  leftDatasetId: e.edge.source.id,
                  rightDatasetId: e.edge.target.id,
                  leftDatasetFieldId: undefined,
                  rightDatasetFieldId: undefined,
                  relationType: undefined,
                },
              ]);
            } else {
              this._getSurface((s) => {
                setTimeout(() => {
                  s.toolkitInstance.undo();
                  s.toolkitInstance.flushUndoRedo();

                  const resp = {
                    message: this.$t("datamodel.noJoinAddedToDatasetItself"),
                    data: "",
                    duration: 3000,
                    customClass: "center vis-notification",
                    title: this.$t("notifyTitle.warning"),
                    type: "warning",
                  };

                  Notification(resp);
                }, 300);
              });
            }
          } else {
            this._getSurface((s) => {
              setTimeout(() => {
                s.toolkitInstance.undo();
                s.toolkitInstance.flushUndoRedo();

                const resp = {
                  message: this.$t(
                    "datamodel.You can not join datasets with different connections"
                  ),
                  data: "",
                  duration: 3000,
                  customClass: "center vis-notification",
                  title: this.$t("notifyTitle.warning"),
                  type: "warning",
                };

                Notification(resp);
              }, 300);
            });
          }
        } else {
          this._getSurface((s) => {
            setTimeout(() => {
              s.toolkitInstance.undo();
              s.toolkitInstance.flushUndoRedo();

              const allLinks = this.editorData.edges.filter(
                (edge) =>
                  (edge.source === e.edge.source.id &&
                    edge.target === e.edge.target.id) ||
                  (edge.source === e.edge.target.id &&
                    edge.target === e.edge.source.id)
              );
              const showConnectionPopupData = allLinks.map((link) => {
                return {
                  id: link.data.connectionId,
                  leftDatasetId: link.source,
                  rightDatasetId: link.target,
                  leftDatasetFieldId: link.data.leftDatasetFieldId,
                  rightDatasetFieldId: link.data.rightDatasetFieldId,
                  relationType: link.data.relationType,
                };
              });

              this.$emit("showConnectionPopup", showConnectionPopupData);
            }, 300);
          });
        }
      }
    });

    window.addEventListener(
      "keydown",
      (e) => {
        const selected = toolkit.getSelection().getEdges()?.[0]
          ?.data?.connectionId;
        if (
          (e.key === "Backspace" || e.key === "Delete") &&
          e.target.localName !== "input" &&
          selected
        ) {
          const selectedConnection = toolkit.getSelection().getEdges()?.[0];
          const selectedConnections = this.checkAllConnections(
            selectedConnection?.source?.id,
            selectedConnection?.target?.id
          );
          if (selectedConnections) {
            this.$emit("onRemoveConnection", selectedConnections);
            // e.stopImmediatePropagation();
          }
        }
      },
      true
    );
  },

  methods: {
    // a wrapper around getSurface, which expects a callback, as the surface may or may not have been
    // initialised when calls are made to it.
    _getSurface(cb) {
      getSurface(this.surfaceId, cb);
    },

    onDatasetClicked(e) {
      this.$emit("onDatasetClicked", e.obj.id);
    },
    onShowPreview(id) {
      this.$emit("onShowPreview", id);
    },
    onEditClicked(id) {
      this.$emit("onEditClicked", id);
    },
    onDeleteClicked(id) {
      this.$emit("onDeleteClicked", id);
    },
    onAppendClicked(id) {
      this.$emit("onAppendClicked", id);
    },
    onReplaceClicked(id) {
      this.$emit("onReplaceClicked", id);
    },
    onDuplicateClicked(id) {
      this.$emit("onDuplicateClicked", id);
    },
    showTheGeneralDataModelPropertyOnTheRight(isShow) {
      this.$emit("onShowDataModelProperties", isShow);
    },
    onPositionsAndLevelsUpdated() {
      let datasetCoords = [];
      let zoomLevel = 1;

      const datasetNodes = document.getElementsByClassName(
        "dataset-node-container"
      );

      for (let i = 0; i < datasetNodes.length; i++) {
        datasetCoords.push({
          source_id: datasetNodes[i].getAttribute("data-jtk-managed"),
          source_css: datasetNodes[i].style.cssText,
        });
      }

      this._getSurface((s) => {
        zoomLevel = s.getZoom();
      });
      this.$emit("updatePositionsAndLevels", { datasetCoords, zoomLevel });
    },
    setJoin(joinTypeDiv, type, leftDatasetId, rightDatasetId, connectionId) {
      const connectionImage = type
        ? CustomIcon[
            "Join" + type.charAt(0).toUpperCase() + type.toLowerCase().slice(1)
          ]
        : null;
      const nodes = this.editorData?.nodes;
      let leftDatasetName = "";
      let rightDatasetName = "";

      if (connectionImage != null) {
        leftDatasetName =
          nodes?.find((n) => n.id === leftDatasetId)?.name ?? "";
        rightDatasetName =
          nodes?.find((n) => n.id === rightDatasetId)?.name ?? "";
      }
      const connectionIds = this.checkAllConnections(
        leftDatasetId,
        rightDatasetId
      );

      const deleteButtonId = `connection-delete-${connectionId}`;

      joinTypeDiv.innerHTML = `
        <div class="connection-middle-container">
            <div class="connection-middle-container-info-box">
                <div class="connection-middle-container-side">
                    <span class="connection-middle-left" title="${leftDatasetName}">${leftDatasetName}</span>
                    <hr/>
                </div>
                <i
                    class="connection-middle-delete vis-cursor-pointer ${CustomIcon.TrashCanOutline}"
                    aria-hidden="true"
                    data-attr-connection="${connectionIds}"
                    id="${deleteButtonId}"
                ></i>
                <div class="connection-middle-container-side">
                    <hr/>
                    <span class="connection-middle-right" title="${rightDatasetName}">${rightDatasetName}</span>
                </div>
            </div>
            <i class="dataset-node-connection-action-icon vis-cursor-pointer ${connectionImage}"aria-hidden="true"></i>
        </div>
        `;

      this.$nextTick(() => {
        const connectionDeleteButton = document.getElementById(deleteButtonId);
        const _this = this;

        if (connectionDeleteButton) {
          connectionDeleteButton.addEventListener(
            "click",
            function () {
              const connectionIdsToDelete = this.getAttribute(
                "data-attr-connection"
              );

              if (connectionIdsToDelete) {
                const deleteJoinIds = connectionIdsToDelete.split(",");

                _this.$emit("onRemoveConnection", deleteJoinIds);
              }
            },
            false
          );
        }
      });
    },
    showConnectionPopup(connection) {
      this.$emit("showConnectionPopup", [
        {
          id: connection.data.connectionId,
          leftDatasetId: connection.sourceId,
          rightDatasetId: connection.targetId,
          leftDatasetFieldId: connection.data.leftDatasetFieldId,
          rightDatasetFieldId: connection.data.rightDatasetFieldId,
          relationType: connection.data.relationType,
        },
      ]);
    },
    isJoinAlreadyAdded(sourceId, targetId) {
      let alreadyAdded = false;
      const allEdgesExceptLastAdded = toolkit
        .getAllEdges()
        .slice(0, toolkit.getAllEdges().length - 1);

      allEdgesExceptLastAdded?.forEach((e) => {
        if (
          (e.source.id === sourceId && e.target.id === targetId) ||
          (e.source.id === targetId && e.target.id === sourceId)
        ) {
          alreadyAdded = true;
        }
      });

      return alreadyAdded;
    },
    checkAllConnections(sourceId, targetId) {
      const connectionIds = [];
      const allEdgesExceptLastAdded = toolkit.getAllEdges();

      allEdgesExceptLastAdded?.forEach((e) => {
        if (e.source.id === sourceId && e.target.id === targetId) {
          connectionIds.push(e.data.connectionId);
        }
      });
      return connectionIds;
    },
    initializeToolkit() {
      toolkitComponent = this.$refs.toolkitComponent;
      toolkit = toolkitComponent.toolkit;

      toolkit.bind(EVENT_GRAPH_CLEARED, () => {
        if (toolkit.getNodeCount() === 0) {
          this.appendToolkitData();
        }
      });

      this.loadToolkitData();
    },
    getNodesWithCoords() {
      return this.editorData?.nodes?.map((node) => {
        const coord = this.positionsAndLevels?.datasetCoords?.find(
          (coord) => coord.source_id === node.id
        );
        const sourceCssLeft = coord?.source_css
          ? coord.source_css.split(";")[0].split(": ")[1].replace("px", "")
          : 0;
        const sourceCssTop = coord?.source_css
          ? coord.source_css.split(";")[1].split(": ")[1].replace("px", "")
          : 0;

        return {
          ...node,
          left: sourceCssLeft,
          top: sourceCssTop,
        };
      });
    },
    loadToolkitData() {
      if (toolkit && this.editorData?.nodes && this.editorData?.edges) {
        this._getSurface((s) => {
          s.setZoom(this.positionsAndLevels?.zoomLevel ?? 1);
        });

        try {
          toolkit.load({
            type: "json",
            data: {
              nodes: this.getNodesWithCoords(),
              edges: this.editorData.edges,
            },
          });
          this.toolkitLoaded = true;
        } catch (e) {
          console.log("Load error", e);
        }
      }
    },
    appendToolkitData() {
      if (toolkit && this.editorData?.nodes && this.editorData?.edges) {
        this._getSurface((s) => {
          s.setZoom(this.positionsAndLevels?.zoomLevel ?? 1);
        });

        try {
          toolkit.load({
            type: "json",
            data: {
              nodes: this.getNodesWithCoords(),
              edges: this.editorData.edges,
            },
          });
          this.toolkitLoaded = true;
        } catch (e) {
          console.log("Append error", e);
        }
      }
    },
  },
};
</script>

<style>
.connection-middle-icon {
  -webkit-transition: background-color 0.25s ease-in;
  -moz-transition: background-color 0.25s ease-in;
  transition: background-color 0.25s ease-in;
}
.connection-middle-icon {
  color: white;
  padding: 0.3em;
  cursor: pointer;
}
.dataset-node-connection-action-icon {
  color: #004de6;
  font-size: 40px !important;
  padding: 5px;
}
.connection-middle-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.connection-middle-container-side {
  width: 110px;
  display: flex;
  align-items: center;
}
.connection-middle-container-info-box {
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3d62f8;
  border-radius: 5px;
  display: none;
  position: absolute;
  top: -25px;
  padding: 0 5px;
}
.connection-middle-left {
  color: black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.connection-middle-delete {
  color: #004de6;
  font-size: 20px !important;
}
.connection-middle-right {
  color: black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.connection-middle-container:hover .connection-middle-container-info-box {
  display: flex !important;
  align-items: center;
  padding: 12px;
}

.jtk-miniview-element {
  background-color: var(--primary-lighteen-1) !important;
}

hr {
  min-width: 10px;
  height: 0;
  border: 1px solid #707070;
  display: inline-block;
  margin: 3px;
  flex: 1;
}
</style>
