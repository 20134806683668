import {
    rightLocation,
    defaultSeperator,
    percentType,
    currencyType,
    defaultCurrency,
    defaultLocale,
    getCurrencySign,
    dateType,
    defaultDateOptions,
    dateVariables,
    dayNameVariable,
    quarterWeekVariables,
    numericTypes,
    clockType,
    quarterType,
    weekType,
    defaultClockView,
    hour12, hour24, minute, second, scaleList,
    defaultType
} from "./measures";
import i18n from "@/i18n";


import moment from "moment";

export const formatMeasure = (value, format) => {
    if (value == undefined) return ""

    let formattedValue = value;

    if (format?.type == dateType && format?.dateList?.length) {

        let formatString = ""
        let customString = ""
        for (let i = 0; i < format?.dateList?.length; i++) {
            let date = format?.dateList[i];
            let dateAfter = format?.dateList[i + 1] ?? "";

            if (dateVariables.includes(date)) {
                if (format?.[date]) formatString += format?.[date];
                else formatString += defaultDateOptions?.[date];

                if (dateVariables.includes(dateAfter))
                    formatString += format?.dateFormat
            }
            if (date == dayNameVariable) {
                if (format?.[date]) formatString += format?.[date];
                else formatString += defaultDateOptions?.[date];
            }

            if (date == clockType) {
                const timeFormat = format?.time;
                const hourView = format?.clockView == defaultClockView ? hour24 : hour12;
                const clockView = format?.clockView == defaultClockView ? '' : 'a';
                switch (timeFormat) {
                  case 'hh:mm:ss':
                    formatString += hourView + format?.clockFormat + minute + format?.clockFormat + second + clockView;
                    break;
                  case 'hh:mm':
                    formatString += hourView + format?.clockFormat + minute + clockView;
                    break;
                  case 'hh':
                    formatString += hourView + clockView;
                    break;
                  case 'mm':
                    formatString +=  minute + clockView;
                    break;
                  case 'ss':
                    formatString += second + clockView;
                    break;
                  default:
                    formatString += hourView + format?.clockFormat + minute + clockView;
                    break;
                }
            }

            if (Object.keys(quarterWeekVariables).includes(date)) {
                let val = 0;
                let result = "";
                if (date == quarterType) {
                    val = moment(value).utc().quarter()
                }
                if (date == weekType) {
                    val = moment(value).utc().week()
                }

                switch (format?.[date]?.length) {
                    case 1:
                        result = val;
                        break;
                    case 2:
                        result = val % 10 == val ? "0" + val : val;
                        break;
                    case 3:
                        result = quarterWeekVariables[date].key + val;
                        break;
                    case 4:
                        result = quarterWeekVariables[date].label + " " + val;
                        break;
                    case 5:
                        result = val + "." + quarterWeekVariables[date].label;
                        break;
                    default:
                        result = val;
                        break;
                }

                customString += " " + result;
            }

            if (dateAfter?.length && !dateVariables.includes(date) || (dateVariables.includes(date) && !dateVariables.includes(dateAfter))) {
                formatString += " "
            }
        }
        const formattedDateVal = formatString.length ? moment.utc(value).format(formatString).toString() : ""
        formattedValue = formattedDateVal + customString
    }
    else if (numericTypes.includes(format?.type)) {
        formattedValue = formatNumberValues(formattedValue, format);

        if(format?.type == defaultType) {
            formattedValue = (format?.prefix ? format?.prefix + " " : "") + 
            formattedValue + 
            (format?.suffix ? " " + format?.suffix : "")
        }
        if (format?.type == percentType) {
            if (format?.ratioSignLocation == rightLocation) formattedValue = formattedValue + " %"
            else formattedValue = "% " + formattedValue
        }
        else if (format?.type == currencyType) {
            const curr = getCurrencySign(format?.currency) ?? defaultCurrency
            if (format?.currencyLocation == rightLocation) formattedValue = formattedValue + " " + curr
            else formattedValue = curr + " " + formattedValue
        }

    }

    if (Math.sign(formattedValue) === 0) return 0;
    if(Number.isNaN(formattedValue)) return value;

    return formattedValue?.length ? formattedValue : value;
}

export const formatNumberValues = (value, format) => {
    const formatInstance = new Intl.NumberFormat(
        defaultLocale,
        {
            maximumFractionDigits: format?.decimal,
            useGrouping: format?.thousandSeperator == defaultSeperator,
        }
    )

    const formattedValue = format?.scale ?
        formatInstance.format(value / Math.pow(10, format?.scale)) :
        formatInstance.format(value);

    return setScalePrefix(formattedValue, format);
}

const setScalePrefix = (value, format) => {
    if (!format?.scale || !format?.showScaleUnit) {
        return value;
    }

    const scale = scaleList.find(i => i.value === format.scale);

    if (!scale) {
        return value;
    }

    return value + " " + i18n.t(`numberScales.${scale.label}`);
}