/* eslint-disable */
import { ACTION, GETTER, STATE, MUTATION } from "./types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { MUTATION_GENERAL } from "../../Visualize/General/types";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const ingestDataFlow = {
  state: {
    [STATE.DATAFLOWS]: [],
    [STATE.SELECTED_DATAFLOW_DETAILS]: [],
    [STATE.JOBS]: [],
    [STATE.RDB_PREVIEW]: {},
  },
  getters: {
    [GETTER.GET_DATAFLOWS]: (state) => {
      return state[STATE.DATAFLOWS];
    },
    [GETTER.GET_SELECTED_DATAFLOW_DETAILS]: (state) => {
      return state[STATE.SELECTED_DATAFLOW_DETAILS];
    },
    [GETTER.GET_JOBS]: (state) => {
      return state[STATE.DATAFLOWS];
    },
    [GETTER.GET_RDB_PREVIEW]: (state) => {
      return state[STATE.RDB_PREVIEW];
    },
  },
  actions: {
    [ACTION.FETCH_DATAFLOWS]: async ({ commit }, { folderId }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.readDataFlows,
        queryParam: folderId,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        successMutation: MUTATION.SET_DATAFLOWS,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.FETCH_DATAFLOW_DETAILS_BY_ID]: async ({ commit }, id) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.readDataFlowDetailsById,
        queryParam: id,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        successMutation: MUTATION.SET_SELECTED_DATAFLOW_DETAILS,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.DELETE_DATAFLOW_BY_ID]: async ({ commit }, { queryParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.deleteDataFlowById,
        queryParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        successMutation: MUTATION.SET_DATAFLOWS,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },

    [ACTION.UPDATE_DATAFLOW_NAME_AND_DESCRIPTION]: async (
      { commit },
      { dataFlowId, bodyParam }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.updateDataFlowNameAndDescription,
        queryParam: dataFlowId,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },

    [ACTION.POST_RDB_PREVIEW]: async ({ commit }, { bodyParam, jobId, withSuccessNotify }) => {
      const result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.postRdbPreview,
        bodyParam,
        withNotify: true,
        withSuccessNotify,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });

      commit(MUTATION.SET_RDB_PREVIEW, { jobId, result });

      return result;
    },

    [ACTION.POST_RDB_SAVE]: async ({ commit }, { bodyParam, loadingComponent }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.postRdbSave,
        bodyParam,
        withNotify: true,
        withLoading: true,
        loadingComponent,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },

    [ACTION.PUT_RDB_SAVE]: async ({ commit }, { dataflowId, bodyParam, loadingComponent }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.putRdbSave,
        queryParam: dataflowId,
        bodyParam,
        withNotify: true,
        withLoading: true,
        loadingComponent,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },

    [ACTION.POST_RDB_RUN]: async ({ commit }, { bodyParam }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.postRdbRun,
        bodyParam,
        withNotify: true,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },

    [ACTION.READ_RDB_STATUS]: async ({ commit }, id) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.readRdbStatus,
        queryParam: id,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        successMutation: MUTATION.SET_SELECTED_DATAFLOW_DETAILS,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.MOVE_DATAFLOW]: async ({ commit }, { itemId, targetFolderId }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataFlow,
        serviceMethodSub: serviceMethodSub.moveDataFlow,
        queryParam: { itemId, targetFolderId: targetFolderId ?? "" },
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.FETCH_HIGHEST_PRIORITY_BY_DATAFLOW_ID]: async ({ commit }, { dataFlowId }) => {
      return await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.DataFlow,
          serviceMethodSub: serviceMethodSub.readHighestPriorityByDataFlowId,
          queryParam: dataFlowId,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
        },
      );
    },
  },
  mutations: {
    [MUTATION.SET_DATAFLOWS](state, items) {
      Vue.set(state, [STATE.DATAFLOWS], items);
    },
    [MUTATION.SET_SELECTED_DATAFLOW_DETAILS](state, item) {
      Vue.set(state, [STATE.SELECTED_DATAFLOW_DETAILS], item);
    },
    [MUTATION.SET_JOBS](state, items) {
      Vue.set(state, [STATE.JOBS], items);
    },
    [MUTATION.SET_RDB_PREVIEW](state, { jobId, result }) {
      Vue.set(state[STATE.RDB_PREVIEW], jobId, result);
    },
  },
};
