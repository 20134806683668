<template>
  <div class="vis-flex-scrollbar">
    <HomeScenarioV2 v-if="selectedModule === Modules.SCENARIO" />
    <HomeVisualize v-else />
  </div>
</template>

<script>
import HomeVisualize from "./HomeVisualize.vue";
import HomeScenarioV2 from "./HomeScenarioV2.vue";
import eventBus from "../eventBus";
import { Modules } from "../commons/modules";

export default {
  components: {
    HomeVisualize,
    HomeScenarioV2,
  },
  data() {
    return {
      Modules: Modules,
      selectedModule: null,
    };
  },
  mounted() {
    this.selectedModule = localStorage.getItem("selectedModule");

    eventBus.$on("onSelectedModuleChanged", () => {
      this.selectedModule = localStorage.getItem("selectedModule");
    });
  },
};
</script>
