<template>
  <div class="scenario-aside-properties-event-tab">
    <span>{{ $t("Define the rule for this transition") }}</span>
    <div class="vis-mt--1 vis-mb--1">
      <label>{{ $t("Rule") }}</label>
      <ScenarioRuleEditor
        :value="properties.expression"
        :eventFields="selectedEventFields"
        :enrichments="selectedEnrichments"
        @input="(v) => $emit('updateExpression', v)"
      />
    </div>
    <!--<div class="scenario-aside-properties-event-tab-footer">
      <el-link
        class="scenario-aside-properties-event-tab-check-button"
        :underline="false"
        >Check</el-link
      >
      <span class="scenario-aside-properties-event-tab-expression-result"
        >Expression succesful</span
      >
    </div>-->
  </div>
</template>

<script>
import ScenarioRuleEditor from "./components/ScenarioRuleEditor.vue";

export default {
  components: {
    ScenarioRuleEditor,
  },
  props: {
    properties: {
      type: Object,
    },
    events: {
      type: Array,
    },
    enrichmentOptions: {
      type: Array,
    },
  },
  computed: {
    selectedEvent() {
      return this.events.find((e) => e.name === this.properties.event);
    },
    selectedEventFields() {
      return this.selectedEvent?.fields?.map((f) => f.field) ?? [];
    },
    selectedEnrichments() {
      return (
        this.properties?.enrichments?.map((e) => {
          const option = this.enrichmentOptions?.find(
            (eo) => eo.enrichmentId === e.enrichmentId
          );

          return option.name;
        }) ?? []
      );
    },
  },
};
</script>

<style scoped>
.label-disabled {
  font: normal normal 400 13px/20px Poppins !important;
  letter-spacing: 0px;
  color: #3e3e3e;
}
label {
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
}
.scenario-aside-properties-event-tab-check-button {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0014ff !important;
}
.scenario-aside-properties-event-tab-footer {
  display: flex;
}
.scenario-aside-properties-event-tab-check-button:hover {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0014ff !important;
}
.scenario-aside-properties-event-tab-expression-result {
  font: normal normal 500 13px/20px Poppins;
  letter-spacing: 0px;
  color: #00b10d;
  margin-left: auto;
}
</style>
