import {
  BOX_KEY_ENUM,
  chartTypes,
  dashboardFilterTypes,
} from "../commons/dashboardAndPanel";
import { countryValues } from "../commons/dashboardProperties";
import {
  BOX_OPTIONS,
  DatamodelContextDefaults,
} from "../commons/dataModelTypes";

const colorByBox = {
  title: "generalPages.colorBy",
  boxKey: BOX_KEY_ENUM.COLORBY.KEY,
  usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
}

const commonChartBoxes = [
  {
    title: "generalPages.measures",
    boxKey: BOX_KEY_ENUM.MEASURES.KEY,
    usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
  },
  {
    title: "generalPages.slices",
    boxKey: BOX_KEY_ENUM.SLICES.KEY,
    usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
  }
];
const commonFilterChartBoxes = [
  {
    title: "generalPages.dataItems",
    boxKey: BOX_KEY_ENUM.DATA_ITEM.KEY,
    usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
  },
];
export const chartBoxes = ({isShowSlot}) => {
 return [
  {
    chartType: chartTypes.SUMMARY,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.Target",
        boxKey: BOX_KEY_ENUM.TARGET.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
    ],
  },
  {
    chartType: chartTypes.SCATTER_CHART,
    boxes: [
      {
        title: "generalPages.points",
        boxKey: BOX_KEY_ENUM.POINTS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      {
        title: "generalPages.xAxis",
        boxKey: BOX_KEY_ENUM.XAXIS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        boxOptions: [BOX_OPTIONS.SINGLE_METRIC, BOX_OPTIONS.SINGLE_TYPE],
      },
      {
        title: "generalPages.yAxis",
        boxKey: BOX_KEY_ENUM.YAXIS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        boxOptions: [BOX_OPTIONS.SINGLE_METRIC, BOX_OPTIONS.SINGLE_TYPE],
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.HEATMAP_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.columns",
        boxKey: BOX_KEY_ENUM.COLUMN.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      {
        title: "generalPages.rows",
        boxKey: BOX_KEY_ENUM.ROW.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
    ],
  },
  {
    chartType: chartTypes.BUBBLE_CHART,
    boxes: [
      {
        title: "generalPages.points",
        boxKey: BOX_KEY_ENUM.POINTS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      {
        title: "generalPages.xAxis",
        boxKey: BOX_KEY_ENUM.XAXIS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        boxOptions: [BOX_OPTIONS.SINGLE_METRIC, BOX_OPTIONS.SINGLE_TYPE],
      },
      {
        title: "generalPages.yAxis",
        boxKey: BOX_KEY_ENUM.YAXIS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        boxOptions: [BOX_OPTIONS.SINGLE_METRIC, BOX_OPTIONS.SINGLE_TYPE],
      },
      {
        title: "generalPages.pointSize",
        boxKey: BOX_KEY_ENUM.POINT_SIZE.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        boxOptions: [BOX_OPTIONS.SINGLE_METRIC],
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.COMBINATION_CHART,
    boxes: [
      {
        title: "generalPages.bars",
        boxKey: BOX_KEY_ENUM.BARS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.lines",
        boxKey: BOX_KEY_ENUM.LINES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.xAxis",
        boxKey: BOX_KEY_ENUM.SLICES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.COLUMN_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.bars",
        boxKey: BOX_KEY_ENUM.BARS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.BAR,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.bars",
        boxKey: BOX_KEY_ENUM.BARS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.PIE,
    boxes: commonChartBoxes,
  },
  {
    chartType: chartTypes.LINE_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.points",
        boxKey: BOX_KEY_ENUM.POINTS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.AREA_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.points",
        boxKey: BOX_KEY_ENUM.POINTS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.TREEMAP_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.branches",
        boxKey: BOX_KEY_ENUM.BRANCHES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
    ],
  },
  {
    chartType: chartTypes.GAUGE_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        boxOptions: [BOX_OPTIONS.SINGLE_METRIC],
      },
      {
        title: "generalPages.min",
        boxKey: BOX_KEY_ENUM.MIN.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        boxOptions: [BOX_OPTIONS.SINGLE_METRIC],
      },
      {
        title: "generalPages.max",
        boxKey: BOX_KEY_ENUM.MAX.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        boxOptions: [BOX_OPTIONS.SINGLE_METRIC],
      },
    ],
  },
  {
    chartType: chartTypes.PIVOT_TABLE,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.columns",
        boxKey: BOX_KEY_ENUM.COLUMN.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      {
        title: "generalPages.rows",
        boxKey: BOX_KEY_ENUM.ROW.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
    ],
  },
  {
    chartType: chartTypes.RADAR_CHART,
    boxes: [...commonChartBoxes, colorByBox],
  },
  {
    chartType: chartTypes.STACKED_COLUMN_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.bars",
        boxKey: BOX_KEY_ENUM.BARS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.STACKED_BAR_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.bars",
        boxKey: BOX_KEY_ENUM.BARS.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      colorByBox
    ],
  },
  {
    chartType: chartTypes.GEO_MAP_CHART,
    boxes: [
      {
        title: "generalPages.measures",
        boxKey: BOX_KEY_ENUM.MEASURES.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
      },
      {
        title: "generalPages.City",
        boxKey: BOX_KEY_ENUM.CITY.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      },
      isShowSlot === countryValues.TR_DISTRICT ? 
      {
        title: "generalPages.District",
        boxKey: BOX_KEY_ENUM.DISTRICT.KEY,
        usageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
      } : null
    ],
  },

  //FILTERS
  {
    chartType: dashboardFilterTypes.FILTER_SELECT_LIST,
    boxes: commonFilterChartBoxes,
  },
  {
    chartType: dashboardFilterTypes.FILTER_DROPDOWN,
    boxes: commonFilterChartBoxes,
  },
  {
    chartType: dashboardFilterTypes.FILTER_DATE,
    boxes: commonFilterChartBoxes,
  },
  {
    chartType: dashboardFilterTypes.FILTER_TEXT,
    boxes: commonFilterChartBoxes,
  },
];
}

export const getHighlightFields = (args, selected) => {
  const value = args?.item.value
  const isSelectedColumnEqualsValue = value === selected.fieldUsageType ||
    value === selected.type ||
    value === selected.orderType;

  if ((value || value === DatamodelContextDefaults.BARS.DO_NOT_SORT) && isSelectedColumnEqualsValue) {
    args.element.innerHTML = `<strong>${args.item.text}</strong> <i class="mdi mdi-check"></i>`;
  }

}
