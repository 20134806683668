import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../../Visualize/General/types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visEnvironment = {
  state: {
    [STATE.ENVIRONMENTS]: [],
  },
  getters: {
    [GETTER.GET_ENVIRONMENTS]: (state) => {
      return state[STATE.ENVIRONMENTS];
    },
  },
  actions: {
    [ACTION.FETCH_ENVIRONMENTS]: async ({ commit }, { loadingComponent }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Environments,
        serviceMethodSub: serviceMethodSub.readEnvironments,
        successMutation: MUTATION.SET_ENVIRONMENTS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },
    [ACTION.CREATE_ENVIRONMENT]: async ({ commit, dispatch }, payload) => {
      const result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Environments,
        serviceMethodSub: serviceMethodSub.createEnvironment,
        bodyParam: payload,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });

      if (!result?.data?.statusCode) {
        await dispatch(ACTION.FETCH_ENVIRONMENTS, { loadingComponent: null });
      }
    },
    [ACTION.UPDATE_ENVIRONMENT]: async (
      { commit, dispatch },
      { queryParam, bodyParam }
    ) => {
      const result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Environments,
        serviceMethodSub: serviceMethodSub.updateEnvironmentById,
        queryParam,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });

      if (!result?.data?.statusCode) {
        await dispatch(ACTION.FETCH_ENVIRONMENTS, { loadingComponent: null });
      }
    },
    [ACTION.DELETE_ENVIRONMENT]: async (
      { commit, dispatch },
      { queryParam }
    ) => {
      const result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Environments,
        serviceMethodSub: serviceMethodSub.deleteEnvironmentById,
        queryParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });

      if (!result?.data?.statusCode) {
        await dispatch(ACTION.FETCH_ENVIRONMENTS, { loadingComponent: null });
      }
    },
  },
  mutations: {
    [MUTATION.SET_ENVIRONMENTS](state, items) {
      Vue.set(state, [STATE.ENVIRONMENTS], items);
    },
  },
};
