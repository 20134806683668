<template>
  <div class="vis-user-tabs-content vis-scroll-thin">
    <div class="vis-row">
      <div
        class="vis-col"
        :class="cardResponsiveClass"
        v-for="(item, index) in mappedProjects"
        :key="item.id"
      >
        <div
          class="vis-user-tab-card vis-flex-direction--column vis-flex--spacebeetwen"
          :class="getActiveClass(item)"
          @click.ctrl="selectMultipleProject(item)"
        >
          <!-- MAIN ACTIONS -->
          <div class="vis-user-tab-card-title vis-flex--spacebeetwen-Ycenter">
            <i
              v-if="!item.isIconImage"
              :class="item.iconClassName"
              aria-hidden="true"
            />
            <img
              v-else
              :src="getScenarioImage(item.iconClassName)"
              class="type-image"
              alt="type-scenario"
            />

            <div class="vis-user-tab-card-icon-box vis-flex--Ycenter">
              <el-tooltip
                popper-class="vis-el-tooltip-home-projects"
                :disabled="!mainAction.isTooltip"
                :content="item.description || $t('home.noDescription')"
                effect="light"
                v-for="(
                  mainAction, indexMainAction
                ) in getMainActionsWithoutDropdown(item.mainActions)"
                :key="`${mainAction.iconClassName}-${index}-${indexMainAction}`"
              >
                <i
                  :class="mainAction.iconClassName"
                  @click.stop="mainActionClicked(mainAction.actionKey, item)"
                  aria-hidden="true"
                />
              </el-tooltip>

              <!-- ThreeDotIcon  START -->
              <el-dropdown
                trigger="click"
                placement="bottom"
                @command="dropdownActionClicked"
                @visible-change="dropdownClicked"
              >
                <i
                  :class="getMainActionsDropdownIconClassName(item)"
                  @click="
                    mainActionClicked(
                      getMainActionsDropdown(item.mainActions).actionKey,
                      item
                    )
                  "
                  aria-hidden="true"
                  class="lastIcon"
                />

                <el-dropdown-menu class="vis-dropdown-menu">
                  <el-dropdown-item
                    v-for="(dropdown, indexDropdown) in item.dropdowns"
                    :key="`${dropdown.iconClassName}-${index}-${indexDropdown}`"
                    :command="{ actionKey: dropdown.actionKey, item: item }"
                    :class="{
                      [dropdown.liClassName]: true,
                      'vis-display-none': dropdown.hidden,
                    }"
                  >
                    <i :class="dropdown.iconClassName" aria-hidden="true"></i>
                    {{ $t(dropdown.localizationKey) }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- ThreeDotIcon END -->
            </div>
          </div>
          <!-- MAIN ACTIONS END -->

          <!-- *************************************** -->

          <!-- TYPE and TITLE -->
          <div
            @click.stop="goViewProject(item)"
            class="vis-user-tab-card-content vis-cursor-pointer"
          >
            <span>{{ $t(`generalPages.${item.type}`) }}</span>
            <h3>{{ item.name }}</h3>
          </div>
          <!-- TYPE and TITLE END -->

          <!-- *************************************** -->

          <!-- CREATE DATE and CREATE BY -->
          <div
            class="vis-user-tab-card-footer vis-flex--Ycenter vis-flex--spacebeetwen"
          >
            <span
              v-if="item.type === ScenarioCardType.SCENARIO"
              class="vis-user-tab-card-footer-title"
              :class="
                item.item.status === ScenarioStatusEnum.DEPLOYED
                  ? 'vis-color-success'
                  : 'vis-color-danger'
              "
            >
              {{ item.item.status }}
            </span>
            <span v-else class="vis-user-tab-card-footer-title">
              {{ item.createBy }}
            </span>
            <span>{{
              $t(`generalPages.${formatISOToDateTime(item.updatedDate)}`)
            }}</span>
          </div>
          <!-- CREATE DATE and CREATE BY END -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatISOToDateFromNow } from "../../util/moment";
import {
  MainCardAction,
  CardDropdownAction,
  ScenarioCardType,
  ScenarioStatusEnum,
} from "../../util/homePageMappers";
import { mapActions } from "vuex";
import { ACTION as ACTION_FAVORITES } from "../../store/modules/Visualize/Favorites/types";

export default {
  props: {
    mappedProjects: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedProjects: {
      type: Array,
      defauult: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dropdownVisible: false,
      ScenarioCardType: ScenarioCardType,
      ScenarioStatusEnum: ScenarioStatusEnum,
    };
  },
  computed: {
    cardResponsiveClass() {
      return this.mappedProjects.length < 6 ? ['vis-col-2', 'vis-col-xl3', 'vis-col-m6', 'vis-col-s12' ] : '';
    }
  },
  methods: {
    ...mapActions({
      updateFavorite: ACTION_FAVORITES.UPDATE_FAVORITE,
      deleteFavorite: ACTION_FAVORITES.DELETE_FAVORITE,
    }),
    getScenarioImage(imageName) {
      var images = require.context(
        "../../assets/images/scenario/",
        false,
        /\.svg$/
      );

      try {
        return images("./" + imageName + ".svg");
      } catch (e) {
        return "";
      }
    },
    isPopupVisible(cardId, item) {
      return cardId == item?.id;
    },
    goViewProject(item) {
      this.$emit("view", { type: item.type, id: item.id, name: item.name });
    },
    getMainActionsWithoutDropdown(mainActions) {
      return mainActions?.filter((i) => i.actionKey !== MainCardAction.TOGGLE);
    },
    getMainActionsDropdown(mainActions) {
      return mainActions?.find((i) => i.actionKey === MainCardAction.TOGGLE);
    },
    dropdownClicked(visible) {
      this.dropdownVisible = visible;
    },
    dropdownActionClicked(command) {
      const actionKey = command.actionKey;
      const item = command.item;
      if (actionKey === CardDropdownAction.DELETE) {
        this.$emit("delete", { type: item.type, id: item.id });
      } else if (actionKey === CardDropdownAction.EDIT) {
        this.$emit("edit", { type: item.type, id: item.id });
      } else if (actionKey === CardDropdownAction.PROPERTIES) {
        this.$emit("properties", { item: item.item });
      } else if (actionKey === CardDropdownAction.MOVE) {
        this.$emit("move", { item: item.item });
      } else if (actionKey === CardDropdownAction.SHARE) {
        this.$emit("share", { item: item.item });
      } else if (actionKey === CardDropdownAction.DUPLICATE) {
        this.$emit("duplicate", { item: item });
      } else if (actionKey === CardDropdownAction.DEPLOY) {
        this.$emit("deploy", { id: item.id });
      } else if (actionKey === CardDropdownAction.UNDEPLOY) {
        this.$emit("undeploy", { id: item.id });
      }
    },
    async mainActionClicked(actionKey, item) {
      if (actionKey === MainCardAction.STAR) {
        if (item.item.favorite) {
          await this.deleteFavorite(item.id);
        } else {
          let payload = {
            objectId: item.id,
            objectType: item.type.toUpperCase(),
          };
          await this.updateFavorite({ payload });
        }
        this.$emit("updateFavorite", item);
      }
    },
    formatISOToDateTime(val) {
      return formatISOToDateFromNow(val);
    },
    getSelectedProjectById(value) {
      return this.selectedProjects.find((x) => x.id === value.id);
    },
    selectMultipleProject(item) {
      if (this.getSelectedProjectById(item)) {
        const selectedProjects = this.selectedProjects.filter(
          (x) => x.id != item.id
        );
        this.$emit("setSelectedProjects", selectedProjects);
      } else
        this.$emit("addSelectedProjects", {
          id: item.id,
          objectType: item.type,
        });
    },
    getActiveClass(item) {
      return this.getSelectedProjectById(item) ? "vis-user-card-selected" : "";
    },
    getMainActionsDropdownIconClassName(item) {
      return this.getMainActionsDropdown(item.mainActions)?.iconClassName;
    },
  },
};
</script>
<style scoped>
.vis-user-card-selected {
  border: 1px solid var(--primary);
}
.el-dropdown {
  display: flex;
  margin-right: 0px;
}
.lastIcon {
  margin-right: 0 !important;
}
.type-image {
  width: 32px;
  height: 32px;
}
</style>
