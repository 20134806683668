import { formatValueController } from "./chartOptionsHelper";
import cloneDeep from "clone-deep";

export const formatDataModelQueryResults = (
  rawQueryResults,
  dataModelColumns
) => {
  let formattedQueryResults = [];

  if (rawQueryResults?.length) {
    formattedQueryResults = cloneDeep([...rawQueryResults]);
    formattedQueryResults.forEach((m) => {
      Object.keys(m).forEach((key) => {
        if (key.includes(".")) {
          const x = cloneDeep(key.replace(".", "_"));
          m[x] = m[key];
          delete m[key];
          key = x;
        }

        let column = dataModelColumns?.find((x) => x.field === key);

        m[key] = formatValueController(column?.format, m[key]);
      });
    });
  }

  return formattedQueryResults;
};
