<template>
  <div class="scenario-aside-properties-general-tab">
    <span>{{
      $t("Enter name of the current scenario")
    }}</span>
    <div class="vis-mt--1 vis-mb--1">
      <label>{{ $t("Name") }}</label>
      <el-input
        :value="properties.name"
        @input="(v) => $emit('updateName', v)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    properties: {
      type: Object,
    },
  },
  data() {
    return {
      generalForm: {
        name: "",
      },
    };
  },
};
</script>

<style scoped>
.label-disabled {
  font: normal normal 400 13px/20px Poppins !important;
  letter-spacing: 0px;
  color: #3e3e3e;
}
label {
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
}
</style>
