export const domain = "FAVORITES";

export const STATE = {
    FAVORITES: `${domain}/FAVORITES`,
};

export const GETTER = {
    GET_FAVORITES: `${domain}/GET_FAVORITES`,
};

export const ACTION = {
    UPDATE_FAVORITE: `${domain}/UPDATE_FAVORITE`,
    DELETE_FAVORITE: `${domain}/DELETE_FAVORITE`,
    FETCH_FAVORITES: `${domain}/FETCH_FAVORITES`,
};

export const MUTATION = {
    SET_FAVORITES: `${domain}/SET_FAVORITES`
}