<template>
  <div
    v-bind:style="{
      left: obj.left + 'px',
      top: obj.top + 'px',
    }"
    class="flowchart-object flowchart-action scenario-state-finish-node scenario-flowchart-node"
    :class="{ 'scenario-state-finish-node-selected': obj.isNodeSelected }"
    data-jtk-target="true"
  >
    <div class="scenario-state-finish-node-header">
      <div class="scenario-state-finish-node-header-content">
        <span
          class="scenario-state-finish-node-header-state-icon material-icons-outlined"
        >
          flag
        </span>
        <div class="scenario-state-finish-node-name-and-type">
          <span class="scenario-state-finish-node-type" :title="obj.type">{{
            obj.type
          }}</span>
          <span class="scenario-state-finish-node-name" :title="obj.name">{{
            obj.name
          }}</span>
          <div class="scenario-state-finish-node-body">
            <span
              class="scenario-state-finish-node-body-icon material-icons-outlined"
            >
              bolt
            </span>
          </div>
        </div>
        <div
          class="scenario-state-finish-node-action-container-actions"
          style="margin-left: auto"
        >
          <el-dropdown
            placement="bottom-start"
            size="mini"
            @command="handleCommand"
          >
            <span class="el-dropdown-link"
              ><i
                :class="`${CustomIcon.VerticalThreeDot} scenario-state-finish-node-actions`"
                aria-hidden="true"
              >
              </i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="actionCommands.Delete">{{
                $t("datamodel.deleteDataset")
              }}</el-dropdown-item>
              <el-dropdown-item :command="actionCommands.ConvertToState" :disabled="!obj.isConvertable">{{
                $t("Convert to State")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <i
      :class="`${CustomIcon.CircleOutline} scenario-state-finish-node-add-join-action visualize-add-connection-indicator-state`"
      aria-hidden="true"
      data-jtk-source="false"
      data-jtk-port-type="target"
    >
    </i>
  </div>
</template>

<script>
import JsplumbVue2BaseEditableNode from "./JsplumbVue2BaseEditableNode.vue";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  mixins: [JsplumbVue2BaseEditableNode],
  data() {
    return {
      CustomIcon: CustomIcon,
      actionCommands: {
        Delete: "delete",
        ConvertToState: "ConvertToState",
      },
    };
  },
  methods: {
    handleCommand(command) {
      if (command === this.actionCommands.Delete) {
        this.onDeleteClicked();
      } else if (command === this.actionCommands.ConvertToState) {
        this.onConvertToStateClicked();
      }
    },
    onEditClicked() {
      this.$parent.$parent.onEditClicked(this.obj.id);
    },
    onDeleteClicked() {
      this.$parent.$parent.onDeleteClicked(this.obj.id);
    },
    onConvertToStateClicked() {
      this.$parent.$parent.onConvertToStateClicked(this.obj.id);
    },
  },
};
</script>

<style>
.visualize-add-connection-indicator-state {
  color: black;
  font-size: 1.25rem;
  line-height: 20px;
  position: absolute;
  bottom: 3px;
  right: 6px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.scenario-state-finish-node:hover .scenario-state-finish-node-add-join-action {
  display: block;
}
.scenario-state-finish-node-add-join-action {
  font-size: 1.25rem;
  cursor: pointer;
  color: #3d62f8 !important;
  display: none;
}
.scenario-state-finish-node {
  padding: 0px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  cursor: move;
  color: white;
  font-size: 1rem;
  width: 190px;
  height: 80px;
}
.scenario-state-finish-node-selected {
  border: 2px solid var(--primary-lighteen-1);
}
.scenario-state-finish-node-header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  background: #f4f4f4;
  border-radius: 10px;
  padding: 8px;
  justify-content: center;
}
.scenario-state-finish-node:hover .add-connection-indicator {
  display: block;
}
.scenario-state-finish-node-footer-top-text {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #858585;
  opacity: 0.8;
}
.scenario-state-finish-node-footer-bottom-text {
  font: normal normal 500 12px/18px Poppins;
  letter-spacing: 0px;
  color: var(--secondary-dark-1);
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.scenario-state-finish-node-name {
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  font-size: 0.75rem;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.scenario-state-finish-node-type {
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  opacity: 0.6;
  font-size: 0.625rem;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scenario-state-finish-node-type-icon {
  font-size: 2.1875rem !important;
}
.scenario-state-finish-node-type-excel-csv {
  color: var(--success);
}
.scenario-state-finish-node-type-database {
  color: var(--primary);
}
.scenario-state-finish-node-actions {
  font-size: 1.125rem;
  color: #626262;
  cursor: pointer;
}
.scenario-state-finish-node-header-content {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.scenario-state-finish-node-name-and-type {
  display: flex;
  flex-direction: column;
  max-width: 120px;
}
.scenario-state-finish-node-body {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.scenario-state-finish-node-body-icon {
  color: #aaaaaa;
  font-size: 1rem;
  cursor: pointer;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.scenario-state-finish-node-body-icon:hover {
  color: #636afa;
}
.scenario-state-finish-node-header-state-icon {
  font-size: 2.1875rem;
  color: #c92727;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}

.scenario-state-finish-node:hover .scenario-state-finish-node-actions {
  display: block;
}
.scenario-state-finish-node-actions {
  font-size: 18px;
  font-weight: 400;
  color: #3d62f8 !important;
  cursor: pointer;
  display: none;
}
.scenario-state-finish-node-action-container-actions {
  margin-left: auto;
  margin-bottom: auto;
}
</style>
