<template>
  <Layout articleId="article" :showNav="false" :showAside="showEnrichmentAside">
    <div slot="modals"></div>
    <div slot="header" class="enrichment-header">
      <span class="enrichment-header-title">{{ $t("Enrichment") }}</span>
      <div class="vis-ml--auto enrichment-header-actions">
        <i
          class="vis-cursor-pointer vis-position-relative"
          :class="CustomIcon.SaveOutlined"
          @click.stop="saveEnrichment"
          aria-hidden="true"
        />
        <i
          class="vis-cursor-pointer vis-position-relative"
          :class="[
            CustomIcon.Filter,
            {
              'vis-active-setting-icon': showEnrichmentAside,
            },
          ]"
          @click.stop="toggleAside"
          aria-hidden="true"
        />
      </div>
    </div>
    <div slot="aside">
      <EnrichmentAsideProperties
        :properties="properties"
        :datasources="datasources"
        @updateName="(value) => (properties.enrichmentName = value)"
        @updateDescription="
          (value) => (properties.enrichmentDescription = value)
        "
        @updateConnection="(value) => (properties.enrichmentConnection = value)"
      />
    </div>

    <div slot="article" class="vis-height-100 enrichment-article-container">
      <div class="vis-ingest-cards-and-preview-container">
        <!-- CARD CONTAINER -->
        <div class="vis-row-box ingest-cards-items">
          <EnrichmentSqlEditor
            :textToBeAddedToSql="textToBeAddedToSql"
            :connection="sourceConnectionId"
            @resetTextToBeAddedToSql="textToBeAddedToSql = ''"
            @runSql="runSql"
            @saveSql="saveSql"
          />
        </div>

        <!-- PREVIEW CONTAINER -->
        <div class="vis-row-box preview-container">
          <EnrichmentPreviewTable :columns="columns" :rows="testEnrichmentRows" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Layout from "../layout/Layout.vue";
import { CustomIcon } from "../assets/js/custom-icons";
import {
  GETTER as GETTER_SCENARIO_MAIN,
  ACTION as ACTION_SCENARIO_MAIN,
} from "../store/modules/Scenario/Main/types";
import EnrichmentAsideProperties from "../components/scenario/enrichment/EnrichmentAsideProperties.vue";
import EnrichmentSqlEditor from "../components/scenario/enrichment/EnrichmentSqlEditor.vue";
import EnrichmentPreviewTable from "../components/scenario/enrichment/EnrichmentPreviewTable.vue";

export default {
  components: {
    Layout,
    EnrichmentAsideProperties,
    EnrichmentSqlEditor,
    EnrichmentPreviewTable,
  },
  data() {
    return {
      textToBeAddedToSql: "",
      sql: null,
      sourceConnectionId: null,
      showEnrichmentAside: true,
      CustomIcon: CustomIcon,
      properties: {
        enrichmentName: "",
        enrichmentDescription: "",
        enrichmentConnection: "",
      },
    };
  },
  async mounted() {
    this.fetchDatasources();

    if (this.enrichmentId) {
      await this.fetchEnrichmentById({ id: this.enrichmentId });
      this.prepareFormForUpdate();
    }
  },
  computed: {
    ...mapGetters({
      selectedEnrichment: GETTER_SCENARIO_MAIN.GET_SELECTED_ENRICHMENT,
      datasources: GETTER_SCENARIO_MAIN.GET_DATASOURCES,
      testEnrichmentRows: GETTER_SCENARIO_MAIN.GET_TEST_ENRICHMENT,
    }),
    enrichmentId() {
      return this.$route.params.id;
    },
    columns() {
      return this.testEnrichmentRows.length
        ? Object.keys(this.testEnrichmentRows[0]).map((key) => ({
            label: key,
            field: key,
          }))
        : [];
    },
  },
  methods: {
    ...mapActions({
      createEnrichment: ACTION_SCENARIO_MAIN.CREATE_ENRICHMENT,
      updateEnrichment: ACTION_SCENARIO_MAIN.UPDATE_ENRICHMENT,
      fetchEnrichmentById: ACTION_SCENARIO_MAIN.FETCH_ENRICHMENT_BY_ID,
      testEnrichment: ACTION_SCENARIO_MAIN.TEST_ENRICHMENT,
      fetchDatasources: ACTION_SCENARIO_MAIN.FETCH_DATASOURCES,
    }),
    saveSql(newSql) {
      this.sql = newSql;
    },
    runSql() {
      this.testEnrichment({
        data: {
          sql: this.sql,
          connection: {
            connectionId: this.properties.enrichmentConnection,
          },
        },
      });
    },
    prepareFormForUpdate() {
      this.textToBeAddedToSql = this.selectedEnrichment.sql;
      this.sql = this.selectedEnrichment.sql;
      this.properties = {
        enrichmentName: this.selectedEnrichment.name,
        enrichmentDescription: this.selectedEnrichment.description,
        enrichmentConnection: this.selectedEnrichment.connection.connectionId,
      };
    },
    saveEnrichment() {
      const payload = {
        name: this.properties.enrichmentName,
        sql: this.sql,
        description: this.properties.enrichmentDescription,
        connection: {
          connectionId: this.properties.enrichmentConnection,
        },
      };

      if (!this.enrichmentId) {
        this.createEnrichment({ data: payload });
      } else {
        this.updateEnrichment({ id: this.enrichmentId, data: payload });
      }
    },
    toggleAside() {
      this.showEnrichmentAside = !this.showEnrichmentAside;
    },
  },
};
</script>

<style scoped>
::v-deep header {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e3e8;
}
::v-deep aside {
  width: 470px;
}
::v-deep .el-tabs__item {
  padding: 0;
}
::v-deep .el-tabs__item.is-active {
  color: #0014ff;
}
::v-deep .el-tabs__active-bar {
  background: #0014ff;
}
::v-deep .el-tabs__item:hover {
  color: #0014ff;
}
::v-deep .el-radio__label {
  display: none;
}
.enrichment-header {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: inherit;
}
.enrichment-header-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
}
.enrichment-header-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}
</style>
