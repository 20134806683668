import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

const initialPagination = {number: 0, totalPages:0, numberOfElements:0};

export const visHome = {
    state: {
        [STATE.SEARCH_HOMEPAGE_RESULTS]: [],
        [STATE.SEARCH_HOMEPAGE_PAGINATION]: initialPagination,
    },
    getters: {
        [GETTER.GET_SEARCH_HOMEPAGE_RESULTS]: (state) => {
            return state[STATE.SEARCH_HOMEPAGE_RESULTS];
        },
        [GETTER.GET_HOMEPAGE_PAGINATION]: (state) => {
            return state[STATE.SEARCH_HOMEPAGE_PAGINATION];
        },
    },
    actions: {
        [ACTION.FETCH_HOMEPAGE_SEARCH]: async ({ commit}, { loadingComponent, queryParam} ) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Search,
                    serviceMethodSub: serviceMethodSub.readMainPageSearch,
                    queryParam,
                    successMutation: MUTATION.SET_SEARCH_HOMEPAGE_RESULTS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    loadingComponent,
                    withLoading: true
                },
            );
        },
    },
    mutations: {
        [MUTATION.SET_SEARCH_HOMEPAGE_RESULTS](state, items) {
            const newItems = [...state[STATE.SEARCH_HOMEPAGE_RESULTS], ...items.content]
            const pagination = { number:items.number, totalPages: items.totalPages, numberOfElements: items.numberOfElements}

            Vue.set(state, [STATE.SEARCH_HOMEPAGE_RESULTS], newItems);
            Vue.set(state, [STATE.SEARCH_HOMEPAGE_PAGINATION], pagination);
        },
        [MUTATION.RESET_SEARCH_HOMEPAGE_RESULTS](state) {
            Vue.set(state, [STATE.SEARCH_HOMEPAGE_RESULTS], []);
        },
    },
}