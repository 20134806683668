<template>
  <div>
    <div class="vis-row">
      <div class="vis-col vis-col-3">
        <div>
          <label>{{ $t("schedule.labelDistributeBy") }}</label>
          <el-select v-model="selected">
            <el-option
              v-for="option in distributionOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div
      v-if="selected === scheduleDistributionOptions.Email"
      class="vis-flex--spacebeetwen vis-row"
    >
      <div class="vis-col vis-col-6">
        <div v-for="(item, index) in mailToItems" :key="item.key">
          <!-- title -->
          <div class="vis-row">
            <div
              class="vis-col vis-mb--none"
              :class="{
                'vis-col-10': isDynamicAvailable,
                'vis-col-12': !isDynamicAvailable,
              }"
            >
              <div>
                <label class="">{{ item.label }}</label>
              </div>
            </div>

            <div
              v-if="isDynamicAvailable"
              class="vis-col vis-col-2 vis-mb--none"
            >
              <label v-if="index == 0" class="">{{ $t("Dynamic") }}</label>
            </div>
          </div>
          <!-- title -->

          <!-- The part that contains the switch and the input field -->
          <div class="vis-row vis-mb--05 align-items-center">
            <div
              class="vis-col"
              :class="{
                'vis-col-10': isDynamicAvailable,
                'vis-col-12': !isDynamicAvailable,
              }"
            >
              <div>
                <el-input
                  v-if="!item.isFieldDynamic"
                  :value="item.value"
                  :class="item.className"
                  type="textarea"
                  :rows="3"
                  multiple
                  :placeholder="$t('Enter email addresses')"
                  @input="(val) => updateMailDetailsByKey(item.key, val)"
                />
                <el-select
                  v-else
                  :value="item.value"
                  class="dynamic-field-select"
                  @change="(val) => updateDynamicFieldByKey(item.key, val)"
                >
                  <el-option
                    v-for="option in selectableDynamicContentFields"
                    :key="option.name"
                    :label="option.name"
                    :value="option.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="isDynamicAvailable" class="vis-col vis-col-2">
              <div>
                <el-switch
                  :value="item.isFieldDynamic"
                  @change="(val) => updateIsDynamicByKey(item.key, val)"
                />
              </div>
            </div>
          </div>
          <!-- The part that contains the switch and the input field -->
        </div>
      </div>

      <!-- The part that contains subject and body -->
      <div class="vis-col vis-col-6">
        <div class="vis-mb--1">
          <label class="">{{ $t("schedule.labelSubject") }}</label>
          <el-input
            v-model="notificationDtoUpdated.subject"
            :class="unFilledFieldBorder(notificationDtoUpdated.subject)"
            type="text"
          />
        </div>
        <div>
          <label class="">{{ $t("schedule.labelBody") }}</label>
          <el-input
            v-model="notificationDtoUpdated.body"
            type="textarea"
            :rows="9"
            :class="unFilledFieldBorder(notificationDtoUpdated.body)"
          />
        </div>
      </div>
      <!-- The part that contains subject and body -->
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";
import {
  scheduleDistributionOptions,
  notificationDtoDefaultValue,
  distributionOptionsDefaultValue,
  defaultMail,
  defaultDynamicMail,
} from "./schedule.js";

export default {
  props: {
    scheduleNotificationDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isDistributionFieldSolidity: {
      type: Boolean,
      default: null,
    },
    isEmailValid: {
      type: Boolean,
    },
    selectableDynamicContentFields: {
      type: Array,
      default: () => [],
    },
    isDynamicAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notificationDtoUpdated: notificationDtoDefaultValue,
      distributionOptions: distributionOptionsDefaultValue,
      selected: "Email",
      scheduleDistributionOptions: scheduleDistributionOptions,
    };
  },
  mounted() {
    this.notificationDtoUpdated = cloneDeep(this.scheduleNotificationDto);
  },
  watch: {
    notificationDtoUpdated: {
      deep: true,
      handler() {
        this.$emit("onScheduleUpdated", this.notificationDtoUpdated);
      },
    },
  },
  computed: {
    isReceiverFieldDynamic() {
      return this.notificationDtoUpdated?.receiver?.[0]?.isDynamic;
    },
    isCcFieldDynamic() {
      return this.notificationDtoUpdated?.cc?.[0]?.isDynamic;
    },
    isBccFieldDynamic() {
      return this.notificationDtoUpdated?.bcc?.[0]?.isDynamic;
    },
    receiverAddressess() {
      if (!this.isReceiverFieldDynamic) {
        return this.notificationDtoUpdated?.receiver
          ?.map((r) => r.address)
          ?.join(";");
      }

      return this.notificationDtoUpdated?.receiver?.[0]?.dynamicField;
    },
    ccAddressess() {
      if (!this.isCcFieldDynamic) {
        return this.notificationDtoUpdated?.cc
          ?.map((r) => r.address)
          ?.join(";");
      }

      return this.notificationDtoUpdated?.cc?.[0]?.dynamicField;
    },
    bccAddressess() {
      if (!this.isBccFieldDynamic) {
        return this.notificationDtoUpdated?.bcc
          ?.map((r) => r.address)
          ?.join(";");
      }

      return this.notificationDtoUpdated?.bcc?.[0]?.dynamicField;
    },
    mailToItems() {
      return [
        {
          key: "receiver",
          label: this.$t("schedule.labelTo"),
          isFieldDynamic: this.isReceiverFieldDynamic,
          value: this.receiverAddressess,
          className: "",
          inputPlaceholder: this.$t("Enter emails addresses"),
        },
        {
          key: "cc",
          label: this.$t("schedule.labelCc"),
          isFieldDynamic: this.isCcFieldDynamic,
          value: this.ccAddressess,
          className: "",
          inputPlaceholder: this.$t("Enter emails addresses"),
        },
        {
          key: "bcc",
          label: this.$t("schedule.labelBcc"),
          isFieldDynamic: this.isBccFieldDynamic,
          value: this.bccAddressess,
          className: "",
          inputPlaceholder: this.$t("Enter emails addresses"),
        },
      ];
    },
  },
  methods: {
    validateEmails(emailString) {
      // Define the regular expression pattern for a simple email validation
      var emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

      // Split the input string into individual emails
      var emails = emailString.split(";");

      // Validate each email
      for (var i = 0; i < emails.length; i++) {
        var email = emails[i].trim(); // Remove leading and trailing spaces
        if (!emailPattern.test(email)) {
          return false; // Return false if any email is not valid
        }
      }

      return true; // All emails are valid
    },
    updateDynamicFieldByKey(key, dynamicField) {
      this.notificationDtoUpdated[key][0].dynamicField = dynamicField;
    },
    updateMailDetailsByKey(key, inputEmailString) {
      const emailArray = inputEmailString.split(";");

      const resultArray = emailArray?.map((email) => {
        return {
          address: email,
          dynamicField: null,
          isDynamic: false,
        };
      });

      this.notificationDtoUpdated[key] = resultArray;
    },
    updateIsDynamicByKey(key, isDynamic) {
      if (isDynamic) {
        this.notificationDtoUpdated[key] = [cloneDeep(defaultDynamicMail)];
      } else {
        this.notificationDtoUpdated[key] = [cloneDeep(defaultMail)];
      }
    },
    notValidMailBorder(val) {
      if (
        (!this.isEmailValid && this.isEmailValid != null) ||
        (!this.isDistributionFieldSolidity &&
          this.isDistributionFieldSolidity != null &&
          val === "")
      ) {
        return "vis-unfilled-field";
      } else return;
    },
    unFilledFieldBorder(val) {
      if (
        !this.isDistributionFieldSolidity &&
        this.isDistributionFieldSolidity != null &&
        val === ""
      ) {
        return "vis-unfilled-field";
      } else return;
    },
  },
};
</script>
<style scoped>
::v-deep .el-textarea__inner {
  resize: none !important;
}
.dynamic-field-switch-default {
  height: 75px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dynamic-field-switch-dropdown {
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-items-center {
  align-items: center !important;
}
</style>
