export const domain = "SSE";

export const STATE = {
  SSE_LOGS: `${domain}/SSE_LOGS`,
};

export const GETTER = {
  GET_SSE_LOGS: `${domain}/GET_SSE_LOGS`,
};

export const ACTION = {
  INIT_SSE: `${domain}/INIT_SSE`,
};

export const MUTATION = {
  SET_SSE_LOGS: `${domain}/SET_SSE_LOGS`,
};

export const ServerEventToClientEventMapping = {
  FILE_INGEST: "onIngestSSEReceived",
  RDB_INGEST_TASK: "onIngestSSETaskReceived",
  RDB_INGEST_SUBTASK: "onIngestSSESubtaskReceived",
  RDB_INGEST_JOB_TRANSACTION: "onIngestSSEJobTransactionReceived",
};
