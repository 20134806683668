<template>
  <div v-loading="loading.PanelPage" class="vis-flex-scrollbar vis-height-100">
    <SettingsBar
      :editMode="editMode"
      :isPageLink="true"
      :isActiveIconBox="false"
      :showDataModelLink="false"
      :showParametersLink="false"
    />
    <div class="vis-row vis-overflow-auto">
      <PanelDatamodelFieldsMenu
        :selectedDataModel="selectedDataModel"
        :datasetFields="fields"
        :selectedPanel="selectedPanel"
        :datasetFilterParams="datasetFilterParams"
        :isChartTypeFilter="isChartTypeFilter"
        :disableArea="showFieldsAndLeftMenu || !isPanelTypeLogo"
        :selectedFieldsWithKeyCtrlShift="selectedFieldsWithKeyCtrlShift"
        @updateSelectedPanel="updateSelectedPanel"
        @activeFieldBox="isActiveFieldBox = $event"
        @passiveFieldBox="isActiveFieldBox = $event"
        @addSelectedFieldsWithKeyCtrl="addSelectedFieldsWithKeyCtrl"
        @addSelectedFieldsWithKeyShift="addSelectedFieldsWithKeyShift"
      />
      <!-- DATAMODEL-FIELDS-MENU -->
      <div
        v-if="showFieldsAndLeftMenu"
        class="vis-dashboard-custom-col vis-right-border vis-bg-color-white vis-scroll-thin vis-overflow-auto vis-height-100"
      >
        <ChartPopup
          :selectedChartType="chartType"
          :selectedChartTypeName="chartName"
          :isChartTypeFilter="isChartTypeFilter"
          @changeChartType="changeChartType"
        />

        <div v-if="!isPanelTypeLogo">
          <div v-if="isPanelTypeTable">
            <PanelColumnsBox
              :selectedPanel="selectedPanel"
              :isActiveFieldBox="isActiveFieldBox"
              :designMode="designMode"
              :selectedFieldsWithKeyCtrlShift="selectedFieldsWithKeyCtrlShift"
              :datasetFields="fields"
              :datasetIds="datasetIds"
              :datasetName="datasetName"
              :isColumnBasedPropertiesSelected="isColumnBasedPropertiesSelected"
              :selectedColumnField="selectedColumnBasedPropertiesColumn"
              @updateSelectedPanel="updateSelectedPanel"
              @updateSelectedPanelAndViewChart="updateSelectedPanelAndViewChart"
              @deleteSelectedFieldsWithKeyCtrlShift="
                selectedFieldsWithKeyCtrlShift = []
              "
              @onSelectColumnBasedPropertiesColumn="
                setSelectedColumnBasedPropertiesColumn
              "
            />
            <PanelSectionsBox
              :selectedPanel="selectedPanel"
              :isActiveFieldBox="isActiveFieldBox"
              :designMode="designMode"
              :datasetName="datasetName"
              @updateSelectedPanel="updateSelectedPanel"
              @updateSelectedPanelAndViewChart="updateSelectedPanelAndViewChart"
            />
          </div>
          <div v-else>
            <div v-for="box in panelDataBoxes" :key="box.title">
              <PanelDataBox
                :selectedPanel="selectedPanel"
                :isActiveFieldBox="isActiveFieldBox"
                :datasetFields="fields"
                :designMode="designMode"
                :boxName="$t(box.title)"
                :usageType="box.usageType"
                :boxKey="box.boxKey"
                :boxOptions="box.boxOptions"
                :datasetIds="datasetIds"
                :datasetName="datasetName"
                @updateSelectedPanel="updateSelectedPanel"
                @updateSelectedPanelAndViewChart="
                  updateSelectedPanelAndViewChart
                "
                @changeFilterChartPropertyValue="changeFilterChartPropertyValue"
                @onSelectColumnBasedPropertiesColumn="
                  setSelectedColumnBasedPropertiesColumn
                "
              />
            </div>
          </div>
          <PanelFiltersBox
            v-if="showByChartType"
            :selectedPanel="selectedPanel"
            :searchinList="searchinList"
            :isActiveFieldBox="isActiveFieldBox"
            :designMode="designMode"
            :datasetName="datasetName"
            @updateSelectedPanel="updateSelectedPanel"
            @updateSelectedPanelAndViewChart="updateSelectedPanelAndViewChart"
          />
          <PanelSortBox
            v-if="showByChartType"
            :selectedPanel="selectedPanel"
            :isActiveFieldBox="isActiveFieldBox"
            :designMode="designMode"
            :datasetName="datasetName"
            @updateSelectedPanel="updateSelectedPanel"
            @updateSelectedPanelAndViewChart="updateSelectedPanelAndViewChart"
          />
        </div>
        <div v-else>
          <PanelLogosBox
            :panelData="selectedPanel"
            @updateSelectedPanel="updateSelectedPanel"
          />
        </div>
      </div>
      <!-- LEFT-MENU-2 -->
      <div
        class="vis-col vis-pa--none vis-ma--none vis-scroll-thin vis-height-100"
      >
        <div
          v-loading="isSearchLoading"
          class="vis-chart-card min-h-89_5 vis-not-border"
          :style="mergeCardAndTemplateStyleAsCSS()"
          :class="[hasBorderinChartCard]"
        >
          <PanelCardTitle
            :panelData="selectedPanel"
            :designMode="designMode"
            :isActiveSaveIcon="true"
            :hasTitle="
              mergeCardAndTemplateStyleSingleProperty(
                allPropertiesKeys.HAS_TITLE
              )
            "
            :hasSubTitle="
              mergeCardAndTemplateStyleSingleProperty(
                allPropertiesKeys.HAS_SUBTITLE
              )
            "
            :isPanelPage="isPanelPage"
            @saveAndPreviousDashboard="saveAndPreviousDashboard"
            @previousDashboard="previousDashboard"
            @getViewChart="viewChart"
          />
          <div
            v-if="!checkHasSearchResponseError()"
            :class="{
              'vis-chart-card-content vis-scroll-thin': isPanelTypeTable,
            }"
          >
            <Chart
              v-if="isChartsAvailable && isPanelTypeECharts"
              :panelData="getPanelDataByAuthorize()"
              :paramsFromSearch="options"
              :isPanelTypeLabel="isPanelTypeLabel"
              :selectedTabDesignMode="designMode"
            />
            <Logo
              v-if="isPanelTypeLogo"
              :panelData="selectedPanel"
              :option="options"
            />
            <!-- Fields Düzeltilecek -->
            <Summary
              v-if="isPanelTypeSummary"
              :panelData="getPanelDataByAuthorize()"
              :option="options"
              :fields="fields"
            />
            <!-- Fields Düzeltilecek -->
            <DataTable
              v-else-if="isPanelTypeTable"
              :panelData="getPanelDataByAuthorize()"
              :option="options"
              :paramsFromSearch="options"
              :isPanelPage="isPanelPage"
              @addFiltersToPanels="addFiltersToPanelFromPanel"
              @updateAndViewSelectedPanel="mapSelectedPanelAndViewChart"
              @updateSelectedPanel="mapSelectedPanelUpdate"
              @onSelectColumnBasedPropertiesColumn="
                setSelectedColumnBasedPropertiesColumn
              "
              @setDataTableColumnBasedComponentProperties="
                setDataTableColumnBasedComponentProperties
              "
              @onTableWrapperClicked="handleTableWrapperClicked"
            />
            <PivotTable
              v-if="isPanelTypePivotTable"
              :panelData="getPanelDataByAuthorize()"
              :option="options"
              :showFieldListEnabled="false"
              :refreshOnStyleChanges="isPanelPage"
              :selectedColumnField="selectedColumnBasedPropertiesColumn"
              @updateAndViewSelectedPanel="mapSelectedPanelAndViewChart"
              @onSelectColumnBasedPropertiesColumn="
                setSelectedColumnBasedPropertiesColumn
              "
              @setDataTableColumnBasedComponentProperties="
                setDataTableColumnBasedComponentProperties
              "
            />
            <FilterText
              v-else-if="isPanelTypeFilterText"
              :panelData="getPanelDataByAuthorize()"
              :isPanelPage="isPanelPage"
              @addFiltersToPanels="addFiltersToPanelFromPanel"
              @setSelectedPanelStyle="setSelectedPanelStyle"
            />
            <FilterDropdown
              v-else-if="isPanelTypeFilterDropdown"
              :panelData="getPanelDataByAuthorize()"
              :option="options"
              :isDisabled="isPanelPage"
              @addFiltersToPanels="addFiltersToPanelFromPanel"
            />
            <FilterNumber
              v-else-if="isPanelTypeFilterNumber"
              :panelData="getPanelDataByAuthorize()"
              @addFiltersToPanels="addFiltersToPanelFromPanel"
            />
            <FilterDate
              v-else-if="isPanelTypeFilterDate"
              :panelData="getPanelDataByAuthorize()"
              :isPanelPage="isPanelPage"
              @addFiltersToPanels="addFiltersToPanelFromPanel"
              @setSelectedPanelStyle="setSelectedPanelStyle"
            />
            <FilterSelectList
              v-else-if="isPanelTypeFilterSelectList"
              :panelData="getPanelDataByAuthorize()"
              :option="options"
              :isDisabled="isPanelPage"
              @addFiltersToPanels="addFiltersToPanelFromPanel"
            />
            <AdvancedButton
              v-if="isPanelTypeAdvancedButton"
              :panelData="selectedPanel"
            />
            <FilterButton
              v-if="isPanelTypeFilterButton"
              :panelData="selectedPanel"
            />
          </div>
          <DashboardChartMessage
            v-if="checkHasSearchResponseError()"
            :message="$t(`errorCode.${checkHasSearchResponseError()}`)"
          />
          <ButtonDialog
            class="vis-mt--2 vis-flex--Xcenter"
            :isDialogActive="isPanelTypeFilterButton"
            :isPositionFixed="false"
            :panelData="selectedPanel"
          />
          <div class="vis-chart-card-footer vis-chart-card-footer-padding">
            {{ selectedPanelFootNote }}
          </div>
        </div>
      </div>
      <!-- CENTER-PANEL -->
      <div
        v-if="editMode"
        class="vis-col vis-pa--none vis-ma--none vis-scroll-thin vis-height-100 vis-overflow-auto vis-tools-box vis-left-border vis-bg-color-white"
        style="max-width: 300px"
      >
        <PanelProperties
          ref="PanelProperties"
          :chartType="chartType"
          :chartTypeName="chartTypeName"
          :propertiesPanel="selectedPanel.properties"
          :editMode="editMode"
          :colorPalettes="colorPalettes"
          :customFilterType="checkFilterTypeIsCustom()"
          :isColumnBasedPropertiesSelected="isColumnBasedPropertiesSelected"
          :selectedColumnBasedFormattingsProperties="
            selectedColumnBasedFormattingsProperties
          "
          :selectedColumnField="selectedColumnBasedPropertiesColumn"
          :dashboardTabsOptionList="dashboardTabsOptionList"
          @setPanelStyle="setSelectedPanelStyle"
          @setDataTableColumnBasedComponentProperties="
            setDataTableColumnBasedComponentProperties
          "
          @setDashboardStyle="setDashboardStyle"
          @closeColumnBasedProperties="resetSelectedColumnBasedPropertiesColumn"
          @onPropertiesEventTriggered="onPropertiesEventTriggered"
        />
      </div>
      <!-- PROPERTIES-MENU -->
    </div>
    <!-- ROW -->
    <ConfirmDialog
      :title="$t('dialog.unsaveTitle')"
      :message="$t('dialog.unsaveMessage')"
      :dialogVisible="dialogVisible"
      @cancel="dialogVisible = $event"
      @confirm="setConfirm()"
    />
    <ConditionalFormattingPopup
      v-if="isConditionalFormattingPopupVisible"
      :selectedPanel="selectedPanel"
      :selectedColumn="selectedColumnBasedPropertiesColumn"
      @updateSelectedPanel="updateSelectedPanel"
      @closePopup="isConditionalFormattingPopupVisible = false"
    />
    <SqlShowPopup
      v-if="isSqlPopupVisible"
      :sqlQuery="sqlQuery"
      :selectedDbType="selectedDbType"
      @closePopup="isSqlPopupVisible = false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);
import {
  GETTER as GETTER_DASHBOARD,
  ACTION as ACTION_DASHBOARD,
  MUTATION as MUTATION_DASHBOARD,
} from "../store/modules/Visualize/Dashboard/types";
import {
  GETTER as GETTER_DATASET,
  ACTION as ACTION_DATASET,
  MUTATION as MUTATION_DATASET,
} from "../store/modules/Visualize/Dataset/types";
import {
  GETTER as GETTER_DASHBOARD_FIELDS,
  ACTION as ACTION_DASHBOARD_FIELDS,
} from "../store/modules/Visualize/DashboardFields/types";
import {
  GETTER as GETTER_CHART_FIELDS,
  ACTION as ACTION_CHART_FIELDS,
} from "../store/modules/Visualize/ChartFields/types";
import {
  GETTER as GETTER_COLOR_PALETTES,
  ACTION as ACTION_COLOR_PALETTES,
} from "../store/modules/Visualize/ColorPalette/types";
import {
  GETTER as GETTER_DATAMODEL,
  ACTION as ACTION_DATAMODEL,
} from "../store/modules/Visualize/DataModel/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import {
  TEMP_STORAGE_KEYS,
  GETTER as GETTER_TEMP_STORAGE,
} from "../store/modules/temp-storage/types";
import { GETTER as GETTER_TEMPLATES } from "../store/modules/Visualize/AdminTemplates/types";
import { mapGetters, mapActions, mapMutations } from "vuex";
import panelSearch from "../mixins/panelSearch.js";
import SettingsBar from "../components/helper/SettingsBar.vue";
import PanelProperties from "../components/panel/PanelProperties.vue";
import SqlShowPopup from "../components/panel/SqlShowPopup.vue";
import PanelDatamodelFieldsMenu from "../components/panel/PanelDatamodelFieldsMenu.vue";
import PanelLogosBox from "../components/panel/PanelLogosBox.vue";
import PanelColumnsBox from "../components/panel/PanelColumnsBox.vue";
import PanelSectionsBox from "../components/panel/PanelSectionsBox.vue";
import PanelFiltersBox from "../components/panel/PanelFiltersBox.vue";
import PanelSortBox from "../components/panel/PanelSortBox.vue";
import PanelDataBox from "../components/panel/PanelDataBox.vue";
import PanelCardTitle from "../components/panel/PanelCardTitle.vue";
import Chart from "../components/dashboard/chart/Chart.vue";
import Logo from "../components/dashboard/logo/Logo.vue";
import Summary from "../components/dashboard/summary/Summary.vue";
import DataTable from "../components/dashboard/table/Table.vue";
import FilterText from "../components/dashboard/chart/FilterText.vue";
import FilterDropdown from "../components/dashboard/chart/FilterDropdown.vue";
import FilterNumber from "../components/dashboard/chart/FilterNumber.vue";
import FilterDate from "../components/dashboard/chart/FilterDate.vue";
import FilterSelectList from "../components/dashboard/chart/FilterSelectList.vue";
import AdvancedButton from "../components/dashboard/chart/AdvancedButton.vue";
import FilterButton from "../components/dashboard/chart/FilterButton.vue";
import ChartPopup from "../components/panel/ChartPopup.vue";
import { includesValues, routerEnums } from "../commons/Enum";
import ButtonDialog from "../components/helper/ButtonDialog.vue"
import {
  chartTypes,
  dashboardFilterTypes,
  panelTypes,
  otherChartType,
  onPropertiesEventOptions,
  BOX_KEY_ENUM,
  getFilteredPanelDataByAuthorize,
} from "../commons/dashboardAndPanel";
import {
  DefaultValue,
  allPropertiesKeys,
  datatablePropertiesEnum,
  GEO_MAP_CHART_PROPERTIES_ENUM,
  propertiesKeyToCSSKeyMapping,
  countryValues,
} from "../commons/dashboardProperties";
import cloneDeep from "clone-deep";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import { createSmartTitle } from "../util/panelDataPropertiesStyle";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";
import { diff } from "deep-object-diff";
import { checkLimitAndSetPanelFields } from "../components/panel/measures/js/measures";
import PivotTable from "../components/dashboard/table/PivotTable.vue";
import { chartBoxes } from "../util/panelDataBoxes";
import { setDefaultProperties } from "../util/panel-properties/panelDefaultPropertiesSetter";
import ConditionalFormattingPopup from "../components/panel/conditionalFormatting/ConditionalFormattingPopup.vue";
import {
  changeVisTypeForSearchParamByAggregateResult,
  setSelectedPanelDetailAggregationAndMetrics,
} from "../util/data-table/tableCommonsHelper";
import { cardPropertiesTemplates } from "../mocks/cardPropertiesTemplates";
import { defaultColorPalettes } from "../components/settings/settings";
import DashboardChartMessage from "../components/dashboard/DashboardChartMessage.vue";
export default {
  mixins: [panelSearch],
  components: {
    Chart,
    Logo,
    Summary,
    DataTable,
    FilterText,
    FilterDropdown,
    FilterNumber,
    FilterDate,
    FilterSelectList,
    SettingsBar,
    ChartPopup,
    PanelDatamodelFieldsMenu,
    PanelLogosBox,
    PanelColumnsBox,
    PanelSectionsBox,
    PanelFiltersBox,
    PanelSortBox,
    PanelCardTitle,
    PanelProperties,
    ConfirmDialog,
    PanelDataBox,
    PivotTable,
    AdvancedButton,
    FilterButton,
    ConditionalFormattingPopup,
    SqlShowPopup,
    DashboardChartMessage,
    ButtonDialog,
  },
  watch: {
    dynamicTitleShowValue(val) {
      const dynamicTitleValue = this.getPanelPropertyKey(
        includesValues.DYNAMIC_TITLE_VALUE
      );

      if (typeof val == "boolean" && dynamicTitleValue)
        this.setSelectedPanelStyle({
          property: dynamicTitleValue,
          value: val ? "" : this.selectedPanel.name,
        });
    },
  },
  data() {
    return {
      isSqlPopupVisible: false,
      isConditionalFormattingPopupVisible: false,
      selectedColumnBasedPropertiesColumn: null,
      isChartsAvailable: true,
      boxEmptyMessage:
        "Sol menüden sürükle-bırak yaparak alanı doldurabilirsiniz.",
      selectedComponent: "",
      editMode: true,
      chartType: this.$route.query.chartType,
      searchinList: {
        measuresList: [],
        barsList: [],
        columnList: [],
        sectionList: [],
        filterList: [],
        dataItems: [],
      },
      isActiveFieldBox: false,
      dashboardFilterTypes: dashboardFilterTypes,
      allPropertiesKeys: allPropertiesKeys,
      tempSelectedPanel: {},
      dialogVisible: false,
      beforeRouteLink: "",
      selectedFieldsWithKeyCtrlShift: [],
    };
  },
  beforeDestroy() {
    this.resetDatasetFilterParams();
  },
  async created() {
    if (!this.dashboard?.id && this.$route.query.dashboardId) {
      await this.getDashboardById({
        loadingComponent: LoadingComponent.DashboardPage,
        dashboardId: this.dashboardId,
      });
    }
    await this.fetchDataModelById(this.dashboard.datamodel_id);
  },
  async mounted() {
    await this.getDatasetFieldsControl();
    //FOR FIELD AUTHORIZE
    this.changePanelDataDetailsData();

    this.clickOnBody();

    this.tempSelectedPanel = cloneDeep(this.selectedPanel);

    if (this.isChartTypeFilter) await this.getCustomFilterParams();

    window.onbeforeunload = function (event) {
      event.returnValue = "Are you really want to perform the action?";
    };

    if (this.isChartTypeFilter) {
      setDefaultProperties(this?.selectedPanel?.type, {
        checkHasDynamicTitleValue: this.checkHasDynamicTitleValue,
        checkHasGroupMultipleValues: this.checkHasGroupMultipleValues,
        checkHasUniqueName: this.checkHasUniqueName,
        setSelectedPanelStyleByNoKey: this.setSelectedPanelStyleByNoKey,
      }).fn.forEach((f) => {
        f();
      });
    }

    if (!this.designMode) this.viewChart();

    this.selectedComponent = this.chartType;

    this.fetchAndControlColorPalettes();
  },
  computed: {
    ...mapGetters({
      dashboard: GETTER_DASHBOARD.GET_DASHBOARD,
      dataModels: GETTER_DATAMODEL.GET_DATAMODELS,
      selectedDataModel: GETTER_DATAMODEL.GET_SELECTED_DATAMODEL,
      datasetFilterParams: GETTER_DATASET.GET_DATASET_BY_ID_FILTER_PARAMS,
      dashboardFields: GETTER_DASHBOARD_FIELDS.GET_DASHBOARD_BY_ID_FIELDS,
      chartFields: GETTER_CHART_FIELDS.GET_CHART_BY_ID_FIELDS, //TODO yapı belli olunca tekrar bakılacak.
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
      loading: GETTER_GENERAL.GET_LOADING,
      getColorPalettes: GETTER_COLOR_PALETTES.GET_COLOR_PALETTES,
      getCustomColorPalettes: GETTER_DASHBOARD.GET_CUSTOM_COLOR_PALETTES,
      datasetIds: GETTER_DATASET.GET_DATASET_IDS,
      cardTemplates: GETTER_TEMPLATES.GET_TEMPLATES,
      unAuthorizedDashboardFields:
        GETTER_DASHBOARD_FIELDS.GET_DASHBOARD_UNAUTHORIZED_FIELDS,
    }),
    selectedColumnBasedFormattingsProperties() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      return selectedPanelCloned?.properties?.columnBasedFormattings?.find(
        (f) =>
          this.selectedColumnBasedPropertiesColumn?.slotFieldId
            ? f.slotFieldId ===
              this.selectedColumnBasedPropertiesColumn?.slotFieldId
            : f.fieldId === this.selectedColumnBasedPropertiesColumn?.fieldId
      )?.formattings;
    },
    dashboardTabsOptionList() {
      return this.dashboard.tabs.map((m) => {
        return {
          label: m.name,
          value: m?.properties?.uiTabId,
        };
      });
    },
    tempDatasetFields() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_DATASET_FIELDS);
    },
    tempSelectedDashboard() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_SELECTED_DASHBOARD);
    },
    fields() {
      return this.dashboardId ? this.dashboardFields : this.tempDatasetFields;
    },

    dashboardId() {
      return this.$route.query.dashboardId ?? this.dashboard.id;
    },
    tabIndex() {
      return this.$route.query.tabIndex;
    },
    chartList() {
      return this.dashboard.tabs[this.tabIndex].charts;
    },
    selectedPanel() {
      var panel = this.dashboard?.tabs?.[this.tabIndex]?.charts?.find(
        (x) => x?.i == this.queryPanelId
      );
      return panel;
    },
    panelId() {
      return this.selectedPanel?.id;
    },
    queryPanelId() {
      return this.$route.query.id;
    },
    datasetName() {
      return this.selectedPanel?.properties?.dataSet?.name;
    },
    chartTypeName() {
      return this.isColumnBasedPropertiesSelected
        ? "Column " + this.selectedColumnBasedPropertiesColumn.alias
        : this.selectedPanel?.chartTypeName;
    },
    chartName() {
      return this.selectedPanel?.chartTypeName;
    },
    selectedPanelFootNote() {
      return this.selectedPanel?.footnote;
    },
    isColumnBasedPropertiesSelected() {
      return this.selectedColumnBasedPropertiesColumn ? true : false;
    },
    selectedTemplateId() {
      return this.dashboard.tabs[this.tabIndex].properties?.style?.[
        allPropertiesKeys.SELECTED_TEMPLATE_ID
      ];
    },
    selectedTabStyle() {
      return this.dashboard?.tabs[this.tabIndex].properties?.style;
    },
    mergedCardTemplateList() {
      return [...this.cardTemplates, ...cardPropertiesTemplates];
    },
    selectedTemplateProperties() {
      return (
        this.mergedCardTemplateList.find(
          (d) => d.id === this.selectedTemplateId
        )?.properties?.style ?? {}
      );
    },

    isPanelTypeECharts() {
      return (
        this.selectedPanel?.type?.toLowerCase().includes(panelTypes.CHART) &&
        !this.isPanelTypeLogo &&
        !this.isPanelTypeSummary
      );
    },
    isPanelTypeLogo() {
      return this.selectedPanel?.type?.toLowerCase().includes(panelTypes.LOGO);
    },
    isPanelTypeSummary() {
      return this.selectedPanel?.type
        ?.toLowerCase()
        .includes(panelTypes.SUMMARY);
    },
    isPanelTypeTable() {
      return this.selectedPanel?.type === chartTypes.TABLE;
    },
    isPanelTypePivotTable() {
      return this.selectedPanel?.type === chartTypes.PIVOT_TABLE;
    },
    isPanelTypeFilterText() {
      return this.selectedPanel?.type === dashboardFilterTypes.FILTER_TEXT;
    },
    isPanelTypeFilterDropdown() {
      return this.selectedPanel?.type === dashboardFilterTypes.FILTER_DROPDOWN;
    },
    isPanelTypeFilterNumber() {
      return this.selectedPanel?.type === dashboardFilterTypes.FILTER_NUMBER;
    },
    isPanelTypeFilterDate() {
      return this.selectedPanel?.type === dashboardFilterTypes.FILTER_DATE;
    },
    isPanelTypeAdvancedButton() {
      return this.selectedPanel?.type === dashboardFilterTypes.ADVANCED_BUTTON;
    },
    isPanelTypeFilterButton() {
      return this.selectedPanel?.type === otherChartType.BUTTON;
    },
    isPanelTypeFilterSelectList() {
      return (
        this.selectedPanel?.type === dashboardFilterTypes.FILTER_SELECT_LIST
      );
    },
    isPanelTypeLabel() {
      return this.selectedPanel?.type === chartTypes.LABEL;
    },
    isSearchLoading() {
      return this.loading[LoadingComponent.GetSearch];
    },
    showByChartType() {
      return this.isPanelTypeFilterText ||
        this.isPanelTypeFilterDate ||
        this.isPanelTypeAdvancedButton
        ? false
        : true;
    },
    designMode() {
      return (
        this.selectedTabStyle[allPropertiesKeys.DASHBOARD_GRID_DESIGN_MODE] ||
        this.selectedPanel?.type === chartTypes.TABLE
      );
    },
    isChartTypeFilter() {
      return this.selectedPanel?.type?.toLowerCase().includes("filter");
    },
    isPanelPage() {
      return this.$route.name == routerEnums.PANEL;
    },
    panelDataBoxes() {
      const selectedPropertyStyle = this.getPanelPropertyStyleForShowSlot();

      return chartBoxes({ isShowSlot: selectedPropertyStyle })
        .find((i) => i.chartType === this.chartType)
        ?.boxes?.filter((x) => x);
    },
    isChartTypeGeoMap() {
      return this.selectedPanel?.type === chartTypes.GEO_MAP_CHART;
    },
    colorPalettes() {
      return [
        ...(this.getCustomColorPalettes || []),
        ...(this.getColorPalettes || []),
      ];
    },
    showFieldsAndLeftMenu() {
      return (
        !this.isPanelTypeAdvancedButton &&
        !this.isPanelTypeFilterButton &&
        !this.isPanelTypeLabel
      );
    },
    dynamicTitleShowValue() {
      return this.selectedPanel?.properties?.style?.[
        this.getPanelPropertyKey(includesValues.DYNAMIC_TITLE_SHOW_VALUE)
      ];
    },
  },
  methods: {
    ...mapActions({
      getDashboardById: ACTION_DASHBOARD.FETCH_DASHBOARDS_BY_ID,
      fetchDataModelById: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID,
      updateDashboardPanel: ACTION_DASHBOARD.UPDATE_DASHBOARD,
      fetchDatasetByIdFields: ACTION_DATASET.FETCH_DATASET_BY_ID_FIELDS,
      fetchDatasetByIdFilterParams:
        ACTION_DATASET.FETCH_DATASET_BY_ID_FILTER_PARAMS,
      fetchChartByIdFields: ACTION_CHART_FIELDS.FETCH_CHART_BY_ID_FIELDS,
      fetchDashboardByIdFields:
        ACTION_DASHBOARD_FIELDS.FETCH_DASHBOARD_BY_ID_FIELDS,
      fetchColorPalettes: ACTION_COLOR_PALETTES.FETCH_COLOR_PALETTES,
      createColorPalettes: ACTION_COLOR_PALETTES.CREATE_COLOR_PALETTES,
    }),
    ...mapMutations({
      setSelectedPanel: MUTATION_DASHBOARD.SET_SELECTED_PANEL,
      setDashboardProperty: MUTATION_DASHBOARD.SET_DASHBOARD_PROPERTY,
      resetDatasetFilterParams: MUTATION_DATASET.RESET_DATASET_FILTER_PARAMS,
    }),
    checkHasSearchResponseError() {
      return this.searchResponseError?.[this.selectedPanel?.i]?.message;
    },
    changePanelDataDetailsData() {
      if (!this.unAuthorizedDashboardFields?.size) return;

      let selectedPanelCloned = cloneDeep(this.selectedPanel);
      const changeAuthorizeValue = (arr) => {
        if (!arr?.length) return;
        arr.forEach((field) => {
          if (this.unAuthorizedDashboardFields.has(field.fieldId)) {
            field.isAuthorize = false;
          }
        });
      };

      changeAuthorizeValue(selectedPanelCloned?.details?.metrics);
      changeAuthorizeValue(selectedPanelCloned?.details?.aggregation);
      changeAuthorizeValue(selectedPanelCloned?.details?.columns);
      changeAuthorizeValue(selectedPanelCloned?.details?.filters);
      changeAuthorizeValue(selectedPanelCloned?.details?.orders);
      changeAuthorizeValue(selectedPanelCloned?.details?.sections);

      this.updateSelectedPanel({ value: selectedPanelCloned });
    },
    setDefaultAuthorizeValueInFields() {
      //Kaydedip çıkmak istediğimizde key true set edilecek. Çünkü slot içindeki fieldlarda isAuthorize değeri false olarak kalmayacak
      if (!this.unAuthorizedDashboardFields?.size) return;

      let selectedPanelCloned = cloneDeep(this.selectedPanel);

      const changeAuthorizeValue = (arr) => {
        arr.forEach((field) => {
          if (this.unAuthorizedDashboardFields.has(field.fieldId)) {
            field.isAuthorize = true;
          }
        });
      };

      changeAuthorizeValue(selectedPanelCloned?.details?.metrics);
      changeAuthorizeValue(selectedPanelCloned?.details?.aggregation);
      changeAuthorizeValue(selectedPanelCloned?.details?.columns);
      changeAuthorizeValue(selectedPanelCloned?.details?.filters);
      changeAuthorizeValue(selectedPanelCloned?.details?.orders);
      changeAuthorizeValue(selectedPanelCloned?.details?.sections);

      this.updateSelectedPanel({ value: selectedPanelCloned });
    },
    getPanelDataByAuthorize() {
      if (!this.unAuthorizedDashboardFields?.size) return this.selectedPanel;

      return getFilteredPanelDataByAuthorize({
        chartData: this.selectedPanel,
        unAuthorizedDashboardFields: this.unAuthorizedDashboardFields,
      });
    },
    handleTableWrapperClicked() {
      this.$refs.PanelProperties?.closeColumnBasedProperties();
    },
    getPanelPropertyStyleForShowSlot() {
      if (this.isChartTypeGeoMap) {
        const style =
          this.selectedPanel?.properties?.style?.[
            GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COUNTRY
          ];
        return style;
      } else return true;
    },

    onPropertiesEventTriggered(event) {
      if (
        event.eventId === onPropertiesEventOptions.openConditionalFormatting
      ) {
        this.isConditionalFormattingPopupVisible = true;
      } else {
        this.isSqlPopupVisible = true;
      }
    },
    setSelectedColumnBasedPropertiesColumn(value) {
      this.selectedColumnBasedPropertiesColumn = value;
    },
    resetSelectedColumnBasedPropertiesColumn() {
      this.selectedColumnBasedPropertiesColumn = null;
    },
    clickOnBody() {
      document.body.addEventListener("click", (e) => {
        if (this.selectedFieldsWithKeyCtrlShift.length && !e.ctrlKey)
          this.selectedFieldsWithKeyCtrlShift = [];
      });
    },
    addSelectedFieldsWithKeyCtrl(value) {
      if (!this.isPanelTypeTable) return; //used for table chart for now.

      const selectedItem = this.getSelectedFieldWithKeyCtrlShiftByFieldId(
        value.fieldId
      );

      if (selectedItem) {
        const selectedItemFindIndex =
          this.selectedFieldsWithKeyCtrlShift.findIndex(
            (f) => f.fieldId === selectedItem.fieldId
          );
        this.selectedFieldsWithKeyCtrlShift.splice(selectedItemFindIndex, 1);
      } else this.selectedFieldsWithKeyCtrlShift.push(value);
    },
    addSelectedFieldsWithKeyShift(params) {
      if (!this.isPanelTypeTable) return; //used for table chart for now.

      const fieldList = params.fieldList;
      const firstItemIndex = fieldList.findIndex(
        (x) => x.fieldId === this.selectedFieldsWithKeyCtrlShift[0]?.fieldId
      );
      const lastItemIndex = fieldList.findIndex(
        (x) => x.fieldId === params.value.fieldId
      );

      fieldList.forEach((element, index) => {
        const addedField = this.getSelectedFieldWithKeyCtrlShiftByFieldId(
          element.fieldId
        );

        if (index >= firstItemIndex && index <= lastItemIndex && !addedField) {
          this.selectedFieldsWithKeyCtrlShift.push(element);
        }
      });
    },
    getSelectedFieldWithKeyCtrlShiftByFieldId(fieldId) {
      return this.selectedFieldsWithKeyCtrlShift.find(
        (f) => f.fieldId === fieldId
      );
    },
    setSelectedPanelStyleByNoKey(property, value) {
      if (this.selectedPanel.properties.style[property] === undefined) {
        this.setSelectedPanelStyle({
          property,
          value,
        });
      }
    },
    //Chart'ların defaultta DynamicTitleValue properties alanı olmadığı için kontrol sağlatamıyoruz. Bundan kaynaklı eğer undefined ise
    // chart'ın name'ini dynamicTitle properties kısmına set ettiriyoruz.
    checkHasDynamicTitleValue(property) {
      const panelDataPropertiesStyle =
        this.selectedPanel.properties.style[property];
      if (
        !panelDataPropertiesStyle ||
        (panelDataPropertiesStyle && !this.dynamicTitleShowValue)
      ) {
        this.setSelectedPanelStyle({
          property,
          value: this.selectedPanel.name,
        });
      }
    },
    checkHasGroupMultipleValues(property, defaultValue) {
      const panelDataPropertiesStyle =
        this.selectedPanel.properties.style[property];

      if (panelDataPropertiesStyle === undefined) {
        this.setSelectedPanelStyle({
          property,
          value: defaultValue,
        });
      }
    },

    checkHasUniqueName(property) {
      if (this.selectedPanel.properties.style[property] == undefined) {
        const date = new Date();
        const uniqueDate = date.getTime().toString();

        this.setSelectedPanelStyle({
          property,
          value: `Parameter${uniqueDate.substring(5, 10)}`,
        });
      }
    },

    checkFilterTypeIsCustom() {
      if (this.isChartTypeFilter) {
        const customFilter = this.getCustomFilterParamsMappedProperty();

        if (customFilter) {
          let newObj = {};
          Object.keys(customFilter).forEach((element) => {
            newObj[element] = customFilter[element];
          });
          return {
            key: newObj,
            isDisabled: true,
          };
        } else
          return {
            key: {},
            isDisabled: false,
          };
      }
    },

    getCustomFilterParamsMappedProperty() {
      const fieldList = [
        ...this.selectedPanel?.details?.metrics,
        ...this.selectedPanel?.details?.customFilters,
      ];

      return fieldList
        .filter(
          (x) => x.mandatory !== undefined || x.multi_select !== undefined
        )
        .map((m) => {
          return {
            [this.getPanelPropertyKey(includesValues.MANDATORY)]: m.mandatory,
            [this.getPanelPropertyKey(includesValues.ALLOW_MULTISELECT)]:
              m.multi_select,
            [this.getPanelPropertyKey(includesValues.FILTER_DEFAULT_SELECTION)]:
              m?.default_value ? DefaultValue.SELECTION : DefaultValue.NONE,
          };
        })[0];
    },

    getPanelPropertyKeyMultiple(includesKeys) {
      const keys = Object.keys(this.selectedPanel.properties.style);
      const newKeys = [];

      includesKeys.forEach((element) => {
        const selectedKey = keys.find((x) => x.includes(element));

        newKeys.push(selectedKey);
      });

      return newKeys;
    },
    getPanelPropertyKey(includesKey) {
      const keys = Object.keys(this.selectedPanel.properties.style);
      const selectedKey = keys.find((x) => x.includes(includesKey));
      return selectedKey;
    },
    //For Filter Chart Component.
    //Dataset listesinden slot'a sürükle bırak yapıldığında bu method çalışır.
    changeFilterChartPropertyValue() {
      if (this.isChartTypeFilter) {
        const customFilterPropertyValues =
          this.getCustomFilterParamsMappedProperty();

        const selectedKeys = this.getPanelPropertyKeyMultiple([
          includesValues.MANDATORY,
          includesValues.ALLOW_MULTISELECT,
          includesValues.FILTER_DEFAULT_SELECTION,
        ]);

        if (customFilterPropertyValues) {
          selectedKeys.forEach((element) => {
            if (element)
              this.setSelectedPanelStyle({
                property: element,
                value: customFilterPropertyValues[element],
              });
          });
        }
      }
    },

    async getCustomFilterParams() {
      const datasetsByDatamodelId = this.selectedDataModel.datasets;

      for (let index = 0; index < datasetsByDatamodelId.length; index++) {
        const element = datasetsByDatamodelId[index];
        await this.fetchDatasetByIdFilterParams({
          loadingComponent: LoadingComponent.CustomFilters,
          id: element.datasetId,
        });
      }
    },
    async getDatasetFieldsControl() {
      if (this.panelId) {
        await this.fetchChartByIdFields(this.panelId);
      } else if (this.dashboardId)
        await this.fetchDashboardByIdFields(this.dashboardId);
    },
    mergeCardAndTemplateStyleAsCSS() {
      const merged = this.mergeCardAndTemplateStyle();
      const resultStyle = {};

      Object.keys(merged).forEach((propKey) => {
        const convertedCSSKey = propertiesKeyToCSSKeyMapping[propKey];

        if (convertedCSSKey) resultStyle[convertedCSSKey] = merged[propKey];
      });

      return resultStyle;
    },
    mergeCardAndTemplateStyle() {
      return {
        ...this.selectedTemplateProperties,
        ...this.selectedPanel?.properties?.style,
      };
    },
    mergeCardAndTemplateStyleSingleProperty(propKey) {
      return this.mergeCardAndTemplateStyle()?.[propKey];
    },
    hasBorderinChartCard() {
      return this.mergeCardAndTemplateStyle?.[allPropertiesKeys.HAS_BORDER]
        ? "vis-border"
        : "";
    },

    setSelectedPanelStyle(params) {
      let selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.properties.style[params.property] = params.value;

      selectedPanelCloned = this.updateTableDataForSearchParamByAggregateResult(
        selectedPanelCloned,
        params
      );

      selectedPanelCloned = this.updateGeoMapForSearchParamByAggregateResult(
        selectedPanelCloned,
        params.value
      );

      this.updateSelectedPanel({ value: selectedPanelCloned });

      if (
        this.isChartTypeGeoMap &&
        diff(this.selectedPanel.details, selectedPanelCloned.details)
      ) {
        this.viewChart();
      }
    },

    updateTableDataForSearchParamByAggregateResult(
      selectedPanelCloned,
      params
    ) {
      if (
        selectedPanelCloned.type === chartTypes.TABLE &&
        params.property ===
          datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
      ) {
        selectedPanelCloned =
          changeVisTypeForSearchParamByAggregateResult(selectedPanelCloned);

        selectedPanelCloned =
          setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);
      }
      return selectedPanelCloned;
    },
    updateGeoMapForSearchParamByAggregateResult(selectedPanelCloned, value) {
      if (this.isChartTypeGeoMap && countryValues.TR.includes(value)) {
        selectedPanelCloned.details.aggregation =
          selectedPanelCloned?.details?.aggregation?.filter(
            (x) => x.boxKey === BOX_KEY_ENUM.CITY.KEY
          );
      }
      return selectedPanelCloned;
    },
    // Column based formattings setter method
    setDataTableColumnBasedComponentProperties(params) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      if (!selectedPanelCloned.properties?.columnBasedFormattings) {
        selectedPanelCloned.properties.columnBasedFormattings = [];
      }

      const findExistingColumnBasedFormattings = () => {
        return selectedPanelCloned.properties.columnBasedFormattings.find((f) =>
          this.selectedColumnBasedPropertiesColumn.slotFieldId
            ? f.slotFieldId ===
              this.selectedColumnBasedPropertiesColumn.slotFieldId
            : f.fieldId === this.selectedColumnBasedPropertiesColumn?.fieldId
        );
      };

      const filterExistingColumnBasedFormattingsByColumn = () => {
        return selectedPanelCloned.properties.columnBasedFormattings.filter(
          (f) =>
            this.selectedColumnBasedPropertiesColumn?.slotFieldId
              ? f.slotFieldId !==
                this.selectedColumnBasedPropertiesColumn.slotFieldId
              : f.fieldId !== this.selectedColumnBasedPropertiesColumn?.fieldId
        );
      };

      const updateColumnBasedFormattings = () => {
        let foundExistingColumnBasedFormattings =
          findExistingColumnBasedFormattings();

        if (!foundExistingColumnBasedFormattings) {
          foundExistingColumnBasedFormattings = {
            formattings: {},
            fieldId: this.selectedColumnBasedPropertiesColumn?.fieldId,
            slotFieldId: this.selectedColumnBasedPropertiesColumn?.slotFieldId,
          };
        }

        foundExistingColumnBasedFormattings.formattings[params.property] =
          params.value;

        return foundExistingColumnBasedFormattings;
      };

      const updatedColumnBasedFormattingsByColumn =
        filterExistingColumnBasedFormattingsByColumn();

      updatedColumnBasedFormattingsByColumn.push(
        updateColumnBasedFormattings()
      );

      selectedPanelCloned.properties.columnBasedFormattings =
        updatedColumnBasedFormattingsByColumn;
      this.updateSelectedPanel({ value: selectedPanelCloned });
    },

    setDashboardStyle(params) {
      this.setDashboardProperty(params);
    },
    addFiltersToPanelFromPanel(param) {
      param.router = routerEnums.PANEL;
      param.chartList = this.chartList;
      this.$root.$emit("addFiltersToPanelFromPanel", param);
    },

    setConfirm() {
      this.confirmLeave = true;
      this.dialogVisible = false;
      this.$router.push({ name: this.beforeRouteLink });
    },

    updateSelectedPanel(params) {
      params.tabIndex = this.tabIndex;
      params.panelId = this.queryPanelId;
      this.setSmartTitle(params.value);
      this.setSelectedPanel(params);
    },
    updateSelectedPanelAndViewChart(params) {
      this.updateSelectedPanel(params);
      this.viewChart();
    },
    mapSelectedPanelAndViewChart(panel) {
      this.updateSelectedPanelAndViewChart({
        value: panel,
      });
    },
    mapSelectedPanelUpdate(panel) {
      this.updateSelectedPanel({
        value: panel,
      });
    },
    async setSmartTitle(chartDetails) {
      const {
        name,
        properties: { smartTitle },
      } = chartDetails;

      if (!name || (name && smartTitle)) {
        chartDetails.name = createSmartTitle(chartDetails);
        chartDetails.properties.smartTitle = true;
      }
    },
    async updatePanel(payload) {
      await this.updateDashboardPanel({
        loadingComponent: LoadingComponent.DashboardPage,
        payload,
      });
    },
    async saveAndPreviousDashboard() {
      //Eğer chartın filter listesine field eklendi ve filtre değeri atanmadıysa eklenen fieldı sildirmek için. Çünkü backend filter içindeki fieldlardan value değeri bekler.
      this.deleteNoneValueInFilterList();

      setDefaultProperties(this?.selectedPanel?.type, {
        checkHasDynamicTitleValue: this.checkHasDynamicTitleValue,
        checkHasGroupMultipleValues: this.checkHasGroupMultipleValues,
        checkHasUniqueName: this.checkHasUniqueName,
        setSelectedPanelStyleByNoKey: this.setSelectedPanelStyleByNoKey,
      })?.fn?.forEach((f) => {
        f();
      });

      this.setDefaultAuthorizeValueInFields();

      if (this.dashboardId) {
        this.isChartsAvailable = false;
        this.goDashboardEdit();
      } else {
        this.goDashboardPage();
      }
    },

    previousDashboard() {
      this.updateSelectedPanel({ value: this.tempSelectedPanel });

      this.goDashboardPage();
    },

    goDashboardPage() {
      this.isChartsAvailable = false;
      if (this.dashboardId) this.goDashboardEdit();
      else this.goDashboard();
    },

    goDashboard() {
      setTimeout(() => {
        this.$router.push({
          name: routerEnums.DASHBOARD,
          params: { previousPagePanel: true },
          query: { tabIndex: this.tabIndex },
        });
      }, 500);
    },
    goDashboardEdit() {
      setTimeout(() => {
        this.$router.push({
          name: routerEnums.DASHBOARD_EDIT,
          params: { id: this.dashboardId, previousPagePanel: true },
          query: { tabIndex: this.tabIndex },
        });
      }, 500);
    },

    deleteNoneValueInFilterList() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      const panelFilterList = selectedPanelCloned.details.filters;

      panelFilterList.forEach((element, index) => {
        if (!element.value) {
          panelFilterList.splice(index, 1);
          this.updateSelectedPanel({ value: selectedPanelCloned });
        }
      });
    },

    async fetchAndControlColorPalettes() {
      const colorPalettes = await this.fetchColorPalettes({
        loadingComponent: LoadingComponent.ColorPalettes,
      });
      if (colorPalettes.length < 2) {
        defaultColorPalettes.forEach(async (palette) => {
          await this.createColorPalettes({
            name: palette.name,
            paletteColors: palette.paletteColors,
          });
        });
      }
    },

    setPropertiesStyle(selectedPanelCloned, chartTypeToChange) {
      let chartUsedChartType = this.selectedPanel?.type;
      const propertiesStyle = {};

      if (this.isChartTypeFilter) {
        //Bu methodu kullanma sebebimiz filtre componentinin chart tipi ile properties style içindeki key'leri aynı yapmak için.
        const changeValueTextToFilterText = (value) => {
          value = value.substring(value.indexOf("_"));
          value = value.slice(1) + "_FILTER";
          return value;
        };

        chartTypeToChange = changeValueTextToFilterText(chartTypeToChange);
        chartUsedChartType = changeValueTextToFilterText(chartUsedChartType);
        selectedPanelCloned.details.FILTER_VALUE = null;
      }

      Object.keys(selectedPanelCloned?.properties?.style).forEach(
        (styleKey) => {
          const defaultStyleKey = cloneDeep(styleKey);

          if (styleKey.includes(chartUsedChartType)) {
            styleKey = styleKey.replace(chartUsedChartType, chartTypeToChange);
          }
          propertiesStyle[styleKey] =
            selectedPanelCloned?.properties?.style[defaultStyleKey];
        }
      );
      selectedPanelCloned.properties.style = propertiesStyle;
    },
    changeChartType(chartItem) {
      const chartTypeToChange = chartItem.id;
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.type = chartTypeToChange;
      selectedPanelCloned.chartTypeName = chartItem.name;

      this.setPropertiesStyle(selectedPanelCloned, chartTypeToChange);
      this.chartType = chartTypeToChange;

      if (chartTypeToChange == chartTypes.TABLE) {
        selectedPanelCloned.properties.vismetadata.vistype = chartTypes.TABLE;
        for (
          let index = 0;
          index < selectedPanelCloned.details.metrics.length;
          index++
        ) {
          selectedPanelCloned.details.metrics.splice(index, 1);
        }
      } else {
        checkLimitAndSetPanelFields(selectedPanelCloned);
        selectedPanelCloned.properties.vismetadata.vistype = chartTypes.OTHER;
      }

      this.updateSelectedPanel({ value: selectedPanelCloned });
      this.viewChart();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.diffDashboardData = diff(this.dashboard, this.tempSelectedDashboard);
    if (
      Object.keys(this.diffDashboardData).length &&
      to.name === routerEnums.HOME
    ) {
      this.dialogVisible = true;
      this.beforeRouteLink = to.name;
      if (this.confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
<style scoped>
.vis-chart-card.min-h-89_5 {
  min-height: 89.5vh;
}
.vis-not-border {
  border: none !important;
}
::v-deep .e-gridcontent {
  height: calc(100% - 160px) !important;
}
</style>
