import { Notification } from "element-ui";
import { routerEnums } from "./Enum";

export const Notify = (
  message,
  type,
  title,
  dangerouslyUseHTMLString = false
) => {
  Notification({
    title,
    message,
    type,
    duration: 3000,
    customClass: "vis-notification center",
    dangerouslyUseHTMLString,
  });
};

export const checkDashboardName = (routeName) => {
  if (
    routeName == routerEnums.DASHBOARD ||
    routeName == routerEnums.DASHBOARD_EDIT ||
    routeName == routerEnums.DASHBOARD_VIEW
  ) {
    return true;
  }
  return false;
};

export const isRouteDatamodel = (routeName) => {
  if (
    routeName == routerEnums.DATAMODEL ||
    routeName == routerEnums.DATAMODEL_VIEW ||
    routeName == routerEnums.DATA
  ) {
    return true;
  }
  return false;
};

export const isRouteIngest = (routeName) => {
  if (
    routeName == routerEnums.INGEST_CREATE ||
    routeName == routerEnums.INGEST_EDIT
  ) {
    return true;
  }
  return false;
};
