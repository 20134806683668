<template>
  <div>
    <Datamodel ref="dataModelComponent" />
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Datamodel from "./Datamodel";
import {
  TEMP_STORAGE_KEYS,
  GETTER as GETTER_TEMP_STORAGE,
} from "../store/modules/temp-storage/types";

export default {
  components: {
    Datamodel,
  },
  computed: {
    ...mapGetters({
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
    }),
    tempDataModel() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_DATAMODEL);
    },
  },
};
</script>
