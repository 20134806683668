<template>
  <div>
    <div class="vis-filter-common-title">
      <slot name="title"></slot>
    </div>
    <div class="vis-filter-common-content vis-scroll-thin">
      <slot name="content"></slot>
    </div>
    <div class="vis-filter-common-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.vis-filter-common-title {
  position: relative;
  width: 100%;
  padding: 0 10px;
}
.vis-filter-common-content {
  position: relative;
  width: 100%;
  height: 220px;
}
.vis-filter-common-footer {
  margin: 15px 0;
}
</style>
