import Vue from "vue";
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import { NOTIFY_TITLE } from "../../../../commons/notifierTypes";

export const visExportMetaData = {
    state: {
        [STATE.EXPORT_METADATA_RECORDS]: [],
    },
    getters: {
        [GETTER.GET_EXPORT_METADATA_RECORDS]: (state) => {
            return state[STATE.EXPORT_METADATA_RECORDS];
        },
    },
    actions: {
        [ACTION.FETCH_EXPORT_METADATA_RECORDS]: async ({ commit }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ExportMetaData,
                    serviceMethodSub: serviceMethodSub.readExportMetaDataRecords,
                    successMutation: MUTATION.SET_EXPORT_METADATA_RECORDS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data.data,
                    withLoading: false,
                    withNotify: false
                },
            );
        },
        [ACTION.CREATE_EXPORT_METADATA]: async ({ commit, dispatch }, bodyParam) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ExportMetaData,
                    serviceMethodSub: serviceMethodSub.createExportMetaData,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    notifyTitle: NOTIFY_TITLE.START_EXPORT_FILE,
                },
            );
            dispatch(ACTION.FETCH_EXPORT_METADATA_RECORDS);
        },
        [ACTION.FETCH_EXPORT_METADATA_SHARED_URL]: async ({ commit }, queryParam) => {
            const result = await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ExportMetaData,
                    serviceMethodSub: serviceMethodSub.readExportSharedUrl,
                    queryParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );

            window.open(result?.data, '_blank').focus();
        },
        [ACTION.DELETE_EXPORT_METADATA]: async ({ commit, dispatch}, exportId) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent:serviceMethodParent.ExportMetaData,
                    serviceMethodSub: serviceMethodSub.deleteExportMetaData,
                    queryParam: exportId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            dispatch(ACTION.FETCH_EXPORT_METADATA_RECORDS);
        },
        [ACTION.CREATE_EXPORT_NOTIFICATION]: async ({ commit }, bodyParam) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ExportMetaData,
                    serviceMethodSub: serviceMethodSub.createExportNotification,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    withSuccessNotify: false,
                },
            );
        },
    },
    mutations: {
        [MUTATION.SET_EXPORT_METADATA_RECORDS](state, items) {
            Vue.set(state, [STATE.EXPORT_METADATA_RECORDS], items);
        },
    },
};
