<template>
  <div class="vis-chart-card-input-box no-drag vis-chart-card-footer">
    <input
      tabindex="-1"
      type="text"
      class="vis-chart-card-footer-padding"
      @keyup="(event) => $emit('updateChartFootnote', event.target.value)"
      :value="footnoteValue"
      :style="inputStyle"
      :disabled="isModeDashboardView"
      :placeholder="placeHolder"
    />
  </div>
</template>
<script>
export default {
  props: {
    inputStyle: {
      type: Object,
    },
    footnoteValue: {
      type: String,
      default: "",
    },
    isModeDashboardView: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
      default: "",
    },
  },
};
</script>
