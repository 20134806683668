
import {
    fontsArr,
    rotateArr,
    fontSizesArr,
    fontWeightsArr,
    SCATTER_CHART_PROPERTIES_ENUM,
    predefineColorsArr,
    alignItems,
    locationItems,
    verticalAlignItems,
    yAxisPositionArr,
    allPropertiesKeys,
    autoRefreshTimes,
    legendMaxCharArr,
    legendPositionArr,
    showOnlyArr
} from "../../commons/dashboardProperties";
import { SCATTER_CHART_DEFAULT_CONFIGURATIONS} from "../../mocks/scatterChartDefaultProperties";
import ColorPaletteSelector from "../../components/panel/properties/ColorPaletteSelector";
import { getPropertiesWithUndefinedCheck } from "../../util/chartViewHelper";

export default {
    computed: {
        scatterChartComponentsMapping() {
            return {
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_VALUE_LABEL]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_VALUE_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_VALUE_LABEL
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_VALUE_LABEL,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_ONLY]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_ONLY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showOnly.title",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_ONLY
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_ONLY),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_ONLY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: showOnlyArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_INSIGHT
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_ALLOW_DRILL_THROUGH]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_ALLOW_DRILL_THROUGH,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.Allow Drill Through",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_ALLOW_DRILL_THROUGH
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_ALLOW_DRILL_THROUGH),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_ALLOW_DRILL_THROUGH,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_SQL]: {
                    id: this.getPropertyId(
                      SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_SQL
                      ],
                      SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_SQL,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_FAMILY
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_VALUE_LABEL_FONT_FAMILY),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_SIZE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_VALUE_LABEL_FONT_SIZE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_ROTATE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_ROTATE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.rotate",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_ROTATE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_VALUE_LABEL_ROTATE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_ROTATE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: rotateArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_COLOR
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_VALUE_LABEL_FONT_COLOR),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_POSITION]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Legend Position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_POSITION
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_LEGEND_POSITION),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_POSITION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendPositionArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.hideOverlap",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_LEGEND]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_LEGEND,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_LEGEND
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_LEGEND),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_LEGEND,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_MAX_CHARACTER]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_MAX_CHARACTER,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Max Character",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_MAX_CHARACTER
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_LEGEND_MAX_CHARACTER),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_MAX_CHARACTER,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendMaxCharArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_VERTICAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_LEGEND_VERTICAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_FAMILY
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_LEGEND_FONT_FAMILY),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_WEIGHT
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_LEGEND_FONT_WEIGHT),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_SIZE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_LEGEND_FONT_SIZE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_COLOR
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_LEGEND_FONT_COLOR),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_NAME]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_NAME
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_X_AXIS_NAME),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_NAME,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_VERTICAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_NAME_VERTICAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_SIZE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_NAME_FONT_SIZE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_COLOR
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_NAME_FONT_COLOR),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_NAME]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_NAME
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_Y_AXIS_NAME),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_NAME,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_VERTICAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_NAME_VERTICAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_LOCATION]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_LOCATION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.location",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_LOCATION
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_NAME_LOCATION),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_LOCATION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: locationItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_LABEL]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_LABEL
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_X_AXIS_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_LABEL,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_ROTATE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_ROTATE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.rotate",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_ROTATE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_LABEL_ROTATE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_ROTATE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: rotateArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_LABEL]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_LABEL
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SHOW_Y_AXIS_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_LABEL,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.logarithmicScale",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.logarithmicScale",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_MIN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_MIN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.xAxis.startAt",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_MIN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_MIN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_MIN,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_MIN]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_MIN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.yAxis.startAt",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_MIN
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_MIN),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_MIN,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_POSITION]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.yAxis.position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_POSITION
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_POSITION),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_POSITION,
                    selectValues: yAxisPositionArr,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_SHOW_LINE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_SHOW_LINE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLine",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_SHOW_LINE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_SHOW_LINE),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_SHOW_LINE,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLineColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SELECTED_COLOR_PALETTE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SELECTED_COLOR_PALETTE,
                        this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SELECTED_COLOR_PALETTE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_SELECTED_COLOR_PALETTE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SELECTED_COLOR_PALETTE,
                    dashboardProperty: allPropertiesKeys.CUSTOM_COLOR_PALETTES,
                    componentType: this.componentTypes.OTHER,
                    component: ColorPaletteSelector
                },

                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_AUTO_REFRESH),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH_CUSTOM
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_AUTO_REFRESH_CUSTOM),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_VALUE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_DYNAMIC_TITLE_VALUE),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_GROUP_MULTIPLE_VALUES
                    ], SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
            }
        },
    },
};
