<template>
  <div class="vis-flex-scrollbar">
    <header>
      <div class="vis-container">
        <div class="vis-row">
          <div class="vis-col vis-pa--none vis-col-xl8 vis-col-l12 vis-col-m12">
            <HomeCreateCard
              :forDataFlowDoesUserHaveEditRoles="
                forDataFlowDoesUserHaveEditRoles
              "
              @createClicked="createClicked"
            />
          </div>
          <div class="vis-col vis-col-3 vis-col-xl4 vis-col-l12 vis-col-m12">
            <WelcomeBox />
          </div>
          <div
            class="vis-col vis-col-3 vis-col-xxxl2 vis-col-xl2 vis-col-s12 vis-tablet-hidden"
          >
            <img
              class="vis-header-image"
              :src="getHeaderImage()"
              :alt="imageName"
            />
          </div>
        </div>
      </div>
    </header>
    
    <div
      :class="{ 'vis-big-size': bigSize }"
      class="vis-flex-scrollbar"
    >
      <section class="vis-bg-color-white">
        <div class="vis-container">
          <div class="vis-row">
            <div class="vis-col vis-col-12 vis-mb--none">
              <HomeTabsAndActions ref="homeTabs"
                :iconName="iconName"
                :selectedProjectsLength="selectedProjects.length"
                :currentFolderId="currentFolderId"
                :folderNameList="folderNameList"
                :forDataFlowDoesUserHaveEditRoles="
                  forDataFlowDoesUserHaveEditRoles
                "
                :homePageSearchQueryData="homePageSearchQueryData"
                :isInSearch="isInSearch"
                :showCardOrTable="showCardOrTable"
                :isCreateFolderAuthorized="isCreateFolderAuthorized"
                @resetHomepageSearch="resetHomepageSearch"
                @resetHomepageSearchParameters="resetHomepageSearchParameters"
                @handleIsInSearch="handleIsInSearch"
                @handleSearchQueryData="handleSearchQueryData"
                @getAllProjects="getAllProjects"
                @viewFolder="viewFolder"
                :isActiveBulkDelete="
                  Boolean(selectedProjects.length) && !dialogVisibleBulkDelete
                "
                :paginationResult="paginationResult"
                @tabChanged="mapServerDataToProjects"
                @openNewFolderPopup="openNewFolderPopup"
                @changeViewCardOrTable="changeViewCardOrTable"
                @changeResize="changeResize"
                @closeHomePageSearch="closeHomePageSearch"
                @searchProjectList="handleHomePageSearch"
                @deleteSelectedProjects="dialogVisibleBulkDelete = true"
              />
            </div>
          </div>
        </div>
      </section>
      <div 
        v-loading="isHomepageListLoading"
        class="vis-scroll-thin vis-min-h-60" 
        :class="{'vis-container' : showCardOrTable}" 
        @scroll.stop="handleScroll"
      >
        <div class="vis-row">
          <div
            class="vis-col vis-col-12"
            :class="[
              {'vis-pa--none': showCardOrTable},
              {'vis-pl--05 vis-pr--none': !showCardOrTable}
            ]"
          >
            <HomeProjectCards
              v-if="showCardOrTable"
              :mappedProjects="mappedProjectsFilteredByProjectName"
              :selectedProjects="selectedProjects"
              @view="viewProject"
              @edit="editProject"
              @delete="deleteProjectConfirm"
              @share="clickShare"
              @move="clickMove"
              @properties="clickProperties"
              @duplicate="clickDuplicate"
              @addSelectedProjects="addSelectedProjects"
              @setSelectedProjects="selectedProjects = $event"
              @searchProjectList="handleHomePageSearch"
              @resetHomepageSearch="resetHomepageSearch"
              @updateFavorite="updateFavorite"
            />
            <HomeProjectTable
              v-else
              class="vis-mt--05"
              :mappedProjects="mappedProjectsFilteredByProjectName"
              :isInSearch="isInSearch"
              @view="viewProject"
              @edit="editProject"
              @properties="clickProperties"
              @duplicate="clickDuplicate"
              @share="clickShare"
              @move="clickMove"
              @setMappedProjectFavoriteById="setMappedProjectFavoriteById"
              @delete="deleteProjectConfirm"
              @handleScroll="handleScroll"
              @updateFavorite="updateFavorite"
            />
            <FolderSavePopup
              v-if="isActiveFolderPopup"
              :tableRows="folders"
              :folderId="folderId"
              :folderNameList="popupFolderNameList"
              @setFolderName="setFolderName"
              @getFoldersById="getFoldersById"
              @setFolderId="setFolderId"
              @removeTableClass="removeTableClass"
              @closeFolderSave="closeFolderSave"
            />
            <SelectFolderPopup
              v-if="isMoveClicked"
              :tableRows="folders"
              :folderId="folderId"
              :folderNameList="popupFolderNameList"
              @selectFolder="selectFolder"
              @getFoldersById="getFoldersById"
              @setFolderId="setFolderId"
              @removeTableClass="removeTableClass"
              @closeFolderSelect="closeFolderSelect"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        showAuthorizationPropertiesGeneral ||
        showAuthorizationPropertiesAccessPermissions
      "
      class="vis-position-fixed vis-dashboard-custom-col authorizationPropertiesContainer w350 vis-tools-box vis-ma--none vis-mobile-hidden vis-scroll-thin vis-height-100 vis-overflow-auto vis-z-index-11"
    >
      <AuthorizationProperties
        :showAuthorizationPropertiesGeneral="showAuthorizationPropertiesGeneral"
        :showAuthorizationPropertiesAccessPermissions="
          showAuthorizationPropertiesAccessPermissions
        "
        :isDashboardProperties="isDashboardProperties"
        :authorizationPropertiesItem="authorizationPropertiesItem"
        :currentUserUsername="currentUserUsername"
        @resetHomepageSearch="resetHomepageSearch"
        @searchProjectList="handleHomePageSearch"
        @close="closeAuthorizationProperties"
        @refresh="init"
      />
    </div>
    <ConfirmDialog
      :title="deleteDialogTitle()"
      :message="deleteDialogMessage()"
      :dialogVisible="dialogVisible"
      @confirm="deleteProject"
      @cancel="dialogVisible = $event"
    />
    <ConfirmDialog
      :title="$t('dialog.deleteBulkProjectTitle')"
      :message="$t('dialog.deleteBulkProjectMessage')"
      :dialogVisible="dialogVisibleBulkDelete"
      @confirm="deleteSelectedProjects"
      @cancel="dialogVisibleBulkDelete = $event"
    />
  </div>
</template>

<script>
import HomeCreateCard from "../components/home/HomeCreateCard.vue";
import HomeProjectCards from "../components/home/HomeProjectCards.vue";
import HomeProjectTable from "../components/home/HomeProjectTable.vue";
import FolderSavePopup from "../components/home/FolderSavePopup.vue";
import SelectFolderPopup from "../components/home/SelectFolderPopup.vue";
import WelcomeBox from "../components/home/WelcomeBox.vue";
import HomeTabsAndActions from "../components/home/HomeTabsAndActions.vue";
import {
  ACTION as ACTION_DASHBOARD,
  MUTATION as MUTATION_DASHBOARD,
} from "../store/modules/Visualize/Dashboard/types";
import {
  ACTION as ACTION_FOLDER,
} from "../store/modules/Visualize/Folder/types";
import {
  ACTION as ACTION_DATAMODEL,
} from "../store/modules/Visualize/DataModel/types";
import {
  ACTION as ACTION_DATAFLOW,
} from "../store/modules/Ingest/DataFlow/types";
import {
  ACTION as ACTION_SEARCH,
  GETTER as GETTER_SEARCH,
  MUTATION as MUTATION_HOME,
} from "../store/modules/Visualize/Home/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import { ACTION as ACTION_BULK_DELETE } from "../store/modules/Visualize/BulkDelete/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { HOMEPAGE_SEARH_PARAMS, HOMEPAGE_SEARH_QUERY_DATA_LIST, HOME_TABS_KEY, HOME_TABS_SORT_VALUES, SORT_BY} from "../commons/homeDataTypes";
import {
  mapDashboardToHomePageProjectItem,
  mapDatamodelToHomePageProjectItem,
  mapFolderToHomePageProjectItem,
  mapDataFlowToHomePageProjectItem,
MainCardAction,
} from "../util/homePageMappers";
import { CardType, ObjectType } from "../util/homePageMappers";
import { routerEnums } from "../commons/Enum";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";
import { CustomIcon } from "../assets/js/custom-icons";
import AuthorizationProperties from "../components/home/AuthorizationProperties.vue";
import { COMPONENT_PRIVILEGES, ROLES } from "../util/authorization";
import componentAvailabilityCheck from "../mixins/componentAvailabilityCheck";
import { parseQueryStringTopbar } from "../util/embeded/queryString";
import cloneDeep from "clone-deep";

export default {
  components: {
    HomeCreateCard,
    HomeProjectCards,
    HomeProjectTable,
    FolderSavePopup,
    WelcomeBox,
    HomeTabsAndActions,
    ConfirmDialog,
    AuthorizationProperties,
    SelectFolderPopup,
  },
  mixins: [componentAvailabilityCheck],
  data() {
    return {
      showCardOrTable: true,
      bigSize: false,
      iconName: {
        changeViewIcon: CustomIcon.ListView,
        resizeIcon: CustomIcon.OpenInFull,
        closeIcon: CustomIcon.Close,
      },
      imageName: "home-header-bg-image",
      mappedProjects: [],
      lastSelectedTab: HOME_TABS_KEY.ALL,
      projectName: "",
      dialogVisible: false,
      deleteProjectParams: {
        type: "",
        id: "",
      },
      showAuthorizationPropertiesGeneral: false,
      showAuthorizationPropertiesAccessPermissions: false,
      authorizationPropertiesItem: null,
      selectedProjects: [],
      isActiveFolderPopup: false,
      folderId: null,
      isMoveClicked: false,
      isInPopup: false,
      move: {
        type: "",
        id: "",
      },
      folderNameList: [],
      popupFolderNameList: [],
      dialogVisibleBulkDelete: false,
      currentFolderId: null,
      isDashboardProperties: false,
      scrollPosition: null,
      homepageQueryParam: "",
      isInSearch: false,
      homePageSearchQueryData: HOMEPAGE_SEARH_QUERY_DATA_LIST,
      popupSearchQueryData: {},
      folders: [],
      ObjectType : ObjectType,
      HOMEPAGE_SEARH_PARAMS: HOMEPAGE_SEARH_PARAMS,
      paginationResult: {},

    };
  },
  watch: {
    isHomepageListLoading: {
      handler(val) {
        if (val) return;
        this.changeCardColMaxWidthByResponsive();
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.changeCardColMaxWidthByResponsive);
    this.resetHomepageSearch();
  },
  created() {
    window.addEventListener('resize', this.changeCardColMaxWidthByResponsive);
  },
  mounted() {
    this.init();
    this.isActiveFolderPopup;
  },
  computed: {
    ...mapGetters({
      loading: GETTER_GENERAL.GET_LOADING,
      homepageSearch: GETTER_SEARCH.GET_SEARCH_HOMEPAGE_RESULTS,
      homepagePagination: GETTER_SEARCH.GET_HOMEPAGE_PAGINATION,
      currentUserRoles: "Security/Get/Keycloak/Roles",
    }),
    forDataFlowDoesUserHaveEditRoles() {
      return this.currentUserRoles?.some((r) =>
        [ROLES.ADMIN.key, ROLES.DATAFLOW_EDITOR.key].includes(r)
      );
    },
    isHomeCardEditVisible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_CARD_EDIT
      );
    },
    isCreateFolderAuthorized() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_FOLDER_CREATE_VISIBLE
      );
    },
    isMoveDashboardToFolderAuthorized() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DASHBOARD_TO_FOLDER
      );
    },
    isMoveDatamodelToFolderAuthorized() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DATAMODEL_TO_FOLDER
      );
    },
    isHomepageListLoading() {
      return this.loading[LoadingComponent.HomepageList];
    },
    
    mappedProjectsFilteredByProjectName() {
      if (this.projectName) {
        return this.mappedProjects.filter((item) => {
          return this.projectName
            ?.toLowerCase()
            ?.split(" ")
            ?.every((x) => item.name.toLowerCase().includes(x));
        });
      } else {
        return this.mappedProjects;
      }
    },
    currentUserUsername() {
      return this.$store.getters["Security/Get/Keycloak/Username"];
    },
  },
  methods: {
    ...mapActions({
      deleteDatamodel: ACTION_DATAMODEL.DELETE_DATAMODEL,
      moveDatamodel: ACTION_DATAMODEL.MOVE_DATAMODEL,
      moveDataFlow: ACTION_DATAFLOW.MOVE_DATAFLOW,
      deleteDashboard: ACTION_DASHBOARD.DELETE_DASHBOARD,
      moveDashboard: ACTION_DASHBOARD.MOVE_DASHBOARD,
      copyDashboard: ACTION_DASHBOARD.COPY_DASHBOARD,
      copyDatamodel: ACTION_DATAMODEL.COPY_DATAMODEL,
      addFolder: ACTION_FOLDER.CREATE_FOLDER,
      moveFolder: ACTION_FOLDER.MOVE_FOLDER,
      deleteFolder: ACTION_FOLDER.DELETE_FOLDER,
      bulkDeleteProjects: ACTION_BULK_DELETE.BULK_DELETE_PROJECTS,
      deleteDataFlowById: ACTION_DATAFLOW.DELETE_DATAFLOW_BY_ID,
      fetchHomePageSearch: ACTION_SEARCH.FETCH_HOMEPAGE_SEARCH,
    }),
    ...mapMutations({
      resetDashboardState: MUTATION_DASHBOARD.RESET_DASHBOARD,
      resetHomepageSearch: MUTATION_HOME.RESET_SEARCH_HOMEPAGE_RESULTS,
    }),
    updateFavorite(params) {
      this.mappedProjects?.find((x) => {
        if (x?.item?.id === params.item.id) {
          const starAction = x.mainActions.find((x) => x.actionKey === MainCardAction.STAR);

          this.$set(x.item, "favorite", !params.item.favorite);
          this.$set(
            starAction,
            "iconClassName",
            `vis-font-size-1-25 vis-cursor-pointer ${params.item.favorite ?  `vis-color-orange ${CustomIcon.StarInFull}` : CustomIcon.StarOutlined}`
          );
          if(this.lastSelectedTab === HOME_TABS_KEY.FAVORITES) {
            
            this.resetQueryDataExceptSearchKey()
            this.handleSearchQueryData({val: true, type: HOMEPAGE_SEARH_PARAMS.FAVORITE});
            this.handleHomePageSearch();
          }
        }
      });
    },
    changeCardColMaxWidthByResponsive() {
      let colList = document.querySelectorAll('.vis-user-tabs-content .vis-row .vis-col');
      let firstCardWidth = null;
      
      colList.forEach(col => {
        if (!firstCardWidth) {
          firstCardWidth = cloneDeep(col.clientWidth);
        } 
        if (firstCardWidth && col.clientWidth !== firstCardWidth) {
          col.style.maxWidth = firstCardWidth + 'px';
        }
      });
    },
    resetSearchParametersExceptType() {
      this.homePageSearchQueryData?.forEach((x) => 
      {
        if(x.key === HOMEPAGE_SEARH_PARAMS.FOLDER_ID) {
          x.value = "root";
        } else if(x.key !== HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE) {
          x.value = "";
        }
      })
      this.resetHomepageSearch();
    },
    resetHomepageSearchParameters(){
      this.homePageSearchQueryData?.forEach((x) => 
      {
        if(x.key === HOMEPAGE_SEARH_PARAMS.FOLDER_ID) {
          x.value = "root";
        } else x.value = "";
      })
      this.resetHomepageSearch();
    },
    resetQueryDataExceptSearchKey() {
      this.currentFolderId = null;
      this.homePageSearchQueryData?.forEach((x) => 
      {
        if(this.isInSearch && 
          x.key !== HOMEPAGE_SEARH_PARAMS.SEARCH_KEY) {
          x.value = "";
      } else if(x.key !== HOMEPAGE_SEARH_PARAMS.SEARCH_KEY && x.key !== HOMEPAGE_SEARH_PARAMS.FOLDER_ID) {
          x.value = "";
      }
    })
      this.resetHomepageSearch();
    },
    mapAccordingToTypeHomePageProjectItem(item) {
      let payload = {
        editHidden: !this.isHomeCardEditVisible,
        authorizationToMove: this.isMoveDatamodelToFolderAuthorized
      }
      if(item.objectType === ObjectType.DASHBOARD) {
        return mapDashboardToHomePageProjectItem([item], payload);
      } else if(item.objectType === ObjectType.DATAMODEL) {
        return mapDatamodelToHomePageProjectItem([item], payload);
      } else if(item.objectType === ObjectType.FOLDER) {
        return mapFolderToHomePageProjectItem([item], payload);
      } else if(item.objectType === ObjectType.DATAFLOW) {
        return mapDataFlowToHomePageProjectItem([item], payload);
      }
    },
    convertListToAccordingToType() {
      let projectList = [];
      this.homepageSearch.forEach((x) => projectList =  [...projectList, ...this.mapAccordingToTypeHomePageProjectItem(x)])

      return projectList;
    },
    getList(query) {
      return () => {
        this.resetQueryDataExceptSearchKey()
        this.handleSearchQueryData(query);
        this.handleHomePageSearch();
      }
    },
    async handleScroll(e) {
      let currentScrollPosition = e.srcElement.scrollTop;
      this.scrollPosition = e.srcElement.scrollHeight - e.srcElement.offsetHeight
      if((currentScrollPosition === this.scrollPosition) && 
          ((this.homepagePagination.number + 1) < this.homepagePagination.totalPages) &&
          (currentScrollPosition !== 0)) {
        this.handleSearchQueryData({val: this.homepagePagination.number + 1, type: HOMEPAGE_SEARH_PARAMS.PAGE});
        await this.handleHomePageSearch();
        this.changeCardColMaxWidthByResponsive();
      }
      this.scrollPosition = currentScrollPosition;
    },
    handleIsInSearch(value) {
      this.isInSearch = value;
    },
    handleSearchQueryData(data, queryData) {
      if(queryData !== this.popupSearchQueryData){
        this.homePageSearchQueryData.find((x) => x?.key === data.type).value = data.val;
      }else this.popupSearchQueryData.find((x) => x?.key === data.type).value = data.val;
    },
    setFolderId(id) {
      if(id !== this.folderId){
        if(!this.isInPopup){
          this.currentFolderId = this.folderId;
        }
        this.folderId = id;
      }
    },
    setMappedProjectFavoriteById(id) {
      let selectedProject = this.mappedProjects.find((x) => x.id === id);
      selectedProject.item.favorite = !selectedProject.item.favorite;
    },
    setPaginationResults(){
      if(this.homepagePagination.number !== 0) {
        this.paginationResult.number = this.homepagePagination.number;
        this.paginationResult.numberOfElements += this.homepagePagination.numberOfElements;
        this.paginationResult.totalPages = this.homepagePagination.totalPages;
      } else {
        this.paginationResult = this.homepagePagination;
      }
    },
    async handleHomePageSearch(queryData) {
      if(queryData !== this.popupSearchQueryData){
        queryData = this.homePageSearchQueryData
      }
      this.homepageQueryParam = ""
      queryData?.forEach((x) => x.value ? 
      (this.homepageQueryParam += (this.homepageQueryParam.length ? "&" : "") + 
      `${x.key}=${x.value}`) : "" )
      await this.fetchHomePageSearch(
       {
        loadingComponent: LoadingComponent.HomepageList,
        queryParam: this.homepageQueryParam
       } 
      );
      if(this.isInPopup) {
        this.folders = this.convertListToAccordingToType()
      } else {
        this.setPaginationResults()
        this.mappedProjects = this.convertListToAccordingToType()
      }

      this.updateAuthorizationPropertiesItem();
    },
    updateAuthorizationPropertiesItem() {
      const list = this.convertListToAccordingToType();
      const foundItem = list?.find(item => item.id === this.authorizationPropertiesItem?.item?.id);

      if (foundItem) {
        this.authorizationPropertiesItem.item = { ...foundItem.item };
      }
    },
    async init() {
      await this.handleHomePageSearch();
      this.parseQueryParamOnHomePage();
      this.resetDashboardState();
      this.resetAuthorizationProperties();
    },
    removeTableClass() {
      var row = document.querySelectorAll(".clickable td");
      for (let index = 0; index < row.length; index++) {
        const element = row[index];
        element.classList.remove("vis-selectedRow");
      }
    },
    closeFolderSave() {
      this.isActiveFolderPopup = !this.isActiveFolderPopup;
      this.isInPopup = false;
      if(this.isInSearch) this.isInSearch = false;
      this.getAllProjects();
      this.$refs["homeTabs"]?.resetTabValue();
    },
    async closeFolderSelect() {
      this.isMoveClicked = !this.isMoveClicked;
      this.isInPopup = false;
      this.popupFolderNameList = []
    },
    getAllProjects() {
      this.folderId = null;
      this.currentFolderId = null;
      this.folderNameList = [];
      this.popupFolderNameList = []
      this.resetHomepageSearchParameters();
      this.handleHomePageSearch();
    },
    checkBreadcrumb(id, name) {
      if (!id && !name) {
        if(this.isInPopup) {
          this.popupFolderNameList = [];
        }
        else {
          this.folderNameList = [];
        } 
      }
      if (id && name) {
        if(this.isInPopup) {
          const selectedBreadcrumb = this.popupFolderNameList.find((x) => x.id == id);
          if (!selectedBreadcrumb) {
            this.popupFolderNameList.push({ id, name });
          } else {
            const selectedFolderIndex = this.popupFolderNameList.findIndex(
              (y) => y.id == selectedBreadcrumb.id
            );
            this.popupFolderNameList = this.popupFolderNameList.filter(
              (f, index) => index <= selectedFolderIndex
            );
          }
        } else {
          const selectedBreadcrumb = this.folderNameList.find((x) => x.id == id);
          if (!selectedBreadcrumb) {
            this.folderNameList.push({ id, name });
          } else {
            const selectedFolderIndex = this.folderNameList.findIndex(
              (y) => y.id == selectedBreadcrumb.id
            );
            this.folderNameList = this.folderNameList.filter(
              (f, index) => index <= selectedFolderIndex
            );
          }
        }
      }
    },
    async getFoldersById(id, name) {
      if((!this.isInPopup || id) && !this.isInSearch && !this.folderId) {
        this.currentFolderId = id;
      }
      this.folderId = id;
      this.checkBreadcrumb(id, name);
      if(id || this.isInSearch) {
        if(this.isInPopup){
          this.handleSearchQueryData( {val: id, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID},  this.popupSearchQueryData);
          this.handleSearchQueryData({val: ObjectType.FOLDER, type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE}, this.popupSearchQueryData);
        }
        this.handleSearchQueryData({val: id, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID});
      }
      
      this.resetHomepageSearch();
      if(this.isInPopup) {
        if(!id) { this.handleSearchQueryData( {val: "root", type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID},  this.popupSearchQueryData); }
        await this.handleHomePageSearch(this.popupSearchQueryData);
      }else { await this.handleHomePageSearch(); }
    },
    selectFolder() {
      let itemId = this.move.id;
      this.handleMoveTypes(this.move.type, itemId, this.folderId);
      this.isInPopup = false;
      this.isMoveClicked = !this.isMoveClicked;
      this.popupFolderNameList = [];
    },

    setFolderName(id, val) {
      if(!this.isInPopup){
        this.currentFolderId = this.folderId;
      }
      this.isInPopup = false;
      this.popupFolderNameList = [];
      this.isActiveFolderPopup = !this.isActiveFolderPopup;
      this.saveFolder(id, { name: val }, this.currentFolderId);
      this.$refs["homeTabs"]?.resetTabValue();
    },
    async saveFolder(parentFolderId, bodyParam) {
      await this.addFolder({
        loadingComponent: LoadingComponent.HomePage,
        parentFolderId,
        bodyParam,
      });
      this.resetHomepageSearchParameters();
      if(this.currentFolderId) { this.handleSearchQueryData( {val: this.currentFolderId, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID} ) }
      await this.handleHomePageSearch()
    },

    parseQueryParamOnHomePage() {
      if (this.$route.query.embeded == "true") {
        const parsedQueryString = parseQueryStringTopbar(this.$route.query);

        this.$root.$emit("setTopbarQuery", parsedQueryString);
      }
    },
    resetAuthorizationProperties() {
      this.showAuthorizationPropertiesGeneral = false;
      this.showAuthorizationPropertiesAccessPermissions = false;
      this.authorizationPropertiesItem = null;
    },
    closeAuthorizationProperties() {
      this.showAuthorizationPropertiesGeneral = false;
      this.showAuthorizationPropertiesAccessPermissions = false;
      this.isDashboardProperties = false;
    },
    clickShare(item) {
      this.closeAuthorizationProperties();
      setTimeout(() => {
        this.showAuthorizationPropertiesAccessPermissions = true;
        this.authorizationPropertiesItem = item;
      }, 300);
    },
    async clickMove({ item }) {
      this.isInPopup = true;
      this.isMoveClicked = true;
      this.move.type = item.type;
      this.move.id = item.id;
      this.popupSearchQueryData = cloneDeep(this.homePageSearchQueryData)
      this.resetHomepageSearch();
      
      if(this.isInSearch){
        if(item?.folderId){
          this.checkBreadcrumb(item?.folderId, item?.folderNames[0]);
          this.handleSearchQueryData({val: item.folderId, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID},  this.popupSearchQueryData);
        } else {
          this.handleSearchQueryData({val: "root", type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID},  this.popupSearchQueryData);
        }
        this.handleSearchQueryData({val: "", type: HOMEPAGE_SEARH_PARAMS.SEARCH_KEY},  this.popupSearchQueryData);
        this.handleSearchQueryData({val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE},  this.popupSearchQueryData);
      }
      if(item.folderId && !this.isInSearch) {
        this.handleSearchQueryData({val: this.folderId, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID},  this.popupSearchQueryData);
      }
      
      this.handleSearchQueryData({val: ObjectType.FOLDER, type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE},  this.popupSearchQueryData);
      this.handleSearchQueryData({val: "",  type: HOMEPAGE_SEARH_PARAMS.PAGE}, this.popupSearchQueryData);
      await this.handleHomePageSearch(this.popupSearchQueryData)
      if(!this.isInSearch) {
        this.popupFolderNameList = cloneDeep(this.folderNameList);
      }
      
    },
    async handleMoveTypes(types, itemId, targetFolderId) {
      if (!targetFolderId) {
        this.folderId = null;
      }
      
      let payload = { itemId, targetFolderId};

      if (types === CardType.FOLDER) {
        await this.moveFolder(payload);
      }
      if (types === CardType.DASHBOARD) {
        await this.moveDashboard(payload);
      }
      if (types === CardType.DATAMODEL) {
        await this.moveDatamodel(payload);
      }
      if (types === CardType.DATAFLOW) {
        await this.moveDataFlow(payload);
      }
      this.resetHomepageSearch();
      if(!this.isInSearch && this.currentFolderId){
        this.handleSearchQueryData({val: this.currentFolderId, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID});
      } else if(!this.isInSearch && !this.currentFolderId){
        this.handleSearchQueryData({val: "root", type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID});
      } else if(this.isInSearch) {
        this.handleSearchQueryData({val: "", type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID});
      }
      await this.handleHomePageSearch();
    },
    clickProperties(item) {
      this.closeAuthorizationProperties();
      this.showAuthorizationPropertiesAccessPermissions = false;
      setTimeout(() => {
        this.showAuthorizationPropertiesGeneral = true;
        this.authorizationPropertiesItem = item;
      }, 300);
      if (item.item.type === CardType.DASHBOARD) {
        this.isDashboardProperties = true;
      }
    },
    async clickDuplicate(item) {
      if (item.item.type === CardType.DASHBOARD) {
        let payload = {
          id: item.item.id,
          name: item.item.name + " Copy",
        };
        await this.copyDashboard({ payload });
      } else if (item.item.type === CardType.DATAMODEL) {
        let payload = {
          id: item.item.id,
        };
        await this.copyDatamodel({ payload });
      }
      this.resetHomepageSearch();
      this.handleSearchQueryData({val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE});
      await this.handleHomePageSearch();
    },
    sortByProjectList(item) {
      let sortedProjects = this.mappedProjectsFilteredByProjectName;
      sortedProjects.sort((a, b) => {
        let fa = a[item?.value]?.toLowerCase(),
          fb = b[item?.value]?.toLowerCase();
        if (item?.type == SORT_BY.ASC) {
          return fa < fb ? -1 : 1;
        } else {
          return fa < fb ? 1 : -1;
        }
      });
    },
    createClicked(name) {
      this.resetHomepageSearchParameters();
      if (routerEnums.DASHBOARD == name) {
        this.resetDashboardState();
      }
      this.$router.push({ name });
    },
    viewProject({ type, id, name }) {
      if (type === CardType.FOLDER) {
        if(this.isInSearch && !this.showCardOrTable){
          this.changeViewCardOrTable();
        } 

        this.$refs["homeTabs"]?.resetSearchDataValue();
        this.isInSearch = false;
        this.viewFolder(id, name);
      }
      if (type === CardType.DASHBOARD) {
        this.resetHomepageSearchParameters();
        this.$router.push({
          name: routerEnums.DASHBOARD_VIEW,
          params: { id },
        });
      }
        
      else if (type === CardType.DATAMODEL) {
        this.resetHomepageSearchParameters();
        this.$router.push({
          name: routerEnums.DATAMODEL_VIEW,
          params: { id },
        });
      }
        
      else if (type === CardType.DATAFLOW) {
        this.resetHomepageSearchParameters();
        this.$router.push({
          name: routerEnums.INGEST_EDIT,
          params: { id },
        });
      }
        
    },
    viewFolder(id, name) {
      //for breadcrumb
      this.checkBreadcrumb(id, name);
      this.folderId = id;
      this.currentFolderId = id;
      this.resetHomepageSearchParameters();
      this.handleSearchQueryData({val: id, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID});
      this.handleHomePageSearch();
    },
    editProject({ type, id }) {
      this.resetHomepageSearchParameters();
      if (type === CardType.DASHBOARD)
        this.$router.push({
          name: routerEnums.DASHBOARD_EDIT,
          params: { id },
        });
      else if (type === CardType.DATAMODEL)
        this.$router.push({
          name: routerEnums.DATAMODEL_VIEW,
          params: { id },
        });
    },
    deleteProjectConfirm({ type, id }) {
      this.dialogVisible = true;
      this.deleteProjectParams.type = type;
      this.deleteProjectParams.id = id;
    },
    async deleteProject() {
      let payload = {
        queryParam: this.deleteProjectParams.id
      };
      if (this.deleteProjectParams.type === CardType.DASHBOARD) {
        await this.deleteDashboard(payload);
      } else if (this.deleteProjectParams.type === CardType.DATAMODEL) {
        await this.deleteDatamodel(payload);
      } else if (this.deleteProjectParams.type === CardType.FOLDER) {
        await this.deleteFolder(payload);
      } else if (this.deleteProjectParams.type === CardType.DATAFLOW) {
        await this.deleteDataFlowById(payload);
      }
      this.dialogVisible = false;
      this.resetHomepageSearch();
      await this.handleHomePageSearch();
    },
    deleteDialogTitle() {
      if (this.deleteProjectParams.type === CardType.DASHBOARD)
        return this.$t("dialog.deleteDashboardTitle");
      else if (this.deleteProjectParams.type === CardType.DATAMODEL)
        return this.$t("dialog.deleteDatamodelTitle");
      else if (this.deleteProjectParams.type === CardType.DATAFLOW)
        return this.$t("dialog.deleteDataflowTitle");
      else return this.$t("dialog.deleteFolderTitle");
    },
    deleteDialogMessage() {
      if (this.deleteProjectParams.type === CardType.DASHBOARD)
        return this.$t("dialog.deleteDashboardMessage");
      else if (this.deleteProjectParams.type === CardType.DATAMODEL)
        return this.$t("dialog.deleteDatamodelMessage");
      else if (this.deleteProjectParams.type === CardType.DATAFLOW)
        return this.$t("dialog.deleteDataflowMessage");
      else return this.$t("dialog.deleteFolderMessage");
    },
    async deleteSelectedProjects() {
      this.dialogVisibleBulkDelete = false;
      await this.bulkDeleteProjects(this.selectedProjects);
      this.selectedProjects = [];
    },
    addSelectedProjects(item) {
      this.selectedProjects.push(item);
    },
    mapServerDataToProjects(value) {
      this.lastSelectedTab = value;
      const renderTab = {
        [HOME_TABS_KEY.ALL]: this.getList({val: "", type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE}),
        [HOME_TABS_KEY.FOLDER]: this.getList({val: ObjectType.FOLDER, type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE}),
        [HOME_TABS_KEY.DATA_MODEL]: this.getList({val: ObjectType.DATAMODEL, type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE}),
        [HOME_TABS_KEY.DASHBOARD]: this.getList({val: ObjectType.DASHBOARD, type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE}),
        [HOME_TABS_KEY.DATA_FLOW]: this.getList({val: ObjectType.DATAFLOW, type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE}),
        [HOME_TABS_KEY.RECENTLY_USED]: this.getList({val: `${HOME_TABS_SORT_VALUES.UPDATED_DATE},${SORT_BY.DESC}`, type: HOMEPAGE_SEARH_PARAMS.SORT_PARAMS}),
        [HOME_TABS_KEY.SHARED_WITH_ME]: this.getList({val: true, type: HOMEPAGE_SEARH_PARAMS.SHARED_WITH_ME}),
        [HOME_TABS_KEY.MY_CONTENT]: this.getList({val: true, type: HOMEPAGE_SEARH_PARAMS.MY_CONTENT}),
        [HOME_TABS_KEY.FAVORITES]: this.getList({val: true, type: HOMEPAGE_SEARH_PARAMS.FAVORITE}),
      };

      this.mappedProjects = renderTab[value]();
    },
    async openNewFolderPopup() {
      this.isActiveFolderPopup = true;
      this.isInPopup = true;
      this.popupSearchQueryData = cloneDeep(this.homePageSearchQueryData);
      this.resetHomepageSearch();
      this.handleSearchQueryData({val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE},  this.popupSearchQueryData);
      this.handleSearchQueryData({val: "", type: HOMEPAGE_SEARH_PARAMS.SEARCH_KEY},  this.popupSearchQueryData);
      this.handleSearchQueryData({val: ObjectType.FOLDER, type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE},  this.popupSearchQueryData);
      await this.handleHomePageSearch(this.popupSearchQueryData);
      this.popupFolderNameList = cloneDeep(this.folderNameList);
    },
    changeViewCardOrTable() {
      this.showCardOrTable = !this.showCardOrTable;
      if (this.showCardOrTable) {
        this.iconName.changeViewIcon = CustomIcon.ListView;
      } else {
        this.iconName.changeViewIcon = CustomIcon.Grid;
      }
    },
    changeResize() {
      this.bigSize = !this.bigSize;
      if (this.bigSize) {
        this.iconName.resizeIcon = CustomIcon.CloseFullScreen;
      } else {
        this.iconName.resizeIcon = CustomIcon.OpenInFull;
      }
    },
    async closeHomePageSearch() {
      if(!this.showCardOrTable){
        this.changeViewCardOrTable();
      }
      this.homepageQueryParam = ""
      this.isInSearch = false;
      this.getAllProjects();
      this.$refs["homeTabs"]?.resetTabValue();
    },
    getHeaderImage() {
      var images = require.context("../assets/images/home/", false, /\.png$/);
      return images("./" + this.imageName + ".png");
    },
  },
};
</script>
<style scoped>
.authorizationPropertiesContainer {
  top: 48px;
  right: 0;
}
.vis-min-h-60{
  min-height: 60vh;
}
</style>
