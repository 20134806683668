<template>
  <div
    v-if="isShow"
    @click="$emit('closePopup', false)"
    :style="{ position: position, background: backgroundColor }"
    class="vis-overlay"
  ></div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default() {
        return "fixed";
      },
    },
    backgroundColor: {
      type: String,
      default() {
        return "rgba(0, 0, 0, 0.05)";
      },
    },
  },
};
</script>
<style scoped>
.vis-overlay {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 1;
}
</style>
