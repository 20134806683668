<template>
  <div>
    <!-- Code editing, highlight the content of the completion dialog box -->
    <div class="in-coder-panel">
      <textarea ref="textareaSql"></textarea>
    </div>
  </div>
</template>

<script>
// Introduce global instance
import CodeMirror from "codemirror";
// Core style
import "codemirror/lib/codemirror.css";
// After the theme is introduced, you need to specify the theme in options to take effect
import "codemirror/theme/idea.css";
import "codemirror/mode/sql/sql.js";

//Code completion prompt
import "codemirror/addon/hint/anyword-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/sql-hint.js";

export default {
  name: "CodeMirrorSql",
  props: {
    language: {
      type: String,
      default: null,
    },
    sql: {
      type: String,
      required: true,
    },
    hints: {
      type: Object,
      required: true,
    },
    textToBeAddedToSql: {
      type: String,
      default: "",
    },
  },
  watch: {
    textToBeAddedToSql: {
      handler(val) {
        this.addTextToCursorPosition(val);
      },
      deep: true,
    },
  },
  data() {
    return {
      code: "",
      mode: "shell",
      coder: null,
      options: {
        tabSize: 4,
        theme: "idea",
        lineNumbers: true,
        line: true,
        extraKeys: { Ctrl: "autocomplete" },
      },
      modes: [
        {
          value: "x-sql",
          label: "SQL",
        },
      ],
    };
  },
  mounted() {
    this._initialize();
  },
  methods: {
    /**
     * @return {void}
     */
    _initialize() {
      // Initialize the editor instance, pass in the text field object and default configuration to be instantiated
      this.coder = CodeMirror.fromTextArea(
        this.$refs.textareaSql,
        this.options
      );
      // Editor assignment
      this.coder.setValue(this.sql || this.code);

      this.coder.on("change", (coder) => {
        this.code = coder.getValue();

        if (this.$emit) {
          this.updateSql(this.code);
          this.$emit("input", this.code);
        }
      });

      this.changeMode("x-sql");

      this.setTableHint();
    },
    /**
     * @param {object} hintList
     * @return {void}
     */
    setTableHint() {
      let self = this;
      CodeMirror.commands.autocomplete = function (cm) {
        CodeMirror.showHint(cm, CodeMirror.hint.sql, {
          tables: self.hints,
        });
      };
    },
    /**
     * @param {string} language
     * @return {string} foundMode
     */
    _getLanguage(language) {
      // Find the incoming syntax type in the list of supported syntax types
      return this.modes.find((mode) => {
        // All values ignore case for comparison
        let currentLanguage = language.toLowerCase();
        let currentLabel = mode.label.toLowerCase();
        let currentValue = mode.value.toLowerCase();

        // Because the real value may not be standardized, for example, the real value of Java is x-java, so it is said that value and label are compared with the incoming syntax at the same time
        return (
          currentLabel === currentLanguage || currentValue === currentLanguage
        );
      });
    },

    addTextToCursorPosition(val) {
      let doc = this.coder.getDoc();

      doc.replaceSelection(val);

      this.$emit("resetTextToBeAddedToSql");
    },
    /**
     * @param {string} val
     * @return {void}
     */
    changeMode(val) {
      // Modify the syntax configuration of the editor
      this.coder.setOption("mode", `text/${val}`);
    },
    /**
     * @param {string} val
     * @return {void}
     */
    updateSql(val) {
      this.$emit("update:sql", val);
    },
  },
};
</script>
