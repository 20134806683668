<template>
  <CommonFilterLayout>
    <template v-slot:content>
      <MeasureRuleTab
        v-if="selectedTabValue == filterPopupTabValue.RULE"
        :measureRuleComponentList="measureRuleComponentList"
        @addMeasureRuleComponent="addMeasureRuleComponent"
        @setMeasureRuleComponentList="setMeasureRuleComponentList"
      />
    </template>
    <template v-slot:footer>
      <div class="vis-common-footer-body">
        <div class="vis-row">
          <div class="vis-col vis-col-12">
            <el-checkbox v-model="lowestLevelRecords">{{
              $t("filterComponents.applyLowestLevelRecords")
            }}</el-checkbox>
          </div>
        </div>
      </div>
      <div v-if="showFooterActions">
        <div class="vis-row-box vis-mb--1">
          <div class="vis-line-1"></div>
        </div>
        <FilterButton
          @closeFilters="closeFilters"
          @submitFilter="saveFilterByMeasure"
        />
      </div>
    </template>
  </CommonFilterLayout>
</template>
<script>
import CommonFilterLayout from "./helper/CommonFilterLayout.vue";
import FilterButton from "./FilterButton.vue";
import { filterPopupTabValue, measureFilterTabList } from "./js/filters";
import MeasureRuleTab from "./MeasureRuleTab.vue";
// import MeasureAdvancedTab from "./MeasureAdvancedTab.vue";
import cloneDeep from "clone-deep";
export default {
  components: {
    CommonFilterLayout,
    FilterButton,
    MeasureRuleTab,
    // MeasureAdvancedTab,
  },
  props: {
    selectedComponent: {
      type: String,
    },
    filterDetailData: {
      type: Object,
    },
    showFooterActions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      measureFilterTabList: measureFilterTabList,
      filterPopupTabValue: filterPopupTabValue,
      selectedTabValue: "",
      lowestLevelRecords: false,
      measureRuleComponentList: [{ operator: "", value1: "", value2: "" }],
    };
  },
  watch: {
    measureRuleComponentList: {
      handler() {
        if (!this.showFooterActions) {
          this.saveFilterByMeasure();
        }
      },
      deep: true,
    },
    lowestLevelRecords: {
      handler() {
        if (!this.showFooterActions) {
          this.saveFilterByMeasure();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.setDetailFilter();
    this.selectedTabValue = this.selectedComponent;
  },
  created() {
    // parent'dan gelen event'i kullanabilmek için
    this.$parent.$on("clearAllFilter", this.clearSelectedFilters);
  },
  methods: {
    closeFilters(value) {
      this.$emit("closeFilters", value);
    },
    addMeasureRuleComponent(param) {
      this.measureRuleComponentList.push(param);
    },
    setMeasureRuleComponentList(params) {
      this.measureRuleComponentList = params;
    },
    saveFilterByMeasure() {
      if (this.selectedComponent == filterPopupTabValue.RULE) {
        this.$emit("saveFilterByMeasure", {
          measureRuleFilterData: this.measureRuleComponentList,
          isCheckedLowestLevelRecords: this.lowestLevelRecords,
        });
      }
    },
    setDetailFilter() {
      if (this.filterDetailData?.filterValues?.length) {
        this.measureRuleComponentList = cloneDeep(
          this.filterDetailData.filterValues
        );
      }
      this.lowestLevelRecords = this.filterDetailData?.isChecked;
    },
    clearSelectedFilters() {
      this.measureRuleComponentList.forEach((element) => {
        this.$set(element, "value1", "");
        this.$set(element, "value2", "");
      });
      this.measureRuleComponentList = cloneDeep(this.measureRuleComponentList);
      this.lowestLevelRecords = false;
      this.filterQuery = "";
    },
  },
};
</script>
<style scoped>
.vis-common-footer-body {
  padding: 0 10px;
}
</style>
