/* eslint-disable */
import { ACTION } from "./types";
import { MUTATION_GENERAL } from "../General/types";
/* import _ from "lodash"; */
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const visFieldGroup = {
    actions: {
        [ACTION.CREATE_FIELD_GROUP]: async ({ commit }, { bodyParam }) => {
           return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.FieldGroup,
                    serviceMethodSub: serviceMethodSub.createFieldGroup,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result,
                    withLoading: true,
                    withNotify: true
                },
            );
        },
        [ACTION.UPDATE_FIELD_GROUP]: async ({ commit }, { queryParam, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.FieldGroup,
                    serviceMethodSub: serviceMethodSub.updateFieldGroup,
                    queryParam,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result,
                    withLoading: true,
                    withNotify: true
                },
            );
        },
    },
};
