<template>
  <div class="vis-row">
    <div class="vis-col vis-col-12 vis-mb--1">
      <el-select
        v-model="filterFormData.operator"
        :placeholder="$t('filterComponents.select_operator')"
      >
        <el-option
          v-for="item in dateOperatorSelectList"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="vis-row-box vis-mb--1">
      <div class="vis-line-1"></div>
    </div>
    <div class="vis-col vis-col-12" v-show="showOnlyStartDate">
      <label for="">{{
        showOnlyEndDate
          ? $t("filterComponents.start_date")
          : $t("filterComponents.date")
      }}</label>
      <el-date-picker
        v-model="filterFormData.startDate"
        type="datetime"
        format="dd/MM/yyyy"
        value-format="dd-MM-yyyy"
        :placeholder="$t('dateComponents.select_date_and_time')"
      >
      </el-date-picker>
    </div>
    <div class="vis-col vis-col-12" v-show="showOnlyEndDate">
      <label for="">{{
        showOnlyStartDate
          ? $t("filterComponents.end_date")
          : $t("filterComponents.date")
      }}</label>
      <el-date-picker
        v-model="filterFormData.endDate"
        type="datetime"
        format="dd/MM/yyyy"
        value-format="dd-MM-yyyy"
        :placeholder="$t('dateComponents.select_date_and_time')"
      >
      </el-date-picker>
    </div>
  </div>
</template>
<script>
import cloneDeep from "clone-deep";
import {
  dateOperatorSelectList,
  startDateOperators,
  endDateOperators,
} from "./js/filters";
export default {
  props: {
    dateFilterFormData: {
      type: Object,
    },
    filterDetailData: {
      type: Object,
    },
  },
  data() {
    return {
      filterFormData: {},
      dateOperatorSelectList: dateOperatorSelectList,
    };
  },
  watch: {
    filterFormData() {
      this.setFormData();
    },
  },
  computed: {
    showOnlyStartDate() {
      return Object?.values?.(startDateOperators).find(
        (x) => x == this.filterFormData.operator
      );
    },
    showOnlyEndDate() {
      return Object?.values?.(endDateOperators).find(
        (x) => x == this.filterFormData.operator
      );
    },
  },
  mounted() {
    this.setDetailFilter();
  },
  methods: {
    setFormData() {
      this.$emit("setSelectionFormData", this.filterFormData);
    },
    setDetailFilter() {
      if (this.filterDetailData?.filterValues) {
        this.filterFormData = cloneDeep(this.filterDetailData.filterValues);
      }
    },
  },
};
</script>
