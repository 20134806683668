<template>
  <div class="vis-error-card">
    <div class="vis-error-title vis-flex--spacebeetwen-Ycenter">
      <span>{{ $t("datamodel.errorDetails") }}</span>
      <div class="vis-error-title-rightbox">
        <i
          @click="$emit('closePopup')"
          aria-hidden="true"
          :class="customIcon.Close"
          class="vis-cursor-pointer vis-notification-delete"
        ></i>
      </div>
    </div>
    <div class="vis-error-subtitle">
      <span>{{ $t(`errorCode.${data.code}`) }}</span>
    </div>
    <div class="vis-error-card-sql">
      <div class="vis-error-sql-title vis-flex--spacebeetwen-Ycenter">
        <span>{{ $t("datamodel.sql") }}</span>
        <div class="vis-error-title-rightbox">
          <i
            @click="handleSqlQueryFieldVisibilty()"
            aria-hidden="true"
            :class="handleQueryVisibleIcon()"
            class="vis-cursor-pointer vis-mr--05"
          ></i>
          <i
            @click="copyToClipboard(data.params[0])"
            aria-hidden="true"
            :class="customIcon.Copy"
            class="vis-cursor-pointer"
          ></i>
        </div>
      </div>
      <div 
        v-show="queryFieldVisible"  
        class="vis-sql-query"
      >
        <el-card
          shadow="never"
        >{{ data.params }}
        </el-card>
      </div>
    </div>
    <div class="vis-error-card-message">
      <div class="vis-error-message-title vis-mt--2 vis-flex--spacebeetwen-Ycenter">
        <span>{{ $t("datamodel.errorMessage") }}</span>
        <div class="vis-error-title-rightbox">
          <i
            @click="copyToClipboard(data.message)"
            aria-hidden="true"
            :class="customIcon.Copy"
            class="vis-cursor-pointer"
          ></i>
        </div>
      </div>
      <div class="vis-sql-query">
        <el-card
          shadow="never"
        >{{ data.message }}
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../../assets/js/custom-icons";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
      queryFieldVisible: false,
    };
  },
  methods: {
    copyToClipboard(copySource) {
      navigator.clipboard.writeText(`${copySource}`).then(
        () => {
          alert(
            this.$t("notifyTitle.copiedToClipboard"),
            this.$t("notifyTitle.success")
          );
        },
        () => {
          alert(this.$t("notifyTitle.error"), this.$t("notifyTitle.warning"));
        }
      );
    },
    handleSqlQueryFieldVisibilty(){
      this.queryFieldVisible = !this.queryFieldVisible;
    },
    handleQueryVisibleIcon(){
      return this.queryFieldVisible ? CustomIcon.VisibilityOff : CustomIcon.VisibilityEye
    },
  },
};
</script>

<style scoped>
.vis-error-card {
  width: 1000px;
  height: 750px;
  z-index: 999;
  padding: 40px;
  border: 1px solid var(--layout-border-color);
  box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.019);
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.vis-error-sql-title,
.vis-error-message-title {
  position: relative;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}
.vis-notification-delete {
  font-size: 1.5rem !important;
}
.vis-error-title {
  position: relative;
  width: 100%;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.vis-error-subtitle {
  position: relative;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 20px;
}
.vis-sql-textarea,
.vis-error-textarea {
  min-height: 250px !important;
  margin-bottom: 20px;
}
::v-deep .el-card__body {
  height: 222px;
  overflow: auto;
}
</style>
