import {
  formatValueController,
  calculateGridDistances,
  getSelectedColorPaletteByProperty,
  getChartLabelFormatByAggregation,
  generateHTMLForTreemapTooltip,
  truncateText,
  setShowOnlyValue,
} from "../chartOptionsHelper";
import { setTreeMapChartItemStyle } from "../chart-filter/filterChartStyles";
import { getAlignPositionByLegend, getOrientPositionByLegend, isChartWidthLimited } from "../chartViewHelper";
import { ascDescSort } from "../sortHelper";
import { Orientation, TREEMAP_PROPERTIES_ENUM, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
export const getTreemapOptions = (treemapComputedValues, paramsFromSearch, panelData, isFullScreen) => {

  // sets itemStyle for treemap nodes everytime when dataSeries change or a node is highlighted.
  paramsFromSearch?.dataSeries?.forEach(serie => {
    setTreeMapChartItemStyle(serie, panelData);
  })

  let metricOrder;
  let aggregationOrder;
  panelData.details.aggregation.filter((item) => {
    aggregationOrder = item.order;
  });
  panelData.details.metrics.filter((item) => {
    metricOrder = item.order;
  });

  const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
    showLegend: treemapComputedValues.TREEMAP_SHOW_LEGEND,
    legendPosition: treemapComputedValues.TREEMAP_LEGEND_POSITION,
    legendMaxCharacter: treemapComputedValues.TREEMAP_LEGEND_MAX_CHARACTER,
    yAxisNameLocation: "end",
    panelData,
  });

  const optionsObject = {
    grid: {
      top: gridTop,
      bottom: gridBottom,
      left: gridLeft,
      right: gridRight,
      containLabel: true,
    },
    legend: {
      show: isChartWidthLimited(panelData.w) ? treemapComputedValues.TREEMAP_SHOW_LEGEND : false,
      orient: getOrientPositionByLegend(treemapComputedValues.TREEMAP_LEGEND_POSITION).toLowerCase(),
      left: getAlignPositionByLegend(treemapComputedValues.TREEMAP_LEGEND_POSITION),
      top: getOrientPositionByLegend(treemapComputedValues.TREEMAP_LEGEND_POSITION) === Orientation.VERTICAL ? "2%" : treemapComputedValues.TREEMAP_LEGEND_VERTICAL_ALIGN,
      textStyle: {
        fontWeight: treemapComputedValues.TREEMAP_LEGEND_FONT_WEIGHT,
        fontFamily: treemapComputedValues.TREEMAP_LEGEND_FONT_FAMILY,
        fontSize: treemapComputedValues.TREEMAP_LEGEND_FONT_SIZE,
        color: treemapComputedValues.TREEMAP_LEGEND_FONT_COLOR,
      },
      formatter: function (value) {
        return truncateText(value, treemapComputedValues.TREEMAP_LEGEND_MAX_CHARACTER);
      },
      type: "scroll",
    },
    tooltip: {
      show: true,
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      textStyle: {
        fontFamily: "Poppins",
        fontSize: 12,
      },
      formatter: function (info) {
        const treePath = info.treePathInfo.map((i) => getChartLabelFormatByAggregation(i.name,  paramsFromSearch?.aggregationFormats)).slice(1);
        const value = info.value;
        const nameList = info?.treePathInfo?.filter((i) => i.name !== info.seriesName)

        info.name = nameList?.map((n, index) => getChartLabelFormatByAggregation(n.name,  [paramsFromSearch?.aggregationFormats?.[index]])).join(" | ");

        return showOnlyTypesExceptOnlyItem.includes(treemapComputedValues.TREEMAP_SHOW_ONLY) ?
          setShowOnlyValue(
            info,
            paramsFromSearch,
            treemapComputedValues,
            TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_ONLY,
            dashboardSPEnums.TOOLTIP,
            panelData
          )
          :
          generateHTMLForTreemapTooltip(
            info.color,
            treePath.join(" | "),
            formatValueController(paramsFromSearch?.metricFormatsMapping?.[info?.seriesName], value),
            panelData?.details?.aggregation,
            panelData?.details?.metrics
          );
      },
    },
    series: paramsFromSearch?.dataSeries
      ? {
        data: aggregationOrder
          ? paramsFromSearch?.dataSeries.sort((a, b) => ascDescSort(a.name, b.name, aggregationOrder))
          : paramsFromSearch?.dataSeries,
        sort: metricOrder,
        type: "treemap",
        name: paramsFromSearch?.dataSeriesNames?.[0],
        breadcrumb: { show: false },
        top: gridTop,
        bottom: gridBottom,
        left: gridLeft,
        right: gridRight,
        roam: true,
        nodeClick: isFullScreen ? "zoomToNode" : false,
        label: {
          show: isChartWidthLimited(panelData.w) ? treemapComputedValues.TREEMAP_SHOW_VALUE_LABEL : false,
          fontSize: treemapComputedValues.TREEMAP_VALUE_LABEL_FONT_SIZE,
          fontFamily: treemapComputedValues.TREEMAP_VALUE_LABEL_FONT_FAMILY,
          fontWeight: treemapComputedValues.TREEMAP_VALUE_LABEL_FONT_WEIGHT,
          color: treemapComputedValues.TREEMAP_VALUE_LABEL_FONT_COLOR,
          formatter: function (value) {
            const treePath = value.treePathInfo
              .map((i) =>
                getChartLabelFormatByAggregation(
                  i.name,
                  paramsFromSearch?.aggregationFormats
                )
              )
              .slice(1);
            const nameList = value?.treePathInfo?.filter((i) => i.name !== value.seriesName)

            value.name = nameList?.map((n, index) => getChartLabelFormatByAggregation(n.name,  [paramsFromSearch?.aggregationFormats?.[index]])).join(" | ");
            
            return showOnlyTypesExceptOnlyItem.includes(
              treemapComputedValues.TREEMAP_SHOW_ONLY
            )
              ? setShowOnlyValue(
                  value,
                  paramsFromSearch,
                  treemapComputedValues,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_ONLY,
                  dashboardSPEnums.LABEL,
                  panelData
                )
              : treePath.join(" | ");
          },
        },
        levels: [
          {
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 0,
              gapWidth: 5,
            },
          },
          {
            colorSaturation: [0.5, 0.7],
            itemStyle: {
              gapWidth: 2,
            },
          },
          {
            colorSaturation: [0.4, 0.6],
            itemStyle: {
              gapWidth: 1,
            },
          },
          {
            colorSaturation: [0.3, 0.5],
            itemStyle: {
              gapWidth: 0.5,
            },
          },
          {
            colorSaturation: [0.25, 0.45],
            itemStyle: {
              gapWidth: 0.5,
            },
          },
        ],
        itemStyle: { borderColor: "#fff" }
      }
      : null,
    color: getSelectedColorPaletteByProperty(treemapComputedValues.TREEMAP_SELECTED_COLOR_PALETTE),
  };

  return {
    options: optionsObject,
  };
};
