export const domain = "TRANSFER_MIGRATE_CONTENT";

export const STATE = {
  DASHBOARDS_WITH_FOLDERS: `${domain}/DASHBOARDS_WITH_FOLDERS`,
  CONNECTIONS_BY_ENV: `${domain}/CONNECTIONS_BY_ENV`,
};

export const ACTION = {
  FETCH_DASHBOARDS_WITH_FOLDERS: `${domain}/FETCH_DASHBOARDS_WITH_FOLDERS`,
  FETCH_CONNECTIONS_BY_ENV: `${domain}/FETCH_CONNECTIONS_BY_ENV`,
  MOVE_OBJECT: `${domain}/MOVE_OBJECT`,
};

export const MUTATION = {
  SET_DASHBOARDS_WITH_FOLDERS: `${domain}/SET_DASHBOARDS_WITH_FOLDERS`,
  SET_CONNECTIONS_BY_ENV: `${domain}/SET_CONNECTIONS_BY_ENV`,
};
