<template>
  <div>
    <div class="vis-topbar vis-ma--none vis-setting-bar">
      <div class="vis-row vis-height-100">
        <div
          class="vis-dashboard-custom-col vis-flex--Ycenter vis-mobile-hidden vis-right-border"
        >
          <div class="vis-settings-tab-link">
            <div class="link-item">
              <span>{{ $t("ingest.Data Flow") }}</span>
            </div>
          </div>
        </div>
        <div
          class="vis-col vis-mb--none vis-text--right vis-flex--Xright vis-flex--Ycenter vis-mobile-hidden vis-icon-position"
        >
          <div
            v-if="showIngestActions"
            class="vis-display-flex vis-flex--Ycenter"
          >
            <i
              v-for="icon in iconList"
              :key="icon.id"
              class="vis-cursor-pointer"
              :class="[
                icon.className,
                {
                  'vis-active-setting-icon': icon.isActiveClass,
                },
                { 'vis-display-none': !icon.show },
              ]"
              @click="icon.action()"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  props: {
    showIngestActions: {
      type: Boolean,
      default: false,
    },
    isSaveAvailable: {
      type: Boolean,
      default: false,
    },
    showAside: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isProjectNamePopupOpen: false,
      customIcon: CustomIcon,
    };
  },
  computed: {
    iconList() {
      return [
        {
          id: 1,
          className: `${CustomIcon.Property} vis-mr--2`,
          action: () => this.$emit("toggleShowAside"),
          isActiveClass: this.showAside,
          show: true,
        },
        {
          id: 2,
          className: `${CustomIcon.Save} vis-mr--2`,
          action: () => this.$emit("save", true),
          show: this.isSaveAvailable,
        },
        {
          id: 3,
          className: `${CustomIcon.Close} vis-mr--1 e-icons e-large`,
          action: () => this.$router.push("/"),
          show: true,
        },
      ];
    },
  },
};
</script>

<style scoped>
.vis-settings-tab-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 5px;
}
.vis-settings-tab-link .link-item {
  display: flex;
  padding: 0 14px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  transition: all 0.3s ease;
}
.vis-settings-tab-link .link-item a {
  display: flex;
  align-items: center;
}

.vis-settings-tab-link .link-item.active {
  background-color: var(--primary-lighteen-1);
  width: 100%;
}
.vis-settings-tab-link .link-item.active span,
.vis-settings-tab-link .link-item.active i {
  display: inline-block;
  color: #333333 !important;
}

.add-new-dataset-plus-icon {
  font-size: 25px;
  margin-right: 10px;
}
.ingest-top-bar-breadcrumb {
  margin-left: 20px;
}
::v-deep .el-breadcrumb__inner {
  font-weight: bold !important;
}
.add-new-dataset-button {
  margin-right: 16px;
}
.vis-icon-position {
  padding-right: 4px !important;
}
</style>
