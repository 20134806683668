import { ACTION, MUTATION, GETTER, STATE} from "./types";
import { MUTATION_GENERAL } from "../General/types";

import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import { NOTIFY_TITLE } from "../../../../commons/notifierTypes";

export const visFolder = {
    state: {
        [STATE.FOLDERS]: [],
    },
    getters: {
        [GETTER.GET_FOLDERS]: (state) => {
            return state[STATE.FOLDERS];
        },
    },
    actions: {
        [ACTION.CREATE_FOLDER]: async ({ commit }, { loadingComponent, parentFolderId, bodyParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Folder,
                    serviceMethodSub: serviceMethodSub.createFolder,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    queryParam: parentFolderId ?? "",
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    loadingComponent,
                    notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
                },
            );
        },
        [ACTION.UPDATE_FOLDER_NAME]: async ({ commit, dispatch }, { queryParam, bodyParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Folder,
                    serviceMethodSub: serviceMethodSub.updateFolderName,
                    queryParam,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            await dispatch(ACTION.FETCH_FOLDER_BY_ID, queryParam)
        },
        [ACTION.DELETE_FOLDER]: async ({ commit }, { queryParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Folder,
                    serviceMethodSub: serviceMethodSub.deleteFolder,
                    queryParam: queryParam ?? "",
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
        },
        [ACTION.FETCH_FOLDERS]: async ({ commit }, { loadingComponent, folderId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Folder,
                    serviceMethodSub: serviceMethodSub.readFolderList,
                    successMutation: MUTATION.SET_FOLDERS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    queryParam: folderId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    loadingComponent
                },
            );
        },
        [ACTION.FETCH_FOLDER_BY_ID]: async ({ commit }, datamodelId) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Folder,
                    serviceMethodSub: serviceMethodSub.readFolderById,
                    queryParam: datamodelId,
                    successMutation: MUTATION.SET_SELECTED_FOLDER,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true
                },
            );
        },
        [ACTION.MOVE_FOLDER]: async ({ commit }, {itemId, targetFolderId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Folder,
                    serviceMethodSub: serviceMethodSub.moveFolder,
                    queryParam: { itemId, targetFolderId: targetFolderId ?? "" },
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
        },
    },
    mutations: {
        [MUTATION.SET_FOLDERS](state, items) {
            Vue.set(state, [STATE.FOLDERS], items);
        },
    },
}