<template>
  <div class="vis-tools-box vis-left-border vis-page vis-bg-color-white">
    <div class="propertiesTopBar vis-display-flex">
      <span class="vis-font-bold">{{ $t("generalPages.properties") }}</span
      ><i
        aria-hidden="true"
        class="vis-cursor-pointer vis-ml--auto e-icons e-large"
        :class="customIcon.Close"
        @click="$emit('close')"
      ></i>
    </div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in authorizationPropertiesCollapses"
        :key="collapse.key"
        :title="$t(`${collapse.title}`)"
        :name="collapse.key"
        :disabled="collapse.disabled"
      >
        <div
          v-if="collapse.key === authorizationPropertiesCollapseTypes.General"
        >
          <div
            v-for="row in generalDescriptionTable"
            :key="row.title"
            class="vis-mb--1"
          >
            <div class="vis-display-flex">
              <span class="vis-font-medium">{{ $t(`${row.title}`) }}</span>
              <span class="vis-ml--auto">{{ row.value }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="
            collapse.key ===
            authorizationPropertiesCollapseTypes.AccessPermissions
          "
        >
          <div class="vis-mb--1">
            <label>{{ $t("generalPages.searchUsersOrGroups") }}</label
            ><el-autocomplete
              class="vis-mb--1"
              style="width: 100%"
              :debounce="1000"
              v-model="autocompleteValue"
              :fetch-suggestions="querySearchAsync"
              :placeholder="$t('generalPages.searchInput')"
              @select="handleSelect"
            >
              <i
                class="el-input__icon addAction"
                :class="customIcon.Plus"
                slot="suffix"
                @click="handleCreateAuthorization"
                aria-hidden="true"
              >
              </i>
              <template slot-scope="{ item }">
                <div class="value">
                  <i
                    class="vis-mr--1"
                    :class="
                      item.type === userTypes.USER
                        ? 'el-icon-user'
                        : 'el-icon-office-building'
                    "
                    aria-hidden="true"
                  >
                  </i
                  ><span class="link">{{ item.value }}</span>
                </div>
              </template>
            </el-autocomplete>
            <vue-good-table
              max-height="400px"
              :columns="accessPermissionsColumns"
              :rows="accessPermissionsRows"
              :fixed-header="true"
              :sort-options="{
                enabled: false,
              }"
            >
              <template slot="table-column" slot-scope="props">
                <span>
                  {{ $t(`${props.column.label}`) }}
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <div
                  v-if="
                    props.column.field === accessPermissionsColumnsFields.Name
                  "
                >
                  <span
                    class="vis-float--left vis-field-item-span"
                    :title="props.row.username || props.row.name"
                    >{{ props.row.username || props.row.name }}</span
                  >
                </div>
                <div
                  v-else-if="
                    props.column.field ===
                    accessPermissionsColumnsFields.Authority
                  "
                >
                  <el-select
                    :value="props.row.authority"
                    :placeholder="$t('generalPages.permission')"
                    size="mini"
                    @change="(val) => onAuthorityChanged(val, props.row)"
                  >
                    <el-option
                      v-for="item in optionsAuthority(props.row)"
                      :key="item.key"
                      :label="item.label"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  v-else-if="
                    props.column.field ===
                    accessPermissionsColumnsFields.Actions
                  "
                >
                  <div>
                    <span
                      ><i
                        @click="callRemoveAuthorizationById(props.row.id)"
                        :class="customIcon.TrashCircle"
                        class="vis-cursor-pointer"
                        aria-hidden="true"
                    /></span>
                  </div>
                </div>
                <span v-else class="vis-limit-long-words">
                  {{ props.formattedRow[props.column.field] }}
                </span></template
              >
            </vue-good-table>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { formatISOToDateTime } from "../../../util/moment";
import { mapActions, mapGetters } from "vuex";
import { CustomIcon } from "../../../assets/js/custom-icons";

import { ACTION } from "../../../store/modules/Visualize/Authorization/types";
import {
  ACTION as ACTION_FIELD_AUTHORIZATION,
  GETTER as GETTER_FIELD_AUTHORIZATION,
} from "../../../store/modules/Visualize/FieldAuthorization/types";

const AuthorizationColumnBasedEnum = {
  DENY: "DENY",
};

export default {
  props: {
    authorizationPropertiesItem: {
      type: Object,
      required: true,
    },
    showGeneral: {
      type: Boolean,
      default: false,
    },
    showAccessPermissions: {
      type: Boolean,
      default: false,
    },
  },
  components: { VueGoodTable },
  data() {
    return {
      authorizationPropertiesCollapseTypes: {
        General: "General",
        AccessPermissions: "AccessPermissions",
      },
      autocompleteValue: "",
      timeout: null,
      activeCollapses: [],
      accessPermissionsColumnsFields: {
        Name: "name",
        Authority: "authority",
        Actions: "actions",
      },
      userTypes: {
        USER: "USER",
        GROUP: "GROUP",
      },
      usersAndGroups: [],
      selectedItem: null,
      generalForm: {
        name: "",
        description: "",
      },
      customIcon: CustomIcon,
      dialogVisible: false,
    };
  },
  watch: {
    authorizationPropertiesItem: {
      handler() {
        this.init();
      },
      deep: true,
    },
    showGeneral: {
      handler() {
        this.init(false);
      },
    },
    showAccessPermissions: {
      handler() {
        this.init(false);
      },
    },
  },
  async mounted() {
    await this.init();
  },
  computed: {
    ...mapGetters({
      getFieldAuthorizationByDatamodel:
        GETTER_FIELD_AUTHORIZATION.GET_FIELD_AUTHORIZATION_BY_DATAMODEL,
      getFieldAuthorizationByDataset:
        GETTER_FIELD_AUTHORIZATION.GET_FIELD_AUTHORIZATION_BY_DATASET,
    }),
    accessPermissionsRows() {
      return (
        this.getFieldAuthorizationByDataset?.filter(
          (item) => item.fieldId === this.authorizationPropertiesItem.fieldId
        ) ?? []
      );
    },
    accessPermissionsColumns() {
      return [
        {
          label: "generalPages.user",
          field: this.accessPermissionsColumnsFields.Name,
          width: "60px",
        },
        {
          label: "generalPages.permission",
          field: this.accessPermissionsColumnsFields.Authority,
        },
        {
          label: "generalPages.labelActions",
          field: this.accessPermissionsColumnsFields.Actions,
          width: "60px",
        },
      ];
    },
    authorizationPropertiesCollapses() {
      return [
        {
          title: "generalPages.general",
          key: this.authorizationPropertiesCollapseTypes.General,
        },
        {
          title: "generalPages.accessPermissions",
          key: this.authorizationPropertiesCollapseTypes.AccessPermissions,
        },
      ];
    },
    generalDescriptionTable() {
      return [
        {
          title: "generalPages.labelName",
          value: this.authorizationPropertiesItem?.alias ?? "-",
        },
        {
          title: "generalPages.labelDescription",
          value: this.authorizationPropertiesItem?.description ?? "-",
        },
        {
          title: "generalPages.labelOwner",
          value: this.authorizationPropertiesItem?.createdBy ?? "-",
        },
        {
          title: "generalPages.labelCreatedBy",
          value: this.authorizationPropertiesItem?.createdBy ?? "-",
        },
        {
          title: "generalPages.labelCreatedAt",
          value:
            formatISOToDateTime(
              this.authorizationPropertiesItem?.createdDate
            ) ?? "-",
        },
        {
          title: "generalPages.labelLastUpdatedBy",
          value: this.authorizationPropertiesItem?.updatedBy ?? "-",
        },
        {
          title: "generalPages.labelLastUpdatedAt",
          value:
            formatISOToDateTime(
              this.authorizationPropertiesItem?.updatedDate
            ) ?? "-",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: ACTION.FETCH_USERS,
      fetchGroups: ACTION.FETCH_GROUPS,
      fetchFieldAuthorizationByDatamodel:
        ACTION_FIELD_AUTHORIZATION.FETCH_FIELD_AUTHORIZATION_BY_DATAMODEL,
      fetchFieldAuthorizationByDataset:
        ACTION_FIELD_AUTHORIZATION.FETCH_FIELD_AUTHORIZATION_BY_DATASET,
      createFieldAuthorization:
        ACTION_FIELD_AUTHORIZATION.CREATE_FIELD_AUTHORIZATION,
      updateFieldAuthorization:
        ACTION_FIELD_AUTHORIZATION.UPDATE_FIELD_AUTHORIZATION,
      deleteFieldAuthorization:
        ACTION_FIELD_AUTHORIZATION.DELETE_FIELD_AUTHORIZATION,
    }),
    optionsAuthority() {
      return Object.values(AuthorizationColumnBasedEnum)?.map((value) => ({
        key: value,
        label: value,
      }));
    },
    async onAuthorityChanged(newAuthority, row) {
      await this.updateFieldAuthorization({
        fieldAuthorizationId: row.id,
        bodyParam: {
          authority: newAuthority,
          fieldId: row.fieldId,
          name: row.name,
          type: row.type,
          id: row.id,
        },
      });
      this.fetchFieldAuthorizationByDataset({
        datasetId: this.authorizationPropertiesItem.datasetId,
      });
    },
    async callRemoveAuthorizationById(id) {
      await this.deleteFieldAuthorization({ fieldAuthorizationId: id });
      this.fetchFieldAuthorizationByDataset({
        datasetId: this.authorizationPropertiesItem.datasetId,
      });
      this.$emit('getDatamodelFieldsAndFilters');
    },
    async init(fetch = true) {
      this.autocompleteValue = "";
      this.handleSelect(null);

      if (this.showGeneral) {
        this.activeCollapses = [
          this.authorizationPropertiesCollapseTypes.General,
        ];
      } else if (this.showAccessPermissions) {
        this.activeCollapses = [
          this.authorizationPropertiesCollapseTypes.AccessPermissions,
        ];
      }

      if (this.authorizationPropertiesItem.datasetId && fetch)
        this.fetchFieldAuthorizationByDataset({
          datasetId: this.authorizationPropertiesItem.datasetId,
        });
    },
    async loadUsersAndGroups(queryString) {
      const users = await this.fetchUsers(queryString ?? "");
      const groups = await this.fetchGroups();
      const userMapped =
        users?.map((u) => {
          return {
            value: u.username,
            id: u.id,
            type: this.userTypes.USER,
          };
        }) ?? [];
      const groupsMapped =
        groups?.map((g) => {
          return {
            value: g.name,
            type: this.userTypes.GROUP,
          };
        }) ?? [];

      return [...userMapped, ...groupsMapped];
    },
    async querySearchAsync(queryString, cb) {
      clearTimeout(this.timeout);
      if (queryString != "") {
        this.usersAndGroups = await this.loadUsersAndGroups(queryString);
        var usersAndGroups = this.usersAndGroups;
        var results = queryString
          ? usersAndGroups.filter(this.createFilter(queryString))
          : usersAndGroups;
        this.timeout = setTimeout(() => {
          cb(results);
        }, 2000 * Math.random());
      } else cb([]);
    },
    createFilter(queryString) {
      return (usersAndGroups) => {
        return (
          usersAndGroups.value
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.selectedItem = item;
    },
    async handleCreateAuthorization() {
      await this.createFieldAuthorization({
        authority: AuthorizationColumnBasedEnum.DENY,
        fieldId: this.authorizationPropertiesItem.fieldId,
        name: this.selectedItem.value,
        type: this.selectedItem.type,
      });
      this.fetchFieldAuthorizationByDataset({
        datasetId: this.authorizationPropertiesItem.datasetId,
      });
      this.$emit('getDatamodelFieldsAndFilters');
    },
  },
};
</script>

<style scoped>
label {
  font-weight: 500;
}
::v-deep .vgt-table.bordered th {
  padding: 5px 0px 5px 10px;
}
::v-deep .vgt-table.bordered td {
  padding: 5px 0px 5px 10px;
  text-align: center;
  vertical-align: middle;
}
::v-deep .vgt-table.bordered td:last-child {
  padding-right: 2px;
}
::v-deep .vgt-table thead tr {
  background: #f4f7fd;
}
::v-deep .vgt-wrap {
  border-left: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
}
.propertiesTopBar {
  height: 40px;
  border-top: 1px solid var(--layout-border-color);
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}
.update-button {
  background-color: rgb(32, 111, 255) !important;
  color: white !important;
  float: right;
}
.update-button-container {
  width: 100% !important;
  display: inline-block;
}
</style>
