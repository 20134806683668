<template>
  <div class="vis-chart-error-content-messages">
    <i aria-hidden="true" :class="customIcon.AdminPanelSettings"></i>
    <div>
      {{ message }}
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
};
</script>
