<template>
  <el-popover
    v-model="isPopoverVisible"
    trigger="click"
    placement="top-start"
    popper-class="vis-dropdown-popper vis-custom-popper"
  >
    <div slot="reference" class="vis-chart-select vis-cursor-pointer">
      <img
        height="20"
        :src="getHeaderImage(selectedIcon)"
        :alt="selectedIcon"
      />
      {{ $t(selectedChartTypeName) }}
      <i class="el-icon-arrow-down" aria-hidden="true"></i>
    </div>
    <div class="vis-chart-select-box">
      <div class="vis-chart-select-box-title">
        <span>{{ chartPopupCardTitle }}</span>
      </div>
      <div class="vis-chart-select-box-content">
        <div class="vis-row">
          <div
            v-for="item in chartListByType()"
            :key="item.id"
            class="vis-col vis-col-4"
          >
            <div
              class="vis-cursor-pointer vis-chart-box"
              @click="setChart(item)"
            >
              <img
                height="30"
                :src="getHeaderImage(item.icon)"
                :alt="item.icon"
              />
            </div>
            <p class="vis-text--center">{{ $t(item.name) }}</p>
          </div>
        </div>
      </div>
    </div>
  </el-popover>
</template>
<script>
import {
  chartList,
  filterList,
  otherList,
} from "../../commons/dashboardAndPanel";
export default {
  props: {
    selectedChartType: {
      type: String,
    },
    selectedChartTypeName: {
      type: String,
    },
    isChartTypeFilter: {
      type: Boolean,
    },
  },
  data() {
    return {
      chartList: chartList,
      filterList: filterList,
      selectedIcon: "",
      isPopoverVisible: false,
    };
  },
  mounted() {
    this.setIcon();
  },
  computed: {
    chartPopupCardTitle() {
      return this.isChartTypeFilter
        ? this.$t("generalPages.All Filters")
        : this.$t("generalPages.allVisualizations");
    },
  },
  methods: {
    setChart(item) {
      this.selectedIcon = item.icon;
      this.isPopoverVisible = false;
      this.$emit("changeChartType", item);
    },
    setIcon() {
      let chartandFilterList = [
        ...this.filterList,
        ...this.chartList,
        ...otherList(),
      ];
      var chart = chartandFilterList.find(
        (x) => x.id == this.selectedChartType
      );
      this.selectedIcon = chart.icon;
    },
    getHeaderImage(imageName) {
      if (this.selectedIcon) {
        var images = require.context(
          "../../assets/images/chartsAndfilters/",
          false,
          /\.svg$/
        );
        return images("./" + imageName + ".svg");
      }
    },
    chartListByType() {
      return this.isChartTypeFilter ? this.filterList : this.chartList;
    },
  },
};
</script>
<style scoped>
.vis-chart-select {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 7px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  background-color: #fff;
  border-bottom: 1px solid #dedede;
  border-top: none;
  border-radius: 2px;
}
.vis-chart-select span i {
  margin-right: 7px;
}
.vis-chart-select i {
  font-weight: 600;
}
.vis-chart-select-box {
  width: 354px;
  background-color: #ffffff;
  border: 1px solid #dadcdd;
}
.vis-chart-select-box-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px 24px;
  border-bottom: 1px solid #dedede;
  font-weight: 500;
  background-color: var(--purple-lighteen-1);
}
.vis-chart-select-box-content {
  position: relative;
  width: 100%;
  padding: 12px 9px;
  overflow: auto;
}
.vis-chart-box {
  width: 100%;
  height: 64px;
  width: 100%;
  margin: 0 8px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-lighteen-1);
}
.vis-chart-box i {
  transition: all 0.3s ease;
  font-size: 1.5rem;
}
</style>
