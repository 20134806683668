import { PRIVILEGES } from "./../util/authorization";

export default {
    data() {
        return {
            privileges: {}
        }
    },
    created() {
        this.privileges = PRIVILEGES;
    },

};

