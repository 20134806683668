export const domain = "VISUALIZE_FIELD_AUTHORIZATION";

export const STATE = {
  FIELD_AUTHORIZATION_BY_DATAMODEL: `${domain}/FIELD_AUTHORIZATION_BY_DATAMODEL`,
  FIELD_AUTHORIZATION_BY_DATASET: `${domain}/FIELD_AUTHORIZATION_BY_DATASET`,
};

export const GETTER = {
  GET_FIELD_AUTHORIZATION_BY_DATAMODEL: `${domain}/GET_FIELD_AUTHORIZATION_BY_DATAMODEL`,
  GET_FIELD_AUTHORIZATION_BY_DATASET: `${domain}/GET_FIELD_AUTHORIZATION_BY_DATASET`,
};

export const ACTION = {
  FETCH_FIELD_AUTHORIZATION_BY_DATAMODEL: `${domain}/FETCH_FIELD_AUTHORIZATION_BY_DATAMODEL`,
  FETCH_FIELD_AUTHORIZATION_BY_DATASET: `${domain}/FETCH_FIELD_AUTHORIZATION_BY_DATASET`,
  CREATE_FIELD_AUTHORIZATION: `${domain}/CREATE_FIELD_AUTHORIZATION`,
  UPDATE_FIELD_AUTHORIZATION: `${domain}/UPDATE_FIELD_AUTHORIZATION`,
  DELETE_FIELD_AUTHORIZATION: `${domain}/DELETE_FIELD_AUTHORIZATION`,
};

export const MUTATION = {
  SET_FIELD_AUTHORIZATION_BY_DATAMODEL: `${domain}/SET_FIELD_AUTHORIZATION_BY_DATAMODEL`,
  SET_FIELD_AUTHORIZATION_BY_DATASET: `${domain}/SET_FIELD_AUTHORIZATION_BY_DATASET`,
};
