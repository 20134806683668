<template>
  <div class="vis-account-box vis-z-index-11">
    <div
      :class="
        isAvatarBoxAvailable
          ? 'vis-account-box-header'
          : 'vis-account-nonimage-header'
      "
    >
      <div class="vis-account-image" v-if="isAvatarBoxAvailable"></div>
    </div>
    <div class="vis-account-box-title">
      <h3>{{ username }}</h3>
      <div class="vis-account-box-border"></div>
    </div>
    <ul class="vis-account-box-list">
      <li class="vis-account-box-list-icon" v-if="isProfileAvailable">
        <span>{{ $t("generalPages.profile") }}</span>
      </li>
      <li
        id="accountBoxAdministration"
        v-if="isAdministrateVisible"
        class="vis-account-box-list-icon"
        @click="settingsClicked"
      >
        <span>{{ $t("generalPages.administration") }}</span>
      </li>
      <el-popover
        placement="right-start"
        trigger="click"
        popper-class="vis-dropdown-popper"
      >
        <ul class="vis-languages-dropdown">
          <li v-for="lang in userLanguages" :key="lang.id">
            <label @click="changeUserLanguage(lang)" :for="lang.value">
              {{ $t(`${lang.label}`) }}
              <i
                v-if="$i18n.locale == lang.value"
                class="mdi mdi-check"
                aria-hidden="true"
              ></i
            ></label>
            <input
              :id="lang.value"
              :value="lang.value"
              v-model="$i18n.locale"
              type="radio"
              @change="language = false"
            />
          </li>
        </ul>

        <li slot="reference" class="vis-account-box-list-icon">
          <span>{{ $t("generalPages.languages") }}</span>
        </li>
      </el-popover>
      <el-popover
        placement="right-start"
        trigger="click"
        popper-class="vis-dropdown-popper"
      >
        <ul class="vis-languages-dropdown">
          <li v-for="m in modules" :key="m.value">
            <label @click="changeModule(m)" :for="m.value">
              {{ $t(`${m.label}`) }}
              <i
                v-if="selectedModule == m.value"
                class="mdi mdi-check"
                aria-hidden="true"
              ></i
            ></label>
            <input :id="m.value" :value="m.value" type="radio" />
          </li>
        </ul>

        <li slot="reference" class="vis-account-box-list-icon">
          <span>{{ $t("generalPages.module") }}</span>
        </li>
      </el-popover>

      <div class="vis-account-box-border"></div>
      <li @click="openInfoPopup()" class="vis-account-box-list-icon">
        <span>{{ $t("generalPages.aboutVisualize") }}</span>
      </li>
      <li>
        <a :href="helperUrl" class="vis-w--full vis-display-block">{{
          $t("generalPages.help")
        }}</a>
      </li>
      <div class="vis-account-box-border"></div>
      <li @click="$emit('signOut')">
        <span>{{ $t("generalPages.signOut") }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { routerEnums } from "../../commons/Enum";
import { COMPONENT_PRIVILEGES } from "../../util/authorization";
import componentAvailabilityCheck from "../../mixins/componentAvailabilityCheck";
import eventBus from "../../eventBus";
import { Modules } from "../../commons/modules";
import {
  GETTER as GETTER_SCENARIO,
} from "../../store/modules/Scenario/Main/types";
import { mapGetters } from 'vuex';

export default {
  mixins: [componentAvailabilityCheck],
  props: {
    userLanguages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isDarkMode: true,
      isNotification: false,
      language: false,
      dialogVisible: false,
      helperUrl: process.env.VUE_APP_HELPER_URL,
      selectedModule: null,
    };
  },
  computed: {
    ...mapGetters({
      isScenarioModuleAvailable: GETTER_SCENARIO.GET_IS_SCENARIO_MODULE_AVAILABLE,
    }),
    modules() {
      return [
        {
          label: "Visualize",
          value: Modules.VISUALIZE,
        },
        this.isScenarioModuleAvailable
          ? {
              label: "Scenario",
              value: Modules.SCENARIO,
            }
          : null,
      ].filter((m) => m);
    },
    username: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Username"];
      },
    },
    isAdministrateVisible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.TOP_BAR_SETTINGS_ADMINISTRATE
      );
    },
    isProfileAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION
      );
    },
    isAvatarBoxAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION
      );
    },
  },
  mounted() {
    this.selectedModule = localStorage.getItem("selectedModule");
  },
  methods: {
    changeModule(item) {
      localStorage.setItem("selectedModule", item.value);
      this.selectedModule = localStorage.getItem("selectedModule");

      eventBus.$emit("onSelectedModuleChanged");
    },
    changeUserLanguage(item) {
      this.$emit("updateUserPreferences", {
        queryParam: item.key,
        bodyParam: { value: item.value },
      });
    },
    settingsClicked() {
      this.$router.push({
        name: routerEnums.ADMINISTRATION,
      });
      this.closePopup();
    },
    closePopup() {
      this.$emit("closePopup", false);
    },
    openInfoPopup() {
      this.$emit("openInfoBox", true);
      this.closePopup();
    },
  },
};
</script>
<style scoped>
.vis-account-box {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 10;
  height: auto;
  width: 250px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 2px #00000019;
}
.vis-account-box-border {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 5px 0;
  background-color: #e4e3e8;
}
.vis-account-box-header {
  position: relative;
  width: 100%;
  height: 60px;
  margin-bottom: 40px;
  background: transparent linear-gradient(95deg, #084bdf 0%, #793daa 100%);
}
.vis-account-box-header i {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}
.vis-account-image {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background: transparent linear-gradient(145deg, #ffffff 50%, #a698b1 100%);
}
.vis-account-box-title {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 10px;
  text-align: center;
}
.vis-account-box-title h3 {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vis-account-box-title h4 {
  font-size: 0.833rem;
  color: #404040;
  font-weight: normal;
}
.vis-account-box-list {
  position: relative;
  width: 100%;
  margin: 0;
  padding-bottom: 12px;
}
.vis-account-box-list li {
  position: relative;
  padding: 8px 24px 8px 15px;
  cursor: pointer;
  transition: ease all 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vis-account-box-list li i {
  font-size: 1.333rem;
  color: #494849;
}
.vis-account-box-list-icon::after {
  font-family: "icomoon" !important;
  content: "\e91c";
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.85rem;
}

.vis-account-box-list li:hover {
  background: #f5f5f5;
}

.vis-languages-dropdown input {
  position: absolute;
  right: 0;
  opacity: 0;
}
.vis-languages-dropdown li label {
  padding: 10px 20px;
  cursor: pointer;
}
.vis-languages-dropdown li:hover {
  background-color: #f5f5f5;
}
.vis-account-nonimage-header {
  position: relative;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  background: transparent linear-gradient(95deg, #084bdf 0%, #793daa 100%);
}
</style>
