var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vis-templatepage-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("admin.templates.Templates")))]),_c('span',[_vm._v(_vm._s(_vm.$t("admin.templates.Manage Templates")))])]),_c('div',{staticClass:"vis-template-body vis-mt--2"},[_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-3"},[_c('div',{staticClass:"vis-flex--spacebeetwen-Ycenter vis-template-col-title"},[_c('span',[_vm._v(_vm._s(_vm.$t("admin.templates.Template Name")))]),(!_vm.editedTemplateId && _vm.doesHaveAdminAuthority)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.addTemporaryTemplate()}}},[_vm._v(_vm._s(_vm.$t("panelProperties.add")))]):_vm._e()],1),_c('ul',{staticClass:"vis-template-list vis-mb--2"},_vm._l((_vm.templates),function(template,templateIndex){return _c('li',{key:template.id,class:{
              'vis-active-li': template.id === _vm.selectedTemplateId,
            },on:{"click":function($event){$event.stopPropagation();return _vm.selectTemplate(template.id, template)}}},[(template.id === _vm.editedTemplateId)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(template.name),expression:"template.name"}],attrs:{"id":"editTemplateInput","type":"text"},domProps:{"value":(template.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(template, "name", $event.target.value)}}}):_c('span',[_vm._v(" "+_vm._s(template.name))]),_c('div',{staticClass:"vis-template-list-icons"},_vm._l((_vm.getTemplateIconList(template, templateIndex)),function(icon){return _c('i',{key:icon.name,class:[
                  icon.name,
                  { 'vis-display-none': !icon.isShow },
                  icon.className,
                ],attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return icon.triggerFn.apply(null, arguments)}}})}),0)])}),0)]),_c('div',{staticClass:"vis-col vis-col-3"},[_c('div',{staticClass:"vis-flex--spacebeetwen-Ycenter vis-template-col-title"},[_c('span',[_vm._v(_vm._s(_vm.$t("admin.templates.Template Properties")))])]),_c('div',{staticClass:"vis-template-properties-content vis-scroll-thin"},_vm._l((_vm.templatePropertiesMapping),function(templateProp,index){return _c('div',{key:templateProp.name},[(index !== 0)?_c('div',{staticClass:"vis-line-1 vis-mb--2"}):_vm._e(),_c('div',{staticClass:"vis-section-title"},[_vm._v(_vm._s(_vm.$t(templateProp.title)))]),_c('div',{staticClass:"vis-row"},_vm._l((templateProp.components),function(component){return _c('CustomTemplateComponent',{key:component.property,attrs:{"component":component,"disabledComponent":!_vm.editedTemplateId,"componentTypes":_vm.componentTypes},on:{"changePropertyStyle":_vm.changePropertyStyle}})}),1)])}),0)]),_c('div',{staticClass:"vis-col vis-col-6 vis-template-col-title"},[_c('div',{staticClass:"vis-flex--spacebeetwen-Ycenter vis-template-col-title"},[_c('span',[_vm._v(_vm._s(_vm.$t("admin.templates.Sample Output"))+" ")])]),_c('div',{staticClass:"vis-sampleTemplate-card",style:(_vm.sampleCardStyle)},[_c('div',{staticClass:"vis-sampleTemplate-content"},[_c('div',{staticClass:"vis-sampleTemplate-title"},[_c('p',{style:(_vm.sampleCardTitleStyle)},[_vm._v(" "+_vm._s(_vm.$t("admin.templates.Sample Title"))+" ")]),_c('p',{style:(_vm.sampleCardSubTitleStyle)},[_vm._v(" "+_vm._s(_vm.$t("admin.templates.Sample Subtitle"))+" ")])]),_c('div',{staticClass:"vis-sampleTemplate-footer"},[_c('p',{style:(_vm.sampleCardFootNoteStyle)},[_vm._v(" "+_vm._s(_vm.$t("admin.templates.Sample Footnote"))+" ")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }