import { HiddenComponentsByEnv } from "../../componentVisualizationConfig.js"
import privileges from "./privileges.js";

export default {
    mixins: [
        privileges,
    ],
    methods: {
        isComponentAvailable(componentName) {
            const privilegeCheck = this.$store.getters["Security/Get/Keycloak/Privileges"].includes(componentName);
            const environmentCheck = HiddenComponentsByEnv[process.env.VUE_APP_ENVIRONMENT].includes(componentName);

            return !environmentCheck && privilegeCheck
        }
    },
};
