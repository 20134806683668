import { DefaultValue} from "../commons/dashboardProperties"

export const DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS = {
    DROPDOWN_FILTER_MANDATORY_SELECTION: false,
    DROPDOWN_FILTER_ALLOW_MULTISELECT: true,
    DROPDOWN_FILTER_SELECTION_ACTION: false,
    DROPDOWN_FILTER_DEFAULT_SELECTION: DefaultValue.NONE,
    DROPDOWN_FILTER_UNIQUE_NAME: "",
    DROPDOWN_FILTER_TAB_INDEX: 0,
    DROPDOWN_FILTER_APPLY_TO_TABS: []
}

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}