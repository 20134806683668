export const treemapSearch = (params, searchData, chartI) => {
  if (!params?.metric?.length) return;

  const metricKey = params.metric[0].field + params.metric[0].type;
  const aggregations = params.aggregation.map((i) => i.field);
  const aggregationFormats = params?.aggregation?.map((a) => a.format);
  const metricFormatsMapping = params.metric.reduce(
    (accumulator, value) => {
      return { ...accumulator, [value.field]: value.format };
    },
    {}
  );
  let filters = {};

  const dataSeries = fillChildren(
    searchData.aggregations,
    filters,
    aggregations[0],
    metricKey
  );

  navigateTreemapAndFillData(
    aggregations,
    0,
    dataSeries,
    filters,
    searchData.aggregations,
    metricKey,
    true
  );
  let dataSeriesTotal = 0;
  if (dataSeries?.length) {
    dataSeries?.forEach((item) => 
      (dataSeriesTotal += parseInt(item.value)))
  }

  return {
    dataSeries,
    dataSeriesTotal,
    dataSeriesNames: params.metric?.map((e) => e.alias),
    metricFormatsMapping,
    id: chartI,
    aggregationFormats
  };
};

const navigateTreemapAndFillData = (
  aggregations,
  agIndex,
  childrenArr,
  filters,
  data,
  metricKey,
  start
) => {
  if (aggregations.length === 0 || aggregations.length - 1 === agIndex) {
    return;
  }

  childrenArr.forEach((i) => {
    if (start) {
      filters = {};
    }

    filters[aggregations[agIndex]] = i.name;
    i.children = fillChildren(
      data,
      filters,
      aggregations[agIndex + 1],
      metricKey
    );

    navigateTreemapAndFillData(
      aggregations,
      agIndex + 1,
      i.children,
      filters,
      data,
      metricKey
    );
  });
};

const fillChildren = (data, filterObj, aggregation, metricKey) => {
  const filteredData = filterData(data, filterObj);
  return groupTreemapChildren(filteredData, aggregation, metricKey);
};

const filterData = (data, filterObj) => {
  return Object.keys(filterObj).reduce((prev, cur) => {
    prev = prev.filter((i) => i[cur] === filterObj[cur]);
    return prev;
  }, data);
};

const groupTreemapChildren = (objectArray, property, metricKey) => {
  if (!objectArray) return;
  
  return objectArray.reduce((arr, obj) => {
    const key = obj[property];
    const value = obj[metricKey];

    if (!key || !value) return arr;

    const groupObj = arr.find(i => i.name === key);

    if (groupObj) {
      groupObj.value += value;
    } else {
      arr.push({ name: key, value, children: [] })
    }

    return arr;
  }, []);
}