export const getFilterList = (optionArr) => {
    return (
        optionArr
            ?.map((x) => {
                const value = x.VALUE ?? x.value;
                return {
                    value: value,
                    label: typeof value == "number" ? value.toString() : value,
                };
            })
            .filter((a) => a) ?? []
    );
}