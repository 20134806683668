import { allPropertiesKeys } from "../commons/dashboardProperties";
export const cardPropertiesTemplates = [
  {
    id: "softtech1",
    name: "Softtech 1",
    properties: {
      style: {
        [allPropertiesKeys.HAS_TITLE]: true,
        [allPropertiesKeys.TITLE_FONT_FAMILY]: `Poppins, ${"sans-serif"}`,
        [allPropertiesKeys.TITLE_FONT_WEIGHT]: 600,
        [allPropertiesKeys.HAS_SUBTITLE]: true,
        [allPropertiesKeys.DESCRIPTION_FONT_FAMILY]: `Poppins, ${"sans-serif"}`,
        [allPropertiesKeys.DESCRIPTION_FONT_WEIGHT]: "normal",
        [allPropertiesKeys.HAS_FOOTNOTE]: true,
        [allPropertiesKeys.FOOTNOTE_FONT_FAMILY]: `Poppins, ${"sans-serif"}`,
        [allPropertiesKeys.BORDER]: "1px solid",
        [allPropertiesKeys.BORDER_RADIUS]: "3px",
        [allPropertiesKeys.HAS_BORDER]: true,
        [allPropertiesKeys.BORDER_COLOR]: "#f4f4f4",
        [allPropertiesKeys.HAS_DROP_SHADOW]: true,
        [allPropertiesKeys.BACKGROUND_COLOR]: "#ffffff",
      },
    },
  },
];
