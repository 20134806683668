<template>
  <div
    :class="[
      'scenario-transition-node-container',
      { 'scenario-transition-node-container-selected': isEdgeSelected },
    ]"
  >
    <div class="scenario-transition-node-header">
      <div class="scenario-transition-node-header-content">
        <div class="scenario-transition-node-name-and-type">
          <div class="vis-flex--Ycenter">
            <span class="scenario-transition-node-type" :title="type">{{
              type
            }}</span>
            <span
              class="scenario-transition-node-header-icon material-icons-outlined"
              >settings</span
            >
          </div>
          <span class="scenario-transition-node-name" :title="displayName">{{
            displayName
          }}</span>
          <div class="scenario-transition-node-body">
            <span
              class="scenario-transition-node-body-icon material-icons-outlined"
              :class="{
                'scenario-transition-node-body-icon-active': hasEvent,
              }"
              @click="handleBottomIconClick(bottomIcons.event)"
              >view_week</span
            >
            <span
              class="scenario-transition-node-body-icon transform-90 material-icons-outlined"
              :class="{
                'scenario-transition-node-body-icon-active': hasEnrichment,
              }"
              @click="handleBottomIconClick(bottomIcons.enrichment)"
              >lan</span
            >
            <span
              class="scenario-transition-node-body-icon material-icons-outlined"
              :class="{
                'scenario-transition-node-body-icon-active': hasRule,
              }"
              @click="handleBottomIconClick(bottomIcons.rule)"
              >filter_alt</span
            >
            <span
              class="scenario-transition-node-body-icon material-icons-outlined"
              :class="{
                'scenario-transition-node-body-icon-active': hasAction,
              }"
              @click="handleBottomIconClick(bottomIcons.action)"
              >bolt</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="scenario-transition-node-delete-action-wrapper">
      <span
        class="scenario-transition-node-delete-action material-icons-outlined"
        @click="$emit('onTransitionDeleteClick', id)"
        >delete_forever</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    type: String,
    displayName: String,
    isEdgeSelected: Boolean,
    hasEvent: Boolean,
    hasEnrichment: Boolean,
    hasRule: Boolean,
    hasAction: Boolean,
  },
  data() {
    return {
      bottomIcons: {
        event: "event",
        enrichment: "enrichment",
        rule: "rule",
        action: "action",
      },
    };
  },
  methods: {
    handleBottomIconClick(type) {
      if (type === this.bottomIcons.event)
        this.$emit("onEventIconClick", this.id);
      else if (type === this.bottomIcons.enrichment)
        this.$emit("onEnrichmentIconClick", this.id);
      else if (type === this.bottomIcons.rule)
        this.$emit("onRuleIconClick", this.id);
      else if (type === this.bottomIcons.action)
        this.$emit("onActionIconClick", this.id);
    },
  },
};
</script>

<style scoped>
.scenario-transition-node-container {
  width: 120px;
  height: 80px;
  border-radius: 25px;
  opacity: 1;
}
.scenario-transition-node-container-selected {
  border: 2px solid var(--primary-lighteen-1);
}
.scenario-transition-node-name-and-type {
  display: flex;
  flex-direction: column;
  max-width: 120px;
}
.scenario-transition-node-header {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  padding: 8px;
  justify-content: center;
}
.scenario-transition-node-header-content {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.scenario-transition-node-header-icon {
  font-size: 1.25rem;
  color: #ff7600;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  margin-left: auto;
}
.scenario-transition-node-type {
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  opacity: 0.6;
  font-size: 0.625rem;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: white;
}
.scenario-transition-node-type-icon {
  font-size: 2.1875rem !important;
}
.scenario-transition-node-name {
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  font-size: 0.75rem;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: white;
  width: fit-content;
}
.scenario-transition-node-body {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.scenario-transition-node-body-icon {
  color: #aaaaaa;
  font-size: 1rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  background-color: white;
  cursor: pointer;
}
.scenario-transition-node-delete-action {
  font-size: 1.75rem;
  color: #626262;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  margin-left: auto;
  cursor: pointer;
  visibility: hidden;
}
.scenario-transition-node-delete-action-wrapper {
  text-align: center !important;
}
.scenario-transition-node-container:hover
  .scenario-transition-node-delete-action {
  visibility: visible !important;
}
.transform-90 {
  transform: matrix(0, 1, -1, 0, 0, 0) !important;
}
.scenario-transition-node-body-icon-active {
  color: #636afa;
}
.scenario-transition-node-body-icon:hover {
  color: #636afa;
}
</style>
