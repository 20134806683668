import { Orientation, labelEnumsAlignTo, legendPositionEnums } from "../commons/dashboardProperties";

export const isChartHeightWidthLimited = (h, w) => {
    if (isChartHeightLimited(h) && isChartWidthLimited(w)) return true;
    return false;
}

export const isChartWidthLimited = (width) => {
    if (width > 12) return true;
    return false;
}
export const isChartHeightLimited = (height) => {
    if (height > 9) return true;
    return false;
}
export const getSeriesLabelPaddingByChartHeight = (height) => {
    return height <= 12 ? -10 : 0;
}

export const isPieceSizeLimited = (value) => {
    const threshold = 5;
    if(value < threshold) return true;
    return false;
}

export const isPieChartValueLabelInside = (value) => {
    if(value === labelEnumsAlignTo.INSIDE) return true;
    return false;
}

export const getTooltipPositionByValueLabel = (labelPosition) => {
    if(labelPosition === labelEnumsAlignTo.OUTSIDE_ARROUND) {
        return "none";
    } else if(labelPosition === labelEnumsAlignTo.OUTSIDE_EDGE) {
        return "edge";
    } else return labelEnumsAlignTo.INSIDE;
}

export const getOrientPositionByLegend = (legendPosition) => {
    if(legendPosition === legendPositionEnums.RIGHT || legendPosition === legendPositionEnums.LEFT) {
        return Orientation.VERTICAL;
    } else return Orientation.HORIZONTAL;
}

export const getAlignPositionByLegend = (legendPosition) => {
    if(legendPosition === legendPositionEnums.RIGHT || legendPosition === legendPositionEnums.LEFT) {
        return legendPosition;
    } else if(legendPosition === legendPositionEnums.TOP_LEFT){
        return legendPositionEnums.LEFT;
    } else if(legendPosition === legendPositionEnums.TOP_RIGHT) {
        return legendPositionEnums.RIGHT;
    } else return legendPositionEnums.CENTER;
}

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}