<template>
  <div class="vis-row">
    <div class="vis-col vis-col-12 vis-mb--1">
      <label for=""> {{ $t("filterComponents.period") }} </label>
      <el-select
        v-model="filterFormData.period"
        :placeholder="$t('filterComponents.selectPeriod')"
      >
        <el-option
          v-for="item in datePeriodSelectList"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="vis-row-box vis-mb--1">
      <div class="vis-line-1"></div>
    </div>

    <div class="vis-col vis-col-12 vis-mb--1">
      <label for="">{{ $t("filterComponents.type") }}</label>
      <el-select
        v-model="filterFormData.periodType"
        :placeholder="$t('filterComponents.selectType')"
        :disabled="hasPeriodValue"
      >
        <el-option
          v-for="item in dateTypeList"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="activeDurationComponent" class="vis-col vis-col-12 vis-mb--1">
      <label for="">{{ $t("filterComponents.duration") }}</label>
      <div class="vis-row">
        <div class="vis-col vis-col-6 vis-pa--none vis-flex--Ycenter">
          <el-input-number
            controls-position="right"
            v-model="filterFormData.duration"
          ></el-input-number>
        </div>
        <div class="vis-col vis-col-6 vis-flex--Ycenter">
          <span>{{ $t(durationText) }}</span>
        </div>
      </div>
    </div>
    <div class="vis-col vis-col-12" v-show="activeDateSelectionComponent">
      <label for="">{{ $t("filterComponents.date") }}</label>
      <el-date-picker
        v-model="filterFormData.date"
        type="datetime"
        format="dd/MM/yyyy"
        value-format="dd-MM-yyyy"
        :placeholder="$t('dateComponents.select_date_and_time')"
      >
      </el-date-picker>
    </div>
  </div>
</template>
<script>
import {
  datePeriodSelectList,
  commonPeriodType,
  datePeriodWeek,
  datePeriodMonth,
  datePeriodQuarter,
} from "./js/filters";
import cloneDeep from "clone-deep";

export default {
  props: {
    dateFilterFormData: {
      type: Object,
    },
    filterDetailData: {
      type: Object,
    },
  },
  data() {
    return {
      datePeriodSelectList: datePeriodSelectList,
      filterFormData: {},
    };
  },
  watch: {
    filterFormData() {
      this.setFormData();
    },
  },
  computed: {
    dateTypeList() {
      return this.filterFormData.period
        ? datePeriodSelectList.find(
            (x) => x.value == this.filterFormData.period
          ).selectList
        : [];
    },
    activeDurationComponent() {
      return (
        this.filterFormData.periodType == commonPeriodType.LAST ||
        this.filterFormData.periodType == commonPeriodType.PERIODS_AGO
      );
    },
    durationText() {
      return this.filterFormData.period
        ? datePeriodSelectList.find(
            (x) => x.value == this.filterFormData.period
          ).label
        : null;
    },
    hasPeriodValue() {
      return !this.filterFormData.period;
    },
    activeDateSelectionComponent() {
      return (
        this.filterFormData.periodType == datePeriodWeek.WEEK_TO_DATE ||
        this.filterFormData.periodType == datePeriodMonth.MONTH_TO_DATE ||
        this.filterFormData.periodType == datePeriodQuarter.QUARTER_TO_DATE
      );
    },
  },
  mounted() {
    this.setDetailFilter();
  },
  methods: {
    setFormData() {
      this.$emit("setSelectionFormData", this.filterFormData);
    },
    setDetailFilter() {
      if (this.filterDetailData?.filterValues) {
        this.filterFormData = cloneDeep(this.filterDetailData.filterValues);
      }
    },
  },
};
</script>
