<template>
  <div class="vis-flex--spacebeetwen-Ycenter vis-cursor-pointer">
    <div class="vis-flex--Ycenter titleContent"  @click="$emit('filterClicked', value)">
      <i
        class="mdi mdi-filter-outline data-model-sql-filter-icon"
        :class="{
          'color-purple': coloredPrefix,
        }"
        aria-hidden="true"
      />
      <span
        class="data-model-sql-filter-label-prefix"
        :class="{
          'color-purple': coloredPrefix,
        }"
        >{{ labelPrefix }}</span
      >
      <span class="vis-font-medium ellipsisLabel" >{{ $t(label) }}</span>
    </div>
    <div v-if="showActions" class="contextMenuContent">
      <el-popover
        placement="right-start"
        trigger="click"
        popper-class="vis-dropdown-popper"
      >
        <div class="vis-dropdown">
          <ul>
            <li
              v-for="(dropdown, indexDropdown) in dropdownActions"
              :key="`${dropdown.iconClassName}-${indexDropdown}`"
              :class="dropdown.liClassName"
              @click.stop="dropdownActionClicked(dropdown.actionKey)"
            >
              <i :class="dropdown.iconClassName" aria-hidden="true"></i>
              {{ $t(dropdown.localizationKey) }}
            </li>
          </ul>
        </div>
        <i
          slot="reference"
          class="vis-icon icon-vertical_three_dot vis-cursor-pointer"
          aria-hidden="true"
        />
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterId: {
      type: String,
      default: null,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelPrefix: {
      type: String,
      default: "",
    },
    coloredPrefix: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardDropdownAction: {
        DELETE: "DELETE",
        EDIT: "EDIT",
      },
    };
  },
  computed: {
    dropdownActions() {
      return [
        {
          localizationKey: "generalPages.edit",
          iconClassName: "vis-icon icon-pencil",
          liClassName: "no-border vis-flex--Ycenter",
          actionKey: this.cardDropdownAction.EDIT,
        },
        {
          localizationKey: "generalPages.delete",
          iconClassName: "vis-icon icon-trash-icon",
          liClassName: "vis-flex--Ycenter",
          actionKey: this.cardDropdownAction.DELETE,
        },
      ];
    },
  },
  methods: {
    dropdownActionClicked(actionKey) {
      if (actionKey === this.cardDropdownAction.DELETE) {
        this.$emit("deleteItem");
      } else if (actionKey === this.cardDropdownAction.EDIT) {
        this.$emit("editItem");
      }
    },
  },
};
</script>

<style scoped>
.data-model-sql-filter-icon {
  opacity: 0.7;
  margin-right: 5px;
  font-size: 1.125rem;
}

.data-model-sql-filter-label-prefix {
  margin-right: 5px;
  font-weight: 600;
}

.titleContent {
  width: 90% !important;
}

.contextMenuContent {
  width: 10% !important;
}

.ellipsisLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
