export const domain = "VISUALIZE_DASHBOARD";

export const STATE = {
  ACTIVE_SETTING_TAB: `${domain}/activeSettingTab`,
  IS_DASHBOARD_PROPERTIES_ACTIVE: `${domain}/isDashboardPropertiesActive`,
  DASHBOARD_LIST_BY_DATAMODEL_ID: `${domain}/dashboardListByDatamodelId`,
  FILTER_GRID_LAYOUT_PROPERTIES: `${domain}/filterGridLayoutProperties`,
  DASHBOARD_SELECTION_PANE_PROPERTIES: `${domain}/dashboardSelectionPaneProperties`,
};

export const GETTER = {
  GET_DASHBOARDS: `${domain}/getDashboards`,
  GET_DASHBOARD: `${domain}/getDashboard`,
  GET_CUSTOM_COLOR_PALETTES: `${domain}/getCustomColorPalettes`,
  GET_ACTIVE_SETTING_TAB: `${domain}/getActiveSettingTab`,
  GET_IS_DASHBOARD_PROPERTIES_ACTIVE: `${domain}/getIsDashboardPropertiesActive`,
  GET_DASHBOARD_LIST_BY_DATAMODEL_ID: `${domain}/getDashboardListByDatamodelId`,
  GET_FILTER_GRID_LAYOUT_PROPERTIES: `${domain}/getFilterGridLayoutProperties`,
  GET_DASHBOARD_SELECTION_PANE_PROPERTIES: `${domain}/getDashboardSelectionPaneProperties`,
};

export const ACTION = {
  FETCH_DASHBOARDS: `${domain}/fetchDashboards`,
  FETCH_DASHBOARDS_BY_ID: `${domain}/fetchDashboardsById`,
  CREATE_DASHBOARD: `${domain}/createDashboard`,
  UPDATE_DASHBOARD: `${domain}/updateDashboard`,
  COPY_DASHBOARD: `${domain}/copyDashboard`,
  DELETE_DASHBOARD: `${domain}/deleteDashboard`,
  MOVE_DASHBOARD: `${domain}/moveDashboard`,
  ENCODE_QUERY_PARAM: `${domain}/encodeQueryParam`,
  DECODE_QUERY_PARAM:`${domain}/decodeQueryParam`,
  UPDATE_DASHBOARD_NAME:`${domain}/UPDATE_DASHBOARD_NAME`,
  DELETE_DASHBOARD_CALCULATION: `${domain}/DELETE_DASHBOARD_CALCULATION`,
  FETCH_DASHBOARD_LIST_BY_DATAMODEL_ID: `${domain}/fetchDashboardListByDatamodelId`,
  FETCH_HIGHEST_PRIORITY_BY_DASHBOARD_ID: `${domain}/FETCH_HIGHEST_PRIORITY_BY_DASHBOARD_ID`,
};

export const MUTATION = {
  SET_DASHBOARDS: `${domain}/setDashboards`,
  SET_DASHBOARD: `${domain}/setDashboard`,
  RESET_DASHBOARD: `${domain}/resetDashboard`,
  SET_DASHBOARD_PANELS: `${domain}/setDashboardPanels`,
  SET_DASHBOARD_PROPERTY: `${domain}/setDashboardProperty`,
  UPDATE_DASHBOARD_PANEL_LIST: `${domain}/updateDashboardPanelList`,
  SET_SELECTED_PANEL: `${domain}/setSelectedPanel`,
  SET_PANELS: `${domain}/setPanels`,
  SET_ACTIVE_SETTING_TAB: `${domain}/setActiveSettingTab`,
  SET_DASHBOARD_PROPERTIES_ACTIVE: `${domain}/setDashboardPropertiesActive`,
  SET_DASHBOARD_LIST_BY_DATAMODEL_ID: `${domain}/setDashboardListByDatamodelId`,
  SET_DASHBOARD_SELECTION_PANE_PROPERTIES: `${domain}/setDashboardSelectionPaneProperties`,
  SET_FILTER_GRID_LAYOUT_ACTIVE: `${domain}/setFilterGridLayoutActive`,
  SET_FILTER_GRID_LAYOUT_PIN_ICON_ACTIVE: `${domain}/setFilterGridLayoutPinIconActive`,
  SET_DASHBOARD_SELECTION_PANE_ACTIVE: `${domain}/setDashboardSelectionPaneActive`,
  SET_DASHBOARD_SELECTION_PANE_PIN_ICON_ACTIVE: `${domain}/setDashboardSelectionPanePinIconActive`,
};

export const DASHBOARD_STATE_OBJECT_KEYS = {
  isDashboardSelectionPaneActive: "isDashboardSelectionPaneActive",
  isDashboardSelectionPanePinIconActive:
    "isDashboardSelectionPanePinIconActive",
  isFilterGridLayoutActive: "isFilterGridLayoutActive",
  isFilterGridLayoutPinIconActive: "isFilterGridLayoutPinIconActive",
};