import moment from "moment";

const formatIsoToDate = (isoDate) => {
    return moment(isoDate).format("DD-MM-YYYY");
}

export const dateQuickOptions = {
    today: "today",
    yesterday: "yesterday",
    aWeekAgo: "aWeekAgo",
}

export const changeFormatTodayDate = (dateValue) => {
    const date = new Date();
    const formattedValue = formatIsoToDate(date);

    if (dateValue === formattedValue) return false;
    else return formattedValue;
}

export const changeFormatYesterdayDate = (dateValue) => {
    const convertedDate = getYesterdayInDate();
    const formattedValue = formatIsoToDate(convertedDate);

    if (dateValue === formattedValue) return false;
    else return formattedValue;
}

export const changeFormatAweekagoDate = (dateValue) => {
    const week = 7;
    const convertedDate = getAweekAgoDateByParam(week);
    const formattedValue = formatIsoToDate(convertedDate);

    if (dateValue === formattedValue) return false;
    else return formattedValue;
}

export const getAweekAgoDateByParam = (week) => {
    const date = new Date();
    return date.setTime(date.getTime() - 3600 * 1000 * 24 * week);
}

export const getYesterdayInDate = () => {
    const date = new Date();
    return date.setTime(date.getTime() - 3600 * 1000 * 24);
}