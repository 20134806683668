export const getCommonsFieldProperty = (fieldData) => {
  return {
    fieldId: fieldData?.fieldId ? fieldData?.fieldId : fieldData?.id || fieldData?.paramId,
    field: fieldData?.name || fieldData?.field,
    alias: fieldData?.alias,
    datasetId: fieldData?.datasetId,
    fieldType: fieldData.fieldType || fieldData.type,
    isAuthorize: fieldData?.isAuthorize
  };
};
