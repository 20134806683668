var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vis-user-tabs-content vis-scroll-thin"},[_c('div',{staticClass:"vis-row"},_vm._l((_vm.mappedProjects),function(item,index){return _c('div',{key:item.id,staticClass:"vis-col",class:_vm.cardResponsiveClass},[_c('div',{staticClass:"vis-user-tab-card vis-flex-direction--column vis-flex--spacebeetwen",class:_vm.getActiveClass(item),on:{"click":function($event){if(!$event.ctrlKey)return null;return _vm.selectMultipleProject(item)}}},[_c('div',{staticClass:"vis-user-tab-card-title vis-flex--spacebeetwen-Ycenter"},[(!item.isIconImage)?_c('i',{class:item.iconClassName,attrs:{"aria-hidden":"true"}}):_c('img',{staticClass:"type-image",attrs:{"src":_vm.getScenarioImage(item.iconClassName),"alt":"type-scenario"}}),_c('div',{staticClass:"vis-user-tab-card-icon-box vis-flex--Ycenter"},[_vm._l((_vm.getMainActionsWithoutDropdown(item.mainActions)),function(mainAction,indexMainAction){return _c('el-tooltip',{key:`${mainAction.iconClassName}-${index}-${indexMainAction}`,attrs:{"popper-class":"vis-el-tooltip-home-projects","disabled":!mainAction.isTooltip,"content":item.description || _vm.$t('home.noDescription'),"effect":"light"}},[_c('i',{class:mainAction.iconClassName,attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.mainActionClicked(mainAction.actionKey, item)}}})])}),_c('el-dropdown',{attrs:{"trigger":"click","placement":"bottom"},on:{"command":_vm.dropdownActionClicked,"visible-change":_vm.dropdownClicked}},[_c('i',{staticClass:"lastIcon",class:_vm.getMainActionsDropdownIconClassName(item),attrs:{"aria-hidden":"true"},on:{"click":function($event){_vm.mainActionClicked(
                    _vm.getMainActionsDropdown(item.mainActions).actionKey,
                    item
                  )}}}),_c('el-dropdown-menu',{staticClass:"vis-dropdown-menu"},_vm._l((item.dropdowns),function(dropdown,indexDropdown){return _c('el-dropdown-item',{key:`${dropdown.iconClassName}-${index}-${indexDropdown}`,class:{
                    [dropdown.liClassName]: true,
                    'vis-display-none': dropdown.hidden,
                  },attrs:{"command":{ actionKey: dropdown.actionKey, item: item }}},[_c('i',{class:dropdown.iconClassName,attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t(dropdown.localizationKey))+" ")])}),1)],1)],2)]),_c('div',{staticClass:"vis-user-tab-card-content vis-cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.goViewProject(item)}}},[_c('span',[_vm._v(_vm._s(_vm.$t(`generalPages.${item.type}`)))]),_c('h3',[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"vis-user-tab-card-footer vis-flex--Ycenter vis-flex--spacebeetwen"},[(item.type === _vm.ScenarioCardType.SCENARIO)?_c('span',{staticClass:"vis-user-tab-card-footer-title",class:item.item.status === _vm.ScenarioStatusEnum.DEPLOYED
                ? 'vis-color-success'
                : 'vis-color-danger'},[_vm._v(" "+_vm._s(item.item.status)+" ")]):_c('span',{staticClass:"vis-user-tab-card-footer-title"},[_vm._v(" "+_vm._s(item.createBy)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(`generalPages.${_vm.formatISOToDateTime(item.updatedDate)}`)))])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }