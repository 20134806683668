import { fieldItemTypes } from "../commons/dashboardProperties"

export const PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS = {
    PIVOT_TABLE_COLUMN_FONT_FAMILY: undefined,
    PIVOT_TABLE_COLUMN_FONT_SIZE: undefined,
    PIVOT_TABLE_COLUMN_FONT_WEIGHT: undefined,
    PIVOT_TABLE_COLUMN_FONT_COLOR: undefined,
    PIVOT_TABLE_FONT_COLOR: undefined,
    PIVOT_TABLE_ROW_FONT_FAMILY: undefined,
    PIVOT_TABLE_ROW_FONT_WEIGHT: undefined,
    PIVOT_TABLE_ROW_FONT_SIZE: undefined,
    PIVOT_TABLE_COLUMN_WIDTH: undefined,
    PIVOT_TABLE_SELECT_COLUMN_ALIGN: undefined,
    PIVOT_TABLE_COLUMN_FORMAT: "NORMAL",
    PIVOT_TABLE_COLUMN_TEXT_WRAP: false,
    PIVOT_TABLE_ROW_TEXT_WRAP: null,
    PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN: undefined,
    PIVOT_TABLE_ROW_BACKGROUND_COLOR: undefined,
    PIVOT_TABLE_COLUMN_NAME:"",
    PIVOT_TABLE_COLUMN_SCREENTIP:"",
    PIVOT_TABLE_ROW_MAX_CHARACTER: undefined,
    PIVOT_TABLE_AGGREGATION_FUNCTION: fieldItemTypes[0].value,
};
export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}