<template>
  <div>
    <div class="vis-topbar vis-ma--none vis-setting-bar">
      <div class="vis-row vis-height-100">
        <div
          class="vis-col vis-mb--none vis-pa--none vis-mobile-hidden vis-flex--spacebeetwen-Ycenter"
        >
          <el-breadcrumb separator="/" class="vis-ml--2">
            <el-breadcrumb-item>{{
              $t("generalPages.administration")
            }}</el-breadcrumb-item>
          </el-breadcrumb>
          <i
            aria-hidden="true"
            :class="customIcon.Close"
            class="vis-cursor-pointer vis-mr--1_5 vis-font-medium e-icons e-large"
            @click="adminPageClose"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- html'de $t('') kullandığımda uyarı veriyordu. Ondan kaynaklı script eklendi. -->
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
export default {
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  methods: {
    adminPageClose() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner {
  font-size: 16px !important;
}
</style>
