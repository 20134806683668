/* eslint-disable */
import { STATE, ACTION, MUTATION, GETTER } from "./types";
import Vue from "vue";
import { MUTATION_GENERAL } from "../General/types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visTemplates = {
  state: {
    [STATE.TEMPLATES]: [],
  },
  getters: {
    [GETTER.GET_TEMPLATES]: (state) => {
      return state[STATE.TEMPLATES];
    },
  },
  actions: {
    [ACTION.FETCH_TEMPLATES]: async ({ commit }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Templates,
        serviceMethodSub: serviceMethodSub.readTemplates,
        successMutation: MUTATION.SET_TEMPLATES,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.CREATE_TEMPLATE]: async ({ commit, dispatch }, { bodyParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Templates,
        serviceMethodSub: serviceMethodSub.createTemplate,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });

      await dispatch(ACTION.FETCH_TEMPLATES);
    },
    [ACTION.UPDATE_TEMPLATE]: async ({ commit, dispatch }, { bodyParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Templates,
        serviceMethodSub: serviceMethodSub.updateTemplate,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });

      await dispatch(ACTION.FETCH_TEMPLATES);
    },
    [ACTION.DELETE_TEMPLATE]: async ({ commit, dispatch }, templateId) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Templates,
        serviceMethodSub: serviceMethodSub.deleteTemplate,
        queryParam: templateId,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
      await dispatch(ACTION.FETCH_TEMPLATES);
    },
  },
  mutations: {
    [MUTATION.SET_TEMPLATES](state, payload) {
      Vue.set(state, [STATE.TEMPLATES], payload);
    },
  },
};
