<template>
  <div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in allCollapses"
        :key="collapse.key"
        :title="collapse.label"
        :name="collapse.key"
      >
      </el-collapse-item>
    </el-collapse>
  </div>
</template>


<script>
export default {
  data() {
    return {
      allCollapses: [],
      activeCollapses: [],
    };
  },
};
</script>