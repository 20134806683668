<template>
  <div>
    <div class="vis-templatepage-title">
      <h2>{{ $t("admin.templates.Templates") }}</h2>
      <span>{{ $t("admin.templates.Manage Templates") }}</span>
    </div>
    <div class="vis-template-body vis-mt--2">
      <div class="vis-row">
        <div class="vis-col vis-col-3">
          <div class="vis-flex--spacebeetwen-Ycenter vis-template-col-title">
            <span>{{ $t("admin.templates.Template Name") }}</span>
            <el-button
              v-if="!editedTemplateId && doesHaveAdminAuthority"
              @click="addTemporaryTemplate()"
              type="text"
              >{{ $t("panelProperties.add") }}</el-button
            >
          </div>
          <ul class="vis-template-list vis-mb--2">
            <li
              v-for="(template, templateIndex) in templates"
              :key="template.id"
              @click.stop="selectTemplate(template.id, template)"
              :class="{
                'vis-active-li': template.id === selectedTemplateId,
              }"
            >
              <input
                id="editTemplateInput"
                v-if="template.id === editedTemplateId"
                type="text"
                v-model="template.name"
              />
              <span v-else> {{ template.name }}</span>
              <div class="vis-template-list-icons">
                <i
                  v-for="icon in getTemplateIconList(template, templateIndex)"
                  :key="icon.name"
                  :class="[
                    icon.name,
                    { 'vis-display-none': !icon.isShow },
                    icon.className,
                  ]"
                  @click.stop="icon.triggerFn"
                  aria-hidden="true"
                ></i>
              </div>
            </li>
          </ul>
        </div>

        <div class="vis-col vis-col-3">
          <div class="vis-flex--spacebeetwen-Ycenter vis-template-col-title">
            <span>{{ $t("admin.templates.Template Properties") }}</span>
          </div>

          <div class="vis-template-properties-content vis-scroll-thin">
            <div
              v-for="(templateProp, index) in templatePropertiesMapping"
              :key="templateProp.name"
            >
              <div v-if="index !== 0" class="vis-line-1 vis-mb--2"></div>
              <div class="vis-section-title">{{ $t(templateProp.title) }}</div>

              <div class="vis-row">
                <CustomTemplateComponent
                  v-for="component in templateProp.components"
                  :key="component.property"
                  :component="component"
                  :disabledComponent="!editedTemplateId"
                  :componentTypes="componentTypes"
                  @changePropertyStyle="changePropertyStyle"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="vis-col vis-col-6 vis-template-col-title">
          <div class="vis-flex--spacebeetwen-Ycenter vis-template-col-title">
            <span>{{ $t("admin.templates.Sample Output") }} </span>
          </div>
          <div class="vis-sampleTemplate-card" :style="sampleCardStyle">
            <div class="vis-sampleTemplate-content">
              <div class="vis-sampleTemplate-title">
                <p :style="sampleCardTitleStyle">
                  {{ $t("admin.templates.Sample Title") }}
                </p>
                <p :style="sampleCardSubTitleStyle">
                  {{ $t("admin.templates.Sample Subtitle") }}
                </p>
              </div>
              <div class="vis-sampleTemplate-footer">
                <p :style="sampleCardFootNoteStyle">
                  {{ $t("admin.templates.Sample Footnote") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  allPropertiesKeys,
  borderRadius,
  fontSizesArr,
  fontWeightsArr,
  fontsArr,
  dashboardCardPropertiesCssKey,
  dashboardCardTitlePropertiesCssKey,
  dashboardCardDescriptionPropertiesCssKey,
  dashboardCardFootnotePropertiesCssKey,
} from "../../commons/dashboardProperties";
import CustomTemplateComponent from "../helper/CustomTemplateComponent.vue";
import { CustomIcon } from "../../assets/js/custom-icons";
import { defaultTemplate } from "./settings";
import cloneDeep from "clone-deep";
import { mapActions, mapGetters } from "vuex";
import {
  ACTION,
  GETTER,
} from "../../store/modules/Visualize/AdminTemplates/types";
export default {
  components: {
    CustomTemplateComponent,
  },
  props: {
    doesHaveAdminAuthority: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
      componentTypes: {
        SWITCH: "SWITCH",
        COLOR_PICKER: "COLOR_PICKER",
        SELECT_BOX: "SELECT_BOX",
        TEXT_INPUT: "TEXT_INPUT",
      },
      selectedTemplateId: null,
      editedTemplateId: null,
      templates: [],
      selectedTemporaryTemplate: null,
    };
  },
  async mounted() {
    await this.fetchTemplates();

    if (this.templateList?.length) {
      this.templates = cloneDeep(this.templateList);
    }
  },
  computed: {
    ...mapGetters({
      templateList: GETTER.GET_TEMPLATES,
    }),
    selectedTemplatePropertiesStyle() {
      return this.templates.find((x) => x.id === this.selectedTemplateId)
        ?.properties?.style;
    },
    templatePropertiesMapping() {
      return [
        {
          title: "generalPages.chart",
          name: "Chart",
          components: [
            {
              label: "dashboardPropertiesLocale.general.backgroundColor",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.BACKGROUND_COLOR
                ],
              componentType: this.componentTypes.COLOR_PICKER,
              property: allPropertiesKeys.BACKGROUND_COLOR,
            },
            {
              label: "generalPages.show",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.HAS_BORDER
                ],
              componentType: this.componentTypes.SWITCH,
              property: allPropertiesKeys.HAS_BORDER,
            },
            {
              label: "dashboardPropertiesLocale.commonCard.borderColor",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.BORDER_COLOR
                ],
              componentType: this.componentTypes.COLOR_PICKER,
              property: allPropertiesKeys.BORDER_COLOR,
            },
            {
              label: "dashboardPropertiesLocale.commonCard.dropShadow",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.HAS_DROP_SHADOW
                ],
              componentType: this.componentTypes.SWITCH,
              property: allPropertiesKeys.HAS_DROP_SHADOW,
            },
            {
              label: "dashboardPropertiesLocale.commonCard.borderRadius",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.BORDER_RADIUS
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.BORDER_RADIUS,
              selectBoxOptions: borderRadius,
              flex: true,
            },
          ],
        },
        {
          title: "generalPages.title",
          name: "Title",
          components: [
            {
              label: "generalPages.show",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.HAS_TITLE
                ],
              componentType: this.componentTypes.SWITCH,
              property: allPropertiesKeys.HAS_TITLE,
            },
            {
              label: "generalPages.Font Family",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.TITLE_FONT_FAMILY
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.TITLE_FONT_FAMILY,
              selectBoxOptions: fontsArr,
            },
            {
              label: "generalPages.Font Size",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.TITLE_FONT_SIZE
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.TITLE_FONT_SIZE,
              selectBoxOptions: fontSizesArr,
              flex: true,
              className: "vis-mr--05",
            },
            {
              label: "generalPages.Font Weight",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.TITLE_FONT_WEIGHT
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.TITLE_FONT_WEIGHT,
              selectBoxOptions: fontWeightsArr,
              flex: true,
              className: "vis-ml--05",
            },
            {
              label: "generalPages.Font Color",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.TITLE_FONT_COLOR
                ],
              componentType: this.componentTypes.COLOR_PICKER,
              property: allPropertiesKeys.TITLE_FONT_COLOR,
            },
          ],
        },
        {
          title: "generalPages.Subtitle",
          name: "Sub Title",
          components: [
            {
              label: "generalPages.show",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.HAS_SUBTITLE
                ],
              componentType: this.componentTypes.SWITCH,
              property: allPropertiesKeys.HAS_SUBTITLE,
            },
            {
              label: "generalPages.Font Family",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.DESCRIPTION_FONT_FAMILY
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.DESCRIPTION_FONT_FAMILY,
              selectBoxOptions: fontsArr,
            },
            {
              label: "generalPages.Font Size",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.DESCRIPTION_FONT_SIZE
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.DESCRIPTION_FONT_SIZE,
              selectBoxOptions: fontSizesArr,
              flex: true,
              className: "vis-mr--05",
            },
            {
              label: "generalPages.Font Weight",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.DESCRIPTION_FONT_WEIGHT
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.DESCRIPTION_FONT_WEIGHT,
              selectBoxOptions: fontWeightsArr,
              flex: true,
              className: "vis-ml--05",
            },
            {
              label: "generalPages.Font Color",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.DESCRIPTION_FONT_COLOR
                ],
              componentType: this.componentTypes.COLOR_PICKER,
              property: allPropertiesKeys.DESCRIPTION_FONT_COLOR,
            },
          ],
        },
        {
          title: "Foot Note",
          name: "Foot Note",
          components: [
            {
              label: "generalPages.show",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.HAS_FOOTNOTE
                ],
              componentType: this.componentTypes.SWITCH,
              property: allPropertiesKeys.HAS_FOOTNOTE,
            },
            {
              label: "generalPages.Font Family",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.FOOTNOTE_FONT_FAMILY
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.FOOTNOTE_FONT_FAMILY,
              selectBoxOptions: fontsArr,
            },
            {
              label: "generalPages.Font Size",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.FOOTNOTE_FONT_SIZE
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.FOOTNOTE_FONT_SIZE,
              selectBoxOptions: fontSizesArr,
              flex: true,
              className: "vis-mr--05",
            },
            {
              label: "generalPages.Font Weight",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.FOOTNOTE_FONT_WEIGHT
                ],
              componentType: this.componentTypes.SELECT_BOX,
              property: allPropertiesKeys.FOOTNOTE_FONT_WEIGHT,
              selectBoxOptions: fontWeightsArr,
              flex: true,
              className: "vis-ml--05",
            },
            {
              label: "generalPages.Font Color",
              value:
                this.selectedTemplatePropertiesStyle?.[
                  allPropertiesKeys.FOOTNOTE_FONT_COLOR
                ],
              componentType: this.componentTypes.COLOR_PICKER,
              property: allPropertiesKeys.FOOTNOTE_FONT_COLOR,
            },
          ],
        },
      ];
    },
    sampleCardStyle() {
      if (!this.selectedTemplatePropertiesStyle) return;

      const resultStyle = this.getCardStyles(dashboardCardPropertiesCssKey);

      let cssAddedObjectBasedOnTrueOrFalse = {
        boxShadow: this.selectedTemplatePropertiesStyle?.[
          allPropertiesKeys.HAS_DROP_SHADOW
        ]
          ? "5px 5px 6px #00000029"
          : "none",
        border: this.selectedTemplatePropertiesStyle?.[
          allPropertiesKeys.HAS_BORDER
        ]
          ? "1px solid"
          : "none",
      };

      return { ...resultStyle, ...cssAddedObjectBasedOnTrueOrFalse };
    },
    sampleCardTitleStyle() {
      if (!this.selectedTemplatePropertiesStyle) return;

      const resultStyle = this.getCardStyles(
        dashboardCardTitlePropertiesCssKey
      );

      let cssAddedObjectBasedOnTrueOrFalse = {
        display: this.selectedTemplatePropertiesStyle?.[
          allPropertiesKeys.HAS_TITLE
        ]
          ? "block"
          : "none",
      };

      return { ...resultStyle, ...cssAddedObjectBasedOnTrueOrFalse };
    },
    sampleCardSubTitleStyle() {
      if (!this.selectedTemplatePropertiesStyle) return;

      const resultStyle = this.getCardStyles(
        dashboardCardDescriptionPropertiesCssKey
      );

      let cssAddedObjectBasedOnTrueOrFalse = {
        display: this.selectedTemplatePropertiesStyle?.[
          allPropertiesKeys.HAS_SUBTITLE
        ]
          ? "block"
          : "none",
      };

      return { ...resultStyle, ...cssAddedObjectBasedOnTrueOrFalse };
    },
    sampleCardFootNoteStyle() {
      if (!this.selectedTemplatePropertiesStyle) return;

      const resultStyle = this.getCardStyles(
        dashboardCardFootnotePropertiesCssKey
      );

      let cssAddedObjectBasedOnTrueOrFalse = {
        display: this.selectedTemplatePropertiesStyle?.[
          allPropertiesKeys.HAS_FOOTNOTE
        ]
          ? "block"
          : "none",
      };

      return { ...resultStyle, ...cssAddedObjectBasedOnTrueOrFalse };
    },
  },
  methods: {
    ...mapActions({
      fetchTemplates: ACTION.FETCH_TEMPLATES,
      createTemplate: ACTION.CREATE_TEMPLATE,
      deleteTemplateById: ACTION.DELETE_TEMPLATE,
      updateTemplate: ACTION.UPDATE_TEMPLATE,
    }),
    getCardStyles(cardCssKeys) {
      let resultStyle = {};
      Object.keys?.(this.selectedTemplatePropertiesStyle)?.forEach(
        (propKey) => {
          const cssKey = cardCssKeys[propKey];
          const isKeyFontSize = cssKey?.includes("fontSize");

          if (cssKey)
            resultStyle[cssKey] = isKeyFontSize
              ? this.selectedTemplatePropertiesStyle[propKey] + "px"
              : this.selectedTemplatePropertiesStyle[propKey];
        }
      );
      return resultStyle;
    },
    getTemplateIconList(template, templateIndex) {
      return [
        {
          name: "mdi mdi-check",
          triggerFn: () => this.setDefaultTemplate(template),
          isShow:
            (template.isDefault && template.id !== this.selectedTemplateId) ||
            (this.editedTemplateId && template.id === this.selectedTemplateId),
          className: template.isDefault ? "vis-color-primary" : "",
        },
        {
          name: CustomIcon.Refresh,
          triggerFn: () => this.resetTemplate(template.id),
          isShow: template.id === this.editedTemplateId && !template.isTempData,
        },
        {
          name: CustomIcon.Delete,
          triggerFn: () => this.deleteTemplate(template, templateIndex),
          isShow:
            (template.id === this.selectedTemplateId &&
              template.id !== this.editedTemplateId &&
              this.doesHaveAdminAuthority) ||
            (template.id === this.editedTemplateId &&
              template.isTempData &&
              this.doesHaveAdminAuthority),
        },
        {
          name: CustomIcon.Copy,
          triggerFn: () => this.duplicateTemplate(template),
          isShow:
            template.id === this.selectedTemplateId &&
            !this.editedTemplateId &&
            this.doesHaveAdminAuthority,
        },
        {
          name: CustomIcon.PencilOutline,
          triggerFn: () => this.editTemplate(template.id),
          isShow:
            !this.editedTemplateId &&
            template.id === this.selectedTemplateId &&
            this.doesHaveAdminAuthority,
        },
        {
          name: CustomIcon.SaveOutlined,
          triggerFn: () => this.saveTemplate(template),
          isShow:
            this.editedTemplateId && template.id === this.selectedTemplateId,
        },
      ];
    },
    addTemporaryTemplate() {
      this.templates.push(defaultTemplate());
      const notSavedTemplate = this.templates.find((x) => x.isTempData);
      this.selectedTemplateId = notSavedTemplate.id;
      this.editedTemplateId = notSavedTemplate.id;
    },

    async saveTemplate(template) {
      if (template.isTempData) {
        this.deleteTemporaryKeys(template);
        await this.createTemplate({ bodyParam: template });
      } else {
        await this.updateTemplate({ bodyParam: template });
        this.selectedTemplateId = template.id;
      }

      this.templates = cloneDeep(this.templateList);
      this.editedTemplateId = null;
    },
    deleteTemporaryKeys(template) {
      delete template["id"];
      delete template["isTempData"];
    },
    async deleteTemplate(template, index) {
      if (!template?.isTempData) await this.deleteTemplateById(template.id);

      this.templates.splice(index, 1);
      this.editedTemplateId = null;
    },
    duplicateTemplate(template) {
      const date = new Date();
      const duplicatedTemplate = cloneDeep(template);

      duplicatedTemplate.isTempData = true;
      duplicatedTemplate.name =
        duplicatedTemplate.name + date.getMilliseconds();
      duplicatedTemplate.isDefault = false;

      this.saveTemplate(duplicatedTemplate);
    },

    selectTemplate(templateId, template) {
      const notSavedTemplate = this.templates.find((x) => x.isTempData);

      if (notSavedTemplate) return;

      if (templateId !== this.editedTemplateId) {
        this.editedTemplateId = null;
      }

      this.selectedTemplateId = templateId;

      this.selectedTemporaryTemplate = cloneDeep(template);
    },
    editTemplate(id) {
      this.editedTemplateId = id;
    },
    resetTemplate(id) {
      let selectedTemplate = this.templates.find((x) => x.id === id);

      selectedTemplate.name = cloneDeep(this.selectedTemporaryTemplate.name);
      selectedTemplate.properties = cloneDeep(
        this.selectedTemporaryTemplate.properties
      );

      this.editedTemplateId = null;
    },
    async setDefaultTemplate(template) {
      if (!this.doesHaveAdminAuthority) return;
      
      if (!template.isDefault) template.isDefault = true;
      else template.isDefault = false;

      await this.updateTemplate({ bodyParam: template });
    },
    changePropertyStyle(params) {
      let selectedObj = this.templates.find(
        (x) => x.id === this.selectedTemplateId
      );
      if (selectedObj.properties.style[params.property]) {
        selectedObj.properties.style[params.property] = params.value;
      } else {
        selectedObj.properties.style = {
          ...selectedObj.properties.style,
          ...{ [params.property]: params.value },
        };
      }
    },
  },
};
</script>
<style scoped>
.vis-template-properties-content,
.vis-template-list {
  height: calc(100vh - 270px) !important;
}
.vis-template-properties-content {
  padding-right: 6px;
}
.vis-active-icon {
  color: var(--primary);
}
.vis-templatepage-title h2 {
  font-weight: 400;
  font-size: 1.625rem;
  margin-bottom: 14px;
}
.vis-templatepage-title span,
.vis-template-col-title > span {
  font-weight: 500;
  font-size: 0.75rem;
}
.vis-template-col-title {
  position: relative;
  width: 100%;
  height: 38px;
}

.vis-section-title {
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 10px;
}
.vis-template-list {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #d2d2d2;
}
.vis-template-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
}
.vis-active-li {
  background-color: #f6f8f9;
}
.vis-active-li input {
  border: none;
  background-color: transparent;
  font-size: 0.75rem;
  width: 92px;
}
.vis-template-list-icons {
  display: flex;
  align-items: center;
}
.vis-template-list-icons i {
  font-size: 1.125rem;
  margin: 0 3px;
}
.vis-template-list-icons label {
  width: 16px;
  height: 16px;
}
.vis-sampleTemplate-card {
  position: relative;
  width: 480px;
  height: 290px;
  max-width: 100%;
}
.vis-sampleTemplate-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
}
</style>
