/* eslint-disable */
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
/* import _ from "lodash"; */
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import { routerEnums } from "../../../../commons/Enum";

export const visTestQuery = {
    state: {
        [STATE.TEST_QUERY_RESULTS]: [],
        [STATE.SEARCH_RESULTS]: [],
        [STATE.TEST_QUERY_TABLE_NAMES]: [],
        [STATE.TEST_QUERY_COLUMN_NAMES]: []
    },
    getters: {
        [GETTER.GET_TEST_QUERY_RESULTS]: (state) => {
            return state[STATE.TEST_QUERY_RESULTS];
        },
        [GETTER.GET_TEST_QUERY_TABLE_NAMES]: (state) => {
            return state[STATE.TEST_QUERY_TABLE_NAMES];
        },
        [GETTER.GET_TEST_QUERY_COLUMN_NAMES]: (state) => {
            return state[STATE.TEST_QUERY_COLUMN_NAMES];
        },
        [GETTER.GET_SEARCH_RESULTS]: (state) => {
            return state[STATE.SEARCH_RESULTS];
        },
    },
    actions: {
        [ACTION.FETCH_SEARCH_WITHOUT_MUTATE]: async ({ commit }, { loadingComponent, bodyParam, pageName = null }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Search,
                    serviceMethodSub: serviceMethodSub.readSearch,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: pageName?.includes(routerEnums.DASHBOARD) ? false : true,
                    withSuccessNotify: false,
                    loadingComponent,
                },
            );
        },
        [ACTION.FETCH_SEARCH]: async ({ commit }, bodyParam) => {
            commit(MUTATION.SET_SEARCH_RESULTS, []);
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Search,
                    serviceMethodSub: serviceMethodSub.readSearch,
                    bodyParam,
                    successMutation: MUTATION.SET_SEARCH_RESULTS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withNotify: true,
                    withLoading: true
                },
            );
        },
        [ACTION.FETCH_TEST_PREVIEW]: async ({ commit }, { loadingComponent, bodyParam }) => {
            commit(MUTATION.SET_TEST_QUERY_RESULTS, []);
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.TestQuery,
                    serviceMethodSub: serviceMethodSub.readTestPreview,
                    bodyParam,
                    successMutation: MUTATION.SET_TEST_QUERY_RESULTS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withNotify: false,
                    withLoading: true,
                    loadingComponent,
                },
            );
        },
        [ACTION.FETCH_TEST_QUERY]: async ({ commit }, { loadingComponent, bodyParam }) => {
            commit(MUTATION.SET_TEST_QUERY_RESULTS, []);
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.TestQuery,
                    serviceMethodSub: serviceMethodSub.readTestQuery,
                    bodyParam,
                    successMutation: MUTATION.SET_TEST_QUERY_RESULTS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withNotify: true,
                    withLoading: true,
                    loadingComponent,
                },
            );
        },
        [ACTION.FETCH_TEST_QUERY_TABLE_NAMES]: async ({ commit }, bodyParam) => {
            commit(MUTATION.SET_TEST_QUERY_TABLE_NAMES, []);
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.TestQuery,
                    serviceMethodSub: serviceMethodSub.readTestQuery,
                    bodyParam,
                    successMutation: MUTATION.SET_TEST_QUERY_TABLE_NAMES,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withNotify: true,
                    withLoading: true
                },
            );
        },
        [ACTION.FETCH_TEST_QUERY_COLUMN_NAMES]: async ({ commit }, bodyParam) => {
            commit(MUTATION.SET_TEST_QUERY_COLUMN_NAMES, []);
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.TestQuery,
                    serviceMethodSub: serviceMethodSub.readTestQuery,
                    bodyParam,
                    successMutation: MUTATION.SET_TEST_QUERY_COLUMN_NAMES,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withNotify: true,
                    withLoading: true
                },
            );
        },
    },
    mutations: {
        [MUTATION.SET_SEARCH_RESULTS](state, items) {
            Vue.set(state, [STATE.SEARCH_RESULTS], items);
        },
        [MUTATION.SET_TEST_QUERY_RESULTS](state, items) {
            Vue.set(state, [STATE.TEST_QUERY_RESULTS], items);
        },
        [MUTATION.SET_TEST_QUERY_TABLE_NAMES](state, items) {
            Vue.set(state, [STATE.TEST_QUERY_TABLE_NAMES], items);
        },
        [MUTATION.SET_TEST_QUERY_COLUMN_NAMES](state, items) {
            Vue.set(state, [STATE.TEST_QUERY_COLUMN_NAMES], items);
        },
        [MUTATION.RESET_TEST_QUERY](state) {
            Vue.set(state, [STATE.SEARCH_RESULTS], []);
            Vue.set(state, [STATE.TEST_QUERY_RESULTS], []);
            Vue.set(state, [STATE.TEST_QUERY_TABLE_NAMES], []);
            Vue.set(state, [STATE.TEST_QUERY_COLUMN_NAMES], []);
        },
    },
};
