<template>
  <div class="vis-display-flex">
    <div class="vis-mr--1" v-for="item in getFilterValueParse()" :key="item.id">
      <label>{{ item.alias }}</label>
      <el-select
        v-if="item.fieldItem.filterType !== filterType.CUSTOM"
        :value="item.value"
        clearable
        filterable
        collapse-tags
        size="small"
        @change="(x) => addFiltersToChartsWithDrillThrough(x, item)"
      >
        <el-option
          v-for="list in item.list"
          :key="list.value"
          :label="list.label"
          :value="list.value"
        >
        </el-option>
      </el-select>
      <input
        v-else
        @change.stop="
          addFiltersToChartsWithDrillThrough($event.target.value, item)
        "
        :value="item.value"
        type="text"
        class="vis-input vis-bg-color-edf1ff"
        :placeholder="$t('filterComponents.enterFilterValue')"
      />
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { getFieldItemForFilter } from "../../util/chart-filter/filterValueSetters";
import { filterType } from "../../commons/filterComponents";
export default {
  props: {
    filterValue: {
      type: Object,
    },
    searchOption: {
      type: Object,
    },
  },
  data() {
    return {
      filterType: filterType,
    };
  },
  mounted() {
    this.$emit("callDrillThroughFilterList", this.filterValue);
  },

  methods: {
    getFilterValueParse() {
      return Object.keys(this.filterValue)
        .map((x) => {
          return {
            id: uuidv4(),
            fieldKey: x,
            alias: this.filterValue[x].alias,
            value: this.filterValue[x].value,
            fieldItem: {
                 //filterId: this.filterValue[x].fieldItem?.filterId, TODO++
              ...getFieldItemForFilter(this.filterValue[x].fieldItem),
            },
            list: this.searchOption?.[x]?.map((v) => {
              return {
                value: v.value,
                label: v.value,
              };
            }),
          };
        })
    },

    addFiltersToChartsWithDrillThrough(value, item) {
      this.$emit("addFiltersToChartsWithDrillThrough", {
        [item.fieldKey]: {
          value,
          alias: item.alias,
          fieldItem: item.fieldItem,
        },
      });
    },
  },
};
</script>
