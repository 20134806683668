import {
  ACTION,
  MUTATION,
  GETTER,
  STATE,
  ServerEventToClientEventMapping,
} from "./types";
import Vue from "vue";
import eventBus from "../../../eventBus";

export const visSSE = {
  state: {
    [STATE.SSE_LOGS]: [],
  },
  getters: {
    [GETTER.GET_SSE_LOGS]: (state) => {
      return state[STATE.SSE_LOGS];
    },
  },
  actions: {
    [ACTION.INIT_SSE]: ({ commit }, keycloakToken) => {
      Vue.$sse
        .create({
          url: process.env.VUE_APP_API_ENDPOINT + "general/v1/events",
          polyfill: true,
          forcePolyfill: true,
          polyfillOptions: {
            headers: {
              Connection: "keep-alive",
              "Cache-Control": "no-transform",
              Authorization: keycloakToken,
            },
          },
        })
        .on("message", (msg) => {
          const newMsg = JSON.parse(msg);

          commit(MUTATION.SET_SSE_LOGS, newMsg);

          if (newMsg.type !== "PING") {
            Object.keys(ServerEventToClientEventMapping).forEach((key) => {
              if (key === newMsg.type) {
                eventBus.$emit(ServerEventToClientEventMapping[key], newMsg);
              }
            });
          }
        })
        .on("error", (err) =>
          console.error("Failed to parse or lost connection:", err)
        )
        .connect()
        .catch((err) => console.error("Failed make initial connection:", err));

      //dispatch(ACTION.FETCH_ENVIRONMENTS, { loadingComponent: null });
    },
  },
  mutations: {
    [MUTATION.SET_SSE_LOGS](state, item) {
      state[STATE.SSE_LOGS].push(item);
    },
  },
};
