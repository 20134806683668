<template>
  <el-dialog :title="$t('generalPages.createFolder')" :visible="true">
    <div class="vis-row-box mb-15">
      <label>{{ $t("generalPages.labelName") }}</label>
      <input
        v-model="folderName"
        class="vis-input"
        type="text"
      />
    </div>
    <div class="vis-row-box-mb-15">
      <label>{{ $t("generalPages.labelLocation") }}</label>
      <div class="vis-vgt-card">
        <div class="vis-vgt-card-title vis-flex--spacebeetwen-Ycenter">
          <ul class="vis-user-tabs vis-flex--Ycenter">
            <li 
              @click="getFoldersById(null, null)"
              class="vis-main-breadcrumb vis-ml--none"
            >{{ $t("home.all") }}</li>
            <div 
              v-for="folder in folderNameList"
              :key="folder.id"
              class="vis-flex--Ycenter"
            >
              <li class="vis-main-breadcrumb"><i aria-hidden="true" :class="CustomIcon.ArrowRight"></i></li>
              <li 
                @click="getFoldersById(folder.id, folder.name)"
                :class="{'vis-font-medium': folderId == folder.id}"
                class="vis-main-breadcrumb"
              >
                {{ folder.name }}
              </li>
            </div>
          </ul>
          <InputSearch @keyupSeachData="searchFolder = $event" />
        </div>
        <vue-good-table
          :columns="columns"
          :rows="tableRows"
          max-height="300px"
          :fixed-header="true"
          :search-options="{
            enabled: true,
            externalQuery: searchFolder,
          }"
          :row-style-class="rowStyleClassFn"
          @on-row-click="onRowClick"
          @on-row-dblclick="(item) => getFoldersById(item.row.id, item.row.name)"
        >
          <template slot="table-column" slot-scope="props">
            <span>
              {{ $t(`${props.column.label}`) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.field == CardSaveFieldsTypeEnums.NAME"
              class="vis-cursor-pointer"
            >
              {{ props.row.item.name }}
            </span>
            <span v-else-if="props.column.field == CardSaveFieldsTypeEnums.CREATED_BY">
              <span>{{ props.row.item.createdBy }}
            </span>
            </span>
            <span v-else-if="props.column.field == CardSaveFieldsTypeEnums.UPDATED_BY">
              <span>{{ props.row.item.updatedBy }}</span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$emit('closeFolderSave')"
        class="vis-cancel-btn"
        type="text"
        >{{ $t("generalPages.cancel") }}</el-button
      >
      <el-button 
        @click="$emit('setFolderName', folderId, folderName)" 
        type="primary" 
        size="small">
        {{ $t("generalPages.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import InputSearch from "../helper/Form/InputSearch.vue";
import { CustomIcon } from "../../assets/js/custom-icons";
import { CardSaveFieldsTypeEnums } from "../../util/homePageMappers";
export default {
  components: { VueGoodTable, InputSearch },
  props: {
    tableRows: {
      type: Array,
    },
    folderId: {
      default: null
    },
    folderNameList: {
      type: Array,
    }
  },
  data() {
    return {
      folderName: "",
      searchFolder: "",
      selectedRow: "",
      columns: [
        {
          label: this.$t("generalPages.labelName"),
          field: CardSaveFieldsTypeEnums.NAME,
        },
        {
          label: this.$t("generalPages.labelCreatedBy"),
          field: CardSaveFieldsTypeEnums.CREATED_BY,
        },
        {
          label: this.$t("generalPages.labelUpdatedBy"),
          field: CardSaveFieldsTypeEnums.UPDATED_BY,
        },
      ],
      CustomIcon: CustomIcon,
      CardSaveFieldsTypeEnums: CardSaveFieldsTypeEnums,
    };
  },
  methods: {
    onRowClick(item) {
      this.selectedRow = item.row.originalIndex;
      this.$emit("setFolderId", item.row.id);
    },
    getFoldersById(id, name) {
      this.$emit("getFoldersById", id, name);
    },
    rowStyleClassFn(row) {
      return row.originalIndex === this.selectedRow ? 'vis-selectedRow':'';
    },
  }
};
</script>
