<template>
  <div class="vis-filter-pane-icons-box vis-mr--1" :class="mainClass">
    <div>
      <i
        aria-hidden="true"
        @click="$emit('changePinIcon')"
        :class="[customIcon.PinFilterPane, {'vis-active-setting-icon': isDashboardSelectionPanePinIconActive || isFilterGridLayoutPinIconActive }]"
      ></i>
      <i
        aria-hidden="true"
        @click="$emit('deleteFilterInChartsByUITabId')"
        :class="customIcon.ClearAllFilters"
      ></i>
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
export default {
  props: {
    isDashboardSelectionPanePinIconActive: {
      type: Boolean,
      default: false,
    },
    isFilterGridLayoutPinIconActive: {
      type: Boolean,
      default: false
    },
    mainClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
};
</script>
<style scoped>
.vis-filter-pane-icons-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 42px;
}
.vis-filter-pane-icons-box i {
  cursor: pointer;
  margin: 0 4px;
  font-size: 26px;
}
</style>
