<template>
  <div class="vis-panel-data-box">
    <label>{{ $t("generalPages.logos") }}</label>
    <div
      v-for="image in imagesData"
      :key="image"
      @click="setImage(image)"
      :class="{
        'vis-user-tab-card-footer': true,
        'vis-panel-data-card-empty': image == activeLogo,
      }"
    >
      <img
        :src="getImgUrl(image)"
        v-bind:alt="image"
        width="100%"
        height="100%"
      />
    </div>
  </div>
</template>
<script>
import cloneDeep from "clone-deep";
import { logos, defaultLogo } from "../../commons/dashboardAndPanel";
import panelDataPropertiesStyle from "../../mixins/panelDataPropertiesStyle";

export default {
  components: {},
  props: {
    panelData: {
      type: Object,
    },
  },
  mixins: [panelDataPropertiesStyle],
  data() {
    return {
      imagesData: logos,
    };
  },
  computed: {
    activeLogo() {
      return this.panelDataDetailsLogo?.length
        ? this.panelDataDetailsLogo
        : defaultLogo;
    },
  },
  methods: {
    getImgUrl(image) {
      var images = require.context("../../assets/images/", false, /\.png$/);
      return images("./" + image + ".png");
    },
    setImage(image) {
      const selectedPanelCloned = cloneDeep(this.panelData);
      selectedPanelCloned.details.logo = image;
      this.updateSelectedPanel(selectedPanelCloned);
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
  },
};
</script>
<style scoped></style>
