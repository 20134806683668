var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vis-temporary-filters-popup"},[_c('div',{staticClass:"vis-temporary-filters-popup-header vis-flex--spacebeetwen-Ycenter"},[_c('h3',[_vm._v(_vm._s(_vm.$t("generalPages.defineFilters")))]),_c('i',{staticClass:"vis-cursor-pointer",class:_vm.customIcon.Close,attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.$emit('close')}}})]),_c('div',{staticClass:"vis-temporary-filters-popup-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.mappedItems,"max-height":"340px","fixed-header":true},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(`${props.column.label}`))+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === _vm.columnFields.value)?_c('span',[(_vm.isDateType(props.formattedRow[_vm.columnFields.filterName]))?_c('el-date-picker',{attrs:{"value":_vm.getValueOfRow(props.formattedRow[_vm.columnFields.filterName]),"format":"dd/MM/yyyy","value-format":"dd-MM-yyyy"},on:{"input":(val) =>
                  _vm.setValueOfRow(
                    props.formattedRow[_vm.columnFields.filterName],
                    val
                  )}}):_c('el-input',{attrs:{"value":_vm.getValueOfRow(props.formattedRow[_vm.columnFields.filterName])},on:{"input":(val) =>
                  _vm.setValueOfRow(
                    props.formattedRow[_vm.columnFields.filterName],
                    val
                  )}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('div',{staticClass:"vis-row-box vis-text--right vis-temporary-filters-popup-run"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v(_vm._s(_vm.$t("generalPages.reset")))]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.fetchSql}},[_vm._v(_vm._s(_vm.$t("generalPages.run")))])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }