<template>
  <div class="vis-filter-button-box">
    <div class="vis-row">
      <div class="vis-col vis-mb--none vis-text--right">
        <el-button size="small" @click="$emit('closeFilters', null)" plain>{{
          $t("generalPages.cancel")
        }}</el-button>
        <el-button size="small" @click="$emit('submitFilter')" type="primary">{{
          $t("generalPages.apply")
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.vis-filter-button-box {
  position: relative;
  width: 100%;
  padding: 0 10px;
}
</style>
