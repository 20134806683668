<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold">{{ $t("generalPages.functions") }}</span>
    </div>
    <div>
      <InputSearch
        v-if="Object.keys(calculatedColumnFunctions).length"
        customComponentClass="vis-dataset-search-input"
        @keyupSearchData="searchField = $event"
      />
      <el-collapse
        :value="activeCollapses"
        @change="(e) => (activeCollapses = e)"
      >
        <el-collapse-item
          v-for="calculatedColumnFunction in calculatedColumnFunctions"
          :key="calculatedColumnFunction.collapseName"
          :title="$t(calculatedColumnFunction.collapseLabel)"
          :name="calculatedColumnFunction.collapseName"
          class="vis-collapse-item"
        >
          <div class="calculated-column-properties-container">
            <div
              v-for="aFunction in getFunctionsByName(
                calculatedColumnFunction.functions
              )"
              :key="aFunction"
              class="calculated-column-function-item vis-cursor-pointer"
              @click="
                $emit('addFunctionToCalculatedColumnEditor', aFunction + '()')
              "
              @mouseover="currentUsageInfoFunctionPreviewButton = aFunction"
              @mouseleave="currentUsageInfoFunctionPreviewButton = null"
            >
              <i class="mdi mdi-function-variant" aria-hidden="true"></i>
              <span class="vis-font-bold">{{ aFunction }}</span>
              <i
                v-if="currentUsageInfoFunctionPreviewButton === aFunction"
                @click.stop="showUsageInfo(aFunction)"
                class="vis-cursor-pointer calculated-column-function-preview-button"
                :class="customIcon.Information"
                aria-hidden="true"
              />
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import {
  getCalculatedColumnFunctions,
  calculatedColumnFunctionTypes,
} from "../calculatedColumn/calculatedColumnTypes.js";
import { CustomIcon } from "../../../assets/js/custom-icons";
import InputSearch from "../../helper/Form/InputSearch.vue";

export default {
  props: {
    connectionType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedUsageInfo: null,
      currentUsageInfoFunctionPreviewButton: null,
      activeCollapses: [],
      calculatedColumnFunctionTypes: calculatedColumnFunctionTypes,
      connectionTypes: {
        ORACLE: "ORACLE",
        MSSQL: "MSSQL",
        CLICKHOUSE: "CLICKHOUSE",
        POSTGRESQL: "POSTGRESQL",
        SYBASE_ASE: "SYBASE_ASE",
        SYBASE_IQ: "SYBASE_IQ",
        TRINO: "TRINO",
      },
      customIcon: CustomIcon,
      searchField: null,
    };
  },
  watch: {
    // connectionType değiştiğinde çalışacak
    connectionType: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.updateActiveCollapses();
      },
      deep: true,
    },
  },
  mounted() {
    //Eğer 300 milisaniyede bir bug durumu geçerli olursa watch eklenemsi düşünülcek!!!
    this.updateActiveCollapses();
  },
  computed: {
    isOracleSelectable() {
      return this.connectionType === this.connectionTypes.ORACLE;
    },
    isMssqlSelectable() {
      return this.connectionType === this.connectionTypes.MSSQL;
    },
    isClickhouseSelectable() {
      return this.connectionType === this.connectionTypes.CLICKHOUSE;
    },
    isPostgresqlSelectable() {
      return this.connectionType === this.connectionTypes.POSTGRESQL;
    },
    isSybaseAseSelectable() {
      return this.connectionType === this.connectionTypes.SYBASE_ASE;
    },
    isSybaseIqSelectable() {
      return this.connectionType === this.connectionTypes.SYBASE_IQ;
    },
    isTrinoSelectable() {
      return this.connectionType === this.connectionTypes.TRINO;
    },
    calculatedColumnFunctions() {
      return getCalculatedColumnFunctions({
        isOracleSelectable: this.isOracleSelectable,
        isMssqlSelectable: this.isMssqlSelectable,
        isClickhouseSelectable: this.isClickhouseSelectable,
        isPostgresqlSelectable: this.isPostgresqlSelectable,
        isSybaseAseSelectable: this.isSybaseAseSelectable,
        isSybaseIqSelectable: this.isSybaseIqSelectable,
        isTrinoSelectable: this.isTrinoSelectable,
      });
    },
  },
  methods: {
    showUsageInfo(showUsageInfo) {
      if (this.selectedUsageInfo != showUsageInfo) {
        this.selectedUsageInfo = showUsageInfo;
      } else this.selectedUsageInfo = null;
      this.$emit("calculatedColumnFunctionName", this.selectedUsageInfo);
    },
    getFunctionsByName(functions) {
      return this.searchField
        ? functions.filter((f) => {
            return f.toLowerCase().includes(this.searchField.toLowerCase());
          })
        : functions;
    },
    updateActiveCollapses() {
      this.activeCollapses = [
        this.isOracleSelectable
          ? this.calculatedColumnFunctionTypes.Oracle.key
          : null,
        this.isMssqlSelectable
          ? this.calculatedColumnFunctionTypes.Mssql.key
          : null,
        this.isClickhouseSelectable
          ? this.calculatedColumnFunctionTypes.Clickhouse.key
          : null,
        this.isPostgresqlSelectable
          ? this.calculatedColumnFunctionTypes.Postgresql.key
          : null,
        this.isSybaseAseSelectable
          ? this.calculatedColumnFunctionTypes.SybaseAse.key
          : null,
        this.isSybaseIqSelectable
          ? this.calculatedColumnFunctionTypes.SybaseIq.key
          : null,
        this.isTrinoSelectable
          ? this.calculatedColumnFunctionTypes.Trino.key
          : null,
        this.calculatedColumnFunctionTypes.String.key,
        this.calculatedColumnFunctionTypes.Math.key,
        this.calculatedColumnFunctionTypes.Date.key,
        this.calculatedColumnFunctionTypes.Conditions.key,
        this.calculatedColumnFunctionTypes.Operators.key,
      ];
    },
  },
  components: { InputSearch },
};
</script>

<style scoped>
.calculated-column-function-item {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.calculated-column-properties-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 25px;
}
.usage-info-wrapper {
  position: absolute;
  bottom: 0;
  height: 200px;
  min-height: 200px;
  background: white;
  border: 1px solid #e4e3e8;
  width: 300px;
  padding: 15px 20px 15px 20px;
  right: 0;
}
.usage-info-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
}
.usage-info-function-name {
  font: normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #b40ed4;
}
.calculated-column-function-preview-button {
  margin-left: auto;
}
</style>
