<template>
  <div class="aside-properties">
    <div class="scenario-aside-title">
      <span class="scenario-aside-title-icon material-icons-outlined">
        <img :src="getScenarioImage()" class="type-image" alt="type-scenario" />
      </span>
      {{ $t("Scenario Settings") }}
    </div>
    <div class="scenario-aside-properties-container">
      <el-tabs :stretch="true">
        <el-tab-pane>
          <span slot="label"> {{ $t("General") }}</span>
          <ScenarioGeneralProperties
            :properties="properties"
            @updateName="(name) => $emit('updateName', name)"
          />
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label">
            <span class="scenario-properties-icon material-icons-outlined">
              view_week </span
            >{{ $t("Events") }}
          </span>
          <ScenarioUsedEventsProperties :properties="properties" />
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label">
            <span class="scenario-properties-icon material-icons-outlined">
              grain </span
            >{{ $t("Simulate") }}
          </span>
          <ScenarioSimulateProperties
            :properties="properties"
            :events="events"
            :localSimulateData="localSimulateData"
            @showSimulatePopup="() => $emit('showSimulatePopup')"
            @simulateByElement="(e) => $emit('simulateByElement', e)"
            @deleteSimulateItem="(e) => $emit('deleteSimulateItem', e)"
            @editSimulateItem="(e) => $emit('editSimulateItem', e)"
            @publishAll="$emit('publishAll')"
            @simulateOrderChanged="(e) => $emit('simulateOrderChanged', e)"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ScenarioGeneralProperties from "./ScenarioGeneralProperties.vue";
import ScenarioSimulateProperties from "./ScenarioSimulateProperties.vue";
import ScenarioUsedEventsProperties from "./ScenarioUsedEventsProperties.vue";

export default {
  props: {
    properties: {
      type: Object,
    },
    events: {
      type: Array,
    },
    localSimulateData: {
      type: Array,
    },
  },
  components: {
    ScenarioGeneralProperties,
    ScenarioSimulateProperties,
    ScenarioUsedEventsProperties,
  },
  methods: {
    getScenarioImage() {
      var images = require.context(
        "../../assets/images/scenario/",
        false,
        /\.svg$/
      );

      try {
        return images("./" + "type-scenario" + ".svg");
      } catch (e) {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.aside-properties {
  padding: 20px !important;
}
.scenario-aside-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.scenario-aside-title-icon {
  color: #0014ff;
  font-size: 1.625rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.scenario-aside-properties-container {
  width: 100%;
}
.scenario-properties-icon {
  font-size: 1.2rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  vertical-align: middle;
  margin-right: 2px;
}
</style>
