<template>
  <div>
    <div class="vis-row">
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.labelOnDays") }}</label>
        <el-select v-model="scheduleCronDtoUpdated.dailyCronType">
          <el-option
            v-for="option in dailyCronOptions"
            :key="option.key"
            :value="option.key"
            :label="option.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div
      v-if="
        scheduleCronDtoUpdated.dailyCronType ===
        scheduleDailyOnDaysOptions.SelectedDays
      "
      class="day-selection"
    >
      <div class="vis-row">
        <div class="vis-col vis-col-4">
          <label>{{ $t("schedule.selectDays") }}</label>
        </div>
      </div>
      <div class="vis-row">
        <div class="vis-col vis-col-12">
          <div class="vis-display-flex">
            <el-checkbox
              :indeterminate="isIndeterminateDay"
              v-model="checkAllDay"
              @change="handleCheckAllDayChange"
              class="vis-centered-label vis-checkbox-mr-30"
              >{{ $t("home.all") }}</el-checkbox
            >
            <el-checkbox-group
              v-model="scheduleCronDtoSelected.selectedDays"
              @change="handleCheckedDayChange"
            >
              <div class="vis-display-flex">
                <el-checkbox
                  v-for="day in filteredDays"
                  :label="day.key"
                  :key="day.key"
                  class="vis-centered-label"
                  >{{ day.value }}</el-checkbox
                >
              </div>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        scheduleCronDtoUpdated.dailyCronType ===
        scheduleDailyOnDaysOptions.RepeatAfter
      "
      class="day-selection"
    >
      <div class="vis-row">
        <div class="vis-col vis-col-2">
          <label>{{ $t("schedule.labelEvery") }}</label>
          <el-input
            type="number"
            v-model="scheduleCronDtoUpdated.repeatDay"
            min="1"
          />
        </div>
        <div class="vis-mt--2">
          {{ $t("schedule.labelDays") }}
        </div>
      </div>
    </div>
    <div class="vis-row">
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.labelAt") }}</label>
        <TimePicker 
          :isMinuteEnabled="true"
          :value="dailyAtTimeUpdated.dailyTime" 
          @input="value => dailyAtTimeUpdated.dailyTime=value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  scheduleAllSelect,
  scheduleDailyOnDaysOptions,
  scheduleCronDtoDefaultValue,
  dailyAtTimeDefaultValue,
  scheduleCronDtoSelectDefaultValue,
} from "./schedule.js";
import cloneDeep from "clone-deep";
import TimePicker from "../../helper/TimePicker.vue";
export default {
  props: {
    daysOptions: {
      type: Array,
    },
    dailyCronOptions: {
      type: Array,
    },
    scheduleCronDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dailyAtTime: {
      type: Object,
      default: () => {
        return {};
      },
    },
    existingSelectCronDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {TimePicker},
  data() {
    return {
      scheduleDailyOnDaysOptions: scheduleDailyOnDaysOptions,
      isIndeterminateDay: true,
      checkAllDay: false,
      scheduleCronDtoUpdated: scheduleCronDtoDefaultValue,
      dailyAtTimeUpdated: dailyAtTimeDefaultValue,
      scheduleCronDtoSelected: scheduleCronDtoSelectDefaultValue,
    };
  },
  mounted() {
    this.dailyAtTimeUpdated = cloneDeep(this.dailyAtTime);
    this.scheduleCronDtoUpdated = cloneDeep(this.scheduleCronDto);
    this.scheduleCronDtoSelected = cloneDeep(this.existingSelectCronDto);
  },
  computed: {
    filteredDays() {
      return this.daysOptions.filter((x) => x.key !== scheduleAllSelect.All);
    },
  },
  watch: {
    dailyAtTimeUpdated: {
      deep: true,
      handler() {
        this.$emit("onDailyAtTimeUpdated", this.dailyAtTimeUpdated);
      },
    },
    scheduleCronDtoUpdated: {
      deep: true,
      handler() {
        this.$emit("onScheduleUpdated", this.scheduleCronDtoUpdated);
      },
    },
    scheduleCronDtoSelected: {
      deep: true,
      handler() {
        this.$emit("onCronDtoSelectUpdated", this.scheduleCronDtoSelected);
      },
    },
  },
  methods: {
    handleCheckAllDayChange(val) {
      this.scheduleCronDtoSelected.selectedDays = val
        ? this.daysOptions.map((x) => x.key)
        : [];
      this.isIndeterminateDay = false;
    },
    handleCheckedDayChange(value) {
      let checkCount = value.length;
      this.checkAllDay = checkCount === this.daysOptions.length;
      this.isIndeterminateDay =
        checkCount > 0 && checkCount < this.daysOptions.length;
    },
  },
};
</script>
<style scoped>

</style>
