import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { ACTION, GETTER, MUTATION, STATE } from "./types";

export const visFilter = {
    state: {
        [STATE.ENCODE]: "",
        [STATE.DECODE]: ""
    },

    getters: {
        [GETTER.GET_ENCODE]: (state) => {
            return state[STATE.ENCODE]
        },

        [GETTER.GET_DECODE]: (state) => {
            return state[STATE.DECODE];
        },
    },

    actions: {
        [ACTION.FETCH_ENCODE]: async ({ commit }, bodyParam) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Hashing,
                    serviceMethodSub: serviceMethodSub.readEncode,
                    successMutation: MUTATION.SET_ENCODE,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    withSuccessNotify: false,
                },
            );
        },

        [ACTION.FETCH_DECODE]: async ({ commit }, bodyParam) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Hashing,
                    serviceMethodSub: serviceMethodSub.readDecode,
                    successMutation: MUTATION.SET_DECODE,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    withSuccessNotify: false,
                },
            );
        }
    },
    mutations: {
        [MUTATION.SET_ENCODE](state, payload) {
            state[STATE.ENCODE] = payload;
        },

        [MUTATION.SET_DECODE](state, payload) {
            state[STATE.DECODE] = payload;
        },
    },

};