<template>
  <CommonFilterLayout>
    <template v-slot:title>
      <div class="vis-row">
        <div class="vis-col vis-col-12 vis-mb--none">
          <label class="vis-mb--1 vis-font-bold" for="">{{
            $t("filterComponents.type")
          }}</label>
          <el-radio-group class="vis-mb--2" v-model="selectedTabValue">
            <el-radio
              v-for="tab in dateFilterTabList"
              :key="tab.value"
              :label="tab.value"
              >{{ $t(tab.label) }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="vis-date-component-box">
        <Simple
          v-if="isSelection"
          :dateFilterFormData="dateFilterFormData"
          :filterDetailData="filterDetailData"
          @setSelectionFormData="setSelectionFormData"
        />
        <Relative
          v-if="isRelative"
          :filterDetailData="filterDetailData"
          @setSelectionFormData="setSelectionFormData"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div v-if="isSelection" class="vis-date-component-infoBox">
        <span v-if="dateFilterFormData.startDate">{{
          dateFilterFormData.startDate
        }}</span>
        <span v-if="dateFilterFormData.endDate">
          {{
            dateFilterFormData.startDate
              ? "- " + dateFilterFormData.endDate
              : dateFilterFormData.endDate
          }}
        </span>
      </div>
      <div v-if="!isSelection && showFooterActions">
        <div class="vis-row-box vis-mb--1">
          <div class="vis-line-1"></div>
        </div>
        <FilterButton
          @submitFilter="saveFilterByDate"
          @closeFilters="closeFilters"
        />
      </div>
    </template>
  </CommonFilterLayout>
</template>
<script>
import CommonFilterLayout from "./helper/CommonFilterLayout.vue";
import FilterButton from "./FilterButton.vue";
import Simple from "./DateSimpleTab.vue";
import Relative from "./DateRelativeTab.vue";
import { filterPopupTabValue, dateFilterTabList } from "./js/filters";
export default {
  components: {
    CommonFilterLayout,
    FilterButton,
    Simple,
    Relative,
  },
  props: {
    selectedComponent: {
      type: String,
    },
    filterDetailData: {
      type: Object,
    },
    showFooterActions: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    selectedTabValue() {
      this.$emit("setTabValue", this.selectedTabValue);

      if (!this.showFooterActions) {
        this.saveFilterByDate();
      }
    },
    dateFilterFormData: {
      handler() {
        if (!this.showFooterActions) {
          this.saveFilterByDate();
        }
      },
      deep: true,
    },
    relativeFilterFormData: {
      handler() {
        if (!this.showFooterActions) {
          this.saveFilterByDate();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      filterPopupTabValue: filterPopupTabValue,
      dateFilterTabList: dateFilterTabList,
      selectedTabValue: filterPopupTabValue.SELECTION,
      dateFilterFormData: {
        operator: "",
        startDate: "",
        endDate: "",
      },
      relativeFilterFormData: {
        period: "",
        periodType: "",
        duration: null,
        date: "",
      },
    };
  },
  mounted() {
    this.selectedTabValue = this.selectedComponent;
  },
  created() {
    // parent'dan gelen event'i kullanabilmek için
    this.$parent.$on("clearAllFilter", this.clearSelectedFilters);
  },
  computed: {
    isRelative() {
      return this.selectedTabValue == filterPopupTabValue.RELATIVE;
    },
    isSelection() {
      return this.selectedTabValue == filterPopupTabValue.SELECTION;
    },
  },
  methods: {
    setSelectionFormData(param) {
      if (this.isSelection) {
        this.dateFilterFormData = param;
      } else if (this.isRelative) {
        this.relativeFilterFormData = param;
      }
    },
    saveFilterByDate() {
      if (this.isSelection) {
        this.$emit(
          "saveFilterByDate",
          this.dateFilterFormData,
          this.selectedTabValue
        );
      } else if (this.isRelative) {
        this.$emit(
          "saveFilterByDate",
          this.relativeFilterFormData,
          this.selectedTabValue
        );
      }
    },
    closeFilters(value) {
      this.$emit("closeFilters", value);
    },
    clearSelectedFilters() {
      this.dateFilterFormData.endDate = "";
      this.dateFilterFormData.startDate = "";
      this.dateFilterFormData.operator = "";
      this.filterQuery = "";
      this.relativeFilterFormData.period = "";
      this.relativeFilterFormData.periodType = "";
      this.relativeFilterFormData.duration = null;
      this.relativeFilterFormData.date = "";
    },
  },
};
</script>
<style scoped>
.vis-date-component-box {
  position: relative;
  margin: 0 40px;
}
.vis-date-component-infoBox {
  position: relative;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background-color: #f1f3ff;
  margin-bottom: 15px;
}
</style>
