var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vis-tools-box vis-left-border vis-page vis-bg-color-white"},[_c('div',{staticClass:"propertiesTopBar vis-display-flex"},[_c('span',{staticClass:"vis-font-bold"},[_vm._v(_vm._s(_vm.$t("generalPages.properties")))]),_c('i',{staticClass:"vis-cursor-pointer vis-ml--auto e-icons e-large",class:_vm.customIcon.Close,attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.$emit('close')}}})]),_c('div',{staticClass:"vis-mb--1 accessContainer"},[_c('label',[_vm._v(_vm._s(_vm.$t("generalPages.searchUsersOrGroups")))]),_c('el-autocomplete',{staticClass:"vis-mb--1",staticStyle:{"width":"100%"},attrs:{"fetch-suggestions":_vm.querySearchAsync,"placeholder":_vm.$t('generalPages.searchInput')},on:{"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"value"},[_c('i',{staticClass:"vis-mr--1",class:item.type === _vm.userTypes.USER
                ? 'el-icon-user'
                : 'el-icon-office-building',attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"link"},[_vm._v(_vm._s(item.value))])])]}}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('i',{staticClass:"el-icon-plus el-input__icon addAction",attrs:{"slot":"suffix","aria-hidden":"true"},on:{"click":_vm.handleSaveConnectionAuthorization},slot:"suffix"})]),_c('vue-good-table',{attrs:{"max-height":"400px","columns":_vm.connectionAccessPermissionsColumns,"rows":_vm.connectionAccessPermissionsRows,"fixed-header":true,"sort-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(`${props.column.label}`))+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === _vm.accessPermissionsColumnsFields.Actions)?_c('div',[_c('div',[_c('span',[_c('i',{staticClass:"el-icon el-icon-remove-outline vis-cursor-pointer",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.callRemoveConnectionAuthorizationById(props.row.id)}}})])])]):(
            props.column.field === _vm.accessPermissionsColumnsFields.Authority
          )?_c('div',[(props.row.authority === _vm.AuthorizationConnectionEnum.OWNER)?_c('span',{staticStyle:{"float":"left !important"}},[_vm._v(_vm._s(props.row.authority))]):_c('el-select',{attrs:{"value":props.row.authority,"placeholder":"Permission","size":"mini"},on:{"change":(val) => _vm.controlAuthorityChanged(val, props.row)}},_vm._l((_vm.optionsAuthority),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.label,"value":item.key}})}),1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }