
import {
    autoRefreshTimes,
    fontsArr,
    fontSizesArr,
    fontWeightsArr,
    GAUGE_CHART_PROPERTIES_ENUM,
    predefineColorsArr,
} from "../../commons/dashboardProperties";
import GaugeSeperator from "../../components/panel/properties/GaugeSeperator.vue";
import { GAUGE_CHART_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/gaugeChartDefaultProperties";

export default {
    computed: {
        gaugeChartComponentsMapping() {
            return {
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.label.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.label.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.label.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.label.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_WIDTH]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_WIDTH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.progressBarWidth",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_WIDTH
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_PROGRESS_BAR_WIDTH),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_WIDTH,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_FAMILY
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_VALUE_LABEL_FONT_FAMILY),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_SIZE
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_VALUE_LABEL_FONT_SIZE),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_COLOR
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_VALUE_LABEL_FONT_COLOR),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.title.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_FAMILY
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_TITLE_FONT_FAMILY),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.title.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_WEIGHT
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_TITLE_FONT_WEIGHT),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.title.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_SIZE
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_TITLE_FONT_SIZE),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.title.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_COLOR
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_TITLE_FONT_COLOR),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_VALUES]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_VALUES,
                        this.collapse.CHART
                    ),
                    label: "generalPages.seperationPercentages",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_VALUES
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_SEPERATION_VALUES),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_VALUES,
                    component: GaugeSeperator,
                    componentType: this.componentTypes.OTHER,
                    flexClass: true,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_ENABLED]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_ENABLED,
                        this.collapse.CHART
                    ),
                    label: "generalPages.seperationEnabled",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_ENABLED
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_SEPERATION_ENABLED),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_ENABLED,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },

                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_AUTO_REFRESH),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH_CUSTOM
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_AUTO_REFRESH_CUSTOM),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_VALUE
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_DYNAMIC_TITLE_VALUE),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_GROUP_MULTIPLE_VALUES
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_GROUP_MULTIPLE_VALUES),
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_GROUP_MULTIPLE_VALUES,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_INSIGHT
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_ALLOW_DRILL_THROUGH]: {
                    id: this.getPropertyId(
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_ALLOW_DRILL_THROUGH,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.Allow Drill Through",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_ALLOW_DRILL_THROUGH
                    ], GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_ALLOW_DRILL_THROUGH),
                    componentType: this.componentTypes.SWITCH,
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_ALLOW_DRILL_THROUGH,
                    flexClass: true,
                },
                [GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_SQL]: {
                    id: this.getPropertyId(
                      GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_SQL
                      ],
                      GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_SQL,
                    flexClass: true,
                },
            }
        },
    },
};
