<template>
  <div class="vis-row">
    <!--ICON NAME START-->
    <div class="vis-col vis-col-2">
      <label class="vis-form-label">{{
        $t("conditionalFormatting.icon")
      }}</label>
      <SelectboxWithIcon
        :options="iconList"
        :value="conditionData.iconFormat.iconName"
        :placeholder="$t('conditionalFormatting.Select Icon')"
        size="mini"
        @handleChange="(value) => (conditionData.iconFormat.iconName = value)"
      />
    </div>
    <!--ICON NAME END-->
    <!--ICON COLOR START-->
    <div class="vis-col vis-col-2 vis-text--center">
      <label class="vis-form-label">{{
        $t("conditionalFormatting.Icon Color")
      }}</label>
      <el-color-picker
        size="mini"
        v-model="conditionData.iconFormat.iconColor"
      ></el-color-picker>
    </div>
    <!--ICON COLOR END-->
    <!--ICON SIZE START-->
    <div class="vis-col vis-col-2">
      <label class="vis-form-label">{{
        $t("conditionalFormatting.Icon Size")
      }}</label>
      <el-select
        size="mini"
        v-model="conditionData.iconFormat.iconSize"
        :placeholder="$t('conditionalFormatting.Icon Size')"
      >
        <el-option
          v-for="fontSize in fontSizes"
          :key="fontSize.value"
          :label="fontSize.label"
          :value="fontSize.value"
        >
        </el-option>
      </el-select>
    </div>
    <!--ICON SIZE END-->
    <!--ICON SHOW START-->
    <div class="vis-col vis-col-2">
      <label class="vis-form-label">{{
        $t("conditionalFormatting.Show")
      }}</label>
      <el-select
        size="mini"
        v-model="conditionData.iconFormat.iconShowOption"
        :placeholder="$t('conditionalFormatting.Icon Size')"
      >
        <el-option
          v-for="showOption in iconShowOptions"
          :key="showOption.value"
          :label="$t(showOption.label)"
          :value="showOption.value"
        >
        </el-option>
      </el-select>
    </div>
    <!--ICON SHOW END-->
    <!--ICON POSITION START-->
    <div class="vis-col vis-col-2">
      <label class="vis-form-label">{{
        $t("conditionalFormatting.Icon Position")
      }}</label>
      <el-select
        size="mini"
        v-model="conditionData.iconFormat.iconPosition"
        :placeholder="$t('conditionalFormatting.Icon Position')"
      >
        <el-option
          v-for="position in iconPositionOptions"
          :key="position.value"
          :label="$t(position.label)"
          :value="position.value"
        >
        </el-option>
      </el-select>
    </div>
    <!--ICON POSITION END-->
    <div
      v-if="
        conditionData.iconFormat.iconShowOption ===
        iconFormatShowType.ICON_VALUE_ONLY
      "
      class="vis-col"
    >
      <label class="vis-form-label">{{
        $t("conditionalFormatting.Custom Text")
      }}</label>
      <el-input v-model="conditionData.iconFormat.customText"></el-input>
    </div>
  </div>
</template>
<script>
import cloneDeep from "clone-deep";
import {
  fontSizes,
  iconList,
  iconShowOptions,
  iconPositionOptions,
  iconFormatShowType,
} from "./conditionalFormattingHelper";
import SelectboxWithIcon from "../../helper/SelectboxWithIcon.vue";

export default {
  components: { SelectboxWithIcon },
  props: {
    condition: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    conditionData: {
      handler(val, oldVal) {
        if (Object.keys(oldVal).length) this.setIconFormat();
        if (
          val.iconFormat.iconShowOption === iconFormatShowType.ICON_ONLY &&
          val.iconFormat.customText !== ""
        ) {
          this.conditionData.iconFormat.customText = "";
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.condition?.iconFormat)
      this.conditionData = cloneDeep(this.condition);
  },
  data() {
    return {
      conditionData: {},
      fontSizes: fontSizes,
      iconList: iconList,
      iconShowOptions: iconShowOptions,
      iconPositionOptions: iconPositionOptions,
      iconFormatShowType: iconFormatShowType,
    };
  },
  methods: {
    setIconFormat() {
      this.$emit("setIconFormat", this.conditionData);
    },
  },
};
</script>
<style scoped>
.vis-col {
  padding: 0 5px;
}
</style>
