<template>
  <div v-if="calculatedColumnFunctions" class="usage-info-wrapper">
    <div class="vis-flex--Xright">
      <i
        class="vis-cursor-pointer"
        :class="customIcon.Close"
        aria-hidden="true"
        @click="closeUsageInfo()"
      ></i>
    </div>
    <h3 class="vis-display-block vis-mb--1 vis-text--center">
      {{ $t("calculatedColumnExplanation.usageInfo") }}
    </h3>
    <span class="vis-display-block usage-info-title vis-mb--1">{{
      $t(calculatedColumnFunctions.key)
    }}</span>
    <div v-if="calculatedColumnFunctions.multipleExplanation">
      <div
        v-for="calculatedExplanation in calculatedColumnFunctions.multipleExplanation"
        :key="calculatedExplanation.key"
      >
        <span class="vis-display-block">{{
          $t(calculatedExplanation.explanation)
        }}</span>
        <span class="vis-display-block usage-info-title vis-mb--1 vis-mt--1">{{
          $t("calculatedColumnExplanation.exampleSyntax") +
          calculatedExplanation.key
        }}</span>
        <span class="vis-display-block vis-ma--1 usage-info-function-example">{{
          calculatedExplanation.example
        }}</span>
      </div>
    </div>
    <div v-else>
      <span class="vis-display-block">{{
        $t(calculatedColumnFunctions.explanation)
      }}</span>
      <span class="vis-display-block usage-info-title vis-mb--1 vis-mt--1">{{
        $t("calculatedColumnExplanation.exampleSyntax")
      }}</span>
      <span class="vis-display-block vis-ma--1 usage-info-function-example">{{
        $t(calculatedColumnFunctions.example)
      }}</span>
    </div>
  </div>
</template>

<script>
import {
  calculatedColumnOracleFunctions,
  calculatedColumnMsSqlFunctions,
  calculatedColumnClickhouseFunctions,
  calculatedColumnPostgreSqlFunctions,
  calculatedColumnSybaseAseFunctions,
  calculatedColumnSybaseIqFunctions,
  calculatedColumnTrinoFunctions
} from "../calculatedColumn/calculatedColumnContents.js";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  props: {
    calculatedColumnFunction: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      calculatedColumnOracleFunctions: calculatedColumnOracleFunctions,
      calculatedColumnMsSqlFunctions: calculatedColumnMsSqlFunctions,
      calculatedColumnClickhouseFunctions: calculatedColumnClickhouseFunctions,
      calculatedColumnPostgreSqlFunctions: calculatedColumnPostgreSqlFunctions,
      calculatedColumnSybaseAseFunctions: calculatedColumnSybaseAseFunctions,
      calculatedColumnSybaseIqFunctions: calculatedColumnSybaseIqFunctions,
      calculatedColumnTrinoFunctions: calculatedColumnTrinoFunctions,
      customIcon: CustomIcon,
    };
  },
  computed: {
    calculatedColumnFunctions() {
      return (
        this.calculatedColumnOracleFunctions?.[this.calculatedColumnFunction] ||
        this.calculatedColumnMsSqlFunctions[this.calculatedColumnFunction]||
        this.calculatedColumnClickhouseFunctions?.[this.calculatedColumnFunction] ||
        this.calculatedColumnPostgreSqlFunctions?.[this.calculatedColumnFunction] ||
        this.calculatedColumnSybaseAseFunctions?.[this.calculatedColumnFunction] || 
        this.calculatedColumnSybaseIqFunctions?.[this.calculatedColumnFunction] ||
        this.calculatedColumnTrinoFunctions?.[this.calculatedColumnFunction]
      );
    },
  },
  methods: {
    closeUsageInfo() {
      this.$emit("calculatedColumnFunctionName", null);
    },
  },
};
</script>

<style scoped>
.usage-info-wrapper {
  height: auto;
  min-height: 200px;
  background: white;
  border: 1px solid #e4e3e8;
  padding: 15px 20px;
  right: 0;
}
.usage-info-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
}
.usage-info-function-example {
  font: normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #b40ed4;
}
</style>
