import { getChartLabelFormatByAggregation } from "../chartOptionsHelper";
import { generalChartSearch } from "./generalChartSearch"


export const radarChartSearch = (params, searchData, chartI) => {
    const result= generalChartSearch(params,searchData,chartI);
    const aggregationFormats = params?.aggregation?.map((a) => a.format);

    const radarIndicator = result.dataAxis.map((axisName, index)=>{
      let max= 0;
      result.dataSeries.forEach((dataSerie)=>{
        if(max < dataSerie[index]){
          max = dataSerie[index]
        }
      })
      return {
        max:  max,
        name: getChartLabelFormatByAggregation(axisName, aggregationFormats)
      }
    })

    return{
      ...result,
      radarIndicator,
      aggregationFormats
    }
}