<template>
  <el-dialog :title="title" width="800px" :visible="isActiveProjectNamePopup">
    <div class="vis-row-box mb-15">
      <label>{{ $t("generalPages.labelName") }}</label>
      <input
        :value="projectName"
        @keyup="(e) => changeProjectName(e)"
        class="vis-input"
        type="text"
      />
    </div>
    <div class="vis-row-box mb-15">
      <label>{{ $t("generalPages.labelLocation") }}</label>
      <div class="vis-vgt-card">
        <div class="vis-vgt-card-title vis-flex--spacebeetwen-Ycenter">
          <ul class="vis-user-tabs vis-flex--Ycenter">
            <li 
              @click="getFoldersById(null, null)"
              class="vis-main-breadcrumb vis-ml--none"
            >{{ $t("home.all") }}</li>
            <div 
              v-for="folder in folderNameList"
              :key="folder.id"
              class="vis-flex--Ycenter"
            >
              <li class="vis-main-breadcrumb"><i aria-hidden="true" :class="CustomIcon.ArrowRight"></i></li>
              <li 
                @click="getFoldersById(folder.id, folder.name)"
                :class="{'vis-font-medium': folderId == folder.id}"
                class="vis-main-breadcrumb"
              >
                {{ folder.name }}
              </li>
            </div>
          </ul>
          <InputSearch @keyupSearchData="searchFolder = $event" />
        </div>
        <vue-good-table
          :columns="columns"
          :rows="tableRows"
          max-height="300px"
          :fixed-header="true"
          :search-options="{
            enabled: true,
            externalQuery: searchFolder,
          }"
          :row-style-class="rowStyleClassFn"
          @on-row-click="onOnRowClick"
          @on-row-dblclick="(item) => getFoldersById(item.row.id, item.row.name)"
        >
        </vue-good-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$emit('closePopup', false)"
        class="vis-cancel-btn"
        type="text"
        >{{ $t("generalPages.cancel") }}</el-button
      >
      <el-button @click="setProjectName()" type="primary" size="small">{{
        $t("generalPages.save")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import InputSearch from "./Form/InputSearch.vue";
import { CustomIcon } from "../../assets/js/custom-icons";
export default {
  components: { VueGoodTable, InputSearch },
  props: {
    title: {
      type: String,
    },
    labelName: {
      type: String,
    },
    projectName: {
      type: String,
    },
    tableRows: {
      type: Array,
    },
    isActiveProjectNamePopup: {
      type: Boolean,
    },
    folderNameList: {
      type: Array,
    }
  },
  data() {
    return {
      projectNameValue: "",
      searchFolder: "",
      selectedRow: "",
      columns: [
        {
          label: this.$t("generalPages.labelName"),
          field: "name",
        }
      ],
      folderId: null,
      CustomIcon: CustomIcon,
    };
  },
  mounted() {
    this.projectNameValue = this.projectName;
  },
  methods: {
    setProjectName() {
      this.$emit("setProjectName", this.projectNameValue);
    },
    changeProjectName(e) {
      this.projectNameValue = e.target.value;
    },
    onOnRowClick(item) {
      this.selectedRow = item.row.originalIndex;
      this.$emit("setFolderId", item.row.id);
    },
    getFoldersById(id, name) {
      this.$emit("getFoldersById", id, name);
    },
    rowStyleClassFn(row) {
      return row.originalIndex === this.selectedRow ? 'vis-selectedRow':'';
    },
  },
};
</script>
<style scoped></style>
