<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold">
        {{ $t("datamodel.datasetProperties") }}</span
      >
    </div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in allCollapses"
        :key="collapse.key"
        :title="$t(`${collapse.label}`)"
        :name="collapse.key"
        :disabled="collapse.disabled"
      >
        <DatasetRightPropertiesPanelDBGeneral
          v-if="collapse.key === collapseTypes.general"
          :selectedDataset="selectedDataset"
          :sqlViewVisible="sqlViewVisible"
          :connectionLength="connectionLength"
          :connectionName="connectionName"
          :type="type"
          :selectedDatasetFieldsLength="selectedDatasetFieldsLength"
          @propertyChanged="(value) => $emit('dbGeneralPropertyChanged', value)"
        />
        <DataModelRightPropertiesPanelDBScheduling
          v-else-if="collapse.key === collapseTypes.scheduling"
        />
        <DataModelRightPropertiesPanelDBLoadOptions
          v-else-if="collapse.key === collapseTypes.load_options"
        />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import DatasetRightPropertiesPanelDBGeneral from "./DatasetRightPropertiesPanelDBGeneral";
import DataModelRightPropertiesPanelDBScheduling from "./DataModelRightPropertiesPanelDBScheduling";
import DataModelRightPropertiesPanelDBLoadOptions from "./DataModelRightPropertiesPanelDBLoadOptions";
import { COMPONENT_PRIVILEGES } from "../../../util/authorization";
import componentAvailabilityCheck from "../../../mixins/componentAvailabilityCheck";

export default {
  components: {
    DatasetRightPropertiesPanelDBGeneral,
    DataModelRightPropertiesPanelDBScheduling,
    DataModelRightPropertiesPanelDBLoadOptions,
  },
  mixins: [componentAvailabilityCheck],
  props: {
    selectedDataset: {
      type: Object,
    },
    sqlViewVisible: {
      type: Boolean,
    },
    connectionLength: {
      type: Number,
    },
    selectedDatasetFieldsLength: {
      type: Number,
    },
    type: {
      type: String, //csv, db, null
    },
    connectionName: {
      type: String,
    },
  },
  data() {
    return {
      collapseTypes: {
        general: "general",
        scheduling: "scheduling",
        load_options: "load_options",
      },
      activeCollapses: [],
    };
  },
  mounted() {
    this.activeCollapses.push(this.collapseTypes.general);
  },
  computed: {
    allCollapses() {
      return [
        {
          key: this.collapseTypes.general,
          label: "generalPages.general",
          isShow: true,
        },
        this.isSchedulingAvailable
          ? {
              key: this.collapseTypes.scheduling,
              label: "generalPages.schedule",
              isShow: !this.sqlViewVisible,
              disabled: true,
            }
          : null,
        this.isLoadOptionsAvailable
          ? {
              key: this.collapseTypes.load_options,
              label: "generalPages.loadOptions",
              isShow: !this.sqlViewVisible,
            }
          : null,
      ].filter((x) => x && x.isShow);
    },
    isSchedulingAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION
      );
    },
    isLoadOptionsAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION
      );
    },
  },
};
</script>
