<template>
  <div
    v-bind:style="{
      left: obj.left + 'px',
      top: obj.top + 'px',
    }"
    class="flowchart-object flowchart-action scenario-state-start-node scenario-flowchart-node"
    :class="{ 'scenario-state-start-node-selected': obj.isNodeSelected }"
  >
    <span class="material-icons-outlined scenario-state-start-node-icon"
      >home</span
    >
    <i
      :class="`${CustomIcon.CircleOutline} scenario-state-start-node-add-join-action visualize-add-connection-indicator-start`"
      aria-hidden="true"
      data-jtk-source="true"
      data-jtk-port-type="source"
    >
    </i>
  </div>
</template>

<script>
import JsplumbVue2BaseEditableNode from "./JsplumbVue2BaseEditableNode.vue";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  mixins: [JsplumbVue2BaseEditableNode],
  data() {
    return {
      CustomIcon: CustomIcon,
    };
  },
  methods: {
    onEditClicked() {
      this.$parent.$parent.onEditClicked(this.obj.id);
    },
    onDeleteClicked() {
      this.$parent.$parent.onDeleteClicked(this.obj.id);
    },
  },
};
</script>

<style>
.visualize-add-connection-indicator-start {
  color: black;
  font-size: 1.25rem;
  line-height: 20px;
  position: absolute;
  bottom: 3px;
  right: -6px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.scenario-state-start-node:hover .scenario-state-start-node-add-join-action {
  display: block;
}
.scenario-state-start-node-add-join-action {
  font-size: 1.25rem;
  cursor: pointer;
  color: black !important;
  display: none;
}
.scenario-state-start-node {
  padding: 0px;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  cursor: move;
  color: white;
  font-size: 16px;
  width: 55px;
  height: 55px;
  background: #18a231 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scenario-state-start-node-selected {
  border: 2px solid var(--primary-lighteen-1);
}
.scenario-state-start-node-icon {
  font-size: 30px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.scenario-state-start-node:hover .add-connection-indicator {
  display: block;
}
</style>
