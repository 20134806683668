<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold"
        >{{ $t(chartTypeName) }}
        {{ $t("dashboard.properties.dashboardProperties") }}</span
      >
      <i
        v-if="showCloseIcon"
        @click="closeColumnBasedProperties"
        class="vis-cursor-pointer"
        style="margin-left: auto"
        :class="CustomIcon.Close"
        aria-hidden="true"
      />
    </div>
    <el-collapse v-model="activeCollapses">
      <el-collapse-item
        v-for="dashboardPropertiesCollapse in dashboardPropertiesCollapses"
        :class="{
          'vis-collapse-item': true,
          'p-0': dashboardPropertiesCollapse.hasPadding === false,
        }"
        :key="dashboardPropertiesCollapse.name"
        :title="$t(dashboardPropertiesCollapse.title)"
        :name="dashboardPropertiesCollapse.name"
        :disabled="disabledCollapseItem"
      >
        <RowBox
          v-for="dashboardPropertiesCollapseComponent in dashboardPropertiesCollapse.components"
          :key="dashboardPropertiesCollapseComponent.id"
          :spacebetween="
            spacebetween(dashboardPropertiesCollapseComponent.flexClass)
          "
          :yCenter="yCenter(dashboardPropertiesCollapseComponent.flexClass)"
          :paddingLeft="dashboardPropertiesCollapseComponent.paddingLeftClass"
          :isShowCustomLabel="dashboardPropertiesCollapseComponent.isShowCustomLabel"
          :customLabelText="dashboardPropertiesCollapseComponent.customLabelText"
        >
          <!-- SWITCH STARTS -->
          <template
            v-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.SWITCH
            "
            v-slot:content
          >
            <label :for="dashboardPropertiesCollapseComponent.id">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>

            <el-switch
              :id="dashboardPropertiesCollapseComponent.id"
              :value="dashboardPropertiesCollapseComponent.value"
              @change="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
              :disabled="
                dashboardPropertiesCollapseComponent.readOnly ||
                isCheckDisableSwitch(
                  dashboardPropertiesCollapseComponent.property
                )
              "
            >
            </el-switch>
          </template>
          <!-- SWITCH END -->
          <!-- INPUT START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.INPUT
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <input
              class="vis-input"
              :type="dashboardPropertiesCollapseComponent.inputType"
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              @keyup="
                (e) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value: e.target.value,
                  })
              "
            />
          </template>
          <!-- INPUT END -->

          <!-- INPUT WITH ENTER START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.INPUT_WITH_ENTER
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <input
              v-if="!dashboardPropertiesCollapseComponent.readOnly"
              class="vis-input"
              :title="$t('dashboardPropertiesLocale.general.pressEnter')"
              :type="dashboardPropertiesCollapseComponent.inputType"
              :value="dashboardPropertiesCollapseComponent.value"
              @keyup="
                (e) =>
                  setComponentStyleWithEnterCheck({
                    property: dashboardPropertiesCollapseComponent.property,
                    value: e.target.value,
                    event: e,
                  })
              "
            />
            <input
              v-else
              class="vis-input"
              :type="dashboardPropertiesCollapseComponent.inputType"
              v-model="dashboardPropertiesCollapseComponent.value"
              readonly
            />
          </template>
          <!-- INPUT END -->

           <!-- INPUT NUMBER START -->
           <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.INPUT_NUMBER
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <input
              class="vis-input"
              type="number"
              :placeholder="$t(dashboardPropertiesCollapseComponent.placeHolder)"
              :min="dashboardPropertiesCollapseComponent.minValue"
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              @input="
                (e) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value: e.target.value,
                  })
              "
            />
          </template>
          <!-- INPUT END -->

          <!-- COLOR PICKER START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.COLOR_PICKER
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <el-color-picker
              :value="dashboardPropertiesCollapseComponent.value"
              :predefine="dashboardPropertiesCollapseComponent.predefineColors"
              :size="dashboardPropertiesCollapseComponent.size"
              @change="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
            ></el-color-picker>
          </template>
          <!-- COLOR PICKER END -->
          <!-- SELECT START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.SELECT_BOX
            "
            v-slot:content
          >
            <label for="">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>
            <el-select 
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              :placeholder="$t('generalPages.selectValue')"
              :clearable="dashboardPropertiesCollapseComponent.clearable"
              @change="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
            >
              <el-option
                v-for="item in dashboardPropertiesCollapseComponent.selectValues"
                :key="item.value"
                :label="selectboxLabel(item)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <!-- SELECT END -->

          <!-- CUSTOM_MULTIPLE_SELECT_BOX START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.CUSTOM_MULTIPLE_SELECT_BOX
            "
            v-slot:content
          >
            <label for="">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>
            <el-select 
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              :placeholder="$t('generalPages.selectValue')"
              :clearable="dashboardPropertiesCollapseComponent.clearable"
              multiple
              collapse-tags
              @change="
                (value) =>
                setComponentStyleWithCustomMultipleSelectBox({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  }, 
                  dashboardPropertiesCollapseComponent)
              "
            >
            <el-option
                :key="selectBoxOption.ALL_ITEM"
                label="All Tabs"
                :value="selectBoxOption.ALL_ITEM"
                class="vis-elselect-option-border-bottom"
              >
              </el-option>
              <el-option
                v-for="item in dashboardPropertiesCollapseComponent.selectValues"
                :key="item.value"
                :label="selectboxLabel(item)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <!-- CUSTOM_MULTIPLE_SELECT_BOX END -->

          <!-- CUSTOM_ICON_SELECT_BOX START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.CUSTOM_ICON_SELECT_BOX
            "
            v-slot:content
          >
            <label for="">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>
            <SelectboxWithIcon
              :options="dashboardPropertiesCollapseComponent.selectValues"
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              width="25%"
              @handleChange="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
            />
          </template>
          <!-- CUSTOM_ICON_SELECT_BOX END -->
          <!-- OTHER START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.OTHER
            "
            v-slot:content
          >
            <component
              :is="dashboardPropertiesCollapseComponent.component"
              :value="dashboardPropertiesCollapseComponent.value"
              :palettes="colorPalettes"
              @setComponentStyle="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
              @setDashboardStyle="
                (value) =>
                  setDashboardStyle({
                    property:
                      dashboardPropertiesCollapseComponent.dashboardProperty,
                    value,
                  })
              "
            ></component>
          </template>
          <!-- OTHER END -->

          <!-- TEXTAREA START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.TEXTAREA
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <textarea
              class="vis-textarea"
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              @keyup="
                (event) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value: event.target.value,
                  })
              "
              :rows="dashboardPropertiesCollapseComponent.textAreaRow || '2'"
            >
            </textarea>
          </template>
          <!-- TEXTAREA END -->

          <!-- BUTTON STARTS -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.BUTTON
            "
            v-slot:content
          >
            <el-button
              style="width: 100%"
              @click="$emit('onPropertiesEventTriggered', { eventId: dashboardPropertiesCollapseComponent.eventId })"
              >{{ $t(dashboardPropertiesCollapseComponent.label) }}</el-button
            >
          </template>
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.SHOW_BUTTON
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <el-button
              type="primary"
              size="small"
              @click="$emit('onPropertiesEventTriggered', { eventId: dashboardPropertiesCollapseComponent.eventId })"
              >{{ $t(dashboardPropertiesCollapseComponent.placeholder) }}</el-button
            >
          </template>
          <!-- BUTTON END -->
          <!-- DATE RANGE START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.DATE_RANGE
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <div class="vis-flex--spacebeetwen-Ycenter">
              <el-date-picker
                :value="dashboardPropertiesCollapseComponent.value"
                :class="dashboardPropertiesCollapseComponent.className"
                :placeholder="
                  $t(dashboardPropertiesCollapseComponent.placeHolder)
                "
                format="dd/MM/yyyy"
                :picker-options="
                  pickerOptions(
                    dashboardPropertiesCollapseComponent.isPickerOptionsActive,
                    dashboardPropertiesCollapseComponent.property
                  )
                "
                @input="
                  (value) =>
                    setComponentStyle({
                      property: dashboardPropertiesCollapseComponent.property,
                      value,
                    })
                "
              >
              </el-date-picker>
            </div>
          </template>
          <!-- DATE RANGE END -->
        </RowBox>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import RowBox from "./RowBox.vue";
import dashboardProperties from "../../../mixins/dashboardProperties";
import { cssStylePropertiesEnum, selectBoxOption } from "../../../commons/dashboardProperties";
import { includesValues } from "../../../commons/Enum";
import { CustomIcon } from "../../../assets/js/custom-icons";
import SelectboxWithIcon from "../../helper/SelectboxWithIcon.vue";

export default {
  mixins: [dashboardProperties],
  props: {
    chartType: {
      type: String,
    },
    chartTypeName: {
      type: String,
    },
    colorPalettes: {
      type: Array,
    },
    customFilterType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabledCollapseItem: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RowBox,
    SelectboxWithIcon
  },
  watch: {
    disabledCollapseItem(val) {
      if (val) this.activeCollapses = [];
      else  this.activeCollapses = [
        this.collapse.GENERAL,
        this.collapse.CARD,
        this.collapse.CHART,
        this.collapse.PIE_CHART_VALUE_LABEL,
      ];
    }
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      defaultBorderStyle: cssStylePropertiesEnum.BORDER,
      selectBoxOption: selectBoxOption,
    };
  },
  created() {
    //"this.collapse" mixin componenti data() içinden kullanılıyor.
    this.activeCollapses = [
      this.collapse.GENERAL,
      this.collapse.CARD,
      this.collapse.CHART,
      this.collapse.PIE_CHART_VALUE_LABEL,
    ];
  },
  computed: {
    showCloseIcon() {
      return this.activeComponentType === this.dashboardComponentTypes.DATA_TABLE_COLUMN || this.activeComponentType === this.dashboardComponentTypes.PIVOT_TABLE_COLUMN; 
    }
  },
  methods: {
    getPropertyValue(key) {
      const selectedKey = Object.keys(this.componentStyle).find((f) =>
        f?.includes(key)
      );

      return this.componentStyle?.[selectedKey];
    },
    pickerOptions(isPickerOptionsActive, property) {
      if (!isPickerOptionsActive) return {};

      const START_DATE = "START_DATE",
        END_DATE = "END_DATE";
      const startDate = new Date(this.getPropertyValue(START_DATE)).getTime();
      const endDate = new Date(this.getPropertyValue(END_DATE)).getTime();

      return {
        disabledDate(time) {
          const timeDate = time.getTime();

          if (startDate && !endDate) {
            return timeDate <= startDate;
          } else if (endDate && !startDate) {
            return timeDate >= endDate;
          } else if (
            (startDate &&
              timeDate <= startDate &&
              property?.includes(END_DATE)) ||
            (endDate && timeDate >= endDate && property?.includes(START_DATE))
          ) {
            return true;
          }
        },
      };
    },
    closeColumnBasedProperties() {
      this.$emit("closeColumnBasedProperties");
    },
    setDataTableOrPivotColumnBasedProperties(params) {
      if (
        [
          this.dashboardComponentTypes.DATA_TABLE_COLUMN,
          this.dashboardComponentTypes.PIVOT_TABLE_COLUMN,
        ].includes(this.activeComponentType)
      ) {
        this.$emit("setDataTableColumnBasedComponentProperties", params);

        return true;
      } else return false;
    },
    setComponentStyle(params) {
      const isChartPivotOrTable = this.setDataTableOrPivotColumnBasedProperties(params);

      if (isChartPivotOrTable) return;

      this.$emit("setComponentStyle", params);
    },
    setComponentStyleWithEnterCheck(params) {
      const event = params.event;

      if (event.key !== "Enter" || event.keyCode !== 13) return;

      const isChartPivotOrTable =
        this.setDataTableOrPivotColumnBasedProperties(params);

      if (isChartPivotOrTable) return;
      
      this.$emit("setComponentStyle", params);
    },
    setComponentStyleWithCustomMultipleSelectBox(params, component) {
      if (!this.checkIncludesValueInArray(component.value, selectBoxOption.ALL_ITEM) && this.checkIncludesValueInArray(params.value, selectBoxOption.ALL_ITEM)) {
          params.value = params.value.filter(f=> f === selectBoxOption.ALL_ITEM);
      }
      else if (this.checkIncludesValueInArray(params.value, selectBoxOption.ALL_ITEM) && params.value.length) {
          params.value = params.value.filter(f=> f !== selectBoxOption.ALL_ITEM);
      }
      this.$emit("setComponentStyle", params);
    },
    setDashboardStyle(params) {
      this.$emit("setDashboardStyle", params);
    },
    
    setBorderStyle(hasBorder) {
      if (hasBorder) {
        this.$emit("setBorderStyle", this.defaultBorderStyle);
      } else {
        this.$emit("resetBorderStyle");
      }
    },
    checkIncludesValueInArray(arr, incValue){
      return arr?.includes(incValue);
    },
    spacebetween(value) {
      if (value) return "vis-flex--spacebeetwen";
      else return "";
    },
    yCenter(value) {
      if (value) return "vis-flex--Ycenter";
      else return "";
    },
    selectboxLabel(item) {
      if (item.labelNotLocale) {
        return `${item.labelNotLocale} ${this.$t(item.label)}`;
      } else {
        return this.$t(item.label);
      }
    },
    isCheckDisableSwitch(property) {
      if (Object.keys?.(this.customFilterType).length) {
        let isDisabledMultiSelect = false;
        let isDisabledMandatory = false;
        const key = this.customFilterType.key[property];

        if (property.includes(includesValues.ALLOW_MULTISELECT) && key)
          isDisabledMultiSelect = true;
        else if (property.includes(includesValues.MANDATORY) && !key)
          isDisabledMandatory = true;

        return (
          Object.keys(this.customFilterType?.key).includes(property) &&
          this.customFilterType.isDisabled &&
          !isDisabledMultiSelect &&
          !isDisabledMandatory
        );
      }
    },
  },
};
</script>

<style>
/* scoped yapınca yazılan css'ler ::v-deep kullansakta çalışmıyor. */

.vis-elselect-option-border-bottom {
  border-bottom: 1px solid #d8d8d8 !important;
}
.vis-row-box .el-select__tags {
  flex-wrap: inherit !important;
  max-width: 180px !important;
}
</style>