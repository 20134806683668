<template>
  <div>
    <div class="vis-chatGpt-banner">
      <span class="vis-font-size-0875 vis-font-medium">{{
        $t("generalPages.How can I help you today?")
      }}</span>
      <div class="vis-chartGpt-banner-iconbox">
        <i
          :class="[
            customIcon.SaveOutlined,
            { 'vis-cursor-notAllowed': !visualizeAIChartId },
          ]"
          class="vis-mr--1 vis-cursor-pointer"
          aria-hidden="true"
          @click="$emit('saveVisualizeAI')"
        ></i>
        <i
          :class="customIcon.Close"
          class="vis-cursor-pointer"
          aria-hidden="true"
          @click="$emit('closeVisualizeAI')"
        ></i>
      </div>
    </div>
    <div
      class="vis-chatGpt-card"
      :class="{ 'vis-visualizeAI-card-with-chart': visualizeAIChartId }"
    >
      <div v-if="!visualizeAIChartId" class="vis-visualizeAI-empty-card">
        <i aria-hidden="true" :class="customIcon.TipsAndUpdated"></i>
      </div>
      <div class="vis-chatGpt-card-container">
        <div class="vis-chatGpt-card-title vis-flex--spacebeetwen-Ycenter">
          <el-tabs v-model="activeTabName">
            <el-tab-pane
              :label="$t('generalPages.assistant')"
              :name="chatGptTabs.INSIGHTS"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <div class="vis-chatGpt-card-content">
          <div v-if="isActiveTabNameInsights">
            <VueChatBot
              :is-open="true"
              :messages="aiMessages"
              :options="botOptions"
              :bot-typing="isLoading"
              @msg-send="messageSendHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueChatBot } from "vue-chat-bot";
import { CustomIcon } from "../../assets/js/custom-icons";
import { chatGptTabs } from "../../util/chatGptHelper";
export default {
  components: {
    VueChatBot,
  },
  props: {
    aiMessages: {
      type: Array,
    },
    visualizeAIChartId: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTabName: chatGptTabs.INSIGHTS,
      customIcon: CustomIcon,
      chatGptTabs: chatGptTabs,
      botOptions: {
        colorScheme: "#3d62f8",
        animation: true,
        inputPlaceholder: this.$t("generalPages.message"),
        botAvatarImg: this.getLogoImageUrl(),
      },
    };
  },
  mounted() {
    if (this.aiMessages?.length && !this.visualizeAIChartId) {
      this.$emit("resetAImessages");
    }
  },
  computed: {
    isActiveTabNameInsights() {
      return this.activeTabName === chatGptTabs.INSIGHTS;
    },
  },
  methods: {
    getLogoImageUrl() {
      let images = require.context("../../assets/images/", false, /\.svg$/);
      return images("./" + "topbar-logo" + ".svg");
    },
    messageSendHandler(value) {
      this.$emit("addNewChartWithChatBot", {
        agent: "user",
        type: "text",
        text: value.text,
      });
    },
  },
};
</script>

<style scoped>
::v-deep .qkb-board-content {
  overflow: hidden auto;
}
::v-deep .qkb-bot-ui {
  position: relative;
  bottom: 0;
  right: 0;
}
::v-deep .qkb-board {
  position: relative !important;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  border-radius: 0;
}
::v-deep .qkb-bot-bubble,
::v-deep .qkb-board-header {
  display: none;
}

.vis-chatGpt-card-container {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.vis-chatGpt-card {
  position: fixed;
  top: 96px;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 4px 0 20px 0;
  transition: all 0.3s ease;
  transform: none !important;
  z-index: 2001;
  background-color: #f8f9ff;
  display: flex;
  justify-content: end;
}
.vis-chatGpt-card .vue-resizable-handle {
  display: none !important;
}
.vis-chatGpt-card-title {
  position: relative;
  width: 100%;
  padding: 0 16px;
}
::v-deep .el-tabs__nav-wrap::after {
  width: 0 !important;
}
::v-deep .el-tabs__active-bar {
  background-color: var(--primary);
}
::v-deep .el-tabs__item,
::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: var(--secondary-dark-1);
}

.vis-chatGpt-card-content {
  position: relative;
  width: 100%;
  padding: 0 18px;
}
.vis-chatGpt-card-content ul,
.vis-summary-text-box {
  height: 600px;
}
.vis-chatGpt-card-content ul li span {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--secondary-dark-1);
}

.vis-chatGpt-banner {
  position: fixed;
  width: 100%;
  height: 40px;
  top: 55px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1;
}
.vis-visualizeAI-empty-card {
  position: absolute;
  left: 0;
  top: 0;
  width: 70%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vis-visualizeAI-empty-card i {
  font-size: 150px;
  color: #e3e3e3;
}
</style>
