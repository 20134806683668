<template>
  <div>
    <form @change="setMeasureRuleComponentList()">
      <div
        class="vis-row"
        v-for="(item, index) in measureRuleComponentListData"
        :key="index"
      >
        <div v-if="index != 0" class="vis-col vis-col-12">
          <el-tag @click="changeCommonOperator()" size="small">{{
            $t(commonOperator.label)
          }}</el-tag>
        </div>
        <div class="vis-col">
          <label class="vis-form-label">{{
            $t("filterComponents.select_operator")
          }}</label>
          <el-select
            @change="setMeasureRuleComponentList()"
            v-model="item.operator"
            size="mini"
            :placeholder="$t('filterComponents.select_operator')"
          >
            <el-option
              v-for="item in measureOptionList"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="!isValueInputDeactive(item.operator)" class="vis-col">
          <label class="vis-form-label">{{
            $t("filterComponents.enterValue")
          }}</label>
          <el-input
            :placeholder="$t('filterComponents.enterValue')"
            v-model="item.value1"
            size="mini"
          ></el-input>
        </div>
        <div v-if="isSecondInputActive(item.operator)" class="vis-col">
          <label class="vis-form-label">{{
            $t("filterComponents.enterValue")
          }}</label>
          <el-input
            placeholder="Enter Value"
            v-model="item.value2"
            size="mini"
          ></el-input>
        </div>
      </div>
    </form>
    <!-- Sonraki Aşamalarda Devreye Alınacak. -->
    <!-- <div v-if="isActiveCriteriaButton" class="vis-row">
      <div class="vis-col vis-col-12">
        <el-button type="text" @click="addMeasureRuleComponent()">{{
          $t("filterComponents.addNewCriteria")
        }}</el-button>
      </div>
    </div> -->
  </div>
</template>
<script>
import cloneDeep from "clone-deep";
import {
  ruleTabOrOperator,
  ruleTabAndOperator,
  operator,
  measureOptions,
  objectsIncludesNull,
  objectsIncludesBetween,
} from "./js/filters";

export default {
  props: {
    measureRuleComponentList: {
      type: Array,
    },
    filterDetailData: {
      type: Object,
    },
  },
  data() {
    return {
      measureRuleComponentListData: [],
      commonOperator: ruleTabOrOperator,
      selectedOperator: null,
    };
  },
  watch: {
    measureRuleComponentList() {
      this.changeMeasureRuleComponentList();
    },
  },
  mounted() {
    this.changeMeasureRuleComponentList();
  },
  computed: {
    measureOptionList() {
      return measureOptions;
    },
    isActiveCriteriaButton() {
      return this.measureRuleComponentListData.length <= 1;
    },
  },
  methods: {
    addMeasureRuleComponent() {
      this.$emit("addMeasureRuleComponent", {
        operator: "",
        value1: "",
        value2: "",
      });
    },
    setMeasureRuleComponentList() {
      this.$emit(
        "setMeasureRuleComponentList",
        this.measureRuleComponentListData
      );
    },
    changeMeasureRuleComponentList() {
      this.measureRuleComponentListData = cloneDeep(
        this.measureRuleComponentList
      );
    },
    changeCommonOperator() {
      if (this.commonOperator.value == operator.OR) {
        this.commonOperator = ruleTabAndOperator;
      } else {
        this.commonOperator = ruleTabOrOperator;
      }
    },
    isSecondInputActive(selectedOperator) {
      return Object.values(objectsIncludesBetween).includes(selectedOperator);
    },
    isValueInputDeactive(selectedOperator) {
      return Object.values(objectsIncludesNull).includes(selectedOperator);
    },
  },
};
</script>
<style scoped>
.vis-text-capitalize {
  text-transform: capitalize;
}
</style>
