
import {
    alignItems,
    fontsArr,
    fontSizesArr,
    fontWeightsArr,
    predefineColorsArr,
    verticalAlignItems,
    visualMapTypes,
    GEO_MAP_CHART_PROPERTIES_ENUM,
    countries,
    allPropertiesKeys,
    autoRefreshTimes,
    showOnlyArr
} from "../../commons/dashboardProperties";
import { GEO_MAP_CHART_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/geoMapDefaultProperties";
import NumberRange from "../../components/panel/properties/NumberRange.vue";
import ColorPaletteSelector from "../../components/panel/properties/ColorPaletteSelector";

export default {
    computed: {
        geoMapComponentsMapping() {
            return {
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VALUE_LABEL]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VALUE_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VALUE_LABEL
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VALUE_LABEL,
                    flexClass: true,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.hideOverlap",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS),
                    componentType: this.componentTypes.SWITCH,
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS,
                    flexClass: true,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_ONLY]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_ONLY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showOnly.title",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_ONLY
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_SHOW_ONLY),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_ONLY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: showOnlyArr,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_INSIGHT
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_SQL]: {
                    id: this.getPropertyId(
                      GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_SQL
                      ],
                      GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_SQL,
                    flexClass: true,
                },
                 [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_BORDER_COLOR]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_BORDER_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.borderSettings.borderColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_BORDER_COLOR
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_BORDER_COLOR),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_BORDER_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COUNTRY]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COUNTRY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.mapSettings.selectedCountry",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COUNTRY
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_SELECTED_COUNTRY),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COUNTRY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: countries,
                    rerenderChart: true,
                    flexClass: false,
                },
                // VISUAL MAP
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VISUALMAP]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VISUALMAP,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VISUALMAP
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_SHOW_VISUALMAP),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VISUALMAP,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_TYPE]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_TYPE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.type",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_TYPE
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VISUALMAP_TYPE),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_TYPE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: visualMapTypes,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VALUES]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.values",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VALUES
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VISUALMAP_VALUES),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VALUES,
                    component: NumberRange,
                    componentType: this.componentTypes.OTHER,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_SIZE]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_SIZE
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VISUALMAP_FONT_SIZE),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_FAMILY
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VISUALMAP_FONT_FAMILY),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_COLOR]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_COLOR
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_VISUALMAP_FONT_COLOR),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_COLOR,
                    componentType: this.componentTypes.COLOR_PICKER,
                    size: "mini",
                    selectValues: predefineColorsArr,
                    flexClass: true,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COLOR_PALETTE]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COLOR_PALETTE,
                        this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COLOR_PALETTE
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_SELECTED_COLOR_PALETTE),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COLOR_PALETTE,
                    dashboardProperty: allPropertiesKeys.CUSTOM_COLOR_PALETTES,
                    componentType: this.componentTypes.OTHER,
                    component: ColorPaletteSelector
                },

                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_AUTO_REFRESH),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH_CUSTOM
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_AUTO_REFRESH_CUSTOM),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_VALUE
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_DYNAMIC_TITLE_VALUE),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_GROUP_MULTIPLE_VALUES
                    ], GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
            }
        },
    },
};
