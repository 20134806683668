
import {
    allPropertiesKeys,
    autoRefreshTimes,
    fontsArr,
    fontSizesArr,
    legendMaxCharArr,
    fontWeightsArr,
    labelValuesAlignTo,
    PIE_CHART_PROPERTIES_ENUM,
    predefineColorsArr,
    showOnlyArr,
    verticalAlignItems,
    legendPositionArr
} from "../../commons/dashboardProperties";
import { PIE_CHART_DEFAULT_CONFIGURATIONS} from "../../mocks/pieChartDefaultProperties";
import ColorPaletteSelector from "../../components/panel/properties/ColorPaletteSelector";
import { getPropertiesWithUndefinedCheck } from "../../util/chartViewHelper";

export default {
    computed: {
        pieChartComponentsMapping() {
            return {
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_DOUGHNUT]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_DOUGHNUT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.doughnut",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_DOUGHNUT
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_SHOW_DOUGHNUT),
                    componentType: this.componentTypes.SWITCH,
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_DOUGHNUT,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_VALUE_LABEL]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_VALUE_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_VALUE_LABEL
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_VALUE_LABEL,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_ONLY]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_ONLY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showOnly.title",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_ONLY
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_SHOW_ONLY),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_ONLY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: showOnlyArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_INSIGHT
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_ALLOW_DRILL_THROUGH]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_ALLOW_DRILL_THROUGH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Allow Drill Through",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_ALLOW_DRILL_THROUGH
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_ALLOW_DRILL_THROUGH),
                    componentType: this.componentTypes.SWITCH,
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_ALLOW_DRILL_THROUGH,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_SQL]: {
                    id: this.getPropertyId(
                      PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_SQL],
                      PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_SQL,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_LEGEND]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_LEGEND,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_LEGEND
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_SHOW_LEGEND),
                    componentType: this.componentTypes.SWITCH,
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_LEGEND,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_MAX_CHARACTER]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_MAX_CHARACTER,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Max Character",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_MAX_CHARACTER
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_LEGEND_MAX_CHARACTER),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_MAX_CHARACTER,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendMaxCharArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_FAMILY
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_VALUE_LABEL_FONT_FAMILY),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_WEIGHT
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_VALUE_LABEL_FONT_WEIGHT),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_SIZE
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_VALUE_LABEL_FONT_SIZE),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_COLOR
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_VALUE_LABEL_FONT_COLOR),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_POSITION]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Legend Position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_POSITION
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_LEGEND_POSITION),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_POSITION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendPositionArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_VERTICAL_ALIGN
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_LEGEND_VERTICAL_ALIGN),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_FAMILY
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_LEGEND_FONT_FAMILY),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_WEIGHT
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_LEGEND_FONT_WEIGHT),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_SIZE]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_SIZE
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_LEGEND_FONT_SIZE),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_COLOR]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_COLOR
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_LEGEND_FONT_COLOR),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SELECTED_COLOR_PALETTE]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SELECTED_COLOR_PALETTE,
                        this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SELECTED_COLOR_PALETTE
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_SELECTED_COLOR_PALETTE),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SELECTED_COLOR_PALETTE,
                    dashboardProperty: allPropertiesKeys.CUSTOM_COLOR_PALETTES,
                    componentType: this.componentTypes.OTHER,
                    component: ColorPaletteSelector
                },

                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_AUTO_REFRESH),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH_CUSTOM
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_AUTO_REFRESH_CUSTOM),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_VALUE
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_DYNAMIC_TITLE_VALUE),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_GROUP_MULTIPLE_VALUES
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
                [PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_ALIGNTO]: {
                    id: this.getPropertyId(
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_ALIGNTO,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.Value Label Position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_ALIGNTO
                    ], PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_VALUE_LABEL_ALIGNTO),
                    property: PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_ALIGNTO,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: labelValuesAlignTo,
                    flexClass: false,
                },
            }
        },
    },
};
