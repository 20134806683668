<template>
  <div class="vis-tools-box vis-left-border vis-page vis-bg-color-white">
    <div class="propertiesTopBar vis-display-flex">
      <span class="vis-font-bold">{{ $t("generalPages.properties") }}</span
      ><i
        aria-hidden="true"
        :class="customIcon.Close"
        class="vis-cursor-pointer vis-ml--auto e-icons e-large"
        @click="$emit('close')"
      ></i>
    </div>
    <div class="vis-mb--1 accessContainer">
      <label>{{ $t("generalPages.searchUsersOrGroups") }}</label
      ><el-autocomplete
        class="vis-mb--1"
        style="width: 100%"
        v-model="state"
        :fetch-suggestions="querySearchAsync"
        :placeholder="$t('generalPages.searchInput')"
        @select="handleSelect"
      >
        <i
          class="el-icon-plus el-input__icon addAction"
          slot="suffix"
          @click="handleSaveConnectionAuthorization"
          aria-hidden="true"
        >
        </i>
        <template slot-scope="{ item }">
          <div class="value">
            <i
              class="vis-mr--1"
              :class="
                item.type === userTypes.USER
                  ? 'el-icon-user'
                  : 'el-icon-office-building'
              "
              aria-hidden="true"
            >
            </i
            ><span class="link">{{ item.value }}</span>
          </div>
        </template>
      </el-autocomplete>

      <vue-good-table
        max-height="400px"
        :columns="connectionAccessPermissionsColumns"
        :rows="connectionAccessPermissionsRows"
        :fixed-header="true"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <span>
            {{ $t(`${props.column.label}`) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <div
            v-if="props.column.field === accessPermissionsColumnsFields.Actions"
          >
            <div>
              <span
                ><i
                  @click="callRemoveConnectionAuthorizationById(props.row.id)"
                  class="el-icon el-icon-remove-outline vis-cursor-pointer"
                  aria-hidden="true"
              /></span>
            </div>
          </div>
          <div
            v-else-if="
              props.column.field === accessPermissionsColumnsFields.Authority
            "
          >
            <span
              v-if="props.row.authority === AuthorizationConnectionEnum.OWNER"
              style="float: left !important"
              >{{ props.row.authority }}</span
            >
            <el-select
              v-else
              :value="props.row.authority"
              placeholder="Permission"
              size="mini"
              @change="(val) => controlAuthorityChanged(val, props.row)"
            >
              <el-option
                v-for="item in optionsAuthority"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span></template
        >
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";

import { ACTION } from "../../store/modules/Visualize/Authorization/types";

import {
  ACTION as ACTION_CONNECTIONS,
  GETTER as GETTER_CONNECTIONS,
} from "../../store/modules/Visualize/Connections/types";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  props: {
    connection: {
      type: Object,
      required: true,
    },
  },
  components: { VueGoodTable },
  data() {
    return {
      authorizationPropertiesCollapseTypes: {
        AccessPermissions: "AccessPermissions",
      },
      state: "",
      timeout: null,
      activeCollapses: [],
      accessPermissionsColumnsFields: {
        Name: "name",
        Authority: "authority",
        Actions: "actions",
      },
      userTypes: {
        USER: "USER",
        GROUP: "GROUP",
      },
      usersAndGroups: [],
      selectedItem: null,
      generalForm: {
        name: "",
        description: "",
      },
      AuthorizationConnectionEnum: {
        READ: "READ",
        WRITE: "WRITE",
        OWNER: "OWNER",
        DENY: "DENY",
      },
      customIcon: CustomIcon
    };
  },
  async mounted() {
    await this.findByConnectionId(this.connection.connectionId);
  },
  computed: {
    ...mapGetters({
      connectionAuthorization: GETTER_CONNECTIONS.GET_CONNECTION_AUTHORIZATION,
    }),
    connectionAccessPermissionsRows() {
      return this.connectionAuthorization;
    },
    optionsAuthority() {
      return Object.keys(this.AuthorizationConnectionEnum).map((key) => ({
        key,
        label: key,
      }));
    },
    connectionAccessPermissionsColumns() {
      return [
        {
          label: "generalPages.user",
          field: this.accessPermissionsColumnsFields.Name,
          width: "45px",
        },
        {
          label: "generalPages.permission",
          field: this.accessPermissionsColumnsFields.Authority,
        },
        {
          label: "",
          field: this.accessPermissionsColumnsFields.Actions,
          width: "45px",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: ACTION.FETCH_USERS,
      fetchGroups: ACTION.FETCH_GROUPS,
      findByConnectionId: ACTION_CONNECTIONS.FIND_BY_CONNECTION_ID,
      saveConnectionAuthorization:
        ACTION_CONNECTIONS.SAVE_CONNECTION_AUTHORIZATION,
      updateConnectionAuthorization:
        ACTION_CONNECTIONS.UPDATE_CONNECTION_AUTHORIZATION,
      deleteConnectionAuthorization:
        ACTION_CONNECTIONS.DELETE_CONNECTION_AUTHORIZATION,
    }),

    handleSelect(item) {
      this.selectedItem = item;
    },
    createFilter(queryString) {
      return (usersAndGroups) => {
        return (
          usersAndGroups.value
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    async handleSaveConnectionAuthorization() {
      await this.saveConnectionAuthorization({
        bodyParam: {
          name: this.selectedItem.value,
          type: this.selectedItem.type,
          authority: "READ",
          connectionId: this.connection.connectionId,
        },
      });

      await this.findByConnectionId(this.connection.connectionId);
    },
    controlAuthorityChanged(val, row) {
      if (val !== this.AuthorizationConnectionEnum.OWNER) {
        this.onAuthorityChanged(val, row);

        return;
      }

      this.$confirm("Do you want to give 'OWNER' authority?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.onAuthorityChanged(val, row);
        })
        .catch(() => {});
    },
    async onAuthorityChanged(val, row) {
      await this.updateConnectionAuthorization({
        connectionId: row.id,
        bodyParam: {
          authority: val,
        },
      });

      await this.findByConnectionId(this.connection.connectionId);
    },
    async callRemoveConnectionAuthorizationById(id) {
      await this.deleteConnectionAuthorization(id);
      await this.findByConnectionId(this.connection.connectionId);
    },
    async loadUsersAndGroups(queryString) {
      const users = await this.fetchUsers(queryString ?? "");
      const groups = await this.fetchGroups();

      const userMapped =
        users?.map((u) => {
          return {
            value: u.username,
            id: u.id,
            type: this.userTypes.USER,
          };
        }) ?? [];
      const groupsMapped =
        groups?.map((g) => {
          return {
            value: g.name,
            id: g.name,
            type: this.userTypes.GROUP,
          };
        }) ?? [];
      return [...userMapped, ...groupsMapped];
    },

    async querySearchAsync(queryString, cb) {
      clearTimeout(this.timeout);
      if (queryString != "") {
        this.usersAndGroups = await this.loadUsersAndGroups(queryString);
        var usersAndGroups = this.usersAndGroups;
        var results = queryString
          ? usersAndGroups.filter(this.createFilter(queryString))
          : usersAndGroups;
        this.timeout = setTimeout(() => {
          cb(results);
        }, 2000 * Math.random());
      } else cb([]);
    },
  },
};
</script>

<style scoped>
label {
  font-weight: 500;
}
::v-deep .vgt-table.bordered th {
  padding: 5px 0px 5px 10px;
}
::v-deep .vgt-table.bordered td {
  padding: 5px 0px 5px 10px;
  text-align: center;
  vertical-align: middle;
}
::v-deep .vgt-table.bordered td:first-child {
  text-align: left;
}
::v-deep .vgt-table.bordered td:last-child {
  padding-right: 10px;
}
::v-deep .vgt-table thead tr {
  background: #f4f7fd;
}
::v-deep .vgt-wrap {
  border-left: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
}
.propertiesTopBar {
  height: 40px;
  border-top: 1px solid var(--layout-border-color);
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}
.accessContainer {
  padding-left: 24px;
  padding-right: 24px;
}
</style>
