<template>
  <div>
    <div class="data-model-sql-editor-header">
      <span>SQL</span>
      <div class="data-model-sql-editor-header-action-buttons">
        <i
          :class="CustomIcon.Play"
          aria-hidden="true"
          @click="$emit('runSql', sql)"
        />
        <el-tooltip
          :disabled="hasDatasetName"
          :content="$t('datamodel.datasetNameEmpty')"
          effect="light"
        >
          <i
            v-if="sqlSaveAction"
            :class="[
              CustomIcon.SaveOutlined,
              { 'vis-disable-opacity': !hasDatasetName },
            ]"
            aria-hidden="true"
            @click="$emit('saveSql', sql)"
          />
        </el-tooltip>

        <i
          :class="CustomIcon.Close"
          aria-hidden="true"
          @click="$emit('close')"
        />
      </div>
    </div>
    <CodeMirrorSql
      class="data-model-sql-editor"
      :sql.sync="sql"
      :hints="hints"
      :textToBeAddedToSql="textToBeAddedToSql"
      @resetTextToBeAddedToSql="$emit('resetTextToBeAddedToSql')"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../api/ApiConstants";
import { ServiceContainer } from "../../../api/ServiceContainer";
import { MUTATION as MUTATION_TEST_QUERY } from "../../../store/modules/Visualize/TestQuery/types";
import CodeMirrorSql from "../../helper/CodeMirrorSql";
import _ from "lodash";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  components: { CodeMirrorSql },
  props: {
    textToBeAddedToSql: {
      type: String,
      default: "",
    },
    connection: {
      type: Object,
      default: null,
    },
    datasetName: {
      type: String,
      default: "",
    },
    sqlSaveAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sql: "",
      properties: [],
      CustomIcon: CustomIcon,
    };
  },
  mounted() {
    this.resetTestQuery();
    this.fetchProperties();
  },
  computed: {
    hints() {
      let tableNameStr = "TABLE_NAME";
      let columnNameStr = "COLUMN_NAME";

      return _.mapValues(_.groupBy(this.properties, tableNameStr), (list) =>
        list.map((item) => item[columnNameStr])
      );
    },
    hasDatasetName() {
      return this.datasetName != "";
    },
  },
  methods: {
    ...mapMutations({
      resetTestQuery: MUTATION_TEST_QUERY.RESET_TEST_QUERY,
    }),
    async fetchProperties() {
      const { connectionId } = this.connection;
      const propertyQueryResult = await ServiceContainer.getInstance()[
        serviceMethodParent.Connection
      ][serviceMethodSub.readPropertyQuery](connectionId);

      this.properties = propertyQueryResult?.data ?? [];
    },
  },
};
</script>

<style scoped>
.data-model-sql-editor-header {
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
  font-size: 0.875rem;
}

.data-model-sql-editor-header-action-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.data-model-sql-editor-header-action-buttons i {
  cursor: pointer;
}
.data-model-sql-editor-header-action-buttons i:not(:last-child) {
  margin-right: 1rem;
}
.data-model-sql-editor {
  border: 1px solid #e4e3e8;
  border-right: none;
  border-left: none;
}
</style>
