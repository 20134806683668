import { BOX_KEY_ENUM, chartTypes, dashboardFilterTypes } from "../../commons/dashboardAndPanel";
import { LABEL_CHART_PROPERTIES_ENUM } from "../../commons/dashboardProperties";
import { singleFilterOperator } from "../../commons/filterComponents";

const dashboardFilterServices =
{
    [chartTypes.HEATMAP_CHART]: { serviceFn: (filterObj, params) => addFilterValueByHeatMap(filterObj, params) },
    [chartTypes.TREEMAP_CHART]: { serviceFn: (filterObj, selectedPanelFilterValue) => addFilterValueByTreeMap(filterObj, selectedPanelFilterValue) },
}
export const getMappedAndFilteredByBoxKeyAggregationList = (aggregationList) => {
    aggregationList = aggregationList.map((agg) => {
    const { alias, boxKey, datasetId,  field, fieldId, fieldType, fieldUsageType, type, slotFieldId } = agg;
    return {
        alias,
        boxKey,
        datasetId,
        field,
        fieldId,
        fieldType,
        fieldUsageType,
        type,
        slotFieldId,
      };
    });
    
    return aggregationList?.length === 1
      ? aggregationList
      : aggregationList.filter((x) => x.boxKey != BOX_KEY_ENUM.COLORBY.KEY);
  };
export const createFilterObj = (fieldItem, params, value) => {
    return {
        fieldId: fieldItem.fieldId || fieldItem.id,
        field: fieldItem.field,
        alias: fieldItem.alias,
        fieldType: fieldItem.fieldType,
        fieldUsageType: fieldItem.fieldUsageType,
        filterId: params.panelI,
        datasetId: fieldItem.datasetId,
        operator: params.operator,
        value: value || [],
        isDrillDown: params.isDrillDown,
        isActiveDashboardFilter: params.isActiveDashboardFilter ?? true,
        isActiveFilterIcon: true,
        isActiveDefaultValue: params.isActiveDefaultValue ?? false,
        isServerSideTableFilter: params.isServerSideTableFilter ?? false,
        filterType: fieldItem?.filterType,
        isAuthorize: fieldItem?.isAuthorize ?? true
    };
}

export const getFilterObj = (fieldItem, params, chartType, selectedPanelFilterValue) => {

    let filterObj = createFilterObj(fieldItem, params)
    const selectedFieldItemFieldValue = selectedPanelFilterValue?.[fieldItem.field]?.value;

    if (selectedPanelFilterValue) {
        if (!selectedFieldItemFieldValue && params.isChart) {
            delete selectedPanelFilterValue[fieldItem.field]
        }
    }

    if (isOperatorSingle(params.operator)) {
        if (dashboardFilterServices[chartType]) {
            dashboardFilterServices[chartType].serviceFn(filterObj, selectedPanelFilterValue)
        }
        else addFilterValueGeneral(filterObj, selectedPanelFilterValue)
    } else {
        //in operatorunde gelen değer [] içerisinde olmalıdır
        if (typeof selectedFieldItemFieldValue == "string")
            filterObj.value = [selectedFieldItemFieldValue];
        else filterObj.value = selectedFieldItemFieldValue;
    }

    return filterObj;
}

const addFilterValueByHeatMap = (filterObj, params) => {
    addFilterValueGeneral(filterObj, params);
}

const addFilterValueGeneral = (filterObj, selectedPanelFilterValue) => {
    const value = selectedPanelFilterValue?.[filterObj.field]?.value;

    if (!value) return;

    filterObj.value.push(value);
}

const addFilterValueByTreeMap = (filterObj, selectedPanelFilterValue) => {
    const value = selectedPanelFilterValue?.[filterObj.field]?.value;

    if (!value) return;

    filterObj.value.push(value)
}

const isOperatorSingle = (operator) => {
    return Object.values(singleFilterOperator).find(x => x == operator);
}

export const getAggregationOrMetricListByChartType = (params, selectedPanel) => {

    if (params.isChart) {
        return getMappedAndFilteredByBoxKeyAggregationList(selectedPanel?.details?.aggregation);
    } else if (params.hasCustomFilter) {
        return selectedPanel?.details?.customFilters;
    } else {
        return selectedPanel?.details?.metrics;
    }

}

export const getIncludeFilterChartList = (clonedPanelList, chartI) => {
    //İnclude seçeneğinde sadece seçilen chart'a filtre ekletmek için.
    return clonedPanelList.filter(
        (x) => x.i == chartI
    );
}

export const chartListWhereNotUsedDatasetAndRequiredFilter = (chartList) => {
    return chartList.filter(c => (c.type == chartTypes.LABEL && c?.properties?.style?.[LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE])
        || c.type == dashboardFilterTypes.ADVANCED_BUTTON)
}
export const chartListSelectedChartIsNotIn = (chartList, chartId) => {
    return chartList.filter(x => x.i !== chartId)
}

export const chartListWithoutFilters = (chartList, chartId) => {
    return chartListSelectedChartIsNotIn(chartList, chartId)
}