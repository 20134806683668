import { AREA_CHART_PROPERTIES_ENUM, AXIS_TYPES_ENUM, Orientation, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import { calculateGridDistances, isAxisLocationDefault, isAxisNameActive, isNameLocationDefault, truncateText, formatValueAxisLabels, getLabelPositionByRotate, getSelectedColorPaletteByProperty, getChartLabelFormatByAggregation, generateHTMLForTooltipWithSinglePoint, checkLengthColorbySlot, setShowOnlyValue, checkLengthMeasuresSlot, aggregationDateFormatController} from "../chartOptionsHelper";
import { getSeriesLabelPaddingByChartHeight, getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";
export const getAreaChartOptions = (areaChartComputedValues, paramsFromSearch, yAxisName, xAxisName, panelData) => {
    const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
        yAxisPosition: areaChartComputedValues.AREA_CHART_Y_AXIS_POSITION,
        showYAxisLabels: areaChartComputedValues.AREA_CHART_SHOW_Y_AXIS_LABEL,
        showXAxisLabels: areaChartComputedValues.AREA_CHART_SHOW_X_AXIS_LABEL,
        showLegend: areaChartComputedValues.AREA_CHART_SHOW_LEGEND,
        legendPosition: areaChartComputedValues.AREA_CHART_LEGEND_POSITION,
        legendMaxCharacter: areaChartComputedValues.AREA_CHART_LEGEND_MAX_CHARACTER,
        yAxisNameLocation: areaChartComputedValues.AREA_CHART_Y_AXIS_NAME_LOCATION,
        labelRotate: areaChartComputedValues.AREA_CHART_VALUE_LABEL_ROTATE,
        showLabel: areaChartComputedValues.AREA_CHART_SHOW_VALUE_LABEL,
        showOnly: areaChartComputedValues.AREA_CHART_SHOW_ONLY,
        panelData
    });

    const optionObject = {
        grid:
        {
            top: gridTop + 20,
            bottom: gridBottom,
            left: gridLeft,
            right: gridRight,
            containLabel: true
        },
        legend: {
            show: isChartWidthLimited(panelData.w) ? areaChartComputedValues.AREA_CHART_SHOW_LEGEND : false,
            orient: getOrientPositionByLegend(areaChartComputedValues.AREA_CHART_LEGEND_POSITION).toLowerCase(),
            left: getAlignPositionByLegend(areaChartComputedValues.AREA_CHART_LEGEND_POSITION),
            top: getOrientPositionByLegend(areaChartComputedValues.AREA_CHART_LEGEND_POSITION) === Orientation.VERTICAL ? "4%" : areaChartComputedValues.AREA_CHART_LEGEND_VERTICAL_ALIGN,
            textStyle: {
                fontWeight: areaChartComputedValues.AREA_CHART_LEGEND_FONT_WEIGHT,
                fontFamily: areaChartComputedValues.AREA_CHART_LEGEND_FONT_FAMILY,
                fontSize: areaChartComputedValues.AREA_CHART_LEGEND_FONT_SIZE,
                color: areaChartComputedValues.AREA_CHART_LEGEND_FONT_COLOR,
            },
            backgroundColor: 'transparent',
            formatter: function (value) {
                return truncateText(value, areaChartComputedValues.AREA_CHART_LEGEND_MAX_CHARACTER);
            },
            type: 'scroll',
        },
        tooltip: {
            show: true,
            trigger: checkLengthColorbySlot(panelData) || checkLengthMeasuresSlot(panelData)  ? 'item' : 'axis',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: {
                fontFamily: 'Poppins',
                fontSize: 12
            },
            formatter: function (values) {
                const value = checkLengthColorbySlot(panelData) || checkLengthMeasuresSlot(panelData) ? values : values[0];

                value.name =  aggregationDateFormatController(value?.name, paramsFromSearch);

                return showOnlyTypesExceptOnlyItem.includes(
                  areaChartComputedValues.AREA_CHART_SHOW_ONLY
                )
                  ? setShowOnlyValue(
                      value,
                      paramsFromSearch,
                      areaChartComputedValues,
                      AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_ONLY,
                      dashboardSPEnums.TOOLTIP,
                      panelData
                    )
                  : generateHTMLForTooltipWithSinglePoint(
                      value,
                      paramsFromSearch?.metricFormats,
                      panelData
                    );
            },
        },
        xAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            axisLabel: {
                show: isChartHeightLimited(panelData.h) ? areaChartComputedValues.AREA_CHART_SHOW_X_AXIS_LABEL : false,
                fontWeight: areaChartComputedValues.AREA_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: areaChartComputedValues.AREA_CHART_X_AXIS_LABEL_FONT_FAMILY,
                fontSize: areaChartComputedValues.AREA_CHART_X_AXIS_LABEL_FONT_SIZE,
                color: areaChartComputedValues.AREA_CHART_X_AXIS_LABEL_FONT_COLOR,
                rotate: areaChartComputedValues.AREA_CHART_X_AXIS_LABEL_ROTATE,
                formatter: function (value) {
                    const formattedValue = getChartLabelFormatByAggregation(value, paramsFromSearch?.aggregationFormats);
                    return truncateText(formattedValue, areaChartComputedValues.AREA_CHART_X_AXIS_LABEL_MAX_CHARACTER)
                },
                hideOverlap: true,
                margin: 10
            },
            type: 'category',
            axisTick: {
                show: false
            },
            data: paramsFromSearch?.dataAxis ?? [],
            axisLine: {
                show: areaChartComputedValues.AREA_CHART_SHOW_X_AXIS_LINE,
            }
        },
        yAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartWidthLimited(panelData.w) ? areaChartComputedValues.AREA_CHART_SHOW_Y_AXIS_LABEL : false,
                fontWeight: areaChartComputedValues.AREA_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: areaChartComputedValues.AREA_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                fontSize: areaChartComputedValues.AREA_CHART_Y_AXIS_LABEL_FONT_SIZE,
                color: areaChartComputedValues.AREA_CHART_Y_AXIS_LABEL_FONT_COLOR,
                formatter: function (value) {
                    return formatValueAxisLabels(paramsFromSearch?.metricFormats, value)
                },
                hideOverlap: true,
                margin: 10
            },
            type: areaChartComputedValues.AREA_CHART_Y_AXIS_LOGARITHMIC_SCALE ? AXIS_TYPES_ENUM.LOG : AXIS_TYPES_ENUM.VALUE,
            min: areaChartComputedValues.AREA_CHART_Y_AXIS_MIN,
            position: areaChartComputedValues.AREA_CHART_Y_AXIS_POSITION,
            axisLine: {
                show: areaChartComputedValues.AREA_CHART_Y_AXIS_SHOW_LINE,
                lineStyle: {
                    color: areaChartComputedValues.AREA_CHART_Y_AXIS_LINE_STYLE_COLOR
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                lineStyle: {
                  color: areaChartComputedValues.AREA_CHART_X_AXIS_GRID_LINE_COLOR,
                }
            },
        },
        series: paramsFromSearch?.dataSeries ? paramsFromSearch?.dataSeries?.map((s, i) => {
            return {
                data: s.map((a, index) => {
                    return {
                        value: a,
                        itemStyle: getCommonChartItemStyle(paramsFromSearch?.dataAxis[index], panelData)
                    }
                }) ?? [],
                type: "line",
                stack: 'Total',
                name: paramsFromSearch?.dataSeriesNames?.[i],
                emphasis: {
                    focus: 'series'
                },
                label: {
                    position: getLabelPositionByRotate(areaChartComputedValues.AREA_CHART_VALUE_LABEL_ROTATE),
                    padding: getSeriesLabelPaddingByChartHeight(panelData.h),
                    show: isChartWidthLimited(panelData.w) ? areaChartComputedValues.AREA_CHART_SHOW_VALUE_LABEL : false,
                    fontSize: areaChartComputedValues.AREA_CHART_VALUE_LABEL_FONT_SIZE,
                    fontFamily: areaChartComputedValues.AREA_CHART_VALUE_LABEL_FONT_FAMILY,
                    fontWeight: areaChartComputedValues.AREA_CHART_VALUE_LABEL_FONT_WEIGHT,
                    color: areaChartComputedValues.AREA_CHART_VALUE_LABEL_FONT_COLOR,
                    rotate: areaChartComputedValues.AREA_CHART_VALUE_LABEL_ROTATE,
                    formatter: function (value) {
                        value.name =  aggregationDateFormatController(value?.name, paramsFromSearch);

                        return showOnlyTypesExceptOnlyItem.includes(areaChartComputedValues.AREA_CHART_SHOW_ONLY) ? 
                        setShowOnlyValue(
                            value,
                            paramsFromSearch,
                            areaChartComputedValues,
                            AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_ONLY,
                            dashboardSPEnums.LABEL,
                            panelData
                        )
                        :
                        value?.name
                    },
                },
                labelLayout: {
                    hideOverlap: areaChartComputedValues.AREA_CHART_HIDE_OVERLAPPED_VALUE_LABELS
                },
                areaStyle: { opacity: areaChartComputedValues.AREA_CHART_AREA_OPACITY }
            }
        }) : null,
        color: getSelectedColorPaletteByProperty(areaChartComputedValues.AREA_CHART_SELECTED_COLOR_PALETTE)
    }

    return {
        options: optionObject,
        yAxis: {
            name: isAxisNameActive(paramsFromSearch, yAxisName),
            show: isChartWidthLimited(panelData.w) ? areaChartComputedValues.AREA_CHART_SHOW_Y_AXIS_NAME : false,
            fontFamily: areaChartComputedValues.AREA_CHART_Y_AXIS_NAME_FONT_FAMILY,
            fontSize: areaChartComputedValues.AREA_CHART_Y_AXIS_NAME_FONT_SIZE,
            fontWeight: areaChartComputedValues.AREA_CHART_Y_AXIS_NAME_FONT_WEIGHT,
            fontColor: areaChartComputedValues.AREA_CHART_Y_AXIS_NAME_FONT_COLOR,
            isAxisLocationDefault: isAxisLocationDefault(areaChartComputedValues.AREA_CHART_Y_AXIS_POSITION),
            isNameLocationDefault: isNameLocationDefault(areaChartComputedValues.AREA_CHART_Y_AXIS_NAME_LOCATION)
        },
        xAxis: {
            name: isAxisNameActive(paramsFromSearch, xAxisName),
            show: isChartHeightLimited(panelData.h) ? areaChartComputedValues.AREA_CHART_SHOW_X_AXIS_NAME : false,
            fontFamily: areaChartComputedValues.AREA_CHART_X_AXIS_NAME_FONT_FAMILY,
            fontSize: areaChartComputedValues.AREA_CHART_X_AXIS_NAME_FONT_SIZE,
            fontWeight: areaChartComputedValues.AREA_CHART_X_AXIS_NAME_FONT_WEIGHT,
            fontColor: areaChartComputedValues.AREA_CHART_X_AXIS_NAME_FONT_COLOR,
        }
    }
}