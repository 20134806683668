export const domain = "VISUALIZE_COLOR_PALETTE";

export const GETTER = {
  GET_COLOR_PALETTES: `${domain}/GET_COLOR_PALETTES`,
};

export const ACTION = {
  FETCH_COLOR_PALETTES: `${domain}/FETCH_COLOR_PALETTES`,
  DELETE_COLOR_PALETTES: `${domain}/DELETE_COLOR_PALETTES`,
  CREATE_COLOR_PALETTES: `${domain}/CREATE_COLOR_PALETTES`,
  UPDATE_COLOR_PALETTES: `${domain}/UPDATE_COLOR_PALETTES`,
};

export const MUTATION = {
  SET_COLOR_PALETTES: `${domain}/SET_COLOR_PALETTES`,
};


