<template>
  <ContextPopup :customClass="'vis-orderByPopup'">
    <template v-slot:title>
      <div class="vis-ellipsisLabel">
        <span
          >{{ $t("generalPages.The Top") }} /
          {{ $t("generalPages.The Bottom") }} -
        </span>
        <span class="vis-color-primary">{{ selectedField.alias }}</span>
      </div>

      <div>
        <i
          @click="resetRankData()"
          :class="customIcon.ResetRefresh"
          class="vis-cursor-pointer vis-mr--1"
          aria-hidden="true"
        ></i>
        <i
          @click="closeTopBottomPopup"
          :class="customIcon.Close"
          class="vis-cursor-pointer"
          aria-hidden="true"
        ></i>
      </div>
    </template>
    <template v-slot:content>
      <div class="vis-row">
        <div class="vis-col vis-col-8 vis-mb--2">
          <label>{{ $t("generalPages.Choose Calculation") }}</label>
          <el-select
            v-model="rank.calculationId"
            filterable
            placeholder="Select"
          >
            <el-option
              v-for="item in allMeasureFieldsMapped"
              :key="item.fieldId"
              :label="item.alias"
              :value="item.fieldId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="vis-row">
        <div class="vis-col vis-col-8 vis-mb--2">
          <el-radio-group v-model="rank.rank_type">
            <el-radio label="TOP">{{ $t("generalPages.top") }}</el-radio>
            <el-radio label="BOTTOM">{{ $t("generalPages.bottom") }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="vis-row">
        <div class="vis-col vis-col-8 vis-mb--2">
          <label>{{ $t("generalPages.Quantity to Display") }}</label>
          <!-- <input type="number" v-model="rank.count" /> -->
          <el-input-number
            :value="rank.count"
            @change="
              (e) => {
                rank.count = e;
              }
            "
            controls-position="right"
            :min="0"
          ></el-input-number>
        </div>
      </div>
      <div class="vis-col vis-text--right">
        <el-button size="small" @click="closeTopBottomPopup" plain>
          {{ $t("generalPages.cancel") }}</el-button
        >
        <el-tooltip
          :disabled="!isActiveSaveButton"
          :content="$t('generalPages.Fill In All Fields')"
          placement="top"
          effect="light"
        >
          <el-button
            size="small"
            type="primary"
            :disabled="isActiveSaveButton"
            @click="saveTopBottom"
          >
            {{ $t("generalPages.apply") }}</el-button
          >
        </el-tooltip>
      </div>
    </template>
  </ContextPopup>
</template>
<script>
import cloneDeep from "clone-deep";
import ContextPopup from "../ContextPopup.vue";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  components: {
    ContextPopup,
  },
  props: {
    allMeasureFieldsMapped: {
      type: Array,
    },
    selectedField: {
      type: Object,
    },
  },
  data() {
    return {
      rank: {},
      defaultRankData: {
        calculationId: null,
        rank_type: null,
        count: 0,
      },
      loading: false,
      customIcon: CustomIcon,
    };
  },
  mounted() {
    if (this.selectedField?.rank) {
      this.rank = cloneDeep(this.selectedField?.rank);
    } else {
      this.rank = cloneDeep(this.defaultRankData);
      this.rank.count = 1;
    }
  },
  computed: {
    isActiveSaveButton() {
      return this.rankValuesLength >= 1 && !this.isRankValueFull;
    },
    rankValuesLength() {
      return Object.values(this.rank).filter((x) => x).length;
    },
    isRankValueFull() {
      return Object.keys(this.rank).length === this.rankValuesLength;
    },
  },
  methods: {
    resetRankData() {
      this.rank = cloneDeep(this.defaultRankData);
    },
    saveTopBottom() {
      this.save();
      this.closeTopBottomPopup();
    },
    save() {
      this.$emit("saveTopBottom", {
        rank: this.isRankValueFull ? this.rank : null,
        fieldId: this.selectedField.fieldId,
        slotFieldId: this.selectedField.slotFieldId,
      });
    },
    closeTopBottomPopup() {
      this.$emit("closeTopBottomPopup", null);
    },
  },
};
</script>
<style scoped>
.vis-panel-dropdown.vis-orderByPopup {
  width: 400px;
}
.vis-orderByPopup-icon i {
  font-size: 1.6rem;
  cursor: pointer;
}
.vis-ellipsisLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
