import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import DatamodelCreate from "../views/DatamodelCreate.vue";
import Scenario from "../views/Scenario.vue";
import DatamodelView from "../views/DatamodelView.vue";
import Dashboard from "../views/Dashboard.vue";
import Panel from "../views/Panel.vue";
import Dev from "../views/Dev.vue";
import Administration from "../views/Administration.vue";
import Ingest from "../views/Ingest.vue";
import Event from "../views/Event.vue";
import Enrichment from "../views/Enrichment.vue";

import { routerEnums } from "../commons/Enum";
import { PAGE_PRIVILEGES } from "../util/authorization";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: routerEnums.HOME,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.HOME_PAGE,
    },
    component: Home,
  },
  {
    path: "/dev",
    name: routerEnums.HOME,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.HOME_PAGE,
    },
    component: Dev,
  },
  {
    path: "/datamodel",
    name: routerEnums.DATAMODEL,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.DATAMODEL_PAGE,
    },
    component: DatamodelCreate,
  },
  {
    path: "/data-flow/edit/:id",
    name: routerEnums.INGEST_EDIT,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.DATAMODEL_PAGE,
    },
    component: Ingest,
  },
  {
    path: "/data-flow/create",
    name: routerEnums.INGEST_CREATE,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.DATAMODEL_PAGE,
    },
    component: Ingest,
  },
  // scenario begins
  {
    path: "/scenario",
    name: routerEnums.SCENARIO_CREATE,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.HOME_PAGE,
    },
    component: Scenario,
  },
  {
    path: "/scenario/view/:id",
    name: routerEnums.SCENARIO_EDIT,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.HOME_PAGE,
    },
    component: Scenario,
  },
  {
    path: "/event/create",
    name: routerEnums.EVENT_CREATE,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.HOME_PAGE,
    },
    component: Event,
  },
  {
    path: "/event/view/:id",
    name: routerEnums.EVENT_EDIT,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.HOME_PAGE,
    },
    component: Event,
  },
  {
    path: "/enrichment/create",
    name: routerEnums.ENRICHMENT_CREATE,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.HOME_PAGE,
    },
    component: Enrichment,
  },
  {
    path: "/enrichment/view/:id",
    name: routerEnums.ENRICHMENT_EDIT,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.HOME_PAGE,
    },
    component: Enrichment,
  },
  // scenario ends
  {
    path: "/datamodel/view/:id",
    name: routerEnums.DATAMODEL_VIEW,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.DATAMODEL_VIEW_PAGE,
    },
    component: DatamodelView,
  },
  {
    path: "/create-dashboard",
    name: routerEnums.DASHBOARD,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.DASHBOARD_CREATE_PAGE,
    },
    component: Dashboard,
  },
  {
    path: "/dashboard/edit/:id",
    name: routerEnums.DASHBOARD_EDIT,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.DASHBOARD_EDIT_PAGE,
      isNotSaveConfirmation: true,
    },
    component: Dashboard,
  },
  {
    path: "/dashboard/view/:id",
    name: routerEnums.DASHBOARD_VIEW,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.DASHBOARD_VIEW_PAGE,
      isNotSaveConfirmation: true,
    },
    component: Dashboard,
  },

  //#region will only use backend
  {
    path: "/dashboard/view/:id/tab/:tabId",
    name: routerEnums.DASHBOARD_VIEW_TABID,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.DASHBOARD_VIEW_PAGE,
      isNotSaveConfirmation: true,
    },
    component: Dashboard,
  },
  //#endregion
  {
    path: "/panel",
    name: routerEnums.PANEL,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PANEL_PAGE,
      isNotSaveConfirmation: true,
    },
    component: Panel,
  },
  {
    path: "/administration",
    name: routerEnums.ADMINISTRATION,
    meta: {
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.ADMINISTRATION_PAGE,
    },
    component: Administration,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path === "/login") {
    next("/");
    return;
  }
  
  if (to.path === "/401" || to.path === "/404") {
    next();
    return;
  }

  if (to.matched.length === 0) {
    return next("/404");
  }

  const store = await import("@/store"); //await the store
  const isPublic = to.matched.some((record) => record.meta.public);
  const pagePrivilege = to.meta.pagePrivilege;
  const currentUserPagePrivileges = await store.default.getters[
    "Security/Get/Keycloak/PagePrivileges"
  ];
  const hasAccessToCurrentPage =
    pagePrivilege && currentUserPagePrivileges.includes(pagePrivilege);

  if (!isPublic) {
    if (hasAccessToCurrentPage) {
      next();
    } else {
      return next("/401");
    }
  } else {
    next();
  }
});

export default router;
