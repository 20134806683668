const domain = "INGEST_DATAFLOW";

export const STATE = {
  DATAFLOWS: `${domain}/DATAFLOWS`,
  SELECTED_DATAFLOW_DETAILS: `${domain}/SELECTED_DATAFLOW_DETAILS`,
  JOBS: `${domain}/JOBS`,
  RDB_PREVIEW: `${domain}/RDB_PREVIEW`,
};

export const GETTER = {
  GET_DATAFLOWS: `${domain}/GET_DATAFLOWS`,
  GET_SELECTED_DATAFLOW_DETAILS: `${domain}/GET_SELECTED_DATAFLOW_DETAILS`,
  GET_JOBS: `${domain}/GET_JOBS`,
  GET_RDB_PREVIEW: `${domain}/GET_RDB_PREVIEW`,
};

export const ACTION = {
  FETCH_DATAFLOWS: `${domain}/FETCH_DATAFLOWS`,
  FETCH_DATAFLOW_DETAILS_BY_ID: `${domain}/FETCH_DATAFLOW_DETAILS_BY_ID`,
  DELETE_DATAFLOW_BY_ID: `${domain}/DELETE_DATAFLOW_BY_ID`,
  FETCH_JOBS: `${domain}/FETCH_JOBS`,
  UPDATE_DATAFLOW_NAME_AND_DESCRIPTION: `${domain}/UPDATE_DATAFLOW_NAME_AND_DESCRIPTION`,
  POST_RDB_PREVIEW: `${domain}/POST_RDB_PREVIEW`,
  POST_RDB_SAVE: `${domain}/POST_RDB_SAVE`,
  PUT_RDB_SAVE: `${domain}/PUT_RDB_SAVE`,
  POST_RDB_RUN: `${domain}/POST_RDB_RUN`,
  READ_RDB_STATUS: `${domain}/READ_RDB_STATUS`,
  MOVE_DATAFLOW: `${domain}/MOVE_DATAFLOW`,
  FETCH_HIGHEST_PRIORITY_BY_DATAFLOW_ID: `${domain}/FETCH_HIGHEST_PRIORITY_BY_DATAFLOW_ID`,
};
export const MUTATION = {
  SET_DATAFLOWS: `${domain}/SET_DATAFLOWS`,
  SET_SELECTED_DATAFLOW_DETAILS: `${domain}/SET_SELECTED_DATAFLOW_DETAILS`,
  SET_JOBS: `${domain}/SET_JOBS`,
  SET_RDB_PREVIEW: `${domain}/SET_RDB_PREVIEW`,
};
