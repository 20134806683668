/* eslint-disable */
import { ACTION, MUTATION, GETTER } from "./types";
import Vue from "vue";
import { MUTATION_GENERAL } from "../General/types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visColorPalette = {
  state: {
    colorPalettes: [],
  },
  getters: {
    [GETTER.GET_COLOR_PALETTES]: (state) => {
      return state.colorPalettes;
    },
  },
  actions: {
    [ACTION.FETCH_COLOR_PALETTES]: async ({ commit }, {loadingComponent}) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.ColorPalettes,
        serviceMethodSub: serviceMethodSub.readColorPalettes,
        successMutation: MUTATION.SET_COLOR_PALETTES,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent
      });
    },
    [ACTION.CREATE_COLOR_PALETTES]: async ({ commit, dispatch }, bodyParam) => {
      let result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.ColorPalettes,
        serviceMethodSub: serviceMethodSub.createColorPalettes,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });

      if (result) {
        await dispatch(ACTION.FETCH_COLOR_PALETTES, { loadingComponent: null});
        return result;
      }
    },
    [ACTION.UPDATE_COLOR_PALETTES]: async ({ commit }, bodyParam) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.ColorPalettes,
        serviceMethodSub: serviceMethodSub.updateColorPalettes,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.DELETE_COLOR_PALETTES]: async ({ commit, dispatch }, paletteId) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.ColorPalettes,
        serviceMethodSub: serviceMethodSub.deleteColorPalettes,
        queryParam: paletteId,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
      await dispatch(ACTION.FETCH_COLOR_PALETTES, { loadingComponent: null});
    },
  },
  mutations: {
    [MUTATION.SET_COLOR_PALETTES](state, payload) {
      Vue.set(state, "colorPalettes", payload);
    },
  },
};
