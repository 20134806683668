<template>
  <div>
    <div class="vis-row">
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.labelSelectedDays") }}</label>
      </div>
    </div>
    <div class="vis-row">
      <div class="vis-col vis-col-12">
        <div class="vis-display-flex">
          <el-checkbox
            :indeterminate="isIndeterminateDay"
            v-model="checkAllDay"
            @change="handleCheckAllDayChange"
            class="vis-centered-label vis-checkbox-mr-30"
            >{{ $t("home.all") }}</el-checkbox
          >
          <el-checkbox-group
            v-model="scheduleCronDtoSelected.selectedDays"
            @change="handleCheckedDayChange"
          >
            <div class="vis-display-flex">
              <el-checkbox
                v-for="day in filteredDays"
                :label="day.key"
                :key="day.key"
                class="vis-centered-label"
                >{{ day.value }}</el-checkbox
              >
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="vis-row">
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.labelAtEvery") }}</label>
        <el-select v-model="selectedTimeRange.timeRange">
          <el-option
            v-for="range in timeRangeOptions"
            :key="range.key"
            :label="range.value"
            :value="range.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="vis-row">
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.labelStartAt") }}</label>
        <TimePicker 
          :value="scheduleStartEndHoursUpdated.startHour" 
          @input="value => scheduleStartEndHoursUpdated.startHour=value"
        />
      </div>
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.labelEndAt") }}</label>
        <TimePicker 
          :value="scheduleStartEndHoursUpdated.endHour" 
          :isMinuteEnabled="false"
          @input="value => scheduleStartEndHoursUpdated.endHour=value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";
import {
  scheduleAllSelect,
  scheduleCronDtoSelectDefaultValue,
  scheduleStartEndDefaultHours,
} from "./schedule.js";
import scheduleMixin from "./mixin/scheduleMixin";
import TimePicker from "../../helper/TimePicker.vue";
export default {
  mixins: [scheduleMixin],
  props: {
    daysOptions: {
      type: Array,
    },
    timeRangeOptions: {
      type: Array,
    },
    existingTimeRange: {
      type: Object,
      default: () => {
        return {};
      },
    },
    existingSelectCronDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
    scheduleStartEndHours: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {TimePicker},
  data() {
    return {
      scheduleStartEndHoursUpdated: scheduleStartEndDefaultHours,
      selectedTimeRange: this.timeRangesDefaultValue,
      scheduleCronDtoSelected: scheduleCronDtoSelectDefaultValue,
      isIndeterminateDay: true,
      checkAllDay: false,
      pickerOptions: {
        start: "03:00",
        step: "01:00",
        end: "23:00",
      },
    };
  },
  mounted() {
    this.scheduleStartEndHoursUpdated = cloneDeep(this.scheduleStartEndHours);
    this.selectedTimeRange = cloneDeep(this.existingTimeRange);
    this.scheduleCronDtoSelected = cloneDeep(this.existingSelectCronDto);
  },
  computed: {
    filteredDays() {
      return this.daysOptions.filter((x) => x.key !== scheduleAllSelect.All);
    },
  },
  watch: {
    scheduleStartEndHoursUpdated: {
      deep: true,
      handler() {
        this.$emit("onscheduleStartEndHoursUpdated", this.scheduleStartEndHoursUpdated);
      },
    },
    selectedTimeRange: {
      deep: true,
      handler() {
        this.$emit("onTimeRangeUpdated", this.selectedTimeRange);
      },
    },
    scheduleCronDtoSelected: {
      deep: true,
      handler() {
        this.$emit("onCronDtoSelectUpdated", this.scheduleCronDtoSelected);
      },
    },
  },
  methods: {
    handleCheckAllDayChange(val) {
      this.scheduleCronDtoSelected.selectedDays = val
        ? this.daysOptions.map((x) => x.key)
        : [];
      this.isIndeterminateDay = false;
    },
    handleCheckedDayChange(value) {
      let checkCount = value.length;
      this.checkAllDay = checkCount === this.daysOptions.length;
      this.isIndeterminateDay =
        checkCount > 0 && checkCount < this.daysOptions.length;
    },
  },
};
</script>
