import _ from "lodash";
import { iconList } from "../components/panel/conditionalFormatting/conditionalFormattingHelper";
export const predefineColorsArr = [
  "#ff4500",
  "#ff8c00",
  "#ffd700",
  "#90ee90",
  "#00ced1",
  "#1e90ff",
  "#c71585",
  "rgba(255, 69, 0, 0.68)",
  "rgb(255, 120, 0)",
  "hsv(51, 100, 98)",
  "hsva(120, 40, 94, 0.5)",
  "hsl(181, 100%, 37%)",
  "hsla(209, 100%, 56%, 0.73)",
  "#c7158577",
];

export const fontsArr = [
  {
    value: "'Times New Roman', Times, serif",
    label: "Times New Roman",
  },
  {
    value: "Arial, Helvetica, sans-serif",
    label: "Arial-Helvetica",
  },
  {
    value: "Poppins, sans-serif",
    label: "Poppins",
  },
  {
    value: "Calibri",
    label: "Calibri"
  },
  {
    value: "Verdana",
    label: "Verdana"
  },
];

export const rotateArr = [
  {
    value: 0,
    label: "generalPages.horizontal",
  },
  {
    value: 45,
    label: "generalPages.angled",
  },
  {
    value: 90,
    label: "generalPages.vertical",
  },
];

export const labelMaxCharacters = [
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
  { value: "all", label: "All" },
];

export const showOnlyValueFormatIncludingTypes = "{c}";

export const showOnlyTypes = {
  onlyItem: "{b}",
  itemValue: "{b} : {c} ",
  itemPercent: "{b} : ({d}%)",
  itemPercentValue: "{b} : {c} ({d}%)",
  value: "{c}",
  percent: "{d}%",
  valuePercent: "{c} ({d}%)",
};

export const showOnlyTypesExceptOnlyItem = [
  showOnlyTypes.value,
  showOnlyTypes.itemValue,
  showOnlyTypes.itemPercent,
  showOnlyTypes.valuePercent,
  showOnlyTypes.itemPercentValue,
  showOnlyTypes.percent,
];

export const breakCharEnums = {
  CHARBR: "<br>",
  CHARN: "\n",
};
export const dashboardSPEnums = {
  LABEL: "Label",
  TOOLTIP: "Tooltip",
};

export const toolTipEnums = {
  PERCENT: "panelProperties.showOnly.% to Total",
};
export const tableColumnFormatValues = {
  NORMAL: "NORMAL",
  PERCENTAGE: "PERCENTAGE",
};

export const getItemPercent = () => {
  return showOnlyArr.find((x) => x.value === showOnlyTypes.itemValue).value;
};

export const showOnlyArr = [
  {
    value: showOnlyTypes.onlyItem,
    label: "panelProperties.showOnly.onlyItem",
  },
  {
    value: showOnlyTypes.itemValue,
    label: "panelProperties.showOnly.itemValue",
  },
  {
    value: showOnlyTypes.itemPercent,
    label: "panelProperties.showOnly.itemPercent",
  },
  {
    value: showOnlyTypes.itemPercentValue,
    label: "panelProperties.showOnly.itemPercentValue",
  },
  {
    value: showOnlyTypes.value,
    label: "panelProperties.showOnly.value",
  },
  {
    value: showOnlyTypes.percent,
    label: "panelProperties.showOnly.percent",
  },
  {
    value: showOnlyTypes.valuePercent,
    label: "panelProperties.showOnly.valuePercent",
  },
];

export const pivotTableSelectionModes = [
  {
    value: "Row",
    label: "panelProperties.row",
  },
  {
    value: "Column",
    label: "panelProperties.column",
  },
  {
    value: "Cell",
    label: "panelProperties.cell",
  },
  {
    value: "Both",
    label: "panelProperties.both",
  },
];

export const pivotTableSelectionTypes = [
  {
    value: "Single",
    label: "panelProperties.single",
  },
  {
    value: "Multiple",
    label: "panelProperties.multiple",
  },
];

export const pivotTableTotalsEnums = {
  Show: "panelProperties.showLabel",
  DoNotShow: "panelProperties.dontShow",
  ShowRowOnly: "panelProperties.showRowOnly",
  ShowColumnOnly: "panelProperties.showColumnOnly",
};

export const pivotTableSubTotalsSelections = [
  {
    value: pivotTableTotalsEnums.Show,
    label: "panelProperties.showLabel",
  },
  {
    value: pivotTableTotalsEnums.DoNotShow,
    label: "panelProperties.dontShow",
  },
  {
    value: pivotTableTotalsEnums.ShowRowOnly,
    label: "panelProperties.showRowOnly",
  },
  {
    value: pivotTableTotalsEnums.ShowColumnOnly,
    label: "panelProperties.showColumnOnly",
  },
];

export const pivotTableGrandTotalsSelections = [
  {
    value: pivotTableTotalsEnums.Show,
    label: "panelProperties.showLabel",
  },
  {
    value: pivotTableTotalsEnums.DoNotShow,
    label: "panelProperties.dontShow",
  },
  {
    value: pivotTableTotalsEnums.ShowRowOnly,
    label: "panelProperties.showRowOnly",
  },
  {
    value: pivotTableTotalsEnums.ShowColumnOnly,
    label: "panelProperties.showColumnOnly",
  },
];

export const pivotTableGrandTotalPositionsEnums = {
  Top: "generalPages.top",
  Bottom: "generalPages.bottom",
};

export const pivotTableGrandTotalPositions = [
  {
    value: "Top",
    label: pivotTableGrandTotalPositionsEnums.Top,
  },
  {
    value: "Bottom",
    label: pivotTableGrandTotalPositionsEnums.Bottom,
  },
];

export const tableSubTotalPositionsEnums = {
  Above: "Above",
  Below: "Below",
  Both: "Both",
};

export const tableSubTotalPositions = [
  {
    value: tableSubTotalPositionsEnums.Above,
    label: "generalPages.above",
  },
  {
    value: tableSubTotalPositionsEnums.Below,
    label: "generalPages.below",
  },
  {
    value: tableSubTotalPositionsEnums.Both,
    label: "generalPages.both",
  },
];

export const yAxisPositionArr = [
  {
    value: "left",
    label: "generalPages.left",
  },
  {
    value: "right",
    label: "generalPages.right",
  },
];
export const autoRefreshTimeValue = {
  custom: "custom",
};

const autoRefreshValues = [
  {
    value: 5000,
    labelNotLocale: "5",
  },
  {
    value: 15000,
    labelNotLocale: "15",
  },
  {
    value: 30000,
    labelNotLocale: "30",
  },
  {
    value: 60000,
    labelNotLocale: "60",
  },
];

export const autoRefreshTimes = [
  {
    value: autoRefreshTimeValue.custom,
    label: "generalPages.custom",
  },
  {
    value: 0,
    label: "generalPages.none",
  },
  ...autoRefreshValues.map((x) => {
    return {
      value: x.value,
      labelNotLocale: x.labelNotLocale,
      label: `generalPages.secondSort`,
    };
  }),
];

export const Orientation = {
  VERTICAL: "Vertical",
  HORIZONTAL: "Horizontal",
  STACK: "Stack",
};

export const LegendDirection = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
}

export const DefaultValue = {
  NONE: "NONE",
  SELECTION: "SELECTION",
  DYNAMIC: "DYNAMIC",
};

export const selectListFilterOrientationArr = [
  {
    value: Orientation.VERTICAL,
    label: "generalPages.vertical",
  },
  {
    value: Orientation.HORIZONTAL,
    label: "generalPages.horizontal",
  },
  {
    value: Orientation.STACK,
    label: "generalPages.stack",
  },
];

export const filterDefaultValues = [
  {
    value: DefaultValue.NONE,
    label: "panelProperties.none",
  },
  {
    value: DefaultValue.SELECTION,
    label: "panelProperties.selection",
  },
  {
    value: DefaultValue.DYNAMIC,
    label: "panelProperties.dynamic",
  },
];

export const filterSelectionActions = [
  {
    value: false,
    label: "panelProperties.autoApply",
  },
  {
    value: true,
    label: "panelProperties.waitForButton",
  },
];

export const chartTablePageSize = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

export const legendGridDistanceAccordingToMaxCharacterArr = [
  {
    maxCharacter: 5,
    gridDistance: 120,
  },
  {
    maxCharacter: 10,
    gridDistance: 150,
  },
  {
    maxCharacter: 15,
    gridDistance: 160,
  },
  {
    maxCharacter: 20,
    gridDistance: 170,
  },
  {
    maxCharacter: 25,
    gridDistance: 180,
  },
  {
    maxCharacter: 100,
    gridDistance: 200,
  },
]

export const alignItems = [
  {
    value: LegendDirection.LEFT,
    label: "generalPages.left",
  },
  {
    value: LegendDirection.CENTER,
    label: "generalPages.center",
  },
  {
    value: LegendDirection.RIGHT,
    label: "generalPages.right",
  },
];
export const chartOrientationArr = [
  {
    value: Orientation.VERTICAL,
    label: "generalPages.vertical",
  },
  {
    value: Orientation.HORIZONTAL,
    label: "generalPages.horizontal",
  },
];
export const legendPositionEnums = {
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  TOP_RIGHT: "top_right",
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
}

export const legendPositionArr = [
  {
    value: legendPositionEnums.TOP_LEFT,
    label: "generalPages.Top-left",
  },
  {
    value: legendPositionEnums.TOP_CENTER,
    label: "generalPages.Top-center",
  },
  {
    value: legendPositionEnums.TOP_RIGHT,
    label: "generalPages.Top-right",
  },
  {
    value: legendPositionEnums.RIGHT,
    label: "generalPages.right",
  },
  {
    value: legendPositionEnums.LEFT,
    label: "generalPages.left",
  },
]

export const labelEnumsAlignTo = {
  INSIDE: "inside",
  OUTSIDE_ARROUND: "outside-arround",
  OUTSIDE_EDGE: "otside-edge",
}

export const labelValuesAlignTo = [
  {
    value: "inside",
    label: "panelProperties.valueLabel.Inside",
  },
  {
    value: "outside-arround",
    label: "panelProperties.valueLabel.Outside around",
  },
  {
    value: "otside-edge",
    label: "panelProperties.valueLabel.Outside aligned",
  },
];
export const legendMaxCharArr = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 100,
    label: "All",
  },
]
export const locationItems = [
  {
    value: "middle",
    label: "generalPages.side",
  },
  {
    value: "end",
    label: "generalPages.top",
  },
];
export const tableColumnFormats = [
  { value: tableColumnFormatValues.NORMAL, label: "generalPages.value" },
  { value: tableColumnFormatValues.PERCENTAGE, label: "generalPages.to Total" },
];
export const verticalAlignItems = [
  {
    value: "top",
    label: "generalPages.top",
  },
  {
    value: "middle",
    label: "generalPages.middle",
  },
  {
    value: "bottom",
    label: "generalPages.bottom",
  },
];

export const fieldItemTypes = [
  {
    value: "SAME_AS_MEASURE",
    label: "generalPages.Same As Measure",
  },
  {
    value: "SUM",
    label: "generalPages.sum",
  },
  {
    value: "AVG",
    label: "generalPages.avg",
  },
  {
    value: "MAX",
    label: "generalPages.max",
  },
  {
    value: "MIN",
    label: "generalPages.min",
  },
  {
    value: "NONE",
    label: "generalPages.none",
  },
];

export const fontWeightsArr = [
  {
    value: "normal",
    label: "generalPages.regular",
  },
  {
    value: 500,
    label: "generalPages.medium",
  },
  {
    value: 600,
    label: "generalPages.bold",
  },
  {
    value: 700,
    label: "generalPages.bolder",
  },
];

export const fontSizesArr = [
  {
    value: 8,
    label: "8 Pt",
  },
  {
    value: 10,
    label: "10 Pt",
  },
  {
    value: 12,
    label: "12 Pt",
  },
  {
    value: 14,
    label: "14 Pt",
  },
  {
    value: 16,
    label: "16 Pt",
  },
  {
    value: 18,
    label: "18 Pt",
  },
  {
    value: 20,
    label: "20 Pt",
  },
  {
    value: 22,
    label: "22 Pt",
  },
  {
    value: 24,
    label: "24 Pt",
  },
  {
    value: 26,
    label: "26 Pt",
  },
  {
    value: 28,
    label: "28 Pt",
  },
];

export const bigFontSizesArr = [
  {
    value: 36,
    label: "36 Pt",
  },
  {
    value: 48,
    label: "48 Pt",
  },
  {
    value: 64,
    label: "64 Pt",
  },
  {
    value: 72,
    label: "72 Pt",
  },
];

export const gridLinesArr = [
  {
    value: "Both",
    label: "panelProperties.both",
  },
  {
    value: "None",
    label: "panelProperties.none",
  },
  {
    value: "Horizontal",
    label: "generalPages.horizontal",
  },
  {
    value: "Vertical",
    label: "generalPages.vertical",
  },
  {
    value: "Default",
    label: "generalPages.default",
  },
];
export const pageSizeValues = {
  FIT_TO_SCREEN: "FIT_TO_SCREEN",
  CUSTOM: "CUSTOM",
};
export const pageSizesArr = [
  {
    value: pageSizeValues.FIT_TO_SCREEN,
    label: "generalPages.fitToScreen",
  },
  {
    value: pageSizeValues.CUSTOM,
    label: "generalPages.custom",
  },
];

export const AXIS_TYPES_ENUM = {
  LOG: "log",
  VALUE: "value",
  CATEGORY: "category",
};

export const visualMapTypes = [
  { value: "continuous", label: "generalPages.continous" },
  { value: "piecewise", label: "generalPages.piecewise" },
];
export const countryValues = {
  TR: "TR",
  USA: "USA",
  TR_DISTRICT: "TR-district",
};
export const countries = [
  {
    value: countryValues.TR,
    label: "Türkiye",
  },
  {
    value: countryValues.USA,
    label: "panelProperties.mapSettings.USACountry",
  },
  {
    value: countryValues.TR_DISTRICT,
    label: "panelProperties.mapSettings.TRDistrict",
  },
];

export const borderRadius = [
  {
    value: "1px",
    label: "1 pt",
  },
  {
    value: "2px",
    label: "2 pt",
  },
  {
    value: "3px",
    label: "3 pt",
  },
  {
    value: "4px",
    label: "4 pt",
  },
  {
    value: "5px",
    label: "5 pt",
  },
  {
    value: "8px",
    label: "8 pt",
  },
  {
    value: "10px",
    label: "10 pt",
  },
  {
    value: "14px",
    label: "14 pt",
  },
  {
    value: "20px",
    label: "20 pt",
  },
];

export const percentageValueKeys = {
  ALL: "ALL",
  COLUMN: "COLUMN",
};
export const percentageOptions = [
  {
    value: percentageValueKeys.ALL,
    label: "panelProperties.All Data",
  },
  {
    value: percentageValueKeys.COLUMN,
    label: "panelProperties.column",
  },
];
export const chartPrefix = "CHART";

export const selectBoxOption = {
  ALL_ITEM: "ALL_ITEM",
};

export const propertiesIconList = [...iconList];

export const buttonSelectionValues = {
  RUN: "run",
  TAB: "tab",
  URL: "url",
  DIAOLOG: "dialog"
};

export const positionValues = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left"
};

export const filterPanePositionArr = [
  { value: positionValues.TOP, label: "generalPages.top" },
  { value: positionValues.RIGHT, label: "generalPages.right" },
  { value: positionValues.BOTTOM, label: "generalPages.bottom" },
  { value: positionValues.LEFT, label: "generalPages.left" }
];

export const buttonSelectionOptions = [
  {
    value: buttonSelectionValues.RUN,
    label: "generalPages.run",
  },
  {
    value: buttonSelectionValues.TAB,
    label: "panelProperties.Go To Tab",
  },
  {
    value: buttonSelectionValues.URL,
    label: "panelProperties.Go To Url",
  },
  {
    value: buttonSelectionValues.DIAOLOG,
    label: "panelProperties.Show Dialog",
  },
];

export const allPropertiesKeys = Object.freeze({
  HAS_TITLE: "HAS_TITLE",
  HAS_SUBTITLE: "HAS_SUBTITLE",
  HAS_FOOTNOTE: "HAS_FOOTNOTE",
  HAS_BORDER: "HAS_BORDER",
  BORDER_COLOR: "BORDER_COLOR",
  BORDER_RADIUS: "BORDER_RADIUS",
  HAS_DROP_SHADOW: "HAS_DROP_SHADOW",
  TITLE_FONT_FAMILY: "TITLE_FONT_FAMILY",
  TITLE_FONT_SIZE: "TITLE_FONT_SIZE",
  TITLE_FONT_WEIGHT: "TITLE_FONT_WEIGHT",
  TITLE_FONT_COLOR: "TITLE_FONT_COLOR",
  TITLE_ALIGN_ITEM: "TITLE_ALIGN_ITEM",
  DESCRIPTION_FONT_FAMILY: "DESCRIPTION_FONT_FAMILY",
  DESCRIPTION_FONT_SIZE: "DESCRIPTION_FONT_SIZE",
  DESCRIPTION_FONT_WEIGHT: "DESCRIPTION_FONT_WEIGHT",
  DESCRIPTION_ALIGN_ITEM: "DESCRIPTION_ALIGN_ITEM",
  DESCRIPTION_FONT_COLOR: "DESCRIPTION_FONT_COLOR",
  FOOTNOTE_FONT_FAMILY: "FOOTNOTE_FONT_FAMILY",
  FOOTNOTE_FONT_SIZE: "FOOTNOTE_FONT_SIZE",
  FOOTNOTE_FONT_WEIGHT: "FOOTNOTE_FONT_WEIGHT",
  FOOTNOTE_FONT_COLOR: "FOOTNOTE_FONT_COLOR",
  POSITION_X: "POSITION_X",
  POSITION_Y: "POSITION_Y",
  CARD_W: "CARD_W",
  CARD_H: "CARD_H",
  DASHBOARD_GRID_BACKGROUND_COLOR: "DASHBOARD_GRID_BACKGROUND_COLOR",
  DASHBOARD_GRID_HAS_GRID_LINES: "DASHBOARD_GRID_HAS_GRID_LINES",
  DASHBOARD_GRID_DESIGN_MODE: "DASHBOARD_GRID_DESIGN_MODE",
  DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE: "DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE",
  DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE:
    "DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE",
  DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE: "DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE",
  DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE:
    "DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE",
  DASHBOARD_GRID_FILTER_PANE_POSITION: "DASHBOARD_GRID_FILTER_PANE_POSITION",
  DASHBOARD_GRID_FILTER_PANE_WIDTH: "DASHBOARD_GRID_FILTER_PANE_WIDTH",
  DASHBOARD_GRID_FILTER_PANE_HEIGHT: "DASHBOARD_GRID_FILTER_PANE_HEIGHT",
  SELECTED_TEMPLATE_ID: "SELECTED_TEMPLATE_ID",
  HAS_TABS: "HAS_TABS",
  TABS_FONT_FAMILY: "TABS_FONT_FAMILY",
  TABS_FONT_COLOR: "TABS_FONT_COLOR",
  TABS_BACKGROUND_COLOR: "TABS_BACKGROUND_COLOR",
  PAGE_SIZE: "PAGE_SIZE",
  WIDTH: "WIDTH",
  HEIGHT: "HEIGHT",
  FONT_FAMILY: "FONT_FAMILY",
  FONT_SIZE: "FONT_SIZE",
  FONT_WEIGHT: "FONT_WEIGHT",
  COLOR: "COLOR",
  BORDER: "BORDER",
  BACKGROUND_COLOR: "BACKGROUND_COLOR",
  CHART_SHOW_VALUE_LABEL: "CHART_SHOW_VALUE_LABEL",

  CHART_LOCATION: "CHART_LOCATION",
  CHART_SHOW_ONLY: "CHART_SHOW_ONLY",
  CHART_VALUE_LABEL_FONT: "CHART_VALUE_LABEL_FONT",
  CHART_VALUE_LABEL_FONT_COLOR: "CHART_VALUE_LABEL_FONT_COLOR",

  //FILTER CHART
  CHART_FILTER_MANDATORY_SELECTION: "CHART_FILTER_MANDATORY_SELECTION",
  CHART_FILTER_ALLOW_MULTISELECT: "CHART_FILTER_ALLOW_MULTISELECT",

  CHART_FILTER_DEFAULT_SELECTION: "CHART_FILTER_DEFAULT_SELECTION",
  CHART_SELECTION_ACTION: "CHART_SELECTION_ACTION",

  CUSTOM_COLOR_PALETTES: "CUSTOM_COLOR_PALETTES",

  CUSTOM_ENDPOINT: "CUSTOM_ENDPOINT",
});

export const chartPropertiesEnum = Object.freeze(
  _.pick(allPropertiesKeys, [
    allPropertiesKeys.CHART_SHOW_VALUE_LABEL,
    allPropertiesKeys.CHART_LOCATION,
    allPropertiesKeys.CHART_SHOW_ONLY,
    allPropertiesKeys.CHART_VALUE_LABEL_FONT,
    allPropertiesKeys.CHART_VALUE_LABEL_FONT_COLOR,
    allPropertiesKeys.CHART_FILTER_MANDATORY_SELECTION,
    allPropertiesKeys.CHART_FILTER_ALLOW_MULTISELECT,
    allPropertiesKeys.CHART_FILTER_DEFAULT_SELECTION,
    allPropertiesKeys.CHART_SELECTION_ACTION,
  ])
);

export const datatablePropertiesEnum = Object.freeze({
  CHART_DATA_TABLE_AGGREGATE_RESULT: "CHART_DATA_TABLE_AGGREGATE_RESULT",
  CHART_SELECT_PAGE_SIZE: "CHART_SELECT_PAGE_SIZE",
  CHART_SELECT_COLUMN_ALIGN: "CHART_SELECT_COLUMN_ALIGN",
  CHART_SELECT_ROW_ALIGN: "CHART_SELECT_ROW_ALIGN",
  CHART_DATA_TABLE_ENABLE_SORTING: "CHART_DATA_TABLE_ENABLE_SORTING",
  CHART_DATA_TABLE_ENABLE_FILTERING: "CHART_DATA_TABLE_ENABLE_FILTERING",
  CHART_DATA_TABLE_ENABLE_EXPORT_MENU: "CHART_DATA_TABLE_ENABLE_EXPORT_MENU",
  CHART_DATA_TABLE_ENABLE_EXCEL_EXPORT: "CHART_DATA_TABLE_ENABLE_EXCEL_EXPORT",
  CHART_DATA_TABLE_ENABLE_CSV_EXPORT: "CHART_DATA_TABLE_ENABLE_CSV_EXPORT",
  CHART_DATA_TABLE_ENABLE_PDF_EXPORT: "CHART_DATA_TABLE_ENABLE_PDF_EXPORT",
  CHART_DATA_TABLE_ENABLE_PAGING: "CHART_DATA_TABLE_ENABLE_PAGING",
  CHART_DATA_TABLE_ENABLE_SEARCH: "CHART_DATA_TABLE_ENABLE_SEARCH",
  CHART_DATA_TABLE_ROW_HEIGHT: "CHART_DATA_TABLE_ROW_HEIGHT",
  CHART_DATA_TABLE_ENABLE_RESIZING: "CHART_DATA_TABLE_ENABLE_RESIZING",
  CHART_DATA_TABLE_ENABLE_REORDERING: "CHART_DATA_TABLE_ENABLE_REORDERING",
  CHART_DATA_TABLE_ENABLE_SELECTION: "CHART_DATA_TABLE_ENABLE_SELECTION",
  CHART_DATA_TABLE_ENABLE_TEXT_WRAP: "CHART_DATA_TABLE_ENABLE_TEXT_WRAP",
  CHART_DATA_TABLE_COLUMN_FONT_FAMILY: "CHART_DATA_TABLE_COLUMN_FONT_FAMILY",
  CHART_DATA_TABLE_COLUMN_FONT_SIZE: "CHART_DATA_TABLE_COLUMN_FONT_SIZE",
  CHART_DATA_TABLE_COLUMN_FONT_WEIGHT: "CHART_DATA_TABLE_COLUMN_FONT_WEIGHT",
  CHART_DATA_TABLE_ROW_FONT_FAMILY: "CHART_DATA_TABLE_ROW_FONT_FAMILY",
  CHART_DATA_TABLE_ROW_FONT_SIZE: "CHART_DATA_TABLE_ROW_FONT_SIZE",
  CHART_DATA_TABLE_ROW_FONT_WEIGHT: "CHART_DATA_TABLE_ROW_FONT_WEIGHT",
  CHART_DATA_TABLE_TOTAL_FONT_FAMILY: "CHART_DATA_TABLE_TOTAL_FONT_FAMILY",
  CHART_DATA_TABLE_TOTAL_FONT_SIZE: "CHART_DATA_TABLE_TOTAL_FONT_SIZE",
  CHART_DATA_TABLE_TOTAL_FONT_WEIGHT: "CHART_DATA_TABLE_TOTAL_FONT_WEIGHT",
  CHART_DATA_TABLE_TOTAL_FONT_COLOR: "CHART_DATA_TABLE_TOTAL_FONT_COLOR",
  CHART_DATA_TABLE_TOTAL_BACKGROUND_COLOR:
    "CHART_DATA_TABLE_TOTAL_BACKGROUND_COLOR",
  CHART_DATA_TABLE_SUBTOTAL_FONT_FAMILY:
    "CHART_DATA_TABLE_SUBTOTAL_FONT_FAMILY",
  CHART_DATA_TABLE_SUBTOTAL_FONT_SIZE: "CHART_DATA_TABLE_SUBTOTAL_FONT_SIZE",
  CHART_DATA_TABLE_SUBTOTAL_FONT_WEIGHT:
    "CHART_DATA_TABLE_SUBTOTAL_FONT_WEIGHT",
  CHART_DATA_TABLE_SUBTOTAL_FONT_COLOR: "CHART_DATA_TABLE_SUBTOTAL_FONT_COLOR",
  CHART_DATA_TABLE_SUBTOTAL_BACKGROUND_COLOR:
    "CHART_DATA_TABLE_SUBTOTAL_BACKGROUND_COLOR",
  CHART_DATA_TABLE_GRID_LINES: "CHART_DATA_TABLE_GRID_LINES",
  CHART_DATA_TABLE_SHOW_TOTAL: "CHART_DATA_TABLE_SHOW_TOTAL",
  CHART_DATA_TABLE_SHOW_SUBTOTAL: "CHART_DATA_TABLE_SHOW_SUBTOTAL",
  CHART_DATA_TABLE_SHOW_SQL: "CHART_DATA_TABLE_SHOW_SQL",
  CHART_DATA_TABLE_SUBTOTAL_POSITION: "CHART_DATA_TABLE_SUBTOTAL_POSITION",
  CHART_DATA_TABLE_AUTO_REFRESH: "CHART_DATA_TABLE_AUTO_REFRESH",
  CHART_DATA_TABLE_AUTO_REFRESH_CUSTOM: "CHART_DATA_TABLE_AUTO_REFRESH_CUSTOM",
  CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE: "CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE",
  CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE:
    "CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE",
  CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES:
    "CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES",
  CHART_DATA_TABLE_ENABLE_SHOW_MAXIMIZE_BUTTON:
    "CHART_DATA_TABLE_ENABLE_SHOW_MAXIMIZE_BUTTON",
  CHART_DATA_TABLE_ENABLE_SHOW_INSIGHT: "CHART_DATA_TABLE_ENABLE_SHOW_INSIGHT",
  CHART_DATA_TABLE_ENABLE_CONTEXT_MENU: "CHART_DATA_TABLE_ENABLE_CONTEXT_MENU",
  CHART_DATA_TABLE_BACKGROUND_COLOR: "CHART_DATA_TABLE_BACKGROUND_COLOR",
  CHART_DATA_TABLE_FONT_COLOR: "CHART_DATA_TABLE_FONT_COLOR",
  CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR:
    "CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR",
  CHART_DATA_TABLE_ROW_BACKGROUND_COLOR:
    "CHART_DATA_TABLE_ROW_BACKGROUND_COLOR",
  CHART_DATA_TABLE_COLUMN_FONT_COLOR: "CHART_DATA_TABLE_COLUMN_FONT_COLOR",
  CHART_DATA_TABLE_SECTION_FONT_FAMILY: "CHART_DATA_TABLE_SECTION_FONT_FAMILY",
  CHART_DATA_TABLE_SECTION_FONT_SIZE: "CHART_DATA_TABLE_SECTION_FONT_SIZE",
  CHART_DATA_TABLE_SECTION_FONT_WEIGHT: "CHART_DATA_TABLE_SECTION_FONT_WEIGHT",
  CHART_DATA_TABLE_SECTION_BACKGROUND_COLOR:
    "CHART_DATA_TABLE_SECTION_BACKGROUND_COLOR",
  CHART_DATA_TABLE_SECTION_FONT_COLOR: "CHART_DATA_TABLE_SECTION_FONT_COLOR",
  CHART_DATA_TABLE_SECTION_INDENT_CELL: "CHART_DATA_TABLE_SECTION_INDENT_CELL",
  CHART_DATA_TABLE_COLUMN_WIDTH: "CHART_DATA_TABLE_COLUMN_WIDTH",
  CHART_DATA_TABLE_CONDITIONAL_FORMATTING_BUTTON:
    "CHART_DATA_TABLE_CONDITIONAL_FORMATTING_BUTTON",
  CHART_DATA_TABLE_COLUMN_FORMAT: "CHART_DATA_TABLE_COLUMN_FORMAT",
  CHART_DATA_TABLE_COLUMN_TEXT_WRAP: "CHART_DATA_TABLE_COLUMN_TEXT_WRAP",
  CHART_DATA_TABLE_ROW_TEXT_WRAP: "CHART_DATA_TABLE_ROW_TEXT_WRAP",
  CHART_DATA_TABLE_COLUMN_NAME: "CHART_DATA_TABLE_COLUMN_NAME",
  CHART_DATA_TABLE_COLUMN_SCREENTIP: "CHART_DATA_TABLE_COLUMN_SCREENTIP",
  CHART_DATA_TABLE_ROW_MAX_CHARACTER: "CHART_DATA_TABLE_ROW_MAX_CHARACTER",
  CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH: "CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH",
  CHART_DATA_TABLE_ALLOW_DRILL_THROUGH: "CHART_DATA_TABLE_ALLOW_DRILL_THROUGH",
  CHART_DATA_TABLE_AGGREGATION_FUNCTION: "CHART_DATA_TABLE_AGGREGATION_FUNCTION"
});

export const PIE_CHART_PROPERTIES_ENUM = Object.freeze({
  PIE_CHART_SHOW_DOUGHNUT: "PIE_CHART_SHOW_DOUGHNUT",
  PIE_CHART_SHOW_VALUE_LABEL: "PIE_CHART_SHOW_VALUE_LABEL",
  PIE_CHART_SHOW_ONLY: "PIE_CHART_SHOW_ONLY",
  PIE_CHART_SHOW_INSIGHT: "PIE_CHART_SHOW_INSIGHT",
  PIE_CHART_SHOW_SQL: "PIE_CHART_SHOW_SQL",
  PIE_CHART_VALUE_LABEL_FONT_FAMILY: "PIE_CHART_VALUE_LABEL_FONT_FAMILY",
  PIE_CHART_VALUE_LABEL_FONT_WEIGHT: "PIE_CHART_VALUE_LABEL_FONT_WEIGHT",
  PIE_CHART_VALUE_LABEL_FONT_SIZE: "PIE_CHART_VALUE_LABEL_FONT_SIZE",
  PIE_CHART_VALUE_LABEL_FONT_COLOR: "PIE_CHART_VALUE_LABEL_FONT_COLOR",
  PIE_CHART_VALUE_LABEL_ALIGNTO: "PIE_CHART_VALUE_LABEL_ALIGNTO",
  PIE_CHART_SHOW_LEGEND: "PIE_CHART_SHOW_LEGEND",
  PIE_CHART_LEGEND_POSITION: "PIE_CHART_LEGEND_POSITION",
  PIE_CHART_LEGEND_FONT_FAMILY: "PIE_CHART_LEGEND_FONT_FAMILY",
  PIE_CHART_LEGEND_FONT_WEIGHT: "PIE_CHART_LEGEND_FONT_WEIGHT",
  PIE_CHART_LEGEND_FONT_SIZE: "PIE_CHART_LEGEND_FONT_SIZE",
  PIE_CHART_LEGEND_FONT_COLOR: "PIE_CHART_LEGEND_FONT_COLOR",
  PIE_CHART_LEGEND_VERTICAL_ALIGN: "PIE_CHART_LEGEND_VERTICAL_ALIGN",
  PIE_CHART_LEGEND_MAX_CHARACTER: "PIE_CHART_LEGEND_MAX_CHARACTER",
  PIE_CHART_GENERAL_COLORS: "PIE_CHART_GENERAL_COLORS",
  PIE_CHART_SELECTED_COLOR_PALETTE: "PIE_CHART_SELECTED_COLOR_PALETTE",

  PIE_CHART_AUTO_REFRESH: "PIE_CHART_AUTO_REFRESH",
  PIE_CHART_AUTO_REFRESH_CUSTOM: "PIE_CHART_AUTO_REFRESH_CUSTOM",

  PIE_CHART_DYNAMIC_TITLE_VALUE: "PIE_CHART_DYNAMIC_TITLE_VALUE",
  PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE: "PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  PIE_CHART_GROUP_MULTIPLE_VALUES: "PIE_CHART_GROUP_MULTIPLE_VALUES",
  PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  PIE_CHART_ALLOW_DRILL_THROUGH: "PIE_CHART_ALLOW_DRILL_THROUGH"
});

export const COLUMN_CHART_PROPERTIES_ENUM = Object.freeze({
  COLUMN_CHART_SHOW_VALUE_LABEL: "COLUMN_CHART_SHOW_VALUE_LABEL",
  COLUMN_CHART_SHOW_ONLY: "COLUMN_CHART_SHOW_ONLY",
  COLUMN_CHART_SHOW_INSIGHT: "COLUMN_CHART_SHOW_INSIGHT",
  COLUMN_CHART_SHOW_SQL: "COLUMN_CHART_SHOW_SQL",
  COLUMN_CHART_VALUE_LABEL_FONT_FAMILY: "COLUMN_CHART_VALUE_LABEL_FONT_FAMILY",
  COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT: "COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT",
  COLUMN_CHART_VALUE_LABEL_FONT_SIZE: "COLUMN_CHART_VALUE_LABEL_FONT_SIZE",
  COLUMN_CHART_VALUE_LABEL_ROTATE: "COLUMN_CHART_VALUE_LABEL_ROTATE",
  COLUMN_CHART_VALUE_LABEL_FONT_COLOR: "COLUMN_CHART_VALUE_LABEL_FONT_COLOR",
  COLUMN_CHART_SHOW_LEGEND: "COLUMN_CHART_SHOW_LEGEND",
  COLUMN_CHART_LEGEND_POSITION: "COLUMN_CHART_LEGEND_POSITION",
  COLUMN_CHART_LEGEND_FONT_FAMILY: "COLUMN_CHART_LEGEND_FONT_FAMILY",
  COLUMN_CHART_LEGEND_FONT_WEIGHT: "COLUMN_CHART_LEGEND_FONT_WEIGHT",
  COLUMN_CHART_LEGEND_FONT_SIZE: "COLUMN_CHART_LEGEND_FONT_SIZE",
  COLUMN_CHART_LEGEND_FONT_COLOR: "COLUMN_CHART_LEGEND_FONT_COLOR",
  COLUMN_CHART_LEGEND_VERTICAL_ALIGN: "COLUMN_CHART_LEGEND_VERTICAL_ALIGN",
  COLUMN_CHART_LEGEND_MAX_CHARACTER: "COLUMN_CHART_LEGEND_MAX_CHARACTER",
  COLUMN_CHART_SHOW_X_AXIS_LINE: "COLUMN_CHART_SHOW_X_AXIS_LINE",
  COLUMN_CHART_SHOW_X_AXIS_NAME: "COLUMN_CHART_SHOW_X_AXIS_NAME",
  COLUMN_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "COLUMN_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  COLUMN_CHART_X_AXIS_NAME_VERTICAL_ALIGN:
    "COLUMN_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT: "COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT",
  COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY: "COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY",
  COLUMN_CHART_X_AXIS_NAME_FONT_SIZE: "COLUMN_CHART_X_AXIS_NAME_FONT_SIZE",
  COLUMN_CHART_X_AXIS_NAME_FONT_COLOR: "COLUMN_CHART_X_AXIS_NAME_FONT_COLOR",
  COLUMN_CHART_X_AXIS_NAME_TEXT: "COLUMN_CHART_X_AXIS_NAME_TEXT",
  COLUMN_CHART_SHOW_Y_AXIS_NAME: "COLUMN_CHART_SHOW_Y_AXIS_NAME",
  COLUMN_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "COLUMN_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  COLUMN_CHART_Y_AXIS_NAME_VERTICAL_ALIGN:
    "COLUMN_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  COLUMN_CHART_Y_AXIS_NAME_LOCATION: "COLUMN_CHART_Y_AXIS_NAME_LOCATION",
  COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT: "COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY: "COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY",
  COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE: "COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE",
  COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR: "COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR",
  COLUMN_CHART_Y_AXIS_NAME_TEXT: "COLUMN_CHART_Y_AXIS_NAME_TEXT",
  COLUMN_CHART_SHOW_X_AXIS_LABEL: "COLUMN_CHART_SHOW_X_AXIS_LABEL",
  COLUMN_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "COLUMN_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  COLUMN_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "COLUMN_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT:
    "COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY:
    "COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY",
  COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE: "COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE",
  COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR: "COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR",
  COLUMN_CHART_X_AXIS_GRID_LINE_COLOR: "COLUMN_CHART_X_AXIS_GRID_LINE_COLOR",
  COLUMN_CHART_SHOW_Y_AXIS_LABEL: "COLUMN_CHART_SHOW_Y_AXIS_LABEL",
  COLUMN_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "COLUMN_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  COLUMN_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "COLUMN_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT:
    "COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY:
    "COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE: "COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE",
  COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR: "COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR",
  COLUMN_CHART_X_AXIS_LABEL_ROTATE: "COLUMN_CHART_X_AXIS_LABEL_ROTATE",
  COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER:
    "COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER",
  COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE:
    "COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE",
  COLUMN_CHART_Y_AXIS_MIN: "COLUMN_CHART_Y_AXIS_MIN",
  COLUMN_CHART_Y_AXIS_POSITION: "COLUMN_CHART_Y_AXIS_POSITION",
  COLUMN_CHART_Y_AXIS_SHOW_LINE: "COLUMN_CHART_Y_AXIS_SHOW_LINE",
  COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR: "COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR",
  COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS:
    "COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  COLUMN_CHART_SELECTED_COLOR_PALETTE: "COLUMN_CHART_SELECTED_COLOR_PALETTE",

  COLUMN_CHART_AUTO_REFRESH: "COLUMN_CHART_AUTO_REFRESH",
  COLUMN_CHART_AUTO_REFRESH_CUSTOM: "COLUMN_CHART_AUTO_REFRESH_CUSTOM",

  COLUMN_CHART_DYNAMIC_TITLE_VALUE: "COLUMN_CHART_DYNAMIC_TITLE_VALUE",
  COLUMN_CHART_DYNAMIC_TITLE_SHOW_VALUE:
    "COLUMN_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  COLUMN_CHART_GROUP_MULTIPLE_VALUES: "COLUMN_CHART_GROUP_MULTIPLE_VALUES",
  COLUMN_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "COLUMN_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  COLUMN_CHART_ALLOW_DRILL_THROUGH: "COLUMN_CHART_ALLOW_DRILL_THROUGH"
});

export const BAR_CHART_PROPERTIES_ENUM = Object.freeze({
  BAR_CHART_SHOW_VALUE_LABEL: "BAR_CHART_SHOW_VALUE_LABEL",
  BAR_CHART_SHOW_ONLY: "BAR_CHART_SHOW_ONLY",
  BAR_CHART_SHOW_INSIGHT: "BAR_CHART_SHOW_INSIGHT",
  BAR_CHART_SHOW_SQL: "BAR_CHART_SHOW_SQL",
  BAR_CHART_VALUE_LABEL_FONT_FAMILY: "BAR_CHART_VALUE_LABEL_FONT_FAMILY",
  BAR_CHART_VALUE_LABEL_FONT_WEIGHT: "BAR_CHART_VALUE_LABEL_FONT_WEIGHT",
  BAR_CHART_VALUE_LABEL_FONT_SIZE: "BAR_CHART_VALUE_LABEL_FONT_SIZE",
  BAR_CHART_VALUE_LABEL_FONT_COLOR: "BAR_CHART_VALUE_LABEL_FONT_COLOR",
  BAR_CHART_SHOW_LEGEND: "BAR_CHART_SHOW_LEGEND",
  BAR_CHART_LEGEND_POSITION: "BAR_CHART_LEGEND_POSITION",
  BAR_CHART_LEGEND_FONT_FAMILY: "BAR_CHART_LEGEND_FONT_FAMILY",
  BAR_CHART_LEGEND_FONT_WEIGHT: "BAR_CHART_LEGEND_FONT_WEIGHT",
  BAR_CHART_LEGEND_FONT_SIZE: "BAR_CHART_LEGEND_FONT_SIZE",
  BAR_CHART_LEGEND_FONT_COLOR: "BAR_CHART_LEGEND_FONT_COLOR",
  BAR_CHART_LEGEND_VERTICAL_ALIGN: "BAR_CHART_LEGEND_VERTICAL_ALIGN",
  BAR_CHART_LEGEND_MAX_CHARACTER: "BAR_CHART_LEGEND_MAX_CHARACTER",

  BAR_CHART_SHOW_X_AXIS_NAME: "BAR_CHART_SHOW_X_AXIS_NAME",
  BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN: "BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  BAR_CHART_X_AXIS_NAME_FONT_WEIGHT: "BAR_CHART_X_AXIS_NAME_FONT_WEIGHT",
  BAR_CHART_X_AXIS_NAME_FONT_FAMILY: "BAR_CHART_X_AXIS_NAME_FONT_FAMILY",
  BAR_CHART_X_AXIS_NAME_FONT_SIZE: "BAR_CHART_X_AXIS_NAME_FONT_SIZE",
  BAR_CHART_X_AXIS_NAME_FONT_COLOR: "BAR_CHART_X_AXIS_NAME_FONT_COLOR",
  BAR_CHART_X_AXIS_NAME_TEXT: "BAR_CHART_X_AXIS_NAME_TEXT",

  BAR_CHART_SHOW_Y_AXIS_NAME: "BAR_CHART_SHOW_Y_AXIS_NAME",
  BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN: "BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT: "BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  BAR_CHART_Y_AXIS_NAME_FONT_FAMILY: "BAR_CHART_Y_AXIS_NAME_FONT_FAMILY",
  BAR_CHART_Y_AXIS_NAME_FONT_SIZE: "BAR_CHART_Y_AXIS_NAME_FONT_SIZE",
  BAR_CHART_Y_AXIS_NAME_FONT_COLOR: "BAR_CHART_Y_AXIS_NAME_FONT_COLOR",
  BAR_CHART_Y_AXIS_NAME_TEXT: "BAR_CHART_Y_AXIS_NAME_TEXT",

  BAR_CHART_SHOW_X_AXIS_LABEL: "BAR_CHART_SHOW_X_AXIS_LABEL",
  BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT: "BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  BAR_CHART_X_AXIS_LABEL_FONT_FAMILY: "BAR_CHART_X_AXIS_LABEL_FONT_FAMILY",
  BAR_CHART_X_AXIS_LABEL_FONT_SIZE: "BAR_CHART_X_AXIS_LABEL_FONT_SIZE",
  BAR_CHART_X_AXIS_LABEL_FONT_COLOR: "BAR_CHART_X_AXIS_LABEL_FONT_COLOR",

  BAR_CHART_SHOW_Y_AXIS_LABEL: "BAR_CHART_SHOW_Y_AXIS_LABEL",
  BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT: "BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY: "BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  BAR_CHART_Y_AXIS_LABEL_FONT_SIZE: "BAR_CHART_Y_AXIS_LABEL_FONT_SIZE",
  BAR_CHART_Y_AXIS_LABEL_FONT_COLOR: "BAR_CHART_Y_AXIS_LABEL_FONT_COLOR",
  BAR_CHART_X_AXIS_LABEL_ROTATE: "BAR_CHART_X_AXIS_LABEL_ROTATE",
  BAR_CHART_X_AXIS_LOGARITHMIC_SCALE: "BAR_CHART_X_AXIS_LOGARITHMIC_SCALE",
  BAR_CHART_X_AXIS_MIN: "BAR_CHART_X_AXIS_MIN",
  BAR_CHART_Y_AXIS_POSITION: "BAR_CHART_Y_AXIS_POSITION",
  BAR_CHART_Y_AXIS_SHOW_LINE: "BAR_CHART_Y_AXIS_SHOW_LINE",
  BAR_CHART_Y_AXIS_LINE_STYLE_COLOR: "BAR_CHART_Y_AXIS_LINE_STYLE_COLOR",
  BAR_CHART_Y_AXIS_GRID_LINE_COLOR: "BAR_CHART_Y_AXIS_GRID_LINE_COLOR",
  BAR_CHART_Y_AXIS_NAME_LOCATION: "BAR_CHART_Y_AXIS_NAME_LOCATION",
  BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER: "BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER",
  BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS:
    "BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  BAR_CHART_SELECTED_COLOR_PALETTE: "BAR_CHART_SELECTED_COLOR_PALETTE",

  BAR_CHART_AUTO_REFRESH: "BAR_CHART_AUTO_REFRESH",
  BAR_CHART_AUTO_REFRESH_CUSTOM: "BAR_CHART_AUTO_REFRESH_CUSTOM",

  BAR_CHART_DYNAMIC_TITLE_VALUE: "BAR_CHART_DYNAMIC_TITLE_VALUE",
  BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE: "BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  BAR_CHART_GROUP_MULTIPLE_VALUES: "BAR_CHART_GROUP_MULTIPLE_VALUES",
  BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",

  BAR_CHART_ALLOW_DRILL_THROUGH: "BAR_CHART_ALLOW_DRILL_THROUGH"
});

export const LINE_CHART_PROPERTIES_ENUM = Object.freeze({
  LINE_CHART_SHOW_VALUE_LABEL: "LINE_CHART_SHOW_VALUE_LABEL",
  LINE_CHART_SHOW_ONLY: "LINE_CHART_SHOW_ONLY",
  LINE_CHART_SHOW_INSIGHT: "LINE_CHART_SHOW_INSIGHT",
  LINE_CHART_SHOW_SQL: "LINE_CHART_SHOW_SQL",
  LINE_CHART_VALUE_LABEL_FONT_FAMILY: "LINE_CHART_VALUE_LABEL_FONT_FAMILY",
  LINE_CHART_VALUE_LABEL_FONT_WEIGHT: "LINE_CHART_VALUE_LABEL_FONT_WEIGHT",
  LINE_CHART_VALUE_LABEL_FONT_SIZE: "LINE_CHART_VALUE_LABEL_FONT_SIZE",
  LINE_CHART_VALUE_LABEL_ROTATE: "LINE_CHART_VALUE_LABEL_ROTATE",
  LINE_CHART_VALUE_LABEL_FONT_COLOR: "LINE_CHART_VALUE_LABEL_FONT_COLOR",
  LINE_CHART_SHOW_LEGEND: "LINE_CHART_SHOW_LEGEND",
  LINE_CHART_LEGEND_POSITION: "LINE_CHART_LEGEND_POSITION",
  LINE_CHART_LEGEND_FONT_FAMILY: "LINE_CHART_LEGEND_FONT_FAMILY",
  LINE_CHART_LEGEND_FONT_WEIGHT: "LINE_CHART_LEGEND_FONT_WEIGHT",
  LINE_CHART_LEGEND_FONT_SIZE: "LINE_CHART_LEGEND_FONT_SIZE",
  LINE_CHART_LEGEND_FONT_COLOR: "LINE_CHART_LEGEND_FONT_COLOR",
  LINE_CHART_LEGEND_VERTICAL_ALIGN: "LINE_CHART_LEGEND_VERTICAL_ALIGN",
  LINE_CHART_LEGEND_MAX_CHARACTER: "LINE_CHART_LEGEND_MAX_CHARACTER",
  LINE_CHART_SHOW_X_AXIS_LINE: "LINE_CHART_SHOW_X_AXIS_LINE",
  LINE_CHART_SHOW_X_AXIS_NAME: "LINE_CHART_SHOW_X_AXIS_NAME",
  LINE_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "LINE_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  LINE_CHART_X_AXIS_NAME_VERTICAL_ALIGN:
    "LINE_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  LINE_CHART_X_AXIS_NAME_FONT_WEIGHT: "LINE_CHART_X_AXIS_NAME_FONT_WEIGHT",
  LINE_CHART_X_AXIS_NAME_FONT_FAMILY: "LINE_CHART_X_AXIS_NAME_FONT_FAMILY",
  LINE_CHART_X_AXIS_NAME_FONT_SIZE: "LINE_CHART_X_AXIS_NAME_FONT_SIZE",
  LINE_CHART_X_AXIS_NAME_FONT_COLOR: "LINE_CHART_X_AXIS_NAME_FONT_COLOR",
  LINE_CHART_X_AXIS_NAME_TEXT: "LINE_CHART_X_AXIS_NAME_TEXT",
  LINE_CHART_SHOW_Y_AXIS_NAME: "LINE_CHART_SHOW_Y_AXIS_NAME",
  LINE_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "LINE_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  LINE_CHART_Y_AXIS_NAME_VERTICAL_ALIGN:
    "LINE_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  LINE_CHART_Y_AXIS_NAME_FONT_WEIGHT: "LINE_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  LINE_CHART_Y_AXIS_NAME_FONT_FAMILY: "LINE_CHART_Y_AXIS_NAME_FONT_FAMILY",
  LINE_CHART_Y_AXIS_NAME_FONT_SIZE: "LINE_CHART_Y_AXIS_NAME_FONT_SIZE",
  LINE_CHART_Y_AXIS_NAME_FONT_COLOR: "LINE_CHART_Y_AXIS_NAME_FONT_COLOR",
  LINE_CHART_Y_AXIS_NAME_TEXT: "LINE_CHART_Y_AXIS_NAME_TEXT",
  LINE_CHART_SHOW_X_AXIS_LABEL: "LINE_CHART_SHOW_X_AXIS_LABEL",
  LINE_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "LINE_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  LINE_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "LINE_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  LINE_CHART_X_AXIS_LABEL_FONT_WEIGHT: "LINE_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  LINE_CHART_X_AXIS_LABEL_FONT_FAMILY: "LINE_CHART_X_AXIS_LABEL_FONT_FAMILY",
  LINE_CHART_X_AXIS_LABEL_FONT_SIZE: "LINE_CHART_X_AXIS_LABEL_FONT_SIZE",
  LINE_CHART_X_AXIS_LABEL_FONT_COLOR: "LINE_CHART_X_AXIS_LABEL_FONT_COLOR",
  LINE_CHART_X_AXIS_GRID_LINE_COLOR: "LINE_CHART_X_AXIS_GRID_LINE_COLOR",
  LINE_CHART_SHOW_Y_AXIS_LABEL: "LINE_CHART_SHOW_Y_AXIS_LABEL",
  LINE_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "LINE_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  LINE_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "LINE_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  LINE_CHART_Y_AXIS_LABEL_FONT_WEIGHT: "LINE_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  LINE_CHART_Y_AXIS_LABEL_FONT_FAMILY: "LINE_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  LINE_CHART_Y_AXIS_LABEL_FONT_SIZE: "LINE_CHART_Y_AXIS_LABEL_FONT_SIZE",
  LINE_CHART_Y_AXIS_LABEL_FONT_COLOR: "LINE_CHART_Y_AXIS_LABEL_FONT_COLOR",
  LINE_CHART_X_AXIS_LABEL_ROTATE: "LINE_CHART_X_AXIS_LABEL_ROTATE",
  LINE_CHART_X_AXIS_LABEL_MAX_CHARACTER:
    "LINE_CHART_X_AXIS_LABEL_MAX_CHARACTER",
  LINE_CHART_Y_AXIS_LOGARITHMIC_SCALE: "LINE_CHART_Y_AXIS_LOGARITHMIC_SCALE",
  LINE_CHART_Y_AXIS_MIN: "LINE_CHART_Y_AXIS_MIN",
  LINE_CHART_Y_AXIS_POSITION: "LINE_CHART_Y_AXIS_POSITION",
  LINE_CHART_Y_AXIS_SHOW_LINE: "LINE_CHART_Y_AXIS_SHOW_LINE",
  LINE_CHART_Y_AXIS_LINE_STYLE_COLOR: "LINE_CHART_Y_AXIS_LINE_STYLE_COLOR",
  LINE_CHART_Y_AXIS_NAME_LOCATION: "LINE_CHART_Y_AXIS_NAME_LOCATION",
  LINE_CHART_HIDE_OVERLAPPED_VALUE_LABELS:
    "LINE_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  LINE_CHART_SELECTED_COLOR_PALETTE: "LINE_CHART_SELECTED_COLOR_PALETTE",

  LINE_CHART_AUTO_REFRESH: "LINE_CHART_AUTO_REFRESH",
  LINE_CHART_AUTO_REFRESH_CUSTOM: "LINE_CHART_AUTO_REFRESH_CUSTOM",

  LINE_CHART_DYNAMIC_TITLE_VALUE: "LINE_CHART_DYNAMIC_TITLE_VALUE",
  LINE_CHART_DYNAMIC_TITLE_SHOW_VALUE: "LINE_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  LINE_CHART_GROUP_MULTIPLE_VALUES: "LINE_CHART_GROUP_MULTIPLE_VALUES",
  LINE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "LINE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  LINE_CHART_ALLOW_DRILL_THROUGH: "LINE_CHART_ALLOW_DRILL_THROUGH"
});

export const SUMMARY_CHART_PROPERTIES_ENUM = Object.freeze({
  SUMMARY_CHART_SHOW_INSIGHT: "SUMMARY_CHART_SHOW_INSIGHT",
  SUMMARY_CHART_SHOW_SQL: "SUMMARY_CHART_SHOW_SQL",
  SUMMARY_CHART_VALUE_FONT_FAMILY: "SUMMARY_CHART_VALUE_FONT_FAMILY",
  SUMMARY_CHART_VALUE_FONT_WEIGHT: "SUMMARY_CHART_VALUE_FONT_WEIGHT",
  SUMMARY_CHART_VALUE_FONT_SIZE: "SUMMARY_CHART_VALUE_FONT_SIZE",
  SUMMARY_CHART_VALUE_ALIGN: "SUMMARY_CHART_VALUE_ALIGN",
  SUMMARY_CHART_VALUE_FONT_COLOR: "SUMMARY_CHART_VALUE_FONT_COLOR",

  SUMMARY_CHART_SHOW_VALUE_LABEL: "SUMMARY_CHART_SHOW_VALUE_LABEL",
  SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY:
    "SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY",
  SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT:
    "SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT",
  SUMMARY_CHART_VALUE_LABEL_FONT_SIZE: "SUMMARY_CHART_VALUE_LABEL_FONT_SIZE",
  SUMMARY_CHART_VALUE_LABEL_ALIGN: "SUMMARY_CHART_VALUE_LABEL_ALIGN",
  SUMMARY_CHART_VALUE_LABEL_FONT_COLOR: "SUMMARY_CHART_VALUE_LABEL_FONT_COLOR",

  SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY: "SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY",
  SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT: "SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT",
  SUMMARY_CHART_TARGET_VALUE_FONT_SIZE: "SUMMARY_CHART_TARGET_VALUE_FONT_SIZE",
  SUMMARY_CHART_TARGET_VALUE_ALIGN: "SUMMARY_CHART_TARGET_VALUE_ALIGN",
  SUMMARY_CHART_TARGET_VALUE_FONT_COLOR: "SUMMARY_CHART_TARGET_VALUE_FONT_COLOR",

  SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL: "SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL",
  SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY:
    "SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY",
  SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT:
    "SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT",
  SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE: "SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE",
  SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN: "SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN",
  SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_COLOR: "SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_COLOR",

  SUMMARY_CHART_AUTO_REFRESH: "SUMMARY_CHART_AUTO_REFRESH",
  SUMMARY_CHART_AUTO_REFRESH_CUSTOM: "SUMMARY_CHART_AUTO_REFRESH_CUSTOM",

  SUMMARY_CHART_DYNAMIC_TITLE_VALUE: "SUMMARY_CHART_DYNAMIC_TITLE_VALUE",
  SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE:
    "SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  SUMMARY_CHART_GROUP_MULTIPLE_VALUES:
    "SUMMARY_CHART_GROUP_MULTIPLE_VALUES",

    SUMMARY_CHART_VALUE_SHOW_TREND_COLOR: "SUMMARY_CHART_VALUE_SHOW_TREND_COLOR",
    SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR: "SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR",
    SUMMARY_CHART_TREND_SHOW_TREND_ICON: "SUMMARY_CHART_TREND_SHOW_TREND_ICON",
    SUMMARY_CHART_TREND_SHOW_DIFFERENCE: "SUMMARY_CHART_TREND_SHOW_DIFFERENCE",
    SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON: "SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON",
    SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR: "SUMMARY_CHART_TREND_GREATER_THAN_TARGET_ICON_COLOR",
    SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON: "SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON",
    SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR: "SUMMARY_CHART_TREND_EQUAL_TO_TARGET_ICON_COLOR",
    SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON: "SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON",
    SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR: "SUMMARY_CHART_TREND_LESS_THAN_TARGET_ICON_COLOR",
    SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
});

export const SELECT_LIST_FILTER_PROPERTIES_ENUM = Object.freeze({
  SELECT_LIST_FILTER_MANDATORY_SELECTION:
    "SELECT_LIST_FILTER_MANDATORY_SELECTION",
  SELECT_LIST_FILTER_ALLOW_MULTISELECT: "SELECT_LIST_FILTER_ALLOW_MULTISELECT",
  SELECT_LIST_FILTER_APPLY_TO_TABS: "SELECT_LIST_FILTER_APPLY_TO_TABS",
  SELECT_LIST_FILTER_DEFAULT_SELECTION: "SELECT_LIST_FILTER_DEFAULT_SELECTION",
  SELECT_LIST_FILTER_SELECTION_ACTION: "SELECT_LIST_FILTER_SELECTION_ACTION",
  SELECT_LIST_FILTER_DIRECTION: "SELECT_LIST_FILTER_DIRECTION",
  SELECT_LIST_FILTER_VALUE_FONT_FAMILY: "SELECT_LIST_FILTER_FONT_FAMILY",
  SELECT_LIST_FILTER_VALUE_FONT_WEIGHT: "SELECT_LIST_FILTER_VALUE_FONT_WEIGHT",
  SELECT_LIST_FILTER_VALUE_FONT_SIZE: "SELECT_LIST_FILTER_VALUE_FONT_SIZE",
  SELECT_LIST_FILTER_VALUE_LABEL_ALIGN: "SELECT_LIST_FILTER_VALUE_LABEL_ALIGN",
  SELECT_LIST_FILTER_VALUE_FONT_COLOR: "SELECT_LIST_FILTER_VALUE_FONT_COLOR",
  SELECT_LIST_FILTER_VALUE_BACKGROUND_COLOR:
    "SELECT_LIST_FILTER_VALUE_BACKGROUND_COLOR",
  //Value Label
  SELECT_LIST_FILTER_VALUE_LABEL_SHOW_LABEL:
    "SELECT_LIST_FILTER_VALUE_LABEL_SHOW_LABEL",
  SELECT_LIST_FILTER_VALUE_LABEL_FONT_FAMILY:
    "SELECT_LIST_FILTER_VALUE_LABEL_FONT_FAMILY",
  SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT:
    "SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT",
  SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE:
    "SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE",
  SELECT_LIST_FILTER_VALUE_LABEL_LABEL_ALIGN:
    "SELECT_LIST_FILTER_VALUE_LABEL_LABEL_ALIGN",
  SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR:
    "SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR",
  SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR:
    "SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR",
  SELECT_LIST_FILTER_UNIQUE_NAME: "SELECT_LIST_FILTER_UNIQUE_NAME",
  SELECT_LIST_FILTER_TAB_INDEX: "SELECT_LIST_FILTER_TAB_INDEX",
});
export const DATE_FILTER_PROPERTIES_ENUM = Object.freeze({
  DATE_FILTER_MANDATORY_SELECTION: "DATE_FILTER_MANDATORY_SELECTION",
  DATE_FILTER_ALLOW_MULTISELECT: "DATE_FILTER_ALLOW_MULTISELECT",
  DATE_FILTER_APPLY_TO_TABS: "DATE_FILTER_APPLY_TO_TABS",
  DATE_FILTER_RANGE_SELECTOR_START_DATE: "DATE_FILTER_RANGE_SELECTOR_START_DATE",
  DATE_FILTER_RANGE_SELECTOR_END_DATE: "DATE_FILTER_RANGE_SELECTOR_END_DATE",
  DATE_FILTER_MAX_RANGE: "DATE_FILTER_MAX_RANGE",
  DATE_FILTER_DEFAULT_SELECTION: "DATE_FILTER_DEFAULT_SELECTION",
  DATE_FILTER_DEFAULT_VALUE_ACTIVE: "DATE_FILTER_DEFAULT_VALUE_ACTIVE",
  DATE_FILTER_SELECTION_ACTION: "DATE_FILTER_SELECTION_ACTION",
  DATE_FILTER_SHOW_QUICK_OPTIONS: "DATE_FILTER_SHOW_QUICK_OPTIONS",
  DATE_FILTER_SELECTED_QUICK_OPTIONS: "DATE_FILTER_SELECTED_QUICK_OPTIONS",
  DATE_FILTER_UNIQUE_NAME: "DATE_FILTER_UNIQUE_NAME",
  DATE_FILTER_TAB_INDEX: "DATE_FILTER_TAB_INDEX",
});
export const DROPDOWN_FILTER_PROPERTIES_ENUM = Object.freeze({
  DROPDOWN_FILTER_MANDATORY_SELECTION: "DROPDOWN_FILTER_MANDATORY_SELECTION",
  DROPDOWN_FILTER_ALLOW_MULTISELECT: "DROPDOWN_FILTER_ALLOW_MULTISELECT",
  DROPDOWN_FILTER_APPLY_TO_TABS: "DROPDOWN_FILTER_APPLY_TO_TABS",
  DROPDOWN_FILTER_DEFAULT_SELECTION: "DROPDOWN_FILTER_DEFAULT_SELECTION",
  DROPDOWN_FILTER_SELECTION_ACTION: "DROPDOWN_FILTER_SELECTION_ACTION",
  DROPDOWN_FILTER_UNIQUE_NAME: "DROPDOWN_FILTER_UNIQUE_NAME",
  DROPDOWN_FILTER_TAB_INDEX: "DROPDOWN_FILTER_TAB_INDEX",
});
export const TEXT_FILTER_PROPERTIES_ENUM = Object.freeze({
  TEXT_FILTER_MANDATORY_SELECTION: "TEXT_FILTER_MANDATORY_SELECTION",
  TEXT_FILTER_SELECTION_ACTION: "TEXT_FILTER_SELECTION_ACTION",
  TEXT_FILTER_APPLY_TO_TABS: "TEXT_FILTER_APPLY_TO_TABS",
  TEXT_FILTER_DEFAULT_SELECTION: "TEXT_FILTER_DEFAULT_SELECTION",
  TEXT_FILTER_DEFAULT_VALUE_ACTIVE: "TEXT_FILTER_DEFAULT_VALUE_ACTIVE",
  TEXT_FILTER_UNIQUE_NAME: "TEXT_FILTER_UNIQUE_NAME",
  TEXT_FILTER_TAB_INDEX: "TEXT_FILTER_TAB_INDEX",
});
export const AREA_CHART_PROPERTIES_ENUM = Object.freeze({
  AREA_CHART_SHOW_VALUE_LABEL: "AREA_CHART_SHOW_VALUE_LABEL",
  AREA_CHART_SHOW_ONLY: "AREA_CHART_SHOW_ONLY",
  AREA_CHART_SHOW_INSIGHT: "AREA_CHART_SHOW_INSIGHT",
  AREA_CHART_SHOW_SQL: "AREA_CHART_SHOW_SQL",
  AREA_CHART_VALUE_LABEL_FONT_FAMILY: "AREA_CHART_VALUE_LABEL_FONT_FAMILY",
  AREA_CHART_VALUE_LABEL_FONT_WEIGHT: "AREA_CHART_VALUE_LABEL_FONT_WEIGHT",
  AREA_CHART_VALUE_LABEL_FONT_SIZE: "AREA_CHART_VALUE_LABEL_FONT_SIZE",
  AREA_CHART_VALUE_LABEL_ROTATE: "AREA_CHART_VALUE_LABEL_ROTATE",
  AREA_CHART_VALUE_LABEL_FONT_COLOR: "AREA_CHART_VALUE_LABEL_FONT_COLOR",
  AREA_CHART_SHOW_LEGEND: "AREA_CHART_SHOW_LEGEND",
  AREA_CHART_LEGEND_POSITION: "AREA_CHART_LEGEND_POSITION",
  AREA_CHART_LEGEND_FONT_FAMILY: "AREA_CHART_LEGEND_FONT_FAMILY",
  AREA_CHART_LEGEND_FONT_WEIGHT: "AREA_CHART_LEGEND_FONT_WEIGHT",
  AREA_CHART_LEGEND_FONT_SIZE: "AREA_CHART_LEGEND_FONT_SIZE",
  AREA_CHART_LEGEND_FONT_COLOR: "AREA_CHART_LEGEND_FONT_COLOR",
  AREA_CHART_LEGEND_VERTICAL_ALIGN: "AREA_CHART_LEGEND_VERTICAL_ALIGN",
  AREA_CHART_LEGEND_MAX_CHARACTER: "AREA_CHART_LEGEND_MAX_CHARACTER",
  AREA_CHART_SHOW_X_AXIS_LINE: "AREA_CHART_SHOW_X_AXIS_LINE",
  AREA_CHART_SHOW_X_AXIS_NAME: "AREA_CHART_SHOW_X_AXIS_NAME",
  AREA_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "AREA_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  AREA_CHART_X_AXIS_NAME_VERTICAL_ALIGN:
    "AREA_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  AREA_CHART_X_AXIS_NAME_FONT_WEIGHT: "AREA_CHART_X_AXIS_NAME_FONT_WEIGHT",
  AREA_CHART_X_AXIS_NAME_FONT_FAMILY: "AREA_CHART_X_AXIS_NAME_FONT_FAMILY",
  AREA_CHART_X_AXIS_NAME_FONT_SIZE: "AREA_CHART_X_AXIS_NAME_FONT_SIZE",
  AREA_CHART_X_AXIS_NAME_FONT_COLOR: "AREA_CHART_X_AXIS_NAME_FONT_COLOR",
  AREA_CHART_X_AXIS_NAME_TEXT: "AREA_CHART_X_AXIS_NAME_TEXT",
  AREA_CHART_SHOW_Y_AXIS_NAME: "AREA_CHART_SHOW_Y_AXIS_NAME",
  AREA_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "AREA_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  AREA_CHART_Y_AXIS_NAME_VERTICAL_ALIGN:
    "AREA_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  AREA_CHART_Y_AXIS_NAME_FONT_WEIGHT: "AREA_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  AREA_CHART_Y_AXIS_NAME_FONT_FAMILY: "AREA_CHART_Y_AXIS_NAME_FONT_FAMILY",
  AREA_CHART_Y_AXIS_NAME_FONT_SIZE: "AREA_CHART_Y_AXIS_NAME_FONT_SIZE",
  AREA_CHART_Y_AXIS_NAME_FONT_COLOR: "AREA_CHART_Y_AXIS_NAME_FONT_COLOR",
  AREA_CHART_Y_AXIS_NAME_TEXT: "AREA_CHART_Y_AXIS_NAME_TEXT",
  AREA_CHART_SHOW_X_AXIS_LABEL: "AREA_CHART_SHOW_X_AXIS_LABEL",
  AREA_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "AREA_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  AREA_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "AREA_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  AREA_CHART_X_AXIS_LABEL_FONT_WEIGHT: "AREA_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  AREA_CHART_X_AXIS_LABEL_FONT_FAMILY: "AREA_CHART_X_AXIS_LABEL_FONT_FAMILY",
  AREA_CHART_X_AXIS_LABEL_FONT_SIZE: "AREA_CHART_X_AXIS_LABEL_FONT_SIZE",
  AREA_CHART_X_AXIS_LABEL_FONT_COLOR: "AREA_CHART_X_AXIS_LABEL_FONT_COLOR",
  AREA_CHART_X_AXIS_GRID_LINE_COLOR: "AREA_CHART_X_AXIS_GRID_LINE_COLOR",
  AREA_CHART_SHOW_Y_AXIS_LABEL: "AREA_CHART_SHOW_Y_AXIS_LABEL",
  AREA_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "AREA_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  AREA_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "AREA_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  AREA_CHART_Y_AXIS_LABEL_FONT_WEIGHT: "AREA_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  AREA_CHART_Y_AXIS_LABEL_FONT_FAMILY: "AREA_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  AREA_CHART_Y_AXIS_LABEL_FONT_SIZE: "AREA_CHART_Y_AXIS_LABEL_FONT_SIZE",
  AREA_CHART_Y_AXIS_LABEL_FONT_COLOR: "AREA_CHART_Y_AXIS_LABEL_FONT_COLOR",
  AREA_CHART_X_AXIS_LABEL_ROTATE: "AREA_CHART_X_AXIS_LABEL_ROTATE",
  AREA_CHART_X_AXIS_LABEL_MAX_CHARACTER:
    "AREA_CHART_X_AXIS_LABEL_MAX_CHARACTER",
  AREA_CHART_Y_AXIS_LOGARITHMIC_SCALE: "AREA_CHART_Y_AXIS_LOGARITHMIC_SCALE",
  AREA_CHART_Y_AXIS_MIN: "AREA_CHART_Y_AXIS_MIN",
  AREA_CHART_Y_AXIS_POSITION: "AREA_CHART_Y_AXIS_POSITION",
  AREA_CHART_Y_AXIS_SHOW_LINE: "AREA_CHART_Y_AXIS_SHOW_LINE",
  AREA_CHART_Y_AXIS_LINE_STYLE_COLOR: "AREA_CHART_Y_AXIS_LINE_STYLE_COLOR",
  AREA_CHART_Y_AXIS_NAME_LOCATION: "AREA_CHART_Y_AXIS_NAME_LOCATION",
  AREA_CHART_AREA_OPACITY: "AREA_CHART_AREA_OPACITY",
  AREA_CHART_HIDE_OVERLAPPED_VALUE_LABELS:
    "AREA_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  AREA_CHART_SELECTED_COLOR_PALETTE: "AREA_CHART_SELECTED_COLOR_PALETTE",

  AREA_CHART_AUTO_REFRESH: "AREA_CHART_AUTO_REFRESH",
  AREA_CHART_AUTO_REFRESH_CUSTOM: "AREA_CHART_AUTO_REFRESH_CUSTOM",

  AREA_CHART_DYNAMIC_TITLE_VALUE: "AREA_CHART_DYNAMIC_TITLE_VALUE",
  AREA_CHART_DYNAMIC_TITLE_SHOW_VALUE: "AREA_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  AREA_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "AREA_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  AREA_CHART_GROUP_MULTIPLE_VALUES: "AREA_CHART_GROUP_MULTIPLE_VALUES",
  AREA_CHART_ALLOW_DRILL_THROUGH: "AREA_CHART_ALLOW_DRILL_THROUGH"
});

export const TREEMAP_PROPERTIES_ENUM = Object.freeze({
  TREEMAP_SHOW_VALUE_LABEL: "TREEMAP_SHOW_VALUE_LABEL",
  TREEMAP_SHOW_ONLY: "TREEMAP_SHOW_ONLY",
  TREEMAP_SHOW_INSIGHT: "TREEMAP_SHOW_INSIGHT",
  TREEMAP_SHOW_SQL: "TREEMAP_SHOW_SQL",
  TREEMAP_VALUE_LABEL_FONT_FAMILY: "TREEMAP_VALUE_LABEL_FONT_FAMILY",
  TREEMAP_VALUE_LABEL_FONT_WEIGHT: "TREEMAP_VALUE_LABEL_FONT_WEIGHT",
  TREEMAP_VALUE_LABEL_FONT_SIZE: "TREEMAP_VALUE_LABEL_FONT_SIZE",
  TREEMAP_VALUE_LABEL_FONT_COLOR: "TREEMAP_VALUE_LABEL_FONT_COLOR",
  TREEMAP_SHOW_LEGEND: "TREEMAP_SHOW_LEGEND",
  TREEMAP_LEGEND_POSITION: "TREEMAP_LEGEND_POSITION",
  TREEMAP_LEGEND_VERTICAL_ALIGN: "TREEMAP_LEGEND_VERTICAL_ALIGN",
  TREEMAP_LEGEND_MAX_CHARACTER: "TREEMAP_LEGEND_MAX_CHARACTER",
  TREEMAP_LEGEND_FONT_FAMILY: "TREEMAP_LEGEND_FONT_FAMILY",
  TREEMAP_LEGEND_FONT_WEIGHT: "TREEMAP_LEGEND_FONT_WEIGHT",
  TREEMAP_LEGEND_FONT_SIZE: "TREEMAP_LEGEND_FONT_SIZE",
  TREEMAP_LEGEND_FONT_COLOR: "TREEMAP_LEGEND_FONT_COLOR",
  TREEMAP_SELECTED_COLOR_PALETTE: "TREEMAP_SELECTED_COLOR_PALETTE",

  TREEMAP_AUTO_REFRESH: "TREEMAP_AUTO_REFRESH",
  TREEMAP_AUTO_REFRESH_CUSTOM: "TREEMAP_AUTO_REFRESH_CUSTOM",

  TREEMAP_DYNAMIC_TITLE_VALUE: "TREEMAP_DYNAMIC_TITLE_VALUE",
  TREEMAP_DYNAMIC_TITLE_SHOW_VALUE: "TREEMAP_DYNAMIC_TITLE_SHOW_VALUE",
  TREEMAP_GROUP_MULTIPLE_VALUES: "TREEMAP_GROUP_MULTIPLE_VALUES",
  TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  TREEMAP_ALLOW_DRILL_THROUGH: "TREEMAP_ALLOW_DRILL_THROUGH"
});

export const HEATMAP_PROPERTIES_ENUM = Object.freeze({
  HEATMAP_SHOW_VALUE_LABEL: "HEATMAP_SHOW_VALUE_LABEL",
  HEATMAP_SHOW_ONLY: "HEATMAP_SHOW_ONLY",
  HEATMAP_SHOW_INSIGHT: "HEATMAP_SHOW_INSIGHT",
  HEATMAP_SHOW_SQL: "HEATMAP_SHOW_SQL",
  HEATMAP_VALUE_LABEL_FONT_FAMILY: "HEATMAP_VALUE_LABEL_FONT_FAMILY",
  HEATMAP_VALUE_LABEL_FONT_WEIGHT: "HEATMAP_VALUE_LABEL_FONT_WEIGHT",
  HEATMAP_VALUE_LABEL_FONT_SIZE: "HEATMAP_VALUE_LABEL_FONT_SIZE",
  HEATMAP_VALUE_LABEL_FONT_COLOR: "HEATMAP_VALUE_LABEL_FONT_COLOR",
  HEATMAP_SHOW_X_AXIS_LINE: "HEATMAP_SHOW_X_AXIS_LINE",
  HEATMAP_SHOW_X_AXIS_NAME: "HEATMAP_SHOW_X_AXIS_NAME",
  HEATMAP_X_AXIS_NAME_HORIZONTAL_ALIGN: "HEATMAP_X_AXIS_NAME_HORIZONTAL_ALIGN",
  HEATMAP_X_AXIS_NAME_VERTICAL_ALIGN: "HEATMAP_X_AXIS_NAME_VERTICAL_ALIGN",
  HEATMAP_X_AXIS_NAME_FONT_WEIGHT: "HEATMAP_X_AXIS_NAME_FONT_WEIGHT",
  HEATMAP_X_AXIS_NAME_FONT_FAMILY: "HEATMAP_X_AXIS_NAME_FONT_FAMILY",
  HEATMAP_X_AXIS_NAME_FONT_SIZE: "HEATMAP_X_AXIS_NAME_FONT_SIZE",
  HEATMAP_X_AXIS_NAME_FONT_COLOR: "HEATMAP_X_AXIS_NAME_FONT_COLOR",
  HEATMAP_SHOW_Y_AXIS_NAME: "HEATMAP_SHOW_Y_AXIS_NAME",
  HEATMAP_Y_AXIS_NAME_HORIZONTAL_ALIGN: "HEATMAP_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  HEATMAP_Y_AXIS_NAME_VERTICAL_ALIGN: "HEATMAP_Y_AXIS_NAME_VERTICAL_ALIGN",
  HEATMAP_Y_AXIS_NAME_FONT_WEIGHT: "HEATMAP_Y_AXIS_NAME_FONT_WEIGHT",
  HEATMAP_Y_AXIS_NAME_FONT_FAMILY: "HEATMAP_Y_AXIS_NAME_FONT_FAMILY",
  HEATMAP_Y_AXIS_NAME_FONT_SIZE: "HEATMAP_Y_AXIS_NAME_FONT_SIZE",
  HEATMAP_Y_AXIS_NAME_FONT_COLOR: "HEATMAP_Y_AXIS_NAME_FONT_COLOR",
  HEATMAP_SHOW_X_AXIS_LABEL: "HEATMAP_SHOW_X_AXIS_LABEL",
  HEATMAP_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "HEATMAP_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  HEATMAP_X_AXIS_LABEL_VERTICAL_ALIGN: "HEATMAP_X_AXIS_LABEL_VERTICAL_ALIGN",
  HEATMAP_X_AXIS_LABEL_FONT_WEIGHT: "HEATMAP_X_AXIS_LABEL_FONT_WEIGHT",
  HEATMAP_X_AXIS_LABEL_FONT_FAMILY: "HEATMAP_X_AXIS_LABEL_FONT_FAMILY",
  HEATMAP_X_AXIS_LABEL_FONT_SIZE: "HEATMAP_X_AXIS_LABEL_FONT_SIZE",
  HEATMAP_X_AXIS_LABEL_FONT_COLOR: "HEATMAP_X_AXIS_LABEL_FONT_COLOR",
  HEATMAP_SHOW_Y_AXIS_LABEL: "HEATMAP_SHOW_Y_AXIS_LABEL",
  HEATMAP_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "HEATMAP_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  HEATMAP_Y_AXIS_LABEL_VERTICAL_ALIGN: "HEATMAP_Y_AXIS_LABEL_VERTICAL_ALIGN",
  HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT: "HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT",
  HEATMAP_Y_AXIS_LABEL_FONT_FAMILY: "HEATMAP_Y_AXIS_LABEL_FONT_FAMILY",
  HEATMAP_Y_AXIS_LABEL_FONT_SIZE: "HEATMAP_Y_AXIS_LABEL_FONT_SIZE",
  HEATMAP_Y_AXIS_LABEL_FONT_COLOR: "HEATMAP_Y_AXIS_LABEL_FONT_COLOR",
  HEATMAP_X_AXIS_LABEL_ROTATE: "HEATMAP_X_AXIS_LABEL_ROTATE",
  HEATMAP_Y_AXIS_TYPE: "HEATMAP_Y_AXIS_TYPE",
  HEATMAP_Y_AXIS_POSITION: "HEATMAP_Y_AXIS_POSITION",
  HEATMAP_Y_AXIS_SHOW_LINE: "HEATMAP_Y_AXIS_SHOW_LINE",
  HEATMAP_Y_AXIS_LINE_STYLE_COLOR: "HEATMAP_Y_AXIS_LINE_STYLE_COLOR",
  HEATMAP_Y_AXIS_NAME_LOCATION: "HEATMAP_Y_AXIS_NAME_LOCATION",
  HEATMAP_SHOW_VISUALMAP: "HEATMAP_SHOW_VISUALMAP",
  HEATMAP_VISUALMAP_TYPE: "HEATMAP_VISUALMAP_TYPE",
  HEATMAP_VISUALMAP_POSITION: "HEATMAP_VISUALMAP_POSITION",
  HEATMAP_VISUALMAP_VERTICAL_ALIGN: "HEATMAP_VISUALMAP_VERTICAL_ALIGN",
  HEATMAP_VISUALMAP_MAX_CHARACTER: "HEATMAP_VISUALMAP_MAX_CHARACTER",
  HEATMAP_VISUALMAP_FONT_SIZE: "HEATMAP_VISUALMAP_FONT_SIZE",
  HEATMAP_VISUALMAP_FONT_FAMILY: "HEATMAP_VISUALMAP_FONT_FAMILY",
  HEATMAP_VISUALMAP_FONT_WEIGHT: "HEATMAP_VISUALMAP_FONT_WEIGHT",
  HEATMAP_VISUALMAP_FONT_COLOR: "HEATMAP_VISUALMAP_FONT_COLOR",
  HEATMAP_VISUALMAP_WIDTH: "HEATMAP_VISUALMAP_WIDTH",
  HEATMAP_VISUALMAP_COLOR_PALETTE: "HEATMAP_VISUALMAP_COLOR_PALETTE",
  HEATMAP_SELECTED_COLOR_PALETTE: "HEATMAP_SELECTED_COLOR_PALETTE",

  HEATMAP_AUTO_REFRESH: "HEATMAP_AUTO_REFRESH",
  HEATMAP_AUTO_REFRESH_CUSTOM: "HEATMAP_AUTO_REFRESH_CUSTOM",

  HEATMAP_DYNAMIC_TITLE_VALUE: "HEATMAP_DYNAMIC_TITLE_VALUE",
  HEATMAP_DYNAMIC_TITLE_SHOW_VALUE: "HEATMAP_DYNAMIC_TITLE_SHOW_VALUE",
  HEATMAP_GROUP_MULTIPLE_VALUES: "HEATMAP_GROUP_MULTIPLE_VALUES",
  HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  HEATMAP_ALLOW_DRILL_THROUGH: "HEATMAP_ALLOW_DRILL_THROUGH"
});

export const SCATTER_CHART_PROPERTIES_ENUM = Object.freeze({
  SCATTER_CHART_SHOW_VALUE_LABEL: "SCATTER_CHART_SHOW_VALUE_LABEL",
  SCATTER_CHART_SHOW_ONLY: "SCATTER_CHART_SHOW_ONLY",
  SCATTER_CHART_SHOW_INSIGHT: "SCATTER_CHART_SHOW_INSIGHT",
  SCATTER_CHART_SHOW_SQL: "SCATTER_CHART_SHOW_SQL",
  SCATTER_CHART_VALUE_LABEL_FONT_FAMILY:
    "SCATTER_CHART_VALUE_LABEL_FONT_FAMILY",
  SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT:
    "SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT",
  SCATTER_CHART_VALUE_LABEL_FONT_SIZE: "SCATTER_CHART_VALUE_LABEL_FONT_SIZE",
  SCATTER_CHART_VALUE_LABEL_FONT_COLOR: "SCATTER_CHART_VALUE_LABEL_FONT_COLOR",
  SCATTER_CHART_VALUE_LABEL_ROTATE: "SCATTER_CHART_VALUE_LABEL_ROTATE",
  SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS: "SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  SCATTER_CHART_SHOW_LEGEND: "SCATTER_CHART_SHOW_LEGEND",
  SCATTER_CHART_LEGEND_POSITION: "SCATTER_CHART_LEGEND_POSITION",
  SCATTER_CHART_LEGEND_FONT_FAMILY: "SCATTER_CHART_LEGEND_FONT_FAMILY",
  SCATTER_CHART_LEGEND_FONT_WEIGHT: "SCATTER_CHART_LEGEND_FONT_WEIGHT",
  SCATTER_CHART_LEGEND_FONT_SIZE: "SCATTER_CHART_LEGEND_FONT_SIZE",
  SCATTER_CHART_LEGEND_FONT_COLOR: "SCATTER_CHART_LEGEND_FONT_COLOR",
  SCATTER_CHART_LEGEND_VERTICAL_ALIGN: "SCATTER_CHART_LEGEND_VERTICAL_ALIGN",
  SCATTER_CHART_LEGEND_MAX_CHARACTER: "SCATTER_CHART_LEGEND_MAX_CHARACTER",
  SCATTER_CHART_SHOW_X_AXIS_NAME: "SCATTER_CHART_SHOW_X_AXIS_NAME",
  SCATTER_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "SCATTER_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  SCATTER_CHART_X_AXIS_NAME_VERTICAL_ALIGN:
    "SCATTER_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT:
    "SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT",
  SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY:
    "SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY",
  SCATTER_CHART_X_AXIS_NAME_FONT_SIZE: "SCATTER_CHART_X_AXIS_NAME_FONT_SIZE",
  SCATTER_CHART_X_AXIS_NAME_FONT_COLOR: "SCATTER_CHART_X_AXIS_NAME_FONT_COLOR",
  SCATTER_CHART_SHOW_Y_AXIS_NAME: "SCATTER_CHART_SHOW_Y_AXIS_NAME",
  SCATTER_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "SCATTER_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  SCATTER_CHART_Y_AXIS_NAME_VERTICAL_ALIGN:
    "SCATTER_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT:
    "SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY:
    "SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY",
  SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE: "SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE",
  SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR: "SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR",
  SCATTER_CHART_SHOW_X_AXIS_LABEL: "SCATTER_CHART_SHOW_X_AXIS_LABEL",
  SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT:
    "SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY:
    "SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY",
  SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE: "SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE",
  SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR:
    "SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR",
  SCATTER_CHART_SHOW_Y_AXIS_LABEL: "SCATTER_CHART_SHOW_Y_AXIS_LABEL",
  SCATTER_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "SCATTER_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  SCATTER_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "SCATTER_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT:
    "SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY:
    "SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE: "SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE",
  SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR:
    "SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR",
  SCATTER_CHART_X_AXIS_LABEL_ROTATE: "SCATTER_CHART_X_AXIS_LABEL_ROTATE",
  SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE:
    "SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE",
  SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE:
    "SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE",
  SCATTER_CHART_Y_AXIS_POSITION: "SCATTER_CHART_Y_AXIS_POSITION",
  SCATTER_CHART_Y_AXIS_SHOW_LINE: "SCATTER_CHART_Y_AXIS_SHOW_LINE",
  SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR:
    "SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR",
  SCATTER_CHART_Y_AXIS_NAME_LOCATION: "SCATTER_CHART_Y_AXIS_NAME_LOCATION",
  SCATTER_CHART_SYMBOL_SIZE: "SCATTER_CHART_SYMBOL_SIZE",
  SCATTER_CHART_Y_AXIS_MIN: "SCATTER_CHART_Y_AXIS_MIN",
  SCATTER_CHART_X_AXIS_MIN: "SCATTER_CHART_X_AXIS_MIN",
  SCATTER_CHART_SELECTED_COLOR_PALETTE: "SCATTER_CHART_SELECTED_COLOR_PALETTE",

  SCATTER_CHART_AUTO_REFRESH: "SCATTER_CHART_AUTO_REFRESH",
  SCATTER_CHART_AUTO_REFRESH_CUSTOM: "SCATTER_CHART_AUTO_REFRESH_CUSTOM",

  SCATTER_CHART_DYNAMIC_TITLE_VALUE: "SCATTER_CHART_DYNAMIC_TITLE_VALUE",
  SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE:
    "SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  SCATTER_CHART_GROUP_MULTIPLE_VALUES:
    "SCATTER_CHART_GROUP_MULTIPLE_VALUES",
  SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  SCATTER_CHART_ALLOW_DRILL_THROUGH: "SCATTER_CHART_ALLOW_DRILL_THROUGH"
});

export const BUBBLE_CHART_PROPERTIES_ENUM = Object.freeze({
  BUBBLE_CHART_SHOW_VALUE_LABEL: "BUBBLE_CHART_SHOW_VALUE_LABEL",
  BUBBLE_CHART_SHOW_ONLY: "BUBBLE_CHART_SHOW_ONLY",
  BUBBLE_CHART_SHOW_INSIGHT: "BUBBLE_CHART_SHOW_INSIGHT",
  BUBBLE_CHART_SHOW_SQL: "BUUBLE_CHART_SHOW_SQL",
  BUBBLE_CHART_VALUE_LABEL_FONT_FAMILY: "BUBBLE_CHART_VALUE_LABEL_FONT_FAMILY",
  BUBBLE_CHART_VALUE_LABEL_FONT_WEIGHT: "BUBBLE_CHART_VALUE_LABEL_FONT_WEIGHT",
  BUBBLE_CHART_VALUE_LABEL_FONT_SIZE: "BUBBLE_CHART_VALUE_LABEL_FONT_SIZE",
  BUBBLE_CHART_VALUE_LABEL_FONT_COLOR: "BUBBLE_CHART_VALUE_LABEL_FONT_COLOR",
  BUBBLE_CHART_VALUE_LABEL_ROTATE: "BUBBLE_CHART_VALUE_LABEL_ROTATE",
  BUBBLE_CHART_SHOW_LEGEND: "BUBBLE_CHART_SHOW_LEGEND",
  BUBBLE_CHART_LEGEND_POSITION: "BUBBLE_CHART_LEGEND_POSITION",
  BUBBLE_CHART_LEGEND_FONT_FAMILY: "BUBBLE_CHART_LEGEND_FONT_FAMILY",
  BUBBLE_CHART_LEGEND_FONT_WEIGHT: "BUBBLE_CHART_LEGEND_FONT_WEIGHT",
  BUBBLE_CHART_LEGEND_FONT_SIZE: "BUBBLE_CHART_LEGEND_FONT_SIZE",
  BUBBLE_CHART_LEGEND_FONT_COLOR: "BUBBLE_CHART_LEGEND_FONT_COLOR",
  BUBBLE_CHART_LEGEND_VERTICAL_ALIGN: "BUBBLE_CHART_LEGEND_VERTICAL_ALIGN",
  BUBBLE_CHART_LEGEND_MAX_CHARACTER: "BUBBLE_CHART_LEGEND_MAX_CHARACTER",
  BUBBLE_CHART_SHOW_X_AXIS_NAME: "BUBBLE_CHART_SHOW_X_AXIS_NAME",
  BUBBLE_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "BUBBLE_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  BUBBLE_CHART_X_AXIS_NAME_VERTICAL_ALIGN:
    "BUBBLE_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  BUBBLE_CHART_X_AXIS_NAME_FONT_WEIGHT: "BUBBLE_CHART_X_AXIS_NAME_FONT_WEIGHT",
  BUBBLE_CHART_X_AXIS_NAME_FONT_FAMILY: "BUBBLE_CHART_X_AXIS_NAME_FONT_FAMILY",
  BUBBLE_CHART_X_AXIS_NAME_FONT_SIZE: "BUBBLE_CHART_X_AXIS_NAME_FONT_SIZE",
  BUBBLE_CHART_X_AXIS_NAME_FONT_COLOR: "BUBBLE_CHART_X_AXIS_NAME_FONT_COLOR",
  BUBBLE_CHART_SHOW_Y_AXIS_NAME: "BUBBLE_CHART_SHOW_Y_AXIS_NAME",
  BUBBLE_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "BUBBLE_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  BUBBLE_CHART_Y_AXIS_NAME_VERTICAL_ALIGN:
    "BUBBLE_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  BUBBLE_CHART_Y_AXIS_NAME_FONT_WEIGHT: "BUBBLE_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  BUBBLE_CHART_Y_AXIS_NAME_FONT_FAMILY: "BUBBLE_CHART_Y_AXIS_NAME_FONT_FAMILY",
  BUBBLE_CHART_Y_AXIS_NAME_FONT_SIZE: "BUBBLE_CHART_Y_AXIS_NAME_FONT_SIZE",
  BUBBLE_CHART_Y_AXIS_NAME_FONT_COLOR: "BUBBLE_CHART_Y_AXIS_NAME_FONT_COLOR",
  BUBBLE_CHART_SHOW_X_AXIS_LABEL: "BUBBLE_CHART_SHOW_X_AXIS_LABEL",
  BUBBLE_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "BUBBLE_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  BUBBLE_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "BUBBLE_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  BUBBLE_CHART_X_AXIS_LABEL_FONT_WEIGHT:
    "BUBBLE_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  BUBBLE_CHART_X_AXIS_LABEL_FONT_FAMILY:
    "BUBBLE_CHART_X_AXIS_LABEL_FONT_FAMILY",
  BUBBLE_CHART_X_AXIS_LABEL_FONT_SIZE: "BUBBLE_CHART_X_AXIS_LABEL_FONT_SIZE",
  BUBBLE_CHART_X_AXIS_LABEL_FONT_COLOR: "BUBBLE_CHART_X_AXIS_LABEL_FONT_COLOR",
  BUBBLE_CHART_SHOW_Y_AXIS_LABEL: "BUBBLE_CHART_SHOW_Y_AXIS_LABEL",
  BUBBLE_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "BUBBLE_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  BUBBLE_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "BUBBLE_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  BUBBLE_CHART_Y_AXIS_LABEL_FONT_WEIGHT:
    "BUBBLE_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  BUBBLE_CHART_Y_AXIS_LABEL_FONT_FAMILY:
    "BUBBLE_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  BUBBLE_CHART_Y_AXIS_LABEL_FONT_SIZE: "BUBBLE_CHART_Y_AXIS_LABEL_FONT_SIZE",
  BUBBLE_CHART_Y_AXIS_LABEL_FONT_COLOR: "BUBBLE_CHART_Y_AXIS_LABEL_FONT_COLOR",
  BUBBLE_CHART_X_AXIS_LABEL_ROTATE: "BUBBLE_CHART_X_AXIS_LABEL_ROTATE",
  BUBBLE_CHART_X_AXIS_LOGARITHMIC_SCALE:
    "BUBBLE_CHART_X_AXIS_LOGARITHMIC_SCALE",
  BUBBLE_CHART_Y_AXIS_LOGARITHMIC_SCALE:
    "BUBBLE_CHART_Y_AXIS_LOGARITHMIC_SCALE",
  BUBBLE_CHART_Y_AXIS_POSITION: "BUBBLE_CHART_Y_AXIS_POSITION",
  BUBBLE_CHART_Y_AXIS_SHOW_LINE: "BUBBLE_CHART_Y_AXIS_SHOW_LINE",
  BUBBLE_CHART_Y_AXIS_LINE_STYLE_COLOR: "BUBBLE_CHART_Y_AXIS_LINE_STYLE_COLOR",
  BUBBLE_CHART_Y_AXIS_NAME_LOCATION: "BUBBLE_CHART_Y_AXIS_NAME_LOCATION",
  BUBBLE_CHART_HIDE_OVERLAPPED_VALUE_LABELS:
    "BUBBLE_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  BUBBLE_CHART_SYMBOL_SIZE: "BUBBLE_CHART_SYMBOL_SIZE",
  BUBBLE_CHART_Y_AXIS_MIN: "BUBBLE_CHART_Y_AXIS_MIN",
  BUBBLE_CHART_X_AXIS_MIN: "BUBBLE_CHART_X_AXIS_MIN",
  BUBBLE_CHART_SELECTED_COLOR_PALETTE: "BUBBLE_CHART_SELECTED_COLOR_PALETTE",

  BUBBLE_CHART_AUTO_REFRESH: "BUBBLE_CHART_AUTO_REFRESH",
  BUBBLE_CHART_AUTO_REFRESH_CUSTOM: "BUBBLE_CHART_AUTO_REFRESH_CUSTOM",

  BUBBLE_CHART_DYNAMIC_TITLE_VALUE: "BUBBLE_CHART_DYNAMIC_TITLE_VALUE",
  BUBBLE_CHART_DYNAMIC_TITLE_SHOW_VALUE:
    "BUBBLE_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  BUBBLE_CHART_GROUP_MULTIPLE_VALUES:
    "BUBBLE_CHART_GROUP_MULTIPLE_VALUES",
  BUBBLE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "BUBBLE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  BUBBLE_CHART_ALLOW_DRILL_THROUGH: "BUBBLE_CHART_ALLOW_DRILL_THROUGH"
});

export const COMBINATION_CHART_PROPERTIES_ENUM = Object.freeze({
  COMBINATION_CHART_SHOW_VALUE_LABEL: "COMBINATION_CHART_SHOW_VALUE_LABEL",
  COMBINATION_CHART_SHOW_ONLY: "COMBINATION_CHART_SHOW_ONLY",
  COMBINATION_CHART_SHOW_INSIGHT: "COMBINATION_CHART_SHOW_INSIGHT",
  COMBINATION_CHART_SHOW_SQL: "COMBINATION_CHART_SHOW_SQL",
  COMBINATION_CHART_VALUE_LABEL_FONT_FAMILY:
    "COMBINATION_CHART_VALUE_LABEL_FONT_FAMILY",
  COMBINATION_CHART_VALUE_LABEL_FONT_WEIGHT:
    "COMBINATION_CHART_VALUE_LABEL_FONT_WEIGHT",
  COMBINATION_CHART_VALUE_LABEL_FONT_SIZE:
    "COMBINATION_CHART_VALUE_LABEL_FONT_SIZE",
  COMBINATION_CHART_VALUE_LABEL_ROTATE: "COMBINATION_CHART_VALUE_LABEL_ROTATE",
  COMBINATION_CHART_VALUE_LABEL_FONT_COLOR:
    "COMBINATION_CHART_VALUE_LABEL_FONT_COLOR",
  COMBINATION_CHART_SHOW_LEGEND: "COMBINATION_CHART_SHOW_LEGEND",
  COMBINATION_CHART_LEGEND_POSITION: "COMBINATION_CHART_LEGEND_POSITION",
  COMBINATION_CHART_LEGEND_FONT_FAMILY: "COMBINATION_CHART_LEGEND_FONT_FAMILY",
  COMBINATION_CHART_LEGEND_FONT_WEIGHT: "COMBINATION_CHART_LEGEND_FONT_WEIGHT",
  COMBINATION_CHART_LEGEND_FONT_SIZE: "COMBINATION_CHART_LEGEND_FONT_SIZE",
  COMBINATION_CHART_LEGEND_FONT_COLOR: "COMBINATION_CHART_LEGEND_FONT_COLOR",
  COMBINATION_CHART_LEGEND_VERTICAL_ALIGN:
    "COMBINATION_CHART_LEGEND_VERTICAL_ALIGN",
  COMBINATION_CHART_LEGEND_MAX_CHARACTER: "COMBINATION_CHART_LEGEND_MAX_CHARACTER",
  COMBINATION_CHART_SHOW_X_AXIS_LINE: "COMBINATION_CHART_SHOW_X_AXIS_LINE",
  COMBINATION_CHART_SHOW_X_AXIS_NAME: "COMBINATION_CHART_SHOW_X_AXIS_NAME",
  COMBINATION_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "COMBINATION_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  COMBINATION_CHART_X_AXIS_NAME_VERTICAL_ALIGN:
    "COMBINATION_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  COMBINATION_CHART_X_AXIS_NAME_FONT_WEIGHT:
    "COMBINATION_CHART_X_AXIS_NAME_FONT_WEIGHT",
  COMBINATION_CHART_X_AXIS_NAME_FONT_FAMILY:
    "COMBINATION_CHART_X_AXIS_NAME_FONT_FAMILY",
  COMBINATION_CHART_X_AXIS_NAME_FONT_SIZE:
    "COMBINATION_CHART_X_AXIS_NAME_FONT_SIZE",
  COMBINATION_CHART_X_AXIS_NAME_FONT_COLOR:
    "COMBINATION_CHART_X_AXIS_NAME_FONT_COLOR",
  COMBINATION_CHART_X_AXIS_NAME_TEXT: "COMBINATION_CHART_X_AXIS_NAME_TEXT",
  COMBINATION_CHART_SHOW_Y_AXIS_NAME: "COMBINATION_CHART_SHOW_Y_AXIS_NAME",
  COMBINATION_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "COMBINATION_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  COMBINATION_CHART_Y_AXIS_NAME_VERTICAL_ALIGN:
    "COMBINATION_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  COMBINATION_CHART_Y_AXIS_NAME_LOCATION:
    "COMBINATION_CHART_Y_AXIS_NAME_LOCATION",
  COMBINATION_CHART_Y_AXIS_NAME_FONT_WEIGHT:
    "COMBINATION_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  COMBINATION_CHART_Y_AXIS_NAME_FONT_FAMILY:
    "COMBINATION_CHART_Y_AXIS_NAME_FONT_FAMILY",
  COMBINATION_CHART_Y_AXIS_NAME_FONT_SIZE:
    "COMBINATION_CHART_Y_AXIS_NAME_FONT_SIZE",
  COMBINATION_CHART_Y_AXIS_NAME_FONT_COLOR:
    "COMBINATION_CHART_Y_AXIS_NAME_FONT_COLOR",
  COMBINATION_CHART_Y_AXIS_NAME_TEXT: "COMBINATION_CHART_Y_AXIS_NAME_TEXT",
  COMBINATION_CHART_SHOW_X_AXIS_LABEL: "COMBINATION_CHART_SHOW_X_AXIS_LABEL",
  COMBINATION_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "COMBINATION_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  COMBINATION_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "COMBINATION_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  COMBINATION_CHART_X_AXIS_LABEL_FONT_WEIGHT:
    "COMBINATION_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  COMBINATION_CHART_X_AXIS_LABEL_FONT_FAMILY:
    "COMBINATION_CHART_X_AXIS_LABEL_FONT_FAMILY",
  COMBINATION_CHART_X_AXIS_LABEL_FONT_SIZE:
    "COMBINATION_CHART_X_AXIS_LABEL_FONT_SIZE",
  COMBINATION_CHART_X_AXIS_LABEL_FONT_COLOR:
    "COMBINATION_CHART_X_AXIS_LABEL_FONT_COLOR",
  COMBINATION_CHART_X_AXIS_GRID_LINE_COLOR:
    "COMBINATION_CHART_X_AXIS_GRID_LINE_COLOR",
  COMBINATION_CHART_SHOW_Y_AXIS_LABEL: "COMBINATION_CHART_SHOW_Y_AXIS_LABEL",
  COMBINATION_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "COMBINATION_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  COMBINATION_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "COMBINATION_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  COMBINATION_CHART_Y_AXIS_LABEL_FONT_WEIGHT:
    "COMBINATION_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  COMBINATION_CHART_Y_AXIS_LABEL_FONT_FAMILY:
    "COMBINATION_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  COMBINATION_CHART_Y_AXIS_LABEL_FONT_SIZE:
    "COMBINATION_CHART_Y_AXIS_LABEL_FONT_SIZE",
  COMBINATION_CHART_Y_AXIS_LABEL_FONT_COLOR:
    "COMBINATION_CHART_Y_AXIS_LABEL_FONT_COLOR",
  COMBINATION_CHART_X_AXIS_LABEL_ROTATE:
    "COMBINATION_CHART_X_AXIS_LABEL_ROTATE",
  COMBINATION_CHART_X_AXIS_LABEL_MAX_CHARACTER:
    "COMBINATION_CHART_X_AXIS_LABEL_MAX_CHARACTER",
  COMBINATION_CHART_Y_AXIS_LOGARITHMIC_SCALE:
    "COMBINATION_CHART_Y_AXIS_LOGARITHMIC_SCALE",
  COMBINATION_CHART_Y_AXIS_MIN: "COMBINATION_CHART_Y_AXIS_MIN",
  COMBINATION_CHART_Y_AXIS_POSITION: "COMBINATION_CHART_Y_AXIS_POSITION",
  COMBINATION_CHART_Y_AXIS_SHOW_LINE: "COMBINATION_CHART_Y_AXIS_SHOW_LINE",
  COMBINATION_CHART_Y_AXIS_LINE_STYLE_COLOR:
    "COMBINATION_CHART_Y_AXIS_LINE_STYLE_COLOR",
  COMBINATION_CHART_HIDE_OVERLAPPED_VALUE_LABELS:
    "COMBINATION_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  COMBINATION_CHART_SHOW_SECOND_Y_AXIS_NAME:
    "COMBINATION_CHART_SHOW_SECOND_Y_AXIS_NAME",
  COMBINATION_CHART_SECOND_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "COMBINATION_CHART_SECOND_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  COMBINATION_CHART_SECOND_Y_AXIS_NAME_VERTICAL_ALIGN:
    "COMBINATION_CHART_SECOND_Y_AXIS_NAME_VERTICAL_ALIGN",
  COMBINATION_CHART_SECOND_Y_AXIS_NAME_LOCATION:
    "COMBINATION_CHART_SECOND_Y_AXIS_NAME_LOCATION",
  COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_WEIGHT:
    "COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_WEIGHT",
  COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_FAMILY:
    "COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_FAMILY",
  COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_SIZE:
    "COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_SIZE",
  COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_COLOR:
    "COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_COLOR",
  COMBINATION_CHART_SECOND_Y_AXIS_NAME_TEXT:
    "COMBINATION_CHART_SECOND_Y_AXIS_NAME_TEXT",
  COMBINATION_CHART_SHOW_SECOND_Y_AXIS_LABEL:
    "COMBINATION_CHART_SHOW_SECOND_Y_AXIS_LABEL",
  COMBINATION_CHART_SECOND_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "COMBINATION_CHART_SECOND_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  COMBINATION_CHART_SECOND_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "COMBINATION_CHART_SECOND_Y_AXIS_LABEL_VERTICAL_ALIGN",
  COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_WEIGHT:
    "COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_WEIGHT",
  COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_FAMILY:
    "COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_FAMILY",
  COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_SIZE:
    "COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_SIZE",
  COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_COLOR:
    "COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_COLOR",
  COMBINATION_CHART_SECOND_Y_AXIS_LOGARITHMIC_SCALE:
    "COMBINATION_CHART_SECOND_Y_AXIS_LOGARITHMIC_SCALE",
  COMBINATION_CHART_SECOND_Y_AXIS_MIN: "COMBINATION_CHART_SECOND_Y_AXIS_MIN",
  COMBINATION_CHART_SECOND_Y_AXIS_POSITION:
    "COMBINATION_CHART_SECOND_Y_AXIS_POSITION",
  COMBINATION_CHART_SECOND_Y_AXIS_SHOW_LINE:
    "COMBINATION_CHART_SECOND_Y_AXIS_SHOW_LINE",
  COMBINATION_CHART_SECOND_Y_AXIS_LINE_STYLE_COLOR:
    "COMBINATION_CHART_SECOND_Y_AXIS_LINE_STYLE_COLOR",
  COMBINATION_CHART_SELECTED_COLOR_PALETTE:
    "COMBINATION_CHART_SELECTED_COLOR_PALETTE",

  COMBINATION_CHART_AUTO_REFRESH: "COMBINATION_CHART_AUTO_REFRESH",
  COMBINATION_CHART_AUTO_REFRESH_CUSTOM:
    "COMBINATION_CHART_AUTO_REFRESH_CUSTOM",

  COMBINATION_CHART_DYNAMIC_TITLE_VALUE:
    "COMBINATION_CHART_DYNAMIC_TITLE_VALUE",
  COMBINATION_CHART_DYNAMIC_TITLE_SHOW_VALUE:
    "COMBINATION_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  COMBINATION_CHART_GROUP_MULTIPLE_VALUES:
    "COMBINATION_CHART_GROUP_MULTIPLE_VALUES",
  COMBINATION_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "COMBINATION_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  COMBINATION_CHART_ALLOW_DRILL_THROUGH: "COMBINATION_CHART_ALLOW_DRILL_THROUGH"
});

export const GAUGE_CHART_PROPERTIES_ENUM = Object.freeze({
  GAUGE_CHART_SHOW_INSIGHT: "GAUGE_CHART_SHOW_INSIGHT",
  GAUGE_CHART_SHOW_SQL: "GAUGE_CHART_SHOW_SQL",
  GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY:
    "GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY",
  GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT:
    "GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT",
  GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE:
    "GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE",
  GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR:
    "GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR",
  GAUGE_CHART_PROGRESS_BAR_WIDTH: "GAUGE_CHART_PROGRESS_BAR_WIDTH",
  GAUGE_CHART_TITLE_FONT_FAMILY: "GAUGE_CHART_TITLE_FONT_FAMILY",
  GAUGE_CHART_TITLE_FONT_WEIGHT: "GAUGE_CHART_TITLE_FONT_WEIGHT",
  GAUGE_CHART_TITLE_FONT_SIZE: "GAUGE_CHART_TITLE_FONT_SIZE",
  GAUGE_CHART_TITLE_FONT_COLOR: "GAUGE_CHART_TITLE_FONT_COLOR",
  GAUGE_CHART_VALUE_LABEL_FONT_FAMILY: "GAUGE_CHART_VALUE_LABEL_FONT_FAMILY",
  GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT: "GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT",
  GAUGE_CHART_VALUE_LABEL_FONT_SIZE: "GAUGE_CHART_VALUE_LABEL_FONT_SIZE",
  GAUGE_CHART_VALUE_LABEL_FONT_COLOR: "GAUGE_CHART_VALUE_LABEL_FONT_COLOR",
  GAUGE_CHART_SEPERATION_ENABLED: "GAUGE_CHART_SEPERATION_ENABLED",
  GAUGE_CHART_SEPERATION_VALUES: "GAUGE_CHART_SEPERATION_VALUES",

  GAUGE_CHART_AUTO_REFRESH: "GAUGE_CHART_AUTO_REFRESH",
  GAUGE_CHART_AUTO_REFRESH_CUSTOM: "GAUGE_CHART_AUTO_REFRESH_CUSTOM",

  GAUGE_CHART_DYNAMIC_TITLE_VALUE: "GAUGE_CHART_DYNAMIC_TITLE_VALUE",
  GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE: "GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  GAUGE_CHART_GROUP_MULTIPLE_VALUES: "GAUGE_CHART_GROUP_MULTIPLE_VALUES",
  GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  GAUGE_CHART_ALLOW_DRILL_THROUGH: "GAUGE_CHART_ALLOW_DRILL_THROUGH"
});

export const PIVOT_TABLE_PROPERTIES_ENUM = Object.freeze({
  PIVOT_TABLE_SHOW_FIELD_LIST: "PIVOT_TABLE_SHOW_FIELD_LIST",
  PIVOT_TABLE_ENABLE_VALUE_SORTING: "PIVOT_TABLE_ENABLE_VALUE_SORTING",
  PIVOT_TABLE_ENABLE_ROW_PAGING:"PIVOT_TABLE_ENABLE_ROW_PAGING",
  PIVOT_TABLE_ROWS_PER_PAGE: "PIVOT_TABLE_ROWS_PER_PAGE",
  PIVOT_TABLE_ENABLE_COLS_PAGING:"PIVOT_TABLE_ENABLE_COLS_PAGING",
  PIVOT_TABLE_COLS_PER_PAGE: "PIVOT_TABLE_COLS_PER_PAGE",
  PIVOT_TABLE_ALLOW_EXPORT_MENU: "PIVOT_TABLE_ALLOW_EXPORT_MENU",
  PIVOT_TABLE_ALLOW_EXCEL_EXPORT: "PIVOT_TABLE_ALLOW_EXCEL_EXPORT",
  PIVOT_TABLE_ALLOW_CSV_EXPORT: "PIVOT_TABLE_ALLOW_CSV_EXPORT",
  PIVOT_TABLE_ALLOW_PDF_EXPORT: "PIVOT_TABLE_ALLOW_PDF_EXPORT",
  PIVOT_TABLE_ALLOW_SELECTION: "PIVOT_TABLE_ALLOW_SELECTION",
  PIVOT_TABLE_ALLOW_SHOW_MAXIMIZE_BUTTON:
    "PIVOT_TABLE_ALLOW_SHOW_MAXIMIZE_BUTTON",
  PIVOT_TABLE_ALLOW_SHOW_INSIGHT: "PIVOT_TABLE_ALLOW_SHOW_INSIGHT",
  PIVOT_TABLE_ALLOW_SHOW_SQL: "PIVOT_TABLE_ALLOW_SHOW_SQL",
  PIVOT_TABLE_ALLOW_CONTEXT_MENU: "PIVOT_TABLE_ALLOW_CONTEXT_MENU",
  PIVOT_TABLE_SELECTION_MODE: "PIVOT_TABLE_SELECTION_MODE",
  PIVOT_TABLE_SELECTION_TYPE: "PIVOT_TABLE_SELECTION_TYPE",
  PIVOT_TABLE_SUB_TOTALS: "PIVOT_TABLE_SUB_TOTALS",
  PIVOT_TABLE_GRAND_TOTALS: "PIVOT_TABLE_GRAND_TOTALS",
  PIVOT_TABLE_ALLOW_HEATMAP:
    "PIVOT_TABLE_ALLOW_HEATMAP",
  PIVOT_TABLE_CONDITIONAL_FORMAT: "PIVOT_TABLE_CONDITIONAL_FORMAT",
  PIVOT_TABLE_GRAND_TOTAL_POSITION: "PIVOT_TABLE_GRAND_TOTAL_POSITION",
  PIVOT_TABLE_ROW_TITLE_FONT_FAMILY: "PIVOT_TABLE_ROW_TITLE_FONT_FAMILY",
  PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT: "PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT",
  PIVOT_TABLE_ROW_TITLE_FONT_SIZE: "PIVOT_TABLE_ROW_TITLE_FONT_SIZE",
  PIVOT_TABLE_ROW_TITLE_FONT_COLOR: "PIVOT_TABLE_ROW_TITLE_FONT_COLOR",
  PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY: "PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY",
  PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT: "PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT",
  PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE: "PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE",
  PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR: "PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR",
  PIVOT_TABLE_MEASURE_FONT_FAMILY: "PIVOT_TABLE_MEASURE_FONT_FAMILY",
  PIVOT_TABLE_MEASURE_FONT_WEIGHT: "PIVOT_TABLE_MEASURE_FONT_WEIGHT",
  PIVOT_TABLE_MEASURE_FONT_SIZE: "PIVOT_TABLE_MEASURE_FONT_SIZE",
  PIVOT_TABLE_MEASURE_FONT_COLOR: "PIVOT_TABLE_MEASURE_FONT_COLOR",
  PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY:
    "PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY",
  PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT:
    "PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT",
  PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE:
    "PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE",
  PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR:
    "PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR",
  PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY:
    "PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY",
  PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT:
    "PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT",
  PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE:
    "PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE",
  PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR:
    "PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR",
  PIVOT_TABLE_GRID_LINES: "PIVOT_TABLE_GRID_LINES",
  PIVOT_TABLE_ROW_HEIGHT: "PIVOT_TABLE_ROW_HEIGHT",
  PIVOT_TABLE_ROW_WIDTH: "PIVOT_TABLE_ROW_WIDTH",

  PIVOT_TABLE_AUTO_REFRESH: "PIVOT_TABLE_AUTO_REFRESH",
  PIVOT_TABLE_AUTO_REFRESH_CUSTOM: "PIVOT_TABLE_AUTO_REFRESH_CUSTOM",

  PIVOT_TABLE_DYNAMIC_TITLE_VALUE: "PIVOT_TABLE_DYNAMIC_TITLE_VALUE",
  PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE: "PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE",
  PIVOT_TABLE_GROUP_MULTIPLE_VALUES: "PIVOT_TABLE_GROUP_MULTIPLE_VALUES",
  PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  PIVOT_TABLE_DRILLED_MEMBERS: "PIVOT_TABLE_DRILLED_MEMBERS",
  PIVOT_TABLE_ALLOW_DRILL_THROUGH: "PIVOT_TABLE_ALLOW_DRILL_THROUGH",

  PIVOT_TABLE_COLUMN_WIDTH: "PIVOT_TABLE_COLUMN_WIDTH",
  PIVOT_TABLE_CONDITIONAL_FORMATTING_BUTTON:
    "PIVOT_TABLE_CONDITIONAL_FORMATTING_BUTTON",
  PIVOT_TABLE_COLUMN_FORMAT: "PIVOT_TABLE_COLUMN_FORMAT",
  PIVOT_TABLE_COLUMN_TEXT_WRAP: "PIVOT_TABLE_COLUMN_TEXT_WRAP",
  PIVOT_TABLE_ROW_TEXT_WRAP: "PIVOT_TABLE_ROW_TEXT_WRAP",
  PIVOT_TABLE_COLUMN_NAME: "PIVOT_TABLE_COLUMN_NAME",
  PIVOT_TABLE_COLUMN_SCREENTIP: "PIVOT_TABLE_COLUMN_SCREENTIP",
  PIVOT_TABLE_COLUMN_FONT_FAMILY: "PIVOT_TABLE_COLUMN_FONT_FAMILY",
  PIVOT_TABLE_COLUMN_FONT_SIZE: "PIVOT_TABLE_COLUMN_FONT_SIZE",
  PIVOT_TABLE_COLUMN_FONT_WEIGHT: "PIVOT_TABLE_COLUMN_FONT_WEIGHT",
  PIVOT_TABLE_ROW_FONT_FAMILY: "PIVOT_TABLE_ROW_FONT_FAMILY",
  PIVOT_TABLE_ROW_FONT_SIZE: "PIVOT_TABLE_ROW_FONT_SIZE",
  PIVOT_TABLE_ROW_FONT_WEIGHT: "PIVOT_TABLE_ROW_FONT_WEIGHT",
  PIVOT_TABLE_COLUMN_BACKGROUND_COLOR: "PIVOT_TABLE_COLUMN_BACKGROUND_COLOR",
  PIVOT_TABLE_ROW_BACKGROUND_COLOR: "PIVOT_TABLE_ROW_BACKGROUND_COLOR",
  PIVOT_TABLE_COLUMN_FONT_COLOR: "PIVOT_TABLE_COLUMN_FONT_COLOR",
  PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN: "PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN",
  PIVOT_TABLE_ROW_MAX_CHARACTER: "PIVOT_TABLE_ROW_MAX_CHARACTER",
  PIVOT_TABLE_FONT_COLOR: "PIVOT_TABLE_FONT_COLOR",
  PIVOT_TABLE_SELECT_COLUMN_ALIGN: "PIVOT_TABLE_SELECT_COLUMN_ALIGN",
  PIVOT_TABLE_AGGREGATION_FUNCTION: "PIVOT_TABLE_AGGREGATION_FUNCTION",
});

export const RADAR_CHART_PROPERTIES_ENUM = Object.freeze({
  RADAR_CHART_SHOW_VALUE_LABEL: "RADAR_CHART_SHOW_VALUE_LABEL",
  RADAR_CHART_SHOW_ONLY: "RADAR_CHART_SHOW_ONLY",
  RADAR_CHART_SHOW_INSIGHT: "RADAR_CHART_SHOW_INSIGHT",
  RADAR_CHART_SHOW_SQL: "RADAR_CHART_SHOW_SQL",
  RADAR_CHART_VALUE_LABEL_FONT_FAMILY: "RADAR_CHART_VALUE_LABEL_FONT_FAMILY",
  RADAR_CHART_VALUE_LABEL_FONT_WEIGHT: "RADAR_CHART_VALUE_LABEL_FONT_WEIGHT",
  RADAR_CHART_VALUE_LABEL_FONT_SIZE: "RADAR_CHART_VALUE_LABEL_FONT_SIZE",
  RADAR_CHART_VALUE_LABEL_FONT_COLOR: "RADAR_CHART_VALUE_LABEL_FONT_COLOR",
  RADAR_CHART_SHOW_LEGEND: "RADAR_CHART_SHOW_LEGEND",
  RADAR_CHART_LEGEND_POSITION: "RADAR_CHART_LEGEND_POSITION",
  RADAR_CHART_LEGEND_FONT_FAMILY: "RADAR_CHART_LEGEND_FONT_FAMILY",
  RADAR_CHART_LEGEND_FONT_WEIGHT: "RADAR_CHART_LEGEND_FONT_WEIGHT",
  RADAR_CHART_LEGEND_FONT_SIZE: "RADAR_CHART_LEGEND_FONT_SIZE",
  RADAR_CHART_LEGEND_FONT_COLOR: "RADAR_CHART_LEGEND_FONT_COLOR",
  RADAR_CHART_LEGEND_VERTICAL_ALIGN: "RADAR_CHART_LEGEND_VERTICAL_ALIGN",
  RADAR_CHART_LEGEND_MAX_CHARACTER: "RADAR_CHART_LEGEND_MAX_CHARACTER",
  RADAR_CHART_SHOW_AXIS_NAME: "RADAR_CHART_SHOW_AXIS_NAME",
  RADAR_CHART_AXIS_NAME_FONT_FAMILY: "RADAR_CHART_AXIS_NAME_FONT_FAMILY",
  RADAR_CHART_AXIS_NAME_FONT_WEIGHT: "RADAR_CHART_AXIS_NAME_FONT_WEIGHT",
  RADAR_CHART_AXIS_NAME_FONT_SIZE: "RADAR_CHART_AXIS_NAME_FONT_SIZE",
  RADAR_CHART_AXIS_NAME_FONT_COLOR: "RADAR_CHART_AXIS_NAME_FONT_COLOR",
  RADAR_CHART_GENERAL_COLORS: "RADAR_CHART_GENERAL_COLORS",
  RADAR_CHART_SELECTED_COLOR_PALETTE: "RADAR_CHART_SELECTED_COLOR_PALETTE",

  RADAR_CHART_AUTO_REFRESH: "RADAR_CHART_AUTO_REFRESH",
  RADAR_CHART_AUTO_REFRESH_CUSTOM: "RADAR_CHART_AUTO_REFRESH_CUSTOM",

  RADAR_CHART_DYNAMIC_TITLE_VALUE: "RADAR_CHART_DYNAMIC_TITLE_VALUE",
  RADAR_CHART_DYNAMIC_TITLE_SHOW_VALUE: "RADAR_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  RADAR_CHART_GROUP_MULTIPLE_VALUES: "RADAR_CHART_GROUP_MULTIPLE_VALUES",
  RADAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "RADAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  RADAR_CHART_ALLOW_DRILL_THROUGH: "RADAR_CHART_ALLOW_DRILL_THROUGH"
});

export const STACKED_COLUMN_CHART_PROPERTIES_ENUM = Object.freeze({
  STACKED_COLUMN_CHART_SHOW_VALUE_LABEL:
    "STACKED_COLUMN_CHART_SHOW_VALUE_LABEL",
  STACKED_COLUMN_CHART_SHOW_PERCENT_TYPE:
    "STACKED_COLUMN_CHART_SHOW_PERCENT_TYPE",
    STACKED_COLUMN_CHART_SHOW_HUNDRED_PERCENT:
    "STACKED_COLUMN_CHART_SHOW_HUNDRED_PERCENT",
  STACKED_COLUMN_CHART_SHOW_ONLY: "STACKED_COLUMN_CHART_SHOW_ONLY",
  STACKED_COLUMN_CHART_SHOW_INSIGHT: "STACKED_COLUMN_CHART_SHOW_INSIGHT",
  STACKED_COLUMN_CHART_SHOW_SQL: "STACKED_COLUMN_CHART_SHOW_SQL",
  STACKED_COLUMN_CHART_VALUE_LABEL_FONT_FAMILY:
    "STACKED_COLUMN_CHART_VALUE_LABEL_FONT_FAMILY",
  STACKED_COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT:
    "STACKED_COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT",
  STACKED_COLUMN_CHART_VALUE_LABEL_FONT_SIZE:
    "STACKED_COLUMN_CHART_VALUE_LABEL_FONT_SIZE",
  STACKED_COLUMN_CHART_VALUE_LABEL_ROTATE:
    "STACKED_COLUMN_CHART_VALUE_LABEL_ROTATE",
  STACKED_COLUMN_CHART_VALUE_LABEL_FONT_COLOR:
    "STACKED_COLUMN_CHART_VALUE_LABEL_FONT_COLOR",
  STACKED_COLUMN_CHART_SHOW_LEGEND: "STACKED_COLUMN_CHART_SHOW_LEGEND",
  STACKED_COLUMN_CHART_LEGEND_POSITION: "STACKED_COLUMN_CHART_LEGEND_POSITION",
  STACKED_COLUMN_CHART_LEGEND_FONT_FAMILY:
    "STACKED_COLUMN_CHART_LEGEND_FONT_FAMILY",
  STACKED_COLUMN_CHART_LEGEND_FONT_WEIGHT:
    "STACKED_COLUMN_CHART_LEGEND_FONT_WEIGHT",
  STACKED_COLUMN_CHART_LEGEND_FONT_SIZE:
    "STACKED_COLUMN_CHART_LEGEND_FONT_SIZE",
  STACKED_COLUMN_CHART_LEGEND_FONT_COLOR:
    "STACKED_COLUMN_CHART_LEGEND_FONT_COLOR",
  STACKED_COLUMN_CHART_LEGEND_VERTICAL_ALIGN:
    "STACKED_COLUMN_CHART_LEGEND_VERTICAL_ALIGN",
  STACKED_COLUMN_CHART_LEGEND_MAX_CHARACTER: 
    "STACKED_COLUMN_CHART_LEGEND_MAX_CHARACTER",
  STACKED_COLUMN_CHART_SHOW_X_AXIS_LINE:
    "STACKED_COLUMN_CHART_SHOW_X_AXIS_LINE",
  STACKED_COLUMN_CHART_SHOW_X_AXIS_NAME:
    "STACKED_COLUMN_CHART_SHOW_X_AXIS_NAME",
  STACKED_COLUMN_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "STACKED_COLUMN_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  STACKED_COLUMN_CHART_X_AXIS_NAME_VERTICAL_ALIGN:
    "STACKED_COLUMN_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT:
    "STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT",
  STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY:
    "STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY",
  STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_SIZE:
    "STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_SIZE",
  STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_COLOR:
    "STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_COLOR",
  STACKED_COLUMN_CHART_X_AXIS_NAME_TEXT:
    "STACKED_COLUMN_CHART_X_AXIS_NAME_TEXT",
  STACKED_COLUMN_CHART_SHOW_Y_AXIS_NAME:
    "STACKED_COLUMN_CHART_SHOW_Y_AXIS_NAME",
  STACKED_COLUMN_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "STACKED_COLUMN_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  STACKED_COLUMN_CHART_Y_AXIS_NAME_VERTICAL_ALIGN:
    "STACKED_COLUMN_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  STACKED_COLUMN_CHART_Y_AXIS_NAME_LOCATION:
    "STACKED_COLUMN_CHART_Y_AXIS_NAME_LOCATION",
  STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT:
    "STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY:
    "STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY",
  STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE:
    "STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE",
  STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR:
    "STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR",
  STACKED_COLUMN_CHART_Y_AXIS_NAME_TEXT:
    "STACKED_COLUMN_CHART_Y_AXIS_NAME_TEXT",
  STACKED_COLUMN_CHART_SHOW_X_AXIS_LABEL:
    "STACKED_COLUMN_CHART_SHOW_X_AXIS_LABEL",
  STACKED_COLUMN_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "STACKED_COLUMN_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  STACKED_COLUMN_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "STACKED_COLUMN_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT:
    "STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY:
    "STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY",
  STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE:
    "STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE",
  STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR:
    "STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR",
    STACKED_COLUMN_CHART_X_AXIS_GRID_LINE_COLOR:
    "STACKED_COLUMN_CHART_X_AXIS_GRID_LINE_COLOR",
  STACKED_COLUMN_CHART_SHOW_Y_AXIS_LABEL:
    "STACKED_COLUMN_CHART_SHOW_Y_AXIS_LABEL",
  STACKED_COLUMN_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "STACKED_COLUMN_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  STACKED_COLUMN_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "STACKED_COLUMN_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT:
    "STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY:
    "STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE:
    "STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE",
  STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR:
    "STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR",
  STACKED_COLUMN_CHART_X_AXIS_LABEL_ROTATE:
    "STACKED_COLUMN_CHART_X_AXIS_LABEL_ROTATE",
  STACKED_COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER:
    "STACKED_COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER",
  STACKED_COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE:
    "STACKED_COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE",
  STACKED_COLUMN_CHART_Y_AXIS_MIN: "STACKED_COLUMN_CHART_Y_AXIS_MIN",
  STACKED_COLUMN_CHART_Y_AXIS_POSITION: "STACKED_COLUMN_CHART_Y_AXIS_POSITION",
  STACKED_COLUMN_CHART_Y_AXIS_SHOW_LINE:
    "STACKED_COLUMN_CHART_Y_AXIS_SHOW_LINE",
  STACKED_COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR:
    "STACKED_COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR",
  STACKED_COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS:
    "STACKED_COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  STACKED_COLUMN_CHART_SELECTED_COLOR_PALETTE:
    "STACKED_COLUMN_CHART_SELECTED_COLOR_PALETTE",

  STACKED_COLUMN_CHART_AUTO_REFRESH: "STACKED_COLUMN_CHART_AUTO_REFRESH",
  STACKED_COLUMN_CHART_AUTO_REFRESH_CUSTOM:
    "STACKED_COLUMN_CHART_AUTO_REFRESH_CUSTOM",

  STACKED_COLUMN_CHART_DYNAMIC_TITLE_VALUE:
    "STACKED_COLUMN_CHART_DYNAMIC_TITLE_VALUE",
  STACKED_COLUMN_CHART_DYNAMIC_TITLE_SHOW_VALUE:
    "STACKED_COLUMN_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  STACKED_COLUMN__CHART_GROUP_MULTIPLE_VALUES: "STACKED_COLUMN__CHART_GROUP_MULTIPLE_VALUES",
  STACKED_COLUMN_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "STACKED_COLUMN_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  STACKED_COLUMN_CHART_ALLOW_DRILL_THROUGH: "STACKED_COLUMN_CHART_ALLOW_DRILL_THROUGH"
});

export const STACKED_BAR_CHART_PROPERTIES_ENUM = Object.freeze({
  STACKED_BAR_CHART_SHOW_VALUE_LABEL: "STACKED_BAR_CHART_SHOW_VALUE_LABEL",
  STACKED_BAR_CHART_SHOW_PERCENT_TYPE: "STACKED_BAR_CHART_SHOW_PERCENT_TYPE",
  STACKED_BAR_CHART_SHOW_HUNDRED_PERCENT:
    "STACKED_BAR_CHART_SHOW_HUNDRED_PERCENT",
  STACKED_BAR_CHART_SHOW_ONLY: "STACKED_BAR_CHART_SHOW_ONLY",
  STACKED_BAR_CHART_SHOW_INSIGHT: "STACKED_BAR_CHART_SHOW_INSIGHT",
  STACKED_BAR_CHART_SHOW_SQL: "STACKED_BAR_CHART_SHOW_SQL",
  STACKED_BAR_CHART_VALUE_LABEL_FONT_FAMILY:
    "STACKED_BAR_CHART_VALUE_LABEL_FONT_FAMILY",
  STACKED_BAR_CHART_VALUE_LABEL_FONT_WEIGHT:
    "STACKED_BAR_CHART_VALUE_LABEL_FONT_WEIGHT",
  STACKED_BAR_CHART_VALUE_LABEL_FONT_SIZE:
    "STACKED_BAR_CHART_VALUE_LABEL_FONT_SIZE",
  STACKED_BAR_CHART_VALUE_LABEL_FONT_COLOR:
    "STACKED_BAR_CHART_VALUE_LABEL_FONT_COLOR",
  STACKED_BAR_CHART_SHOW_LEGEND: "STACKED_BAR_CHART_SHOW_LEGEND",
  STACKED_BAR_CHART_LEGEND_POSITION: "STACKED_BAR_CHART_LEGEND_POSITION",
  STACKED_BAR_CHART_LEGEND_FONT_FAMILY: "STACKED_BAR_CHART_LEGEND_FONT_FAMILY",
  STACKED_BAR_CHART_LEGEND_FONT_WEIGHT: "STACKED_BAR_CHART_LEGEND_FONT_WEIGHT",
  STACKED_BAR_CHART_LEGEND_FONT_SIZE: "STACKED_BAR_CHART_LEGEND_FONT_SIZE",
  STACKED_BAR_CHART_LEGEND_FONT_COLOR: "STACKED_BAR_CHART_LEGEND_FONT_COLOR",
  STACKED_BAR_CHART_LEGEND_VERTICAL_ALIGN:
    "STACKED_BAR_CHART_LEGEND_VERTICAL_ALIGN",
  STACKED_BAR_CHART_LEGEND_MAX_CHARACTER: 
    "STACKED_BAR_CHART_LEGEND_MAX_CHARACTER",
  STACKED_BAR_CHART_SHOW_X_AXIS_NAME: "STACKED_BAR_CHART_SHOW_X_AXIS_NAME",
  STACKED_BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN:
    "STACKED_BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN",
  STACKED_BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN:
    "STACKED_BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN",
  STACKED_BAR_CHART_X_AXIS_NAME_FONT_WEIGHT:
    "STACKED_BAR_CHART_X_AXIS_NAME_FONT_WEIGHT",
  STACKED_BAR_CHART_X_AXIS_NAME_FONT_FAMILY:
    "STACKED_BAR_CHART_X_AXIS_NAME_FONT_FAMILY",
  STACKED_BAR_CHART_X_AXIS_NAME_FONT_SIZE:
    "STACKED_BAR_CHART_X_AXIS_NAME_FONT_SIZE",
  STACKED_BAR_CHART_X_AXIS_NAME_FONT_COLOR:
    "STACKED_BAR_CHART_X_AXIS_NAME_FONT_COLOR",
  STACKED_BAR_CHART_X_AXIS_NAME_TEXT: "STACKED_BAR_CHART_X_AXIS_NAME_TEXT",
  STACKED_BAR_CHART_Y_AXIS_GRID_LINE_COLOR: "STACKED_BAR_CHART_Y_AXIS_GRID_LINE_COLOR",

  STACKED_BAR_CHART_SHOW_Y_AXIS_NAME: "STACKED_BAR_CHART_SHOW_Y_AXIS_NAME",
  STACKED_BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN:
    "STACKED_BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN",
  STACKED_BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN:
    "STACKED_BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN",
  STACKED_BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT:
    "STACKED_BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT",
  STACKED_BAR_CHART_Y_AXIS_NAME_FONT_FAMILY:
    "STACKED_BAR_CHART_Y_AXIS_NAME_FONT_FAMILY",
  STACKED_BAR_CHART_Y_AXIS_NAME_FONT_SIZE:
    "STACKED_BAR_CHART_Y_AXIS_NAME_FONT_SIZE",
  STACKED_BAR_CHART_Y_AXIS_NAME_FONT_COLOR:
    "STACKED_BAR_CHART_Y_AXIS_NAME_FONT_COLOR",
  STACKED_BAR_CHART_Y_AXIS_NAME_TEXT: "STACKED_BAR_CHART_Y_AXIS_NAME_TEXT",

  STACKED_BAR_CHART_SHOW_X_AXIS_LABEL: "STACKED_BAR_CHART_SHOW_X_AXIS_LABEL",
  STACKED_BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN:
    "STACKED_BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN",
  STACKED_BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN:
    "STACKED_BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN",
  STACKED_BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT:
    "STACKED_BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT",
  STACKED_BAR_CHART_X_AXIS_LABEL_FONT_FAMILY:
    "STACKED_BAR_CHART_X_AXIS_LABEL_FONT_FAMILY",
  STACKED_BAR_CHART_X_AXIS_LABEL_FONT_SIZE:
    "STACKED_BAR_CHART_X_AXIS_LABEL_FONT_SIZE",
  STACKED_BAR_CHART_X_AXIS_LABEL_FONT_COLOR:
    "STACKED_BAR_CHART_X_AXIS_LABEL_FONT_COLOR",

  STACKED_BAR_CHART_SHOW_Y_AXIS_LABEL: "STACKED_BAR_CHART_SHOW_Y_AXIS_LABEL",
  STACKED_BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN:
    "STACKED_BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN",
  STACKED_BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN:
    "STACKED_BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN",
  STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT:
    "STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT",
  STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY:
    "STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY",
  STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_SIZE:
    "STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_SIZE",
  STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_COLOR:
    "STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_COLOR",
  STACKED_BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER:
    "STACKED_BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER",
  STACKED_BAR_CHART_X_AXIS_LABEL_ROTATE:
    "STACKED_BAR_CHART_X_AXIS_LABEL_ROTATE",
  STACKED_BAR_CHART_X_AXIS_LOGARITHMIC_SCALE:
    "STACKED_BAR_CHART_X_AXIS_LOGARITHMIC_SCALE",
  STACKED_BAR_CHART_X_AXIS_MIN: "STACKED_BAR_CHART_X_AXIS_MIN",
  STACKED_BAR_CHART_Y_AXIS_POSITION: "STACKED_BAR_CHART_Y_AXIS_POSITION",
  STACKED_BAR_CHART_Y_AXIS_SHOW_LINE: "STACKED_BAR_CHART_Y_AXIS_SHOW_LINE",
  STACKED_BAR_CHART_Y_AXIS_LINE_STYLE_COLOR:
    "STACKED_BAR_CHART_Y_AXIS_LINE_STYLE_COLOR",
  STACKED_BAR_CHART_Y_AXIS_NAME_LOCATION:
    "STACKED_BAR_CHART_Y_AXIS_NAME_LOCATION",
  STACKED_BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS:
    "STACKED_BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS",
  STACKED_BAR_CHART_SELECTED_COLOR_PALETTE:
    "STACKED_BAR_CHART_SELECTED_COLOR_PALETTE",

  STACKED_BAR_CHART_AUTO_REFRESH: "STACKED_BAR_CHART_AUTO_REFRESH",
  STACKED_BAR_CHART_AUTO_REFRESH_CUSTOM:
    "STACKED_BAR_CHART_AUTO_REFRESH_CUSTOM",

  STACKED_BAR_CHART_DYNAMIC_TITLE_VALUE:
    "STACKED_BAR_CHART_DYNAMIC_TITLE_VALUE",
  STACKED_BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE:
    "STACKED_BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  STACKED_BAR_CHART_GROUP_MULTIPLE_VALUES:
    "STACKED_BAR_CHART_GROUP_MULTIPLE_VALUES",
  STACKED_BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "STACKED_BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
  STACKED_BAR_CHART_ALLOW_DRILL_THROUGH: "STACKED_BAR_CHART_ALLOW_DRILL_THROUGH"
});

export const GEO_MAP_CHART_PROPERTIES_ENUM = Object.freeze({
  // VALUE LABELS
  GEO_MAP_CHART_SHOW_VALUE_LABEL: "GEO_MAP_CHART_SHOW_VALUE_LABEL",
  GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS:
    "GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS",
  GEO_MAP_CHART_SHOW_ONLY: "GEO_MAP_CHART_SHOW_ONLY",
  GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY:
    "GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY",
  GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT:
    "GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT",
  GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE: "GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE",
  GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR: "GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR",
  // BORDER SETTINGS
  GEO_MAP_CHART_BORDER_COLOR: "GEO_MAP_CHART_BORDER_COLOR",
  // MAP SETTINGS
  GEO_MAP_CHART_SELECTED_COUNTRY: "GEO_MAP_CHART_SELECTED_COUNTRY",
  // VISUAL MAP
  GEO_MAP_CHART_SHOW_VISUALMAP: "GEO_MAP_CHART_SHOW_VISUALMAP",
  GEO_MAP_CHART_VISUALMAP_TYPE: "GEO_MAP_CHART_VISUALMAP_TYPE",
  GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN:
    "GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN",
  GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN:
    "GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN",
  GEO_MAP_CHART_VISUALMAP_FONT_SIZE: "GEO_MAP_CHART_VISUALMAP_FONT_SIZE",
  GEO_MAP_CHART_VISUALMAP_FONT_FAMILY: "GEO_MAP_CHART_VISUALMAP_FONT_FAMILY",
  GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT: "GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT",
  GEO_MAP_CHART_VISUALMAP_FONT_COLOR: "GEO_MAP_CHART_VISUALMAP_FONT_COLOR",
  GEO_MAP_CHART_VISUALMAP_WIDTH: "GEO_MAP_CHART_VISUALMAP_WIDTH",
  GEO_MAP_CHART_VISUALMAP_VALUES: "GEO_MAP_CHART_VISUALMAP_VALUES",
  GEO_MAP_CHART_SELECTED_COLOR_PALETTE: "GEO_MAP_CHART_SELECTED_COLOR_PALETTE",

  GEO_MAP_CHART_AUTO_REFRESH: "GEO_MAP_CHART_AUTO_REFRESH",
  GEO_MAP_CHART_AUTO_REFRESH_CUSTOM: "GEO_MAP_CHART_AUTO_REFRESH_CUSTOM",

  GEO_MAP_CHART_DYNAMIC_TITLE_VALUE: "GEO_MAP_CHART_DYNAMIC_TITLE_VALUE",
  GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE:
    "GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  GEO_MAP_CHART_GROUP_MULTIPLE_VALUES:
    "GEO_MAP_CHART_GROUP_MULTIPLE_VALUES",
  GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",

  GEO_MAP_CHART_SHOW_INSIGHT: "GEO_MAP_CHART_SHOW_INSIGHT",
  GEO_MAP_CHART_SHOW_SQL: "GEO_MAP_CHART_SHOW_SQL",
});

export const LABEL_CHART_PROPERTIES_ENUM = Object.freeze({
  LABEL_CHART_DYNAMIC_TITLE_VALUE: "LABEL_CHART_DYNAMIC_TITLE_VALUE",
  LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE: "LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE",
  LABEL_CHART_GROUP_MULTIPLE_VALUES: "LABEL_CHART_GROUP_MULTIPLE_VALUES",
  LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT",
});

export const cardPropertiesEnum = Object.freeze(
  _.pick(allPropertiesKeys, [
    allPropertiesKeys.HAS_TITLE,
    allPropertiesKeys.HAS_SUBTITLE,
    allPropertiesKeys.HAS_FOOTNOTE,
    allPropertiesKeys.HAS_BORDER,
    allPropertiesKeys.BORDER_RADIUS,
    allPropertiesKeys.HAS_DROP_SHADOW,
    allPropertiesKeys.BORDER_COLOR,
    allPropertiesKeys.BACKGROUND_COLOR,
    allPropertiesKeys.POSITION_X,
    allPropertiesKeys.POSITION_Y,
    allPropertiesKeys.CARD_W,
    allPropertiesKeys.CARD_H,
    allPropertiesKeys.TITLE_FONT_FAMILY,
    allPropertiesKeys.TITLE_FONT_SIZE,
    allPropertiesKeys.TITLE_FONT_WEIGHT,
    allPropertiesKeys.TITLE_ALIGN_ITEM,
    allPropertiesKeys.DESCRIPTION_FONT_FAMILY,
    allPropertiesKeys.DESCRIPTION_FONT_SIZE,
    allPropertiesKeys.DESCRIPTION_ALIGN_ITEM,
    allPropertiesKeys.DESCRIPTION_FONT_WEIGHT,
    allPropertiesKeys.FOOTNOTE_FONT_FAMILY,
    allPropertiesKeys.FOOTNOTE_FONT_SIZE,
    allPropertiesKeys.FOOTNOTE_FONT_WEIGHT,
    allPropertiesKeys.TITLE_FONT_COLOR,
    allPropertiesKeys.DESCRIPTION_FONT_COLOR,
    allPropertiesKeys.FOOTNOTE_FONT_COLOR,
    allPropertiesKeys.CUSTOM_ENDPOINT,
  ])
);

export const dashboardGridPropertiesEnum = Object.freeze(
  _.pick(allPropertiesKeys, [
    allPropertiesKeys.DASHBOARD_GRID_BACKGROUND_COLOR,
    allPropertiesKeys.DASHBOARD_GRID_DESIGN_MODE,
    allPropertiesKeys.DASHBOARD_GRID_HAS_GRID_LINES,
    allPropertiesKeys.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE,
    allPropertiesKeys.DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE,
    allPropertiesKeys.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE,
    allPropertiesKeys.DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE,
    allPropertiesKeys.DASHBOARD_GRID_FILTER_PANE_POSITION,
    allPropertiesKeys.DASHBOARD_GRID_FILTER_PANE_WIDTH,
    allPropertiesKeys.DASHBOARD_GRID_FILTER_PANE_HEIGHT,
  ])
);

export const commonCardPropertiesEnum = Object.freeze(
  _.pick(allPropertiesKeys, [
    allPropertiesKeys.HAS_TITLE,
    allPropertiesKeys.TITLE_FONT_FAMILY,
    allPropertiesKeys.HAS_SUBTITLE,
    allPropertiesKeys.DESCRIPTION_FONT_FAMILY,
    allPropertiesKeys.HAS_FOOTNOTE,
    allPropertiesKeys.FOOTNOTE_FONT_FAMILY,
    allPropertiesKeys.HAS_BORDER,
    allPropertiesKeys.BORDER_RADIUS,
    allPropertiesKeys.BORDER_COLOR,
    allPropertiesKeys.HAS_DROP_SHADOW,
    allPropertiesKeys.BACKGROUND_COLOR,
    allPropertiesKeys.SELECTED_TEMPLATE_ID,
  ])
);

export const tabsPropertiesEnum = Object.freeze(
  _.pick(allPropertiesKeys, [
    allPropertiesKeys.HAS_TABS,
    allPropertiesKeys.TABS_FONT_FAMILY,
    allPropertiesKeys.TABS_FONT_COLOR,
    allPropertiesKeys.TABS_BACKGROUND_COLOR,
  ])
);

export const pageSizePropertiesEnum = Object.freeze(
  _.pick(allPropertiesKeys, [
    allPropertiesKeys.PAGE_SIZE,
    allPropertiesKeys.WIDTH,
    allPropertiesKeys.HEIGHT,
  ])
);

export const cssStylePropertiesEnum = Object.freeze(
  _.pick(allPropertiesKeys, [
    allPropertiesKeys.FONT_FAMILY,
    allPropertiesKeys.FONT_SIZE,
    allPropertiesKeys.FONT_WEIGHT,
    allPropertiesKeys.COLOR,
    allPropertiesKeys.BACKGROUND_COLOR,
    allPropertiesKeys.BORDER,
  ])
);

export const dashboardCardPropertiesCssKey = {
  [allPropertiesKeys.BACKGROUND_COLOR]: "backgroundColor",
  [allPropertiesKeys.BORDER_COLOR]: "borderColor",
  [allPropertiesKeys.BORDER_RADIUS]: "borderRadius",
};
export const dashboardCardTitlePropertiesCssKey = {
  [allPropertiesKeys.TITLE_FONT_FAMILY]: "fontFamily",
  [allPropertiesKeys.TITLE_FONT_COLOR]: "color",
  [allPropertiesKeys.TITLE_FONT_SIZE]: "fontSize",
  [allPropertiesKeys.TITLE_FONT_WEIGHT]: "fontWeight",
  [allPropertiesKeys.TITLE_ALIGN_ITEM]: "textAlign",
};
export const dashboardCardDescriptionPropertiesCssKey = {
  [allPropertiesKeys.DESCRIPTION_FONT_FAMILY]: "fontFamily",
  [allPropertiesKeys.DESCRIPTION_FONT_COLOR]: "color",
  [allPropertiesKeys.DESCRIPTION_FONT_SIZE]: "fontSize",
  [allPropertiesKeys.DESCRIPTION_FONT_WEIGHT]: "fontWeight",
  [allPropertiesKeys.DESCRIPTION_ALIGN_ITEM]: "textAlign",
};
export const dashboardCardFootnotePropertiesCssKey = {
  [allPropertiesKeys.FOOTNOTE_FONT_FAMILY]: "fontFamily",
  [allPropertiesKeys.FOOTNOTE_FONT_COLOR]: "color",
  [allPropertiesKeys.FOOTNOTE_FONT_SIZE]: "fontSize",
  [allPropertiesKeys.FOOTNOTE_FONT_WEIGHT]: "fontWeight",
};

export const propertiesKeyToCSSKeyMapping = {
  [allPropertiesKeys.BORDER_RADIUS]: "borderRadius",
  [allPropertiesKeys.BORDER_COLOR]: "borderColor",
  [allPropertiesKeys.BACKGROUND_COLOR]: "backgroundColor",
  [allPropertiesKeys.TABS_FONT_FAMILY]: "fontFamily",
  [allPropertiesKeys.TABS_FONT_COLOR]: "color",
  [allPropertiesKeys.TABS_BACKGROUND_COLOR]: "backgroundColor",
  [allPropertiesKeys.HEIGHT]: "height",
  [allPropertiesKeys.WIDTH]: "width",
};
export const SELECTION_ACTION_KEYS = [
  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SELECTION_ACTION,
  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SELECTION_ACTION,
  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SELECTION_ACTION,
  allPropertiesKeys.CHART_SELECTION_ACTION,
  TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_SELECTION_ACTION,
];

export const BUTTON_PROPERTIES_ENUM = Object.freeze({
  BUTTON_CAPTION_LABEL: "BUTTON_CAPTION_LABEL",
  BUTTON_CAPTION_FONT_FAMILY: "BUTTON_CAPTION_FONT_FAMILY",
  BUTTON_CAPTION_FONT_WEIGHT: "BUTTON_CAPTION_FONT_WEIGHT",
  BUTTON_CAPTION_FONT_SIZE: "BUTTON_CAPTION_FONT_SIZE",
  BUTTON_CAPTION_FONT_COLOR: "BUTTON_CAPTION_FONT_COLOR",
  BUTTON_CAPTION_BACKGROUND_COLOR: "BUTTON_CAPTION_BACKGROUND_COLOR",
  BUTTON_CAPTION_BORDER_RADIUS: "BUTTON_CAPTION_BORDER_RADIUS",
  
  BUTTON_ACTION_SELECT_VALUE: "BUTTON_ACTION_SELECT_VALUE",
  BUTTON_ACTION_URL_VALUE: "BUTTON_ACTION_URL_VALUE",
  BUTTON_ACTION_APPLY_TO_TABS: "BUTTON_ACTION_APPLY_TO_TABS",

  BUTTON_DIALOG_SHOW_TITLE: "BUTTON_DIALOG_SHOW_TITLE",
  BUTTON_DIALOG_SHOW_SUB_TITLE: "BUTTON_DIALOG_SHOW_SUB_TITLE",
  BUTTON_DIALOG_SHOW_BORDER: "BUTTON_DIALOG_SHOW_BORDER",
  BUTTON_DIALOG_BORDER_COLOR: "BUTTON_DIALOG_BORDER_COLOR",
  BUTTON_DIALOG_BORDER_RADIUS: "BUTTON_DIALOG_BORDER_RADIUS",
  BUTTON_DIALOG_BACKGROUND_COLOR: "BUTTON_DIALOG_BACKGROUND_COLOR",

  BUTTON_DIALOG_TITLE: "BUTTON_DIALOG_TITLE",
  BUTTON_DIALOG_SUBTITLE: "BUTTON_DIALOG_SUBTITLE",
  BUTTON_DIALOG_CONTENT: "BUTTON_DIALOG_CONTENT",
  BUTTON_DIALOG_TITLE_FONT_COLOR: "BUTTON_DIALOG_TITLE_FONT_COLOR",
  BUTTON_DIALOG_TITLE_FONT_FAMILY: "BUTTON_DIALOG_TITLE_FONT_FAMILY",
  BUTTON_DIALOG_TITLE_FONT_SIZE: "BUTTON_DIALOG_TITLE_FONT_SIZE",
  BUTTON_DIALOG_TITLE_FONT_WEIGHT: "BUTTON_DIALOG_TITLE_FONT_WEIGHT",
  BUTTON_DIALOG_TITLE_ALIGN: "BUTTON_DIALOG_TITLE_ALIGN",

  BUTTON_DIALOG_SUB_TITLE_FONT_COLOR: "BUTTON_DIALOG_SUB_TITLE_FONT_COLOR",
  BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY: "BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY",
  BUTTON_DIALOG_SUB_TITLE_FONT_SIZE: "BUTTON_DIALOG_SUB_TITLE_FONT_SIZE",
  BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT: "BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT",
  BUTTON_DIALOG_SUB_TITLE_ALIGN: "BUTTON_DIALOG_SUB_TITLE_ALIGN",

  BUTTON_DIALOG_CONTENT_FONT_COLOR: "BUTTON_DIALOG_CONTENT_FONT_COLOR",
  BUTTON_DIALOG_CONTENT_FONT_FAMILY: "BUTTON_DIALOG_CONTENT_FONT_FAMILY",
  BUTTON_DIALOG_CONTENT_FONT_SIZE: "BUTTON_DIALOG_CONTENT_FONT_SIZE",
  BUTTON_DIALOG_CONTENT_FONT_WEIGHT: "BUTTON_DIALOG_CONTENT_FONT_WEIGHT",
  BUTTON_DIALOG_CONTENT_ALIGN: "BUTTON_DIALOG_CONTENT_ALIGN",
  BUTTON_DIALOG_CONTENT_WRAP_TEXT: "BUTTON_DIALOG_CONTENT_WRAP_TEXT",

  BUTTON_DIALOG_CARD_WIDTH: "BUTTON_DIALOG_CARD_WIDTH",
  BUTTON_DIALOG_CARD_HEIGHT: "BUTTON_DIALOG_CARD_HEIGHT",
});
