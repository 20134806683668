import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../../Visualize/General/types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visConfiguration = {
  state: {
    [STATE.CONFIGURATIONS]: [],
  },
  getters: {
    [GETTER.GET_CONFIGURATIONS]: (state) => {
      return state[STATE.CONFIGURATIONS];
    },
  },
  actions: {
    [ACTION.FETCH_CONFIGURATIONS]: async ({ commit }) => {
     return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Configuration,
        serviceMethodSub: serviceMethodSub.readConfigurations,
        successMutation: MUTATION.SET_CONFIGURATIONS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.CREATE_CONFIGURATION]: async ({ commit, dispatch }, payload) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Configuration,
        serviceMethodSub: serviceMethodSub.createConfigurations,
        bodyParam: payload,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });

      await dispatch(ACTION.FETCH_CONFIGURATIONS);
    },
    [ACTION.UPDATE_CONFIGURATION]: async (
      { commit },
      { queryParam, bodyParam }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Configuration,
        serviceMethodSub: serviceMethodSub.updateConfigurations,
        queryParam,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
  },
  mutations: {
    [MUTATION.SET_CONFIGURATIONS](state, items) {
      Vue.set(state, [STATE.CONFIGURATIONS], items);
    },
  },
};
