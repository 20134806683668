<template>
  <li
    class="vis-flex--spacebeetwen-Ycenter vis-list-group-item-selected"
    :class="[
      { 'vis-cursor-pointer': fieldItem.isAuthorize },
      {
        'vis-selected-border': addBorderToSelectedField,
      },
      dashboardFilterItemStyle,
      unAuthorizedFieldClass,
    ]"
    @click="onItemClicked(fieldItem)"
  >
    <div class="vis-flex--Ycenter">
      <i :class="getFieldIconClassName(fieldItem)" aria-hidden="true"></i>
      <el-tooltip
        class="item"
        effect="primary"
        :content="fieldItem.screenTip"
        placement="right-start"
        :disabled="!fieldItem.screenTip"
      >
        <el-tooltip
          class="item"
          placement="bottom"
          popper-class="vis-bg-neon-blue"
          :open-delay="2000"
          :disabled="!fieldItem.isAuthorize"
        >
          <!-- FIELD INFO START -->
          <div
            slot="content"
            class="vis-mx--05 vis-display-flex vis-flex-direction--column"
          >
            <span class="vis-color-quartz vis-text--xsmall">{{
              $t("generalPages.alias")
            }}</span>
            <span class="vis-color-white">{{ fieldItemAlias }}</span>
            <span class="vis-color-quartz vis-text--xsmall vis-mt--05">{{
              $t("generalPages.name")
            }}</span>
            <span class="vis-color-white">{{
              `${datasetName}.${fieldItem.field}`
            }}</span>
          </div>
          <!-- FIELD INFO END -->
          <span
            class="vis-field-item-span"
            :class="{ 'vis-sorted-field-name': fieldItem.orderType }"
            >{{ fieldItemAlias }}
          </span>
        </el-tooltip>
      </el-tooltip>
    </div>
    <div class="vis-flex--Ycenter">
      <i
        v-if="fieldOrderIconClass && fieldItem.isAuthorize"
        @click="onSortMethodSwitched"
        :class="fieldOrderIconClass + ' vis-cursor-pointer'"
        aria-hidden="true"
      ></i>
      <i
        v-if="!fieldItem.isActiveDashboardFilter"
        @click.stop="openContextMenu($event, { item: fieldItem, index: fieldIndex })"
        class="vis-cursor-pointer vis-text--right"
        :class="customIcon.VerticalThreeDot"
        aria-hidden="true"
      >
      </i>
      <!-- Filter box içerisinde çıkan icon -->
      <el-popover
        v-if="fieldItem.isActiveDashboardFilter"
        placement="top-start"
        trigger="hover"
        :content="$t('generalPages.dashboardFilterInfoText')"
      >
        <i
          slot="reference"
          class="vis-cursor-pointer vis-text--xsmall vis-vertical-align-middle"
          :class="customIcon.Information"
          aria-hidden="true"
        ></i>
      </el-popover>
      <!-- Filter box içerisinde çıkan icon end -->
    </div>
    <!-- Table componentinde ejs-contextmenu PanelColumnsBox içerisinde kullanılıyor. -->
    <ejs-contextmenu
      v-if="isCommonUsage"
      :id="contextMenuId"
      :items="contextMenuItems"
      :select="setSelectType"
      :beforeItemRender="beforeContextItemHighlight"
    ></ejs-contextmenu>
  </li>
</template>
<script>
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
import { CustomIcon } from "../../assets/js/custom-icons";
import { getIconByFieldUsageType } from "../../util/dataset-fields/datasetFields";
export default {
  props: {
    contextMenuId: {
      type: String,
    },
    fieldItem: {
      type: Object,
      required: true,
    },
    fieldIndex: {
      type: Number,
      required: true,
    },
    contextMenuItems: {
      type: Array,
    },
    isCommonUsage: {
      type: Boolean,
      default: true,
    },
    datasetName: {
      type: String,
    },
    isColumnBasedPropertiesSelected: {
      type: Boolean,
    },
    selectedColumnField: {
      type: Object,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  computed: {
    dashboardFilterItemStyle() {
      return this.fieldItem.isActiveDashboardFilter
        ? "vis-dashboard-filter"
        : "";
    },
    fieldOrderIconClass() {
      if (!this.fieldItem?.orderType) return null;

      switch (this.fieldItem?.orderType) {
        case DatamodelContextDefaults.BARS.ASC:
          return CustomIcon.ExpandUp;
        case DatamodelContextDefaults.BARS.DESC:
          return CustomIcon.ExpandDown;
        default:
          return "";
      }
    },
    addBorderToSelectedField() {
      return (
        this.isColumnBasedPropertiesSelected &&
        this.selectedColumnField === this.fieldItem
      );
    },
    unAuthorizedFieldClass() {
      return !this.fieldItem?.isAuthorize ? "vis-unauthorized-field" : "";
    },
    fieldItemAlias() {
      return !this.fieldItem?.isAuthorize
        ? this.$t('Unauthorized')
        : this.fieldItem.alias;
    },
  },
  methods: {
    onItemClicked(fieldItem) {
      if (!fieldItem?.isAuthorize) return;
      
      this.$emit('onItemClicked', fieldItem);
    },
    openContextMenu(value, params) {
      this.$emit("openContextMenu", value, params);
    },
    setSelectType(args) {
      this.$emit("setSelectType", args);
    },
    beforeContextItemHighlight(args) {
      this.$emit("beforeContextItemHighlight", args);
    },
    getFieldIconClassName(element) {
      return getIconByFieldUsageType(element);
    },
    onSortMethodSwitched() {
      this.$emit(
        "onSortMethodSwitched",
        this.fieldItem.orderType === DatamodelContextDefaults.BARS.ASC
          ? DatamodelContextDefaults.BARS.DESC
          : DatamodelContextDefaults.BARS.ASC
      );
    },
  },
};
</script>
<style scoped>
.vis-sorted-field-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 84px;
}
.vis-selected-border {
  border: 3px solid var(--primary-lighteen-1) !important;
}
</style>
