/* eslint-disable */
import { ACTION } from "./types";

import { MUTATION_GENERAL } from "../General/types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const visChatGpt = {
    actions: {
        [ACTION.FETCH_CHAT_GPT_INSIGHTS]: async ({ commit }, { loadingComponent, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ChatGpt,
                    serviceMethodSub: serviceMethodSub.readChatGptInsights,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: false,
                    loadingComponent
                },
            );
        },
        [ACTION.FETCH_CHAT_GPT_SUMMARY]: async ({ commit }, { queryParam, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ChatGpt,
                    serviceMethodSub: serviceMethodSub.readChatGptSummary,
                    bodyParam,
                    queryParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: false
                },
            );
        },
        [ACTION.FETCH_CHAT_GPT_CHATBOT]: async ({ commit }, { loadingComponent, queryParam, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ChatGpt,
                    serviceMethodSub: serviceMethodSub.readChatGptChatBot,
                    bodyParam,
                    queryParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: false,
                    loadingComponent
                },
            );
        },
        [ACTION.GENERATE_AI]: async ({ commit }, { loadingComponent, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.ChatGpt,
                    serviceMethodSub: serviceMethodSub.generateAI,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: false,
                    loadingComponent
                },
            );
        },
    },
};
