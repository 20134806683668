<template>
  <div class="vis-simulate-scenario-popup">
    <div
      class="vis-simulate-scenario-popup-header vis-row-box vis-flex--spacebeetwen-Ycenter"
    >
      <h3>{{ $t("Simulation Data") }}</h3>
    </div>

    <div class="vis-simulate-scenario-popup-header-description">
      <div>
        {{ $t("Select event and enter simulation data") }}
      </div>
    </div>

    <div class="vis-simulate-scenario-popup-body">
      <div class="scenario-aside-properties-event-tab">
        <div class="vis-mt--1 vis-mb--1">
          <el-row class="vis-mb--1 vis-mt--1" :gutter="20">
            <el-col :span="12">
              <label class="label-disabled">{{ $t("Event") }}</label>
              <div>
                <el-select
                  :value="formData.name"
                  :placeholder="$t('Event')"
                  @change="setEvent"
                >
                  <el-option
                    v-for="item in events"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select></div
            ></el-col>
            <el-col :span="12">
              <label class="label-disabled">{{ $t("Description") }}</label>
              <div>
                <el-input
                  :value="formData.description"
                  @input="(v) => setDescription(v)"
                /></div
            ></el-col>
          </el-row>
        </div>

        <div v-if="formData.fields.length" class="vis-mb--1">
          <label class="vis-font-bold">{{ $t("Fields") }}</label>
          <el-row
            v-for="(field, index) in formData.fields"
            :key="field.field"
            class="vis-mb--1 vis-mt--1"
            :gutter="20"
          >
            <el-col :span="8">
              <label v-if="index === 0" class="label-disabled">{{
                $t("Name")
              }}</label>
              <div>
                <el-input :value="field.field" :readonly="true" /></div
            ></el-col>
            <el-col :span="8">
              <label v-if="index === 0" class="label-disabled">{{
                $t("Value")
              }}</label>
              <div>
                <el-input
                  v-if="field.type.toLowerCase() === fieldTypes.String"
                  :value="field.value"
                  @input="(v) => setFieldValue(field.field, v)"
                />
                <el-input
                  v-else-if="
                    field.type.toLowerCase() === fieldTypes.Long ||
                    field.type.toLowerCase() === fieldTypes.Number
                  "
                  :value="field.value"
                  type="number"
                  @input="(v) => setFieldValue(field.field, v)"
                />
                <el-date-picker
                  v-else-if="field.type.toLowerCase() === fieldTypes.Date"
                  :value="field.value"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="dd-MM-yyyy"
                  :placeholder="$t('filterComponents.selectDate')"
                  @input="(v) => setFieldValue(field.field, v)"
                /></div
            ></el-col>
            <el-col :span="8">
              <label v-if="index === 0" class="label-disabled">{{
                $t("Type")
              }}</label>
              <div><el-input :value="field.type" :readonly="true" /></div
            ></el-col>
          </el-row>
        </div>
      </div>

      <div class="simulate-popup-container-footer">
        <button
          class="vis-btn medium vis-mt--2 exit-button"
          @click="$emit('cancel')"
        >
          {{ $t("Cancel") }}
        </button>
        <button class="vis-btn medium vis-mt--2 next-button" @click="confirm">
          {{ $t("Save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";

export default {
  props: {
    properties: {
      type: Object,
    },
    events: {
      type: Array,
    },
    editSimulateItemValue: {
      type: Object,
    },
  },
  data() {
    return {
      formData: {
        name: "",
        description: "",
        fields: [],
      },
      fieldTypes: {
        String: "string",
        Long: "long",
        Number: "number",
        Date: "date",
      },
    };
  },
  mounted() {
    const convertEditSimulateItemValueToForm = () => {
      this.formData.name = this.editSimulateItemValue.name;
      this.formData.description = this.editSimulateItemValue.description;
      this.formData.fields = cloneDeep(this.editSimulateItemValue.fields);
    };

    if (this.editSimulateItemValue) {
      convertEditSimulateItemValueToForm();
    }
  },
  computed: {
    selectedEvent() {
      return this.events.find((e) => e.name === this.formData.name);
    },
    selectedEventFields() {
      return this.selectedEvent?.fields ?? [];
    },
  },
  methods: {
    setEvent(name) {
      this.formData.name = name;
      this.formData.fields = cloneDeep(this.selectedEventFields);
    },
    setDescription(description) {
      this.formData.description = description;
    },
    setFieldValue(key, value) {
      const fieldValueToBeUpdated = this.formData.fields.find(
        (field) => field.field === key
      );

      this.$set(fieldValueToBeUpdated, "value", value);
    },
    confirm() {
      const convertedFieldsData = this.formData.fields.map((item) => {
        switch (item.type) {
          case "String":
            return { ...item, value: String(item.value) };
          case "Boolean":
            return { ...item, value: Boolean(item.value) };
          case "Number":
            return { ...item, value: Number(item.value) };
          case "Long":
            return { ...item, value: Number(item.value) };
          default:
            return item;
        }
      });

      if (!this.editSimulateItemValue) {
        this.$emit("save", { ...this.formData, fields: convertedFieldsData });
      } else {
        this.$emit("update", {
          id: this.editSimulateItemValue.id,
          formData: { ...this.formData, fields: convertedFieldsData },
        });
      }
    },
  },
};
</script>

<style scoped>
.vis-simulate-scenario-popup {
  z-index: 1001;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  min-height: 400px;
  background-color: #ffffff;
  border-radius: 7px;
  border: 1px solid #d8d8d8;
}
.vis-simulate-scenario-popup-header {
  border-bottom: 1px solid #dadcdd;
  padding: 6px 24px;
}
.vis-simulate-scenario-popup-header-description {
  margin: 15px 0 10px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.vis-simulate-scenario-popup-body {
  height: calc(100% - 50px);
}
.next-button {
  background: var(--primary-lighteen-1) 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: none;
  color: white;
  float: right;
  opacity: 1;
  margin-right: 50px;
  margin-bottom: 25px;
}
.exit-button {
  background: transparent;
  border: none;
  color: #474747;
  float: right;
  opacity: 1;
  font-weight: 600;
  margin-bottom: 25px;
}
.simulate-popup-container-footer {
  display: flex;
  justify-content: flex-end;
}
.scenario-aside-properties-event-tab {
  padding: 0 25px;
}
</style>
