<template>
  <div class="properties-db-load-options-container">
    <label class="vis-font-bold">{{ $t("generalPages.selectLoadBehaviour") }}</label>
    <el-select
      :value="loadBehaviour"
      :placeholder="$t('generalPages.loadBehaviour')"
      @change="(v) => $emit('loadBehaviourChanged', v)"
    >
      <el-option
        v-for="option in loadBehaviourOptions"
        :key="option.key"
        :label="$t(`${option.label}`)"
        :value="option.key"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { loadBehaviourOptions } from "../../../commons/dataModelTypes";

export default {
  props: {
    loadBehaviour: {
      type: String, //append_new_data or ...
    },
  },
  data() {
    return {
      loadBehaviourOptions: loadBehaviourOptions,
    };
  },
};
</script>

<style scoped>
.properties-db-load-options-container {
  margin-bottom: 25px;
}
</style>