<template>
  <div>
    <textarea
      @click.stop
      :value="value"
      @change="$emit('updateValue', $event, textAreaType)"
      @keyup="$emit('changeTextAreaHeight', textAreaType)"
      :id="textAreaId"
      class="no-drag"
      :class="textAreaClass"
      rows="1"
      :placeholder="$t(placeHolder)"
      :style="fontStyle"
      :disabled="isModeDashboardView || dynamicTitleShowValue"
    ></textarea>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
    fontStyle: {
      type: Object,
    },
    isModeDashboardView: {
      type: Boolean,
    },
    textAreaId: {
      type: String,
    },
    placeHolder: {
      type: String,
    },
    textAreaClass: {
      type: String,
    },
    textAreaType: {
      type: String,
    },
    dynamicTitleShowValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
