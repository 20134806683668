<template>
  <div
    class="no-drag vis-chart-filter vis-position-relative vis-filter-date"
    :class="margin"
    :id="panelData.i"
  >
    <el-date-picker
      :value="filterValueParse"
      @input="(x) => addFiltersToPanels(x, isEvent)"
      :type="datePickerType"
      format="dd/MM/yyyy"
      value-format="dd-MM-yyyy"
      :start-placeholder="$t('filterComponents.start_date')"
      :end-placeholder="$t('filterComponents.end_date')"
      :size="selectBoxSize"
      :disabled="disabledDate"
      :placeholder="$t('filterComponents.selectDate')"
      :class="requiredClass"
      :picker-options="datePickerOptions()"
      :tabindex="tabIndex"
      @blur="handleBlur"
    >
    </el-date-picker>
    <span class="vis-el-datepicker-label">{{ selectedQuickOptionLabel }}</span>
  </div>
</template>
<script>
import moment from "moment";
import {
  DATE_FILTER_PROPERTIES_ENUM,
  DefaultValue,
} from "../../../commons/dashboardProperties";
import { filterOperator } from "../../../commons/filterComponents";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import {
  checkFilterDefaultValueSelection,
  checkFilterDefaultValueNone,
  checkFilterDefaultValueDynamic
} from "../../../util/chart-filter/filterChartCommon";
import { checkSelectBoxSize } from "../../../util/dashboard-filter/dashboardChartFilter";
import { getSelectedFieldDatasetId } from "../../../util/dataset-fields/datasetFields";
import {
  changeFormatTodayDate,
  changeFormatYesterdayDate,
  changeFormatAweekagoDate,
  dateQuickOptions,
} from "../../../util/filter/filterDate.js";
import deepEqual from "deep-equal";

export default {
  mixins: [panelDataPropertiesStyle],

  props: {
    panelData: {
      type: Object,
    },
    isFilterPaneActive: {
      type: Boolean,
      default: false,
    },
    isRequiredFilterValue: {
      type: Boolean,
      default: false,
    },
    isPanelPage: {
      type: Boolean,
      default: false,
    },
    scheduleFilterValue: {
      type: Object,
      default: null,
    },
    margin: {
      type: String,
      default: "vis-mr--1",
    },
  },
  data() {
    return {
      isEvent: true,
      selectedFirstValue: null,
    };
  },
  watch: {
    //Propeties => General altındaki Default Value selectbox change edildiğinde çalışır.
    dateFilterDefaultProperty: {
      handler(val, oldVal) {
        if (checkFilterDefaultValueDynamic(oldVal)) {
          //Properties içindeki default value DYNAMIC => SELECTION VEYA NONE olarak değiştirilirse burası çalışır
          this.setStyleQuickOptionsValue({});
        }
        if (this.hasDefaultValue) {
          //field içinde default value varsa burası çalışır.
          this.addFiltersToPanels(this.parsedDefaultValue);
        } else if (checkFilterDefaultValueNone(val) || val !== oldVal) {
          //Properties içindeki default value NONE seçilirse yada NONE => SELECTION olarak değiştirilirse burası çalışır.
          this.addFiltersToPanels();
        }
        
      },
    },
    selectedField: {
      handler(val) {
        //Field Slot içinden silindiğinde, DATE_FILTER_DEFAULT_SELECTION NONE değilse default hali NONE olduğu için method çalışır
        const setDefaultSelectionPropertyNone = () => {
          if ( !val && !checkFilterDefaultValueNone(this.dateFilterDefaultProperty)) {
            this.setSelectedPanelStyle(
              DefaultValue.NONE,
              DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_SELECTION
            );
          }
        };
        //Slot içindeki field'ın rangeSelect özelliği aktif ve multipleSelection özelliği kapalı ise ALLOW_MULTISELECT disable şekilde aktif etmek için.
        //rangeSelect özelliği dataset içinde oluşturulan customFilter'dan gelir. Custom Filter'lar Dataset İçinde ayarlandığı için burdan müdahale ettirmiyoruz. Bu yüzden disable yapıyoruz.
        const setAllowMultiSelect = () => {
          if (
            val &&
            this.selectedField?.rangeSelect &&
            !this.dateFilterMultipleSelection
          ) {
            this.setSelectedPanelStyle(
              this.selectedField?.rangeSelect,
              DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT
            );
          }
        };

        setDefaultSelectionPropertyNone();
        setAllowMultiSelect();
      },
      sync: true,
    },
    dateFilterMultipleSelection: {
      handler(val) {
        if(!this.isPanelPage) return;
        //Range true ise shoqQuickOptions özelliği false yapılıyor.
        if(val && this.dateFilterShowQuickOptions && this.isPanelPage) {
          this.setSelectedPanelStyle(
              false,
              DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SHOW_QUICK_OPTIONS
            );
        }
        //Value dolu ve Range özelliği "true => false" yapıldığında çalışır.
        if(!val && Array.isArray(this.filterValueParse) && this.filterValueParse?.length > 1) {
          this.addFiltersToPanels();
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.dateFilterDefaultProperty === DefaultValue.DYNAMIC)
      this.setDynamicFilter();

    //Slot içindeki field'da default_value varsa ve default_value, seçilmiş value'ya eşit değilse burası çalışır.
    this.addFiltersToPanelsWithDefaultValue();

    //FOR LIMIT SELECTION
    this.setSelectedFirstValue();
  },
  computed: {
    parsedDefaultValue() {
      return typeof this.selectedField?.default_value === "string" &&
        this.selectedField?.rangeSelect
        ? JSON.parse(this.selectedField?.default_value)
        : this.selectedField?.default_value;
    },
    hasDefaultValue() {
      return (
        this.parsedDefaultValue &&
        checkFilterDefaultValueSelection(this.dateFilterDefaultProperty)
      );
    },
    tabIndex() {
      return this.CHART_COMPUTED_VALUES.DATE_FILTER_TAB_INDEX;
    },
    disabledDate() {
      return (
        (this.isPanelPage &&
          checkFilterDefaultValueNone(this.dateFilterDefaultProperty)) ||
        !this.panelDataDetailsMetrics?.length
      );
    },

    datePickerType() {
      return this.dateFilterMultipleSelection ? "daterange" : "date";
    },

    selectBoxSize() {
      return checkSelectBoxSize(this.isFilterPaneActive);
    },

    filterValueParse() {
      return this.scheduleFilterValue
        ? this.scheduleFilterValue?.[this.selectedField?.field]?.value
        : this.filterValue?.[this.selectedField?.field]?.value;
    },
    selectedField() {
      return this.panelDataDetailsMetrics?.[0];
    },
    requiredClass() {
      return this.dateFilterMandatorySelection &&
        this.isRequiredFilterValue &&
        !this.filterValueParse
        ? "vis-element-form-required"
        : "";
    },
    selectedQuickOptionLabel() {
      return this.dateFilterSelectedQuickOptions?.value && this.filterValueParse
        ? `(${this.$t(this.dateFilterSelectedQuickOptions?.label)})`
        : "";
    },
  },
  methods: {
    handleBlur() {
      this.selectedFirstValue = null;
    },
    addFiltersToPanels(value, isEvent) {
      this.setDateFilterDefaultValueActive(isEvent);
      //selectedQuickOptionLabel değerini takvimden herhangi bir gün seçildiğinde resetlemek için.
      if (checkFilterDefaultValueDynamic(this.dateFilterDefaultProperty)) {
        this.setStyleQuickOptionsValue({});
      }
      const params = {
        value: value
          ? {
              [this.selectedField?.field]: {
                value,
                alias: this.selectedField?.alias,
              },
            }
          : {},
        operator: this.dateFilterMultipleSelection || this.selectedField?.rangeSelect
          ? filterOperator.BETWEEN
          : filterOperator.EQ,
        panelI: this.panelData.i,
        isActiveDefaultValue: checkFilterDefaultValueSelection(
          this?.dateFilterDefaultProperty
        ) || checkFilterDefaultValueDynamic(this?.dateFilterDefaultProperty),
        datasetId: getSelectedFieldDatasetId(this.panelData),
        isActiveMandatory: this.dateFilterMandatorySelection,
        filterType: this.selectedField?.filterType,
        applyToTabs: this.dateFilterApplyToTabs,
      };

      this.$emit("addFiltersToPanels", params);
      this.selectedFirstValue = null;
    },
    setDateFilterDefaultValueActive(isEvent) {
      let defaulValue =
        isEvent && this.dateFilterDefaultValueActive && this.isPanelPage
          ? false
          : true;


      //TODO--- Buranın scheduleFiltersTabda çalışması için giden emit'i scheduleFiltersTab'da dinlememiz lazım...
      //"DATE_FILTER_DEFAULT_VALUE_ACTIVE" amacı default value varsa, date componentini value'su field'ın default value'sunu alır. Eğer kullanıcı manuel veri girerse ->
      //isEvent parametresi dolu gelir. DATE_FILTER_DEFAULT_VALUE_ACTIVE false olarak set edilir ve default value kullanımı devre dışı kalmış olur.
      this.setSelectedPanelStyle(
        defaulValue,
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_VALUE_ACTIVE
      );
    },
    addFiltersToPanelsWithDefaultValue() {
      if (
        this.dateFilterDefaultValueActive &&
        this.hasDefaultValue &&
        !this.isFilterPaneActive &&
        !deepEqual(this.parsedDefaultValue, this.filterValueParse)
      ) {
        this.addFiltersToPanels(this.parsedDefaultValue);
      }
    },

    setSelectedFirstValue() {
      this.$nextTick(() => {
        let dateEditor = document.querySelectorAll(
          `.vis-chart-card #${this.panelData.i} .el-date-editor`
        );
        dateEditor.forEach((element) => {
          element.addEventListener("click", () => {
            let dateTableLeftRows = document.querySelectorAll(
              ".el-date-range-picker__content.is-left .el-date-table .el-date-table__row"
            );
            let dateTableRightRows = document.querySelectorAll(
              ".el-date-range-picker__content.is-right .el-date-table .el-date-table__row"
            );

            dateTableRightRows.forEach((row) => {
              row.addEventListener("click", (e) => {
                const isDisabled = e.srcElement.offsetParent._prevClass.includes("disabled");
                if (this.selectedFirstValue || isDisabled) return;
                let headerDate = document.querySelectorAll(
                  ".el-date-range-picker__content.is-right .el-date-range-picker__header > div"
                );

                this.selectedFirstValue =
                  headerDate?.[0]?.innerText + e.target.innerText;
              });
            });
            dateTableLeftRows.forEach((row) => {
              row.addEventListener("click", (e) => {
                const isDisabled = e.srcElement.offsetParent._prevClass.includes("disabled");
                if (this.selectedFirstValue || isDisabled) return;
                let headerDate = document.querySelectorAll(
                  ".el-date-range-picker__content.is-left .el-date-range-picker__header > div"
                );

                this.selectedFirstValue =
                  headerDate?.[0]?.innerText + e.target.innerText;
              });
            });
          });
        });
      });
    },
    datePickerOptions() {
      let _this = this;
      return this.dateFilterShowQuickOptions
        ? {
            disabledDate(time) {
              return time.getTime() > Date.now();
            },
            shortcuts: [
              {
                text: this.$t("generalPages.Today"),
                onClick(picker) {
                  picker.$emit("pick", new Date());

                  _this.$nextTick(() => {
                    if (
                      _this.dateFilterDefaultProperty == DefaultValue.DYNAMIC && picker
                    ) {
                      _this.setStyleQuickOptionsValue({
                        value: dateQuickOptions.today,
                        label: this.text,
                      });
                    }
                  });
                },
              },
              {
                text: this.$t("generalPages.Yesterday"),
                onClick(picker) {
                  const date = new Date();
                  date.setTime(date.getTime() - 3600 * 1000 * 24);

                  picker.$emit("pick", date);

                  _this.$nextTick(() => {
                    if (
                      _this.dateFilterDefaultProperty == DefaultValue.DYNAMIC && picker
                    ) {
                      _this.setStyleQuickOptionsValue({
                        value: dateQuickOptions.yesterday,
                        label: this.text,
                      });
                    }
                  });
                },
              },
              {
                text: this.$t("generalPages.aWeekAgo"),
                onClick(picker) {
                  const date = new Date();
                  date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit("pick", date);

                  _this.$nextTick(() => {
                    if (
                      _this.dateFilterDefaultProperty == DefaultValue.DYNAMIC && picker
                    ) {
                      _this.setStyleQuickOptionsValue({
                        value: dateQuickOptions.aWeekAgo,
                        label: this.text,
                      });
                    }
                  });
                },
              },
            ],
          }
        : {
            disabledDate(time) {
              const originalDate = moment(_this.selectedFirstValue, "YYYY MMMD");
              const formattedDate = moment(originalDate).locale("en").format("YYYY MMMD");
              const startRangeDate = new Date(formattedDate);
              const startRangeDateTime = startRangeDate.getTime();
              const endRangeDate = startRangeDate.setTime(
                startRangeDateTime + 3600 * 1000 * 24 * _this.dateFilterMaxRange
              );
              const timeDate = new Date(time).getTime();
              const startDate = new Date(_this.dateFilterStartDate).getTime();
              const endDate = new Date(_this.dateFilterEndDate).getTime();
              const endRangeDateTime =
                startRangeDateTime +
                3600 * 1000 * 24 * _this.dateFilterMaxRange;
              
              const checkDateValueIsFull = (dateTime) => {
                return dateTime !== 0;
              };
              if (_this.dateFilterMaxRange) {
                if (
                  timeDate < startRangeDateTime ||
                  timeDate > endRangeDateTime
                ) {
                  return true;
                }
              }
              if (
                checkDateValueIsFull(startDate) &&
                !endDate &&
                startDate > timeDate
              ) {
                return true;
              } else if (
                !startDate &&
                checkDateValueIsFull(endDate) &&
                endDate < timeDate
              ) {
                return true;
              } else if (
                (checkDateValueIsFull(startDate) &&
                  checkDateValueIsFull(endDate) &&
                  startDate > timeDate) ||
                (checkDateValueIsFull(startDate) &&
                  checkDateValueIsFull(endDate) &&
                  endDate < timeDate)
              ) {
                return true;
              } else if (
                _this.dateFilterMultipleSelection &&
                checkDateValueIsFull(startRangeDateTime) &&
                _this.dateFilterMaxRange &&
                endRangeDate < timeDate
              ) {
                return true;
              } else return false;
            },
          };
    },

    setStyleQuickOptionsValue(value) {
      this.setSelectedPanelStyle(
        value,
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SELECTED_QUICK_OPTIONS
      );
    },
    setSelectedPanelStyle(value, property) {
      this.$emit("setSelectedPanelStyle", {
        property: property,
        value: value,
      });
    },
    setDynamicFilter() {
      if (this.dateFilterSelectedQuickOptions?.value) {
        let formattedDateValue = null;

        let quickOptions = {
          [dateQuickOptions.today]: {
            serviceFn: () => {
              formattedDateValue = changeFormatTodayDate(this.filterValueParse);

              if (formattedDateValue)
                this.addFiltersToPanels(formattedDateValue);
            },
          },
          [dateQuickOptions.yesterday]: {
            serviceFn: () => {
              formattedDateValue = changeFormatYesterdayDate(
                this.filterValueParse
              );

              if (formattedDateValue)
                this.addFiltersToPanels(formattedDateValue);
            },
          },
          [dateQuickOptions.aWeekAgo]: {
            serviceFn: () => {
              formattedDateValue = changeFormatAweekagoDate(
                this.filterValueParse
              );

              if (formattedDateValue)
                this.addFiltersToPanels(formattedDateValue);
            },
          },
        };

        return quickOptions[
          this.dateFilterSelectedQuickOptions.value
        ].serviceFn();
      }
    },
  },
};
</script>
<style scoped>
.vis-el-datepicker-label {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.7rem;
  transition: all 0.3s ease;
}
.vis-filter-date {
  height: auto !important;
}
.vis-filter-date:hover .vis-el-datepicker-label {
  top: -7px;
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease;
  color: rgba(51, 51, 51, 0.6);
}
</style>
