import { ACTION, MUTATION, STATE } from "./types";
import { MUTATION_GENERAL } from "../../Visualize/General/types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visMigrateContent = {
  state: {
    [STATE.DASHBOARDS_WITH_FOLDERS]: [],
    [STATE.CONNECTIONS_BY_ENV]: [],
  },
  actions: {
    [ACTION.FETCH_DASHBOARDS_WITH_FOLDERS]: async (
      { commit },
      { loadingComponent }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.MigrateContent,
        serviceMethodSub: serviceMethodSub.readDashboardWithFolders,
        successMutation: MUTATION.SET_DASHBOARDS_WITH_FOLDERS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },
    [ACTION.FETCH_CONNECTIONS_BY_ENV]: async (
      { commit },
      { loadingComponent, environmentId }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.MigrateContent,
        serviceMethodSub: serviceMethodSub.readConnectionsByEnvironment,
        bodyParam: environmentId,
        successMutation: MUTATION.SET_CONNECTIONS_BY_ENV,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },
    [ACTION.MOVE_OBJECT]: async (
      { commit },
      { loadingComponent, bodyParam }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.MigrateContent,
        serviceMethodSub: serviceMethodSub.moveObject,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },
  },
  mutations: {
    [MUTATION.SET_DASHBOARDS_WITH_FOLDERS](state, items) {
      Vue.set(state, [STATE.DASHBOARDS_WITH_FOLDERS], items);
    },
    [MUTATION.SET_CONNECTIONS_BY_ENV](state, items) {
      Vue.set(state, [STATE.CONNECTIONS_BY_ENV], items);
    },
  },
};
