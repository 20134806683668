<template>
  <div class="vis-flex--spacebeetwen-Ycenter vis-mr--1">
    <label>{{ panelDataName }}</label>
    <el-tooltip
      v-if="chartTabInformation.isActiveInformation"
      class="item"
      effect="light"
      :content="tabInformationContent"
      placement="top"
    >
      <i
        aria-hidden="true"
        :class="customIcon.Information"
        class="vis-cursor-pointer  vis-font-size-1"
      ></i>
    </el-tooltip>
  </div>
</template>
<script>
import { CustomIcon } from "../../../assets/js/custom-icons";
export default {
  props: {
    panelDataName: {
      type: String,
    },
    chartTabInformation: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  computed: {
    tabInformationContent() {
      return `${this.chartTabInformation.tabName} ${this.$t('generalPages.Filter Chart')}`
    },
  },
};
</script>
