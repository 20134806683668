import { ACTION, MUTATION, GETTER, STATE } from "./types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";
import { LoadingComponent } from "../../Visualize/General/loadingComponentDefinitions";

export const visScenarioMain = {
  state: {
    [STATE.SELECTED_SCENARIO]: [],
    [STATE.SCENARIOS]: [],
    [STATE.SCENARIO_ITEM]: [],
    [STATE.EVENTS]: [],
    [STATE.ENRICHMENTS]: [],
    [STATE.ACTIONS]: [],
    [STATE.DATASOURCES]: [],
    [STATE.SELECTED_EVENT]: [],
    [STATE.SELECTED_ENRICHMENT]: [],
    [STATE.TEST_ENRICHMENT]: [],
    [STATE.IS_SCENARIO_MODULE_AVAILABLE]: false,
  },
  getters: {
    [GETTER.GET_IS_SCENARIO_MODULE_AVAILABLE]: (state) => {
      return state[STATE.IS_SCENARIO_MODULE_AVAILABLE];
    },
    [GETTER.GET_SELECTED_SCENARIO]: (state) => {
      return state[STATE.SELECTED_SCENARIO];
    },
    [GETTER.GET_SCENARIOS]: (state) => {
      return state[STATE.SCENARIOS];
    },
    [GETTER.GET_SCENARIO_ITEM]: (state) => {
      return state[STATE.SCENARIO_ITEM];
    },
    [GETTER.GET_EVENTS]: (state) => {
      return state[STATE.EVENTS];
    },
    [GETTER.GET_ENRICHMENTS]: (state) => {
      return state[STATE.ENRICHMENTS];
    },
    [GETTER.GET_ACTIONS]: (state) => {
      return state[STATE.ACTIONS];
    },
    [GETTER.GET_DATASOURCES]: (state) => {
      return state[STATE.DATASOURCES];
    },
    [GETTER.GET_SELECTED_EVENT]: (state) => {
      return state[STATE.SELECTED_EVENT];
    },
    [GETTER.GET_SELECTED_ENRICHMENT]: (state) => {
      return state[STATE.SELECTED_ENRICHMENT];
    },
    [GETTER.GET_TEST_ENRICHMENT]: (state) => {
      return state[STATE.TEST_ENRICHMENT];
    },
  },
  actions: {
    [ACTION.INIT_SCENARIO_MODULE]: ({ commit }, isAvailable) => {
      commit(MUTATION.SET_IS_SCENARIO_MODULE_AVAILABLE, isAvailable);
    },
    [ACTION.FETCH_SELECTED_SCENARIO]: async ({ commit }, scenarioId) => {
      await vuexActionHandler({
        commit,
        queryParam: scenarioId,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readScenarioById,
        successMutation: MUTATION.SET_SELECTED_SCENARIO,
        resultSelector: (result) => result.data,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
      });
    },
    [ACTION.FETCH_SCENARIOS]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readScenarios,
        successMutation: MUTATION.SET_SCENARIOS,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.FETCH_SCENARIO_ITEM]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readScenarioItems,
        successMutation: MUTATION.SET_SCENARIO_ITEM,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },

    [ACTION.FETCH_EVENTS]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readEvents,
        successMutation: MUTATION.SET_EVENTS,
        resultSelector: (result) => result.data,
        withLoading: false,
      });
    },
    [ACTION.FETCH_ENRICHMENTS]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readEnrichments,
        successMutation: MUTATION.SET_ENRICHMENTS,
        resultSelector: (result) => result.data,
        withLoading: false,
      });
    },
    [ACTION.FETCH_ACTIONS]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readActions,
        successMutation: MUTATION.SET_ACTIONS,
        resultSelector: (result) => result.data,
        withLoading: false,
      });
    },
    [ACTION.FETCH_DATASOURCES]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readDatasources,
        successMutation: MUTATION.SET_DATASOURCES,
        resultSelector: (result) => result.data,
        withLoading: false,
      });
    },
    [ACTION.UPDATE_SCENARIO]: async ({ commit }, { scenarioId, data }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.updateScenario,
        queryParam: scenarioId,
        bodyParam: data,
        resultSelector: (result) => result.data,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });
    },
    [ACTION.CREATE_SCENARIO]: async ({ commit }, { data }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.createScenario,
        bodyParam: data,
        resultSelector: (result) => result.data,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });
    },
    [ACTION.CREATE_EVENT]: async ({ commit }, { data }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.createEvent,
        bodyParam: data,
        resultSelector: (result) => result.data,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_EVENT]: async ({ commit }, { id, data }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.updateEvent,
        queryParam: id,
        bodyParam: data,
        resultSelector: (result) => result.data,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });
    },
    [ACTION.FETCH_EVENT_BY_ID]: async ({ commit }, { id }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readEventById,
        queryParam: id,
        resultSelector: (result) => result.data,
        successMutation: MUTATION.SET_SELECTED_EVENT,
        withLoading: true,
        withNotify: false,
      });
    },

    [ACTION.CREATE_ENRICHMENT]: async ({ commit }, { data }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.createEnrichment,
        bodyParam: data,
        resultSelector: (result) => result.data,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_ENRICHMENT]: async ({ commit }, { id, data }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.updateEnrichment,
        queryParam: id,
        bodyParam: data,
        resultSelector: (result) => result.data,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });
    },
    [ACTION.FETCH_ENRICHMENT_BY_ID]: async ({ commit }, { id }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.readEnrichmentById,
        queryParam: id,
        resultSelector: (result) => result.data,
        successMutation: MUTATION.SET_SELECTED_ENRICHMENT,
        withLoading: true,
        withNotify: false,
      });
    },
    [ACTION.TEST_ENRICHMENT]: async ({ commit }, { data }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.testEnrichment,
        bodyParam: data,
        resultSelector: (result) => result.data,
        successMutation: MUTATION.SET_TEST_ENRICHMENT,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });
    },
    [ACTION.CREATE_SIMULATE]: async ({ commit }, { data }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.createSimulate,
        bodyParam: data,
        resultSelector: (result) => result.data,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });
    },

    [ACTION.DEPLOY_SCENARIO]: async ({ commit, dispatch }, { id }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.deployScenario,
        queryParam: id,
        resultSelector: (result) => result.data,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });

      dispatch(ACTION.FETCH_SCENARIO_ITEM);
    },
    [ACTION.UNDEPLOY_SCENARIO]: async ({ commit, dispatch }, { id }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.undeployScenario,
        queryParam: id,
        resultSelector: (result) => result.data,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
        withSuccessNotify: true,
        withNotify: true,
      });

      dispatch(ACTION.FETCH_SCENARIO_ITEM);
    },
    [ACTION.DELETE_SCENARIO]: async ({ commit }, { id }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.deleteScenario,
        queryParam: id,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
        withSuccessNotify: false,
        withNotify: true,
      });
    },
    [ACTION.DELETE_EVENT]: async ({ commit }, { id }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.deleteEvent,
        queryParam: id,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
        withSuccessNotify: false,
        withNotify: true,
      });
    },
    [ACTION.DELETE_ENRICHMENT]: async ({ commit }, { id }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scenario,
        serviceMethodSub: serviceMethodSub.deleteEnrichment,
        queryParam: id,
        loadingComponent: LoadingComponent.SelectedScenario,
        withLoading: true,
        withSuccessNotify: false,
        withNotify: true,
      });
    },
  },
  mutations: {
    [MUTATION.SET_IS_SCENARIO_MODULE_AVAILABLE](state, isAvailable) {
      state[STATE.IS_SCENARIO_MODULE_AVAILABLE] = isAvailable;
    },
    [MUTATION.SET_SELECTED_SCENARIO](state, items) {
      Vue.set(state, [STATE.SELECTED_SCENARIO], items);
    },
    [MUTATION.SET_SCENARIOS](state, items) {
      Vue.set(state, [STATE.SCENARIOS], items);
    },
    [MUTATION.SET_SCENARIO_ITEM](state, items) {
      Vue.set(state, [STATE.SCENARIO_ITEM], items);
    },
    [MUTATION.SET_EVENTS](state, items) {
      Vue.set(state, [STATE.EVENTS], items);
    },
    [MUTATION.SET_ENRICHMENTS](state, items) {
      Vue.set(state, [STATE.ENRICHMENTS], items);
    },
    [MUTATION.SET_ACTIONS](state, items) {
      Vue.set(state, [STATE.ACTIONS], items);
    },
    [MUTATION.SET_DATASOURCES](state, items) {
      Vue.set(state, [STATE.DATASOURCES], items);
    },
    [MUTATION.SET_SELECTED_EVENT](state, items) {
      Vue.set(state, [STATE.SELECTED_EVENT], items);
    },
    [MUTATION.SET_SELECTED_ENRICHMENT](state, items) {
      Vue.set(state, [STATE.SELECTED_ENRICHMENT], items);
    },
    [MUTATION.SET_TEST_ENRICHMENT](state, items) {
      Vue.set(state, [STATE.TEST_ENRICHMENT], items);
    },
  },
};
