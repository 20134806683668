<template>
  <div :class="['ingest-transition-node-container']">
    <div class="ingest-transition-node-delete-action-wrapper">
      <span
        class="ingest-transition-node-delete-action material-icons-outlined"
        @click="$emit('onTransitionDeleteClick')"
        >delete_forever</span
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ingest-transition-node-container {
  border-radius: 25px;
  opacity: 1;
  background: transparent !important;
}
.ingest-transition-node-delete-action {
  font-size: 1.75rem;
  color: #626262;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  margin-left: auto;
  cursor: pointer;
  visibility: hidden;
}
.ingest-transition-node-delete-action-wrapper {
  text-align: center !important;
}
.ingest-transition-node-container:hover .ingest-transition-node-delete-action {
  visibility: visible !important;
}
</style>
