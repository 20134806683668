<template>
  <div class="vis-height-100">
    <div
      v-loading="isCSVExcelUploadLoading"
      :class="[
        { 'upload-csv-excel-container': true },
        { 'vis-pa--none': currentPreviewStep === previewSteps.Table },
      ]"
    >
      <div
        v-if="currentPreviewStep === previewSteps.Import"
        class="content-upload"
      >
        <div class="content-left">
          <span class="explanation-title"
            >{{ $t("datamodel.selectFile") }}
          </span>
          <span class="explanation">{{ $t("datamodel.dragDropUpload") }}</span>
          <el-upload
            class="upload-area"
            drag
            action=""
            :file-list="fileList"
            :auto-upload="false"
            :limit="2"
            :on-change="handleImport"
            :on-remove="handleRemove"
          >
            <em class="el-icon-upload"></em>
            <div v-if="isFileAdded" class="el-upload__text">
              <em>{{ $t("datamodel.reselect") }}</em>
            </div>
          </el-upload>
        </div>

        <div class="content-right">
          <!-- 1st row -->
          <el-row>
            <el-col :span="12" class="col-item-container">
              <span class="explanation-title"
                >{{ $t("datamodel.settings") }}
              </span>
              <span class="explanation">{{
                $t("datamodel.enterDatasetName")
              }}</span></el-col
            >

            <el-col :span="12"></el-col>
          </el-row>

          <!-- 2nd row -->
          <el-row>
            <el-col :span="12" class="col-item-container">
              <span class="explanation-title"
                >{{ $t("datamodel.datasetName") }}
              </span>
              <input
                class="vis-input explanation"
                :value="form.datasetName"
                :disabled="hasInitialData"
                @keyup="(e) => inputKeyupHandler('datasetName', e)"
            /></el-col>
            <el-col v-if="isFileTypeCSV" :span="12" class="col-item-container">
              <span class="explanation-title"
                >{{ $t("datamodel.delimiter") }}
              </span>
              <el-select
                class="explanation"
                :value="form.delimiter"
                :placeholder="$t('datamodel.delimiter')"
                @change="(v) => (form.delimiter = v)"
              >
                <el-option
                  v-for="option in delimiterOptions"
                  :key="option.key"
                  :label="$t(`${option.label}`)"
                  :value="option.key"
                >
                </el-option> </el-select
            ></el-col>
          </el-row>

          <!-- 3rd row -->
          <el-row>
            <el-col :span="12" class="col-item-container">
              <span class="explanation-title"
                >{{ $t("datamodel.firstRowContains") }}
              </span>
              <el-select
                class="explanation"
                :value="form.firstRow"
                :placeholder="$t('datamodel.firstRowContains')"
                @change="(v) => (form.firstRow = v)"
              >
                <el-option
                  v-for="option in firstRowOptions"
                  :key="option.key"
                  :label="$t(`${option.label}`)"
                  :value="option.key"
                >
                </el-option> </el-select
            ></el-col>

            <el-col v-if="isFileTypeCSV" :span="12" class="col-item-container">
              <span class="explanation-title"
                >{{ $t("datamodel.textQualifier") }}
              </span>
              <el-select
                class="explanation"
                :value="form.textQualifier"
                :placeholder="$t('datamodel.textQualifier')"
                @change="(v) => (form.textQualifier = v)"
              >
                <el-option
                  v-for="option in textQualifierOptions"
                  :key="option.key"
                  :label="$t(`${option.label}`)"
                  :value="option.key"
                >
                </el-option> </el-select
            ></el-col>

            <el-col v-else :span="12"></el-col>
          </el-row>

          <!-- 4th row -->
          <el-row>
            <el-col v-if="hasInitialData" :span="12" class="col-item-container">
              <span class="explanation-title"
                >{{ $t("datamodel.Match Columns By") }}
              </span>
              <el-select
                class="explanation"
                :value="form.matchType"
                :placeholder="$t('datamodel.Match Columns By')"
                :disabled="form.firstRow === firstRowTypes.No"
                @change="(v) => (form.matchType = v)"
              >
                <el-option
                  v-for="option in matchTypeOptions"
                  :key="option.key"
                  :label="$t(`${option.label}`)"
                  :value="option.key"
                >
                </el-option> </el-select
            ></el-col>

            <el-col v-if="isFileTypeCSV" :span="12" class="col-item-container">
              <span class="explanation-title"
                >{{ $t("datamodel.dateFormat") }}
              </span>
              <el-select
                class="explanation"
                :value="form.dateFormat"
                :placeholder="$t('datamodel.dateFormat')"
                multiple
                collapse-tags
                @change="(v) => (form.dateFormat = v)"
              >
                <el-option
                  v-for="option in dateFormatOptions"
                  :key="option.key"
                  :label="$t(`${option.label}`)"
                  :value="option.key"
                >
                </el-option> </el-select
            ></el-col>

            <el-col v-else :span="12"></el-col>
          </el-row>
        </div>
      </div>
      <div
        v-if="currentPreviewStep === previewSteps.Table"
        class="content-preview-table"
      >
        <vue-good-table :columns="previewTableColumns" :rows="previewRows">
          <template slot="table-column" slot-scope="props">
            <span>
              <strong>{{ $t(`${props.column.className}`) }}</strong>
            </span>
            <br />
            <span style="font-size: 10px">
              {{ $t(`${props.column.classType}`) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <div class="footer">
      <el-button
        v-if="currentPreviewStep === previewSteps.Table"
        size="small"
        type="secondary"
        class="vis-ml--auto"
        @click="handleBack"
        >{{ $t("generalPages.back") }}</el-button
      >
      <el-button
        size="small"
        type="primary"
        @click="handleNext"
        :disabled="!isFileAdded"
        >{{
          currentPreviewStep === previewSteps.Table
            ? $t("generalPages.import")
            : $t("generalPages.next")
        }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { read, utils } from "xlsx";
import { ACTION } from "../../../store/modules/Visualize/File/types";
import {
  GETTER as GETTER_CONNECTIONS,
  ACTION as ACTION_CONNECTIONS,
} from "../../../store/modules/Visualize/Connections/types";
import { Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import Papa from "papaparse";
import { VueGoodTable } from "vue-good-table";
import { DB_TYPE } from "../../../commons/connection";
import { importTypes } from "../../../commons/dataModelTypes";
import { LoadingComponent } from "../../../store/modules/Visualize/General/loadingComponentDefinitions";
import { GETTER as GETTER_GENERAL } from "../../../store/modules/Visualize/General/types";

const matchTypes = {
  ColumnOrder: "COLUMN_ORDER",
  ColumnName: "COLUMN_NAME",
};

const uploadModes = {
  Insert: "INSERT",
  Append: "APPEND",
};

const firstRowTypes = {
  Yes: "yes",
  No: "no",
};

export default {
  props: {
    datamodelName: {
      type: String,
      default: null,
    },
    datamodelId: {
      type: String,
      default: null,
    },
    initialData: {
      type: Object,
    },
  },
  components: {
    VueGoodTable,
  },
  data() {
    return {
      firstRowTypes: firstRowTypes,
      delimiterOptions: [
        {
          key: ",",
          label: "Comma [ , ]",
        },
        {
          key: ";",
          label: "Semi colon [ ; ]",
        },
      ],
      textQualifierOptions: [
        {
          key: "'",
          label: "''",
        },
      ],
      firstRowOptions: [
        {
          key: firstRowTypes.Yes,
          label: "Yes",
        },
        {
          key: firstRowTypes.No,
          label: "No",
        },
      ],
      dateFormatOptions: [
        {
          key: "yyyy-MM-dd HH:mm:ss",
          label: "yyyy-MM-dd HH:mm:ss",
        },
        {
          key: "yyyy-MM-dd",
          label: "yyyy-MM-dd",
        },
        {
          key: "dd-MM-yyyy HH:mm:ss",
          label: "dd-MM-yyyy HH:mm:ss",
        },
        {
          key: "dd-MM-yyyy",
          label: "dd-MM-yyyy",
        },
        {
          key: "yyyy/MM/dd HH:mm:ss",
          label: "yyyy/MM/dd HH:mm:ss",
        },
        {
          key: "yyyy/MM/dd",
          label: "yyyy/MM/dd",
        },
        {
          key: "dd.MM.yyyy HH:mm:ss",
          label: "dd.MM.yyyy HH:mm:ss",
        },
        {
          key: "dd.MM.yyyy",
          label: "dd.MM.yyyy",
        },
        {
          key: "dd/MM/yyyy HH:mm:ss",
          label: "dd/MM/yyyy HH:mm:ss",
        },
        {
          key: "dd/MM/yyyy",
          label: "dd/MM/yyyy",
        },
        {
          key: "dd MM yyyy",
          label: "dd MM yyyy",
        },
        {
          key: "dd MMM yyyy",
          label: "dd MMM yyyy",
        },
        {
          key: "dd MMMM yyyy",
          label: "dd MMMM yyyy",
        },
      ],
      fileList: [],
      importedFilePreviewArray: null,
      fileTypeIncludes: {
        CSV: ".csv",
        XLS: [".xls", ".xlsx"],
      },
      previewTableColumns: [],
      previewSteps: {
        Import: "import",
        Table: "table",
      },
      currentPreviewStep: null,
      form: {
        datasetName: "",
        delimiter: ",",
        textQualifier: "'",
        firstRow: firstRowTypes.Yes,
        dateFormat: [],
        matchType: matchTypes.ColumnOrder,
      },
    };
  },
  watch: {
    "form.delimiter": {
      handler() {
        this.handleCsvImport(this.fileList[0]);
      },
    },
    "form.firstRow": {
      handler(val) {
        if (val === firstRowTypes.No) {
          this.form.matchType = matchTypes.ColumnOrder;
        }
      },
    },
  },
  mounted() {
    this.currentPreviewStep = this.previewSteps.Import;
    this.fetchConnections();

    if (this.hasInitialData) {
      this.form.datasetName = this.initialData.name;
    }
  },
  computed: {
    ...mapGetters({
      connections: GETTER_CONNECTIONS.GET_CONNECTIONS,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    matchTypeOptions() {
      return [
        {
          key: matchTypes.ColumnOrder,
          label: this.$t("datamodel.Column Order"),
        },
        {
          key: matchTypes.ColumnName,
          label: this.$t("datamodel.Column Name"),
        },
      ];
    },
    hasInitialData() {
      return this.initialData?.name;
    },
    initialUploadMode() {
      return this.initialData?.uploadMode;
    },
    isCSVExcelUploadLoading() {
      return this.loading[LoadingComponent.CSVExcelUpload];
    },
    hasHeader() {
      return this.form.firstRow === this.firstRowOptions[0].key;
    },
    isFileAdded() {
      return this.importedFilePreviewArray?.length;
    },
    previewRows() {
      const rows = [];

      const fileRows = this.importedFilePreviewArray;

      fileRows.forEach((element, index) => {
        if (!(index === 0 && this.hasHeader)) {
          rows.push(
            Object.keys(element).reduce((accumulator, key) => {
              accumulator[this.previewTableColumns[key].className] =
                element[key];
              return accumulator;
            }, {})
          );
        }
      });

      return rows;
    },
    isFileTypeCSV() {
      return this.fileList?.[0]?.raw?.name?.endsWith(this.fileTypeIncludes.CSV);
    },
    isFileTypeExcel() {
      return this.fileTypeIncludes.XLS.some((v) =>
        this.fileList?.[0]?.raw?.name?.endsWith(v)
      );
    },
  },
  methods: {
    ...mapActions({
      postFilePreview: ACTION.POST_FILE_PREVIEW,
      postFileUpload: ACTION.POST_FILE_UPLOAD,
      fetchConnections: ACTION_CONNECTIONS.FETCH_CONNECTIONS,
    }),
    handleBack() {
      if (this.currentPreviewStep === this.previewSteps.Import) {
        this.$emit("back");
      } else if (this.currentPreviewStep === this.previewSteps.Table) {
        this.currentPreviewStep = this.previewSteps.Import;
      }
    },
    handleNext() {
      if (this.currentPreviewStep === this.previewSteps.Import) {
        this.submitPreview();
      } else if (this.currentPreviewStep === this.previewSteps.Table) {
        this.submitUpload();
      }
    },
    handleRemove() {
      this.importedFilePreviewArray = null;
      this.fileList = [];
    },
    handleCsvImport(file) {
      const data = [];
      const self = this;

      Papa.parse(file.raw, {
        worker: true,
        preview: 1000,
        delimiter: this.form.delimiter,
        step: function (results) {
          data.push(results.data);
        },
        complete: function () {
          self.importedFilePreviewArray = data;
        },
      });
    },
    async handleImport(file, fileList) {
      this.fileList = [fileList[fileList.length - 1]];

      if (fileList.length !== 0) {
        if (this.isFileTypeExcel) {
          const f = await file.raw.arrayBuffer();
          const wb = read(f, {
            sheetRows: 1000,
            cellText: false,
            cellDates: true,
          });
          const datacsv = utils.sheet_to_csv(wb.Sheets[wb.SheetNames[0]], {
            defval: "",
            raw: false,
            dateNF: 'yyyy-mm-dd" "hh:mm:ss" "',
          });
          var csvToJson = Papa.parse(datacsv);

          this.importedFilePreviewArray = csvToJson.data;
        } else if (this.isFileTypeCSV) {
          this.handleCsvImport(file);
        } else {
          Notify(
            this.$t("datamodel.fileTypeNotSupported"),
            notificationType.WARNING
          );
          this.fileList = [];
        }
      }
    },
    inputKeyupHandler(key, e) {
      this.form[key] = e.target.value;
    },
    createExcelUploadPayload() {
      const fd = new FormData();
      const connection = this.connections.find(
        (c) => c.type === DB_TYPE.CLICKHOUSE
      );
      let dataPart = {
        columns: this.previewTableColumns.map((c) => ({
          className: c.className,
          classType: c.classType,
          receivedClassName: c.receivedClassName,
        })),
        connectionId: connection.connectionId,
        fileType: importTypes.EXCEL,
        mode: this.hasInitialData ? this.initialUploadMode : uploadModes.Insert,
        settings: {
          hasHeader: this.hasHeader,
        },
        datasetName: this.form.datasetName,
      };

      if (this.hasInitialData) {
        dataPart.datasetId = this.initialData.datasetId;
        dataPart.settings.matchType = this.form.matchType;
      }

      if (this.datamodelName?.length && this.datamodelId?.length) {
        dataPart = {
          ...dataPart,
          dataModelName: this.datamodelName,
          dataModelId: this.datamodelId,
        };
      }

      fd.append("file", this.fileList[0].raw);
      fd.append("data", JSON.stringify(dataPart));

      return fd;
    },
    createCSVUploadPayload() {
      const fd = new FormData();
      const connection = this.connections.find(
        (c) => c.type === DB_TYPE.CLICKHOUSE
      );
      let dataPart = {
        columns: this.previewTableColumns.map((c) => ({
          className: c.className,
          classType: c.classType,
          receivedClassName: c.receivedClassName,
        })),
        connectionId: connection.connectionId,
        fileType: importTypes.CSV,
        mode: this.hasInitialData ? this.initialUploadMode : uploadModes.Insert,
        settings: {
          hasHeader: this.hasHeader,
          delimiter: this.form.delimiter,
        },
        datasetName: this.form.datasetName,
      };

      if (this.hasInitialData) {
        dataPart.datasetId = this.initialData.datasetId;
        dataPart.settings.matchType = this.form.matchType;
      }

      // if date format is not auto
      if (this.form.dateFormat?.length) {
        dataPart.settings.dateFormats = this.form.dateFormat;
      }

      if (this.datamodelName?.length && this.datamodelId?.length) {
        dataPart = {
          ...dataPart,
          dataModelName: this.datamodelName,
          dataModelId: this.datamodelId,
        };
      }

      fd.append("file", this.fileList[0].raw);
      fd.append("data", JSON.stringify(dataPart));

      return fd;
    },
    async submitUpload() {
      const result = await this.postFileUpload({
        bodyParam: this.isFileTypeCSV
          ? this.createCSVUploadPayload()
          : this.createExcelUploadPayload(),
        loadingComponent: LoadingComponent.CSVExcelUpload,
      });

      if (result?.status === 400) {
        return;
      }

      if (this.datamodelName?.length && this.datamodelId?.length) {
        this.$emit("init");

        return;
      }

      if (result.datasetId && !this.initialUploadMode) {
        this.$emit("redirectToDatamodel", result.dataModelId);
        this.$emit("closeDatamodelPopup");
      } else {
        this.$emit("closeDatamodelPopup");
      }
    },
    async submitPreview() {
      if (this.form.datasetName?.length > 0) {
        const result = await this.postFilePreview({
          bodyParam: this.preparePreviewPayload(),
          loadingComponent: LoadingComponent.CSVExcelUpload,
        });

        if (result) {
          this.previewTableColumns = result.columns.map((c) => {
            return { ...c, field: c.className, label: c.className };
          });
          this.currentPreviewStep = this.previewSteps.Table;
        }
      } else {
        Notify(
          this.$t("datamodel.datasetNameEmptyNotification"),
          notificationType.WARNING
        );
      }
    },
    preparePreviewPayload() {
      const connection = this.connections.find(
        (c) => c.type === DB_TYPE.CLICKHOUSE
      );

      const payload = {
        mode: this.hasInitialData ? this.initialUploadMode : uploadModes.Insert,
        connectionId: connection.connectionId,
        hasHeader: this.hasHeader,
        fileRows: this.importedFilePreviewArray,
      };

      // if date format is not auto
      if (this.form.dateFormat?.length) {
        payload.dateFormats = this.form.dateFormat;
      }

      return payload;
    },
  },
};
</script>

<style scoped>
.upload-area {
  width: 294px;
  height: 195px;
}
.upload-csv-excel-container {
  height: 80%;
  padding: 50px 80px;
}
.no-padding {
  height: 80%;
  padding: 50px 80px;
}
.content-upload {
  display: flex;
  column-gap: 85px;
  height: 100%;
}
.content-preview-table {
  display: flex;
  height: 100%;
}
.content-left {
  display: flex;
  flex-direction: column;
}
.content-right {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer {
  height: 20%;
  margin-top: 40px;
  float: right;
  margin-right: 40px;
  margin-bottom: 25px;
}
.el-icon-upload {
  margin-top: 45px !important;
}
.el-upload__text {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
.el-upload__text em {
  color: #3d62f8 !important;
}
.explanation-title {
  font-weight: 500;
  margin-bottom: 5px;
}
.explanation {
  margin-bottom: 20px;
}
.col-item-container {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}
::v-deep .vgt-wrap {
  width: 100%;
}
::v-deep .vgt-inner-wrap {
  height: 100%;
}
::v-deep .vgt-responsive {
  height: 100% !important;
}
</style>
