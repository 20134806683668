import { CustomIcon } from "../../assets/js/custom-icons";
import {
  DatamodelContextDefaults,
  DatasetFieldTypes,
} from "../../commons/dataModelTypes";

export const getSelectedFieldDatasetId = (panelData) => {
  return (
    panelData?.details?.metrics?.find((x) => x.datasetId)?.datasetId ??
    panelData?.details?.aggregation?.find((x) => x.datasetId)?.datasetId
  );
};

export const getIconByFieldUsageType = (field) => {
  if (!field?.isAuthorize) return CustomIcon.AdminPanelSettings;
  
  const iconByTypes = {
    [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE]: CustomIcon.Abc,
    [DatamodelContextDefaults.USAGE_TYPES.DATE]: CustomIcon.Clock_Time,
    [DatamodelContextDefaults.USAGE_TYPES.CUSTOM_FILTERS]: CustomIcon.FilterAlt,
    [DatasetFieldTypes.TEXT]: CustomIcon.Abc,
  };
  return (
    iconByTypes[field.fieldUsageType || field.fieldType] ?? CustomIcon.Measure
  );
};

export const getAllDatasetFields = (datasetFields) => {
  let allDatasetFields = [];

  Object.keys(datasetFields).forEach((element) => {
    allDatasetFields = [...allDatasetFields, ...datasetFields[element]];
  });

  return allDatasetFields;
};

export const getAllMeasureFields = (allDatasetFields) => {
  return allDatasetFields
    .filter((f) => f.usageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE && f.fieldId)
    .map((m) => {
      return {
        fieldId: m.fieldId,
        alias: m.alias,
      };
    });
};

export const getJoinedDatasetIdsByDatasetId = (datasetIds, datasetId) => {
  let selectedDatasetIds = [];

  datasetIds.forEach((items) => {
    const includes = items.includes(datasetId);
    if (includes) {
      selectedDatasetIds = items;
    }
  });

  return selectedDatasetIds;
};

export const getAllJoinedDatasetFields = (datasetFields, joinedDatasetIds) => {
  let joinedAllDatasetList = [];

  joinedDatasetIds.forEach((element) => {
    joinedAllDatasetList = [...joinedAllDatasetList, ...datasetFields[element]];
  });

  return joinedAllDatasetList;
};
