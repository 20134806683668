const domain = "VISUALIZE_TEMPLATES";

export const STATE = {
  TEMPLATES: `${domain}/TEMPLATES`,
};

export const GETTER = {
  GET_TEMPLATES: `${domain}/GET_TEMPLATES`,
};

export const ACTION = {
  FETCH_TEMPLATES: `${domain}/FETCH_TEMPLATES`,
  CREATE_TEMPLATE: `${domain}/CREATE_TEMPLATE`,
  UPDATE_TEMPLATE: `${domain}/UPDATE_TEMPLATE`,
  DELETE_TEMPLATE: `${domain}/DELETE_TEMPLATE`,
};

export const MUTATION = {
  SET_TEMPLATES: `${domain}/SET_TEMPLATES`,
};
