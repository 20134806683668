import i18n from "../../i18n";
import { CustomIcon } from "../../assets/js/custom-icons";

const setfilterOperatorName = (element) => {

    let operatorName = i18n.t(`operatorLocale.${element.operator}`)

    return operatorName;
}

const filterInfoClassName = (element, isPanelPage) => {
    if (isPanelPage) {
        return element.isActiveChartFilter ? "inherit" : "vis-color-danger"
    } else {
        return element.isActiveDashboardFilter ? "inherit" : "vis-color-danger"
    }
}

export const getActiveFilterList = (panelDataAllFilters) => {
    let newList = [];

    if (panelDataAllFilters?.length) {
        newList = panelDataAllFilters.filter(
            (x) => x.isActiveFilterIcon && x?.value?.length
        );
    }
    return newList;
}

export const getFilterItemTextValue = (panelDataAllFilters, isPanelPage) => {
    let filterItem = "";

    if (panelDataAllFilters.length) {
        const activeIconList = panelDataAllFilters.filter(
            (x) => x.isActiveFilterIcon
        );

        activeIconList.forEach((element) => {
            let className = filterInfoClassName(element, isPanelPage);

            let operatorName = setfilterOperatorName(element);

            if (element?.value?.length) {
                filterItem += `<div><i class="${CustomIcon.FilterAltOutlined} ${className}"></i> 
                ${element.alias}  ${operatorName}
                ${element.value.join(" | ")} </div>`;
            }
        });
    }

    return filterItem;
}