/* eslint-disable */
import { STATE, ACTION, MUTATION_GENERAL, GETTER } from "./types";
/* import _ from "lodash"; */
import Vue from "vue";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import { vuexActionHandler } from "../../../../util/storeHelper";

export const visGeneral = {
  state: {
    loading: {},
    notify: {},
    [STATE.TOPBAR_QUERY_PARAM]: {}
  },
  getters: {
    [GETTER.GET_NOTIFY]: (state) => {
      return state.notify;
    },
    [GETTER.GET_LOADING]: (state) => {
      return state.loading;
    },
    [GETTER.GET_TOPBAR_QUERY_PARAM]: (state) => {
      return state[STATE.TOPBAR_QUERY_PARAM]
    }
  },
  actions: {
    [ACTION.API_CALL]: async ({ commit }, { bodyParam }) => {
      return await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.ApiCall,
          serviceMethodSub: serviceMethodSub.createApiCall,
          bodyParam,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true
        },
      );
    },
  },
  mutations: {
    [MUTATION_GENERAL.SET_LOADING]: (state, { loadingComponent, value }) => {
      Vue.set(state.loading, loadingComponent, value);
    },
    [MUTATION_GENERAL.ADD_NOTIFY](state, notify) {
      Vue.set(state, "notify", notify);
    },
    [MUTATION_GENERAL.RESET_NOTIFY](state) {
      state.notify = [];
    },
    [MUTATION_GENERAL.SET_TOPBAR_QUERY_PARAM](state, param) {
      Vue.set(state, STATE.TOPBAR_QUERY_PARAM, param)
    }
  },
};
