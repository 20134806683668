<template>
  <div class="confirmable-select">
    <label class="confirmable-select-label">{{ label }}</label>
    <div class="confirmable-select-body">
      <el-select
        :value="valueTemporary"
        :placeholder="placeholder"
        :disabled="isSelectDisabled"
        :multiple="multiple"
        :collapse-tags="collapseTags"
        @change="valueTemporary = $event"
      >
        <el-option
          v-for="item in items"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div
        v-if="isConfirmableSelectActionsVisible"
        class="confirmable-select-actions"
      >
        <template v-if="!isEditMode">
          <i
            :class="CustomIcon.Pencil"
            class="vis-cursor-pointer"
            aria-hidden="true"
            @click="isEditMode = true"
        /></template>
        <template v-else>
          <i
            :class="CustomIcon.Close"
            class="vis-cursor-pointer"
            aria-hidden="true"
            @click="cancel"
          />
          <el-popconfirm
            class="confirmable-select-popconfirm"
            :title="$t('dialog.Are you sure to update this field?')"
            :confirm-button-text="$t('dialog.confirm')"
            :cancel-button-text="$t('dialog.cancel')"
            @confirm="approve"
            @cancel="cancel"
          >
            <i
              slot="reference"
              :class="CustomIcon.Checked"
              class="vis-cursor-pointer"
              aria-hidden="true"
            />
          </el-popconfirm>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { tempDefaultJobPrefix } from "../../commons/ingestDefaultData";
import deepEqual from "deep-equal";

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Array],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    collapseTags: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      isEditMode: false,
      valueTemporary: null,
    };
  },
  watch: {
    value: {
      handler() {
        this.valueTemporary = this.value;
      },
      immediate: true,
    },
    valueTemporary: {
      handler() {
        if (
          this.isJobNewlyCreated &&
          !deepEqual(this.value, this.valueTemporary)
        ) {
          this.$emit("change", this.valueTemporary);
        }
      },
      deep: true,
    },
  },
  computed: {
    isJobNewlyCreated() {
      return this.jobId?.includes(tempDefaultJobPrefix);
    },
    isConfirmableSelectActionsVisible() {
      if (this.isJobNewlyCreated) return false;

      return true;
    },
    isSelectDisabled() {
      if (this.isJobNewlyCreated) return false;
      if (!this.isEditMode) return true;

      return false;
    },
  },
  methods: {
    cancel() {
      this.isEditMode = false;
      this.valueTemporary = this.value;
    },
    approve() {
      this.isEditMode = false;
      this.$emit("change", this.valueTemporary);
    },
  },
};
</script>

<style scoped>
.confirmable-select-body {
  display: flex;
  align-items: center;
  gap: 10px;
}
.confirmable-select-actions {
  display: flex;
  align-items: center;
  gap: 5px;
}
::v-deep .el-input.is-disabled .el-input__inner {
  color: black !important;
  background: #f4f4f4 !important;
}
</style>
