import { formatISOToDateTime } from "../../util/moment";

export const selectDatabaseConnectionColumnsFields = {
  name: "name",
  description: "description",
  type: "type",
  owner: "userName",
  update_at: "updateDate",
  create_at: "createDate",
  actions: "actions",
};

export const getSelectDatabaseConnectionColumns = (
  hideCreateDate,
  hideUpdateDate,
  hideActions
) =>
  [
    {
      label: "generalPages.labelName",
      field: selectDatabaseConnectionColumnsFields.name,
    },
    {
      label: "generalPages.labelDescription",
      field: selectDatabaseConnectionColumnsFields.description,
    },
    {
      label: "generalPages.labelType",
      field: selectDatabaseConnectionColumnsFields.type,
    },
    {
      label: "generalPages.labelOwner",
      field: selectDatabaseConnectionColumnsFields.owner,
    },
    {
      label: "generalPages.labelCreateDate",
      field: selectDatabaseConnectionColumnsFields.create_at,
      formatFn: formatISOToDateTime,
      // Check if hideCreateDate is true, and exclude the field if it is
      hidden: hideCreateDate,
    },
    {
      label: "generalPages.labelUpdateDate",
      field: selectDatabaseConnectionColumnsFields.update_at,
      formatFn: formatISOToDateTime,
      // Check if hideUpdateDate is true, and exclude the field if it is
      hidden: hideUpdateDate,
    },
    {
      label: "generalPages.labelActions",
      field: selectDatabaseConnectionColumnsFields.actions,
      // Check if hideActions is true, and exclude the field if it is
      hidden: hideActions,
    },
  ].filter((column) => !column.hidden);

export const SELECT_DATABASE_CONNECTION_TYPE = {
  BASIC: "BASIC",
  CUSTOM: "CUSTOM",
};

export const SELECT_DATABASE_CONNECTION_TYPE_LIST = [
  {
    value: SELECT_DATABASE_CONNECTION_TYPE.BASIC,
    label: "connection.labelBasic",
  },
  {
    value: SELECT_DATABASE_CONNECTION_TYPE.CUSTOM,
    label: "connection.labelCustom",
  },
];
