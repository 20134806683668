<template>
  <div class="vis-height-100">
    <div class="vis-flex--spacebeetwen enrichment-sql-preview-header-container">
      <span>{{ $t("Preview") }}</span>
    </div>
    <vue-good-table
      v-if="showPreviewTable"
      :columns="columns"
      :rows="rows"
      :fixed-header="false"
    >
      >
      <div class="vis-text--center" slot="emptystate">
        {{ $t("generalPages.noData") }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  components: { VueGoodTable },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  computed: {
    showPreviewTable() {
      return this.columns.length > 0 && this.rows.length > 0;
    },
  }
};
</script>
<style scoped>
.enrichment-sql-preview-header-container {
  padding: 20px;
  font-weight: 600;
  font-size: 0.875rem;
}
::v-deep .vgt-table {
  border-spacing: 0;
  border-top: 0 !important;
}
::v-deep .vgt-table thead {
  position: sticky;
  top: 0;
}
::v-deep .vgt-table thead tr:first-child th {
  border-top: 1px solid #dcdfe6 !important;
}
::v-deep .vgt-table th,
.vgt-table td {
  border-width: 0.5px !important;
}
::v-deep .vgt-wrap {
  height: 504px;
}
::v-deep .vgt-inner-wrap {
  height: 100% !important;
}
::v-deep .vgt-responsive {
  height: 100% !important;
}
</style>
