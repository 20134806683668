export const domain = "VISUALIZE_CALCULATED_FIELD";

export const STATE = {
};

export const GETTER = {
};

export const ACTION = {
    CREATE_CALCULATED_FIELD: `${domain}/CREATE_CALCULATED_FIELD`,
    UPDATE_CALCULATED_FIELD: `${domain}/UPDATE_CALCULATED_FIELD`,
    VALIDATE_CALCULATED_FIELD: `${domain}/VALIDATE_CALCULATED_FIELD`,
};

export const MUTATION = {
};
