import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";

export const pivotTableSearch = (params, searchData, chartI) => {
    const columns = getMappedNameAndCaption(params?.aggregation?.filter(f => f.boxKey === BOX_KEY_ENUM.COLUMN.KEY))
    const rows = getMappedNameAndCaption(params?.aggregation?.filter(f => f.boxKey === BOX_KEY_ENUM.ROW.KEY))
    const metric = getMappedNameWithMetricTypeCaption(params?.metric?.filter(f => f.boxKey === BOX_KEY_ENUM.MEASURES.KEY))

    return {
        dataSourceSettings: {
            enableSorting: false,
            columns: columns,
            dataSource: searchData?.aggregations ?? [],
            values: metric,
            rows: rows,
            expandAll: false,
            filters: [],
        },
        id: chartI
    };
};

const getMappedNameAndCaption = (data) => {
    return data?.map(a => {
        return { name: a.field, caption: a.alias }
    }) ?? []
}

const getMappedNameWithMetricTypeCaption = (data) => {
    return data?.map(a => {
        return { name: `${a.field}${a.type}`, caption: a.alias }
    }) ?? []
}

