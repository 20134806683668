export const BUTTON_DEFAULT_CONFIGURATIONS = {
    BUTTON_CAPTION_LABEL: null,
    BUTTON_CAPTION_FONT_FAMILY: "Poppins",
    BUTTON_CAPTION_FONT_WEIGHT: 500,
    BUTTON_CAPTION_FONT_SIZE: 12,
    BUTTON_CAPTION_FONT_COLOR: "#F0FFFF",
    BUTTON_CAPTION_BACKGROUND_COLOR: "#0000FF",
    BUTTON_ACTION_SELECT_VALUE: "run",
    BUTTON_ACTION_APPLY_TO_TABS: null,
    BUTTON_ACTION_URL_VALUE: "",
    BUTTON_CAPTION_BORDER_RADIUS: "2",
    BUTTON_DIALOG_TITLE: "",
    BUTTON_DIALOG_SUBTITLE: "",
    BUTTON_DIALOG_CONTENT: "",

    BUTTON_DIALOG_SHOW_TITLE: true,
    BUTTON_DIALOG_SHOW_SUB_TITLE: true,
    BUTTON_DIALOG_SHOW_BORDER: false,
    BUTTON_DIALOG_BORDER_COLOR: "#E5EAEA",
    BUTTON_DIALOG_BORDER_RADIUS: "0",
    BUTTON_DIALOG_BACKGROUND_COLOR: "#fff",
    //DIALOG TITLE
    BUTTON_DIALOG_TITLE_FONT_COLOR: "#000000",
    BUTTON_DIALOG_TITLE_FONT_FAMILY: "Poppins",
    BUTTON_DIALOG_TITLE_FONT_SIZE: "20",
    BUTTON_DIALOG_TITLE_FONT_WEIGHT: 600,
    BUTTON_DIALOG_TITLE_ALIGN: "left",
    //DIALOG SUB TITLE
    BUTTON_DIALOG_SUB_TITLE_FONT_COLOR: "#000000",
    BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY: "Poppins",
    BUTTON_DIALOG_SUB_TITLE_FONT_SIZE: "16",
    BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT: 500,
    BUTTON_DIALOG_SUB_TITLE_ALIGN: "left",
    //DIALOG CONTENT
    BUTTON_DIALOG_CONTENT_FONT_COLOR: "#000000",
    BUTTON_DIALOG_CONTENT_FONT_FAMILY: "Poppins",
    BUTTON_DIALOG_CONTENT_FONT_SIZE: "16",
    BUTTON_DIALOG_CONTENT_FONT_WEIGHT: "normal",
    BUTTON_DIALOG_CONTENT_ALIGN: "left",
    BUTTON_DIALOG_CONTENT_WRAP_TEXT: false,

    BUTTON_DIALOG_CARD_WIDTH: "700",
    BUTTON_DIALOG_CARD_HEIGHT: "400"
}

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}