<template>
  <ul class="vis-settings-tab-link">
    <li
      v-if="showDataModelLink"
      class="link-item"
      :class="{ active: activeSettingTab == SettingsLeftTabLinkEnum.DataModel }"
    >
      <span
        @click="$emit('setSettingLeftTab', SettingsLeftTabLinkEnum.DataModel)"
        >{{ $t("generalPages.dataModel") }}</span
      >
    </li>

    <li
      v-if="showParametersLink"
      class="link-item"
      :class="{
        active: activeSettingTab == SettingsLeftTabLinkEnum.Parameters,
      }"
    >
      <span
        @click="$emit('setSettingLeftTab', SettingsLeftTabLinkEnum.Parameters)"
        >{{ $t("generalPages.Parameters") }}</span
      >
    </li>

    <li
      v-else
      class="link-item"
      :class="[
        {
          active: activeSettingTab == SettingsLeftTabLinkEnum.Visualize,
        },
        {
          disabled: activeSettingTab === '',
        },
      ]"
    >
      <span
        @click="$emit('setSettingLeftTab', SettingsLeftTabLinkEnum.Visualize)"
        >{{ $t("generalPages.visualize") }}</span
      >
    </li>
  </ul>
</template>

<script>
import { SettingsLeftTabLinkEnum } from "../../commons/settingsLeftTabLinkEnum";

export default {
  props: {
    activeSettingTab: {
      type: String,
    },
    showDataModelLink: {
      type: Boolean,
      default: false,
    },
    showParametersLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SettingsLeftTabLinkEnum: SettingsLeftTabLinkEnum,
    };
  },
};
</script>
