<template>
  <div>
    <draggable
      id="menu-container-tab-panel"
      tag="ul"
      ghost-class="ghost"
      :disabled="isModeDashboardView || isTabNameEditActive"
      :list="tabPanelTabList"
      class="list-group vis-dashboard-tab vis-height-100 no-drag"
      @change="changeDraggableList"
    >
      <li
        v-for="(tabItem, index) in tabPanelTabList"
        :key="tabItem.id"
        class="menu-container-item-tab-panel"
        :class="[{ active: activeTab == index }]"
      >
        <div @click="handleChangeTab(tabItem.id, index)">
          <input
            v-if="isTabNameEditActive"
            type="text"
            autocomplete="off"
            :value="tabItem.name"
            @focusout="isTabNameEditActive = false"
            @keypress.enter="isTabNameEditActive = false"
            @keyup="(event) => changeTabName(event.target.value, tabItem.id)"
            @click.stop
          />
          <span v-else>{{ tabItem.name }}</span>
        </div>
        <!-- ThreeDotIcon  START -->
        <el-dropdown
          v-if="!isModeDashboardView"
          trigger="click"
          placement="bottom"
          @command="(x) => handleCommand(x, tabItem.id)"
        >
          <i
            :class="customIcon.VerticalThreeDot"
            class="vis-cursor-pointer"
            aria-hidden="true"
          ></i>

          <el-dropdown-menu class="vis-dropdown-menu">
            <el-dropdown-item
              v-for="popoverItem in popoverMenuItems"
              :key="popoverItem.id"
              :command="popoverItem"
            >
              <label class="vis-cursor-pointer">
                <i :class="popoverItem.icon" aria-hidden="true"></i>
                {{ $t(popoverItem.label) }}
              </label>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- ThreeDotIcon END -->
      </li>
      <li v-if="!isModeDashboardView" class="plusIcon">
        <i
          @click="addTabPanelNewTab()"
          :class="customIcon.Plus"
          aria-hidden="true"
        ></i>
      </li>
    </draggable>
    <ArrowScroll
      class="vis-tab-panel-arrow-scroll"
      ref="arrowScrollTabPanel"
      containerId="menu-container-tab-panel"
      containerItemClassName="menu-container-item-tab-panel"
      :scrollWidth="210"
    />
    <slot name="tabPanelContent"></slot>
  </div>
</template>
<script>
import cloneDeep from "clone-deep";
import { CustomIcon } from "../../../assets/js/custom-icons";
import { diff } from "deep-object-diff";
import draggable from "vuedraggable";
import ArrowScroll from "../../helper/ArrowScroll.vue";
export default {
  components: { draggable, ArrowScroll },
  props: {
    tabPanelMenuList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedTabPanelTabChartList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isModeDashboardView: {
      type: Boolean,
      default: false,
    },
    hasFootNote: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedTabPanelTabChartList: {
      handler(val) {
        if (val?.length) {
          this.$emit("setSelectedTabPanelTabChart");
        }
      },
      deep: true,
    },
    tabPanelMenuList: {
      handler(val) {
        if (diff(val, this.tabPanelTabList)) {
          this.tabPanelTabList = cloneDeep(val);
        }
      },
      deep: true,
    },
    tabPanelTabList: {
      handler() {
        this.triggerScroll();
      },
    },
    hasFootNote: {
      handler() {
        this.$emit("changeCurrentResizingChartId");
      },
    },
  },
  data() {
    return {
      activeTab: 0,
      customIcon: CustomIcon,
      tabPanelTabList: [],
      isTabNameEditActive: false,
    };
  },
  mounted() {
    this.tabPanelTabList = cloneDeep(this.tabPanelMenuList);

    this.$emit("changeTabPanelTabId", this.tabPanelTabList?.[0]?.id);

    setTimeout(() => {
      this.triggerScroll();
    }, 500);
  },
  computed: {
    popoverMenuItems() {
      return [
        {
          id: 1,
          icon: CustomIcon.Pencil,
          label: "generalPages.editName",
          serviceFn: () => this.editTabPanelTabName(),
          active: !this.isModeDashboardView,
        },
        {
          id: 2,
          icon: CustomIcon.Trash,
          label: "generalPages.delete",
          serviceFn: ({ tabId }) => this.deleteTabPanelTab({ tabId }),
          active: !this.isModeDashboardView,
        },
      ].filter((x) => x.active);
    },
  },
  methods: {
    triggerScroll() {
      this.$refs["arrowScrollTabPanel"].checkScrollArrowButtonAndSet();
    },
    triggerScrollToRight() {
      this.$refs["arrowScrollTabPanel"].scrollToRight();
    },
    changeDraggableList(evt) {
      this.$emit("chaneTabPanelTabList", this.tabPanelTabList);

      this.activeTab = evt?.moved?.newIndex;
    },
    addTabPanelNewTab() {
      this.$emit("addTabPanelNewTab");
      this.activeTab = this.tabPanelTabList?.length;

      this.triggerScrollToRight();
    },
    handleCommand(command, tabId) {
      command.serviceFn({ tabId });
    },
    deleteTabPanelTab({ tabId }) {
      this.$emit("deleteTabPanelTabByTabId", tabId);
      this.$emit("changeTabPanelTabId", this.tabPanelTabList?.[0]?.id);

      this.activeTab = 0;
      this.triggerScroll();
    },
    handleChangeTab(tabId, index) {
      //If input edit mode is active, the function will not work
      if (this.isTabNameEditActive) return;

      this.$emit("changeTabPanelTabId", tabId);

      setTimeout(() => {
        this.$emit("viewChartForTable");
      }, 700);

      this.activeTab = index;
    },
    editTabPanelTabName() {
      this.isTabNameEditActive = true;
    },
    changeTabName(value, tabId) {
      this.$emit("setSelectedTabPanelTabName", { value, tabId });
    },
  },
};
</script>

<style scoped>
input {
  border: none;
  line-height: 40px;
  font-size: 0.875rem;
}
.vis-tabpanel-plus-icon {
  position: absolute;
  top: 0;
  right: 26px;
}
.vis-dashboard-tab {
  height: 30px !important;
  margin-bottom: 10px;
  width: 90%;
  background-color: transparent;
}
#menu-container-tab-panel {
  scroll-behavior: smooth;
}
.vis-tab-panel-arrow-scroll {
  position: absolute;
  top: 14px;
  width: 90%; /* vis-dashboard-tab içindeki genişlik ile aynı olmalı (tabların olduğu div'in genişliği kadar)*/
}
::v-deep .vis-scroll-arrow {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  width: 26px !important;
  height: 26px !important;
  font-size: 1.25rem !important;
}
::v-deep .vis-scroll-arrow-left {
  left: 0px;
}
::v-deep .vis-scroll-arrow-right {
  right: 0;
}
.vis-dashboard-tab li input {
  width: auto;
}
.vis-dashboard-tab li:nth-child(1) {
  margin-left: 0;
}
.menu-container-item-tab-panel > div:first-child {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: auto;
  max-width: 16rem;
  min-width: 5rem;
  text-align: center;
  position: relative;
}
</style>
