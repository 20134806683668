import { defaultColorPalette } from "../commons/chartOptions"
import { getItemPercent, legendPositionEnums} from "../commons/dashboardProperties"

export const STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS = {
    STACKED_COLUMN_CHART_SHOW_VALUE_LABEL: true,
    STACKED_COLUMN_CHART_SHOW_PERCENT_TYPE: "COLUMN",
    STACKED_COLUMN_CHART_SHOW_HUNDRED_PERCENT:false,
    STACKED_COLUMN_CHART_SHOW_ONLY: getItemPercent(),
    STACKED_COLUMN_CHART_SHOW_INSIGHT: true,
    STACKED_COLUMN_CHART_SHOW_SQL: false,
    STACKED_COLUMN_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins",
    STACKED_COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
    STACKED_COLUMN_CHART_VALUE_LABEL_FONT_SIZE: 12,
    STACKED_COLUMN_CHART_VALUE_LABEL_ROTATE: 0,
    STACKED_COLUMN_CHART_VALUE_LABEL_FONT_COLOR: "#fff",
    STACKED_COLUMN_CHART_SHOW_LEGEND: false,
    STACKED_COLUMN_CHART_LEGEND_MAX_CHARACTER: 15,
    STACKED_COLUMN_CHART_LEGEND_POSITION: legendPositionEnums.TOP_CENTER,
    STACKED_COLUMN_CHART_LEGEND_FONT_FAMILY: "Poppins",
    STACKED_COLUMN_CHART_LEGEND_FONT_WEIGHT: 500,
    STACKED_COLUMN_CHART_LEGEND_VERTICAL_ALIGN: "top",
    STACKED_COLUMN_CHART_LEGEND_FONT_SIZE: 12,
    STACKED_COLUMN_CHART_LEGEND_FONT_COLOR: "#000",
    STACKED_COLUMN_CHART_SHOW_Y_AXIS_NAME: true,
    STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY: "Poppins",
    STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT: 500,
    STACKED_COLUMN_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN: "center",
    STACKED_COLUMN_CHART_Y_AXIS_NAME_VERTICAL_ALIGN: "bottom",
    STACKED_COLUMN_CHART_Y_AXIS_NAME_LOCATION: "middle",
    STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE: 12,
    STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR: "#000",
    STACKED_COLUMN_CHART_Y_AXIS_NAME_TEXT: "",
    STACKED_COLUMN_CHART_SHOW_X_AXIS_LINE: true,
    STACKED_COLUMN_CHART_SHOW_X_AXIS_NAME: true,
    STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY: "Poppins",
    STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT: 500,
    STACKED_COLUMN_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN: "center",
    STACKED_COLUMN_CHART_X_AXIS_NAME_VERTICAL_ALIGN: "top",
    STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_SIZE: 12,
    STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_COLOR: "#000",
    STACKED_COLUMN_CHART_X_AXIS_NAME_TEXT: "",
    STACKED_COLUMN_CHART_SHOW_Y_AXIS_LABEL: true,
    STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY: "Poppins",
    STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT: 500,
    STACKED_COLUMN_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN: "right",
    STACKED_COLUMN_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN: "middle",
    STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE: 12,
    STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR: "",
    STACKED_COLUMN_CHART_SHOW_X_AXIS_LABEL: true,
    STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY: "Poppins",
    STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT: 500,
    STACKED_COLUMN_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN: "center",
    STACKED_COLUMN_CHART_X_AXIS_LABEL_VERTICAL_ALIGN: "top",
    STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE: 12,
    STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR: "#000",
    STACKED_COLUMN_CHART_X_AXIS_GRID_LINE_COLOR: "#e0e6f1",
    STACKED_COLUMN_CHART_X_AXIS_LABEL_ROTATE: 0,
    STACKED_COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER: 10,
    STACKED_COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE: false,
    STACKED_COLUMN_CHART_Y_AXIS_MIN: null,
    STACKED_COLUMN_CHART_Y_AXIS_POSITION: "left",
    STACKED_COLUMN_CHART_Y_AXIS_SHOW_LINE: false,
    STACKED_COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR: "#000",
    STACKED_COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS: true,
    STACKED_COLUMN_CHART_SELECTED_COLOR_PALETTE: defaultColorPalette,
    STACKED_COLUMN_CHART_AUTO_REFRESH: 0,
    STACKED_COLUMN_CHART_AUTO_REFRESH_CUSTOM: null,
    STACKED_COLUMN_CHART_DYNAMIC_TITLE_VALUE: "",
    STACKED_COLUMN_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    STACKED_COLUMN_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
    STACKED_COLUMN__CHART_GROUP_MULTIPLE_VALUES: true,
    STACKED_COLUMN_CHART_ALLOW_DRILL_THROUGH: true
}