import { createMetricKey } from "../chartOptionsHelper";
import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";

export const gaugeChartSearch = (params, searchData, chartI) => {
  const result = {};

  if(!params || !searchData.aggregations || !searchData.aggregations.length) return result;

  const groups = {};

  params.metric.forEach(m => {
    groups[m.boxKey] = {
      metricKey: createMetricKey(m),
      name: m.field,
      format: m.format
    }
  })

  const data = searchData.aggregations[0];

  const dataSerie = {
    data: [
      { value: data[groups[BOX_KEY_ENUM.MEASURES.KEY]?.metricKey], name: groups[BOX_KEY_ENUM.MEASURES.KEY]?.name }
    ],
    name: groups[BOX_KEY_ENUM.MEASURES.KEY]?.name,
    max: data[groups[BOX_KEY_ENUM.MAX.KEY]?.metricKey] || 100,
    min: data[groups[BOX_KEY_ENUM.MIN.KEY]?.metricKey] || 0,
  };
  
  return {
    dataSerie,
    format: groups[BOX_KEY_ENUM.MEASURES.KEY]?.format,
    id: chartI,
  };
};

