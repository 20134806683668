<template>
  <div class="controls" ref="container" can-undo="true" can-redo="true">
    <i
      class="vis-cursor-pointer"
      :class="customIcon.ZoomIn"
      title="Zoom In"
      v-on:click="zoomIn()"
    ></i>
    <i
      class="vis-cursor-pointer"
      :class="customIcon.ZoomOut"
      title="Zoom Out"
      v-on:click="zoomOut()"
    ></i>
    <i
      class="vis-cursor-pointer"
      :class="customIcon.CenterFocusWeak"
      reset
      title="Center Content"
      v-on:click="centerContent()"
    ></i>
    <i
      class="vis-cursor-pointer"
      :class="customIcon.CenterFocusStrong"
      reset
      title="Zoom To Fit"
      v-on:click="zoomToFit()"
    ></i>
    <i
      class="vis-cursor-pointer"
      :class="customIcon.Undo"
      undo
      title="Undo last action"
      v-on:click="undo()"
    ></i>
    <i
      class="vis-cursor-pointer"
      :class="customIcon.Redo"
      redo
      title="Redo last action"
      v-on:click="redo()"
    ></i>
  </div>
</template>

<script>
import { getSurface } from "@jsplumbtoolkit/browser-ui-vue2";
import { EVENT_CANVAS_CLICK } from "@jsplumbtoolkit/browser-ui";
import { CustomIcon } from "../../../assets/js/custom-icons";

let surfaceId;

// a wrapper around getSurface, which expects a callback, as the surface may or may not have been
// initialised when calls are made to it.
function _getSurface(cb) {
  getSurface(surfaceId, cb);
}

export default {
  props: ["surfaceId"],
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  methods: {
    zoomIn: function () {
      _getSurface((s) => s.setZoom(s.getZoom() + 0.05));
    },
    zoomOut: function () {
      _getSurface((s) => s.setZoom(s.getZoom() - 0.05));
    },
    zoomToFit: function () {
      _getSurface((s) => {
        s.zoomToFit();
      });
    },
    centerContent: function () {
      _getSurface((s) => {
        s.centerContent();
      });
    },
    undo: function () {
      _getSurface((s) => {
        s.toolkitInstance.undo();
        s.magnetize();
      });
    },
    redo: function () {
      _getSurface((s) => {
        s.toolkitInstance.redo();
        s.magnetize();
      });
    },
  },
  mounted: function () {
    surfaceId = this.surfaceId;
    _getSurface((surface) => {
      surface.bind(EVENT_CANVAS_CLICK, () => {
        surface.toolkitInstance.clearSelection();
      });
    });
  },
};
</script>

<style scoped>
.controls {
  right: 2px !important;
  left: auto;
  top: 16px;
}
.controls i {
  background-color: transparent !important;
  color: #535353;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 4px;
  border: 1px solid white;
  font-size: 28px;
  font-style: normal;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.controls i:not(.selected-mode):hover {
  background-color: white;
  color: var(--primary-lighteen-1);
  border: 1px solid var(--primary-lighteen-1);
}
[can-undo="true"] [undo]:hover,
[can-redo="true"] [redo]:hover {
  background-color: white !important;
  color: var(--primary-lighteen-1) !important;
  border: 1px solid var(--primary-lighteen-1) !important;
}
</style>
