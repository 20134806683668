<template>
  <CustomPopup
    :fieldText="selectedField.alias"
    :titleText="$t('generalPages.drillPath')"
    :isCancelButtonActive="false"
    :width="800"
    @onClose="$emit('closeDrillPathPopupMenu')"
    @onSubmit="saveField()"
  >
    <template slot="titleRight">
      <i
        @click="addDrillPath()"
        aria-hidden="true"
        :class="customIcon.Plus"
        class="vis-mr--1 vis-cursor-pointer"
      ></i>
    </template>
    <div class="vis-drillpath-content vis-scroll-thin">
      <div
        class="vis-drillpath-hierarcy-card"
        v-for="(hierarcy, hierarcyIndex) in drillPaths"
        :key="hierarcy.id"
      >
        <div
          class="vis-drillpath-hierarcy-card-title vis-flex--spacebeetwen-Ycenter"
        >
          <input
            type="text"
            :placeholder="$t('generalPages.enterHierarchy')"
            v-model="hierarcy.name"
          />
          <i
            @click="deleteDrillPath(hierarcyIndex)"
            aria-hidden="true"
            :class="customIcon.Close"
            class="vis-cursor-pointer"
          ></i>
        </div>
        <div class="vis-drillpath-hierarcy-card-content vis-scroll-thin">
          <draggable
            class="list-group"
            :list="selectedDatasetFields"
            :group="{ name: 'drillPath', pull: 'clone', put: true }"
            @change="(event) => setDrillPaths(event, hierarcyIndex)"
          >
            <div
              class="list-group-item vis-flex--spacebeetwen-Ycenter"
              v-for="(path, pathIndex) in hierarcy.paths"
              :key="path.fieldId"
            >
              <div class="vis-flex--Ycenter">
                <i
                  :class="customIcon.Abc"
                  class="vis-color-primary vis-mr--05"
                  aria-hidden="true"
                ></i>
                <span> {{ getField(path).alias }}</span>
              </div>
              <i
                @click="removePathItem(hierarcyIndex, pathIndex)"
                aria-hidden="true"
                :class="customIcon.Close"
                class="vis-cursor-pointer"
              ></i>
            </div>
            <div class="vis-drillpath-hierarcy-card-empty vis-flex--Ycenter">
              <i
                aria-hidden="true"
                :class="customIcon.Plus"
                class="vis-mr--1"
              ></i>
              {{ $t("generalPages.dropDataItem") }}
            </div>
          </draggable>
        </div>
        <!--vis-drillpath-hierarcy-card-content end -->
      </div>
      <!--vis-drillpath--card end -->
    </div>
  </CustomPopup>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import draggable from "vuedraggable";
import cloneDeep from "clone-deep";
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
import { v4 as uuidv4 } from "uuid";
import CustomPopup from "./CustomPopup.vue";

export default {
  components: {
    draggable,
    CustomPopup,
  },
  props: {
    selectedField: {
      type: Object,
    },
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
      drillPaths: [
        {
          id: uuidv4(),
          name: "Hiyerarchy Name",
          paths: [],
        },
      ],
      selectedDatasetFields: [],
    };
  },
  mounted() {
    if (
      this.selectedField?.drillPaths ||
      this.selectedField?.drillPaths?.length
    ) {
      this.drillPaths = cloneDeep(this.selectedField.drillPaths);
    }
  },
  methods: {
    setDrillPaths(event, hierarcIndex) {
      const param = event.added.element;

      if (
        param.usageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE &&
        param.fieldId !== this.selectedField.fieldId
      )
        this.drillPaths[hierarcIndex].paths.push({
          fieldId: param.fieldId,
          datasetId: param.datasetId,
          name: param.name,
        });
    },
    addDrillPath() {
      this.drillPaths.push({ id: uuidv4(), name: "", paths: [] });
    },
    removePathItem(heararcyIndex, pathIndex) {
      this.drillPaths[heararcyIndex].paths.splice(pathIndex, 1);
    },
    deleteDrillPath(index) {
      this.drillPaths.splice(index, 1);
    },
    saveField() {
      const clonedSelectedField = cloneDeep(this.selectedField);

      clonedSelectedField.drillPaths = this.drillPaths;

      this.$emit("saveField", clonedSelectedField);
      this.$emit("closeDrillPathPopupMenu");
    },
    getField(field) {
      return this.fields[field.datasetId].find(
        (x) => x.fieldId == field.fieldId
      );
    },
  },
};
</script>
<style scoped>
.vis-drillpath-card {
  width: 800px;
}
.vis-drillpath-content {
  position: relative;
  width: 100%;
  min-height: 340px;
  display: -webkit-box;
  overflow-x: auto;
  color: var(--secondary-dark-1);
  border-bottom: 1px solid var(--layout-border-color);
}
.vis-drillpath-title-rightbox i {
  cursor: pointer;
}
.vis-drillpath-hierarcy-card {
  position: relative;
  width: 250px;
  height: auto;
  padding: 0;
  border-right: 1px solid var(--layout-border-color);
}
.vis-drillpath-hierarcy-card-empty {
  position: absolute;
  width: 100%;
  padding: 10px;
  opacity: 0.5;
  border-bottom: 1px solid var(--layout-border-color);
}
.vis-drillpath-hierarcy-card-title {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid #dadcdd;
  font-weight: 600;
}
.vis-drillpath-hierarcy-card-title input {
  border: none;
  font-weight: 600;
  color: var(--secondary-dark-1);
}
.vis-drillpath-hierarcy-card-title input::placeholder {
  opacity: 0.5;
  font-weight: normal;
}
.vis-drillpath-hierarcy-card-content {
  position: relative;
  width: 100%;
  padding: 0;
  height: 300px;
}
.vis-drillpath-hierarcy-card-content .list-group-item {
  padding: 8px 10px 8px 16px;
  border-bottom: 1px solid var(--layout-border-color);
  font-weight: 500;
}
.vis-drillpath-hierarcy-card-content .list-group {
  min-height: 100px;
}
.vis-drillpath-footer {
  position: relative;
  width: 100%;
  padding: 30px;
}
</style>
