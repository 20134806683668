<template>
  <div class="vis-mb--2">
    <div class="vis-row">
      <div class="vis-col vis-col-3">
        <label>{{ $t("filterComponents.period") }}</label>
        <el-select v-model="scheduleCronDtoUpdated.cronType">
          <el-option
            v-for="option in taskCronOptions"
            :key="option.key"
            :value="option.key"
            :label="option.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div v-if="scheduleCronDtoUpdated.cronType === schedulePeriodOptions.IntraDay">
      <ScheduleWhenTabPeriodIntraDay
        :daysOptions="daysOptions"
        :timeRangeOptions="timeRangeOptions"
        :existingTimeRange="selectedTimeRange"
        :existingSelectCronDto="cronDtoSelect"
        :scheduleStartEndHours="scheduleStartEndHours"
        @onTimeRangeUpdated="(value) => $emit('onTimeRangeUpdated', value)"
        @onscheduleStartEndHoursUpdated="(value) => $emit('onscheduleStartEndHoursUpdated', value)"
        @onCronDtoSelectUpdated="(value) => $emit('onCronDtoSelectUpdated', value)"
      />
    </div>
    <div v-if="scheduleCronDtoUpdated.cronType === schedulePeriodOptions.Daily">
      <ScheduleWhenTabPeriodDaily
        :scheduleCronDto="scheduleCronDto"
        :daysOptions="daysOptions"
        :dailyCronOptions="dailyCronOptions"
        :dailyAtTime="dailyAtTime"
        :existingSelectCronDto="cronDtoSelect"
        @onScheduleUpdated="(value) => $emit('onScheduleUpdated', value)"
        @onDailyAtTimeUpdated="(value) => $emit('onDailyAtTimeUpdated', value)"
        @onCronDtoSelectUpdated="(value) => $emit('onCronDtoSelectUpdated', value)"
      />
    </div>
    <div v-if="scheduleCronDtoUpdated.cronType === schedulePeriodOptions.Monthly">
      <ScheduleWhenTabPeriodMonthly
        :scheduleCronDto="scheduleCronDto"
        :monthsOptions="monthsOptions"
        :daysOptions="daysOptions"
        :monthlyCronOptions="monthlyCronOptions"
        :dailyAtTime="dailyAtTime"
        :existingSelectCronDto="cronDtoSelect"
        @onScheduleUpdated="(value) => $emit('onScheduleUpdated', value)"
        @onDailyAtTimeUpdated="(value) => $emit('onDailyAtTimeUpdated', value)"
        @onCronDtoSelectUpdated="(value) => $emit('onCronDtoSelectUpdated', value)"
      />
    </div>
  </div>
</template>

<script>
import { scheduleCronDtoDefaultValue, schedulePeriodOptions } from "./schedule.js";
import ScheduleWhenTabPeriodIntraDay from "./ScheduleWhenTabPeriodIntraDay.vue";
import ScheduleWhenTabPeriodDaily from "./ScheduleWhenTabPeriodDaily.vue";
import ScheduleWhenTabPeriodMonthly from "./ScheduleWhenTabPeriodMonthly.vue";
import cloneDeep from "clone-deep";

export default {
  components: {
    ScheduleWhenTabPeriodIntraDay,
    ScheduleWhenTabPeriodDaily,
    ScheduleWhenTabPeriodMonthly,
  },
  props: {
    scheduleCronDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
    monthsOptions: {
      type: Array,
    },
    daysOptions: {
      type: Array,
    },
    monthlyCronOptions: {
      type: Array,
    },
    dailyCronOptions: {
      type: Array,
    },
    timeRangeOptions: {
      type: Array,
    },
    taskCronOptions: {
      type: Array,
    },
    scheduleStartEndHours: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dailyAtTime: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cronDtoSelect: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedTimeRange: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      schedulePeriodOptions: schedulePeriodOptions,
      scheduleCronDtoUpdated: scheduleCronDtoDefaultValue,
    };
  },
  mounted(){
    this.scheduleCronDtoUpdated = cloneDeep(this.scheduleCronDto);
  },
  watch: {
    scheduleCronDtoUpdated: {
      deep: true,
      handler() {
        this.$emit("onScheduleUpdated", this.scheduleCronDtoUpdated);
      }
    },
  }
};
</script>

<style scoped>
.form-card {
  margin: 20px;
}

label {
  font-weight: bold;
}
</style>
