export const domain = "TRANSFER_ENVIRONMENT";

export const STATE = {
  ENVIRONMENTS: `${domain}/ENVIRONMENTS`,
};

export const GETTER = {
  GET_ENVIRONMENTS: `${domain}/GET_ENVIRONMENTS`,
};

export const ACTION = {
  FETCH_ENVIRONMENTS: `${domain}/FETCH_ENVIRONMENTS`,
  CREATE_ENVIRONMENT: `${domain}/CREATE_ENVIRONMENT`,
  DELETE_ENVIRONMENT: `${domain}/DELETE_ENVIRONMENT`,
  UPDATE_ENVIRONMENT: `${domain}/UPDATE_ENVIRONMENT`,
};

export const MUTATION = {
  SET_ENVIRONMENTS: `${domain}/SET_ENVIRONMENTS`,
};


