import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
import { checkIfFieldTypeIsDateAndFormatDateFilterValue, getFieldItemForFilter } from "../chart-filter/filterValueSetters";

//table.vue içerisinde selectedRowHighlight methodu içinde kullanılıyor.
export const getTableSelectedRowValue = (aggregations, tableArgs) => {
    const filterObj = {};
    aggregations.forEach((element) => {
        const fieldKey = element.field + element.slotFieldId;
        let filterValue = tableArgs.rowData ? tableArgs.rowData[fieldKey] : tableArgs.data[fieldKey];
        
        if (element.fieldUsageType != DatamodelContextDefaults.USAGE_TYPES.MEASURE && filterValue) {
            filterValue = checkIfFieldTypeIsDateAndFormatDateFilterValue(element, filterValue)

            filterObj[element.field] = {
                value: filterValue,
                alias: element.alias,
                fieldItem: getFieldItemForFilter(element)
            };
        }
    });

    return filterObj;
}

export const aggregateColumnProps = {
    footerTemplate: "footerTemplate",
    groupFooterTemplate: "groupFooterTemplate",
    groupCaptionTemplate: "groupCaptionTemplate",
}