export const domain = "SCENARIO_MAIN";

export const STATE = {
  SELECTED_SCENARIO: `${domain}/SELECTED_SCENARIO`,
  SCENARIOS: `${domain}/SCENARIOS`,
  SCENARIO_ITEM: `${domain}/SCENARIO_ITEM`,
  EVENTS: `${domain}/EVENTS`,
  ENRICHMENTS: `${domain}/ENRICHMENTS`,
  ACTIONS: `${domain}/ACTIONS`,
  DATASOURCES: `${domain}/DATASOURCES`,
  SELECTED_EVENT: `${domain}/SELECTED_EVENT`,
  SELECTED_ENRICHMENT: `${domain}/SELECTED_ENRICHMENT`,
  TEST_ENRICHMENT: `${domain}/TEST_ENRICHMENT`,
  IS_SCENARIO_MODULE_AVAILABLE: `${domain}/IS_SCENARIO_MODULE_AVAILABLE`,
};

export const GETTER = {
  GET_SELECTED_SCENARIO: `${domain}/GET_SELECTED_SCENARIO`,
  GET_SCENARIOS: `${domain}/GET_SCENARIOS`,
  GET_SCENARIO_ITEM: `${domain}/GET_SCENARIO_ITEM`,
  GET_EVENTS: `${domain}/GET_EVENTS`,
  GET_ENRICHMENTS: `${domain}/GET_ENRICHMENTS`,
  GET_ACTIONS: `${domain}/GET_ACTIONS`,
  GET_DATASOURCES: `${domain}/GET_DATASOURCES`,
  GET_SELECTED_EVENT: `${domain}/GET_SELECTED_EVENT`,
  GET_SELECTED_ENRICHMENT: `${domain}/GET_SELECTED_ENRICHMENT`,
  GET_TEST_ENRICHMENT: `${domain}/GET_TEST_ENRICHMENT`,
  GET_IS_SCENARIO_MODULE_AVAILABLE: `${domain}/GET_IS_SCENARIO_MODULE_AVAILABLE`,
};

export const ACTION = {
  FETCH_SELECTED_SCENARIO: `${domain}/FETCH_SELECTED_SCENARIO`,
  FETCH_SCENARIOS: `${domain}/FETCH_SCENARIOS`,
  FETCH_SCENARIO_ITEM: `${domain}/FETCH_SCENARIO_ITEM`,
  FETCH_EVENTS: `${domain}/FETCH_EVENTS`,
  FETCH_ENRICHMENTS: `${domain}/FETCH_ENRICHMENTS`,
  FETCH_ACTIONS: `${domain}/FETCH_ACTIONS`,
  FETCH_DATASOURCES: `${domain}/FETCH_DATASOURCES`,
  UPDATE_SCENARIO: `${domain}/UPDATE_SCENARIO`,
  CREATE_SCENARIO: `${domain}/CREATE_SCENARIO`,
  CREATE_EVENT: `${domain}/CREATE_EVENT`,
  UPDATE_EVENT: `${domain}/UPDATE_EVENT`,
  FETCH_EVENT_BY_ID: `${domain}/FETCH_EVENT_BY_ID`,
  CREATE_ENRICHMENT: `${domain}/CREATE_ENRICHMENT`,
  UPDATE_ENRICHMENT: `${domain}/UPDATE_ENRICHMENT`,
  FETCH_ENRICHMENT_BY_ID: `${domain}/FETCH_ENRICHMENT_BY_ID`,
  TEST_ENRICHMENT: `${domain}/TEST_ENRICHMENT`,
  CREATE_SIMULATE: `${domain}/CREATE_SIMULATE`,
  DEPLOY_SCENARIO: `${domain}/DEPLOY_SCENARIO`,
  UNDEPLOY_SCENARIO: `${domain}/UNDEPLOY_SCENARIO`,
  INIT_SCENARIO_MODULE: `${domain}/INIT_SCENARIO_MODULE`,
  DELETE_SCENARIO: `${domain}/DELETE_SCENARIO`,
  DELETE_EVENT: `${domain}/DELETE_EVENT`,
  DELETE_ENRICHMENT: `${domain}/DELETE_ENRICHMENT`,
};

export const MUTATION = {
  SET_SELECTED_SCENARIO: `${domain}/SET_SELECTED_SCENARIO`,
  SET_SCENARIOS: `${domain}/SET_SCENARIOS`,
  SET_SCENARIO_ITEM: `${domain}/SET_SCENARIO_ITEM`,
  SET_EVENTS: `${domain}/SET_EVENTS`,
  SET_ENRICHMENTS: `${domain}/SET_ENRICHMENTS`,
  SET_ACTIONS: `${domain}/SET_ACTIONS`,
  SET_DATASOURCES: `${domain}/SET_DATASOURCES`,
  SET_SELECTED_EVENT: `${domain}/SET_SELECTED_EVENT`,
  SET_SELECTED_ENRICHMENT: `${domain}/SET_SELECTED_ENRICHMENT`,
  SET_TEST_ENRICHMENT: `${domain}/SET_TEST_ENRICHMENT`,
  SET_IS_SCENARIO_MODULE_AVAILABLE: `${domain}/SET_IS_SCENARIO_MODULE_AVAILABLE`,
};
