import moment from "moment";
import VueI18n from '../i18n'

export const formatISOToDateTime = (isoDate) => {
    moment.locale(VueI18n.locale)
    return moment(isoDate).format("LLL")
}

export const formatISOToDateFromNow = (isoDate) => {
    moment.updateLocale(VueI18n.locale, {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: 'Today',
            ss: 'Today',
            m: "Today",
            mm: "Today",
            h: "Today",
            hh: "Today",
            d: "Yesterday",
            dd: "%d days",
            w: "a week",
            ww: "%d weeks",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
        }
    });

    const dateFormat = moment(isoDate).fromNow();
    if (dateFormat.includes("Today") || dateFormat.includes("Yesterday"))
        return dateFormat.split(" ")[0]
    else
        return dateFormat

}

export const formatIsoToDateFilter = (isoDate) => {
    return moment(isoDate).format("DD/MM/YYYY");
}