/* eslint-disable */
import { ACTION } from "./types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visFile = {
  actions: {
    [ACTION.POST_FILE_PREVIEW]: async (
      { commit },
      { bodyParam, loadingComponent }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.File,
        serviceMethodSub: serviceMethodSub.postFilePreview,
        bodyParam,
        resultSelector: (result) => result.data,
        withNotify: true,
        withLoading: true,
        loadingComponent,
      });
    },
    [ACTION.POST_FILE_UPLOAD]: async (
      { commit },
      { bodyParam, loadingComponent }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.File,
        serviceMethodSub: serviceMethodSub.postFileUpload,
        bodyParam,
        resultSelector: (result) => result.data,
        withNotify: true,
        withLoading: true,
        loadingComponent,
      });
    },
  },
};
