import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";

import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visScheduleTaskTypes = {
  state: {
    [STATE.MONTHS]: [],
    [STATE.DAYS]: [],
    [STATE.MONTHLY_CRON]: [],
    [STATE.DAILY_CRON]: [],
    [STATE.CRON]: [],
    [STATE.TIME_RANGES]: [],
  },
  getters: {
    [GETTER.GET_MONTHS]: (state) => {
      return state[STATE.MONTHS];
    },
    [GETTER.GET_DAYS]: (state) => {
      return state[STATE.DAYS];
    },
    [GETTER.GET_MONTHLY_CRON]: (state) => {
      return state[STATE.MONTHLY_CRON];
    },
    [GETTER.GET_DAILY_CRON]: (state) => {
      return state[STATE.DAILY_CRON];
    },
    [GETTER.GET_CRON]: (state) => {
      return state[STATE.CRON];
    },
    [GETTER.GET_TIME_RANGES]: (state) => {
      return state[STATE.TIME_RANGES];
    }
  },
  actions: {
    [ACTION.FETCH_MONTHS]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.TaskTypes,
        serviceMethodSub: serviceMethodSub.readAllMonths,
        successMutation: MUTATION.SET_MONTHS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
    [ACTION.FETCH_DAYS]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.TaskTypes,
        serviceMethodSub: serviceMethodSub.readAllDays,
        successMutation: MUTATION.SET_DAYS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
    [ACTION.FETCH_MONTHLY_CRON]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.TaskTypes,
        serviceMethodSub: serviceMethodSub.readMonthlyCron,
        successMutation: MUTATION.SET_MONTHLY_CRON,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
    [ACTION.FETCH_DAILY_CRON]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.TaskTypes,
        serviceMethodSub: serviceMethodSub.readDailyCron,
        successMutation: MUTATION.SET_DAILY_CRON,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
    [ACTION.FETCH_CRON]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.TaskTypes,
        serviceMethodSub: serviceMethodSub.readTypesCron,
        successMutation: MUTATION.SET_CRON,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
    [ACTION.FETCH_TIME_RANGES]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.TaskTypes,
        serviceMethodSub: serviceMethodSub.readTimeRanges,
        successMutation: MUTATION.SET_TIME_RANGES,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
  },
  mutations: {
    [MUTATION.SET_MONTHS](state, items) {
      Vue.set(state, [STATE.MONTHS], items);
    },
    [MUTATION.SET_DAYS](state, items) {
      Vue.set(state, [STATE.DAYS], items);
    },
    [MUTATION.SET_MONTHLY_CRON](state, items) {
      Vue.set(state, [STATE.MONTHLY_CRON], items);
    },
    [MUTATION.SET_DAILY_CRON](state, items) {
      Vue.set(state, [STATE.DAILY_CRON], items);
    },
    [MUTATION.SET_CRON](state, items) {
      Vue.set(state, [STATE.CRON], items);
    },
    [MUTATION.SET_TIME_RANGES](state, items) {
      Vue.set(state, [STATE.TIME_RANGES], items);
    },
  },
};
