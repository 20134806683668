<template>
  <div class="aside-properties">
    <div class="enrichment-aside-title">
      <span class="enrichment-aside-title-icon material-icons-outlined">
        lan
      </span>
      {{ $t("Enrichment Settings") }}
    </div>
    <div class="enrichment-aside-properties-container">
      <el-tabs :stretch="true">
        <el-tab-pane>
          <span slot="label"> {{ $t("General") }}</span>
          <div class="enrichment-aside-properties-general-tab">
            <div class="vis-mt--1 vis-mb--1">
              <label>{{ $t("Name") }}</label>
              <el-input
                :value="properties.enrichmentName"
                @input="(v) => $emit('updateName', v)"
              />
            </div>
            <div class="vis-mb--1">
              <label>{{ $t("Description") }}</label>
              <el-input
                :value="properties.enrichmentDescription"
                @input="(v) => $emit('updateDescription', v)"
              />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-tabs :stretch="true">
        <el-tab-pane>
          <span slot="label"> {{ $t("Source") }}</span>
          <div class="enrichment-aside-properties-general-tab">
            <div class="vis-mt--1 vis-mb--1">
              <label>{{ $t("Connection") }}</label>
              <el-select
                :value="properties.enrichmentConnection"
                :placeholder="$t('Connection')"
                @change="(value) => $emit('updateConnection', value)"
              >
                <el-option
                  v-for="item in datasources"
                  :key="item.connectionId"
                  :label="item.name"
                  :value="item.connectionId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    properties: {
      type: Object,
    },
    datasources: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.aside-properties {
  padding: 20px !important;
}
.enrichment-aside {
  display: flex;
  align-items: center;
  padding: 18px 25px;
  height: inherit;
  flex-direction: column;
}
.enrichment-aside-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.enrichment-aside-title-icon {
  color: #0014ff;
  font-size: 1.625rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.enrichment-aside-properties-container {
  width: 100%;
}
</style>
