var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vis-topbar vis-ma--none vis-setting-bar"},[_c('div',{staticClass:"vis-row vis-height-100"},[_c('div',{staticClass:"vis-dashboard-custom-col vis-flex--Ycenter vis-mobile-hidden vis-right-border"},[_c('div',{staticClass:"vis-settings-tab-link"},[_c('div',{staticClass:"link-item"},[_c('span',[_vm._v(_vm._s(_vm.$t("ingest.Data Flow")))])])])]),_c('div',{staticClass:"vis-col vis-mb--none vis-text--right vis-flex--Xright vis-flex--Ycenter vis-mobile-hidden vis-icon-position"},[(_vm.showIngestActions)?_c('div',{staticClass:"vis-display-flex vis-flex--Ycenter"},_vm._l((_vm.iconList),function(icon){return _c('i',{key:icon.id,staticClass:"vis-cursor-pointer",class:[
              icon.className,
              {
                'vis-active-setting-icon': icon.isActiveClass,
              },
              { 'vis-display-none': !icon.show },
            ],attrs:{"aria-hidden":"true"},on:{"click":function($event){return icon.action()}}})}),0):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }