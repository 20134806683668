export const domain = "VISUALIZE_CHART_FIELDS";

export const STATE = {
    CHART_FIELDS: `${domain}/CHART_FIELDS`
};
export const GETTER = {
    GET_CHART_BY_ID_FIELDS: `${domain}/GET_CHART_BY_ID_FIELDS`
};

export const ACTION = {
    FETCH_CHART_BY_ID_FIELDS: `${domain}/FETCH_CHART_BY_ID_FIELDS`,
    CREATE_CHART_FIELDS: `${domain}/CREATE_CHART_FIELDS`,
    UPDATE_CHART_FIELDS: `${domain}/UPDATE_CHART_FIELDS`,
    DELETE_CHART_FIELDS: `${domain}/DELETE_CHART_FIELDS`,
    FETCH_MAP_DATA: `${domain}/FETCH_MAP_DATA`,
};

export const MUTATION = {
    SET_CHART_FIELDS: `${domain}/SET_CHART_FIELDS`,
};