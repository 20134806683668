export const domain = "VISUALIZE_SCHEDULE_TASK_TYPES";

export const STATE = {
    MONTHS: `${domain}/MONTHS`,
    DAYS: `${domain}/DAYS`,
    MONTHLY_CRON: `${domain}/MONTHLY_CRON`,
    DAILY_CRON: `${domain}/DAILY_CRON`,
    CRON: `${domain}/CRON`,
    TIME_RANGES: `${domain}/TIME_RANGES`,
};

export const GETTER = {
    GET_MONTHS: `${domain}/GET_MONTHS`,
    GET_DAYS: `${domain}/GET_DAYS`,
    GET_MONTHLY_CRON: `${domain}/GET_MONTHLY_CRON`,
    GET_DAILY_CRON: `${domain}/GET_DAILY_CRON`,
    GET_CRON: `${domain}/GET_CRON`,
    GET_TIME_RANGES: `${domain}/GET_TIME_RANGES`,
};

export const ACTION = {
    FETCH_MONTHS: `${domain}/FETCH_MONTHS`,
    FETCH_DAYS: `${domain}/FETCH_DAYS`,
    FETCH_MONTHLY_CRON: `${domain}/FETCH_MONTHLY_CRON`,
    FETCH_DAILY_CRON: `${domain}/FETCH_DAILY_CRON`,
    FETCH_CRON: `${domain}/FETCH_CRON`,
    FETCH_TIME_RANGES: `${domain}/FETCH_TIME_RANGES`,
};

export const MUTATION = {
    SET_MONTHS: `${domain}/SET_MONTHS`,
    SET_DAYS: `${domain}/SET_DAYS`,
    SET_MONTHLY_CRON: `${domain}/SET_MONTHLY_CRON`,
    SET_DAILY_CRON: `${domain}/SET_DAILY_CRON`,
    SET_CRON: `${domain}/SET_CRON`,
    SET_TIME_RANGES: `${domain}/SET_TIME_RANGES`,
};