import { getConcatArrayWithPipe } from "../chartOptionsHelper";
import { groupBy } from 'lodash';
import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";

export const heatmapSearch = (params, searchData, chartI) => {
    const { row, column } = groupBy(params.aggregation, (val) => val.boxKey);
    const metricKeys = params.metric.map((e) => `${e.field}${e.type}`);
    const aggregationFormats = params?.aggregation?.map((a) => a.format);

    const metricFormatsMapping = params.metric?.reduce((accumulator, value) => {
        return { ...accumulator, [value.field]: value.format };
    }, {});

    const yAxisKeys = row?.map(i => i.field);
    const xAxisKeys = column?.map(i => i.field);
    const axisNames = {
        x: getConcatArrayWithPipe(column?.map(i => i.alias)),
        y: getConcatArrayWithPipe(row?.map(i => i.alias)),
    };
    const dimensionNames = [axisNames.x, axisNames.y]

    const yAxisData = new Set(), xAxisData = new Set();
    let maxValue = 0;

    const dataSeries = searchData.aggregations.map(i => {
        const xAxisValue = getConcatArrayWithPipe(xAxisKeys?.map(key => i[key])) || '';
        const yAxisValue = getConcatArrayWithPipe(yAxisKeys?.map(key => i[key])) || '';
        yAxisData.add(yAxisValue);
        xAxisData.add(xAxisValue);
        const value = i[metricKeys[0]];

        if (maxValue < value) {
            maxValue = value;
        }

        return [xAxisValue, yAxisValue, value];
    });

    const formatMap = {
        x: params.aggregation
          .filter((i) => i.boxKey === BOX_KEY_ENUM.COLUMN.KEY)
          .map((i) => i.format),
        y: params.aggregation
          .filter((i) => i.boxKey === BOX_KEY_ENUM.ROW.KEY)
          .map((i) => i.format),
      }
      let dataSeriesTotal = 0;
      if (dataSeries?.length) {
        dataSeries?.forEach((item) => 
        (dataSeriesTotal += parseInt(item?.[2])))
      }
      return {
        yAxisData: [...yAxisData],
        xAxisData: [...xAxisData],
        dataSeries: [dataSeries],
        dataSeriesTotal,
        dataSeriesNames: params.metric?.map((e) => e.field),
        metricFormatsMapping,
        dimensionNames,
        maxValue,
        axisNames,
        id: chartI,
        aggregationFormats,
        formatMap
    };
}