/* eslint-disable */
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
/* import _ from "lodash"; */
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const visAuthorization = {
    state: {
        [STATE.AUTHORIZATION]: [],
        [STATE.USERS]: [],
        [STATE.GROUPS]: [],
        [STATE.DASHBOARD_AUTHORIZATION_BY_ID]: [],
        [STATE.DATAMODEL_AUTHORIZATION_BY_ID]: [],
        [STATE.FOLDER_AUTHORIZATION_BY_ID]: [],
        [STATE.FOLDER_AUTHORIZATION_TYPES]: [],
        [STATE.DATAFLOW_AUTHORIZATION_BY_ID]: [],
    },
    getters: {
        [GETTER.GET_USERS]: (state) => {
            return state[STATE.USERS];
        },
        [GETTER.GET_GROUPS]: (state) => {
            return state[STATE.GROUPS];
        },
        [GETTER.GET_AUTHORIZATION]: (state) => {
            return state[STATE.AUTHORIZATION];
        },
        [GETTER.GET_DASHBOARD_AUTHORIZATION_BY_ID]: (state) => {
            return state[STATE.DASHBOARD_AUTHORIZATION_BY_ID];
        },
        [GETTER.GET_DATAMODEL_AUTHORIZATION_BY_ID]: (state) => {
            return state[STATE.DATAMODEL_AUTHORIZATION_BY_ID];
        },
        [GETTER.GET_FOLDER_AUTHORIZATION_BY_ID]: (state) => {
            return state[STATE.FOLDER_AUTHORIZATION_BY_ID];
        },
        [GETTER.GET_FOLDER_AUTHORIZATION_TYPES]: (state) => {
            return state[STATE.FOLDER_AUTHORIZATION_TYPES];
        },
        [GETTER.GET_DATAFLOW_AUTHORIZATION_BY_ID]: (state) => {
            return state[STATE.DATAFLOW_AUTHORIZATION_BY_ID];
        },
    },
    actions: {
        [ACTION.FETCH_AUTHORIZATION]: async ({ commit }, { loadingComponent }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readAuthorization,
                    successMutation: MUTATION.SET_AUTHORIZATION,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    loadingComponent,
                },
            );
        },
        [ACTION.FETCH_USERS]: async ({ commit }, queryParam) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readUsers,
                    successMutation: MUTATION.SET_USERS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    queryParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.FETCH_GROUPS]: async ({ commit }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readGroups,
                    successMutation: MUTATION.SET_GROUPS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },

        [ACTION.FETCH_DASHBOARD_AUTHORIZATION_BY_ID]: async ({ commit }, id) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readDashboardAuthorizationById,
                    queryParam: id,
                    successMutation: MUTATION.SET_DASHBOARD_AUTHORIZATION_BY_ID,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.FETCH_DASHBOARD_AUTHORIZATION_BY_ID_WITHOUT_MUTATE]: async ({ commit }, id) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readDashboardAuthorizationById,
                    queryParam: id,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID]: async ({ commit }, id) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readDatamodelAuthorizationById,
                    queryParam: id,
                    successMutation: MUTATION.SET_DATAMODEL_AUTHORIZATION_BY_ID,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID_WITHOUT_MUTATE]: async ({ commit }, id) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readDatamodelAuthorizationById,
                    queryParam: id,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID]: async ({ commit }, id) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readFolderAuthorizationById,
                    queryParam: id,
                    successMutation: MUTATION.SET_FOLDER_AUTHORIZATION_BY_ID,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID_WITHOUT_MUTATE]: async ({ commit }, id) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readFolderAuthorizationById,
                    queryParam: id,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.FETCH_DATAFLOW_AUTHORIZATION_BY_ID]: async ({ commit }, id) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readDataFlowAuthorizationById,
                    queryParam: id,
                    successMutation: MUTATION.SET_DATAFLOW_AUTHORIZATION_BY_ID,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.FETCH_FOLDER_AUTHORIZATION_TYPES]: async ({ commit }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.readFolderAuthorizationTypes,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                },
            );
        },
        [ACTION.CREATE_DASHBOARD_AUTHORIZATION]: async ({ commit, dispatch }, { payload, id }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.createDashboardAuthorization,
                    bodyParam: payload,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DASHBOARD_AUTHORIZATION_BY_ID, id)
        },
        [ACTION.CREATE_DATAMODEL_AUTHORIZATION]: async ({ commit, dispatch }, { payload, id }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.createDatamodelAuthorization,
                    bodyParam: payload,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID, id)
        },
        [ACTION.CREATE_FOLDER_AUTHORIZATION]: async ({commit, dispatch}, { payload, id} ) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.createFolderAuthorization,
                    bodyParam: payload,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                }
            );

            await dispatch(ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID, id);
        },
        [ACTION.UPDATE_FOLDER_AUTHORIZATION_BY_ID]: async ({commit, dispatch}, { updateId, bodyParam, refreshId } ) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateFolderAuthorizationById,
                    queryParam: updateId,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                }
            );

            await dispatch(ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID, refreshId);
        },
        [ACTION.CREATE_DATAFLOW_AUTHORIZATION]: async ({ commit, dispatch }, { payload, id }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.createDataFlowAuthorization,
                    bodyParam: payload,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DATAFLOW_AUTHORIZATION_BY_ID, id)
        },
        [ACTION.DELETE_DASHBOARD_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { removeId, refreshId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.deleteDashboardAuthorizationById,
                    queryParam: removeId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DASHBOARD_AUTHORIZATION_BY_ID, refreshId)
        },
        [ACTION.DELETE_DATAMODEL_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { removeId, refreshId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.deleteDatamodelAuthorizationById,
                    queryParam: removeId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID, refreshId)
        },
        [ACTION.DELETE_FOLDER_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, {removeId, refreshId}) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.deleteFolderAuthorizationById,
                    queryParam: removeId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID, refreshId);
        },
        [ACTION.DELETE_DATAFLOW_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { removeId, refreshId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.deleteDataFlowAuthorizationById,
                    queryParam: removeId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DATAFLOW_AUTHORIZATION_BY_ID, refreshId)
        },
        [ACTION.UPDATE_DASHBOARD_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { updateId, bodyParam, refreshId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateDashboardAuthorizationById,
                    queryParam: updateId,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DASHBOARD_AUTHORIZATION_BY_ID, refreshId)
        },
        [ACTION.UPDATE_DASHBOARD_PARENT_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { dashboardId, useParentAuthorization }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateDashboardParentAuthorizationById,
                    queryParam: { dashboardId, useParentAuthorization },
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DASHBOARD_AUTHORIZATION_BY_ID, dashboardId)
        },
        [ACTION.UPDATE_DATAMODEL_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { updateId, bodyParam, refreshId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateDatamodelAuthorizationById,
                    queryParam: updateId,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID, refreshId)
        },
        [ACTION.UPDATE_DATAMODEL_PARENT_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { datamodelId, useParentAuthorization }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateDatamodelParentAuthorizationById,
                    queryParam: { datamodelId, useParentAuthorization },
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID, datamodelId)
        },
        [ACTION.CREATE_FOLDER_OWNER_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { bodyParam, refreshId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.createFolderOwnerAuthorizationById,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID, refreshId)
        },
        [ACTION.UPDATE_FOLDER_OWNER_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { updateId, refreshId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateFolderOwnerAuthorizationById,
                    queryParam: updateId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID, refreshId)
        },
        [ACTION.UPDATE_FOLDER_PARENT_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, {  folderId, useParentAuthorization }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateFolderParentAuthorizationById,
                    queryParam: {  folderId, useParentAuthorization },
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID, folderId)
        },
        [ACTION.UPDATE_DATAFLOW_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { updateId, bodyParam, refreshId }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateDataFlowAuthorizationById,
                    queryParam: updateId,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DATAFLOW_AUTHORIZATION_BY_ID, refreshId)
        },
        [ACTION.UPDATE_DATAFLOW_PARENT_AUTHORIZATION_BY_ID]: async ({ commit, dispatch }, { dataFlowId, useParentAuthorization }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Authorization,
                    serviceMethodSub: serviceMethodSub.updateDataFlowParentAuthorizationById,
                    queryParam: { dataFlowId, useParentAuthorization },
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                },
            );

            await dispatch(ACTION.FETCH_DATAFLOW_AUTHORIZATION_BY_ID, dataFlowId)
        },
    },
    mutations: {
        [MUTATION.SET_AUTHORIZATION](state, items) {
            Vue.set(state, [STATE.AUTHORIZATION], items);
        },
        [MUTATION.SET_USERS](state, items) {
            Vue.set(state, [STATE.USERS], items);
        },
        [MUTATION.SET_GROUPS](state, items) {
            Vue.set(state, [STATE.GROUPS], items);
        },
        [MUTATION.SET_DASHBOARD_AUTHORIZATION_BY_ID](state, items) {
            Vue.set(state, [STATE.DASHBOARD_AUTHORIZATION_BY_ID], items);
        },
        [MUTATION.SET_DATAMODEL_AUTHORIZATION_BY_ID](state, items) {
            Vue.set(state, [STATE.DATAMODEL_AUTHORIZATION_BY_ID], items);
        },
        [MUTATION.SET_FOLDER_AUTHORIZATION_BY_ID](state, items) {
            Vue.set(state, [STATE.FOLDER_AUTHORIZATION_BY_ID], items);
        },
        [MUTATION.SET_DATAFLOW_AUTHORIZATION_BY_ID](state, items) {
            Vue.set(state, [STATE.DATAFLOW_AUTHORIZATION_BY_ID], items);
        },
    },
};
