<template>
  <div class="scenario-aside-properties-general-tab">
    <span>{{ $t("Select the event you want to use in this transition") }}</span>
    <div class="vis-mt--1 vis-mb--1">
      <label>{{ $t("Events Used") }}</label>

      <div
        v-for="event in properties.events"
        :key="event"
        class="used-event-item"
      >
        <el-input :value="event" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    properties: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.used-event-item {
  margin-bottom: 10px;
}
label {
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #3e3e3e;
  margin-bottom: 10px;
}
</style>
