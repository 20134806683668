<template>
  <div>
    <el-tooltip
      :disabled="!tooltipEnabled"
      :placement="tooltipPlacement"
      effect="primary"
    >
      <div slot="content">
        <slot name="tooltipContent"> {{ tooltipContent }}</slot>
      </div>
      <div v-if="showText" :class="typographyClassesMerged">
        {{ text }}
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    tooltipContent: {
      type: String,
    },
    tooltipEnabled: {
      type: Boolean,
    },
    //top, right, left, bottom
    tooltipPlacement: {
      type: String,
      default: "top",
    },
    //body1, body2, h1, h2, h3, h4, h5, h6, subtitle
    variant: {
      type: String,
      default: "body1",
    },
    text: {
      type: String,
      required: true,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    truncateText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    typographyClass() {
      return "custom-typography-" + this.variant;
    },
    typographyClassesMerged() {
      return `${this.typographyClass} ${
        this.truncateText ? "typography-text-truncate" : ""
      }`;
    },
  },
};
</script>

<style scoped>
.custom-typography-body1 {
  font-size: 0.75rem;
  font-weight: 400;
}
.custom-typography-body2 {
  font-size: 0.875rem;
  font-weight: 400;
}
.custom-typography-body3 {
  font-size: 0.75rem;
  font-weight: 500;
}
.custom-typography-h1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.custom-typography-h5 {
  font-size: 0.75rem;
  font-weight: 600;
}
.typography-text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
