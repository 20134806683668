<template>
  <DashboardProperties
    ref="DashboardProperties"
    :componentStyle="propertiesPanel.style"
    :chartTabIndex="propertiesPanel.tabIndex"
    :selectedColumnBasedFormattingsProperties="selectedColumnBasedFormattingsProperties"
    :selectedColumnField="selectedColumnField"
    :selectedTemplateId="null"
    :chartType="chartType"
    :chartTypeName="chartTypeName"
    :activeComponentType="activeComponentType"
    :colorPalettes="colorPalettes"
    :customFilterType="customFilterType"
    :dashboardTabsOptionList="dashboardTabsOptionList"
    @setComponentStyle="setPanelStyle"
    @setDataTableColumnBasedComponentProperties="setDataTableColumnBasedComponentProperties"
    @setDashboardStyle="setDashboardStyle"
    @closeColumnBasedProperties="$emit('closeColumnBasedProperties')"
    @onPropertiesEventTriggered="value => $emit('onPropertiesEventTriggered', value)"
  />
</template>

<script>
import { chartTypes } from "../../commons/dashboardAndPanel";
import DashboardProperties from "../dashboard/properties/DashboardProperties.vue";

export default {
  components: { DashboardProperties },
  props: {
    propertiesPanel: {
      type: Object,
    },
    chartType: {
      type: String,
    },
    chartTypeName: {
      type: String,
    },
    colorPalettes: {
      type: Array,
    },
    customFilterType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isColumnBasedPropertiesSelected: {
      type: Boolean,
    },
    selectedColumnBasedFormattingsProperties: {
      type: Object,
    },
    selectedColumnField: {
      type: Object
    },
    dashboardTabsOptionList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chartTypes: chartTypes,
      dashboardComponentTypes: {
        GENERAL: "GENERAL",
        CHART: "CHART",
        DATA_TABLE_COLUMN: "DATA_TABLE_COLUMN",
        PIVOT_TABLE_COLUMN: "PIVOT_TABLE_COLUMN",
      },
    };
  },
  methods: {
    closeColumnBasedProperties() {
        this.$refs.DashboardProperties?.closeColumnBasedProperties();
    },
    setPanelStyle(params) {
      this.$emit("setPanelStyle", params);
    },
    setDataTableColumnBasedComponentProperties(params) {
      this.$emit("setDataTableColumnBasedComponentProperties", params);
    },
    setDashboardStyle(params) {
      this.$emit("setDashboardStyle", params);
    },
  },
  computed: {
    activeComponentType() {
      return this.isColumnBasedPropertiesSelected
      ? this.chartType === chartTypes.PIVOT_TABLE
          ? this.dashboardComponentTypes.PIVOT_TABLE_COLUMN
          : this.dashboardComponentTypes.DATA_TABLE_COLUMN
        : this.dashboardComponentTypes.CHART;
    },
  },
};
</script>
<style scoped></style>
