
import {
    locationItems,
    alignItems,
    rotateArr,
    fontsArr,
    fontSizesArr,
    fontWeightsArr,
    HEATMAP_PROPERTIES_ENUM,
    predefineColorsArr,
    verticalAlignItems,
    yAxisPositionArr,
    visualMapTypes,
    allPropertiesKeys,
    autoRefreshTimes,
    legendPositionArr,
    legendMaxCharArr,
    showOnlyArr
} from "../../commons/dashboardProperties";
import { HEATMAP_DEFAULT_CONFIGURATIONS} from "../../mocks/heatmapDefaultProperties";
import ColorPaletteSelector from "../../components/panel/properties/ColorPaletteSelector";
import { getPropertiesWithUndefinedCheck } from "../../util/chartViewHelper";

export default {
    computed: {
        heatmapComponentsMapping() {
            return {
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VALUE_LABEL]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VALUE_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VALUE_LABEL
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VALUE_LABEL,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_ONLY]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_ONLY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showOnly.title",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_ONLY
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_ONLY),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_ONLY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: showOnlyArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_INSIGHT
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_ALLOW_DRILL_THROUGH]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_ALLOW_DRILL_THROUGH,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.Allow Drill Through",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_ALLOW_DRILL_THROUGH
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_ALLOW_DRILL_THROUGH),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_ALLOW_DRILL_THROUGH,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_SQL]: {
                    id: this.getPropertyId(
                      HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_SQL],
                      HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_SQL,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_FAMILY
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VALUE_LABEL_FONT_FAMILY),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_WEIGHT
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VALUE_LABEL_FONT_WEIGHT),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_SIZE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VALUE_LABEL_FONT_SIZE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_COLOR
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VALUE_LABEL_FONT_COLOR),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_HIDE_OVERLAPPED_VALUE_LABELS]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_HIDE_OVERLAPPED_VALUE_LABELS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.hideOverlap",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_HIDE_OVERLAPPED_VALUE_LABELS
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_HIDE_OVERLAPPED_VALUE_LABELS),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_HIDE_OVERLAPPED_VALUE_LABELS,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LINE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LINE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLine",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LINE
                    ], HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LINE),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LINE,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_NAME]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_NAME
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_X_AXIS_NAME),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_NAME,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_HORIZONTAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_NAME_HORIZONTAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_VERTICAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_NAME_VERTICAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_FAMILY
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_NAME_FONT_FAMILY),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_WEIGHT
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_NAME_FONT_WEIGHT),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_SIZE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_SIZE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_NAME_FONT_SIZE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_COLOR]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_COLOR
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_NAME_FONT_COLOR),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },


                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_NAME]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_NAME
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_Y_AXIS_NAME),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_NAME,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_HORIZONTAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_NAME_HORIZONTAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_VERTICAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_NAME_VERTICAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_LOCATION]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_LOCATION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.location",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_LOCATION
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_NAME_LOCATION),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_LOCATION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: locationItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_FAMILY
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_NAME_FONT_FAMILY),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_WEIGHT
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_NAME_FONT_WEIGHT),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_SIZE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_SIZE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_NAME_FONT_SIZE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_COLOR]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_COLOR
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_NAME_FONT_COLOR),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LABEL]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LABEL
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_X_AXIS_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LABEL,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_HORIZONTAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_LABEL_HORIZONTAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_VERTICAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_LABEL_VERTICAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_FAMILY
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_LABEL_FONT_FAMILY),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_WEIGHT
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_LABEL_FONT_WEIGHT),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_SIZE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_LABEL_FONT_SIZE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_COLOR
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_LABEL_FONT_COLOR),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_LABEL]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_LABEL
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_Y_AXIS_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_LABEL,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_HORIZONTAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_LABEL_HORIZONTAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_VERTICAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_LABEL_VERTICAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_FAMILY
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_LABEL_FONT_FAMILY),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_SIZE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_LABEL_FONT_SIZE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_COLOR
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_LABEL_FONT_COLOR),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_POSITION]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.yAxis.position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_POSITION
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_POSITION),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_POSITION,
                    selectValues: yAxisPositionArr,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_SHOW_LINE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_SHOW_LINE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLine",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_SHOW_LINE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_SHOW_LINE),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_SHOW_LINE,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LINE_STYLE_COLOR]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LINE_STYLE_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLineColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LINE_STYLE_COLOR
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_LINE_STYLE_COLOR),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LINE_STYLE_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_ROTATE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_ROTATE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.rotate",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_ROTATE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_LABEL_ROTATE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_ROTATE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: rotateArr,
                    flexClass: false,
                },
                //-------------
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VISUALMAP]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VISUALMAP,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VISUALMAP
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SHOW_VISUALMAP),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VISUALMAP,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_MAX_CHARACTER]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_MAX_CHARACTER,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Max Character",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_MAX_CHARACTER
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VISUALMAP_MAX_CHARACTER),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_MAX_CHARACTER,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendMaxCharArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_TYPE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_TYPE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.type",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_TYPE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VISUALMAP_TYPE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_TYPE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: visualMapTypes,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_POSITION]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.Visual Map Position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_POSITION
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VISUALMAP_POSITION),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_POSITION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendPositionArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_VERTICAL_ALIGN
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VISUALMAP_VERTICAL_ALIGN),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_SIZE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_SIZE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VISUALMAP_FONT_SIZE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_FAMILY
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VISUALMAP_FONT_FAMILY),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_WEIGHT
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VISUALMAP_FONT_WEIGHT),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_COLOR]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.visualMap.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_COLOR
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_VISUALMAP_FONT_COLOR),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_COLOR,
                    componentType: this.componentTypes.COLOR_PICKER,
                    size: "mini",
                    selectValues: predefineColorsArr,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_SELECTED_COLOR_PALETTE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SELECTED_COLOR_PALETTE,
                        this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_SELECTED_COLOR_PALETTE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_SELECTED_COLOR_PALETTE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_SELECTED_COLOR_PALETTE,
                    dashboardProperty: allPropertiesKeys.CUSTOM_COLOR_PALETTES,
                    componentType: this.componentTypes.OTHER,
                    component: ColorPaletteSelector
                },

                [HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_AUTO_REFRESH),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH_CUSTOM
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_AUTO_REFRESH_CUSTOM),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_VALUE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_DYNAMIC_TITLE_VALUE),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_SHOW_VALUE
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [HEATMAP_PROPERTIES_ENUM.HEATMAP_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        HEATMAP_PROPERTIES_ENUM.HEATMAP_GROUP_MULTIPLE_VALUES
                    ], HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: HEATMAP_PROPERTIES_ENUM.HEATMAP_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
            }
        },
    },
};
