var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',{attrs:{"articleId":"article","showNav":false,"showAside":_vm.showEventAside}},[_c('div',{attrs:{"slot":"modals"},slot:"modals"}),_c('div',{staticClass:"event-header",attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"event-header-title"},[_vm._v(_vm._s(_vm.$t("Event")))]),_c('div',{staticClass:"vis-ml--auto event-header-actions"},[_c('i',{staticClass:"vis-cursor-pointer vis-position-relative",class:_vm.CustomIcon.SaveOutlined,attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.saveEvent.apply(null, arguments)}}}),_c('i',{staticClass:"vis-cursor-pointer vis-position-relative",class:[
          _vm.CustomIcon.Filter,
          {
            'vis-active-setting-icon': _vm.showEventAside,
          },
        ],attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAside.apply(null, arguments)}}})])]),_c('div',{attrs:{"slot":"aside"},slot:"aside"},[_c('EventAsideProperties',{attrs:{"properties":_vm.properties},on:{"updateName":(value) => (_vm.properties.eventName = value),"updateDescription":(value) => (_vm.properties.eventDescription = value)}})],1),_c('div',{staticClass:"vis-height-100 event-article-container",attrs:{"slot":"article"},slot:"article"},[_c('div',{staticClass:"event-table-top-bar"},[_c('span',{staticClass:"fields-table-title"},[_vm._v(_vm._s(_vm.$t("Fields")))]),_c('a',{staticClass:"field-add-button",on:{"click":_vm.addNewFieldItem}},[_vm._v(_vm._s(_vm.$t("Add Field")))])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == _vm.columnKeys.field)?_c('span',[_c('el-input',{attrs:{"placeholder":_vm.$t('Field name'),"value":props.row.field},on:{"input":(value) => _vm.updateRowById(props.row.id, _vm.columnKeys.field, value)}})],1):_vm._e(),(props.column.field == _vm.columnKeys.type)?_c('span',[_c('el-select',{attrs:{"value":props.row.type,"placeholder":_vm.$t('Type')},on:{"change":(value) => _vm.updateRowById(props.row.id, _vm.columnKeys.type, value)}},_vm._l((_vm.typeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1):_vm._e(),(props.column.field == _vm.columnKeys.actor)?_c('span',[_c('el-radio',{attrs:{"label":props.row.id},model:{value:(_vm.actorIdField),callback:function ($$v) {_vm.actorIdField=$$v},expression:"actorIdField"}})],1):_vm._e(),(props.column.field == _vm.columnKeys.description)?_c('span',[_c('el-input',{attrs:{"placeholder":_vm.$t('Description'),"value":props.row.description},on:{"input":(value) =>
                _vm.updateRowById(props.row.id, _vm.columnKeys.description, value)}})],1):_vm._e(),(props.column.field == _vm.columnKeys.action)?_c('span',[_c('i',{staticClass:"select-database-connection-action-icon vis-cursor-pointer",class:_vm.CustomIcon.Delete,attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.deleteActionClicked(props.row)}}})]):_vm._e()]}}])},[_c('div',{staticClass:"vis-text--center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t("generalPages.noData"))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }