<template>
  <div
    class="vis-mb--2 vis-col vis-col-6 vis-pa--none"
    :class="{ 'vis-col-12': !component.flex }"
  >
    <div
      :class="[
        {
          'vis-flex--spacebeetwen-Ycenter':
            component.componentType !== componentTypes.SELECT_BOX,
        },
        component.className,
      ]"
    >
      <label>{{ $t(component.label) }}</label>
      <!-- FOR COLOR PICKER COMPONENT START -->
      <el-color-picker
        v-if="component.componentType === componentTypes.COLOR_PICKER"
        :value="component.value"
        size="mini"
        :disabled="disabledComponent"
        @change="changePropertyStyle($event, component.property)"
      ></el-color-picker>
      <!-- FOR COLOR PICKER COMPONENT END -->

      <!-- FOR SWITCH COMPONENT START -->
      <el-switch
        v-if="component.componentType === componentTypes.SWITCH"
        :value="component.value"
        :disabled="disabledComponent"
        @change="changePropertyStyle($event, component.property)"
      >
      </el-switch>
      <!-- FOR SWITCH COMPONENT END -->
      <!-- FOR SELECT_BOX COMPONENT START -->
      <el-select
        v-if="component.componentType === componentTypes.SELECT_BOX"
        :value="component.value"
        :disabled="disabledComponent"
        :placeholder="$t('generalPages.selectValue')"
        @change="changePropertyStyle($event, component.property)"
      >
        <el-option
          v-for="item in component.selectBoxOptions"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- FOR SELECT_BOX COMPONENT END -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    componentTypes: {
      type: Object,
      default: () => {
        return {};
      },
    },
    component: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabledComponent: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    changePropertyStyle(value, property) {
      this.$emit("changePropertyStyle", { value, property });
    },
  },
};
</script>
