import { chartTypes, dashboardFilterTypes, getDetailsDataByAuthorize } from "../commons/dashboardAndPanel";
import { ACTION as ACTION_TEST_QUERY } from "../store/modules/Visualize/TestQuery/types";
import { mapActions } from "vuex";
import { filterPropKey, filterType } from "../commons/filterComponents";
import { notificationType } from "../commons/notificationTypes";
import { Notify } from "../commons/helper";
import {
  chartSearchViewEnum,
  chartServices,
  checkIfChartIsEligibleToCallService,
  getAggregationCombinations,
  getSearchBodyParam,
  getSearchResponseError,
} from "../util/chartSearchServices";
import { DatamodelContextDefaults } from "../commons/dataModelTypes";
import { responseDataErrorCodes } from "../commons/notifierTypes";
import { routerEnums } from "../commons/Enum";
import { selectBoxOption } from "../commons/dashboardProperties";

export default {
  data() {
    return {
      dashboardTab: {
        options: [],
      },
      drillThroughSearchOption: {},
      isRequiredFilterValue: false,
      clearOldData: false,
      searchResponse: {},
      searchResponseError: {},
      searchChartList: [],
      isDesignModeSearchRequestActive: false,
    };
  },
  computed: {
    chartListForDashboardFilterLength() {
      return this.chartListForDashboardFilter?.length;
    },
  },
  methods: {
    ...mapActions({
      fetchSearchWithoutMutate: ACTION_TEST_QUERY.FETCH_SEARCH_WITHOUT_MUTATE,
    }),

    isFilterTypeSearchList(chartType) {
      return (
        chartType == dashboardFilterTypes.FILTER_SELECT_LIST ||
        chartType == dashboardFilterTypes.FILTER_DROPDOWN
      );
    },

    getDashboardOptionsDataById(id) {
      return this.dashboardTab.options.find((x) => x.id == id);
    },
    async getViewChartWithButton() {
      const filterValues = [];

      this.filterChartList.forEach((element) => {
        if (element.details.FILTER_VALUE) {
          filterValues.push(element.details.FILTER_VALUE);
        }
      });

      if (this.filterChartList.length === filterValues.length)
        this.isRequiredFilterValue = false;
      else this.isRequiredFilterValue = true;

      this.setSearchChartList();
      let isMandatory = this.controlMandatory();

      if (!isMandatory) {
        Notify(
          this.$t("notifyMessages.mandatoryMessage"),
          notificationType.WARNING,
          this.$t("notifyMessages.mandatory")
        );
        return;
      }

      this.viewChart();
    },
    setSearchChartList() {
      //if durumu : addFiltersToPanels methodu çalıştığında chartListForDashboardFilter değişkenine chart listesi set edilir.
      //--store'a kaydedilmeden, listeyi search servisinde kullanmak için yapılan işlem.
      //else durumu : store tarafına set edilmiş listemizi kullanmak için(default bu şekil çalışır. Filtre durumlarında if kısmı çalışacak.).
      if (this.chartListForDashboardFilterLength)
        this.searchChartList = this.chartListForDashboardFilter;
      else this.searchChartList = this.selectedTabAllPanelList;
    },
    viewChart(...chartIds) {
      //DesignMode açıksa, tablar arası geçişte  grafiklerin eski verilerle çizilmemesi için
      if (this.selectedTabDesignMode && !this.isModeDashboardView) this.dashboardTab.options = [];

      this.setSearchChartList();

      this.clearOldData = true;
      let isMandatory = this.controlMandatory();
      let charts = [];

      if (chartIds.length) {
        charts = this.searchChartList.filter((item) =>
          chartIds.includes(item.i)
        );
      } else {
        charts = this.searchChartList;
      }

      charts.forEach((chart) => {
        if (this.clearOldData && !this.chartListForDashboardFilter.length) {
          if (chart.type === chartTypes.TABLE) {
            this.emptyOrAddNewChartDataObject(chart);
          }
        }
        this.setChart(chart, isMandatory);
      });
      this.clearOldData = false;
      this.isDesignModeSearchRequestActive = false;
    },

    controlMandatory() {
      let filterChartList = [];
      const selectedTabUiTabId =
        this.dashboard.tabs?.[this.tabIndex]?.properties?.uiTabId;
      this.dashboard.tabs.forEach((tab) => {
        const chartsByTypeFilter = tab?.charts?.filter((x) =>
          this.isChartTypeFilter(x.type)
        );
        filterChartList = [...filterChartList, ...chartsByTypeFilter];
      });

      const mandatoryFilterChartList = filterChartList.filter((x) => {
        const keys = Object.keys(x?.properties?.style);
        const selectedMandatoryKey = keys.find((f) =>
          f.includes("MANDATORY_SELECTION")
        );
        const selectedApplyToTabs = keys.find((t) => t.includes("APPLY_TO_TABS"));
        const tabContainingTheChart =
          x?.properties?.uiTabId === selectedTabUiTabId;

          //ApplyToTabs default durumda (Seçim değiştirilmediyse) ise ve mandatory varsa sadece var olduğu tab'da mandatory etkili olur.
        if (!selectedApplyToTabs && selectedMandatoryKey) {
          return (
            x.properties.style[selectedMandatoryKey] && tabContainingTheChart
          );
        } //ApplyToTabs içinde seçim yada değişiklik olduysa ve mandatory varsa hangi tab'lar seçili ise o tablar mandatory'den etkilecek.
         else if (selectedApplyToTabs && selectedMandatoryKey) {
          const styleApplyToTabsValue =
            x.properties?.style?.[selectedApplyToTabs];
          const applyToTabsLengthEmpty =
            !styleApplyToTabsValue?.length && tabContainingTheChart;
          const hasApplyToTabs =
            styleApplyToTabsValue?.includes(selectedTabUiTabId) ||
            styleApplyToTabsValue?.includes(selectBoxOption.ALL_ITEM) ||
            applyToTabsLengthEmpty;

          return x.properties.style[selectedMandatoryKey] && hasApplyToTabs;
        }

        return false;
      });

      let result = true;

      mandatoryFilterChartList?.forEach((chart) => {
        //Eğer drill through ile açılan raporda zorunlu filtre varsa ve drill through filtrelerinden gelen filtre rapordaki param filtreyle aynı ve
        //dolu ise mandadory'e takılmayacak.
        const drillThroughInFilterValue = this.drillThroughFilterParam?.filterValue?.[chart?.details?.metrics?.[0]?.field];
        if (!chart?.details?.[filterPropKey.FILTER_VALUE] && !drillThroughInFilterValue?.value?.length) {
          result = false;
        }
      });

      return result;
    },

    setChart(item, isMandatory) {
      //If selectedTabDesignMode true and if the run icon in the settings bar is not clicked
      if (
        this.selectedTabDesignMode &&
        !this.isDesignModeSearchRequestActive &&
        !this.isFilterTypeSearchList(item.type) &&
        !this.isModeDashboardView
      )
        return;
      
      if (isMandatory || this.isFilterTypeSearchList(item.type)) {
        this.callChartService(item.type, item);
      } else this.emptyOrAddNewChartDataObject(item);
    },

    emptyOrAddNewChartDataObject(item) {
      let chartData = this.getDashboardOptionsDataById(item.i);

      if (!chartData) {
        this.dashboardTab.options.push({ id: item.i });
      } else {
        let chartDataKeys = Object.keys(chartData);

        let valueNullObj = {};

        chartDataKeys.forEach((element) => {
          valueNullObj[element] = null;
        });

        valueNullObj.id = item.i;

        Object.assign(chartData, valueNullObj);
      }

      this.updateSelectedPanel(item, item?.properties?.tabIndex);
    },

    setChartDataCommon(params) {
      let chartData = this.getDashboardOptionsDataById(params.id);
      if (chartData) {
        let keys = Object.keys(params);
        keys.forEach((key) => {
          this.$set(chartData, key, params[key]);
        });
      } else {
        this.dashboardTab.options.push(params);
      }
    },
    callChartService(chartType, chart) {
      const params = {
        ...getSearchBodyParam(chart),
        ...{ chartId: chart?.id, chartName: chart?.name },
      };

      const canCallService = checkIfChartIsEligibleToCallService(
        chartType,
        params,
        chartSearchViewEnum.DASHBOARD
      );

      if (!canCallService) return false;

      const chartI = chart?.i;

      this.fetchSearchWithoutMutate({
        loadingComponent: chartI,
        bodyParam: params,
        pageName: routerEnums.DASHBOARD,
      }).then((response) => {
        const chartDetails = getDetailsDataByAuthorize({
          chartDataDetails: params,
          unAuthorizedDashboardFields: this.unAuthorizedDashboardFields,
        });
        
        const result = chartServices[chartType].serviceFn(
          chartDetails,
          response,
          chartI,
          chart.id
        );
        
        getSearchResponseError(response, chartI, this.searchResponseError);
          
        const joinError = responseDataErrorCodes.includes(response?.data?.code);

        //Filtre chartında default value true ve filtrede hata var ise burası çalışır.
        if (joinError && chart.details.filters.find((x) => x.isActiveDefaultValue)) {
          let metricDatasetIds = [],
            joinedDatasetIds = [];

            chart.details.metrics.forEach((metric) => {
              if (!metricDatasetIds?.includes(metric?.datasetId)) {
                metricDatasetIds.push(metric.datasetId);
              }
            });
          
          //datasetIds Dashboard.js dosyasında store'dan geliyor.
          this.datasetIds.forEach(dsIds => {
            if (metricDatasetIds.every(x=> dsIds?.includes(x))) {
              joinedDatasetIds = dsIds;
              return;
            }
          });
          
          chart.details.filters = chart.details.filters.filter(
            (x) => joinedDatasetIds?.includes(x?.datasetId) && x.isActiveDefaultValue
          );
            
          this.updateSelectedPanel(chart, chart?.properties?.tabIndex);

          this.viewChart(...[chart].map((x) => x.i));

          return;
        }

        //joinError => gönderdiğimiz payload içinde backend için field'da join uyumu yoksa chart için herhangi bir güncelleme yaptırmıyoruz.
        if (joinError) return;
       
        this.setChartDataCommon({
          ...result,
          searchFilterList: getAggregationCombinations(
            params,
            response?.aggregations
          ),
        });

        this.updateSelectedPanel(chart, chart?.properties?.tabIndex);

        this.searchResponse[chartI] = response;
      });
    },
    
    //FOR DRILL THROUGH FILTER PANE MENU SEARCH SERVICE
    callDrillThroughFilterList(filterValue) {
      let searchPayload = {
        filter: [],
        metric: [],
        aggregation: [],
        params: [],
        dataset: null,
        vismetadata: {
          vistype: chartTypes.OTHER,
        },
      };
      const filterTypeEnum = filterType;

      //Buranın yazılma amacı, customFilterParamlı datamodellerde search'e giden chartlarda hata almamak için params dizisine customParamFiltrenin eklenmesi gerekiyor.
      Object.keys(filterValue).forEach((item) => {
        const { fieldId, datasetId, operator, fieldType, filterType } =
          filterValue[item].fieldItem;
        if (filterType === filterTypeEnum.CUSTOM) {
          searchPayload.params.push({
            field: item,
            alias: filterValue[item].alias,
            fieldId,
            datasetId,
            operator,
            fieldType,
            filterType,
            value: filterValue[item].value,
            isActiveDashboardFilter: true,
            isActiveFilterIcon: true,
          });
        }
      });

      Object.keys(filterValue).forEach((element) => {
        searchPayload.metric = [];
        searchPayload.dataset = {
          id: filterValue[element].fieldItem.datasetId,
        };
        searchPayload.metric.push({
          type: DatamodelContextDefaults.DISTINCT,
          field: element,
          alias: filterValue[element].alias,
          fieldId: filterValue[element].fieldItem.fieldId,
          datasetId: filterValue[element].fieldItem.datasetId,
        });

        //CUSTOM(param) filtre chart'larına search atmaya gerek yok. Çünkü DateFilter ve textFilter kullanılıyor.
        if (filterValue[element].fieldItem.filterType !== filterTypeEnum.CUSTOM)
          this.fetchSearchWithoutMutate({
            loadingComponent: null,
            bodyParam: searchPayload,
          }).then((response) => {
            this.$set(
              this.drillThroughSearchOption,
              [element],
              response.aggregations
            );
          });
      });
    },
  },
};
