import { filterOperator } from "../../commons/filterComponents";
import i18n from "../../i18n";

export const checkSelectBoxSize = (param) => {
    return param ? "small" : "mini"
}

export const getOperator = (param, customFiltersLength) => {
    if (!param) {
        return customFiltersLength ? filterOperator.IN : filterOperator.EQ
    } else return filterOperator.IN;
}

export const getDropdownPlaceholder = () => {
    return {
        [true]: i18n.tc("filterComponents.choseTagsForYourArticle"),
        [false]: i18n.tc("filterComponents.selectData"),
    }
}