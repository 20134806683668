export const domain = "VISUALIZE_DASHBOARD_FIELDS";

export const STATE = {
  DASHBOARD_FIELDS: `${domain}/DASHBOARD_FIELDS`,
  DASHBOARD_UNAUTHORIZED_FIELDS: `${domain}/DASHBOARD_UNAUTHORIZED_FIELDS`,
};
export const GETTER = {
  GET_DASHBOARD_BY_ID_FIELDS: `${domain}/GET_DASHBOARD_BY_ID_FIELDS`,
  GET_DASHBOARD_UNAUTHORIZED_FIELDS: `${domain}/GET_DASHBOARD_UNAUTHORIZED_FIELDS`,
};

export const ACTION = {
  FETCH_DASHBOARD_BY_ID_FIELDS: `${domain}/FETCH_DASHBOARD_BY_ID_FIELDS`,
  CREATE_DASHBOARD_FIELDS: `${domain}/CREATE_DASHBOARD_FIELDS`,
  UPDATE_DASHBOARD_FIELDS: `${domain}/UPDATE_DASHBOARD_FIELDS`,
  DELETE_DASHBOARD_FIELDS: `${domain}/DELETE_DASHBOARD_FIELDS`,
  DUPLICATE_DASHBOARD_DATAMODEL_FIELD: `${domain}/DUPLICATE_DASHBOARD_DATAMODEL_FIELD`
};

export const MUTATION = {
  SET_DASHBOARD_FIELDS: `${domain}/SET_DASHBOARD_FIELDS`,
  SET_DASHBOARD_UNAUTHORIZED_FIELDS: `${domain}/SET_DASHBOARD_UNAUTHORIZED_FIELDS`,
};