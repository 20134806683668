<template>
  <el-collapse v-model="activeNames">
    <el-collapse-item
      v-for="item in chartAndFilterList"
      :key="item.id"
      :title="$t(item.title)"
      :name="item.name"
      :disabled="disabledCollapseItem"
      class="vis-tools-padding vis-collapse-item"
    >
      <div class="vis-tools-content">
        <div class="vis-row">
          <div
            class="vis-col vis-col-6"
            v-for="panel in item.panelList"
            :key="panel.id"
          >
            <div
              :id="panel.id"
              @drag="drag(panel.id)"
              @dragend="dragend(panel)"
              class="droppable-element dragDiv"
              draggable="true"
              unselectable="on"
            >
              <div class="vis-flex--center">
                <img
                  :src="getHeaderImage(panel.icon)"
                  :alt="panel.icon"
                  style="height: 30px"
                />
              </div>
            </div>
            <p class="vis-text--center" style="line-height: 15px">
              {{ $t(panel.name) }}
            </p>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
import {
  chartList,
  filterList,
  otherList,
  collapseName
} from "../../commons/dashboardAndPanel";

export default {
  watch: {
    disabledCollapseItem(val) {
      if (val) this.activeNames = [];
      else  this.activeNames = [collapseName.CHARTS];
    }
  },
  props: {
    disabledCollapseItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeNames: [collapseName.CHARTS],
    };
  },
  computed: {
    chartAndFilterList() {
      return [
        {
          id: 1,
          name: collapseName.CHARTS,
          title: "dashboard.chart",
          panelList: chartList,
        },
        {
          id: 2,
          name: collapseName.FILTERS,
          title: "dashboard.filter",
          panelList: filterList,
        },
        {
          id: 3,
          name: collapseName.OTHER,
          title: "dashboard.other",
          panelList: otherList(),
        },
      ];
    },
  },
  methods: {
    drag(id) {
      this.$emit("dragEmit", id);
    },
    dragend(item) {
      this.$emit("dragendEmit", item);
    },
    getHeaderImage(imageName) {
      var images = require.context(
        "../../assets/images/chartsAndfilters/",
        false,
        /\.svg$/
      );
      return images("./" + imageName + ".svg");
    },
  },
};
</script>
<style scoped>
.vis-tools-content {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}
.vis-tools-content .dragDiv {
  width: 100%;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-lighteen-1);
  margin-bottom: 5px;
}
.droppable-element {
  cursor: move;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.vis-tools-content .droppable-element div i {
  font-size: 1.25rem;
}
.vis-tools-padding .el-collapse-item__wrap {
  padding: 0 5px;
}
</style>
