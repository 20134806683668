<template>
  <div v-if="isJobStatus" v-loading="isJobLoading">
    <div class="vis-display-flex vis-mb--1">
      <span class="vis-font-bold"
        >{{ $t("dataflow.runHistory.Last Run") }}
      </span>
      <i
        @click="refreshData"
        class="vis-cursor-pointer vis-display-flex vis-ml--auto"
        :class="customIcon.Autorenew"
        aria-hidden="true"
      />
    </div>
    <div class="content">
      <div
        v-for="row in lastRunDescriptionTable"
        :key="row.title"
        class="vis-mb--1"
      >
        <div class="vis-display-flex">
          <span class="">{{ $t(`${row.title}`) }}</span>
          <span
            class="vis-ml--auto vis-font-medium"
            :class="row.class ? row.class : ''"
            >{{ row.value }}</span
          >
        </div>
        <!-- Sub items için döngü -->
        <div v-if="row.subItems && row.subItems.length" class="vis-ml--1">
          <div
            v-for="subItem in row.subItems"
            :key="subItem.title"
            class="vis-display-flex vis-subItem-font"
          >
            <span class="vis-text--xsmall" v-if="subItem.title">{{
              $t(`${subItem.title}`)
            }}</span>
            <span :class="subItem.title ? 'vis-ml--auto' : ''">{{
              subItem.value
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ACTION as ACTION_DATAFLOW } from "../../../store/modules/Ingest/DataFlow/types";
import { formatISOToDateTime } from "../../../util/moment";
import { CustomIcon } from "../../../assets/js/custom-icons";
import { IngestJobStatus } from "../enums";
import eventBus from "../../../eventBus";
import { ServerEventToClientEventMapping } from "../../../store/modules/SSE/types";

export default {
  props: {
    jobId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: null,
      customIcon: CustomIcon,
      isJobLoading: false,
      sseTasks: [
        ServerEventToClientEventMapping.RDB_INGEST_JOB_TRANSACTION,
        ServerEventToClientEventMapping.RDB_INGEST_SUBTASK,
        ServerEventToClientEventMapping.RDB_INGEST_TASK,
      ],
    };
  },
  mounted() {
    this.fetchRdbData();

    this.sseTasks.forEach((task) => {
      eventBus.$on(task, (msg) => {
        if (msg.status === "SUCCESS") {
          this.refreshData();
        }
      });
    });
  },
  beforeDestroy() {
    this.sseTasks.forEach((task) => {
      eventBus.$off(task);
    });
  },
  watch: {
    jobId: {
      handler(val) {
        if (val) {
          this.fetchRdbData();
        }
      },
      deep: true,
    },
  },
  computed: {
    status() {
      const taskStatus = this.data?.task?.status;
      const transactionStatus = this.data?.transaction?.status;

      if (taskStatus) {
        return this.$t(`dataflow.runHistory.${taskStatus}`);
      } else if (transactionStatus) {
        switch (transactionStatus) {
          case IngestJobStatus.FAILED:
            return this.$t("dataflow.runHistory.FAILED");
          case IngestJobStatus.FINISHED:
            return this.$t("dataflow.runHistory.Planned");
          default:
            return this.$t("dataflow.runHistory.Planning");
        }
      } else {
        return this.$t("dataflow.runHistory.Planning");
      }
    },
    statusClass() {
      switch (this.status) {
        case this.$t("dataflow.runHistory.FAILED"):
          return "vis-failed-frame";
        case this.$t("dataflow.runHistory.FINISHED"):
          return "vis-finished-frame";
        case this.$t("dataflow.runHistory.IN_PROGRESS"):
          return "vis-in-progress-frame";
        default:
          return "vis-default";
      }
    },
    recordsToTransfer() {
      return this.data?.task?.totalTransferRowCount;
    },
    recordsTransferred() {
      return this.data?.task?.currentTransferredRowCount;
    },
    isJobStatus() {
      return this.data?.transaction;
    },
    waitingCount() {
      const subTaskStatuses = this.data?.task?.subTaskStatuses;
      return (
        subTaskStatuses?.CREATED ||
        0 + subTaskStatuses?.QUEUED ||
        0 + subTaskStatuses?.REQUEUED ||
        0
      );
    },

    lastRunDescriptionTable() {
      return [
        {
          title: "dataflow.runHistory.Status",
          value: this.status,
          class: this.statusClass + " vis-status-frame",
        },
        {
          title: "dataflow.runHistory.Started At",
          value:
            formatISOToDateTime(this.data?.transaction?.createdTime) ?? "-",
        },
        {
          title: "dataflow.runHistory.Updated At",
          value:
            formatISOToDateTime(
              this.data?.task?.lastProcessTime ||
                this.data?.transaction?.lastProcessTime
            ) ?? "-",
        },
        {
          title: "dataflow.runHistory.Completion",
          value:
            this.calculateCompletionRate(
              this.recordsToTransfer,
              this.recordsTransferred
            ) ?? "-",
          subItems: [
            {
              title: "dataflow.runHistory.of Records to transfer",
              value: this.recordsToTransfer ?? "-",
            },
            {
              title: "dataflow.runHistory.of Records transferred",
              value: this.recordsTransferred ?? "-",
            },
          ],
        },
        {
          title: "dataflow.runHistory.Subtask Count",
          value: this.data?.task?.totalSubtaskCount ?? "-",
          subItems: [
            {
              title: "dataflow.runHistory.Waiting",
              value: this.waitingCount,
            },
            {
              title: "dataflow.runHistory.IN_PROGRESS",
              value: this.data?.task?.subTaskStatuses.IN_PROGRESS ?? "0",
            },
            {
              title: "dataflow.runHistory.FINISHED",
              value: this.data?.task?.subTaskStatuses.FINISHED ?? "0",
            },
            {
              title: "dataflow.runHistory.FAILED",
              value: this.data?.task?.subTaskStatuses.FAILED ?? "0",
            },
          ],
        },
        {
          title: "dataflow.runHistory.Run Id",
          subItems: [
            {
              //Run ID kısmı biraz daha kişiselleştirildi..
              title: null,
              value: this.data?.transaction?.id ?? "-",
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchRdbStatus: ACTION_DATAFLOW.READ_RDB_STATUS,
    }),
    refreshData() {
      this.fetchRdbData();
    },
    jobLoading() {
      return (this.isJobLoading = true);
    },
    calculateCompletionRate(recordsToTransfer, recordsTransferred) {
      if (!recordsToTransfer || !recordsTransferred) return "%0";
      return "%" + (recordsTransferred / recordsToTransfer) * 100;
    },
    fetchRdbData() {
      return this.fetchRdbStatus(this.jobId)
        .then((result) => {
          this.data = result;
          this.isJobLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.vis-failed-frame {
  background-color: #fda2a2;
}
.vis-finished-frame {
  background-color: #afea94;
}
.vis-in-progress-frame {
  background-color: #f8df8c;
}
.vis-default {
  background-color: #e2e2e2;
}
.vis-status-frame {
  width: 98px;
  height: 24px;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  padding-top: 3px;
}
.vis-subItem-font {
  font-size: 11px;
}
</style>
