var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'scenario-transition-node-container',
    { 'scenario-transition-node-container-selected': _vm.isEdgeSelected },
  ]},[_c('div',{staticClass:"scenario-transition-node-header"},[_c('div',{staticClass:"scenario-transition-node-header-content"},[_c('div',{staticClass:"scenario-transition-node-name-and-type"},[_c('div',{staticClass:"vis-flex--Ycenter"},[_c('span',{staticClass:"scenario-transition-node-type",attrs:{"title":_vm.type}},[_vm._v(_vm._s(_vm.type))]),_c('span',{staticClass:"scenario-transition-node-header-icon material-icons-outlined"},[_vm._v("settings")])]),_c('span',{staticClass:"scenario-transition-node-name",attrs:{"title":_vm.displayName}},[_vm._v(_vm._s(_vm.displayName))]),_c('div',{staticClass:"scenario-transition-node-body"},[_c('span',{staticClass:"scenario-transition-node-body-icon material-icons-outlined",class:{
              'scenario-transition-node-body-icon-active': _vm.hasEvent,
            },on:{"click":function($event){return _vm.handleBottomIconClick(_vm.bottomIcons.event)}}},[_vm._v("view_week")]),_c('span',{staticClass:"scenario-transition-node-body-icon transform-90 material-icons-outlined",class:{
              'scenario-transition-node-body-icon-active': _vm.hasEnrichment,
            },on:{"click":function($event){return _vm.handleBottomIconClick(_vm.bottomIcons.enrichment)}}},[_vm._v("lan")]),_c('span',{staticClass:"scenario-transition-node-body-icon material-icons-outlined",class:{
              'scenario-transition-node-body-icon-active': _vm.hasRule,
            },on:{"click":function($event){return _vm.handleBottomIconClick(_vm.bottomIcons.rule)}}},[_vm._v("filter_alt")]),_c('span',{staticClass:"scenario-transition-node-body-icon material-icons-outlined",class:{
              'scenario-transition-node-body-icon-active': _vm.hasAction,
            },on:{"click":function($event){return _vm.handleBottomIconClick(_vm.bottomIcons.action)}}},[_vm._v("bolt")])])])])]),_c('div',{staticClass:"scenario-transition-node-delete-action-wrapper"},[_c('span',{staticClass:"scenario-transition-node-delete-action material-icons-outlined",on:{"click":function($event){return _vm.$emit('onTransitionDeleteClick', _vm.id)}}},[_vm._v("delete_forever")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }