var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-database-connection-container",attrs:{"id":"selectDatabaseConnectionContent"}},[_c('div',{staticClass:"select-database-connection-container-body"},[_c('vue-good-table',{attrs:{"columns":_vm.selectDatabaseConnectionColumns,"rows":_vm.rows,"selectOptions":_vm.selectOptions},on:{"on-row-click":_vm.handleRowClick},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(`${props.column.label}`))+" ")])]}},{key:"table-row",fn:function(props){return [(
            props.column.field ===
            _vm.selectDatabaseConnectionColumnsFields.actions
          )?_c('div',[_c('div',{staticClass:"select-database-connection-actions-container"},[(!_vm.findAuthorityById(props.row.connectionId, [
                  _vm.AuthorizationConnectionEnums.read,
                ]))?_c('button',{staticClass:"select-database-comnection-actions-button",on:{"click":function($event){return _vm.deleteActionClicked(props.row)}}},[_c('i',{staticClass:"select-database-connection-action-icon vis-cursor-pointer",class:_vm.CustomIcon.Delete,attrs:{"aria-hidden":"true"}})]):_vm._e(),(!_vm.findAuthorityById(props.row.connectionId, [
                  _vm.AuthorizationConnectionEnums.read,
                ]))?_c('button',{staticClass:"select-database-comnection-actions-button",on:{"click":function($event){return _vm.editActionClicked(props.row)}}},[_c('i',{staticClass:"select-database-connection-action-icon vis-cursor-pointer",class:_vm.CustomIcon.PencilOutline,attrs:{"aria-hidden":"true"}})]):_vm._e(),(!_vm.isModuleScenario && !_vm.findAuthorityById(props.row.connectionId, [
                  _vm.AuthorizationConnectionEnums.read,
                  _vm.AuthorizationConnectionEnums.write,
                ]))?_c('button',{staticClass:"select-database-comnection-actions-button",on:{"click":function($event){return _vm.checkActionClicked(props.row)}}},[_c('i',{staticClass:"select-database-connection-action-icon vis-cursor-pointer",class:_vm.CustomIcon.Share,attrs:{"aria-hidden":"true"}})]):_vm._e()])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"vis-text--center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t( "generalPages.You don’t have access permission to any connection!" ))+" ")])])],1),_c('ConfirmDialog',{attrs:{"title":_vm.$t('dialog.deleteConnectionTitle'),"message":_vm.$t('dialog.deleteConnectionMessage'),"dialogVisible":_vm.dialogVisible},on:{"confirm":_vm.deleteConnectionData,"cancel":function($event){_vm.dialogVisible = $event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }