<template>
  <div class="aside-properties">
    <div class="scenario-aside-title">
      <span class="scenario-aside-title-icon material-icons-outlined">
        settings
      </span>
      {{ $t("Transition Properties") }}
    </div>
    <div class="scenario-aside-properties-container">
      <!-- scenario properties -->
      <el-tabs v-model="activeTab" :stretch="true">
        <el-tab-pane :name="tabs.general">
          <span slot="label"> {{ $t("General") }}</span>
          <ScenarioTransitionGeneralProperties
            v-if="activeTab === tabs.general"
            :properties="properties"
            @updateName="(v) => $emit('updateName', v)"
            @updateScreenNote="(v) => $emit('updateScreenNote', v)"
          />
        </el-tab-pane>
        <el-tab-pane :name="tabs.event">
          <span slot="label">
            <span class="scenario-properties-icon material-icons-outlined">
              view_week </span
            >{{ $t("Event") }}
          </span>
          <ScenarioEventProperties
            v-if="activeTab === tabs.event"
            :properties="properties"
            :events="events"
            @updateEventName="(v) => $emit('updateEventName', v)"
          />
        </el-tab-pane>

        <el-tab-pane :name="tabs.enrichment">
          <span slot="label">
            <span
              class="scenario-properties-icon material-icons-outlined"
              style="transform: matrix(0, 1, -1, 0, 0, 0)"
            >
              lan </span
            >{{ $t("Enrichment") }}
          </span>
          <scenario-enrichment-properties
            v-if="activeTab === tabs.enrichment"
            :properties="properties"
            :enrichmentOptions="enrichmentOptions"
            @updateEnrichment="(v) => $emit('updateEnrichment', v)"
          />
        </el-tab-pane>

        <el-tab-pane :name="tabs.rule">
          <span slot="label">
            <span class="scenario-properties-icon material-icons-outlined">
              filter_alt </span
            >{{ $t("Rule") }}
          </span>
          <ScenarioRuleProperties
            v-if="activeTab === tabs.rule"
            :properties="properties"
            :events="events"
            :enrichmentOptions="enrichmentOptions"
            @updateExpression="(v) => $emit('updateExpression', v)"
          />
        </el-tab-pane>

        <el-tab-pane :name="tabs.action">
          <span slot="label">
            <span class="scenario-properties-icon material-icons-outlined">
              bolt </span
            >{{ $t("Action") }}
          </span>
          <ScenarioActionProperties
            v-if="activeTab === tabs.action"
            :properties="properties"
            :actions="actions"
            :events="events"
            :enrichmentOptions="enrichmentOptions"
            @updateAction="(v) => $emit('updateAction', v)"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ScenarioTransitionGeneralProperties from "./ScenarioTransitionGeneralProperties.vue";
import ScenarioEventProperties from "./ScenarioEventProperties.vue";
import ScenarioEnrichmentProperties from "./ScenarioEnrichmentProperties.vue";
import ScenarioRuleProperties from "./ScenarioRuleProperties.vue";
import ScenarioActionProperties from "./ScenarioActionProperties.vue";

export default {
  props: {
    properties: {
      type: Object,
    },
    events: {
      type: Array,
    },
    enrichmentOptions: {
      type: Array,
    },
    actions: {
      type: Array,
    },
  },
  components: {
    ScenarioTransitionGeneralProperties,
    ScenarioEventProperties,
    ScenarioEnrichmentProperties,
    ScenarioRuleProperties,
    ScenarioActionProperties,
  },
  data() {
    return {
      activeTab: "tab1",
      tabs: {
        general: "tab1",
        event: "tab2",
        enrichment: "tab3",
        rule: "tab4",
        action: "tab5",
      },
    };
  },
  methods: {
    focusEventTab() {
      this.activeTab = this.tabs.event;
    },
    focusEnrichmentTab() {
      this.activeTab = this.tabs.enrichment;
    },
    focusRuleTab() {
      this.activeTab = this.tabs.rule;
    },
    focusActionTab() {
      this.activeTab = this.tabs.action;
    },
  },
};
</script>

<style scoped>
.aside-properties {
  padding: 20px !important;
}
.scenario-aside {
  display: flex;
  align-items: center;
  padding: 18px 25px;
  height: inherit;
  flex-direction: column;
}
.scenario-aside-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.scenario-aside-title-icon {
  color: #ff7623;
  font-size: 1.625rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.scenario-aside-properties-container {
  width: 100%;
}
.scenario-properties-icon {
  font-size: 1rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  vertical-align: middle;
  margin-right: 2px;
}
</style>
