import { colorPalette } from "../../commons/chartOptions";
import { formatValueController } from "../chartOptionsHelper";

export const getGaugeChartOptions = (
  gaugeChartComputedValues,
  paramsFromSearch,
  isFullScreen
) => {
  const optionObject = {
    tooltip: {
      show: true,
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      textStyle: {
        fontFamily: "Poppins",
        fontSize: 12,
      },
      formatter: function (value) {
        return (
          value?.name +
          " <b>" +
          formatValueController(paramsFromSearch?.format, value?.value) +
          "</b>"
        );
      },
    },
    series:
      paramsFromSearch &&
      paramsFromSearch.dataSerie &&
      Object.keys(paramsFromSearch?.dataSerie).length > 0
        ? {
            ...paramsFromSearch.dataSerie,
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            radius: isFullScreen ? "95%" : "120%",
            center: ["50%", "60%"],
            title: {
              offsetCenter: [0, isFullScreen ? "20%" : "30%"],
              fontFamily:
                gaugeChartComputedValues.GAUGE_CHART_TITLE_FONT_FAMILY,
              color: gaugeChartComputedValues.GAUGE_CHART_TITLE_FONT_COLOR,
              fontSize: gaugeChartComputedValues.GAUGE_CHART_TITLE_FONT_SIZE,
              fontWeight:
                gaugeChartComputedValues.GAUGE_CHART_TITLE_FONT_WEIGHT,
            },
            progress: {
              show: true,
              width: gaugeChartComputedValues.GAUGE_CHART_PROGRESS_BAR_WIDTH,
              itemStyle: {
                color: "transparent",
              },
            },
            axisLine: {
              lineStyle: {
                width: gaugeChartComputedValues.GAUGE_CHART_PROGRESS_BAR_WIDTH,
                [gaugeChartComputedValues.GAUGE_CHART_SEPERATION_ENABLED
                  ? "color"
                  : ""]: [
                  ...gaugeChartComputedValues.GAUGE_CHART_SEPERATION_VALUES.map(
                    (row) => [row.percentage / 100, row.color]
                  ),
                ],
              },
            },
            axisLabel: {
              show: isFullScreen,
              distance: 25,
              fontFamily:
                gaugeChartComputedValues.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY,
              color:
                gaugeChartComputedValues.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR,
              fontSize:
                gaugeChartComputedValues.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE,
              fontWeight:
                gaugeChartComputedValues.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT,
              formatter: function (value) {
                return formatValueController(
                  paramsFromSearch.format,
                  +value.toFixed()
                );
              },
            },
            detail: {
              valueAnimation: true,
              formatter: function (value) {
                return formatValueController(paramsFromSearch.format, value);
              },
              offsetCenter: [0, isFullScreen ? "30%" : "60%"],
              fontFamily:
                gaugeChartComputedValues.GAUGE_CHART_VALUE_LABEL_FONT_FAMILY,
              fontSize:
                gaugeChartComputedValues.GAUGE_CHART_VALUE_LABEL_FONT_SIZE,
              fontWeight:
                gaugeChartComputedValues.GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT,
              [gaugeChartComputedValues.GAUGE_CHART_SEPERATION_ENABLED
                ? ""
                : "color"]:
                gaugeChartComputedValues.GAUGE_CHART_VALUE_LABEL_FONT_COLOR,
            },
            pointer: {
              icon: pointerIcon,
              length: "65%",
              width: 16,
              offsetCenter: [0, "5%"],
              itemStyle: {
                [gaugeChartComputedValues.GAUGE_CHART_SEPERATION_ENABLED
                  ? "color"
                  : ""]: "auto",
              },
            },
          }
        : null,
    color: colorPalette,
  };

  return {
    options: optionObject,
  };
};

const pointerIcon =
  "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z";
