
export const notifyStatusMapping = {
    200: {
        title: "notifyTitle.success",
        type: "success",
    },
    201: {
        title: "notifyTitle.success",
        type: "success",
    },
    202: {
        title: "notifyTitle.success",
        type: "success",
    },
    400: {
        title: "notifyTitle.error",
        type: "error",
    },
    401: {
        title: "notifyTitle.error",
        type: "error",
    },
    403: {
        title: "notifyTitle.error",
        type: "error",
    },
    404: {
        title: "notifyTitle.error",
        type: "error",
    },
    405: {
        title: "notifyTitle.warning",
        type: "warning",
    },
    412: {
        title: "notifyTitle.error",
        type: "error",
    },
    422: {
        title: "notifyTitle.error",
        type: "error",
    },
    500: {
        title: "notifyTitle.error",
        type: "error",
    },
    503: {
        title: "notifyTitle.error",
        type: "error",
    },
    504: {
        title: "notifyTitle.error",
        type: "error",
    }
}

export const NOTIFY_TITLE = {
    SAVE_SUCCESS: "notifyTitle.successfullySaved",
    START_EXPORT_FILE: "notifyTitle.startExportFile"
};

export const responseDataErrorCodes = [
    "dap_ec_083_not_found_neighbour_node",
    "dap_ec_079_no_join_path",
    "dap_ec_078_must_not_multiple_facts_from_dataset_join",
    "dap_ec_084_dataset_from_request_not_contains_join_path"
]