<template>
  <div>
    <div class="vis-col vis-col-6">
      <el-radio-group class="vis-mb--1 vis-pl--1" v-model="selectedTabValue">
        <el-radio
          v-for="tab in connectionTypeFilter"
          :key="tab.value"
          :label="tab.value"
          @change="setTabValue(tab.value)"
          >{{ $t(tab.label) }}</el-radio
        >
      </el-radio-group>
    </div>
    <BasicConnection
      v-show="selectedTabValue == selectDatabaseConnectionType.BASIC"
      :basicConnectionData="basicConnectionData"
      :connectionItem="connectionItem"
      :saveConnectionLoading="saveConnectionLoading"
      :testConnectionLoading="testConnectionLoading"
      :connectionTypes="connectionTypes"
      @saveConnection="saveConnection"
      @testConnection="testConnection"
    />
    <CustomConnection
      v-show="selectedTabValue == selectDatabaseConnectionType.CUSTOM"
      :customConnectionData="customConnectionData"
      :connectionItem="connectionItem"
      :saveConnectionLoading="saveConnectionLoading"
      :testConnectionLoading="testConnectionLoading"
      :connectionTypes="connectionTypes"
      @saveConnection="saveConnection"
      @testConnection="testConnection"
    />
  </div>
</template>

<script>
import {
  SELECT_DATABASE_CONNECTION_TYPE,
  SELECT_DATABASE_CONNECTION_TYPE_LIST,
} from "./SelectDatabaseConnection.config.js";
import BasicConnection from "./BasicConnection.vue";
import CustomConnection from "./CustomConnection.vue";

export default {
  components: {
    BasicConnection,
    CustomConnection,
  },
  props: {
    connectionItem: {
      type: Object,
      default: () => null,
    },
    testConnectionLoading: {
      type: Boolean,
      default: false,
    },
    saveConnectionLoading: {
      type: Boolean,
      default: false,
    },
    connectionTypes: {
      type: Array,
      default: () => {
        return [];
      },
    }
  },
  data() {
    return {
      selectedTabValue: SELECT_DATABASE_CONNECTION_TYPE.BASIC,
      connectionTypeFilter: SELECT_DATABASE_CONNECTION_TYPE_LIST,
      selectDatabaseConnectionType: SELECT_DATABASE_CONNECTION_TYPE,
      basicConnectionData: {},
      customConnectionData: {},
    };
  },
  mounted() {
    if (this.connectionItem && this.connectionItem.isCustomURL) {
      this.selectedTabValue = SELECT_DATABASE_CONNECTION_TYPE.CUSTOM;
    }
  },
  methods: {
    testConnection(connectionForm, jdbcURL) {
      this.$emit("testConnection", {
        ...connectionForm,
        jdbcURL,
      });
    },
    saveConnection(connectionForm, jdbcURL) {
      this.$emit("saveConnection", {
        ...connectionForm,
        jdbcURL,
      });
    },
    setTabValue(value) {
      this.selectedTabValue = value;
    },
  },
};
</script>
