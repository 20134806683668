var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-3"},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("schedule.labelDistributeBy")))]),_c('el-select',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.distributionOptions),function(option){return _c('el-option',{key:option.value,attrs:{"label":option.label,"value":option.value}})}),1)],1)])]),(_vm.selected === _vm.scheduleDistributionOptions.Email)?_c('div',{staticClass:"vis-flex--spacebeetwen vis-row"},[_c('div',{staticClass:"vis-col vis-col-6"},_vm._l((_vm.mailToItems),function(item,index){return _c('div',{key:item.key},[_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-mb--none",class:{
              'vis-col-10': _vm.isDynamicAvailable,
              'vis-col-12': !_vm.isDynamicAvailable,
            }},[_c('div',[_c('label',{},[_vm._v(_vm._s(item.label))])])]),(_vm.isDynamicAvailable)?_c('div',{staticClass:"vis-col vis-col-2 vis-mb--none"},[(index == 0)?_c('label',{},[_vm._v(_vm._s(_vm.$t("Dynamic")))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"vis-row vis-mb--05 align-items-center"},[_c('div',{staticClass:"vis-col",class:{
              'vis-col-10': _vm.isDynamicAvailable,
              'vis-col-12': !_vm.isDynamicAvailable,
            }},[_c('div',[(!item.isFieldDynamic)?_c('el-input',{class:item.className,attrs:{"value":item.value,"type":"textarea","rows":3,"multiple":"","placeholder":_vm.$t('Enter email addresses')},on:{"input":(val) => _vm.updateMailDetailsByKey(item.key, val)}}):_c('el-select',{staticClass:"dynamic-field-select",attrs:{"value":item.value},on:{"change":(val) => _vm.updateDynamicFieldByKey(item.key, val)}},_vm._l((_vm.selectableDynamicContentFields),function(option){return _c('el-option',{key:option.name,attrs:{"label":option.name,"value":option.name}})}),1)],1)]),(_vm.isDynamicAvailable)?_c('div',{staticClass:"vis-col vis-col-2"},[_c('div',[_c('el-switch',{attrs:{"value":item.isFieldDynamic},on:{"change":(val) => _vm.updateIsDynamicByKey(item.key, val)}})],1)]):_vm._e()])])}),0),_c('div',{staticClass:"vis-col vis-col-6"},[_c('div',{staticClass:"vis-mb--1"},[_c('label',{},[_vm._v(_vm._s(_vm.$t("schedule.labelSubject")))]),_c('el-input',{class:_vm.unFilledFieldBorder(_vm.notificationDtoUpdated.subject),attrs:{"type":"text"},model:{value:(_vm.notificationDtoUpdated.subject),callback:function ($$v) {_vm.$set(_vm.notificationDtoUpdated, "subject", $$v)},expression:"notificationDtoUpdated.subject"}})],1),_c('div',[_c('label',{},[_vm._v(_vm._s(_vm.$t("schedule.labelBody")))]),_c('el-input',{class:_vm.unFilledFieldBorder(_vm.notificationDtoUpdated.body),attrs:{"type":"textarea","rows":9},model:{value:(_vm.notificationDtoUpdated.body),callback:function ($$v) {_vm.$set(_vm.notificationDtoUpdated, "body", $$v)},expression:"notificationDtoUpdated.body"}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }