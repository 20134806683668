<template>
  <el-dialog
    :title="title"
    width="360px"
    :visible="dialogVisible"
    :modalAppendToBody="false"
  >
    <span>{{ message }}</span>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="text"
        class="vis-cancel-btn"
        @click.stop="$emit('cancel', false)"
        >{{ $t('dialog.cancel') }}</el-button
      >
      <el-button type="primary" @click="$emit('confirm')">{{ $t('dialog.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    dialogVisible: {
      type: Boolean,
    },
  },
};
</script>
<style scoped></style>
