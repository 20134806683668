<template>
  <div>
    <div
      class="vis-display-flex vis-schedule-card"
      v-for="data in schedules"
      :key="data.taskDefinitionDto.id"
    >
      <div>
        <div class="vis-flex--spacebeetwen vis-mt--1">
          <div class="vis-font-bold vis-col-6 card-headerName vis-ml--1">
            {{ scheduleName(data) }}
          </div>
          <div class="vis-mr--1">
            <el-button
              v-if="isDeleteAvailable"
              type="text"
              @click="deleteCard(data)"
            >
              {{ $t("generalPages.delete") }}
            </el-button>
            <el-button type="text" @click="editCard(data)">
              {{ $t("generalPages.edit") }}
            </el-button>
          </div>
        </div>
        <div class="card-body">
          <div class="vis-row vis-pb--1">
            <div class="vis-ml--1">{{ $t("schedule.labelWhen") }}</div>
            <div
              class="el-input__inner vis-disable-opacity vis-ml--1 vis-mr--1"
            >
              {{ scheduleWhen(data) }}
            </div>
          </div>
          <div v-if="scheduleDistributeBy(data)" class="vis-row">
            <div class="vis-col-5">
              <div class="vis-ml--1">
                {{ $t("schedule.labelDistributeBy") }}
              </div>
              <div
                class="el-input__inner vis-disable-opacity vis-ml--1 vis-mr--1"
              >
                {{ scheduleDistributeBy(data) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Confirmdialog
      :title="$t('dialog.deleteScheduleTitle')"
      :message="$t('dialog.deleteScheduleMessage')"
      :dialogVisible="dialogVisibleSchedule"
      @confirm="deleteScheduleData"
      @cancel="dialogVisibleSchedule = false"
    />
  </div>
</template>

<script>
import Confirmdialog from "../../helper/ConfirmDialog.vue";

export default {
  props: {
    schedules: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isDeleteAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisibleSchedule: false,
      taskId: null,
      timeZoneDifference: 3,
    };
  },
  methods: {
    scheduleName(data) {
      return data?.taskDefinitionDto?.name;
    },
    scheduleWhen(data) {
      // removed 3 hours time difference here
      return (
        data?.taskDefinitionDto?.cronDto?.dailyCronType +
        ", " +
        data?.taskDefinitionDto?.cronDto?.cronType +
        ", " +
        String(
          (parseInt(data?.taskDefinitionDto?.cronDto?.hour) +
            this.timeZoneDifference) %
            24
        ).padStart(2, "0") +
        ":" +
        data?.taskDefinitionDto?.cronDto?.minute
      );
    },
    scheduleDistributeBy(data) {
      return data.taskDefinitionDto?.message?.createdBy;
    },
    editCard(data) {
      this.$emit("handleEditSchedule", data);
    },
    deleteCard(data) {
      this.dialogVisibleSchedule = true;
      this.taskId = data.taskDefinitionDto.id;
    },
    deleteScheduleData() {
      this.$emit("deleteScheduleData", this.taskId);
      this.dialogVisibleSchedule = false;
    },
  },
  components: { Confirmdialog },
};
</script>

<style scoped>
.edit-btn,
.delete-btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.edit-btn i,
.delete-btn i {
  margin-right: 5px;
}

.card-headerName {
  align-self: center;
}

.card-row {
  margin-bottom: 5px;
  display: flex;
}

.card-value {
  flex-grow: 1;
}
</style>
