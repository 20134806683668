import { defaultColorPalette } from "../commons/chartOptions";
import { countries, getItemPercent } from "../commons/dashboardProperties";

export const GEO_MAP_CHART_DEFAULT_CONFIGURATIONS = {
  // VALUE LABELS
  GEO_MAP_CHART_SHOW_VALUE_LABEL: false,
  GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS: true,
  GEO_MAP_CHART_SHOW_ONLY: getItemPercent(),
  GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins",
  GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
  GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE: 12,
  GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR: "#000",
  // BORDER SETTINGS
  GEO_MAP_CHART_BORDER_COLOR: "#000",
  // MAP SETTINGS
  GEO_MAP_CHART_SELECTED_COUNTRY: countries[0].value,
  // VISUAL MAP
  GEO_MAP_CHART_SHOW_VISUALMAP: true,
  GEO_MAP_CHART_VISUALMAP_TYPE: "continuous",
  GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN: "right",
  GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN: "top",
  GEO_MAP_CHART_VISUALMAP_FONT_SIZE: 12,
  GEO_MAP_CHART_VISUALMAP_FONT_FAMILY: "Poppins",
  GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT: 500,
  GEO_MAP_CHART_VISUALMAP_FONT_COLOR: "#000",
  GEO_MAP_CHART_VISUALMAP_WIDTH: 18,
  GEO_MAP_CHART_VISUALMAP_VALUES: { min: null, max: null },
  GEO_MAP_CHART_SELECTED_COLOR_PALETTE: defaultColorPalette,
  GEO_MAP_CHART_AUTO_REFRESH: 0,
  GEO_MAP_CHART_AUTO_REFRESH_CUSTOM: null,
  GEO_MAP_CHART_DYNAMIC_TITLE_VALUE: "",
  GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
  GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
  GEO_MAP_CHART_SHOW_INSIGHT: true,
  GEO_MAP_CHART_SHOW_SQL: false,
  GEO_MAP_CHART_GROUP_MULTIPLE_VALUES: true,
}
// TODO: move this function to chart utils
export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
  return typeof value != "undefined" ? value : computedValue;
};