import { BOX_KEY_ENUM, chartTypes } from "../../commons/dashboardAndPanel";
import {
    filterOperator,
    filterPropKey,
} from "../../commons/filterComponents";
import deepEqual from 'deep-equal';
import { getConcatArrayWithPipe } from "../chartOptionsHelper";
import { groupBy } from "lodash"
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
import { formatIsoToDateFilter } from "../moment";
import { getTableSelectedRowValue } from "../data-table/tableFilterHelper";
import { getMappedAndFilteredByBoxKeyAggregationList } from "../dashboard-filter/dashboardFilter";

const chartFilterServices =
{
    [chartTypes.HEATMAP_CHART]: { serviceFn: (filterValue, chartProp, aggregation, panelData) => setFilterValueByHeatMap(filterValue, chartProp, aggregation, panelData) },
    [chartTypes.TREEMAP_CHART]: { serviceFn: (filterValue, chartProp, aggregation, panelData) => setFilterValueByTreeMap(filterValue, chartProp, aggregation, panelData) },
    [chartTypes.SCATTER_CHART]: { serviceFn: (filterValue, chartProp, aggregation, panelData) => setFilterValueByScatter(filterValue, chartProp, aggregation, panelData) },
    [chartTypes.BUBBLE_CHART]: { serviceFn: (filterValue, chartProp, aggregation, panelData) => setFilterValueByScatter(filterValue, chartProp, aggregation, panelData) },
    [chartTypes.TABLE]: { serviceFn: (filterValue, chartProp, aggregation, panelData) => setFilterValueByTable(filterValue, chartProp, aggregation, panelData) },
    [chartTypes.PIVOT_TABLE]: { serviceFn: (filterValue, chartProp, aggregation, panelData) => setFilterValueByPivotTable(filterValue, chartProp, aggregation, panelData) },
}

export const createChartFilter = (chartProp, panelData) => {
    let chartFilterValue;
    const aggregation = getMappedAndFilteredByBoxKeyAggregationList(panelData?.details?.aggregation);
    const filterValue = panelData?.details?.[filterPropKey.FILTER_VALUE];

    if (chartFilterServices[panelData.type])
        chartFilterValue = chartFilterServices[panelData.type].serviceFn(filterValue, chartProp, aggregation, panelData);
    else { chartFilterValue = setFilterValueGeneral(filterValue, chartProp, aggregation, panelData) }

    return {
        value: chartFilterValue,
        operator: filterOperator.EQ,
        panelI: panelData.i,
        isChart: true,
    };

}
export const getFieldItemForFilter = (fieldItem) => {
    const fieldObj = {
      alias: fieldItem.alias,
      datasetId: fieldItem.datasetId,
      field: fieldItem.field,
      fieldId: fieldItem.fieldId,
      fieldType: fieldItem.fieldType,
      fieldUsageType: fieldItem.fieldUsageType,
      operator: fieldItem?.operator,
      filterType: fieldItem?.filterType,
      filterValues: fieldItem?.filterValues,
      popupTabName: fieldItem?.popupTabName,
    };
  
    Object.keys(fieldObj).forEach((key) => {
      if (fieldObj[key] === undefined) {
        delete fieldObj[key];
      }
    });
    
    return fieldObj;
  };

export const addTheFiltersAndParamsAddedToTheChartToDrillThrough = (panelData, filterObj) => {
    const filtersAndParams = [...panelData?.details?.filters, ...panelData?.details?.params];

    if (filtersAndParams?.length) {
        filtersAndParams.forEach((filterItem) => {
          filterObj[filterItem.field] = {
            value: filterItem.value,
            alias: filterItem.alias,
            //fieldItem: { filterId: filterItem.filterId, ...getFieldItemForFilter(filterItem) } , TODO++
            fieldItem: getFieldItemForFilter(filterItem)
          };
        });
      }
}
const setFilterValueGeneral = (filterValue, chartProp, aggregation, panelData) => {
    //chartProp chart'lara tıklandığında bize verdiği event parametresi.
    //split yapmamızım sebebi çoklu gruplamalarda ekleyeceğimiz filtre değerini ayırmak için.
    const values = chartProp?.name?.split(" | ");
    
    const filterObj = {};

    if (chartProp?.drillThrough) {
        addTheFiltersAndParamsAddedToTheChartToDrillThrough(panelData, filterObj);
    }
   
    aggregation.forEach((item, index) => {
        values[index] = checkIfFieldTypeIsDateAndFormatDateFilterValue(item, values[index])

        filterObj[item.field] = { value: values[index], alias: item.alias, fieldItem: item };
    })

    if (deepEqual(filterValue, filterObj) && !checkChartPropEventTypeContextMenu(chartProp)) return {};

    return filterObj;
}

const setFilterValueByTreeMap = (filterValue, chartProp, aggregation, panelData) => {
    let values = chartProp.treeAncestors.filter(x => x.dataIndex)

    const filterObj = {};

    if (chartProp?.drillThrough) {
        addTheFiltersAndParamsAddedToTheChartToDrillThrough(panelData, filterObj);
    }

    aggregation.forEach((item, index) => {
        values[index].name = checkIfFieldTypeIsDateAndFormatDateFilterValue(item, values[index].name)

        filterObj[item.field] = { value: values[index].name, alias: item.alias, fieldItem: item };
    })

    if (deepEqual(filterValue, filterObj) && !checkChartPropEventTypeContextMenu(chartProp)) return {};

    return filterObj;
}

const setFilterValueByHeatMap = (filterValue, chartProp, aggregations, panelData) => {
    const filterObj = {};

    if (chartProp?.drillThrough) {
        addTheFiltersAndParamsAddedToTheChartToDrillThrough(panelData, filterObj);
    }

    chartProp.dimensionNames.slice(0, chartProp.dimensionNames.length - 1).forEach((element, index) => {
        const elementArr = element?.split(" | ")
        const chartPropValues = chartProp.value[index]?.split(" | ")
        elementArr.forEach((e, i) => {
            const agg = aggregations.find(i => i.alias === e);
            if (agg) {
                chartPropValues[i] = checkIfFieldTypeIsDateAndFormatDateFilterValue(agg, chartPropValues[i])
                filterObj[agg.field] = { value: chartPropValues[i], alias: agg.alias, fieldItem: agg }
            }

        });
    });
    if (deepEqual(filterValue, filterObj) && !checkChartPropEventTypeContextMenu(chartProp)) return {};
    return filterObj;
}

const setFilterValueByScatter = (filterValue, chartProp, aggregations, panelData) => {
    const aggGroups = groupBy(aggregations, (field) => field.boxKey);
    const aggNames = Object.values(aggGroups).map(group => getConcatArrayWithPipe(group.map(agg => agg.alias)));
    const { dimensionNames, value } = chartProp;
    const filterObj = {};

    if (chartProp?.drillThrough) {
        addTheFiltersAndParamsAddedToTheChartToDrillThrough(panelData, filterObj);
    }
    
    dimensionNames.forEach((element, index) => {
        if (aggNames.includes(element)) {
            const elementArr = element?.split(" | ")
            const values = value[index]?.split(" | ")
            elementArr.forEach((t, i) => {
                const agg = aggregations.find(i => i.alias === t);
                if (agg) {
                    values[i] = checkIfFieldTypeIsDateAndFormatDateFilterValue(agg, values[i])
                    filterObj[agg.field] = { value: values[i], alias: agg.alias, fieldItem: agg }
                }
            });
        }
    });
    if (deepEqual(filterValue, filterObj) && !checkChartPropEventTypeContextMenu(chartProp)) return {};
    return filterObj;
}

const setFilterValueByTable = (filterValue, chartProps, aggregations, panelData) => {
    const filterObj = getTableSelectedRowValue(aggregations, chartProps?.filterParams);
    
    if (chartProps?.drillThrough) {
        addTheFiltersAndParamsAddedToTheChartToDrillThrough(panelData, filterObj);
    }
    
    if (deepEqual(filterValue, filterObj) && !isActiveContextMenuFilter(chartProps)) return {};

    return filterObj;
}
const isActiveContextMenuFilter = (chartProps) => {
    //context menu içinden filtre yapılacaksa 
    return chartProps?.drillThrough || chartProps?.isActiveFilterFromMenu;
}
const setFilterValueByPivotTable = (filterValue, chartProps, aggregation, panelData) => {
    const columnBoxes = aggregation.filter(x => x.boxKey === BOX_KEY_ENUM.COLUMN.KEY);
    const rowBoxes = aggregation.filter(x => x.boxKey === BOX_KEY_ENUM.ROW.KEY)
    let filterObj = {};
    //pivot table, drill through işleminde eğer pivota eklenmiş bir filtre varsa drillThroug filtre parametresine ekleencek.
    if (chartProps?.drillThrough) {
        addTheFiltersAndParamsAddedToTheChartToDrillThrough(panelData, filterObj);
    }

    columnBoxes.forEach((column, index) => {
        const value = checkIfFieldTypeIsDateAndFormatDateFilterValue(column, chartProps?.filterParams?.columnHeaders[index])
        if (chartProps?.filterParams?.columnHeaders[index])
            filterObj[column.field] = { value, alias: column.alias, fieldItem: getFieldItemForFilter(column) }
    });

    rowBoxes.forEach((row, index) => {
        const value = checkIfFieldTypeIsDateAndFormatDateFilterValue(row, chartProps?.filterParams?.rowHeaders[index])
        if (chartProps?.filterParams?.rowHeaders[index])
            filterObj[row.field] = { value, alias: row.alias, fieldItem: getFieldItemForFilter(row) };
    });
    

    if (deepEqual(filterValue, filterObj) && !isActiveContextMenuFilter(chartProps)) return {};

    return filterObj;
}

const checkChartPropEventTypeContextMenu = (chartProp) => {
    return chartProp.drillThrough;
}

export const checkIfFieldTypeIsDateAndFormatDateFilterValue = (field, filterValue) => {
    if (field?.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.DATE) {
        return formatIsoToDateFilter(filterValue)
    } else return filterValue
}
