<template>
  <CustomPopup
    :fieldText="selectedField.name"
    :titleText="$t('generalPages.group')"
    :width="725"
    @onClose="$emit('closeGroupPopupMenu')"
    @onCancel="$emit('closeGroupPopupMenu')"
    @onSubmit="saveGroup()"
  >
    <div class="vis-group-popup-content">
      <!--vis-group-popup-namebox start -->
      <div class="vis-group-popup-namebox">
        <div v-if="!hasSourceFieldId">
          <span class="vis-mr--1">{{ $t("generalPages.name") }}</span>
          <el-input size="mini" v-model="groupData.alias" />
        </div>
      </div>
      <!--vis-group-popup-namebox end -->
      <div class="vis-display-flex">
        <!--GROUP-POPUP-COL LEFT START-->
        <div
          v-loading="searchLoading"
          class="vis-group-popup-col vis-pr--1 vis-group-popup-col-left"
        >
          <div class="vis-flex--spacebeetwen-Ycenter vis-mb--1">
            <span class="vis-font-medium">{{ $t("panel.dataItems") }}</span>
            <InputSearch @keyupSearchData="fieldRowSearchValue = $event" />
          </div>
          <draggable
            class="list-group vis-scroll-thin"
            :list="fieldsRowFilteredByName"
            :group="{ name: 'people' }"
          >
            <div
              class="list-group-item vis-cursor-pointer"
              v-for="fieldRow in fieldsRowFilteredByName"
              :key="fieldRow.value"
            >
              {{ fieldRow.value }}
            </div>
          </draggable>
        </div>
        <!--GROUP-POPUP-COL LEFT END-->
        <div class="vis-group-popup-col vis-pl--1">
          <div class="vis-flex--spacebeetwen-Ycenter vis-mb--1">
            <span class="vis-font-medium">{{ $t("generalPages.groups") }}</span>
            <el-button @click="addGroup()" type="text" size="small"
              >{{ $t("panelProperties.add") }}
            </el-button>
          </div>

          <el-collapse v-model="activeNames" class="vis-scroll-thin">
            <el-collapse-item
              v-for="(group, groupIndex) in groupData.groups"
              :key="groupIndex"
              :name="groupIndex"
              class="vis-position-relative"
            >
              <template slot="title">
                <span
                  v-if="!group.isActiveTextInput"
                  @dblclick.stop="group.isActiveTextInput = true"
                  >{{ group.name }}</span
                >
                <div
                  v-else
                  :id="groupIndex"
                  class="vis-group-name-box vis-position-relative"
                >
                  <el-input
                    @keyup.enter.native.prevent="
                      group.isActiveTextInput = false
                    "
                    size="mini"
                    v-model="group.name"
                  />
                </div>
                <i
                  v-if="isDeleteIconActive(groupIndex)"
                  @click="deleteGroup(groupIndex, group)"
                  aria-hidden="true"
                  :class="customIcon.Close"
                  class="vis-cursor-pointer vis-delete-group"
                ></i>
              </template>
              <span
                v-if="getGroupItemLength(group)"
                class="vis-empty-text-info"
                >{{ $t("generalPages.dragDataItemsHere") }}</span
              >
              <draggable
                class="list-group vis-scroll-thin"
                :list="group.items"
                group="people"
              >
                <div
                  class="list-group-item vis-cursor-pointer"
                  v-for="groupItem in group.items"
                  :key="groupItem.value"
                >
                  {{ groupItem.value }}
                </div>
              </draggable>
            </el-collapse-item>
            <div class="vis-show-ungrouped-box">
              <el-checkbox v-model="groupData.others.isShow">{{
                $t("generalPages.showUngrouped")
              }}</el-checkbox>
              <p
                v-if="!groupData.others.isActiveTextInput"
                @dblclick.stop="groupData.others.isActiveTextInput = true"
                class="vis-cursor-pointer"
              >
                {{ groupData.others.name }}
              </p>
              <div
                v-else
                class="vis-position-relative vis-show-ungrouped-input"
              >
                <el-input
                  @keyup.enter.native.prevent="
                    groupData.others.isActiveTextInput = false
                  "
                  size="mini"
                  v-model="groupData.others.name"
                />
              </div>
            </div>
          </el-collapse>
        </div>
        <!--GROUP-POPUP-COL RİGHT END-->
      </div>
    </div>
  </CustomPopup>
</template>
<script>
import draggable from "vuedraggable";
import InputSearch from "./Form/InputSearch.vue";
import { mapActions, mapGetters } from "vuex";
import { ACTION as ACTION_TEST_QUERY } from "../../store/modules/Visualize/TestQuery/types";
import { ACTION as ACTION_FIELD_GROUP } from "../../store/modules/Visualize/FieldGroup/types";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { CustomIcon } from "../../assets/js/custom-icons";
import { v4 as uuidv4 } from "uuid";
import { getSearchApiBodyParam } from "../../util/searchApiCommonPayload.js";
import cloneDeep from "clone-deep";
import CustomPopup from "./CustomPopup.vue";

export default {
  components: {
    draggable,
    InputSearch,
    CustomPopup,
  },
  props: {
    selectedField: {
      type: Object,
    },
  },
  data() {
    return {
      groupData: {
        alias: this.selectedField.name + " Group",
        sourceFieldId: this.selectedField.fieldId,
        groups: [
          {
            id: uuidv4(),
            name: "New Group",
            items: [],
            isActiveTextInput: true,
          },
        ],
        others: { isShow: true, name: "Others", isActiveTextInput: true },
      },
      customIcon: CustomIcon,
      activeNames: [0],
      fieldRowList: [],
      fieldRowSearchValue: "",
    };
  },
  async mounted() {
    this.getDetailGroups();

    await this.getDistinctSearch();
  },
  computed: {
    ...mapGetters({
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    hasSourceFieldId() {
      return this.selectedField?.sourceFieldId;
    },
    searchLoading() {
      return this.loading[LoadingComponent.GetSearch];
    },
    fieldRowListHasSourceFieldId() {
      return this.hasSourceFieldId
        ? this.mergedFieldRowList()
        : this.fieldRowList;
    },
    fieldsRowFilteredByName() {
      return this.fieldRowSearchValue
        ? this.fieldRowListHasSourceFieldId.filter((item) => {
            return this.fieldRowSearchValue
              ?.toLowerCase()
              ?.split(" ")
              ?.every((x) => item.value.toLowerCase().includes(x));
          })
        : this.fieldRowListHasSourceFieldId;
    },
  },
  methods: {
    ...mapActions({
      fetchSearchWithoutMutate: ACTION_TEST_QUERY.FETCH_SEARCH_WITHOUT_MUTATE,
      createFieldGroup: ACTION_FIELD_GROUP.CREATE_FIELD_GROUP,
      updateFieldGroup: ACTION_FIELD_GROUP.UPDATE_FIELD_GROUP,
    }),

    async getDistinctSearch() {
      let fieldData;
      if (this.hasSourceFieldId) {
        fieldData = cloneDeep(this.selectedField);
        fieldData.fieldId = fieldData.sourceFieldId;
      } else fieldData = this.selectedField;

      if(!fieldData?.fieldId){
        fieldData.fieldId = fieldData?.id;
      }
      const response = await this.fetchSearchWithoutMutate({
        loadingComponent: LoadingComponent.GetSearch,
        bodyParam: getSearchApiBodyParam(fieldData),
      });

      this.fieldRowList = response.aggregations;
    },
    mergedFieldRowList() {
      if (!this.fieldRowList.length) return;

      let groupItems = [];

      this.groupData.groups.forEach((group) => {
        group.items.forEach((groupItem) => {
          groupItems.push(groupItem);
        });
      });

      let newFieldRowList = [];

      this.fieldRowList.forEach((field) => {
        if (!groupItems.some((x) => x.value === field.value))
          newFieldRowList.push(field);
      });

      return newFieldRowList;
    },
    addGroup() {
      this.groupData.groups.push({
        id: uuidv4(),
        name: "New Group",
        items: [],
        isActiveTextInput: true,
      });
    },
    deleteGroup(index, group) {
      this.groupData.groups.splice(index, 1);
      this.fieldRowList = [...this.fieldRowList, ...group.items];
    },
    getDetailGroups() {
      if (this.hasSourceFieldId) {
        this.groupData.sourceFieldId = this.hasSourceFieldId;
        this.groupData.alias = this.selectedField.alias;
        this.groupData.groups = this.selectedField.groups.filter((x) => x.id);
        this.groupData.others = this.selectedField.groups.find((x) => !x.id);
      }
    },
    getGroupItemLength(group) {
      return !group?.items?.length;
    },
    isDeleteIconActive(index) {
      return this.activeNames.includes(index);
    },
    async saveGroup() {
      const payload = {
        bodyParam: this.groupData,
      };
      let response = null;

      if (this.hasSourceFieldId) {
        response = await this.updateFieldGroup({
          ...payload,
          ...{
            queryParam: this.selectedField.fieldId,
          },
        });
      } else response = await this.createFieldGroup(payload);

      this.$emit("getFieldListById");

      if (response?.data?.fieldId) this.$emit("closeGroupPopupMenu");
    },
  },
};
</script>
<style scoped>
.vis-group-popup-card-width {
  width: 625px;
}
.vis-group-popup-content {
  position: relative;
  width: 100%;
  padding: 0 20px;
}
.vis-group-popup-namebox {
  position: relative;
  display: block;
  width: 100%;
  margin: 20px 0;
}
.vis-group-popup-namebox .el-input {
  width: 223px !important;
}
.vis-group-popup-col-left .el-input {
  width: 143px !important;
}
.vis-group-popup-col-left {
  border-right: 1px solid #d1d1d1;
}
.vis-common-popup-footer {
  position: relative;
  width: 100%;
  padding: 30px;
}
::v-deep .vis-common-popup-footer .el-button--text {
  color: #4b4b4b !important;
}
.vis-group-popup-col {
  position: relative;
  width: 50%;
}
.vertical-line {
  position: absolute;
  height: 100px;
  width: 2px;
  background-color: #4b44bb4b;
  left: 50%;
  transform: translateX(-50%);
}
::v-deep .el-collapse,
.vis-group-popup-col-left .list-group {
  height: 300px;
}
::v-deep .el-collapse-item > div {
  width: 90%;
}
::v-deep .el-collapse-item__header {
  padding: 0 !important;
}
::v-deep .el-collapse-item__wrap {
  position: relative;
  border-bottom: none !important;
  padding-left: 5px;
}
.list-group {
  position: relative;
  width: 100%;
  min-width: 190px;
  min-height: 20px;
  padding-left: 28px;
}
.vis-group-popup-col-left .list-group {
  padding-left: 15px;
}
::v-deep .el-collapse-item__header.is-active::before {
  content: "\e91d" !important;
}
::v-deep .el-collapse-item__header::before {
  content: "\e91b" !important;
}
.vis-group-name-box {
  position: relative;
  width: 100%;
}
.vis-group-name-box span {
  position: absolute;
  right: 5px;
}
.vis-delete-group {
  position: absolute;
  right: 0;
  color: var(--primary);
}
.vis-empty-text-info {
  position: absolute;
  left: 35px;
  top: 0;
}
.vis-show-ungrouped-box p,
.vis-show-ungrouped-box .vis-show-ungrouped-input {
  padding-left: 24px;
}
::v-deep .vis-show-ungrouped-box .el-input {
  width: inherit !important;
}
</style>
