<template>
  <!-- Scenario POC with mock data, remove all mock data TODO -->
  <Layout
    v-loading="isSelectedScenarioLoading"
    articleId="article"
    :showNav="false"
    :showAside="showScenarioAside"
  >
    <div slot="modals">
      <ConfirmDialog
        :title="$t('Are you sure you want to delete this state?')"
        :message="
          $t('State') +
          ' ' +
          deleteStateName +
          ' ' +
          $t('and its transitions will be deleted.')
        "
        :dialogVisible="deleteStateDialogVisible"
        @confirm="handleStateDeleteClicked"
        @cancel="deleteStateDialogVisible = $event"
      />
      <SimulatePopup
        v-if="isSimulatePopupVisible"
        :properties="selectedScenario"
        :events="events"
        :editSimulateItemValue="editSimulateItemValue"
        @cancel="cancelSimulatePopup"
        @save="saveSimulateItemLocally"
        @update="updateSimulateItemLocally"
      />
    </div>
    <div slot="header" class="scenario-header">
      <span class="scenario-header-title">Scenario</span>
      <div class="vis-ml--auto scenario-header-actions">
        <i
          class="vis-cursor-pointer vis-position-relative"
          :class="[CustomIcon.SaveOutlined]"
          @click.stop="saveScenario"
          aria-hidden="true"
        />
        <i
          class="vis-cursor-pointer vis-position-relative"
          :class="[
            CustomIcon.Filter,
            {
              'vis-active-setting-icon': showScenarioAside,
            },
          ]"
          @click.stop="toggleAside"
          aria-hidden="true"
        />
      </div>
    </div>
    <div slot="aside">
      <!-- TODO udpate @updateName="updateStateName" -->
      <ScenarioAsideStateProperties
        v-if="selectedStateId"
        :properties="selectedStateProperties"
        @updateName="() => null"
        @updateScreenNote="updateStateScreenNote"
      />
      <ScenarioAsideTransitionProperties
        v-if="selectedTransitionId"
        ref="ScenarioAsideTransitionProperties"
        :properties="selectedTransitionProperties"
        :events="events"
        :enrichmentOptions="enrichmentOptions"
        :actions="actions"
        @updateName="updateTransitionName"
        @updateScreenNote="updateTransitionScreenNote"
        @updateEventName="updateTransitionEventName"
        @updateEnrichment="updateTransitionEnrichment"
        @updateExpression="updateTransitionExpression"
        @updateAction="updateTransitionAction"
      />
      <ScenarioAsideScenarioProperties
        v-if="!selectedStateId && !selectedTransitionId"
        :properties="tempSelectedScenario"
        :events="events"
        :localSimulateData="localSimulateData"
        @updateName="updateScenarioName"
        @showSimulatePopup="showSimulatePopup"
        @simulateByElement="simulateByElement"
        @publishAll="publishAllSimulations"
        @deleteSimulateItem="deleteSimulateItem"
        @editSimulateItem="editSimulateItem"
        @simulateOrderChanged="simulateOrderChanged"
      />
    </div>

    <div slot="article" class="vis-height-100">
      <JsplumbVue2DataFlowEditor
        ref="JsplumbVue2DataFlowEditor"
        :editorData="editorData"
        :propertiesOpen="showScenarioAside"
        @transition-clicked="handleTransitionClick"
        @state-clicked="handleStateClick"
        @addNewState="(dropPosition) => addNewState('State', dropPosition)"
        @addNewFinishState="
          (dropPosition) => addNewState('Finish', dropPosition)
        "
        @addNewTransition="addNewTransition"
        @updatePositionsAndLevels="updatePositionsAndLevels"
        @canvas-clicked="handleCanvasClick"
        @onDeleteClicked="showDeleteStateConfirmation"
        @onConvertToFinishClicked="handLeConvertToFinishClicked"
        @onConvertToStateClicked="handleConvertToStateClicked"
        @onEventIconClick="handleTransitionOverlayEventIconClick"
        @onEnrichmentIconClick="handleTransitionOverlayEnrichmentIconClick"
        @onRuleIconClick="handleTransitionOverlayRuleIconClick"
        @onActionIconClick="handleTransitionOverlayActionIconClick"
        @onTransitionDeleteClick="handleTransitionDeleteClicked"
      />
    </div>
  </Layout>
</template>

<script>
import Layout from "../layout/Layout.vue";
import JsplumbVue2DataFlowEditor from "../components/scenario/components/JsplumbVue2DataFlowEditor.vue";
import { CustomIcon } from "../assets/js/custom-icons";
import ScenarioAsideTransitionProperties from "../components/scenario/ScenarioAsideTransitionProperties.vue";
import ScenarioAsideStateProperties from "../components/scenario/ScenarioAsideStateProperties.vue";
import ScenarioAsideScenarioProperties from "../components/scenario/ScenarioAsideScenarioProperties.vue";
import SimulatePopup from "../components/scenario/SimulatePopup.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  GETTER as GETTER_SCENARIO_MAIN,
  ACTION as ACTION_SCENARIO_MAIN,
} from "../store/modules/Scenario/Main/types";
import {
  TEMP_STORAGE_KEYS,
  GETTER as GETTER_TEMP_STORAGE,
  MUTATION as MUTATION_TEMP_STORAGE,
} from "../store/modules/temp-storage/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from "clone-deep";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import { routerEnums } from "../commons/Enum";
/*import { Notify } from "../commons/helper";
import { notificationType } from "../commons/notificationTypes";*/
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";

/*function depthFirstSearch(data, startNodeType, targetNodeType) {
  const paths = [];

  function dfs(currentNode, currentPath) {
    if (currentNode.type === targetNodeType) {
      paths.push([...currentPath, currentNode.name]);
      return;
    }

    for (const detailItem of currentNode.detail) {
      const nextState = detailItem.nextState;
      const nextStateNode = data.find((node) => node.name === nextState);

      if (nextStateNode) {
        dfs(nextStateNode, [...currentPath, currentNode.name]);
      }
    }
  }

  const startNode = data.find((node) => node.type === startNodeType);

  if (startNode) {
    dfs(startNode, []);
  }

  return paths;
}*/

export default {
  components: {
    JsplumbVue2DataFlowEditor,
    Layout,
    ScenarioAsideTransitionProperties,
    ScenarioAsideStateProperties,
    ScenarioAsideScenarioProperties,
    SimulatePopup,
    ConfirmDialog,
  },
  data() {
    return {
      deleteStateDialogVisible: false,
      deleteStateName: null,
      showScenarioAside: false,
      selectedTransitionId: null,
      selectedTransitionProperties: null,
      selectedStateId: null,
      selectedStateProperties: null,
      CustomIcon: CustomIcon,
      editorData: {
        edges: [],
        nodes: [],
      },
      localSimulateData: [],
      isSimulatePopupVisible: false,
      editSimulateItemValue: null,
      stateTypes: {
        State: "State",
        Finish: "Finish",
        Start: "Start",
      },
    };
  },
  async mounted() {
    this.fetchEvents();
    this.fetchEnrichments();
    this.fetchActions();

    if (this.scenarioId) {
      await this.fetchSelectedScenario(this.scenarioId);
      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.selectedScenario,
      });

      const getScenarioWithTransitionIds = () => {
        const clonedTempScenario = this.cloneTempSelectedScenario();

        clonedTempScenario.states.forEach((state) => {
          state.stateId = uuidv4();

          state.detail.forEach((detailItem) => {
            detailItem.transitionId = uuidv4();
          });
        });

        return clonedTempScenario;
      };

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: getScenarioWithTransitionIds(),
      });

      const getLocalStorageSimulateData = () => {
        const dataFromLocalStorage = localStorage.getItem(
          `scenarioSimulateData.${this.scenarioId}`
        );

        if (dataFromLocalStorage) {
          this.localSimulateData = JSON.parse(dataFromLocalStorage);
        }
      };

      getLocalStorageSimulateData();
      this.convertServerData();
    } else {
      const scenarioWithStartState = {
        name: "scenario_" + uuidv4(),
        normalizeName: null,
        endDate: "2024-12-11T00:00:00.000+00:00",
        startDate: "2023-06-14T09:02:55.977+00:00",
        events: [],
        states: [
          {
            name: "startNode",
            detail: [],
            type: this.stateTypes.Start,
            screenNote: "",
            properties: { position: { left: 100, top: 100 } },
            stateId: uuidv4(),
          },
        ],
      };

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: scenarioWithStartState,
      });

      this.convertServerData();
    }
  },
  computed: {
    ...mapGetters({
      selectedScenario: GETTER_SCENARIO_MAIN.GET_SELECTED_SCENARIO,
      events: GETTER_SCENARIO_MAIN.GET_EVENTS,
      enrichmentOptions: GETTER_SCENARIO_MAIN.GET_ENRICHMENTS,
      actions: GETTER_SCENARIO_MAIN.GET_ACTIONS,
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    scenarioId() {
      return this.$route.params.id;
    },
    tempSelectedScenario() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO);
    },
    isSelectedScenarioLoading() {
      return this.loading[LoadingComponent.SelectedScenario];
    },
  },
  methods: {
    ...mapActions({
      fetchSelectedScenario: ACTION_SCENARIO_MAIN.FETCH_SELECTED_SCENARIO,
      fetchEvents: ACTION_SCENARIO_MAIN.FETCH_EVENTS,
      fetchEnrichments: ACTION_SCENARIO_MAIN.FETCH_ENRICHMENTS,
      fetchActions: ACTION_SCENARIO_MAIN.FETCH_ACTIONS,
      updateScenario: ACTION_SCENARIO_MAIN.UPDATE_SCENARIO,
      createScenario: ACTION_SCENARIO_MAIN.CREATE_SCENARIO,
      createSimulate: ACTION_SCENARIO_MAIN.CREATE_SIMULATE,
    }),
    ...mapMutations({
      setTempStorageByKey: MUTATION_TEMP_STORAGE.SET_TEMP_STORAGE_BY_KEY,
    }),
    handleTransitionDeleteClicked(id) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      function removeTransition(states, transitionId) {
        states.forEach((state) => {
          if (state.detail) {
            state.detail = state.detail.filter(
              (detail) => detail.transitionId !== transitionId
            );
          }
        });

        return states;
      }

      const tempSelectedScenario = {
        ...clonedTempScenario,
        states: removeTransition(clonedTempScenario.states, id),
      };

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: tempSelectedScenario,
      });

      this.convertServerData();
    },
    handleTransitionOverlayEventIconClick(id) {
      this.handleTransitionClick(id);

      setTimeout(() => {
        this.$refs.ScenarioAsideTransitionProperties.focusEventTab();
      }, 500);
    },
    handleTransitionOverlayEnrichmentIconClick(id) {
      this.handleTransitionClick(id);
      setTimeout(() => {
        this.$refs.ScenarioAsideTransitionProperties.focusEnrichmentTab();
      }, 500);
    },
    handleTransitionOverlayRuleIconClick(id) {
      this.handleTransitionClick(id);
      setTimeout(() => {
        this.$refs.ScenarioAsideTransitionProperties.focusRuleTab();
      }, 500);
    },
    handleTransitionOverlayActionIconClick(id) {
      this.handleTransitionClick(id);
      setTimeout(() => {
        this.$refs.ScenarioAsideTransitionProperties.focusActionTab();
      }, 500);
    },
    handLeConvertToFinishClicked(id) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      clonedTempScenario.states.forEach((state) => {
        if (state.name === id) {
          state.type = this.stateTypes.Finish;
        }
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: clonedTempScenario,
      });

      this.convertServerData();
    },
    handleConvertToStateClicked(id) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      clonedTempScenario.states.forEach((state) => {
        if (state.name === id) {
          state.type = this.stateTypes.State;
        }
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: clonedTempScenario,
      });

      this.convertServerData();
    },
    showDeleteStateConfirmation(deleteStateName) {
      this.deleteStateDialogVisible = true;
      this.deleteStateName = deleteStateName;
    },
    handleStateDeleteClicked() {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      function removeState(graph, stateName) {
        // Step 1: Remove the state from the root array
        graph = graph.filter((node) => node.name !== stateName);

        // Step 2: Remove the state from the "detail" arrays
        graph.forEach((node) => {
          if (node.detail) {
            node.detail = node.detail.filter(
              (detail) => detail.nextState !== stateName
            );
          }
        });

        return graph;
      }

      // Create a new object with the filtered states
      const tempSelectedScenario = {
        ...clonedTempScenario,
        states: removeState(clonedTempScenario.states, this.deleteStateName),
      };

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: tempSelectedScenario,
      });
      this.convertServerData();

      this.deleteStateDialogVisible = false;
      this.deleteStateName = null;
    },
    updateScenarioName(newName) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      clonedTempScenario.name = newName;

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: clonedTempScenario,
      });
    },
    updatePositionsAndLevels({ nodesCoords }) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      clonedTempScenario.states.forEach((state) => {
        nodesCoords.forEach((nodeCoords) => {
          // TODO update name with id when backend fixed
          if (nodeCoords.source_id === state.name) {
            state.properties.position = nodeCoords.source_css;
          }
        });
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: clonedTempScenario,
      });

      this.convertServerData();
    },
    // ADDING NEW NODES TO EDITOR BEGINS
    addNewState(type, dropPosition) {
      const clonedTempScenario = this.cloneTempSelectedScenario();
      const createNewState = () => {
        return {
          name: "s" + (clonedTempScenario.states?.length + 1),
          detail: [],
          type,
          screenNote: "",
          properties: { position: dropPosition },
          stateId: uuidv4(),
        };
      };

      clonedTempScenario.states.push(createNewState());

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: clonedTempScenario,
      });

      this.convertServerData();
    },
    // ADDING NEW NODES TO EDITOR ENDS

    // ADDING NEW TRANSITION BEGINS
    addNewTransition({ source, target }) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      clonedTempScenario.states.forEach((state) => {
        if (state.name === source) {
          state.detail.push({
            event: "",
            expression: "",
            enrichments: [],
            action: {
              actiondetails: {},
            },
            nextState: target,
            transitionId: uuidv4(),
          });
        }
      });

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: clonedTempScenario,
      });

      this.convertServerData();
    },
    // ADDING NEW TRANSITION ENDS

    // UPDATE STATE PROPERTIES BEGINS
    updateStateName(newName) {
      this.selectedStateProperties.name = newName;

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.setNewValueToSelectedState(this.selectedStateProperties),
      });

      this.handleStateClick(this.selectedStateId);
    },
    updateStateScreenNote(newScreenNote) {
      this.selectedStateProperties.screenNote = newScreenNote;

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.setNewValueToSelectedState(this.selectedStateProperties),
      });

      this.handleStateClick(this.selectedStateId);
    },
    findStateByStateId(id) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      return clonedTempScenario.states.find((state) => state.stateId === id);
    },
    // Function to find state by stateId and sets new value
    setNewValueToSelectedState(newStateValue) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      clonedTempScenario.states = clonedTempScenario.states.map((state) => {
        if (state.stateId === this.selectedStateId) {
          return newStateValue;
        }

        return state;
      });

      return clonedTempScenario;
    },
    // UPDATE STATE PROPERTIES ENDS

    // UPDATE TRANSITION PROPERTIES BEGINS
    updateTransitionName(newName) {
      this.$set(this.selectedTransitionProperties, "name", newName);

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.setNewValueToSelectedTransition(
          this.selectedTransitionProperties
        ),
      });

      this.handleTransitionClick(this.selectedTransitionId);
    },
    updateTransitionScreenNote(newScreenNote) {
      this.$set(this.selectedTransitionProperties, "screenNote", newScreenNote);

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.setNewValueToSelectedTransition(
          this.selectedTransitionProperties
        ),
      });

      this.handleTransitionClick(this.selectedTransitionId);
    },
    updateTransitionEventName(newEventName) {
      this.$set(this.selectedTransitionProperties, "event", newEventName);

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.setNewValueToSelectedTransition(
          this.selectedTransitionProperties
        ),
      });

      this.handleTransitionClick(this.selectedTransitionId);
    },
    updateTransitionEnrichment(newEnrichments) {
      this.selectedTransitionProperties.enrichments = cloneDeep(newEnrichments);

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.setNewValueToSelectedTransition(
          this.selectedTransitionProperties
        ),
      });

      this.handleTransitionClick(this.selectedTransitionId);
    },
    updateTransitionAction(newAction) {
      this.$set(this.selectedTransitionProperties, "action", newAction);

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.setNewValueToSelectedTransition(
          this.selectedTransitionProperties
        ),
      });

      this.handleTransitionClick(this.selectedTransitionId);
    },
    updateTransitionExpression(newExpression) {
      this.$set(this.selectedTransitionProperties, "expression", newExpression);

      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_SELECTED_SCENARIO,
        value: this.setNewValueToSelectedTransition(
          this.selectedTransitionProperties
        ),
      });

      this.handleTransitionClick(this.selectedTransitionId);
    },
    findStateDetailByTransitionId(transitionId) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      for (const state of clonedTempScenario.states) {
        for (const detail of state.detail) {
          if (detail.transitionId === transitionId) {
            return detail;
          }
        }
      }
      return null;
    },
    // Function to find state detail by transitionId and sets new value
    setNewValueToSelectedTransition(newDetailValue) {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      clonedTempScenario.states.forEach((state) => {
        const detailToUpdate = state.detail.find(
          (detail) => detail.transitionId === this.selectedTransitionId
        );

        if (detailToUpdate) {
          Object.assign(detailToUpdate, newDetailValue);
        }
      });

      return clonedTempScenario;
    },
    // UPDATE TRANSITION PROPERTIES ENDS

    async saveScenario() {
      const clonedTempScenario = this.cloneTempSelectedScenario();

      /*const validateStates = () => {
        let valid = true;
        let invalidReason = "";
        const dfsPaths = depthFirstSearch(
          clonedTempScenario.states,
          this.stateTypes.Start,
          this.stateTypes.Finish
        );
        const flatNamesInPath = dfsPaths.flat(); // will be used for checking if there is any state that is not connected to finish state
        const allStateNames = clonedTempScenario.states.map(
          (state) => state.name
        );
        const checkAllStateNamesInFlatNamesInPath = allStateNames.every(
          (stateName) => flatNamesInPath.includes(stateName)
        );

        if (!checkAllStateNamesInFlatNamesInPath) {
          valid = false;
          invalidReason = this.$t(
            "Not all states are connected to finish state"
          );
        }

        dfsPaths.forEach((path) => {
          const lastNodeType = clonedTempScenario.states.find(
            (state) => state.name === path[path.length - 1]
          ).type;

          if (lastNodeType !== this.stateTypes.Finish) {
            valid = false;
            invalidReason = this.$t("Last node is not finish node");
          }
        });

        return { invalidReason, valid };
      };

      const validationResult = validateStates();

      if (!validationResult.valid) {
        Notify(validationResult.invalidReason, notificationType.WARNING);

        return;
      }*/

      // Extracting distinct event values from state details
      const distinctEvents = Array.from(
        new Set(
          clonedTempScenario.states
            .flatMap((state) => state.detail.map((detail) => detail.event))
            .filter((event) => event !== undefined)
        )
      );

      clonedTempScenario.events = distinctEvents;

      if (this.scenarioId) {
        this.updateScenario({
          scenarioId: clonedTempScenario.scenarioId,
          data: clonedTempScenario,
        });
      } else {
        const result = await this.createScenario({
          data: clonedTempScenario,
        });

        if (result?.scenarioId) {
          this.$router.push({
            name: routerEnums.SCENARIO_EDIT,
            params: {
              id: result.scenarioId,
            },
          });
        }
      }
    },
    cancelSimulatePopup() {
      this.isSimulatePopupVisible = false;
    },
    updateSimulateItemLocally({ id, formData }) {
      this.isSimulatePopupVisible = false;

      for (let i = 0; i < this.localSimulateData.length; i++) {
        if (this.localSimulateData[i].id === id) {
          this.localSimulateData[i] = { id, ...formData };
          break;
        }
      }

      localStorage.setItem(
        `scenarioSimulateData.${this.scenarioId}`,
        JSON.stringify(this.localSimulateData)
      );
    },
    saveSimulateItemLocally(data) {
      this.isSimulatePopupVisible = false;
      this.localSimulateData.push({ ...data, id: uuidv4() });

      localStorage.setItem(
        `scenarioSimulateData.${this.scenarioId}`,
        JSON.stringify(this.localSimulateData)
      );
    },
    deleteSimulateItem(id) {
      this.localSimulateData = this.localSimulateData.filter(
        (d) => d.id !== id
      );

      localStorage.setItem(
        `scenarioSimulateData.${this.scenarioId}`,
        JSON.stringify(this.localSimulateData)
      );
    },
    simulateOrderChanged(data) {
      this.localSimulateData = cloneDeep(data);

      localStorage.setItem(
        `scenarioSimulateData.${this.scenarioId}`,
        JSON.stringify(this.localSimulateData)
      );
    },
    editSimulateItem(id) {
      this.isSimulatePopupVisible = true;
      this.editSimulateItemValue = this.localSimulateData.find(
        (d) => d.id === id
      );
    },
    showSimulatePopup() {
      this.editSimulateItemValue = null;
      this.isSimulatePopupVisible = true;
    },
    async simulateByElement(element) {
      const convertLocalDataToServer = () => {
        const fieldsMap = {};

        element.fields.forEach((e) => {
          fieldsMap[e.field] = e.value;
        });

        return {
          header: {
            eventName: element.name,
          },
          body: fieldsMap,
        };
      };

      await this.createSimulate({ data: convertLocalDataToServer() });
    },
    async publishAllSimulations() {
      for (let i = 0; i < this.localSimulateData.length; i++) {
        await this.simulateByElement(this.localSimulateData[i]);
      }
    },
    handleCanvasClick() {
      this.showScenarioAside = true;
      this.selectedTransitionId = null;
      this.selectedTransitionProperties = null;
      this.selectedStateId = null;
      this.selectedStateProperties = null;
      this.convertServerData();
    },
    handleTransitionClick(id) {
      const isSelectedTransitionChanged = id !== this.selectedTransitionId;

      if (isSelectedTransitionChanged) {
        this.showScenarioAside = true;
        this.selectedTransitionId = id;
        this.selectedTransitionProperties =
          this.findStateDetailByTransitionId(id);
        this.selectedStateId = null;
        this.selectedStateProperties = null;
        this.convertServerData();
      }
    },
    handleStateClick(id) {
      const isSelectedStateChanged = id !== this.selectedStateId;

      if (isSelectedStateChanged) {
        this.showScenarioAside = true;
        this.selectedTransitionId = null;
        this.selectedTransitionProperties = null;
        this.selectedStateId = id;
        this.selectedStateProperties = this.findStateByStateId(id);
        this.convertServerData();
      }
    },
    cloneTempSelectedScenario() {
      return cloneDeep(this.tempSelectedScenario);
    },
    toggleAside() {
      this.showScenarioAside = !this.showScenarioAside;
    },
    convertServerData() {
      this.editorData = {
        edges: [],
        nodes: [],
      };

      // Convert the states to nodes
      this.tempSelectedScenario.states.forEach((state) => {
        const isConvertable =
          (state.type === this.stateTypes.State && state.detail.length === 0) ||
          state.type === this.stateTypes.Finish;

        this.editorData.nodes.push({
          type: state.type,
          id: state.name,
          name: state.name,
          left: state.properties.position.left,
          top: state.properties.position.top,
          stateId: state.stateId,
          isNodeSelected: this.selectedStateId === state.stateId,
          isConvertable,
        });
      });

      // Convert the detail information to connections
      this.tempSelectedScenario.states.forEach((state) => {
        state.detail.forEach((detail) => {
          if (state.name && detail.nextState)
            this.editorData.edges.push({
              source: state.name,
              target: detail.nextState,
              data: {
                type: "StateEdge",
                source: state.name,
                target: detail.nextState,
                detail,
                isEdgeSelected:
                  this.selectedTransitionId === detail.transitionId,
              },
            });
        });
      });
    },
  },
};
</script>

<style scoped>
::v-deep header {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e3e8;
}
::v-deep aside {
  width: 470px;
}
::v-deep .el-tabs__item {
  padding: 0;
}
::v-deep .el-tabs__item.is-active {
  color: #0014ff;
}
::v-deep .el-tabs__active-bar {
  background: #0014ff;
}
::v-deep .el-tabs__item:hover {
  color: #0014ff;
}
.scenario-header {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: inherit;
}
.scenario-header-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
}
.scenario-header-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.scenario-aside {
  display: flex;
  align-items: center;
  padding: 18px 25px;
  height: inherit;
  flex-direction: column;
}
.scenario-aside-title {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #333333;
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.scenario-aside-title-icon {
  color: #ff7623;
  font-size: 25px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.scenario-aside-properties-container {
  width: 100%;
}
.scenario-properties-icon {
  font-size: 16px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  vertical-align: middle;
  margin-right: 2px;
}
</style>
