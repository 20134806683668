import { ACTION } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const visCalculatedColumn = {
    state: {
    },
    getters: {
    },
    actions: {
        [ACTION.CREATE_CALCULATED_FIELD]: async ({ commit }, { loadingComponent, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.CalculatedColumn,
                    serviceMethodSub: serviceMethodSub.createCalculatedColumn,
                    loadingComponent,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    withErrorNotify: false,
                },
            );
        },
        [ACTION.UPDATE_CALCULATED_FIELD]: async ({ commit }, { queryParam, loadingComponent, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.CalculatedColumn,
                    serviceMethodSub: serviceMethodSub.updateCalculatedColumn,
                    queryParam,
                    loadingComponent,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    withErrorNotify: false,
                },
            );
        },
        [ACTION.VALIDATE_CALCULATED_FIELD]: async ({ commit }, { loadingComponent, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.CalculatedColumn,
                    serviceMethodSub: serviceMethodSub.validateCalculatedColumn,
                    loadingComponent,
                    bodyParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    withErrorNotify: false,
                },
            );
        },
    },
    mutations: {
    },
};