import { AXIS_TYPES_ENUM, COMBINATION_CHART_PROPERTIES_ENUM, dashboardSPEnums, showOnlyTypesExceptOnlyItem} from "../../commons/dashboardProperties";
import { calculateGridDistances, formatValueAxisLabels, getLabelPositionByRotate, getSelectedColorPaletteByProperty, getChartLabelFormatByAggregation, isAxisLocationDefault, isAxisNameActive, isNameLocationDefault, truncateText, generateHTMLForTooltipWithSinglePoint, setShowOnlyValue, aggregationDateFormatController} from "../chartOptionsHelper";
import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import { getSeriesLabelPaddingByChartHeight, getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";

const getAxisName = (propertyName, axisName) => {
    return propertyName ? propertyName : axisName
}

export const getCombinationChartOptions = (combinationChartComputedValues, paramsFromSearch, panelData) => {

    const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
        yAxisPosition: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_POSITION,
        showYAxisLabels: combinationChartComputedValues.COMBINATION_CHART_SHOW_Y_AXIS_LABEL,
        showXAxisLabels: combinationChartComputedValues.COMBINATION_CHART_SHOW_X_AXIS_LABEL,
        showLegend: combinationChartComputedValues.COMBINATION_CHART_SHOW_LEGEND,
        legendPosition: combinationChartComputedValues.COMBINATION_CHART_LEGEND_POSITION,
        legendMaxCharacter: combinationChartComputedValues.COMBINATION_CHART_LEGEND_MAX_CHARACTER,
        yAxisNameLocation: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_NAME_LOCATION,
        showSecondYAxisLabels: combinationChartComputedValues.COMBINATION_CHART_SHOW_SECOND_Y_AXIS_LABEL,
        labelRotate: combinationChartComputedValues.COMBINATION_CHART_VALUE_LABEL_ROTATE,
        showLabel: combinationChartComputedValues.COMBINATION_CHART_SHOW_VALUE_LABEL,
        panelData
    });

    const optionObject = {
        grid:
        {
            top: gridTop + 50,
            bottom: gridBottom,
            left: gridLeft,
            right: gridRight,
            containLabel: true
        },
        title: {
            text: "",
        },
        legend: {
            show: isChartWidthLimited(panelData.w) ? combinationChartComputedValues.COMBINATION_CHART_SHOW_LEGEND : false,
            orient: getOrientPositionByLegend(combinationChartComputedValues.COMBINATION_CHART_LEGEND_POSITION).toLowerCase(),
            left: getAlignPositionByLegend(combinationChartComputedValues.COMBINATION_CHART_LEGEND_POSITION),
            top: getOrientPositionByLegend(combinationChartComputedValues.COMBINATION_CHART_LEGEND_POSITION) ? "4%" : combinationChartComputedValues.COMBINATION_CHART_LEGEND_VERTICAL_ALIGN,
            textStyle: {
                fontWeight: combinationChartComputedValues.COMBINATION_CHART_LEGEND_FONT_WEIGHT,
                fontFamily: combinationChartComputedValues.COMBINATION_CHART_LEGEND_FONT_FAMILY,
                fontSize: combinationChartComputedValues.COMBINATION_CHART_LEGEND_FONT_SIZE,
                color: combinationChartComputedValues.COMBINATION_CHART_LEGEND_FONT_COLOR,
            },
            backgroundColor: 'transparent',
            formatter: function (value) {
                return truncateText(value, combinationChartComputedValues.COMBINATION_CHART_LEGEND_MAX_CHARACTER);
            },
            type: 'scroll',
        },
        tooltip: {
            show: true,
            trigger: 'item',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: {
                fontFamily: 'Poppins',
                fontSize: 12
            },
            formatter: function (value) {
                value.name = aggregationDateFormatController(value.name, paramsFromSearch);

                return showOnlyTypesExceptOnlyItem.includes(
                  combinationChartComputedValues.COMBINATION_CHART_SHOW_ONLY
                )
                  ? setShowOnlyValue(
                      value,
                      paramsFromSearch,
                      combinationChartComputedValues,
                      COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_ONLY,
                      dashboardSPEnums.TOOLTIP,
                      panelData
                    )
                  : generateHTMLForTooltipWithSinglePoint(
                      value,
                      paramsFromSearch?.metricFormats,
                      panelData
                    );
            },
        },
        xAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartHeightLimited(panelData.h) ? combinationChartComputedValues.COMBINATION_CHART_SHOW_X_AXIS_LABEL : false,
                fontWeight: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_LABEL_FONT_FAMILY,
                fontSize: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_LABEL_FONT_SIZE,
                color: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_LABEL_FONT_COLOR,
                rotate: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_LABEL_ROTATE,
                formatter: function (value) {
                    const formattedValue = getChartLabelFormatByAggregation(value, paramsFromSearch?.aggregationFormats);
                    return truncateText(formattedValue, combinationChartComputedValues.COMBINATION_CHART_X_AXIS_LABEL_MAX_CHARACTER)
                },
                hideOverlap: true,
            },
            type: 'category',
            axisTick: {
                show: false
            },
            data: paramsFromSearch?.axisData?.x?.data ?? [],
            axisLine: {
                show: combinationChartComputedValues.COMBINATION_CHART_SHOW_X_AXIS_LINE,
            },
        },
        yAxis: [
            {
                show: paramsFromSearch?.dataSeries ? true : false,
                name: "",
                axisLabel: {
                    show: isChartWidthLimited(panelData.w) ? combinationChartComputedValues.COMBINATION_CHART_SHOW_Y_AXIS_LABEL : false,
                    hideOverlap: true,
                    fontWeight: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                    fontFamily: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                    fontSize: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_LABEL_FONT_SIZE,
                    color: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_LABEL_FONT_COLOR,
                    formatter: function (value) {
                        return formatValueAxisLabels(paramsFromSearch?.axisData?.y?.formats, value)
                    },
                },
                type: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_LOGARITHMIC_SCALE ? AXIS_TYPES_ENUM.LOG : AXIS_TYPES_ENUM.VALUE,
                min: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_MIN,
                axisLine: {
                    show: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_SHOW_LINE,
                    lineStyle: {
                        color: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_LINE_STYLE_COLOR
                    },
                },
                axisTick: {
                    show: false
                },
                alignTicks: true,
                position: "left",
                splitLine: { 
                    lineStyle: {
                      color: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_GRID_LINE_COLOR,
                    }
                },
            },
            paramsFromSearch?.dataSeries?.filter(i => i.type === "line")?.length > 0 ?
                {
                    show: true,
                    name: "",
                    alignTicks: true,
                    axisLabel: {
                        show: isChartWidthLimited(panelData.w) ? combinationChartComputedValues.COMBINATION_CHART_SHOW_SECOND_Y_AXIS_LABEL : false,
                        hideOverlap: true,
                        fontWeight: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_WEIGHT,
                        fontFamily: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_FAMILY,
                        fontSize: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_SIZE,
                        color: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_COLOR,
                        formatter: function (value) {
                            return formatValueAxisLabels(paramsFromSearch?.axisData?.y2?.formats, value)
                        },
                    },
                    type: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_LOGARITHMIC_SCALE ? AXIS_TYPES_ENUM.LOG : AXIS_TYPES_ENUM.VALUE,
                    min: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_MIN,
                    axisLine: {
                        show: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_SHOW_LINE,
                        lineStyle: {
                            color: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_LINE_STYLE_COLOR
                        },
                    },
                    axisTick: {
                        show: false
                    },
                    position: "right",
                } :
                null
        ],

        series: paramsFromSearch?.dataSeries?.length ? paramsFromSearch?.dataSeries?.map((s, index) => {
            return {
                data: s.data.map((x, index) => {
                    return {
                        value: x,
                        itemStyle: getCommonChartItemStyle(paramsFromSearch?.axisData.x.data[index], panelData)
                    }
                }),
                type: s.type,
                name: paramsFromSearch.dataSeriesNames[index],
                yAxisIndex: s.type === "line" ? 1 : 0,
                label: {
                    position: getLabelPositionByRotate(combinationChartComputedValues.COMBINATION_CHART_VALUE_LABEL_ROTATE),
                    padding: getSeriesLabelPaddingByChartHeight(panelData.h),
                    show: isChartWidthLimited(panelData.w) ? combinationChartComputedValues.COMBINATION_CHART_SHOW_VALUE_LABEL : false,
                    fontSize: combinationChartComputedValues.COMBINATION_CHART_VALUE_LABEL_FONT_SIZE,
                    fontFamily: combinationChartComputedValues.COMBINATION_CHART_VALUE_LABEL_FONT_FAMILY,
                    fontWeight: combinationChartComputedValues.COMBINATION_CHART_VALUE_LABEL_FONT_WEIGHT,
                    color: combinationChartComputedValues.COMBINATION_CHART_VALUE_LABEL_FONT_COLOR,
                    rotate: combinationChartComputedValues.COMBINATION_CHART_VALUE_LABEL_ROTATE,
                    formatter: function (value) {
                        value.name = aggregationDateFormatController(value.name, paramsFromSearch);

                        return showOnlyTypesExceptOnlyItem.includes(combinationChartComputedValues.COMBINATION_CHART_SHOW_ONLY)
                        ? setShowOnlyValue(
                            value,
                            paramsFromSearch,
                            combinationChartComputedValues,
                            COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_ONLY,
                            dashboardSPEnums.LABEL,
                            panelData
                        )
                        :
                        value?.name;
                        
                    },
                },
                itemStyle: {
                    borderRadius: [3, 3, 0, 0],
                },
                labelLayout: {
                    hideOverlap: combinationChartComputedValues.COMBINATION_CHART_HIDE_OVERLAPPED_VALUE_LABELS
                },
            }
        }) : null,
        color: getSelectedColorPaletteByProperty(combinationChartComputedValues.COMBINATION_CHART_SELECTED_COLOR_PALETTE)
    };

    return {
        options: optionObject,
        yAxis: {
            name: isAxisNameActive(paramsFromSearch, getAxisName(combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_NAME_TEXT, paramsFromSearch?.axisData?.y?.name)),
            show: isChartWidthLimited(panelData.w) ? combinationChartComputedValues.COMBINATION_CHART_SHOW_Y_AXIS_NAME : false,
            fontFamily: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_NAME_FONT_FAMILY,
            fontSize: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_NAME_FONT_SIZE,
            fontWeight: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_NAME_FONT_WEIGHT,
            fontColor: combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_NAME_FONT_COLOR,
            isAxisLocationDefault: isAxisLocationDefault(combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_POSITION),
            isNameLocationDefault: isNameLocationDefault(combinationChartComputedValues.COMBINATION_CHART_Y_AXIS_NAME_LOCATION)
        },
        secondYAxis: {
            name: isAxisNameActive(paramsFromSearch, getAxisName(combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_NAME_TEXT, paramsFromSearch?.axisData?.y2?.name)),
            show: isChartWidthLimited(panelData.w) ? combinationChartComputedValues.COMBINATION_CHART_SHOW_SECOND_Y_AXIS_NAME : false,
            fontFamily: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_FAMILY,
            fontSize: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_SIZE,
            fontWeight: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_WEIGHT,
            fontColor: combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_COLOR,
            isAxisLocationDefault: isAxisLocationDefault(combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_POSITION),
            isNameLocationDefault: isNameLocationDefault(combinationChartComputedValues.COMBINATION_CHART_SECOND_Y_AXIS_NAME_LOCATION)
        },
        xAxis: {
            name: isAxisNameActive(paramsFromSearch, getAxisName(combinationChartComputedValues.COMBINATION_CHART_X_AXIS_NAME_TEXT, paramsFromSearch?.axisData?.x?.name)),
            show: isChartHeightLimited(panelData.h) ? combinationChartComputedValues.COMBINATION_CHART_SHOW_X_AXIS_NAME : false,
            fontFamily: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_NAME_FONT_FAMILY,
            fontSize: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_NAME_FONT_SIZE,
            fontWeight: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_NAME_FONT_WEIGHT,
            fontColor: combinationChartComputedValues.COMBINATION_CHART_X_AXIS_NAME_FONT_COLOR,
        }
    }
}