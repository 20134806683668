<template>
  <div
    v-bind:style="{
      left: obj.left + 'px',
      top: obj.top + 'px',
    }"
    class="flowchart-object flowchart-action ingest-dataset-node-container ingest-flowchart-node"
    data-jtk-target="true"
  >
    <div
      class="ingest-dataset-node"
      :class="{ 'ingest-dataset-node-selected': obj.nodeSelected }"
    >
      <div class="ingest-dataset-node-header">
        <div class="ingest-dataset-node-header-actions vis-flex--Ycenter">
          <i
            :class="`${customIcon.Play} ingest-dataset-node-actions ingest-dataset-node-actions-run`"
            aria-hidden="true"
            @click="onRunJob"
          >
          </i>
          <i
            :class="`${customIcon.VisibilityEyeMaterial} ingest-dataset-node-actions`"
            aria-hidden="true"
            @click="onShowPreview"
          >
          </i>
          <i
            v-if="obj.sourceConnectionNodeId"
            :class="`${customIcon.PencilOutline} ingest-dataset-node-actions`"
            aria-hidden="true"
            @click="onEditClicked"
          >
          </i>
          <el-dropdown
            placement="bottom-start"
            size="mini"
            @command="handleCommand"
          >
            <span class="el-dropdown-link"
              ><i
                :class="`${customIcon.VerticalThreeDot} ingest-dataset-node-actions`"
                aria-hidden="true"
              >
              </i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="actionCommands.Delete">{{
                $t("datamodel.deleteDataset")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="ingest-dataset-node-header-content">
          <span
            :class="`${customIcon.Dataset} ingest-dataset-node-type-icon`"
            aria-hidden="true"
          ></span>
          <div class="ingest-dataset-node-name-and-type">
            <span
              class="ingest-dataset-node-name"
              :class="
                obj.name.length === 0 ? 'ingest-dataset-node-name-empty' : ''
              "
              :title="obj.name"
              >{{ obj.name.length === 0 ? $t("ingest.Not selected") : obj.name }}</span
            ><span class="ingest-dataset-node-type" :title="$t('ingest.Dataset')">{{
              $t("ingest.Dataset")
            }}</span>
            <!--<span class="ingest-dataset-node-status-valid"
              >Valid</span
            >-->
          </div>
        </div>
      </div>
      <div class="ingest-dataset-node-body">
        <div class="ingest-dataset-node-footer-bottom-text">
          {{ totalColumns + " " + $t("datamodel.columns") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsplumbVue2BaseEditableNode from "./JsplumbVue2BaseEditableNode.vue";
import { CustomIcon } from "../../../../assets/js/custom-icons";

export default {
  mixins: [JsplumbVue2BaseEditableNode],
  data() {
    return {
      customIcon: CustomIcon,
      actionCommands: {
        Delete: "delete",
      },
    };
  },
  computed: {
    totalColumns() {
      return this.obj.columns ?? 0;
    },
    totalRows() {
      return this.obj.rows ?? 0;
    },
  },
  methods: {
    handleCommand(command) {
      if (command === this.actionCommands.Delete) {
        this.onDeleteClicked();
      }
    },
    onEditClicked() {
      this.$parent.$parent.onEditClicked(this.obj);
    },
    onDeleteClicked() {
      this.$parent.$parent.onDeleteClicked(this.obj.id);
    },
    onShowPreview() {
      this.$parent.$parent.onShowPreview(this.obj);
    },
    onRunJob() {
      this.$parent.$parent.onRunJob(this.obj.id);
    },
  },
};
</script>

<style scoped>
.ingest-dataset-node-container {
  width: 200px;
  height: 132px;
  padding: 8px;
}
.ingest-dataset-node {
  padding: 0px;
  position: absolute;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  cursor: move;
  color: white;
  font-size: 16px;
  width: 200px;
  height: 132px;
  display: flex;
  flex-direction: column;
}
.ingest-dataset-node-selected {
  border: 3px solid #3d62f8 !important;
  box-sizing: border-box;
}
.ingest-dataset-node-header {
  height: 100px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  background: #e8e8e8;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 8px;
}
.ingest-dataset-node-body {
  height: 30px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  column-gap: 8px;
}
.ingest-dataset-node:hover .ingest-dataset-node-actions {
  display: block;
}
.ingest-dataset-node-footer-bottom-text {
  font: normal normal 500 12px/18px Poppins;
  letter-spacing: 0px;
  color: black;
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ingest-dataset-node-footer-bottom-right-text {
  font: normal normal 500 12px/18px Poppins;
  letter-spacing: 0px;
  color: black;
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: auto;
  margin-right: 15px;
}

.ingest-dataset-node-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #181818;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ingest-dataset-node-type {
  font-size: 11px;
  line-height: 18px;
  color: #5e5d5d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ingest-dataset-node-type::first-letter {
  text-transform: uppercase;
}

.ingest-dataset-node-status-valid {
  font-size: 10px;
  line-height: 13px;
  background: limegreen;
  border-radius: 10px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 37px;
  right: 7px;
}

.ingest-dataset-node-type-icon {
  font-size: 35px !important;
  color: #835dff;
  line-height: 40px;
}
.ingest-dataset-node-type-database {
  color: #a2a2a2;
}
.ingest-dataset-node-actions {
  font-size: 18px;
  font-weight: 400;
  color: #3d62f8 !important;
  cursor: pointer;
  display: none;
}
.ingest-dataset-node-actions-run {
  margin-right: auto;
  margin-left: 18px;
}
.ingest-dataset-node-header-actions {
  gap: 10px;
  position: absolute;
  justify-content: end;
  width: 100%;
  right: 10px;
}
.ingest-dataset-node-header-content {
  display: flex;
  align-items: end;
  height: 100%;
  column-gap: 10px;
  margin-bottom: 10px;
}
.ingest-dataset-node-name-and-type {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 40px);
}
.ingest-dataset-node-name-empty {
  color: #ff0f0f;
}
</style>
