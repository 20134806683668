<template>
  <div class="vis-row">
    <div
      v-for="(card, index) in cards"
      :key="index"
      @click="createClicked(card, card.routerName)"
      class="vis-create-card"
      :class="{
        'vis-display-none': !card.isVisible,
      }"
    >
      <div class="vis-create-card-content">
        <img :src="card.icon" class="type-image" alt="type-scenario" />
        <i
          class="vis-icon"
          :class="card.icon"
          :style="{ color: card.iconColor }"
          aria-hidden="true"
        ></i>
        <div class="vis-create-card-footer">
          <h4>{{ $t("generalPages.new") }}</h4>
          <div class="vis-flex--spacebeetwen-Ycenter">
            <h3>{{ card.title }}</h3>
            <i class="vis-icon icon-plus" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { routerEnums } from '../../../commons/Enum';
export default {
  data() {
    return {};
  },
  computed: {
    cards() {
      return [
        {
          title: this.$t("Scenario"),
          icon: this.getScenarioImage("type-scenario"),
          iconColor: "#206FFF",
          routerName: routerEnums.SCENARIO_CREATE,
          isVisible: true,
          isClickable: true,
          isShow: true,
        },
        {
          title: this.$t("Event"),
          icon: this.getScenarioImage("type-event"),
          iconColor: "#34BACB",
          routerName: routerEnums.EVENT_CREATE,
          isVisible: true,
          isClickable: true,
          isShow: true,
        },
        {
          title: this.$t("Enrichment"),
          icon: this.getScenarioImage("type-enrichment"),
          iconColor: "#34BACB",
          routerName: routerEnums.ENRICHMENT_CREATE,
          isVisible: true,
          isClickable: true,
          isShow: true,
        },
      ].filter((x) => x.isShow);
    },
  },
  methods: {
    getScenarioImage(imageName) {
      var images = require.context(
        "../../../assets/images/scenario/",
        false,
        /\.svg$/
      );

      try {
        return images("./" + imageName + ".svg");
      } catch (e) {
        return "";
      }
    },
    createClicked(card, name) {
      if (card.isClickable) this.$emit("createClicked", name);
    },
  },
};
</script>

<style scoped>
.type-image {
  width: 40px;
  height: 40px;
  margin-left: -6px;
}
</style>
